export default (api) => {
  const getCarMakers = () => {
    return new Promise((resolve, reject) => {
      api
        .get(`make`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  return {
    getCarMakers,
  };
};
