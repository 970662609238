import { ComboBox } from "@progress/kendo-react-dropdowns";
import { StackLayout } from "@progress/kendo-react-layout";
import classes from "../styles/leadsListing.module.css";
import { TextBox } from "@progress/kendo-react-inputs";
import { LEADS_FILTER_OPTIONS } from "../constants";
import { SvgIcon } from "@progress/kendo-react-common";
import { searchIcon } from "@progress/kendo-svg-icons";
import { useEffect, useRef, useState } from "react";

const defaultFilterType = {
    label: "Select Filter",
    value: null,
};

const LeadsFilter = ({ isAdmin, onChange }) => {
    const [filter, setFilter] = useState({
        type: null,
        value: "",
    });
    const comboboxRef = useRef(null);

    const handleFilterTypeChange = (e) => {
        const { value = defaultFilterType } = e;

        if (!value.value) {
            setFilter({
                type: null,
                value: "",
            });
        } else {
            setFilter((prev) => ({
                ...prev,
                type: value.value,
            }));
        }
    };

    const handleValueChange = (e) => {
        const { value } = e;

        setFilter((prev) => ({
            ...prev,
            value: value || "",
        }));
        
        if (value === "") {
            onChange({ ...filter, value });
        }
    };

    const handlePressEnter = (e) => {
        const { code } = e;

        if (code === "Enter" && filter.type && filter.value.length >= 0) {
            onChange(filter);
        }
    };

    useEffect(() => {
        if (comboboxRef && comboboxRef.current) {
            const inputElementId = comboboxRef.current?._inputId;
            if (inputElementId) {
                const inputElement = document.getElementById(
                    `${inputElementId}`
                );
                if (inputElement) {
                    inputElement.setAttribute("disabled", true);
                }
            }
        }
    }, [comboboxRef]);

    return (
        <StackLayout
            className={
                classes.tableFilterContainer +
                (isAdmin ? " " + classes.adminFilterPosition : "")
            }
        >
            <ComboBox
                ref={comboboxRef}
                className={classes.tableFilterDropdown}
                data={LEADS_FILTER_OPTIONS}
                rounded={null}
                placeholder="Select Filter"
                dataItemKey="value"
                textField="label"
                onChange={handleFilterTypeChange}
            />
            <TextBox
                className={classes.tableFilterInput}
                onChange={handleValueChange}
                value={filter.value}
                disabled={filter.type === null}
                onKeyDown={handlePressEnter}
            />
            <SvgIcon
                role="button"
                icon={searchIcon}
                className={classes.tableFilterSearchIcon}
                onClick={() => filter.type && filter.value && onChange(filter)}
            />
        </StackLayout>
    );
};

export default LeadsFilter;
