import DisposeFilters from "./components/dispose-filters";
import FinderFilters from "./components/finder-filters";
import { FF_PAGE_TYPES } from "../../../../utils/constants/values.constants";
const Filters = (props) => {
	return (
		<div className="ops-filter">
			{props?.pageKey === FF_PAGE_TYPES.DISPOSE && (
				<DisposeFilters {...props} />
			)}
			{props?.pageKey === FF_PAGE_TYPES.FINDERS && <FinderFilters {...props} />}
		</div>
	);
};
export default Filters;
