import { challan_item_keys } from "../../components/challan-detail-revamp/viewModel";
import { challan_request_keys } from "../viewModels";

export const disposerSearchOptions = [
    {
        label: "Reg Number",
        value: challan_item_keys.REG_NUMBER,
    },
      {
        label: "Notice Number",
        value: challan_item_keys.NOTICE_NUMBER,
      },
    ];
  export const finderSearchOptions = [
    {
        label: "Reg Number",
        value: challan_request_keys.REG_NUMBER,
      },
      {
        label: "Appointment Id",
        value: challan_request_keys.APPOINTMENT_ORDER_ID,
      },
    ];