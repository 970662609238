
import React, { useEffect, useState } from "react";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
const AppMultiSelect = ({data = [],allowCustom = false,filterChange,value,textField='label',
dataItemKey= 'value',
placeholder='Start typing to search or select from drop down',
autoClose=false,
popupClass="",
filterable=false}) => {
	
	const [serchedData,setSearchedData] = useState(data);
	useEffect(()=>{
		setSearchedData([...data]);
	},[data])
	const itemRender = (li, itemProps) => {
		const itemChildren = (
			<span>
				<input
					type="checkbox"
					name={itemProps.dataItem}
					checked={itemProps.selected}
					onChange={(e) =>{e.stopPropagation(); e.preventDefault(); itemProps.onClick(itemProps.index, e)}}
				/>
				&nbsp;{li.props.children}
			</span>
		);
		return React.cloneElement(li, li.props, itemChildren);
	};

	const filterSearchChange = (event) => {
		let fil = [...data].filter(ele=>(ele[textField])?.toLowerCase().includes(event.filter?.value?.toLowerCase()));
		setSearchedData([...fil]);
	  };
	return (
		<>
		<MultiSelect
              allowCustom={allowCustom}
                data={serchedData}
                className={`app-multi-select`}
                onChange={(e)=>{filterChange(e)}}
                value={value}
                textField={textField}
                dataItemKey={dataItemKey}
                placeholder={placeholder}
                defaultValue={''}
                // style={{
                //   width: '320px',
                //   minHeight: '40px'
                // }}
				popupSettings={{className:popupClass}}
                autoClose={autoClose}
                filterable={filterable}
                // onFilterChange={(e) => {
                // //   filterValues(e.filter.value)
				// filterChange(e)
                // }}
				itemRender={itemRender}
				 onFilterChange={filterSearchChange}
				// opened={true}
              />
		</>
	);
};
export default AppMultiSelect;
