import React, { useState, useEffect, useCallback } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
	FF_PAGE_TYPES,
	SORT_DIRECTION_KEYS,
	NUMBERS,
} from "../../../utils/constants/values.constants";
import { USER_ROLE_KEYS } from "../../../utils/constants/menu.constants";
import styles from "../assets/style/ff-layout.module.css";
import SearchBarLayout from "../components/layout-search-bar";
import ActionBarLayout from "../components/layout-action-bar";
import TabGridLayout from "../components/layout-tab-grid";
import { ServiceHistory } from "../../../services";
import {
	service_history_column_keys,
	getSelectedAssignToMeRowIds,
	SERVICE_HISTORY_STATUSES,
} from "../service-history/viewModels";
import { service_history_columns, DD_DATA_SOURCES } from "./viewModels";
import {
	showToastMessage,
	isObjectEmpty,
	convertObjectToParams,
	// getUserRoles,
	getArrayItemValueByLabel,
	isFilterFieldPresent,
	getUserFirstNameFromEmail,
	getFFUserType,
} from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-data-query";
import { fetchServiceHistoryList } from "../../../store/actions/service-history";
import { ColumnMenuCheckboxFilter } from "../../../components/custom-grid-filters";
import {
	updateServiceHistoryAssignmentTypeParams,
	resetServiceHistoryItems,
} from "../../../store/actions/service-history";
import ServiceHistoryDetailContainer from "./service-history-detail-container";
import srcEditImg from "../../../assets/img/edit-ico.svg";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import AssignAgentPopup from "./assign-agent-popup";
import { Button } from "@progress/kendo-react-buttons";
const idGetter = getter(service_history_column_keys.ID);
const ALLOWED_SORT_PARAMS = [
	service_history_column_keys.AGEING,
	service_history_column_keys.CREATION_DATE,
];
const initialPagination = {
	pageNumber: 0,
	pageSize: 50,
};
const initialSort = [
	{
		field: service_history_column_keys.CREATION_DATE,
		dir: SORT_DIRECTION_KEYS.DESC,
	},
];
const initialFilter = {
	filters: [],
	logic: "and",
};
const ServiceHistoryContainer = (props = {}) => {
	let loadKey = "";
	const pageType = FF_PAGE_TYPES.SERVICE_HISTORY;
	const userType = getFFUserType();
	const [selectedState, setSelectedState] = useState({});
	const [paginationParams, setPaginationParams] = useState(initialPagination);
	const [filterParams, setFilterParams] = useState({ isAssigned: 0 });
	const [visibleColumns, setVisibleColumns] = useState(service_history_columns);
	const [sortParms, setSortParms] = useState(initialSort);
	const [gridFilters, setGridFilters] = useState(initialFilter);
	const [sources, setSources] = useState(DD_DATA_SOURCES);
	const [isDataExport, setIsDataExport] = useState(false);
	const [serviceHistoryRow, setServiceHistoryRow] = useState();
	const [isOpenAgentPopup, setIsOpenAgentPopup] = useState(false);
	const [agentPopupData, setAgentPopupData] = useState();
	const dispatch = useDispatch();
	const serviceHistory = useSelector((state) => state.serviceHistory);
	const {
		isLoading,
		data,
		filters,
		searchQuery,
		userAssignments,
		totalCount = 0,
	} = serviceHistory;

	const reloadServiceHistoryItems = () => {
		loadKey = "";
		fetchServiceHistoryItems("reload");
	};
	const onExportDataStart = () => {
		setIsDataExport(true);
	};

	const onExportDataEnd = () => {
		setIsDataExport(false);
	};
	const sortChange = (e) => {
		let sortData = !!e?.sort.length ? e?.sort : initialSort;
		let sortKey = sortData[0]?.field;
		let sortDir = sortData[0]?.dir;
		if (!e?.sort?.length) {
			sortDir =
				sortParms[0].dir === SORT_DIRECTION_KEYS.DESC
					? SORT_DIRECTION_KEYS.ASC
					: SORT_DIRECTION_KEYS.DESC;
		}
		if (ALLOWED_SORT_PARAMS.includes(sortKey)) {
			const params = [
				{
					field: sortKey,
					dir: sortDir,
				},
			];
			setSortParms([{ field: sortData[0].field, dir: sortDir }]);
			fetchServiceHistoryItems("sort", params);
		}
	};

	const pageChange = ({ page = 0, size = 50 }) => {
		setPaginationParams({ pageNumber: page, pageSize: size });
	};

	const getServiceHistoryItems = (sort) => {
		const params = { ...paginationParams, ...filterParams };
		let qsParams = convertObjectToParams(params);
		if (!!sort.length) {
			qsParams = `${qsParams}&sortBy=${sort[0].field}&sortDir=${sort[0].dir}`;
		}
		dispatch(fetchServiceHistoryList(qsParams));
	};
	const fetchServiceHistoryItems = (key, sort = []) => {
		if (!loadKey && !isLoading) {
			loadKey = key;
			getServiceHistoryItems(sort);
		}
	};

	const onAssignItems = () => {
		const items = getSelectedAssignToMeRowIds(selectedState, data);
		if (!!items.length) {
			ServiceHistory.assignMeServiceHistory(items)
				.then((resp) => {
					showToastMessage("Assigned successfully");
					setSelectedState({});
				})
				.catch((err) => {
					showToastMessage(err?.message, false);
				})
				.finally(() => {
					reloadServiceHistoryItems();
				});
		} else {
			showToastMessage(
				"Please select atleast one unassigned row to continue",
				false
			);
		}
	};
	const onRowSelectionChange = useCallback(
		(event) => {
			const newSelectedState = getSelectedState({
				event,
				selectedState: selectedState,
				dataItemKey: service_history_column_keys.ID,
			});
			setSelectedState(newSelectedState);
		},
		[selectedState]
	);
	const onHeaderSelectionChange = useCallback((event) => {
		const checkboxElement = event.syntheticEvent.target;
		const checked = checkboxElement.checked;
		const newSelectedState = {};
		event.dataItems.forEach((item) => {
			newSelectedState[idGetter(item)] = checked;
			item["selected"] = checked;
		});
		setSelectedState(newSelectedState);
	}, []);

  const handleFilterButtonClick = React.useCallback(
    (key, e, data, assign) => {
      if (!!e) {
        setGridFilters(e);
        setPaginationParams(initialPagination);
        let _filterParamsData = { ...filterParams };
        const _key =
          key === service_history_column_keys.CAR_DETAILS
            ? service_history_column_keys.YEAR
            : key;
        delete _filterParamsData.isAssigned;
        delete _filterParamsData[_key];
        if (userAssignments !== NUMBERS.FOUR) {
          _filterParamsData = {
            ..._filterParamsData,
            isAssigned: userAssignments,
          };
        }
        if (
          !!searchQuery &&
          userType !== USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_VIEW
        ) {
          _filterParamsData = {
            ..._filterParamsData,
            [searchQuery?.selectedOption]: searchQuery?.value,
          };
        }
        if (
          userType === USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_VIEW &&
          !!searchQuery
        ) {
          _filterParamsData = {
            ..._filterParamsData,
            [service_history_column_keys.REG_NUMBER]: searchQuery?.value,
          };
          delete _filterParamsData.isAssigned;
        }
        e?.filters.map((item) => {
          const updatedFilter = item?.filters.reduce((acc, cur) => {
            const itemKey =
              cur.field === service_history_column_keys.CAR_DETAILS
                ? service_history_column_keys.YEAR
                : cur.field;
            const existingItem = acc?.find(
              (transformedItem) => transformedItem[itemKey]
            );

            const itemValue = [
              service_history_column_keys.CAR_DETAILS,
            ].includes(cur.field)
              ? cur.value
              : getArrayItemValueByLabel(data[cur.field], cur.value);
            if (existingItem) {
              existingItem[itemKey] += `,${itemValue}`;
            } else {
              acc.push({ [itemKey]: itemValue });
            }
            return acc;
          }, []);

					const filterParamsData = updatedFilter.reduce(
						(result, obj) => ({ ...result, ...obj }),
						{}
					);
					_filterParamsData = { ..._filterParamsData, ...filterParamsData };
				});
				setFilterParams(_filterParamsData);
			}
		},
		[userAssignments, filterParams, searchQuery]
	);
	// grid cell customization
	const ApptIdCell = (props) => {
		const { dataItem } = props || {};
		return (
			<td
				title={dataItem[service_history_column_keys.APPOINTMENT_ORDER_NUMBER]}
				className="k-table-td ">
				{
					<span
						onClick={() => setServiceHistoryRow(dataItem)}
						style={{
							color: "#003E72",
							textDecoration: "underline",
							cursor: "pointer",
							wordBreak: "break-word",
							fontSize: "13px",
						}}>
						{dataItem[service_history_column_keys.APPOINTMENT_ORDER_NUMBER]}
					</span>
				}
			</td>
		);
	};
	const handleOpenDialog = () => {
		setIsOpenAgentPopup(!isOpenAgentPopup);
	};

	const handleAdminAssign = (data) => {
		const { agent } = data;
		setAgentPopupData({
			rowData: data,
			selectedAgent: { label: agent, value: agent },
		});
		handleOpenDialog();
	};

	const AssignedCell = (event) => {
		const { agent = "", status } = event.dataItem;
		const agentFirstName = getUserFirstNameFromEmail(agent);
		if (!!agent) {
			return (
				<td className="k-table-td" title={agent}>
					<p
						style={{
							wordWrap: "break-word",
							textAlign: "center",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							textTransform: "capitalize",
						}}>
						{agentFirstName}
						{userType === USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_ADMIN &&
							![
								SERVICE_HISTORY_STATUSES.FULFILLED,
								SERVICE_HISTORY_STATUSES.UNABLE_TO_FULFILL,
							].includes(status) && (
								<div className="assign-me-wrapper">
									<span
										style={{
											cursor: "pointer",
											color: "#0059A3",
											fontWeight: "700",
										}}
										onClick={() => handleAdminAssign(event.dataItem)}>
										<img alt="edit" src={srcEditImg} />
									</span>
								</div>
							)}
					</p>
				</td>
			);
		} else if (userType === USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_ADMIN) {
			return (
				<td>
					<div className="assign-me-wrapper">
						<span
							className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md m-2"
							onClick={() => handleAdminAssign(event.dataItem)}>
							Assign
						</span>
					</div>
				</td>
			);
		} else {
			return <td></td>;
		}
	};
	// end cell customization
	//page side effects
	useEffect(() => {
		setVisibleColumns((columns) => ({
			...columns,
			[service_history_column_keys.APPOINTMENT_ORDER_NUMBER]: {
				...columns[service_history_column_keys.APPOINTMENT_ORDER_NUMBER],
				cell: ApptIdCell,
			},
			[service_history_column_keys.AGENT]: {
				...columns[service_history_column_keys.AGENT],
				cell: AssignedCell,
			},
		}));
		return () => {
			dispatch(resetServiceHistoryItems());
		};
	}, []);
	useEffect(() => {
		setVisibleColumns((columns) => ({
			...columns,
			[service_history_column_keys.STATUS]: {
				...columns[service_history_column_keys.STATUS],
				headerClassName: isFilterFieldPresent(
					gridFilters?.filters,
					service_history_column_keys.STATUS
				)
					? "selected-filter"
					: "de-selected-filter",
				columnMenu: (props) => {
					return (
						<ColumnMenuCheckboxFilter
							{...props}
							filterData={sources}
							userAssignments={userAssignments}
							onFilterButtonClick={handleFilterButtonClick}
							columnMapKey={service_history_column_keys.STATUS}
							filter={gridFilters}
						/>
					);
				},
			},
			[service_history_column_keys.CAR_DETAILS]: {
				...columns[service_history_column_keys.CAR_DETAILS],
				headerClassName: isFilterFieldPresent(
					gridFilters?.filters,
					service_history_column_keys.CAR_DETAILS
				)
					? "selected-filter"
					: "de-selected-filter",
				columnMenu: (props) => {
					return (
						<ColumnMenuCheckboxFilter
							{...props}
							filterData={sources}
							userAssignments={userAssignments}
							onFilterButtonClick={handleFilterButtonClick}
							columnMapKey={service_history_column_keys.CAR_DETAILS}
							filter={gridFilters}
						/>
					);
				},
			},
			[service_history_column_keys.SOURCE]: {
				...columns[service_history_column_keys.SOURCE],
				headerClassName: isFilterFieldPresent(
					gridFilters?.filters,
					service_history_column_keys.SOURCE
				)
					? "selected-filter"
					: "de-selected-filter",
				columnMenu: (props) => {
					return (
						<ColumnMenuCheckboxFilter
							{...props}
							filterData={sources}
							userAssignments={userAssignments}
							onFilterButtonClick={handleFilterButtonClick}
							columnMapKey={service_history_column_keys.SOURCE}
							filter={gridFilters}
						/>
					);
				},
			},
		}));
	}, [sources, gridFilters, searchQuery]);

	useEffect(() => {
		dispatch(updateServiceHistoryAssignmentTypeParams(NUMBERS.ZERO));
		setGridFilters(initialFilter);
		if (!!searchQuery) {
			setFilterParams({ [searchQuery?.selectedOption]: searchQuery?.value });
		} else if (userType === USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_VIEW) {
			setFilterParams({});
		} else {
			setFilterParams({ isAssigned: 0 });
		}
	}, [searchQuery]);

	useEffect(() => {
		fetchServiceHistoryItems("filterParams");
	}, [filterParams]);

	useEffect(() => {
		if (!isObjectEmpty(paginationParams)) {
			fetchServiceHistoryItems("paginationParams");
		}
	}, [paginationParams]);

	useEffect(() => {
		let params = { ...filterParams };
		params = {
			...params,
			isAssigned: userAssignments,
		};
		setFilterParams(params);
	}, [userAssignments]);
	// end of side effects
	const onBackClick = () => {
		setServiceHistoryRow();
	};
	const onResetAssignedAgent = (e) => {
		const { rowData: { agent = "" } = {} } = agentPopupData;
		setAgentPopupData((data) => ({
			...data,
			selectedAgent: { label: agent, value: agent },
		}));
	};
	const onConfirmAssignedAgent = (e) => {
		const {
			rowData: { idServiceHistoryRequest, agent } = {},
			selectedAgent: { value } = {},
		} = agentPopupData || {};
		if (!!value && value !== agent && !!idServiceHistoryRequest) {
			ServiceHistory.adminAssignServiceHistory({
				idServiceHistory: idServiceHistoryRequest,
				assignTo: value,
			})
				.then((resp) => {
					console.log({ resp });
					showToastMessage("Assigned successfully");
				})
				.catch((err) => showToastMessage(err?.message, false))
				.finally(() => {
					reloadServiceHistoryItems();
					handleOpenDialog();
				});
		}
	};
	return (
		<div className="ops-challan-container">
			<div className="ops-grid-layout-container">
				{!!serviceHistoryRow ? (
					<ServiceHistoryDetailContainer
						rowData={serviceHistoryRow}
						onBackClick={onBackClick}
					/>
				) : (
					<>
						<GridLayout
							gap={{
								rows: "0px",
								cols: 10,
							}}>
							<GridLayoutItem
								row={1}
								style={{
									display: "flex",
									justifyContent: "space-between",
								}}>
								<div className={styles.listing_header}>Service History</div>
								<SearchBarLayout type={pageType} />
							</GridLayoutItem>
						</GridLayout>
						<GridLayoutItem
							row={2}
							style={{
								display: "flex",
								justifyContent: "flex-end",
								alignItems: "center",
							}}>
							<ActionBarLayout
								type={pageType}
								AssignToMe={onAssignItems}
								isDataExport={isDataExport}
								onExportDataStart={onExportDataStart}
							/>
						</GridLayoutItem>
						<GridLayoutItem row={3}>
							<TabGridLayout
								isLoading={isLoading}
								type={pageType}
								columns={visibleColumns}
								data={data?.map((item) => ({
									...item,
									selected: selectedState[idGetter(item)],
								}))}
								count={totalCount}
								selectable={true}
								selectedField="selected"
								idField={service_history_column_keys.ID}
								onRowSelectionChange={onRowSelectionChange}
								onHeaderSelectionChange={onHeaderSelectionChange}
								isPagination={true}
								pageChange={pageChange}
								paginationParams={paginationParams}
								sortable={true}
								sortParms={sortParms}
								sortChange={sortChange}
								isDataExport={isDataExport}
								onExportDataEnd={onExportDataEnd}
								userAssignments={userAssignments}
							/>
						</GridLayoutItem>
						{isOpenAgentPopup && (
							<Dialog
								title={
									<p>
										<div
											style={{
												cursor: "pointer",
												color: "#0059A3",
												fontWeight: "700",
												fontSize: "18px",
											}}>
											Assign Agent
										</div>
										<div>Select user below to assign the Service History</div>
									</p>
								}
								onClose={handleOpenDialog}>
								<AssignAgentPopup
									agentPopupData={agentPopupData}
									setAgentPopupData={setAgentPopupData}
								/>
								<DialogActionsBar>
									<Button
										className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
										onClick={onResetAssignedAgent}>
										Reset
									</Button>
									<Button
										className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
										onClick={onConfirmAssignedAgent}
										themeColor={"primary"}>
										Confirm
									</Button>
								</DialogActionsBar>
							</Dialog>
						)}
					</>
				)}
			</div>
		</div>
	);
};
export default ServiceHistoryContainer;
