import React from 'react';
import styles from './styles.module.css'
import { Button } from '@progress/kendo-react-buttons';

const Modal = ({ isOpen, close, heading, children }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal} >
        <div className={styles.modalHeader}>
          <h1>{heading}</h1>
          <Button fillMode="flat" onClick={close}>X</Button>
        </div>
        <div className={styles.modalBody}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
