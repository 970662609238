import ClusterMapper from "./components/cluster-mapper";
import DateTimeMapper from "./components/date-time-mapper";
import FileLoader from "./components/file-url";
import RichTextVieweer from './components/rich-text-viewer';

export const CJ_ANNOUNCEMENT_KEYS = {
    TITLE: "title",
    MESSAGE: "message",
    TRIGGER_DATE_AND_TIME: "triggerDateTime",
    EXPIRY_DATE_AND_TIME: "expiryDateTime",
    CLUSTER: "cluster",
    FILE_URL: "uploadedFileUrl",
    RECURRING_TIME: "recurringTime",
    ACTION: "action",
};

export const ANNOUNCEMENT_COLUMN_MAPPER = {
    [CJ_ANNOUNCEMENT_KEYS.TITLE]: {
        field: CJ_ANNOUNCEMENT_KEYS.TITLE,
        label: "Title",
    },
    [CJ_ANNOUNCEMENT_KEYS.MESSAGE]: {
        field: CJ_ANNOUNCEMENT_KEYS.MESSAGE,
        label: "Message",
        cell: RichTextVieweer,
    },
    [CJ_ANNOUNCEMENT_KEYS.TRIGGER_DATE_AND_TIME]: {
        field: CJ_ANNOUNCEMENT_KEYS.TRIGGER_DATE_AND_TIME,
        label: "Trigger Date & Time",
        cell: DateTimeMapper,
    },
    [CJ_ANNOUNCEMENT_KEYS.EXPIRY_DATE_AND_TIME]: {
        field: CJ_ANNOUNCEMENT_KEYS.EXPIRY_DATE_AND_TIME,
        label: "Expiry Date & Time",
        cell: DateTimeMapper,
    },
    [CJ_ANNOUNCEMENT_KEYS.CLUSTER]: {
        field: CJ_ANNOUNCEMENT_KEYS.CLUSTER,
        label: "Cluster",
        cell: ClusterMapper
    },
    [CJ_ANNOUNCEMENT_KEYS.FILE_URL]: {
        field: CJ_ANNOUNCEMENT_KEYS.FILE_URL,
        label: "Uploaded File",
        cell: FileLoader,
    },
    [CJ_ANNOUNCEMENT_KEYS.ACTION]: {
        field: CJ_ANNOUNCEMENT_KEYS.ACTION,
        label: "Action",
    }
}