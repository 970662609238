import React from 'react';
import { Field, ErrorMessage } from 'formik';
import LabelComponent from '../label';
import styles from '../../style.module.css';
import { FormComboBox } from '../../../../../../../components';

const ComboBoxComponent = ({ name, label, data }) => (
    <div className={styles.mb30}>
        <LabelComponent title={label} />
        <Field name={name}>
            {({ field, form }) => {
                return (
                    <FormComboBox
                        data={data}
                        textField="label"
                        dataItemKey="value"
                        value={field.value}
                        filterable={false}
                        onChange={(e) => { form.setFieldValue(field.name, e.target.value) }}
                    />
                )
            }}
        </Field>
        <ErrorMessage name={name} component="div" className={styles.error} />
    </div>
);

export default ComboBoxComponent;
