import React, { useEffect, useState } from "react";
import { FormData, centreColumns, getDisplayedCoulmns } from "./viewModal";
import { useDispatch, useSelector } from "react-redux";
import CustomGrid from "../../../../common/custom-grid/component";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "@progress/kendo-react-buttons";
import {
  fetchDealerCentre,
  updateDealer,
} from "../../../../store/actions/dealer-procurement";
import {
  // FormDatePicker,
  FormComboBox,
  FormInput,
  // FormTextArea,
} from "../../../../components";
// import { Loader } from "../../../../common/Loader";
import { FORM_ELEMENT_TYPE } from "../../../../utils/constants/values.constants";
import { DealerProcureService } from "../../../../services";
// import { showToastMessage } from "../../../../utils/utils";

const CentreDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.Centre) || {};
  const { data: dealers } =
    useSelector((state) => state.dealerProcurement) || {};

  const search = window.location.href;
  const dealerId = search.split("centerdetailview/")[1];

  const [selectedDealer, setSelectedDealer] = useState(
    dealers?.filter((item) => item?.dealerId == dealerId)[0] || {}
  );

  useEffect(() => {
    if (!dealers?.length) {
      DealerProcureService.fetchDealerById(dealerId)
        .then((res) => setSelectedDealer(res?.data))
        .catch((err) => console.log({ err }));
    }
    dispatch(fetchDealerCentre(dealerId));
  }, [dealerId]);

  const handleCreateCentre = () => {
    history.push(`/dealer/fulfillments/createDealerCenter/${dealerId}`);
  };
  const handleFormChange = (e) => {
    const { id } = e?.target?.props || {};
    const { value } = e || {};
    setSelectedDealer({
      ...selectedDealer,
      [id]: e?.target?.props?.elementtype === "SELECT" ? value?.value : value,
    });
  };

  const handleFormSave = () => {
    dispatch(updateDealer(dealerId, selectedDealer));
  };

  const handleOnBack = () => {
    history.push(`/dealer/fulfillments`);
  };

  return (
    <div>
      <div className="ops-challan-actions">
        <div className="k-form-buttons">
          <Button
            themeColor={"primary"}
            fillMode="outline"
            onClick={handleOnBack}
          >
            Back
          </Button>
        </div>
        <div className="k-form-buttons">
          <Button
            className="m-2"
            themeColor={"primary"}
            onClick={handleCreateCentre}
          >
            Create Centre
          </Button>
        </div>
      </div>
      <div className="middleContent">
        <div className="create-dealer-form">
          {Object.keys(FormData).map((key) => {
            const elementMetaData = FormData[key];
            switch (elementMetaData.elementtype) {
              case FORM_ELEMENT_TYPE.SELECT:
                return (
                  <FormComboBox
                    id={key}
                    key={key}
                    onChange={handleFormChange}
                    data={elementMetaData.source}
                    textField="label"
                    dataItemKey="value"
                    value={elementMetaData?.source?.find(
                      (item) => item.value === selectedDealer[key]
                    )}
                    {...elementMetaData}
                  />
                );
              default:
                return (
                  <FormInput
                    id={key}
                    key={key}
                    onChange={handleFormChange}
                    value={selectedDealer[key]}
                    {...elementMetaData}
                  />
                );
            }
          })}
          <Button
            themeColor={"primary"}
            onClick={handleFormSave}
            className="m-3"
          >
            Update
          </Button>
        </div>
        <div className="centre-list">
          <CustomGrid
            title="Dealer Centre List"
            displayedColumns={getDisplayedCoulmns()}
            data={data || []}
            columnMap={centreColumns}
          />
        </div>
      </div>
    </div>
  );
};

export default CentreDetail;
