import { challan_item_keys } from "../challan-detail-revamp/viewModel";
import { FORM_ELEMENT_TYPE } from "../../../../utils/constants/values.constants";
import { ADMIN_DISPOSER_ASSIGNED_DATA } from "../../dispose-container/dispose-detail-container/viewModal";

export const FORM_TYPES = {
	CHALLAN_PAYMENT: "CHALLAN_PAYMENT",
	ASSIGN_AGENT: "ASSIGN_AGENT",
	REJECT_CHALLAN: "REJECT_CHALLAN",
	ADMIN_ASSIGN_AGENT_DISPOSE: "ADMIN_ASSIGN_AGENT_DISPOSE",
	ADMIN_ASSIGN_AGENT_FINDER: "ADMIN_ASSIGN_AGENT_FINDER",
	ADMIN_RE_ASSIGN_AGENT_DISPOSE: "ADMIN_RE_ASSIGN_AGENT_DISPOSE",
	ADMIN_BULK_ASSIGN_AGENT_DISPOSE: "ADMIN_BULK_ASSIGN_AGENT_DISPOSE",
};
export const PAYMENT_STATUSES = {
	PAID: "PAID",
	UNABLE_TO_FULFILL: "UNABLE_TO_FULFILL",
	VIRTUAL_COURT_PROCEEDING: "VC_PROCEEDING",
	RAISE_REJECTED: "RAISE_REJECTED",
	PAYMENT_RAISED: "PAYMENT_RAISED",
	RAISE_AMENDED: "RAISE_AMENDED",
	RAISE_ACCEPTED: "RAISE_ACCEPTED",
};

export const INIT_DD_DATA_SOURCES = {
	[challan_item_keys.REGION]: [],
	[challan_item_keys.CHALLAN_COURT]: [],
	[challan_item_keys.AGENT_NAME]: [],
	[challan_item_keys.CHALLAN_TYPE]: [
		{ label: "Mark as Paid", value: PAYMENT_STATUSES.PAID },
		{ label: "Unable to Fulfill", value: PAYMENT_STATUSES.UNABLE_TO_FULFILL },
	],
	[challan_item_keys.ASSIGN_TO]: [],
	[challan_item_keys.REMARKS]: [],
};

export const CHALLAN_PAYMENT_FORM_DATA = {
	[challan_item_keys.REG_NUMBER]: {
		name: challan_item_keys.REG_NUMBER,
		label: "Re-enter vehicle reg number",
		elementtype: FORM_ELEMENT_TYPE.INPUT_RIGHT_TICK,
		maxLength: 12,
		autocomplete: "false",
		value: "",
	},
	[challan_item_keys.CHALLAN_AMOUNT]: {
		name: challan_item_keys.CHALLAN_AMOUNT,
		label: "Challan Amount",
		elementtype: FORM_ELEMENT_TYPE.INPUT,
		disabled: true,
		value: null,
	},
	[challan_item_keys.CHALLAN_TYPE]: {
		name: challan_item_keys.CHALLAN_TYPE,
		label: "Challan Status",
		elementtype: FORM_ELEMENT_TYPE.SELECT,
		source: [],
		value: null,
		isDisplayLabel: true,
	},
	[challan_item_keys.AGENT_FESS]: {
		name: challan_item_keys.AGENT_FESS,
		label: "Agent Fees",
		elementtype: FORM_ELEMENT_TYPE.INPUT,
		type: "number",
		value: null,
		hide: true,
	},
	[challan_item_keys.SETTLEMENT_AMOUNT]: {
		name: challan_item_keys.SETTLEMENT_AMOUNT,
		label: "Government fee",
		elementtype: FORM_ELEMENT_TYPE.INPUT,
		type: "number",
		value: null,
		hide: true,
	},
	[challan_item_keys.TOTAL_PAYMENT]: {
		name: challan_item_keys.TOTAL_PAYMENT,
		label: "Total Payment",
		elementtype: FORM_ELEMENT_TYPE.INPUT,
		type: "number",
		value: null,
		disabled: true,
		hide: true,
	},
	[challan_item_keys.FILE_SINGLE_UPLOAD]: {
		name: challan_item_keys.FILE_SINGLE_UPLOAD,
		label: "Upload Challan clearance proof",
		elementtype: FORM_ELEMENT_TYPE.UPLOAD_FILE,
		accept: ".pdf, .png, .jpg",
		value: null,
		hide: true,
	},
	[challan_item_keys.GOVERNMENT_FEE_PROOF]: {
		name: challan_item_keys.GOVERNMENT_FEE_PROOF,
		label: "Upload Government Fee Receipt",
		elementtype: FORM_ELEMENT_TYPE.UPLOAD_FILE,
		accept: ".pdf, .png, .jpg",
		value: null,
		hide: true,
	},
	[challan_item_keys.REMARKS]: {
		name: challan_item_keys.REMARKS,
		label: "Reason for not fulfilling",
		elementtype: FORM_ELEMENT_TYPE.INPUT,
		maxLength: 200,
		value: null,
		hide: true,
	},
};

export const ASSIGN_AGENT_FORM_DATA = {
	[challan_item_keys.REGION]: {
		name: challan_item_keys.REGION,
		label: "Select Region for Disposal",
		elementtype: FORM_ELEMENT_TYPE.SELECT,
		source: [],
		value: null,
		isDisplayLabel: true,
	},
	[challan_item_keys.CHALLAN_COURT]: {
		name: challan_item_keys.CHALLAN_COURT,
		label: "Select Court for Disposal",
		elementtype: FORM_ELEMENT_TYPE.SELECT,
		source: [],
		value: null,
		isDisplayLabel: true,
	},
	[challan_item_keys.AGENT_NAME]: {
		name: challan_item_keys.AGENT_NAME,
		label: "Select Agent for Disposal",
		elementtype: FORM_ELEMENT_TYPE.SELECT,
		source: [],
		value: null,
		isDisplayLabel: true,
	},
};

export const REJECT_CHALLAN_FORM_DATA = {
	[challan_item_keys.REMARKS]: {
		name: challan_item_keys.REMARKS,
		label: "Reason for rejection",
		elementtype: FORM_ELEMENT_TYPE.SELECT,
		source: [],
		value: null,
	},
	[challan_item_keys.FILE_SINGLE_UPLOAD]: {
		name: challan_item_keys.FILE_SINGLE_UPLOAD,
		label: "Upload proof for rejection",
		elementtype: FORM_ELEMENT_TYPE.UPLOAD_FILE,
		accept: ".pdf, .png, .jpg",
	},
};

export const ADMIN_ASSIGN_AGENT_POPUP = {
	[challan_item_keys.APPOINTMENT_ORDER_ID]: {
		name: challan_item_keys.APPOINTMENT_ORDER_ID,
		label: "Appointment Number",
		elementtype: FORM_ELEMENT_TYPE.INPUT,
		disabled: true,
		value: null,
	},
	[challan_item_keys.REG_NUMBER]: {
		name: challan_item_keys.REG_NUMBER,
		label: "Vehicle Reg number",
		elementtype: FORM_ELEMENT_TYPE.INPUT_RIGHT_TICK,
		maxLength: 12,
		disabled: true,
		autocomplete: "false",
		value: "",
	},
	[challan_item_keys.SOURCE_CODE]: {
		name: challan_item_keys.SOURCE_CODE,
		label: "Source",
		elementtype: FORM_ELEMENT_TYPE.INPUT,
		disabled: true,
		value: null,
	},
	[challan_item_keys.ASSIGN_TO]: {
		name: challan_item_keys.ASSIGN_TO,
		label: "Select Assign To",
		elementtype: FORM_ELEMENT_TYPE.SELECT,
		source: [],
		value: null,
		isDisplayLabel: true,
	},
};

export const ADMIN_RE_ASSIGN_AGENT_POPUP = {
	[challan_item_keys.AGENT_TYPE]: {
		name: challan_item_keys.AGENT_TYPE,
		label: "Disposer Assigned",
		elementtype: FORM_ELEMENT_TYPE.RADIO,
		data: ADMIN_DISPOSER_ASSIGNED_DATA,
		value: null,
		isDisplayLabel: true,
	},
	[challan_item_keys.REGION]: {
		name: challan_item_keys.REGION,
		label: "Select Region for Disposal",
		elementtype: FORM_ELEMENT_TYPE.SELECT,
		source: [],
		value: null,
		isDisplayLabel: true,
		hide: true,
	},
	[challan_item_keys.CHALLAN_COURT]: {
		name: challan_item_keys.CHALLAN_COURT,
		label: "Select Court for Disposal",
		elementtype: FORM_ELEMENT_TYPE.SELECT,
		source: [],
		value: null,
		isDisplayLabel: true,
		hide: true,
	},
	[challan_item_keys.AGENT_NAME]: {
		name: challan_item_keys.AGENT_NAME,
		label: "Select Agent for Disposal",
		elementtype: FORM_ELEMENT_TYPE.SELECT,
		source: [],
		value: null,
		isDisplayLabel: true,
		hide: true,
	},
};

export const ADMIN_BULK_ASSIGNMENT_AGENT_POPUP = {
	[challan_item_keys.REGION]: {
		name: challan_item_keys.REGION,
		label: "Select Region for Disposal",
		elementtype: FORM_ELEMENT_TYPE.SELECT,
		source: [],
		value: null,
		isDisplayLabel: true,
	},
	[challan_item_keys.CHALLAN_COURT]: {
		name: challan_item_keys.CHALLAN_COURT,
		label: "Select Court for Disposal",
		elementtype: FORM_ELEMENT_TYPE.SELECT,
		source: [],
		value: null,
		isDisplayLabel: true,
	},
	[challan_item_keys.AGENT_NAME]: {
		name: challan_item_keys.AGENT_NAME,
		label: "Select Agent for Disposal",
		elementtype: FORM_ELEMENT_TYPE.SELECT,
		source: [],
		value: null,
		isDisplayLabel: true,
	},
};

export const calculateTotalPayment = (formData, key, value) => {
	const agentFees =
		parseFloat(formData[challan_item_keys.AGENT_FESS]?.value) || 0;
	const governmentFee =
		parseFloat(formData[challan_item_keys.SETTLEMENT_AMOUNT]?.value) || 0;
	const updatedValue = parseFloat(value);
	if (key === challan_item_keys.AGENT_FESS) {
		return governmentFee + updatedValue;
	} else {
		return agentFees + updatedValue;
	}
};
