export const defaultStyle = {
	fontSize: "12px",
	lineHeight: "18px",
	// alignItems: "center",
	paddingLeft: "0px",
	paddingRight: "0px",
};
export const ChargeItem = (props) => {
	let item = props.dataItem;
	return (
		<ul
			className="align-center"
			style={{
				listStyleType: "none",
				display: "flex",
				justifyContent: "space-between",
				paddingLeft: "0px",
			}}>
			<li style={{ display: "flex", flexDirection: "column", width: "80%" }}>
				<span style={{ ...defaultStyle, fontWeight: 400 }}>{item.details}</span>
				<p style={{ ...defaultStyle, fontWeight: 300 }}>{item?.remarks}</p>
			</li>
			<li
				style={{
					...{ ...defaultStyle, fontWeight: 400 },
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					alignItems: "flex-start",
				}}>
				{item.value}
			</li>
		</ul>
	);
};
