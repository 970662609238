import { useEffect } from 'react';

const useCopyTracker = (callback) => {
  useEffect(() => {
    const handleCopy = (event) => {
      const selection = document.getSelection().toString();
      if (selection) {
        callback(selection);
      }
    };

    document.addEventListener('copy', handleCopy);

    return () => {
      document.removeEventListener('copy', handleCopy);
    };
  }, [callback]);
};

export default useCopyTracker;
