import { useSelector } from "react-redux";
import { KendoCard, KendoListView } from "../../../../../../../components";
import { getBadge } from "../../../pr-automation/viewModel";
import { underscoreToTitleCase } from "../../../../../../../utils/utils";
import {
  rcConditionColorMapping,
  rcTypeColorMapping,
} from "../../../../../../../utils/constants/values.constants";

import styles from '../style.module.css'

const RcCard = () => {
  const prDetails = useSelector((state) => state.prAutomation);
  const { orderData } = prDetails || {};
  const { rcAvailability = "", rcCondition = "" } = orderData || {};
  const rcDetails = [
    {
      details: "RC type",
      value: rcAvailability
        ? underscoreToTitleCase(rcAvailability)
        : "NA",
    },
    {
      details: "RC condition",
      value: rcCondition
        ? underscoreToTitleCase(rcCondition)
        : "NA",
    },
  ];
  return (
    <div className={styles.head}>
      <div className={styles.head_title}>
        RC Details
      </div>
      <div className={`${styles['last']} ${styles['quote_container']}`}>
        <div className={styles['quote_container_data']}>
          <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
            {rcDetails?.[0]?.details}
          </span>
          <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
            {rcDetails?.[0]?.value}
          </span>
        </div>
        <div className={styles['quote_container_data']}>
          <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
            {rcDetails?.[1]?.details}
          </span>
          <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
            {rcDetails?.[1]?.value}
          </span>
        </div>
      </div>
    </div>
  );
};
export default RcCard;
