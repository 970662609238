import { TYPE_BREADCUMB } from "../types";

const INITIAL_STATE = {
  routes:[]
};

const BreadCumb = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPE_BREADCUMB.UPDATE:
      return {
        ...state,
        routes: [...state.routes,...action.payload],
      };
    case TYPE_BREADCUMB.CLEAR:
      return {
        ...state,
        routes: [],
      };
      case TYPE_BREADCUMB.RESET:
        return {
          ...state,
          routes: [...action.payload],
        };
    default:
      return state;
  }
};
export default BreadCumb;
