import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomGrid from "../../../common/custom-grid/component";
import LeadMonActions from "../components/leadmon-actions";
// import LeadMonFilters from "../components/leadmon-filters";
import {
  lead_mon_keys,
  lead_mon_columns,
  getDisplayedCoulmns,
  removeFilterItems,
} from "./viewModels";
import {
  convertObjectToParams,
  isObjectEmpty,
  showToastMessage,
} from "../../../utils/utils";
import {
  fetchLeadMonConfigs,
  fetchCityList,
  fetchMakers,
  fetchMakeModels,
  fetchMakeYears,
  fetchCityLocations,
  resetModels,
  resetYears,
  resetLocations,
  resetLeadMonConfigs,
} from "../../../store/actions/retail-consumer";
import {
  DropdownFilter,
  // TextboxFilter,
} from "../../../components/custom-grid-filters";
// import { getLeadMonData } from "./mock-data";
import { RetailService } from "../../../services";
const LeadMonView = () => {
  const dispatch = useDispatch();
  const leadMonConfigs = useSelector((state) => state.retailConsumers);
  let loadKey = "";
  const [gridfilters, setGridfilters] = React.useState([]);
  const gridfiltersRef = React.useRef(gridfilters);
  const [visibleColumns, setVisibleColumns] = useState(lead_mon_columns);
  const [paginationParams, setPaginationParams] = useState({
    pageNumber: 0,
    pageSize: 10,
  });
  const [filterParams, setFilterParams] = useState({});
  const {
    isLoading,
    leadMon: { data, totalCount },
    cities = [],
    locations = [],
    makers = [],
    models = [],
    years = [],
  } = leadMonConfigs;
  const pageChange = ({ page = 0, size = 10 }) => {
    setPaginationParams({ pageNumber: page, pageSize: size });
  };
  const onMarkStatus = (id, status) => {
    const params = {
      status: status === "Active" ? "Inactive" : "Active",
    };
    RetailService.markStatus(id, params)
      .then((resp) => {
        showToastMessage(resp?.message);
        fetchLeadMons("reload");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const ActiveCell = (event) => {
    const { status = "", id } = event.dataItem;
    return (
      <td>
        <div className="assign-me-wrapper">
          <span
            className={
              status === "Active"
                ? `k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md m-2`
                : `k-button k-button-md k-button-solid  k-rounded-md m-2`
            }
            onClick={() => onMarkStatus(id, status)}
          >
            {`Change to ${status === "Active" ? "Inactive" : "Active"}`}
          </span>
        </div>
      </td>
    );
  };
  const handleFilterButtonClick = (e) => {
    const { key: name, value } = e || {};
    const filters = gridfiltersRef?.current?.filters;
    let params = {};
    let updatedFilters = [];
    filters?.forEach((element) => {
      const key = visibleColumns[element.field].keyid;
      params = { ...params, [key]: element.value };
    });
    const key = visibleColumns[name].keyid;
    if (!!value) {
      if (key === lead_mon_keys.CITY_ID) {
        dispatch(fetchCityLocations(value));
      } else if (key === lead_mon_keys.MAKE_ID) {
        dispatch(fetchMakeModels(value));
        dispatch(fetchMakeYears(value));
      }
    } else {
      delete params[key];
      if (key === lead_mon_keys.CITY_ID) {
        dispatch(resetLocations());
        updatedFilters = removeFilterItems(filters, [
          lead_mon_keys.LOCATION_NAME,
        ]);
        delete params[lead_mon_keys.LOCATION_NAME];
        setGridfilters((items) => ({ ...items, filters: updatedFilters }));
      } else if (key === lead_mon_keys.MAKE_ID) {
        updatedFilters = removeFilterItems(filters, [
          lead_mon_keys.MODEL_NAME,
          lead_mon_keys.YEAR,
        ]);
        delete params[lead_mon_keys.MODEL_ID];
        delete params[lead_mon_keys.YEAR];
        dispatch(resetModels());
        dispatch(resetYears());
        setGridfilters((items) => ({ ...items, filters: updatedFilters }));
      }
    }
    setFilterParams(params);
  };
  const fetchLeadMons = (key) => {
    if (
      !loadKey &&
      !isLoading &&
      !isObjectEmpty(filterParams) &&
      !!filterParams[lead_mon_keys.CITY_ID] &&
      !!filterParams[lead_mon_keys.LOCATION_ID]
    ) {
      loadKey = key;
      const params = {
        page: paginationParams.pageNumber,
        size: paginationParams.pageSize,
        ...filterParams,
      };
      const qsParams = convertObjectToParams(params);
      dispatch(
        fetchLeadMonConfigs(
          filterParams[lead_mon_keys.CITY_ID],
          filterParams[lead_mon_keys.LOCATION_ID],
          qsParams
        )
      );
    }
  };
  useEffect(() => {
    !makers?.length && dispatch(fetchMakers());
    !cities?.length && dispatch(fetchCityList());
  }, []);

  useEffect(() => {
    const cityList =
      !!cities.length &&
      cities.map((item) => {
        return {
          label: item.city_name,
          value: item.city_id,
        };
      });
    setVisibleColumns((columns) => ({
      ...columns,
      [lead_mon_keys.CITY_NAME]: {
        ...columns[lead_mon_keys.CITY_NAME],
        filterCell: (props) => (
          <DropdownFilter
            {...props}
            data={cityList || []}
            onFilterButtonClick={handleFilterButtonClick}
          />
        ),
      },
      [lead_mon_keys.STATUS]: {
        ...columns[lead_mon_keys.STATUS],
        cell: ActiveCell,
        filterCell: (props) => (
          <DropdownFilter
            {...props}
            data={[
              { label: "Active", value: "Active" },
              { label: "Inactive", value: "Inactive" },
            ]}
            onFilterButtonClick={handleFilterButtonClick}
          />
        ),
      },
      // [lead_mon_keys.IS_LEAD_MON]: {
      //   ...columns[lead_mon_keys.IS_LEAD_MON],
      //   filterCell: (props) => (
      //     <DropdownFilter
      //       {...props}
      //       data={[
      //         { label: "Lead Mon", value: 1 },
      //         { label: "Not Lead Mon", value: 0 },
      //       ]}
      //       onFilterButtonClick={handleFilterButtonClick}
      //     />
      //   ),
      // },
      // [lead_mon_keys.IS_LUXURY_CAR]: {
      //   ...columns[lead_mon_keys.IS_LUXURY_CAR],
      //   filterCell: (props) => (
      //     <DropdownFilter
      //       {...props}
      //       data={[
      //         { label: "Luxury", value: 1 },
      //         { label: "Not Luxury", value: 0 },
      //       ]}
      //       onFilterButtonClick={handleFilterButtonClick}
      //     />
      //   ),
      // },
    }));
  }, [cities]);

  useEffect(() => {
    const makeList = makers.map((item) => {
      return {
        label: item.make_display,
        value: item.make_id,
      };
    });
    setVisibleColumns((columns) => ({
      ...columns,
      [lead_mon_keys.MAKER_NAME]: {
        ...columns[lead_mon_keys.MAKER_NAME],
        filterCell: (props) => (
          <DropdownFilter
            {...props}
            data={makeList}
            onFilterButtonClick={handleFilterButtonClick}
          />
        ),
      },
    }));
  }, [makers]);

  useEffect(() => {
    const modelList = models.map((item) => {
      return {
        label: item.model_display,
        value: item.model_id,
      };
    });
    setVisibleColumns((columns) => ({
      ...columns,
      [lead_mon_keys.MODEL_NAME]: {
        ...columns[lead_mon_keys.MODEL_NAME],
        filterCell: (props) => (
          <DropdownFilter
            {...props}
            data={modelList}
            onFilterButtonClick={handleFilterButtonClick}
          />
        ),
      },
    }));
  }, [models]);

  useEffect(() => {
    const yearList = years.map((item) => {
      return {
        label: item.year,
        value: item.year,
      };
    });
    setVisibleColumns((columns) => ({
      ...columns,
      [lead_mon_keys.YEAR]: {
        ...columns[lead_mon_keys.YEAR],
        filterCell: (props) => (
          <DropdownFilter
            {...props}
            data={yearList}
            onFilterButtonClick={handleFilterButtonClick}
          />
        ),
      },
    }));
  }, [years]);

  useEffect(() => {
    const stores = locations.map((item) => {
      const { locationId, name } = item;
      return {
        label: name,
        value: locationId,
      };
    });
    setVisibleColumns((columns) => ({
      ...columns,
      [lead_mon_keys.LOCATION_NAME]: {
        ...columns[lead_mon_keys.LOCATION_NAME],
        filterCell: (props) => (
          <DropdownFilter
            {...props}
            data={stores}
            onFilterButtonClick={handleFilterButtonClick}
          />
        ),
      },
    }));
  }, [locations]);

  useEffect(() => {
    if (
      !isObjectEmpty(filterParams) &&
      !!filterParams[lead_mon_keys.CITY_ID] &&
      !!filterParams[lead_mon_keys.LOCATION_ID]
    ) {
      fetchLeadMons("filterParams");
    } else {
      dispatch(resetLeadMonConfigs());
    }
  }, [filterParams]);

  useEffect(() => {
    if (!isObjectEmpty(paginationParams)) {
      fetchLeadMons("paginationParams");
    }
  }, [paginationParams]);

  const updateFilterValues = (e) => {
    gridfiltersRef.current = e?.filter;
    setGridfilters(e?.filter);
  };
  return (
    <>
      <div className="ops-channel-page-bar">
        <LeadMonActions />
      </div>
      <div className="middleContent">
        <CustomGrid
          displayedColumns={getDisplayedCoulmns(visibleColumns)}
          columnMap={visibleColumns}
          data={data}
          count={totalCount}
          pageChange={pageChange}
          paginationParams={paginationParams}
          isPagination={true}
          filterable={true}
          filter={gridfilters}
          onFilterChange={updateFilterValues}
        />
      </div>
    </>
  );
};
export default LeadMonView;
