import { Button } from "@progress/kendo-react-buttons";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import { FORM_ELEMENT_TYPE } from "../../../utils/constants/values.constants";

export const verification_column_keys = {
  ID: "idChallanVerification",
  APPOINTMENT_ORDER_ID: "appointmentOrderId",
  REG_NUMBER: "regNumber",
  STATUS: "status",
  RAISED_BY: "createdBy",
  VERIFIED_BY: "assignedTo",
  SOURCE: "sourceCode",
  CREATION_DATE: "createdAt",
  CLOSURE_DATE: "closureDate",
  CHALLAN_AMOUNT: "amount",
  AGENT: "agent",
  CHALLAN_PAYMENT: "challanPayment",
  YEAR: "year",
  FILTER_DATE: "date",
  CHALLAN_MODE: "type",
  AGENT_FESS: "agentFees",
  SETTLEMENT_AMOUNT: "settlementAmount",
  FILE_SINGLE_UPLOAD: "file",
  GOVERNMENT_FEE_PROOF: "governmentFeeProof",
  EXCEPTION_PROOF: "expectionProof",
  REJECTION_REASON: "rejectionReason",
  VERIFICATION_STATUS: "verificationStatus",
  VERIFICATION_REJECTION_REASON: "verificationRejectionReason",
  VERIFICATION_AGENT: "verificationAgent",
};

export const AgentCell = (props) => {
  const { dataItem } = props || {};
  return (
    <td className="k-table-td ">
      {dataItem?.status == "UNASSIGNED" ? (
        <Button className="m-2" themeColor={"primary"} onClick={() => {}}>
          Assign to Me
        </Button>
      ) : (
        <span>{dataItem?.agent}</span>
      )}
    </td>
  );
};

export const verificationColumns = {
  [verification_column_keys.APPOINTMENT_ORDER_ID]: {
    field: verification_column_keys.APPOINTMENT_ORDER_ID,
    label: "Identifier",
  },
  [verification_column_keys.REG_NUMBER]: {
    field: verification_column_keys.REG_NUMBER,
    label: "Reg No.",
  },
  [verification_column_keys.STATUS]: {
    field: verification_column_keys.STATUS,
    label: "Status",
  },
  [verification_column_keys.SOURCE]: {
    field: verification_column_keys.SOURCE,
    label: "Source",
  },
  [verification_column_keys.RAISED_BY]: {
    field: verification_column_keys.RAISED_BY,
    label: "Raised By",
  },
  [verification_column_keys.VERIFIED_BY]: {
    field: verification_column_keys.VERIFIED_BY,
    label: "Verified By",
  },
  [verification_column_keys.CREATION_DATE]: {
    field: verification_column_keys.CREATION_DATE,
    label: "Creation Date",
  },
  [verification_column_keys.CLOSURE_DATE]: {
    field: verification_column_keys.CLOSURE_DATE,
    label: "Closure Date",
  },
};

export const getDisplayedCoulmns = (cols) => {
  return Object.keys(cols);
};

export const getSelectedAssignToMeRowIds = (rows, data) => {
  const Ids = Object.keys(rows).filter((key) => {
    const state = rows[key];
    const row = data.find(
      (item) => item.idChallanVerification === parseInt(key)
    );
    if (
      (state && row?.status.toUpperCase() === "UNASSIGNED") ||
      row?.status.toUpperCase() === "CREATED" ||
      row?.status.toUpperCase() === "AMENDED"
    ) {
      return key;
    }
  });
  return Ids;
};

const getYears = () => {
  const currentYear = new Date().getFullYear();
  let years = [];
  for (let i = 0; i <= 15; i++) {
    const yearString = (currentYear - i).toString();
    years.push({ label: yearString, value: yearString });
  }
  return years;
};

export const DD_DATA_SOURCES = {
  [verification_column_keys.STATUS]: [
    { label: "Assigned", value: "ASSIGNED" },
    { label: "Unassigned", value: "CREATED" },
    { label: "Rejected", value: "REJECTED" },
    { label: "Accepted", value: "ACCEPTED" },
    { label: "Amended", value: "AMENDED" },
    { label: "Pending", value: "PENDING" },
  ],
  [verification_column_keys.YEAR]: getYears(),
  [verification_column_keys.SOURCE]: [],
};

export const SCREEN_TYPE = {
  VERIFICATION_LISTING: "verificationListing",
  VERIFICATION_DETAIL: "verificationDetail",
};
