import { BrowserRouter as Router } from "react-router-dom";
import RegisterRoutes from "./render-routes";
const RenderRoutes = () => {
  return (
    <main>
      <Router>
        <RegisterRoutes />
      </Router>
    </main>
  );
};

export default RenderRoutes;
