import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import styles from "../../../assets/styles/pr.styles.module.css";

const AuditCheckLoader = () => {
  const loaderRender = () => {
    return (
      <div className={styles.auditLoader}>
        <h5 className={`k-font-bold  ${styles.auditCheckComplete}`}>
          Running Audit Check...
        </h5>
        <h6 className="k-font-size-sm k-font-normal k-mb-5">
          Please wait while we run the audit check for you.
        </h6>
        <Loader themeColor="success" />
      </div>
    );
  };
  return (
    <Dialog width="610px" contentStyle={{ width: "auto" }}>
      {loaderRender()}
    </Dialog>
  );
};

export default AuditCheckLoader;
