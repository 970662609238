function SharedCarViewedIcon(props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.593 5.848c-.017-.037-.414-.918-1.296-1.8C9.12 2.872 7.635 2.25 6 2.25c-1.635 0-3.12.622-4.297 1.798C.821 4.93.422 5.812.407 5.848a.375.375 0 000 .305c.017.037.414.917 1.296 1.8C2.88 9.128 4.365 9.75 6 9.75c1.635 0 3.12-.622 4.297-1.797.882-.883 1.28-1.763 1.296-1.8a.375.375 0 000-.305zM6 7.875a1.875 1.875 0 110-3.75 1.875 1.875 0 010 3.75z"
        fill="#DAF5F3"
      />
    </svg>
  )
}

export default SharedCarViewedIcon
