import { Button } from "@progress/kendo-react-buttons";

export const ba_column_keys = {
  BA_TYPE: "issueType",
  SALES_APPROVAL: "salesApproval",
  OPS_APPROVAL: "opsApproval",
  HEAD_APPROVAL: "cityHeadApproval",
};

export const ba_columns = {
  [ba_column_keys.BA_TYPE]: {
    field: ba_column_keys.BA_TYPE,
    label: "BA Type",
  },
  [ba_column_keys.SALES_APPROVAL]: {
    field: ba_column_keys.SALES_APPROVAL,
    label: "Sales Approval",
  },
  [ba_column_keys.OPS_APPROVAL]: {
    field: ba_column_keys.OPS_APPROVAL,
    label: "Ops Approval",
  },
  [ba_column_keys.HEAD_APPROVAL]: {
    field: ba_column_keys.HEAD_APPROVAL,
    label: "City head approval",
  },
};

export const getStatus = (required, allowed) => {
  let result;
  if (required) {
    switch (allowed) {
      case true:
        result = "Approved";
        break;
      case false:
        result = "Rejected";
        break;
      case null:
        result = "Pending";
        break;
      default:
        result = "NA";
        break;
    }
  } else {
    result = "Not required";
  }
  return result;
};

export const getStatusMapping = {
  Approved: "success",
  Rejected: "reject",
  Pending: "pending",
  NA: "neutral",
  "Not required": "neutral",
};
export const SerialNumberCell = (props) => {
  const { dataIndex } = props || {};
  return <td>{dataIndex + 1 + " ."}</td>;
};

export const RemoveARowCell = (props) => {
  const { onClick, dataIndex, isDisabled } = props || {};
  return (
    <td>
      <Button
        className="k-font-bold k-ml-sm"
        fillMode="flat"
        themeColor="primary"
        icon="trash"
        onClick={() => onClick(dataIndex)}
        disabled={isDisabled}
      />
    </td>
  );
};
export const GetApprovalFrom = (value, baTypes) => {
  let list = [];
  const filteredBa =
    baTypes &&
    baTypes.length &&
    baTypes.find((item) => item.issueType === value);

  if (filteredBa) {
    if (filteredBa.salesApprovalApplicable) {
      list.push("SALES");
    }
    if (filteredBa.opsApprovalApplicable) {
      list.push("OPS");
    }
    if (filteredBa.cityHeadApprovalApplicable) {
      list.push("CITY HEAD");
    }
  }
  return list.join(", ");
};

export const isPrInitialized = (status) => {
  return status === "INITIALIZED";
};
