import { OPS_CHALLAN } from "../types";
import { ChallanService } from "../../services";
import { challan_request_keys } from "../../views/operations/challan-list/viewModels";
export const fetchChallanOrders =
	(params = {}) =>
	(dispatch) => {
		dispatch({ type: OPS_CHALLAN.FETCH_ORDERS_START });
		ChallanService.fetchOrders(params)
			.then((resp) => {
				dispatch({
					type: OPS_CHALLAN.FETCH_ORDERS_SUCCESS,
					payload: {
						data: resp.content,
						numberOfElements: resp.numberOfElements,
						totalElements: resp.totalElements,
					},
				});
			})
			.catch((err) => {
				dispatch({ type: OPS_CHALLAN.FETCH_ORDERS_FAILURE, payload: err });
			})
			.finally(() => {
				dispatch({ type: OPS_CHALLAN.FETCH_ORDERS_END });
			});
	};
export const updateChallanSourceParams =
	(params = {}) =>
	(dispatch) => {
		dispatch({ type: OPS_CHALLAN.SET_CHALLAN_SOURCE, payload: params });
	};
export const updateAssignmentTypeParams =
	(params = {}) =>
	(dispatch) => {
		dispatch({ type: OPS_CHALLAN.SET_ASSIGNMENT_TYPE, payload: params });
	};

export const getRequestChallanItems =
	(params = {}) =>
	(dispatch) => {
		let data = { ...params };
		ChallanService.fetchChallanItems(params)
			.then((resp) => {
				const { detail = [], numberOfElements = 0, totalElements = 0 } = resp;
				data = {
					...data,
					channelItems: {
						data: detail,
						numberOfElements: detail.length,
						totalElements: detail.length,
					},
				};
				dispatch({ type: OPS_CHALLAN.UPDATE_REQUEST_ORDERS, payload: data });
			})
			.catch((err) => {
				data = {
					...data,
					channelItems: {
						data: [],
						numberOfElements: 0,
						totalElements: 0,
						error: err,
					},
				};
				dispatch({ type: OPS_CHALLAN.UPDATE_REQUEST_ORDERS, payload: data });
			});
	};
export const updateRowChannelRequest =
	(params = {}) =>
	(dispatch) => {
		let data = { ...params };
		const requestParams = `?${[challan_request_keys.REQUEST_ID]}=${
			params[challan_request_keys.REQUEST_ID]
		}`;
		ChallanService.fetchOrders(requestParams).then((resp) => {
			const itemRow = resp.content[0];
			ChallanService.fetchChallanItems(params)
				.then((resp) => {
					const { detail = [], numberOfElements = 0, totalElements = 0 } = resp;
					data = {
						...data,
						...itemRow,
						channelItems: {
							data: detail,
							numberOfElements: detail.length,
							totalElements: detail.length,
						},
					};
					dispatch({
						type: OPS_CHALLAN.UPDATE_REQUEST_ORDERS,
						payload: data,
					});
				})
				.catch((err) => {
					data = {
						...data,
						channelItems: {
							data: [],
							numberOfElements: 0,
							totalElements: 0,
							error: err,
						},
					};
					dispatch({
						type: OPS_CHALLAN.UPDATE_REQUEST_ORDERS,
						payload: data,
					});
				});
		});
	};

export const fetchDisposeChallanItems = (params) => (dispatch) => {
	dispatch({ type: OPS_CHALLAN.FETCH_ORDERS_START });
	ChallanService.fetchDisposeChallanItems(params)
		.then((resp) => {
			dispatch({
				type: OPS_CHALLAN.FETCH_DISPOSE_CHALLAN_ITEMS_SUCCESS,
				payload: {
					data: resp.content,
					numberOfElements: resp.numberOfElements,
					totalElements: resp.totalElements,
				},
			});
		})
		.catch((err) => {
			dispatch({
				type: OPS_CHALLAN.FETCH_DISPOSE_CHALLAN_ITEMS_FAILURE,
				payload: err,
			});
		})
		.finally(() => {
			dispatch({ type: OPS_CHALLAN.FETCH_ORDERS_END });
		});
};

export const updateDisposeChallanItem = (params) => (dispatch) => {
	dispatch({ type: OPS_CHALLAN.REFRESH_DISPOSE_CHALLAN_ITEM, payload: params });
};

export const updateFinderChallanRequest = (params) => (dispatch) => {
	dispatch({
		type: OPS_CHALLAN.REFRESH_FINDER_CHALLAN_REQUEST,
		payload: params,
	});
};

export const updateChallanItemSourceParams =
	(params = {}) =>
	(dispatch) => {
		dispatch({ type: OPS_CHALLAN.SET_CHALLAN_ITEM_SOURCE, payload: params });
	};
export const updateChallanItemAssignmentTypeParams =
	(params = {}) =>
	(dispatch) => {
		dispatch({
			type: OPS_CHALLAN.SET_CHALLAN_ITEM_ASSIGNMENT_TYPE,
			payload: params,
		});
	};
export const setChallanRequestSearchQuery =
	(params = {}) =>
	(dispatch) => {
		dispatch({
			type: OPS_CHALLAN.SET_CHALLAN_REQUEST_SEARCH_QUERY,
			payload: params,
		});
	};

export const updateChallanItemFiltersParams =
	(params = {}) =>
	(dispatch) => {
		dispatch({
			type: OPS_CHALLAN.SET_DISPOSE_FILTERS,
			payload: params,
		});
	};

export const setChallanDisposeSearchQuery =
	(params = "") =>
	(dispatch) => {
		dispatch({
			type: OPS_CHALLAN.SET_DISPOSE_SEARCH_QUERY,
			payload: params,
		});
	};

export const updateRequestFiltersParams =
	(params = {}) =>
	(dispatch) => {
		dispatch({
			type: OPS_CHALLAN.SET_FINDER_FILTERS,
			payload: params,
		});
	};

export const updateChallanItemDelayedParams =
	(params = {}) =>
	(dispatch) => {
		dispatch({
			type: OPS_CHALLAN.SET_CHALLAN_ITEM_DELAYED_TYPE,
			payload: params,
		});
	};

export const resetChallanDataItems = () => (dispatch) => {
	dispatch({
		type: OPS_CHALLAN.RESET_DISPOSE_CHALLAN_ITEMS,
	});
};
