function EditIcon(props) {
  return (
    <svg
      width={20}
      height={22}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 22v-4h20v4H0zm4-8h1.4l7.8-7.775L11.775 4.8 4 12.6V14zm-2 2v-4.25L13.2.575A1.975 1.975 0 0114.6 0c.267 0 .525.05.775.15.25.1.475.25.675.45L17.425 2c.2.183.346.4.438.65.091.25.137.508.137.775 0 .25-.046.496-.137.737a1.874 1.874 0 01-.438.663L6.25 16H2z"
        fill="#0F5288"
      />
    </svg>
  )
}

export default EditIcon
