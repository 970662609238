import { cj_verification_keys } from "../../views/inspection/cj-footprint/viewModal";
import { VEHICLE_INSPECTION } from "../types";

const INITIAL_STATE = {
	isLoading: false,
	vehicleInspection: {
		data: [],
		totalCount: 0,
		numberOfElements: 0,
	},
	error: {},
};

const vehicleInspection = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case VEHICLE_INSPECTION.FETCH_CJ_VERIFICATION_LIST:
			return {
				...state,
				isLoading: true,
			};
		case VEHICLE_INSPECTION.FETCH_CJ_VERIFICATION_LIST_SUCCESS:
			return {
				...state,
				vehicleInspection: {
					data: action.payload.data.data.map((item, index) => ({
						...item,
						[cj_verification_keys.CJ_VERIFICATION_ID]: index
					})),
					totalCount: action.payload.data.totalCount,
					numberOfElements: action.payload.data.displayCount,
				},
				isLoading: false,
				error: {},
			};
		case VEHICLE_INSPECTION.FETCH_CJ_VERIFICATION_LIST_FAILURE:
			return {
				...state,
				vehicleInspection: {},
				isLoading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};
export default vehicleInspection;
