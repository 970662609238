import axios from "axios";
import { getSessionToken } from "@descope/react-sdk";
import { ERROR_CODES } from "../utils/constants/values.constants";
import { getLoginUserEmail, onBackLogin } from "../utils/utils";

const logger = (msg) => {
    const DEBUG = process.env.NODE_ENV === "development";
    if (DEBUG) {
        console.info(msg);
    }
}

const apiInterceptor = (baseURL) => {
    const api = axios.create({
        baseURL: baseURL,
        headers: { "Content-Type": "application/json", },
    });

    api.interceptors.request.use(
        (config) => {
            config.headers["user-email"] = getLoginUserEmail();
            config.headers["x-auth-key"] = `${getSessionToken()}`;
            logger(`✉️ , ${config}`)
            return config;
        },
        (error) => {
            logger(`✉️ , ${error}`)
            return Promise.reject(error);
        }
    );

    api.interceptors.response.use(
        (response) => {
            logger(`📩 , ${response}`)
            return response;
        },
        (error) => {
            if (error.response) {
                logger(`Error: , ${error.response}`)
                return Promise.reject(error.response.data);
            }
            logger(`📩 , ${error}`)
            return Promise.reject(error.message);
        }
    );
    return api;
}

export default apiInterceptor;
