import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSession, useUser, useDescope } from "@descope/react-sdk";
import { getFromLocalStorage, getHomePageByRole, isLmsPage, saveToLocalStorage } from "../utils/utils";
import AvataImg from "../assets/img/avatarImg.png";
import logo from "../assets/img/logo.jpg";
import { AppBar, AppBarSection, Avatar } from "@progress/kendo-react-layout";
import { LOCAL_STORAGE_KEYS } from "../utils/constants/values.constants";
import { SegmentEvents } from "../views/viz-panel/common/constants/events.constants";
import { trackAnalytics } from "../views/viz-panel/common/services/analytics";
import ExotelDailerContext from "../context/exotel-dailer-context";
import styles from './style.module.css';
import LmsStateManagementContext from "../context/lms-stage-management-context";
import LmsActivityPopup from "./lms-activity-popup";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationModal } from "../views/viz-panel/store/actions/myDealers";

const Header = (props) => {
  const { setShowDailerPopup } = useContext(ExotelDailerContext)
  const historyLink = useHistory();
  const prevLink = useLocation();
  const { isRaActive } = useContext(LmsStateManagementContext)
  const [showRaTrackingPopup, setShowRaTrackingPopup] = useState(false)
  const prevPathName = prevLink?.pathname;
  if (prevPathName?.split("negoview")?.[1]) {
    saveToLocalStorage(LOCAL_STORAGE_KEYS.PREV_PATHNAME, prevPathName);
  }
  const [showSubMenu, setShowSubMenu] = useState(false);
  const { user, isUserLoading } = useUser();
  const { logout } = useDescope();
  const { isAuthenticated, isSessionLoading } = useSession();
  const { pathname } = useLocation();
  const { unseenNotificationCount } = useSelector((state) => state.allCars);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSessionLoading || isUserLoading) {
      return;
    }
    if (!user || !isAuthenticated) {
      historyLink.push("/login");
    }
  }, [user, isAuthenticated, historyLink, isUserLoading, isSessionLoading]);

  const logLogoutEvent = () => {
    const loggedInTime = getFromLocalStorage(LOCAL_STORAGE_KEYS.USER_LOGIN_DATE_TIME);
    let difMins = (new Date(loggedInTime) - new Date());
    difMins = Math.abs(Math.round((difMins / 1000) / 60));
    trackAnalytics(SegmentEvents.Logout, { email: user?.email, device_id: '', total_session_duration: difMins });
  }
  const onLogoutClick = () => {
    logLogoutEvent();
    if (isAuthenticated) {
      localStorage.clear();
      sessionStorage.clear();
      logout();
    } else {
      historyLink.push("/login");
    }
  };

  const onGoHome = () => {
    const routePath = getHomePageByRole();
    setTimeout(() => {
      historyLink.push(routePath);
      window.location.reload();
    }, 1000);
  };

  const capitalizedName = (fullName) => {
    const words = fullName?.split(" ");
    return !!words?.length
      ? words
        .map((word) => {
          return word?.charAt(0)?.toUpperCase() + word?.slice(1);
        })
        ?.join(" ")
      : "";
  };

  const allowedUrls = [
    '/retail/lms',
    '/lms/retargeting',
    '/qc/hypo-qc-panel',
  ];
  const kendoAppBar = () => {
    return (
      <AppBar>
        <AppBarSection style={{ width: "15%" }} className="logo-wrapper">
          <div
            className="hamburger"
            onClick={() => props.setIsOpen(!props.isOpen)}
          >
            <span className="k-icon k-i-rows"></span>
          </div>
          <div className="appbar-title-container" onClick={() => onGoHome()}>
            <img className="ml-1" src={logo} alt="cars24" />
          </div>
        </AppBarSection>
        <AppBarSection style={{ width: "84%" }}>
          <div className="userName">
            <span className="userNameIn">
              Welcome! {capitalizedName(user?.name)}{" "}
            </span>
            <AppBarSection>
              {
                allowedUrls.some(url => pathname.startsWith(url)) &&
                <span className='header_dailer' onClick={() => setShowDailerPopup(true)}>&#9742;</span>
              }
              {
                isLmsPage() &&
                <span
                  className={styles.ra_activity}
                  onClick={() => { setShowRaTrackingPopup(true); }}
                  style={{ background: isRaActive ? '#067647' : '#EB5757' }}
                >
                  <span className={styles.ra_activity__status}>{isRaActive ? 'Active' : 'Inactive'}</span>
                </span>
              }
              {
                showRaTrackingPopup &&
                <LmsActivityPopup
                  onSubmit={() => { setShowRaTrackingPopup(false); }}
                />
              }

              <div className="notification">
                {pathname.includes('viz') ? <span className="notification-count">{unseenNotificationCount < 0 ? 0 : unseenNotificationCount > 10 ? '10+' : unseenNotificationCount}</span> : null}
                <span
                  className="k-icon k-i-notification k-i-bell"
                  style={{
                    border: "1px solid #fff",
                    padding: "15px",
                    borderRadius: "40px",
                  }}
                  onClick={() => {
                    if (pathname.includes('viz')) {
                      dispatch(setNotificationModal(true))
                    }
                  }}
                />

              </div>
              <div
                className="avatar-container"
                onClick={() => setShowSubMenu(!showSubMenu)}
              >
                <Avatar type="image" className="mr-2">
                  <img src={AvataImg} alt="KendoReact Layout Kendoka Avatar" />
                </Avatar>
                {capitalizedName(user?.name)}{" "}
                <span className="k-icon k-i-arrow-chevron-down ml-2" />
                {showSubMenu && (
                  <ul className="userDropdown">
                    <li onClick={() => onLogoutClick()}>
                      {isAuthenticated ? "Logout" : "Login"}
                    </li>
                  </ul>
                )}
              </div>
            </AppBarSection>
          </div>
        </AppBarSection>
      </AppBar>
    );
  };

  return (
    <>
      {/* {renderCustom()} */}
      {kendoAppBar()}
    </>
  );
};

export default Header;
