import axios from "axios";
import { getSessionToken } from "@descope/react-sdk";
import { ERROR_CODES } from "../utils/constants/values.constants";
import { getInspectionFeedServiceMetaData } from "../utils/constants/url.constants";
import { onBackLogin } from "../utils/utils";
const DEBUG = process.env.NODE_ENV === "development";
const apiMetaData = getInspectionFeedServiceMetaData();
const api = axios.create({
	baseURL: apiMetaData.url,
	headers: {
		"Content-Type": "application/json",
	},
});

api.interceptors.request.use(
	(config) => {
		config.headers["x-auth-key"] = `${getSessionToken()}`;
	    config.headers["Authorization"] = `Bearer ${getSessionToken()}`;
		if (DEBUG) {
			console.info("✉️ ", config);
		}
		return config;
	},
	(error) => {
		if (DEBUG) {
			console.error("✉️ ", error);
		}
		return Promise.reject(error);
	}
);

api.interceptors.response.use(
	(response) => {
		if (DEBUG) {
			console.info("📩 ", response);
		}
		return response;
	},
	(error) => {
		if (error.response) {
			if (DEBUG) {
				console.error("Error: ", error.response);
			}
			if (
				error &&
				error.response.status === ERROR_CODES.UNAUTHORISED &&
				!window.location.pathname.includes("login")
			) {
				localStorage.clear();
				onBackLogin();
			}
			return Promise.reject(error.response.data);
		}
		if (DEBUG) {
			console.error("📩 ", error);
		}
		return Promise.reject(error.message);
	}
);

export default api;
