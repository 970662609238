import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useMemo, useState } from "react";
import { SFSubTabStrips } from "../../viewModel";
import { USER_ROLE_KEYS } from "../../../../../utils/constants/menu.constants";
import SubTabGrid from "./SubTabGrid";
import classes from "../../styles/LeadsGrid.module.css";
import AgentFilter from "../../components/AgentFilter";
import { AgentContextProvider } from "../../context/AgentContext";

const AssignedLeads = ({
    isAdmin,
    filterData,
    setFilterData,
    initialSubTab,
}) => {
    const [selectedTab, setSelectedTab] = useState(initialSubTab);
    const [selectedAgent, setSelectedAgent] = useState(null);

    const handleTabSelect = (e) => {
        setSelectedTab(e.selected);
    };

    const currentRole = useMemo(() => {
        if (!isAdmin) {
            return USER_ROLE_KEYS.C2B_FINANCE_SELLER_AGENT;
        } else {
            return USER_ROLE_KEYS.C2B_FINANCE_SELLER_ADMIN;
        }
    }, [isAdmin]);

    const renderTabStrips = () =>
        SFSubTabStrips[currentRole]?.ASSIGNED?.map((item) => {
            return (
                <TabStripTab title={item.label} key={item.key}>
                    <SubTabGrid
                        isAdmin={isAdmin}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        subTabKey={item.key}
                    />
                </TabStripTab>
            );
        });

    const handleSelectAgent = (event) => {
        const { email = "" } = event?.value || {};
        setSelectedAgent(email);
    };

    return (
        <div className={classes.assignedTabsContainer}>
            <AgentContextProvider value={selectedAgent}>
                <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                    {renderTabStrips()}
                </TabStrip>
            </AgentContextProvider>
            {isAdmin && <AgentFilter onChange={handleSelectAgent} />}
        </div>
    );
};

export default AssignedLeads;
