import React, { useEffect, useRef, useState } from 'react';
import styles from './style.module.css'
import useLazyQuery from '../../../../../../../hooks/useLazyQuery';
import { genAiApis } from '../../../../../../../services/genAiApi';
import { Loader } from '@progress/kendo-react-indicators';
import { getLoginUserEmail } from '../../../../../../../utils/utils';
import { sendEvent } from '../../../../../../../tracking';

function RACallSummaryCard(props) {

    const {currentSid,callHeader} = props
    const { apiCbFunction:getSummaryBySid,data:summaryBySidData, isLoading:summaryBySidLoading,errorMessage:summaryBySidError} = useLazyQuery(genAiApis.getSumaryBySid)

    useEffect(()=>{
      getSummaryBySid({
        body:{
          "journey": "RETAIL_ASSOCIATE",
          "action": "RA_TRANSCRIPT_SUMMARY",
          "callId": currentSid
        }
      })
      sendEvent({
        event:'summary_click',
        eventCategory:'summary_audit',
        eventAction:'summary_tab_click',
        eventLabel:`Call ${props.index}|${props?.appointmentId}|${getLoginUserEmail()}`
      })
    },[])

    return (
        <div className={styles['head']}>
            {
              summaryBySidLoading 
              ?
                <Loader/>
              :
                <div className={styles['head_summary']}>
                  <span>
                    <span className={styles.callLogDetails_label}>Date :- <span className={styles.callLogDetails_value}>{callHeader?.callDate??'NA'}</span> || </span>
                    <span className={styles.callLogDetails_label}>From :- <span className={styles.callLogDetails_value}>{callHeader?.callFrom??'NA'}</span> || </span>
                    <span className={styles.callLogDetails_label}>To :- <span className={styles.callLogDetails_value}>{callHeader?.callTo??'NA'}</span> || </span>
                    <span className={styles.callLogDetails_label}>Status :- <span className={styles.callLogDetails_value}>{callHeader?.causeCodeLabel??'NA'}</span> || </span>
                    <span className={styles.callLogDetails_label}>Duration :- <span className={styles.callLogDetails_value}>{formatDuration(callHeader?.onCallDuration??0)}</span> || </span>
                    <span className={styles.callLogDetails_label}>Label :- <span className={styles.callLogDetails_value}>{callHeader?.retailPllLabel??'NA'}</span> </span>
                  </span>
                  {
                    summaryBySidError?.msg == 'Not Found.' && 
                    <p>No Summary Found</p>
                  }
                  {
                    summaryBySidData?.data?.text && 
                    parseMarkdown(summaryBySidData?.data?.text)
                  }
                </div>
            }
        </div>
    );
}

export default RACallSummaryCard



const parseMarkdown = (markdown) => {
    const lines = markdown.split('\n');
    const elements = lines.map((line, index) => {
        // Headers
        if (/^###\s/.test(line)) return <h3 key={index}>{line.replace(/^###\s/, '')}</h3>;
        if (/^##\s/.test(line)) return <h2 key={index}>{line.replace(/^##\s/, '')}</h2>;
        if (/^#\s/.test(line)) return <h1 key={index}>{line.replace(/^#\s/, '')}</h1>;

        // Bold text
        if (/\*\*(.*)\*\*/.test(line)) {
            const parts = line.split(/\*\*(.*?)\*\*/g);
            return (
                <p key={index}>
                    {parts.map((part, i) =>
                        i % 2 === 1 ? <b key={i}>{part}</b> : part
                    )}
                </p>
            );
        }

        // Italic text
        if (/\*(.*)\*/.test(line)) {
            const parts = line.split(/\*(.*?)\*/g);
            return (
                <p key={index}>
                    {parts.map((part, i) =>
                        i % 2 === 1 ? <i key={i}>{part}</i> : part
                    )}
                </p>
            );
        }

        // Links
        if (/\[(.*?)\]\((.*?)\)/.test(line)) {
            const parts = line.split(/(\[.*?\]\(.*?\))/g);
            return (
                <p key={index}>
                    {parts.map((part, i) => {
                        const match = part.match(/\[(.*?)\]\((.*?)\)/);
                        if (match) {
                            return (
                                <a key={i} href={match[2]}>
                                    {match[1]}
                                </a>
                            );
                        }
                        return part;
                    })}
                </p>
            );
        }

        return <p key={index}>{line}</p>;
    });

    return elements;
};

const formatDuration = (durationInSeconds) => {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  return `${hours}h ${minutes}m ${seconds}s`;
}