import { TYPE_SEGMENTATION } from "../types";

const INITIAL_STATE = {
  isLoading: false,
  segmentationData: [],
  isRefreshRequired: false,
}

const Segmentation = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPE_SEGMENTATION.SET_SEGMENTATION_DATA:
      return {
        ...state,
        segmentationData: action.payload,
      }
    case TYPE_SEGMENTATION.FETCH_LOADING_START:
      return {
        ...state,
        isLoading: true,
      }
    case TYPE_SEGMENTATION.FETCH_LOADING_END:
      return {
        ...state,
        isLoading: false,
      }
    case TYPE_SEGMENTATION.SET_REFRESH:
      return {
        ...state,
        isRefreshRequired: action.payload,
      }

    default:
      return state;
  }
}

export default Segmentation;