import { getFFUserType } from "../../utils/utils";

export default (api) => {
    const fetchAllLeads = (params = "") => {
        const url = `list`;
        return new Promise((resolve, reject) => {
            api.get(`${url}${params}`, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    const fetchLeadById = (id, params = "") => {
        const url = `${id}`;
        return new Promise((resolve, reject) => {
            api.get(`${url}${params}`, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    const updateDetails = (id, body) => {
        return new Promise((resolve, reject) => {
            api.post(`/update/${id}`, body)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const fetchAgentList = () => {
        const url = `agent/list`;
        return new Promise((resolve, reject) => {
            api.get(`${url}`, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const assignAgentLead = (leadId = "", params = {}) => {
        const url = `assign/${leadId}`;
        return new Promise((resolve, reject) => {
            api.post(`${url}${params}`, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const uploadDocument = (formData) => {
        const url = "/document/uploadfile";

        return new Promise((resolve, reject) => {
            api.post(`${url}`, formData, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const fetchCities = () => {
        const url = "/city/list";

        return new Promise((resolve, reject) => {
            api.get(`${url}`, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const verifyDocument = (body) => {
        const url = "/document/verification";

        return new Promise((resolve, reject) => {
            api.post(`${url}`, body, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const syncAgents = () => {
        const url = "/agent/sync";

        return new Promise((resolve, reject) => {
            api.get(`${url}`, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const fetchMetadata = () => {
        const url = "/metadata";

        return new Promise((resolve, reject) => {
            api.get(`${url}`, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const updateCallStatus = (body, leadId) => {
        const url = `/followup/${leadId}`;

        return new Promise((resolve, reject) => {
            api.post(`${url}`, body, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const uploadSingleLead = (body) => {
        const url = "/create";

        return new Promise((resolve, reject) => {
            api.post(`${url}`, body, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const uploadBulkLead = (formData) => {
        const url = "/upload/leads";

        return new Promise((resolve, reject) => {
            api.post(`${url}`, formData, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const shareLink = (leadId, body) => {
        const url = `/share/link/${leadId}`;

        return new Promise((resolve, reject) => {
            api.patch(`${url}`, body, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const callLead = (body) => {
        const url = "/connect/customer";

        return new Promise((resolve, reject) => {
            api.post(`${url}`, body, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const getCallStatus = (leadId) => {
        const url = `/call/status/${leadId}`;

        return new Promise((resolve, reject) => {
            api.get(`${url}`, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    return {
        fetchAllLeads,
        fetchLeadById,
        fetchAgentList,
        assignAgentLead,
        updateDetails,
        uploadDocument,
        fetchCities,
        fetchMetadata,
        verifyDocument,
        syncAgents,
        updateCallStatus,
        uploadSingleLead,
        uploadBulkLead,
        shareLink,
        callLead,
        getCallStatus,
    };
};
