import styles from "../../assets/styles/pr.styles.module.css";
const CustomTitle = (title) => {
  return (
    <div className="align-center">
      <span className={styles.customTitle}>{title}</span>
    </div>
  );
};

export default CustomTitle;
