import { deleteDealerContact, refreshDealers } from "../../../../../store/actions/myDealers";
import { SegmentEvents } from "../../../../constants/events.constants";
import { trackAnalytics } from "../../../../services/analytics";
import { showToastMessage } from "../../../../utils/utils";


export const handleDelete = (dispatch, dealerId, contactNumber, setIsDelete, email, dealerName) => {
  const trackData = {
    dealer_id: dealerId,
    dealer_name: dealerName,
    email_id: email,
    removed_number: contactNumber,
  }

  dispatch(deleteDealerContact({ dealerId, contactNumber }))
    .then(() => {
      setIsDelete(false);
      showToastMessage('Contact deleted successfully', true, 1000);
      trackAnalytics(SegmentEvents.Viz_contact_modification, trackData);

      dispatch(refreshDealers(false));
    })
    .catch(() => {
      showToastMessage('Failed to delete contact', false, 1000);
    });
};
