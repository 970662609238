import { createContext } from "react";

const LeadsFilterContext = createContext({
    type: null,
    value: null,
});

const LeadsFilterContextProvider = LeadsFilterContext.Provider;

export { LeadsFilterContext, LeadsFilterContextProvider };
