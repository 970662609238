import axios from "axios";
import { getSessionToken } from "@descope/react-sdk";
import { ERROR_CODES, LOCAL_STORAGE_KEYS } from "../../../utils/constants/values.constants";
import { getFromLocalStorage, onBackLogin } from "../../../utils/utils";
import { getVizPanelServiceData } from "../common/constants/env.constants";
import { trackAnalytics } from "../common/services/analytics";
import { SegmentEvents } from "../common/constants/events.constants";
const DEBUG = process.env.NODE_ENV === "development";
const apiMetaData = getVizPanelServiceData();
const api = axios.create({
  baseURL: apiMetaData.url,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    // config.headers["Authorization"] = `Bearer ${getSessionToken()}`;
    config.headers["x-auth-key"] = `${getSessionToken()}`;
    if (DEBUG) {
      console.info("✉️ ", config);
    }
    return config;
  },
  (error) => {
    if (DEBUG) {
      console.error("✉️ ", error);
    }
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (DEBUG) {
      console.info("📩 ", response);
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (DEBUG) {
        console.error("Error: ", error.response);
      }
      if (
        error &&
        error.response.status === ERROR_CODES.UNAUTHORISED &&
        !window.location.pathname.includes("login")
      ) {
        const loggedInTime =  getFromLocalStorage(LOCAL_STORAGE_KEYS.USER_LOGIN_DATE_TIME);
        const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));
        logSessionTimeout(loggedInTime, user?.email);
        localStorage.clear();
        onBackLogin();
      }
      return Promise.reject(error.response.data);
    }
    if (DEBUG) {
      console.error("📩 ", error);
    }
    return Promise.reject(error.message);
  }
);

const logSessionTimeout = (loggedInTime,email)=>{
  let difMins = (new Date(loggedInTime) - new Date());
  difMins =Math.abs(Math.round((difMins / 1000) / 60));
  trackAnalytics(SegmentEvents.Auto_logout, {email:email,total_session_duration:difMins});
}
export default api;
