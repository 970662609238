import { showToastMessage } from "../../../../utils/utils";
import { TYPE_SEGMENTATION } from "../types";
import { getReqParams } from "../../../viz-panel/common/utils/utils";
import segmentationService from "../../segmentation/services";

export const getSegmentsData =
  (params = {}) =>
    (dispatch) => {
      dispatch({ type: TYPE_SEGMENTATION.FETCH_LOADING_START });
      let reqParams = getReqParams(params);
      segmentationService.getSegments(reqParams)
        .then((resp) => {
          dispatch({ type: TYPE_SEGMENTATION.SET_SEGMENTATION_DATA, payload: resp });
          dispatch({ type: TYPE_SEGMENTATION.FETCH_LOADING_END });
        })
        .catch((err) => {
          dispatch({ type: TYPE_SEGMENTATION.FETCH_LOADING_END });
          showToastMessage(err, false);

        })
    };

export const createSegment = (data, isRemoved = false) => async (dispatch) => {
  dispatch({ type: TYPE_SEGMENTATION.FETCH_LOADING_START });
  try {
    const resp = await segmentationService.createSegment(data);
    showToastMessage("Segment created successfully", true, 1500);
    dispatch({ type: TYPE_SEGMENTATION.FETCH_LOADING_END });
    return resp;
  } catch (error) {
    showToastMessage("Failed to create segment", false, 1500);
    dispatch({ type: TYPE_SEGMENTATION.FETCH_LOADING_END });
  }

}

export const updateSegment = (data, isRemoved = false) => async (dispatch) => {
  dispatch({ type: TYPE_SEGMENTATION.FETCH_LOADING_START });
  try {
    const resp = await segmentationService.updateSegment(data, isRemoved);
    showToastMessage("Segment updated successfully", true, 1500);
    dispatch({ type: TYPE_SEGMENTATION.FETCH_LOADING_END });
    return resp;
  } catch (error) {
    showToastMessage("Failed to update segment", false, 1500);
    dispatch({ type: TYPE_SEGMENTATION.FETCH_LOADING_END });
  }
};

export const refreshSegmentationData = (isRefreshed) => (dispatch) => {
  dispatch({ type: TYPE_SEGMENTATION.SET_REFRESH, payload: isRefreshed });
};


