import { useSelector } from "react-redux";
import { KendoListView } from "../../../../../../components";
import { useEffect, useState } from "react";
import { numberFormat } from "../../../../../../utils/utils";
import radioButton from "../../assets/images/radio_button.svg";
import dropDown from "../../assets/images/dropdown.svg";
import styles from "../../assets/styles/pr.styles.module.css";

const NetPayableCard = (props) => {
  const { chargesAmount, setChargesAmount } = props || {};
  const [deductibleChargeDetails, setDeductibleChargeDetails] = useState([]);
  const [payableLaterAmount, setPayableLaterAmount] = useState(0);
  const [holdDetails, setHoldDetails] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const prDetails = useSelector((state) => state.prAutomation);
  const { c24Quote, paymentRules } = prDetails || {};
  const getDetails = (data, isCharges) => {
    let details = [];
    let total = 0;
    data.forEach((item) => {
      if (item.isApplicable) {
        details.push({
          prefix: <span className={styles.leftBorder}></span>,
          details: (
            <span className="k-font-size-sm k-font-medium">{item.label}</span>
          ),
          value: (
            <span
              className="k-font-size-sm k-font-medium"
              style={{ color: isCharges ? "#D92D20" : "#272932" }}
            >
              {numberFormat(item?.amount)}
            </span>
          ),
        });
        total = total + item.amount;
      }
    });
    return { details, total };
  };
  useEffect(() => {
    if (paymentRules) {
      let charges = [];
      let chargesTotal = 0;
      let holds = [];
      let holdsTotal = 0;
      if (paymentRules.charges && paymentRules.charges.length) {
        const { details, total } = getDetails(paymentRules.charges, true) || {};
        charges = details;
        chargesTotal = total;
      }
      setDeductibleChargeDetails(charges);
      setChargesAmount(chargesTotal);
      if (paymentRules.holds && paymentRules.holds.length) {
        const { details, total } = getDetails(paymentRules.holds) || {};
        holds = details;
        holdsTotal = total;
      }
      setHoldDetails(holds);
      setPayableLaterAmount(holdsTotal);
    }
  }, [paymentRules]);
  const deductibles = [
    {
      prefix: <img src={radioButton} alt="radioButton" />,
      details: <span className="k-font-semibold">Deductibles</span>,
      value: (
        <div className="k-font-bold" style={{ color: "#D92D20" }}>
          {numberFormat(chargesAmount)}
        </div>
      ),
    },
  ];
  const payableLater = [
    {
      prefix: <img src={radioButton} alt="radioButton" />,
      details: <span className="k-font-semibold">Payable later</span>,
      value: (
        <span className="k-font-bold">{numberFormat(payableLaterAmount)}</span>
      ),
    },
  ];
  const netPayable = [
    {
      prefix: <img src={radioButton} alt="radioButton" />,
      details: <span className="k-font-semibold">Net Payable</span>,
      value: (
        <span className="k-font-bold">
          {c24Quote - chargesAmount
            ? numberFormat(c24Quote - chargesAmount)
            : numberFormat(0)}
        </span>
      ),
    },
  ];
  const payableOnDelivery = [
    {
      prefix: <img src={radioButton} alt="hourglass" />,
      details: <span className="k-font-semibold">Payable on delivery</span>,
      value: (
        <span className="k-font-bold">
          {c24Quote - chargesAmount - payableLaterAmount
            ? numberFormat(c24Quote - chargesAmount - payableLaterAmount)
            : numberFormat(0)}
        </span>
      ),
    },
  ];

  return (
    <>
      <div>
        <div className="k-font-size-sm k-font-medium k-text-center">
          C24 Quote
        </div>
        <div className={styles.c24Quote}>
          <span>{numberFormat(c24Quote)}</span>
          <img
            src={dropDown}
            alt="dropdown"
            className={`${styles.expandImage} ${
              isCollapsed ? styles.expandedImage : ""
            }`}
            onClick={() => setIsCollapsed((prev) => !prev)}
          />
        </div>
      </div>
      {!isCollapsed && (
        <div>
          <KendoListView
            data={deductibles}
            listViewClassname={`${styles.netPayableTopSpacing} `}
          />
          <KendoListView
            data={deductibleChargeDetails}
            listViewStyle={{ padding: "2px" }}
            listViewClassname={`${styles.netPayableBottomSpacing}`}
          />

          <KendoListView
            data={netPayable}
            listViewClassname={`${styles.netPayableTopSpacing} ${styles.netPayableBottomSpacing} ${styles.netPayableBorder} `}
          />
          <KendoListView
            data={payableLater}
            listViewClassname={`${styles.netPayableTopSpacing} ${styles.netPayableBorder}`}
          />

          <KendoListView
            data={holdDetails}
            listViewStyle={{ padding: "2px" }}
          />

          <KendoListView
            data={payableOnDelivery}
            listViewClassname={`${styles.netPayableTopSpacing}`}
          />
        </div>
      )}
    </>
  );
};
export default NetPayableCard;
