// import { Button } from "@progress/kendo-react-buttons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const dealer_column_keys = {
  ID: "dealerId",
  CODE: "dealerCode",
  NAME: "dealerName",
  PERM_ADDRESS: "permanentAddress",
  PRESENT_ADDRESS: "presentAddress",
  TYPE: "dealerType",
  PANNUMBER: "panNumber",
  GST: "gstCertificateNumber",
  AADHAR: "adharNumber",
  MOBILE: "mobileNo",
  ALTERNATE_NO: "alternateNo",
  EMAIL_ADDRESS: "emailAddress",
  COMPANY_CODE: "companyCode",
  STATUS: "status",
};

export const ucm_column_keys = {
  ID: "userId",
  CENTRE_NAME: "centreName",
  NAME: "userName",
  EMAIL: "userEmail",
  PHONE: "userPhone",
  ADDRESS: "centreAddress",
  CITY_ID: "cityId",
  CITY_NAME: "cityName",
  LAT: "latitude",
  LNG: "longitude",
  PINCODE: "pincode",
};

export const ra_column_keys = {
  ID: "userId",
  NAME: "userName",
  EMAIL: "userEmail",
  PHONE: "userPhone",
};

export const CentreLinkCell = (props) => {
  const { dataItem } = props || {};
  const linkTo = `/dealer/fulfillments/centerdetailview/${
    dataItem?.dealerId || ""
  }`;
  return (
    <td className="k-table-td ">
      {
        <Link to={linkTo} style={{ color: "#0d6efd" }}>
          {dataItem?.dealerName}
        </Link>
      }
    </td>
  );
};

export const fulfillmentColumns = {
  [dealer_column_keys.CODE]: {
    field: dealer_column_keys.CODE,
    label: "Dealer Code",
    filterable: true,
    width: "150px",
  },
  [dealer_column_keys.NAME]: {
    field: dealer_column_keys.NAME,
    label: "Dealer Name",
    cell: CentreLinkCell,
    filterable: true,
    width: "150px",
  },
  [dealer_column_keys.MOBILE]: {
    field: dealer_column_keys.MOBILE,
    label: "Mobile",
    filterable: true,
    width: "150px",
  },
  [dealer_column_keys.EMAIL_ADDRESS]: {
    field: dealer_column_keys.EMAIL_ADDRESS,
    label: "Email",
    width: "300px",
  },
  [dealer_column_keys.TYPE]: {
    field: dealer_column_keys.TYPE,
    label: "Dealer Type",
    width: "150px",
  },
  [dealer_column_keys.PERM_ADDRESS]: {
    field: dealer_column_keys.PERM_ADDRESS,
    label: "Permanent Address",
    width: "200px",
  },
  [dealer_column_keys.PRESENT_ADDRESS]: {
    field: dealer_column_keys.PRESENT_ADDRESS,
    label: "Current Address",
    width: "200px",
  },
  [dealer_column_keys.ALTERNATE_NO]: {
    field: dealer_column_keys.ALTERNATE_NO,
    label: "Alternate no",
    width: "150px",
  },
  [dealer_column_keys.COMPANY_CODE]: {
    field: dealer_column_keys.COMPANY_CODE,
    label: "Code",
    width: "150px",
  },
  [dealer_column_keys.STATUS]: {
    field: dealer_column_keys.STATUS,
    label: "Status",
    width: "150px",
  },
};

export const fulfillmentChildColumns = {
  [ucm_column_keys.ID]: {
    field: ucm_column_keys.ID,
    label: "User Id",
    width: "150px",
  },
  [ucm_column_keys.NAME]: {
    field: ucm_column_keys.NAME,
    label: "Code",
    width: "150px",
  },
  [ucm_column_keys.PHONE]: {
    field: ucm_column_keys.PHONE,
    label: "UCM Phone",
    width: "150px",
  },
  [ucm_column_keys.ADDRESS]: {
    field: ucm_column_keys.ADDRESS,
    label: "Address",
    width: "150px",
  },
  [ucm_column_keys.CITY_NAME]: {
    field: ucm_column_keys.CITY_NAME,
    label: "City",
    width: "150px",
  },
  [ra_column_keys.NAME]: {
    field: ra_column_keys.NAME,
    label: "RA Name",
    width: "150px",
  },
  [ra_column_keys.EMAIL]: {
    field: ra_column_keys.EMAIL,
    label: "Email",
    width: "150px",
  },
  [ra_column_keys.PHONE]: {
    field: ra_column_keys.PHONE,
    label: "RA Phone",
    width: "150px",
  },
};

export const getDisplayedCoulmns = () => {
  return Object.keys(fulfillmentColumns);
};

export const getDisplayedChildCoulmns = () => {
  return Object.keys(fulfillmentChildColumns);
};
