import React from 'react'

function DateTimeMapper(event) {

    function formatDate(dateString) {
        let date = new Date(dateString);

        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let year = date.getFullYear();

        let hours = date.getHours();
        let minutes = String(date.getMinutes()).padStart(2, '0');
        let ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12;
        hours = String(hours).padStart(2, '0');

        return { date: `${month}/${day}/${year}`, time: `${hours}:${minutes} ${ampm}` }
    }

    let { date, time } = formatDate(event?.dataItem[event.field]);

    return (
        <td>
            {date}, {time}
        </td>
    )
}

export default DateTimeMapper
