import React from 'react';
import { Field, ErrorMessage } from 'formik';
import LabelComponent from '../label';
import styles from '../../style.module.css';
import { FormMultiSelect } from '../../../../../../../components';
import { CJ_ANNOUNCEMENT_KEYS } from '../../../announcement-view/viewModel';
import { CjIncentivesService } from '../../../../../../../services';
import { debounce, showToastMessage } from '../../../../../../../utils/utils';



const MultiSelectComponent = ({ name, label, data, initialClusterData, setClusterData, setClusterParam }) => {
    
    const onFilterChange = (e) => {
        const { filter } = e
        if(filter?.value?.length > 2) {
            setClusterParam(filter?.value)
        }
    }

    const fetchUpdatedClusters = async (e) => {
        const selectedClusters = e.target.value;
        return selectedClusters;
    }

    return (
        <div className={styles.mb30}>
            <LabelComponent title={label} />
            <Field name={name}>
                {({ field, form, meta }) => {
                
                    const handleChange = async (e) => {
                        const updatedClusters = await fetchUpdatedClusters(e);
                        form.setFieldValue(name, updatedClusters);
                        setClusterData(initialClusterData);
                    };

                    return (
                        <FormMultiSelect
                            id={CJ_ANNOUNCEMENT_KEYS.CLUSTER}
                            key={CJ_ANNOUNCEMENT_KEYS.CLUSTER}
                            textField="text"
                            dataItemKey="clusterId"
                            data={data}
                            onChange={handleChange}
                            defaultValue={meta.initialValue}
                            value={field.value}
                            isDisplayLabel={true}
    						filterable={true}
                            onFilterChange={debounce(onFilterChange)}
                            placeholder={"Select Cluster"}
                        />
                    );
                }}
            </Field>
            <ErrorMessage name={name} component="div" className={styles.error} />
        </div>
    )
};

export default MultiSelectComponent;
