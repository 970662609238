import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";
import {
	FF_PAGE_TYPES,
	NUMBERS,
	SORT_DIRECTION_KEYS,
} from "../../../../utils/constants/values.constants";
import { USER_ROLE_KEYS } from "../../../../utils/constants/menu.constants";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ChallanCreateOrder from "../../components/create-order";
import { formData } from "../../components/create-order/viewModel";
import { ChallanService, ServiceHistory } from "../../../../services";
import {
	showToastMessage,
	getLoginUserEmail,
	getFFUserType,
} from "../../../../utils/utils";
import { challan_request_keys } from "../../challan-list/viewModels";
import DateRangeCta from "../../../../common/daterange-cta";
import { saveAs } from "file-saver";
const ActionBarLayout = (props) => {
	const { type = "" } = props;
	const userType = getFFUserType();
	const [openCreateOrderDialog, setOpenCreateOrderDialog] = useState(false);
	const [createOrderData, setCreateOrderData] = useState(formData);
	const [sortDir, setSortDir] = useState(SORT_DIRECTION_KEYS.DESC);
	const [isExport, setIsExport] = useState(false);
	const onCreateChallanRequest = () => {
		setOpenCreateOrderDialog(!openCreateOrderDialog);
	};
	const submitCreateOrder = () => {
		const payload = {
			[challan_request_keys.APPOINTMENT_ORDER_ID]:
				createOrderData.appointmentOrderId?.value,
			[challan_request_keys.CHALLAN_SOURCE]:
				createOrderData[challan_request_keys.CHALLAN_SOURCE]?.value?.value,
			[challan_request_keys.REG_NUMBER]: createOrderData.regNumber?.value,
			[challan_request_keys.RETAIL_REGION]: createOrderData.retailRegion?.value,
			[challan_request_keys.DESCRIPTION]: createOrderData.description?.value,
			[challan_request_keys.ASSIGN_TO]: getLoginUserEmail(),
		};
		//check validity of existing payload //todo
		if (!payload[challan_request_keys.APPOINTMENT_ORDER_ID]) {
			showToastMessage("Please select valid Appt Id/Order Id", false);
			return;
		} else if (
			payload[challan_request_keys.APPOINTMENT_ORDER_ID].length < NUMBERS.SIX ||
			payload[challan_request_keys.APPOINTMENT_ORDER_ID].length > NUMBERS.ELEVEN
		) {
			showToastMessage(
				"Please select Appt Id/Order Id of length between 6 to 11",
				false
			);
			return;
		} else if (!payload[challan_request_keys.CHALLAN_SOURCE]) {
			showToastMessage("Please select source type", false);
			return;
		} else if (!payload[challan_request_keys.REG_NUMBER]) {
			showToastMessage("Please select valid Registration Number", false);
			return;
		} else if (
			payload[challan_request_keys.REG_NUMBER].length < NUMBERS.SIX ||
			payload[challan_request_keys.REG_NUMBER].length > NUMBERS.TWELEVE
		) {
			showToastMessage(
				"Please select Registration Number of length between 6 to 12",
				false
			);
			return;
		} else if (!payload[challan_request_keys.RETAIL_REGION]) {
			showToastMessage("Please select valid Retail region", false);
			return;
		} else if (
			!!payload[challan_request_keys.DESCRIPTION] &&
			payload[challan_request_keys.DESCRIPTION].length >
				NUMBERS.TWO * NUMBERS.HUNDREAD
		) {
			showToastMessage(
				"Please select Description of length less than 200",
				false
			);
			return;
		}
		ChallanService.createOrder(payload)
			.then((resp) => {
				setCreateOrderData(formData);
				props.setIsRefreshRequired(true);
				setOpenCreateOrderDialog(false);
				showToastMessage(resp?.message);
			})
			.catch((err) => {
				showToastMessage(err.message, false);
			});
	};
	const onSortClick = () => {
		const dir =
			sortDir === SORT_DIRECTION_KEYS.DESC
				? SORT_DIRECTION_KEYS.ASC
				: SORT_DIRECTION_KEYS.DESC;
		setSortDir(dir);
		props.onDataSortByAgeing(dir);
	};

	const handleOnExport = (startDate, endDate) => {
		let exportType = "challan-item";
		if (type === FF_PAGE_TYPES.FINDERS) {
			exportType = "challan-request";
		}
		setIsExport(true);
		ChallanService.onExportData(exportType, { startDate, endDate })
			.then((res) => {
				const blob = new Blob([res], {
					type: "data.csv",
				});
				saveAs(blob, "data.csv");
			})
			.catch((err) => showToastMessage(err?.message, false))
			.finally(() => {
				setIsExport(false);
			});
	};

	const handleSyncUser = () => {
		if(userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN){
			ChallanService.syncUsers()
				.then((res) => showToastMessage(res?.message))
				.catch((err) => showToastMessage(err?.message));
		} else if(userType === USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_ADMIN){
			ServiceHistory.syncServiceHistoryUserList()
				.then((res) => showToastMessage(res?.message))
				.catch((err) => showToastMessage(err?.message));
		}
	}
	return (
		<div>
			{[
				USER_ROLE_KEYS.FINDER,
				USER_ROLE_KEYS.DISPOSE,
				USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_AGENT,
				USER_ROLE_KEYS.C2B_OPS_FF_VERIFICATION_AGENT,
			].includes(userType) && (
				<>
					{type === FF_PAGE_TYPES.FINDERS && (
						<Button
							className="m-1"
							themeColor={"primary"}
							onClick={onCreateChallanRequest}>
							Create Request
						</Button>
					)}
					<Button
						className="m-1"
						themeColor={"primary"}
						onClick={props.AssignToMe}>
						Assign to Me
					</Button>
				</>
			)}
			{type === FF_PAGE_TYPES.FINDERS && userType === USER_ROLE_KEYS.FINDER && (
				<Button
					className="m-1"
					themeColor={"primary"}
					onClick={props.MarkAsResolved}>
					Mark As Resolved
				</Button>
			)}
			{userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN ? (
				<DateRangeCta
					ctaTitle={"Export"}
					onClick={handleOnExport}
					isDisabled={isExport}
					popUpCtaTitle={"Export File"}
				/>
			) : (
				<Button
					className="m-1"
					icon="k-icon k-i-download"
					themeColor={"primary"}
					fillMode="outline"
					disabled={props.isDataExport}
					onClick={() => props.onExportDataStart(true)}>
					Export
				</Button>
			)}
			{[
				USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN,
				USER_ROLE_KEYS.DISPOSE,
			].includes(userType) &&
				type === FF_PAGE_TYPES.DISPOSE && (
					<Button
						className="m-1"
						themeColor={"primary"}
						fillMode="outline"
						onClick={onSortClick}>
						Sort{" "}
						<span
							className={`k-icon ${
								sortDir === SORT_DIRECTION_KEYS.DESC
									? "k-i-sort-desc-sm"
									: "k-i-sort-asc-sm"
							}`}
						/>
					</Button>
				)}
			{openCreateOrderDialog && (
				<Dialog title={"Create Request"} onClose={onCreateChallanRequest}>
					<ChallanCreateOrder
						data={createOrderData}
						setData={setCreateOrderData}
					/>
					<DialogActionsBar>
						<Button
							className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
							onClick={submitCreateOrder}
							themeColor={"primary"}>
							Submit
						</Button>
					</DialogActionsBar>
				</Dialog>
			)}
			{[USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN,USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_ADMIN].includes(userType) && (
				<Button themeColor={"primary"} onClick={handleSyncUser} className="m-1">
						Sync User List
					</Button>
			)}
		</div>
	);
};

export default ActionBarLayout;
