import styles from "./style.module.css";
import React, { useEffect, useState, useRef, useContext } from "react";
import ExotelCRMWebSDK from "exotel-ip-calling-crm-websdk";
import Notification from "./Notification";
import DialPad from "./DialPad";
import ExotelDailerContext from "../../context/exotel-dailer-context";
import useLazyQuery from "../../hooks/useLazyQuery";
import { commonApis } from "../../services/commonApi";
import { getLoginUserEmail, showToastMessage } from "../../utils/utils";

function Exotel() {

  const { userId, accessToken ,setShowDailerPopup,showDailerPopup } = useContext(ExotelDailerContext)
	const [phoneNumber, setPhoneNumber] = useState("");
	const [isDeviceRegistered, setIsDeviceRegistered] = useState(false);
	const [isMuted, setIsMuted] = useState(false);
	const [isOnHold, setIsOnHold] = useState(false);
	const [isCallActive, setIsCallActive] = useState(false);
	const [isIncomingCall, setIsIncomingCall] = useState(false);
	const [callerNumber, setCallerNumber] = useState(null)
	const [showNotification, setShowNotification] = useState(false);
	const [notificationType, setNotificationType] = useState("info");
	const [notificationMessage, setNotificationMessage] = useState("");
	const webPhone = useRef(null);
  const { apiCbFunction: callConnector, isLoading: isCallConnectorDataLoading } = useLazyQuery(commonApis.callConnector);

	const HandleCallEvents = (eventType, ...args) => {
		switch (eventType) {
			case "incoming":
				setIsIncomingCall(true);
				setCallerNumber(args?.[0]?.callFromNumber)
				setShowDailerPopup(true);
				break;
			case "connected":
				setIsIncomingCall(false);
				setIsCallActive(true);
				break;
			case "callEnded":
				setIsCallActive(false);
				setCallerNumber(null)
				setIsMuted(false)
				setIsOnHold(false)
				setIsIncomingCall(false);
				break;
			default:
				break;
		}
	};

	const RegisterationEvent = (event) => {
		if (event === "registered") {
			setIsDeviceRegistered(true);
			return;
		}
		if (event === "unregistered") {
			setIsDeviceRegistered(false);
		}
	};

	const handlePhoneNumberChange = (event) => {
		setPhoneNumber(event.target.value);
	};

	const dialCallback = (status, data) => {
	};

	const dial = () => {
		if (/^\+?[0-9]{10,14}$/.test(phoneNumber)) {
			setIsCallActive(true);
			setIsIncomingCall(false);
			webPhone?.current?.MakeCall(phoneNumber, dialCallback);
		} else {
			setShowNotification(true);
			setNotificationType("danger");
			setNotificationMessage(
				"Please enter a valid phone number (10-14 digits, optional +)."
			);
			setTimeout((_) => {
				setShowNotification(false);
			}, 3000);
		}
	};

	const acceptCall = () => {
		webPhone?.current?.AcceptCall();
	};

	const hangup = () => {
		webPhone?.current?.HangupCall();
		setIsCallActive(false);
	};

	const onClickToggleMute = () => {
		webPhone?.current?.ToggleMute();
		setIsMuted(!isMuted);
	};

	const onClickToggleHold = () => {
		webPhone?.current?.ToggleHold();
		setIsOnHold(!isOnHold);
	};

  const callButtonHandler = async () =>{
    if (/^\+?[0-9]{10,14}$/.test(phoneNumber)) {
			const res = await callConnector({
        body:{
          "userEmail": getLoginUserEmail(),
          "virtualNumber": phoneNumber,
        }
      })
      if(res?.callSid){ 
        showToastMessage('Call Connected Successfully',true)
      }
      else{
        showToastMessage('Some error occured',false)
      }
		} else {
			setShowNotification(true);
			setNotificationType("danger");
			setNotificationMessage(
				"Please enter a valid phone number (10-14 digits, optional +)."
			);
			setTimeout((_) => {
				setShowNotification(false);
			}, 3000);
		}
  }

	const getDailerControls = () => {
		if (isIncomingCall) {
			return (
				<>
					<div className={styles.incomingCallDiv}>
						<p className={styles.incomingCallHeader}>Incoming Call</p>
						<p className={styles.incomingCallNumber}>Number :- xxxxxxxxx</p>
					</div>
					<div className={styles.phoneControlButtons}>
						<button className={styles.accept} onClick={acceptCall}>
							Accept
						</button>
					</div>
				</>
			)
		}
		else if (isCallActive) {
			return (
				<>
					<div className={styles.incomingCallDiv}>
						<p className={styles.incomingCallHeader}>Call Connected</p>
						<p className={styles.incomingCallNumber}>Number :- xxxxxxxxx</p>
					</div>
					<DialPad sendDTMF={(digit) => webPhone.current.SendDTMF(digit)} />
					<div className={styles.phoneControlButtons}>
						<button className={styles.hangup} onClick={hangup}>
							Hangup
						</button>
						<button
							className={styles[isMuted ? "Unmute" : "Mute"]}
							onClick={onClickToggleMute}
							disabled={!isCallActive}
						>
							{isMuted ? "Unmute" : "Mute"}
						</button>
						<button
							className={styles[isOnHold ? "UnHold" : "Hold"]}
							onClick={onClickToggleHold}
							disabled={!isCallActive}
						>
							{isOnHold ? "Unhold" : "Hold"}
						</button>
					</div>
				</>
			)
		}
		else if (!isCallActive) {
			return (
				<>
					<input
						className={styles.phoneNumberInput}
						type="text"
						id="phoneNumber"
						placeholder="Enter phone number"
						pattern="^\+?[0-9]{10,14}$"
						title="Please enter a valid phone number (10-14 digits, optional +)."
						onChange={handlePhoneNumberChange}
					></input>
					<div className={styles.phoneControlButtons}>
						<button className={styles.call} onClick={callButtonHandler}>
							Call
						</button>
					</div>
				</>
			)
		}
	}

	useEffect(() => {
		async function loadDailer() {
			if (webPhone.current) {
				return;
			}
			const crmWebSDK = new ExotelCRMWebSDK(accessToken, userId, true);
			const crmWebPhone = await crmWebSDK.Initialize(
				HandleCallEvents,
				RegisterationEvent
			);
			webPhone.current = crmWebPhone;
		}
		loadDailer();
	}, []);

	useEffect(() => {
		if (isDeviceRegistered) {
			setShowNotification(true);
			setNotificationType("info");
			setNotificationMessage(`Device is online`);
			setTimeout((_) => {
				setShowNotification(false);
			}, 3000);
			return;
		}
	}, [isDeviceRegistered]);

	return (
		<div
			className={styles.main_div}
			style={{ display: showDailerPopup ? 'flex' : 'none' }}
		>
			<div className={styles.head}>
				<div className={styles.header}>
					<div className={styles.title}>Dialer</div>
					<div className={styles['close_btn']} onClick={() => setShowDailerPopup(false)}>x</div>
				</div>
				{
					showNotification && notificationMessage &&
					<Notification
						notificationMessage={notificationMessage}
						type={notificationType}
					/>
				}
				{
					!isDeviceRegistered ?
						"Waiting for device to register"
						:
						<>
							<div className={styles.PhoneControls}>
								{getDailerControls()}
							</div>

						</>
				}
			</div>
		</div>
	);
}

export default Exotel;
