import React, { useEffect, useState } from 'react';
import Exotel from '../components/Exotel';
import { getLoginUserEmail } from '../utils/utils';
import { useSession } from '@descope/react-sdk';
import { commonApis } from '../services/commonApi';
import useLazyQuery from '../hooks/useLazyQuery';

const ExotelDailerContext = React.createContext();

export const ExotelDailerContextProvider = props => {
  const { isAuthenticated } = useSession();
  const [showDailerPopup, setShowDailerPopup] = useState(false);
  const [dailNumber,setDailNumber] = useState('');
  const [userId,setUserId] = useState(getLoginUserEmail())
  const [accessToken,setAccessToken] = useState(null)
  const { apiCbFunction:exotelAccessTokenGenerator } = useLazyQuery(commonApis.getExotelAccessToken)

  useEffect(()=>{
    if(isAuthenticated){
      setUserId(getLoginUserEmail())
      const setToken = async()=>{
        const response = await exotelAccessTokenGenerator()
        setAccessToken(response?.token)  
      }
      setToken()
    }
  },[isAuthenticated])

  return (
    <ExotelDailerContext.Provider
      value={{
        showDailerPopup: showDailerPopup,
        setShowDailerPopup:setShowDailerPopup,
        dailNumber:dailNumber,
        setDailNumber:setDailNumber,
        userId:userId,
        accessToken:accessToken,
      }}
    >
      {
        userId && accessToken && isAuthenticated && 
        <Exotel/>
      }
      {props.children}
    </ExotelDailerContext.Provider>
  );
};

export default ExotelDailerContext;