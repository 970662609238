import { Button } from "@progress/kendo-react-buttons";
import React, { useEffect, useState } from "react";
import { FormComboBox, KendoCard } from "../../../../../components";
// import { SOURCE_DELAY_REASONS } from '../../viewModal';
import { Dialog } from "@progress/kendo-react-dialogs";
import AgentManagerPopup from "./component/agent-manager-popup";
import { AdministratorService } from "../../../../../services";
// import { showToastMessage } from '../../../../../utils/utils';

export const AgentManager = () => {
  const cardStyle = {
    background: "#FFFFFF",
    minHeight: "180px",
    border: "15px solid #EBF5FF",
    boxShadow: "unset",
    borderRadius: "8px",
  };
  const headerStyle = {
    color: "#003E72",
    fontWeight: "600",
    fontSize: "18px",
  };

  const headerContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const cardDetailStyle = {
    padding: "10px 26px",
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [ctaAction, setCtaAction] = useState("");
  const [regionList, setregionList] = useState([]);
  const [courtList, setCourtList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({});
  const [selectedCourt, setselectedCourt] = useState({});
  const [selectedAgent, setSelectedAgent] = useState({});

  useEffect(() => {
    handleReload("fetchAllRegion");
    getAllAgentList();
  }, []);

  useEffect(() => {
    if (!!Object.keys(selectedRegion || {})?.length) {
      handleReload("fetchCourtByRegion");
    }
  }, [selectedRegion]);

  const handleReload = (value) => {
    if (value === "fetchAllRegion") {
      setSelectedRegion({});
      setselectedCourt({});
      AdministratorService.fetchAllRegion()
        .then((res) => {
          const regionList = res?.detail?.map((item) => ({
            value: item?.id,
            label: item?.regionName,
            status: !!item?.status,
          }));
          setregionList(regionList);
        })
        .catch((err) => console.log(err));
    } else if (value === "fetchCourtByRegion") {
      setselectedCourt({});
      AdministratorService.fetchCourtByRegion(selectedRegion?.value)
        .then((res) => {
          const courtList = res?.detail?.map((item) => ({
            value: item?.id,
            label: item?.courtName,
            status: !!item?.status,
          }));
          setCourtList(courtList);
        })
        .catch((err) => console.log(err));
    } else if (value === "fetchAllAgent") {
      getAllAgentList();
    }
  };

  const getAllAgentList = () => {
    AdministratorService.fetchAllAgent()
      .then((res) => {
        const agentList = res?.detail?.map((item) => ({
          value: item?.id,
          label: item?.agentName,
        }));
        setAgentList(agentList);
      })
      .catch((err) => console.log(err));
  };

  const openAgentMangerPopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleClick = (ctaName) => {
    openAgentMangerPopup();
    setCtaAction(ctaName);
  };

  const handleChange = (e) => {
    const { value } = e;
    const { id } = e.target.props;
    if (id === "Region") {
      setSelectedRegion(value);
      setselectedCourt("");
    } else if (id === "Court") {
      setselectedCourt(value);
    } else if (id === "Agent") {
      setSelectedAgent(value);
    }
  };

  const getCTA = (key) => {
    if (key === "Region") {
      return (
        <div>
          <Button
            fillMode="outline"
            themeColor={"primary"}
            className="m-2"
            onClick={() => handleClick("Add Region")}
          >
            Add region
          </Button>
          <span
            className="k-icon k-font-icon k-i-delete m-2"
            onClick={() => handleClick("Delete Region")}
            style={{ color: "#003E72", cursor: "pointer" }}
          />
          <span
            className="k-icon k-font-icon k-i-edit m-2"
            onClick={() => handleClick("Edit Region")}
            style={{ color: "#003E72", cursor: "pointer" }}
          />
        </div>
      );
    } else if (key === "Court") {
      return (
        <div>
          <Button
            fillMode="outline"
            themeColor={"primary"}
            className="m-2"
            onClick={() => handleClick("Add Court")}
          >
            Add Court
          </Button>
          <span
            className="k-icon k-font-icon k-i-delete m-2"
            onClick={() => handleClick("Delete Court")}
            style={{ color: "#003E72", cursor: "pointer" }}
          />
          <span
            className="k-icon k-font-icon k-i-edit m-2"
            onClick={() => handleClick("Edit Court")}
            style={{ color: "#003E72", cursor: "pointer" }}
          />
        </div>
      );
    } else if (key === "Agent") {
      return (
        <div>
          <Button
            fillMode="outline"
            themeColor={"primary"}
            className="m-2"
            onClick={() => handleClick("Add Agent")}
          >
            Add Agent
          </Button>
        </div>
      );
    }
  };

  return (
    <>
      <KendoCard
        headerTitle={
          <div style={headerContainerStyle}>
            <span style={headerStyle}>Region List</span>
            {getCTA("Region")}
          </div>
        }
        cardStyle={cardStyle}
        details={
          <div style={cardDetailStyle}>
            <div className="administrator-cta-container">
              <FormComboBox
                id={"Region"}
                onChange={handleChange}
                data={regionList}
                textField="label"
                dataItemKey="value"
                label={"Select Region"}
                value={selectedRegion}
                isDisplayLabel={true}
              />
            </div>
          </div>
        }
      ></KendoCard>
      <KendoCard
        headerTitle={
          <div style={headerContainerStyle}>
            <span style={headerStyle}>Court List</span>
            {getCTA("Court")}
          </div>
        }
        cardStyle={cardStyle}
        details={
          <div style={cardDetailStyle}>
            <div className="administrator-cta-container">
              <FormComboBox
                id={"Court"}
                onChange={handleChange}
                data={courtList}
                textField="label"
                dataItemKey="value"
                label={"Select Court"}
                value={selectedCourt}
                isDisplayLabel={true}
              />
            </div>
          </div>
        }
      ></KendoCard>
      <KendoCard
        headerTitle={
          <div style={headerContainerStyle}>
            <span style={headerStyle}>Agent List</span>
            {getCTA("Agent")}
          </div>
        }
        cardStyle={cardStyle}
        details={
          <div style={cardDetailStyle}>
            <div className="administrator-cta-container">
              <FormComboBox
                id={"Agent"}
                onChange={handleChange}
                data={agentList}
                textField="label"
                dataItemKey="value"
                label={"Select Agent"}
                value={selectedAgent}
                isDisplayLabel={true}
              />
            </div>
          </div>
        }
      ></KendoCard>

      {isPopupOpen && (
        <Dialog title={ctaAction} onClose={openAgentMangerPopup}>
          <AgentManagerPopup
            ctaAction={ctaAction}
            selectedRegion={selectedRegion}
            selectedCourt={selectedCourt}
            regionList={regionList}
            openAgentMangerPopup={openAgentMangerPopup}
            handleReload={handleReload}
            courtList={courtList}
          />
        </Dialog>
      )}
    </>
  );
};
