import React, { useState } from 'react'
import { FormCheckbox, FormInput, FormInputTick, UploadFile } from '../../../../../components';
import { Button } from '@progress/kendo-react-buttons';
import { ChallanService, VerficationService } from '../../../../../services';
import { PAYMENT_STATUSES } from '../../../components/dispose-challan-item-popup/viewModel';
import { showToastMessage } from '../../../../../utils/utils';

const RaisePaymentPopUp = (props) => {
    const [regNumber,setRegNumber] = useState();
    const [exceptionFile,setExceptionFile] = useState();
    const [paymentFile,setPaymentFile] = useState();
    const [isException,setIsException] = useState(false);
    const [isRegNumberValid,setIsRegNumberValid] = useState(false);
    const [isEditable,setIsEditable] = useState(false);
    const [settlementAmount,setSettlementAmount] = useState();
    const [agentAmount, setAgentAmount] = useState();
    const [isAgentEditable, setIsAgentEditable] = useState(false);

    const handleChange = (e) => {
        const {value} = e || {};
        const {name} = e?.target || {};
        if(name === "isException"){
            setExceptionFile(null);
        }
        e?.target?.type === "file" ? name === "exceptionProof" ?  setExceptionFile(e.target.files[0]) : setPaymentFile(e.target.files[0]) : name === "isException" ? setIsException(value) : setRegNumber(value);
    }

    const handleSettleAmountChange = (e) => {
        const {value} = e || {};
        setSettlementAmount(value);
    }

    const handleAgentAmountChange = (e) => {
        const {value} = e || {};
        setAgentAmount(value);
    }

    const handleReset = () => {
        setRegNumber("");
        setIsException(false);
        setExceptionFile(null);
        setPaymentFile(null);
    }

    const handleConfirm = () => {
        let formData = new FormData();
        formData.append("isException", isException);
        !!isException && formData.append("exception-proof", exceptionFile);
        if(props?.data?.status=== PAYMENT_STATUSES.PAID){
            ChallanService?.raisePayment(props?.data?.idChallanItem, formData).then((res) =>{
                showToastMessage("Payment Raised");
                props?.resetChallanItems();
                props?.onClose();
            }).catch((err) => {
                showToastMessage(err?.message, false);
            });
        }
        else{
            formData.append("payment-proof", paymentFile);
            !!settlementAmount?.length && formData.append("settlementAmount", settlementAmount);
            !!agentAmount?.length && formData.append("agentFee", agentAmount);
            ChallanService?.amendPayment(props?.data?.idChallanItem, formData).then((res) =>{
                showToastMessage("Raised payment Amended");
                props?.resetChallanItems();
                props?.onClose();
            }).catch((err) => {
                showToastMessage(err?.message, false);
            });
        }
    }

    const onInputLostFocus = (e) => {
        ChallanService.validateRegNumber(
            props?.data?.idChallanItem,
            e.target.value
        ).then((resp) => {
            setIsRegNumberValid(true);
        }).catch((err) => setIsRegNumberValid(false));
      };

      const handleDownloadProof = (file) => {
        if(file === "dipose"){
          VerficationService.getDisposeProof(props?.data?.idChallanItem).then((res) => {
            window.open(res?.detail, '_blank');
          }).catch((err) => showToastMessage(err?.message));
        }else if(file === "governmentFeeProof"){
            VerficationService.getGovernmentFeeProof(props?.data?.idChallanItem).then((res) => {
                window.open(res?.detail, '_blank');
              }).catch((err) => showToastMessage(err?.message));
        }else{
          VerficationService.getExceptionProof(props?.data?.idChallanItem).then((res) => {
            window.open(res?.detail, '_blank');
          }).catch((err) => showToastMessage(err?.message));
        }
      }
  return (
    <>
        <div className="info-box">Challan No: {props?.data?.noticeNumber}</div>
        <div className="info-box">
        <FormInputTick
                  id={"regNumber"}
                  key={"regNumber"}
                  onChange={handleChange}
                  onBlur={onInputLostFocus}
                  value={regNumber}
                  istick={isRegNumberValid}
                  label={"Re-enter vehicle reg number"}
                />
        </div>
        <div className="info-box">Challan Amount: {props?.data?.amount}</div>
        <div className="info-box"> {!isAgentEditable ? "Agent Fee: " + props?.data?.agentFees
        :
        <FormInput
                id={"agentAmount"}
                key={"agentAmount"}
                onChange={handleAgentAmountChange}
                value={agentAmount}
                label={"Agent Fee"}
            />
        }
        {props?.data?.status !== PAYMENT_STATUSES.PAID  && !isAgentEditable && <span style={{marginLeft:"5px", cursor:"pointer", color:"#8BB2FF", fontWeight:"700"}} className="k-icon k-i-edit k-icon-xl" onClick={() => setIsAgentEditable(!isAgentEditable) }/>}
        </div>
        <div className="info-box">{!isEditable ? "Government fee: " + props?.data?.settlementAmount 
        :
            <FormInput
                id={"settlementAmount"}
                key={"settlementAmount"}
                onChange={handleSettleAmountChange}
                value={settlementAmount}
                label={"Government fee"}
            />
      }
        {props?.data?.status !== PAYMENT_STATUSES.PAID  && !isEditable && <span style={{marginLeft:"5px", cursor:"pointer", color:"#8BB2FF", fontWeight:"700"}} className="k-icon k-i-edit k-icon-xl" onClick={() => setIsEditable(!isEditable) }/>}</div>
        <div className="info-box">Is the case an exception? <FormCheckbox name={"isException"} label={"Yes"} onChange={handleChange} value={isException}/></div>
        {isException && <div className="info-box">Upload exception proof
        <UploadFile
                  id={"file"}
                  key={"exceptionProof"}
                  name={"exceptionProof"}
                  onChange={handleChange}
                //   {...elementMetaData}
        />
        {!!props?.data?.exceptionProof && <Button onClick={() => handleDownloadProof("")}>Exception Proof</Button>
        }
         </div>}
         {props?.data?.status=== PAYMENT_STATUSES.RAISE_REJECTED && <div className="info-box">Upload payment proof
        <UploadFile
                  id={"file"}
                  key={"paymentProof"}
                  name={"paymentProof"}
                  onChange={handleChange}
                //   {...elementMetaData}
        />
        {!!props?.data?.challanPaymentProof && <Button onClick={() => handleDownloadProof("dipose")}>Challan Payment Proof</Button>
        }
        {!!props?.data?.govenmentFeeProof && <Button className= "m-1" onClick={() => handleDownloadProof("governmentFeeProof")}>Government Fee Proof</Button>
        }
         </div>}
        <Button
            onClick={handleReset}
            className="k-button k-button-md k-button-outline k-button-outline-primary k-rounded-md m-2"
            themeColor={"primary"}
        >
            Reset
        </Button>
        <Button
            onClick={handleConfirm}
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base m-2"
            themeColor={"primary"}
        >
            Confirm
        </Button>
        
    </>
  )
}
export default RaisePaymentPopUp;
