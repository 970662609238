import { Button } from "@progress/kendo-react-buttons";
import AssignmentTypesFilter from "../filters/components/assignment-types";
import { FF_PAGE_TYPES } from "../../../../utils/constants/values.constants";
import { getFFUserType } from "../../../../utils/utils";
import { USER_ROLE_KEYS } from "../../../../utils/constants/menu.constants";

const ChallanListToolbarItems = (props) => {
	const isUserFinder = getFFUserType() === USER_ROLE_KEYS.FINDER;
	return (
		<div className="ops-grid-toolbar-items">
			<div>
				<AssignmentTypesFilter pageKey={props?.pageKey} />
			</div>
			{props?.pageKey === FF_PAGE_TYPES.FINDERS && (
				<div className="k-form-buttons">
					{isUserFinder && (
						<Button
							className="m-2"
							themeColor={"primary"}
							onClick={() => props.AssignToMe()}>
							Assign to Me
						</Button>
					)}
					<Button
						className="m-2"
						themeColor={"primary"}
						onClick={() => props.MarkAsResolved()}>
						Mark as Resolved
					</Button>
				</div>
			)}
		</div>
	);
};
export default ChallanListToolbarItems;
