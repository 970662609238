import React from 'react';
import { Field, ErrorMessage } from 'formik';
import RichTextEditor from './components/rich-text-editor';
import LabelComponent from '../label';
import styles from '../../style.module.css';

const RichTextEditorComponent = ({ name, label, length }) => (
    <div className={styles.mb30}>
        <LabelComponent title={label} length={length} />
        <Field name={name} component={RichTextEditor} />
        <ErrorMessage name={name} component="div" className={styles.error} />
    </div>
);

export default RichTextEditorComponent;
