import { Dialog } from "@progress/kendo-react-dialogs";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { KendoCard } from "../../../../../../components";
import { Button } from "@progress/kendo-react-buttons";
import { UploadCard } from "./upload-card";
import styles from "../../../negotiation/assets/styles/pr.styles.module.css";
import { DmsService } from "../../../../../../services";
import { showToastMessage } from "../../../../../../utils/utils";
import { PR_AUTOMATION } from "../../../../../../store/types";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { NUMBERS } from "../../../../../../utils/constants/values.constants";
import {
  fetchDocsStatus,
  fetchKycDocs,
} from "../../../../../../store/actions/pr-automation";
import { Loader } from "../../../../../../common/Loader";
export const UploadDocs = (props) => {
  const lms = useSelector((state) => state.lms);
  const { appointmentId } = lms || {};
  const prDetails = useSelector((state) => state.prAutomation);
  const {
    docs = [],
    isLoading: prLoading,
    uploadAuthData = {},
  } = prDetails || {};
  const { utid = "", jti = "", accessToken = "" } = uploadAuthData || {};
  const dispatch = useDispatch();
  const { setDocCode, docCode } = props || {};
  const [imageList, setImageList] = useState({});
  const [selectedDoc, setSelectedDoc] = useState({});
  const [pageCount, setPageCount] = useState(1);
  const [uploadedPageCount, setUploadedPageCount] = useState(0);
  useEffect(() => {
    if (docCode && docs?.length) {
      const filteredDoc = docs.find((doc) => doc.code === docCode);
      setSelectedDoc(filteredDoc);
      if (filteredDoc?.page?.length) {
        setPageCount(NUMBERS.TWO);
      } else {
        setPageCount(NUMBERS.ONE);
      }
    }
  }, [docCode, docs]);
  const handleSubmit = async () => {
    const headers = {
      Authorization: `BEARER ${accessToken}`,
      jti,
      utid,
    };
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    imageList &&
      Object.keys(imageList)?.forEach((page) => {
        const formData = new FormData();
        formData.append("appointmentId", appointmentId);
        formData.append("imageSource", "receive");
        formData.append("isRetailPipeline", true);
        formData.append("docCode", docCode);
        formData.append("page", page);
        formData.append("file", imageList[page][0]);
        formData.append("client", "c2b-internal-portal");
        formData.append("verificationType", "ADMIN_RA");
        formData.append("uploadSource", "ADMIN_RA");

        DmsService.uploadDocs(formData, headers)
          .then((res) => {
            if (res && res.status === 200) {
              dispatch(fetchKycDocs(appointmentId));
              dispatch(fetchDocsStatus(appointmentId));
              setDocCode("");
              showToastMessage("Documents uploaded successfully", true);
            }
          })
          .catch((err) => {
            showToastMessage(err?.message || "Error", false);
            isError = true;
          })
          .finally(() => {
            dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
          });
      });
  };
  const customTitleRender = () => {
    return (
      <div>
        <span className="k-font-bold k-font-size-xl k-text-black">{`Upload ${selectedDoc?.name}`}</span>
      </div>
    );
  };
  return (
    <Dialog
      title={customTitleRender()}
      onClose={() => setDocCode("")}
      contentStyle={{ width: "auto", minWidth: "640px" }}
    >
      <GridLayout
        cols={[
          {
            width: pageCount === NUMBERS.TWO ? "50%" : "100%",
          },
          {
            width: "50%",
          },
        ]}
      >
        <GridLayoutItem row={1} col={1}>
          <KendoCard
            cardStyle={{
              marginTop: "0px",
              minWidth: "400px",
            }}
            headerTitle={`${selectedDoc?.name} Front`}
            details={
              <UploadCard
                imageList={imageList}
                setImageList={setImageList}
                page={"(F)"}
                selectedDoc={selectedDoc}
                setUploadedPageCount={setUploadedPageCount}
              />
            }
            titleStyle={{
              fontSize: "16px",
              fontWeight: 500,
              textAlign: "center",
            }}
          />
        </GridLayoutItem>
        {pageCount === NUMBERS.TWO && (
          <GridLayoutItem row={1} col={2}>
            <KendoCard
              cardStyle={{
                marginTop: "0px",
                minWidth: "400px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              headerTitle={`${selectedDoc?.name} Back`}
              titleStyle={{
                fontSize: "16px",
                fontWeight: 500,
                textAlign: "center",
              }}
              details={
                <UploadCard
                  imageList={imageList}
                  setImageList={setImageList}
                  page={"(B)"}
                  selectedDoc={selectedDoc}
                  setUploadedPageCount={setUploadedPageCount}
                />
              }
            />
          </GridLayoutItem>
        )}
        {prLoading ? <Loader /> : null}
        <GridLayoutItem className="k-mt-5" row={2} colSpan={pageCount}>
          <div className="justify-between">
            <div>
              <div className="k-font-size-sm">
                Upload Image (JPG, JPEG, GIF, PNG) or PDF files
              </div>
              <div className="k-font-size-sm">
                Files shouldn't exceed the size of 10 MB
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <Button
                className={`k-mr-5 ${styles.cancelCta}`}
                onClick={() => setDocCode("")}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                className={styles.submitCta}
                disabled={
                  !imageList ||
                  !Object.keys(imageList)?.length ||
                  uploadedPageCount !== pageCount
                }
              >
                Submit
              </Button>
            </div>
          </div>
        </GridLayoutItem>
      </GridLayout>
    </Dialog>
  );
};
