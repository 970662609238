function AllDealersIcon(props) {
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5 9a.5.5 0 01-.5.5h-1v1a.5.5 0 01-1 0v-1h-1a.5.5 0 010-1h1v-1a.5.5 0 011 0v1h1a.5.5 0 01.5.5zm-3.617 3.678a.5.5 0 11-.766.644C10.859 11.824 9.131 11 7.25 11c-1.88 0-3.61.824-4.867 2.322a.5.5 0 11-.765-.644c.933-1.111 2.095-1.9 3.385-2.323a4.25 4.25 0 114.494 0c1.29.423 2.452 1.212 3.386 2.323zM7.25 10a3.25 3.25 0 100-6.5 3.25 3.25 0 000 6.5z"
        fill="#465166"
      />
    </svg>
  )
}

export default AllDealersIcon
