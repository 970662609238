import { Button } from "@progress/kendo-react-buttons";
import { useSelector, useDispatch } from "react-redux";
import { KendoCard } from "../../../../../../components";
import { Loader } from "@progress/kendo-react-indicators";
import { numberFormat, showToastMessage } from "../../../../../../utils/utils";
import styles from "../../../negotiation/assets/styles/pr.styles.module.css";
import { PrAutomationService } from "../../../../../../services";
import { PR_AUTOMATION } from "../../../../../../store/types";
import useAuditChecks from "../../../hooks/useAuditChecks";
import { isPrInitialized } from "../viewModel";
export const SubmitPrCard = (props) => {
  const dispatch = useDispatch();
  const { chargesAmount, setIsAuditLoader, setIsAuditChecks } = props || {};
  const prDetails = useSelector((state) => state.prAutomation);
  const { prSubmitLoading, c24Quote, orderData } = prDetails || {};
  const { purchase: { purchaseStatus = "" } = {} } = orderData || {};
  const lms = useSelector((state) => state.lms);
  const { appointmentId } = lms || {};
  const [handleInitiateAudit] = useAuditChecks({
    setIsAuditLoader,
    setIsAuditChecks,
  });

  const handleSubmitPr = () => {
    dispatch({ type: PR_AUTOMATION.FETCH_PR_SUBMIT_LOADING_START });
    PrAutomationService.getPrSubmitAllowed(appointmentId)
      .then((resp) => {
        if (resp && resp?.status === 200) {
          if (resp?.detail) {
            handleInitiateAudit();
          } else {
            showToastMessage(resp?.message, false);
          }
        } else {
          showToastMessage(resp?.message, false);
        }
      })
      .catch((err) => {
        if (err) {
          showToastMessage(err?.message || "Error", false);
        }
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_PR_SUBMIT_LOADING_END });
      });
  };
  return (
    <KendoCard
      details={
        <div className="justify-between">
          <div className="align-center k-text-white">
            <span className="k-font-bold k-font-size-large k-mr-2">
              Net payable amount:
            </span>
            <span className="k-font-bold k-font-size-xl">
              {c24Quote - chargesAmount
                ? numberFormat(c24Quote - chargesAmount)
                : numberFormat(0)}
            </span>
          </div>

          <Button
            style={{
              width: "238px",
            }}
            className={styles.popCta}
            onClick={handleSubmitPr}
            disabled={!isPrInitialized(purchaseStatus)}
          >
            <span className="k-mr-4">Send PR for review</span>
            {prSubmitLoading && (
              <Loader
                size="medium"
                type="pulsing"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "90%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            )}
          </Button>
        </div>
      }
      cardStyle={{
        background: "#343D4C",
      }}
      cardBodyStyle={{ paddingBottom: 0 }}
    />
  );
};
export default SubmitPrCard;
