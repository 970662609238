import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { KendoCard, KendoListViewV2 } from "../../../../../../../components";
import { getBadge } from "../../../pr-automation/viewModel";
import { bankStatusMapping } from "../../../../../../../utils/constants/values.constants";
import styles from '../style.module.css';

const BankDetailsCard = () => {
  const prDetails = useSelector((state) => state.prAutomation);
  const { orderData } = prDetails || {};
  const { secondaryBankDetails, primaryBankDetails, pennyDropTxn } =
    orderData || {};
  const [primaryBankStatus, setPrimaryBankStatus] = useState();
  const [secondaryBankStatus, setSecondaryBankStatus] = useState("");
  const getBankAccountDetails = () => {
    let primaryAccount = { value: "Not Available", css: "grey" };
    let secondaryAccount = "Not Available";
    if (orderData) {
      if (secondaryBankDetails) {
        if (secondaryBankDetails.accountType) {
          secondaryAccount = "Available";
        }
      }
      if (pennyDropTxn) {
        primaryAccount = {
          value: bankStatusMapping(pennyDropTxn.transactionStatus)?.value,
          css: bankStatusMapping(pennyDropTxn.transactionStatus)?.css,
        };
      }
    }
    return { primaryAccount, secondaryAccount };
  };
  useEffect(() => {
    setPrimaryBankStatus(getBankAccountDetails()?.primaryAccount);
    setSecondaryBankStatus(getBankAccountDetails()?.secondaryAccount);
  }, [primaryBankDetails, secondaryBankDetails]);

  const bankDetails = [
    {
      details: "Primary Bank Account",
      value: primaryBankStatus?.value,
    },
    {
      details: "Secondary Bank Account",
      value: secondaryBankStatus,
    },
  ];
  return (
    <div className={styles.head}>
      <div className={styles.head_title}>
        Bank Details
      </div>
      <div className={`${styles['last']} ${styles['quote_container']}`}>
        <div className={styles['quote_container_data']}>
          <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
            {bankDetails?.[0]?.details}
          </span>
          <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
            {bankDetails?.[0]?.value}
          </span>
        </div>
        <div className={styles['quote_container_data']}>
          <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
            {bankDetails?.[1]?.details}
          </span>
          <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
            {bankDetails?.[1]?.value}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BankDetailsCard;
