export const service_column_keys = {
  LABEL: "label",
  VALUE: "value",
};

export const service_history_columns = {
  [service_column_keys.LABEL]: {
    field: service_column_keys.LABEL,
    label: "Item",
  },
  [service_column_keys.VALUE]: {
    field: service_column_keys.VALUE,
    label: "Details",
  },
};

export const getDisplayedCoulmns = (columns) => {
  return Object.keys(columns);
};
