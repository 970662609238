export const  finder_detail_list_keys= [
    {
        title:"Appt Id",
        field:"appointmentOrderId",
    },
    {
        title:"Reg Number",
        field:"regNumber",
    },
    {
        title:"Source",
        field:"sourceCode"
    },
    {
        title:"Region",
        field:"retailRegion"
    },
    {
        title:"Attempt Count",
        field:"attemptCount"
    },
    {
        title:"No. of Challans added",
        field:"itemCount"
    },
    {
        title:"Description",
        field:"description"
    },
    {
        title:"Creation Date",
        field:"createdAt"
    },
];
