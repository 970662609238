import { lazy } from 'react';
import AccountView from "../views/account/AccountContainer";
import ChallanListView from "../views/operations/challan-list";
import ChallanHome from "../views/operations/challan-home";
import DealerView from "../views/dealer/fulfillment-list";
import CreateDealer from "../views/dealer/components/create-dealer";
import CreateCenter from "../views/dealer/components/create-center";
import CentreDetail from "../views/dealer/components/centre-detail";
import PaymentsView from "../views/dealer/payment-list";
import PaymentDetail from "../views/dealer/payment-detail";
import PRAutomationView from "../views/retail/lms/negotiation/pr-automation";
import PRSubmissionView from "../views/retail/lms/negotiation/pr-submission";
import ServiceHistoryContainer from "../views/operations/service-history-container";
import ServiceHistoryDetails from "../views/operations/service-history/components/service-history-details";
import VerificationDetails from "../views/operations/verification/components/verification-details";
import LeadMonListView from "../views/retail/lead-mon";
import LeadMonDetailView from "../views/retail/lead-mon-detail";
import AdministratorView from "../views/operations/administrator";
import ConsumerAdminSettings from "../views/consumer/admin-settings";
import VerificationContainer from "../views/operations/verification-container";
import PriceUpdate from "../views/vas/price-update";
import CjIncentivesContainer from "../panels/inspection/cj-incentives";
import CjVerification from "../views/inspection/cj-footprint";
import TriggerManager from "../views/inspection/trigger-manager";
import LeadsView from "../views/seller-finance/leads";
import DetailsView from "../views/seller-finance/details";
import LmsRetailView from "../views/lms/retail";
import ZmView from '../views/lms/retail/zm-view';
import UploadLeads from "../views/seller-finance/upload-leads";
import BuyBack from '../views/dealer-app/buy-back';
import VizPanelRoutes from '../views/viz-panel';
import Segmentation from '../views/dealer-app/segmentation';

const HypoPanel = lazy(() => import("../views/qc/hypo-panel"));

export const RoutesConfig = [
  {
    path: "/login",
    exact: true,
    component: AccountView,
  },
  {
    path: "/",
    exact: true,
    component: AccountView,
  },
  {
    text: "Challans",
    path: "/operations/challans",
    exact: true,
    isSecure: true,
    component: ChallanHome,
  },
  {
    text: "CJ Verification",
    path: "/inspection/cj-footprint",
    exact: true,
    isSecure: true,
    component: CjVerification,
  },
  {
    text: "Trigger Manager",
    path: "/inspection/trigger-manager",
    exact: true,
    isSecure: true,
    component: TriggerManager,
  },
  {
    text: "Challans",
    path: "/operations/v1/challans",
    exact: true,
    isSecure: true,
    component: ChallanListView,
  },
  {
    text: "Challans",
    path: "/operations/v1/admin/finders",
    exact: true,
    isSecure: true,
    component: ChallanListView,
  },
  {
    text: "Challans",
    path: "/operations/v1/admin/disposers",
    exact: true,
    isSecure: true,
    component: ChallanListView,
  },
  {
    text: "Finder",
    path: "/operations/admin/finders",
    exact: true,
    isSecure: true,
    component: ChallanHome,
  },
  {
    text: "Disposer",
    path: "/operations/admin/disposers",
    exact: true,
    isSecure: true,
    component: ChallanHome,
  },
  {
    text: "Verification",
    path: "/operations/verification",
    exact: true,
    isSecure: true,
    component: VerificationContainer,
  },
  {
    text: "Verification",
    path: "/operations/verification/:idChallanVerification",
    exact: true,
    isSecure: true,
    component: VerificationDetails,
  },
  {
    text: "ServiceHistory",
    path: "/operations/services",
    exact: true,
    isSecure: true,
    component: ServiceHistoryContainer,
  },
  {
    text: "ServiceHistory",
    path: "/operations/admin/services",
    exact: true,
    isSecure: true,
    component: ServiceHistoryContainer,
  },
  {
    text: "ServiceHistory",
    path: "/operations/services/:orderId",
    exact: true,
    isSecure: true,
    component: ServiceHistoryDetails,
  },
  {
    text: "Fulfillment",
    path: "/dealer/fulfillments",
    exact: true,
    isSecure: true,
    isChild: true,
    component: DealerView,
  },
  {
    text: "CreateDealer",
    path: "/dealer/fulfillments/createDealer",
    exact: true,
    isSecure: true,
    isChild: true,
    component: CreateDealer,
  },
  {
    text: "CenterDetailView",
    path: "/dealer/fulfillments/centerdetailview/:dealerId",
    exact: true,
    isSecure: true,
    isChild: true,
    component: CentreDetail,
  },
  {
    text: "CreateDealerCenter",
    path: "/dealer/fulfillments/createDealerCenter/:dealerId",
    exact: true,
    isSecure: true,
    isChild: true,
    component: CreateCenter,
  },
  {
    text: "CreateDealerCenter",
    path: "/dealer/fulfillments/updateDealerCenter/:dealerId/:centreId",
    exact: true,
    isSecure: true,
    isChild: true,
    component: CreateCenter,
  },
  {
    text: "Payment",
    path: "/dealer/payouts",
    exact: true,
    isSecure: true,
    isChild: true,
    component: PaymentsView,
  },
  {
    text: "Payment Detail",
    path: "/dealer/payouts/detail/:leadId",
    exact: true,
    isSecure: true,
    isChild: true,
    component: PaymentDetail,
  },
  {
    text: "PR Automation - Negoview",
    path: "/retail/lms/negoview/:appointmentId",
    exact: true,
    isSecure: true,
    isChild: true,
    component: PRAutomationView,
  },
  {
    text: "PR Submission - Negoview",
    path: "/retail/lms/negoview/:appointmentId/:make/:model",
    exact: true,
    isSecure: true,
    isChild: true,
    component: PRSubmissionView,
  },
  {
    text: "Lead Mon",
    path: "/retail/leadmon",
    exact: true,
    isSecure: true,
    isChild: true,
    component: LeadMonListView,
  },
  {
    text: "LeadMon Detail",
    path: "/retail/leadmon/add",
    exact: true,
    isSecure: true,
    isChild: true,
    component: LeadMonDetailView,
  },
  {
    text: "AdminstratorView",
    path: "/operations/adminstratorView",
    exact: true,
    isSecure: true,
    component: AdministratorView,
  },
  {
    text: "Consumer Settings",
    path: "/consumer/settings",
    exact: true,
    isSecure: true,
    isChild: true,
    component: ConsumerAdminSettings,
  },
  {
    text: "Consumer Settings",
    path: "/consumer/vas/priceupdate",
    exact: true,
    isSecure: true,
    isChild: true,
    component: PriceUpdate,
  },
  {
    text: "All Cars",
    path: "/viz/all-cars",
    exact: true,
    isSecure: true,
    isChild: true,
    component: VizPanelRoutes,
  },
  {
    text: 'Call Logs',
    path: '/viz/call-logs',
    exact: true,
    isSecure: true,
    isChild: true,
    component: VizPanelRoutes,

  },
  {
    text: "My Dealers",
    path: "/viz/my-dealers",
    exact: true,
    isSecure: true,
    isChild: true,
    component: VizPanelRoutes,
  }, {
    text: "All PAIs",
    path: "/viz/pais",
    exact: true,
    isSecure: true,
    isChild: true,
    component: VizPanelRoutes,
  }, {
    text: "Leads",
    path: "/seller-finance/leads",
    exact: true,
    isSecure: true,
    isChild: true,
    component: LeadsView,
  }
  , {
    text: "Leads",
    path: "/seller-finance/leads/:id",
    exact: true,
    isSecure: true,
    isChild: true,
    component: DetailsView,
  }, {
    text: "CJ Incentives",
    path: "/inspection/incentives",
    exact: true,
    isSecure: true,
    isChild: true,
    component: CjIncentivesContainer,
  },
  {
    text: "CJ Incentives",
    path: "/inspection/admin",
    exact: true,
    isSecure: true,
    isChild: true,
    component: TriggerManager,
  },
  {
    text: "Hypo Qc Panel",
    path: "/qc/hypo-qc-panel",
    exact: true,
    isSecure: true,
    isChild: true,
    component: HypoPanel,
  }, {
    text: "LmsRetailView",
    path: "/lms/retargeting",
    exact: true,
    isSecure: true,
    isChild: true,
    component: LmsRetailView,
  }, {
    text: "LmsRetailView",
    path: "/lms/retargeting/dashboard",
    exact: true,
    isSecure: true,
    isChild: true,
    component: ZmView,
  },
  {
    text: "Upload Leads",
    path: "/seller-finance/upload-leads",
    exact: true,
    isSecure: true,
    isChild: true,
    component: UploadLeads,
  },
  {
    text: "Buy Back",
    path: "/dealer/buy-back",
    exact: true,
    isSecure: true,
    isChild: true,
    component: BuyBack,
  },
  {
    text: "Segmentation",
    path: "/dealer/segmentation",
    exact: true,
    isSecure: true,
    isChild: true,
    component:Segmentation

  },
  {
    text: "Control Center",
    path: "/viz/control-center",
    exact: true,
    isSecure: true,
    isChild: true,
    component: VizPanelRoutes,
  }
];