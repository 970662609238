import React, { useState, useEffect } from 'react';
import styles from './style.module.css';
import { Link } from 'react-router-dom';
import { PRIORITY_STYLES, TAGS_STYLES } from '../../../../../lms/retail/view-model';
import { commonApis } from '../../../../../../services/commonApi';
import useLazyQuery from '../../../../../../hooks/useLazyQuery';

// Utility to flatten tags into a single list
const getAllTags = (tags) => {
  return tags?.reduce((acc, category) => {
    return [...acc, ...category.tags.map(tag => ({ ...tag, category: category.category, categoryLabel: category.categoryLabel }))];
  }, []);
};

function NegoHeader({
  appointmentId,
  crmDetails,
  MMVY,
  variant,
  ownerNameOnRc,
}) {
  const selectedTagsInitial = getAllTags(crmDetails.data.selectedTags);
  const allTags = crmDetails.data.tags;

  const [isTagClicked, setIsTagClicked] = useState(false);
  const [currentTags, setCurrentTags] = useState(selectedTagsInitial);
  const [selectedTags, setSelectedTags] = useState(selectedTagsInitial);
  const { apiCbFunction: updateTags, isLoading: isUpdateTagsLoading } = useLazyQuery(commonApis.updateTags);

  useEffect(() => {
    setCurrentTags(selectedTagsInitial);
    setSelectedTags(selectedTagsInitial);
  }, [crmDetails]);

  const handleRemoveTag = (tag) => {
    setSelectedTags(selectedTags.filter(item => item.value !== tag.value));
  };

  const handleAddTag = (tag) => {
    const tagCategory = crmDetails?.data?.tags?.find(category => category.category === tag.category);
    const categoryTags = selectedTags?.filter(item => item.category === tag.category);

    if (categoryTags?.length < tagCategory?.allowedCount) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      const newSelectedTags = selectedTags?.filter(item => item.category !== tag.category)??[];
      setSelectedTags([...newSelectedTags, tag]);
    }
  };

  const tagUpdateHandler = async () => {
    try {
      await updateTags({
        body: {
          appointmentId,
          leadTags: selectedTags.map(tag => tag.value)
        }
      });
      setIsTagClicked(false);
      setCurrentTags(selectedTags);
    } catch (error) {
      console.error("Error updating tags:", error);
    }
  };

  const closebtnHandler = () =>{
    setIsTagClicked(false);
  }

  return (
    <div className={styles.nego_header}>
      <div className={styles.nego_breadcrumb}>
        <Link className={styles.nego_breadcrumb__prevLink} to='/lms/retargeting'>
          Retargeting
        </Link>
        <div className={styles.nego_breadcrumb__seperator}>/</div>
        <div className={styles.nego_breadcrumb__currLink}>
          {appointmentId}{ownerNameOnRc ? ' - ' : ''}{ownerNameOnRc}
        </div>
      </div>
      <div className={styles.additional_info}>
        <div className={styles.mmvy_div}>
          <p className={styles.mmvy_div_label}>MMVY: </p>
          <p className={styles.mmvy_div_name}>{`${MMVY} | ${variant}`}</p>
        </div>
        <div className={styles.priority_div}>
          <p className={styles.priority_div_label}>Priority: </p>
          <p className={styles.priority_div_name}>{crmDetails?.data?.priority?.label ?? 'NA'}</p>
        </div>
      </div>
      <div className={styles.nego_header_tags_container}>
        <div
          className={`${styles.nego_header_tags} ${isTagClicked ? styles.nego_header_tags_active : ''}`}
          onClick={() => setIsTagClicked(true)}
        >
          {currentTags?.length > 0 ? (
            currentTags?.map((item) => (
              <div
                key={item.value}
                className={styles.nego_header_tags_data}
                style={TAGS_STYLES[item.value]}
              >
                {item.label}
              </div>
            ))
          ) : (
            <div className={styles.nego_header_tags_add}>+ Add Tag</div>
          )}
        </div>
        {isTagClicked && (
          <div className={styles.nego_header_dropdown_div}>
            <div className={styles.nego_header_dropdown_div_currSelected}>
              {selectedTags?.map((item) => (
                <div
                  key={item.value}
                  className={styles.nego_header_tags_data}
                  style={TAGS_STYLES[item.value]}
                  onClick={() => handleRemoveTag(item)}
                >
                  {item.label}
                  <span className={`k-icon k-font-icon k-i-close ${styles.nego_header_tags_data_cross}`}></span>
                </div>
              ))}
            </div>
            <div className={styles.nego_header_dropdown_div_optionsDiv}>
              <p className={styles.nego_header_dropdown_div_optionsHeader}>SELECT AN OPTION</p>
              {
                allTags?.map(category => (
                  <div key={category.category}>
                    <p className={styles.nego_header_dropdown_div_categoryHeader}>{category.categoryLabel}</p>
                    <div className={styles.nego_header_dropdown_div_options}>
                      {
                        category?.tags.filter(tag => !selectedTags?.some(selected => selected.value === tag.value))?.map((item) => (
                          <div
                            key={item.value}
                            className={`${styles.nego_header_tags_data} ${styles.nego_header_tags_data_listing}`}
                            style={TAGS_STYLES[item.value]}
                            onClick={() => handleAddTag({ ...item, category: category.category })}
                          >
                            {item.label}
                          </div>
                        ))
                      }
                    </div>
                  </div>
                ))
              }
            </div>
            <div className={styles.nego_header_dropdown_actions}>
              <button
                className={styles.nego_header_dropdown_actions_save}
                onClick={tagUpdateHandler}
                disabled={isUpdateTagsLoading}
              >
                {isUpdateTagsLoading ? 'Updating...' : 'Update'}
              </button>
              <button
                className={styles.nego_header_dropdown_actions_close}
                onClick={closebtnHandler}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NegoHeader;
