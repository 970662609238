import React, { useContext, useEffect } from "react";
import { Descope, useSession, useUser } from "@descope/react-sdk";
import { getHomePageByRole, saveToLocalStorage } from "../../utils/utils";
import { useHistory } from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from "../../utils/constants/values.constants";
import { trackAnalytics } from "../viz-panel/common/services/analytics";
import { SegmentEvents } from "../viz-panel/common/constants/events.constants";
import LmsStateManagementContext from "../../context/lms-stage-management-context";
const DescopeSignInWidget = () => {
	const { isAuthenticated, isSessionLoading } = useSession();
  const { addRaActivityHandler } = useContext(LmsStateManagementContext)
	const { isUserLoading, user } = useUser();
	const path = getHomePageByRole();
	const history = useHistory();

	useEffect(() => {
		if (user && isAuthenticated && path) {
			history.push(path);
		}
	}, [user, isAuthenticated]);

	if (isSessionLoading || isUserLoading) {
		return <p>Loading...</p>;
	}

	const onUserLoginSuccess = (e) => {
		!!e?.detail?.user &&
			saveToLocalStorage(LOCAL_STORAGE_KEYS.USER_INFO, e?.detail?.user);

    addRaActivityHandler({activityStatus:'ACTIVE',activity:'LOGIN'})
		trackAnalytics(SegmentEvents.Login_try, {email:e?.detail?.user?.email});
		if(!!e?.detail?.user){
			trackAnalytics(SegmentEvents.Login, {email:e?.detail?.user?.email});
			saveToLocalStorage(LOCAL_STORAGE_KEYS.USER_LOGIN_DATE_TIME, new Date());
		} 
	};
	
	const onFailedLogin = (e) => {
		console.log("login failed");
		trackAnalytics(SegmentEvents.Login_try, {email:e?.detail?.user?.email || null});
	};
	return (
		<Descope
			flowId={"c2b-internal-portal-login"}
			onSuccess={onUserLoginSuccess}
			onError={onFailedLogin}
		/>
	);
};
export default DescopeSignInWidget;
