import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import KycCard from "./kyc-card";
import BankDetailsCard from "./bank-details-card";
import { KendoCard } from "../../../../../../components";
const KycBankDetailsCard = ({ appointmentId }) => {
    return (
        <KendoCard
            cardStyle={{
                background: "white",
            }}
            headerTitle="KYC & Bank Details"
            details={
                <GridLayout
                    gap={{ rows: 20, cols: 0 }}
                >
                    <GridLayoutItem row={1} >
                        <KycCard appointmentId={appointmentId} />
                    </GridLayoutItem>
                    <GridLayoutItem row={2} >
                        <BankDetailsCard />
                    </GridLayoutItem>
                </GridLayout>
            }
        />
    );
};
export default KycBankDetailsCard;
