import { getFFUserType } from "../utils/utils";

export default (api) => {
  const fetchMakers = () => {
    return new Promise((resolve, reject) => {
      api
        .get(`/make`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchModels = (makerId) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/make/${makerId}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchMakeYears = (makeId) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/make/${makeId}/years`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchCityList = () => {
    return new Promise((resolve, reject) => {
      api
        .get(`/city`,  {
					headers: { "user-type": `${getFFUserType()}` },
				})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const fetchCitiesById = (id) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/state/${id}/city`, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data.detail);
        })
        .catch((error) => {
            reject(error)
        });
    });
  };
  
  const fetchStates = () => {
    return new Promise((resolve, reject) => {
      api
        .get(`/get-states`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
        .then((response) => {
          resolve(response.data.detail);
        })
        .catch((error) => {
            reject(error)
        });
    });
  };

  return {
    fetchMakeYears,
    fetchModels,
    fetchMakers,
    fetchCityList,
    fetchStates,
    fetchCitiesById
  };
};
