import React, { useState, useEffect } from "react";
import {
    GridLayout,
    GridLayoutItem,
    TabStrip,
    TabStripTab,
} from "@progress/kendo-react-layout";
import { TAB_STRIPS, INITIAL_ANNOUNCEMENT_DATA, INITIAL_REFERRAL_DATA } from "./viewModel";
import styles from './styles.module.css';
import ActionButtons from "./components/action-buttons";
import AnnouncementView from "./components/announcement-view";
import AnnouncementForm from "./components/announcement-form";
import ReferralView from "./components/referral-view";
import ReferralForm from "./components/referral-form";
import ClusterView from "./components/cluster-form";
import { getUserRoles } from "../../../utils/utils";
import { USER_ROLE_KEYS } from "../../../utils/constants/menu.constants";

const TriggerManager = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [details, setDetails] = useState(null);
    const [itemType, setItemType] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [accessibleTabs, setAccessibleTabs] = useState([]);

    const roles = getUserRoles();

    useEffect(() => {
        if(accessibleTabs.length == 0) {
            const filteredTabs = TAB_STRIPS.filter((item) => 
                roles.some((role) => item.role.includes(role))
            );
            setAccessibleTabs(filteredTabs);
        }
    }, [roles]);

    const renderTabStrips = () => {
        return accessibleTabs.map((item) => (
            <TabStripTab title={item.label} key={item.key}>
                {renderTabStripComponent(item.key)}
            </TabStripTab>
        ));
    };

    const renderTabStripComponent = (key) => {
        switch (key) {
            case "CJ_ANNOUNCEMENTS":
                return <AnnouncementView setAnnouncementDetails={setDetails} setItemType={setItemType} />
            case "CJ_REFERRAL":
                return <ReferralView setReferralDetails={setDetails} />
            case "CLUSTER_MANAGER":
                return <ClusterView isOpen={isOpen} setIsOpen={setIsOpen} />
            default:
                return <></>
        }
    }

    const renderDetailsComponent = () => {
        switch (itemType) {
            case "Announcement":
                return <AnnouncementForm announcementData={details} handleBack={handleBack} />;
            case "Referral":
                return <ReferralForm referralData={details} handleBack={handleBack} />
            default:
                return <></>;
        }
    };

    const handleAddItem = (type) => {
        setItemType(type);
        switch (type) {
            case "Announcement":
                setDetails(INITIAL_ANNOUNCEMENT_DATA);
                break;
            case "Referral":
                setDetails(INITIAL_REFERRAL_DATA);
                break;
            default:
                break;
        }
    };

    const handleBack = () => {
        setDetails(null);
        setItemType(null);
    };

    const handleTabSelect = (e) => {
        setSelectedTab(e.selected);
        setDetails(null);
        setItemType(null);
    }

    const renderActionButtons = () => {
        const currentTabKey = accessibleTabs[selectedTab]?.key;
        switch (currentTabKey) {
            case "CJ_ANNOUNCEMENTS":
                return roles.includes(USER_ROLE_KEYS.C2B_INSP_ANNOUNCEMENT_ADMIN) && (
                    <ActionButtons onAddItem={() => handleAddItem("Announcement")} itemType="Announcement" />
                );
            case "CJ_REFERRAL":
                return roles.includes(USER_ROLE_KEYS.C2B_INSP_CJ_REFERRAL_ADMIN) && (
                    <ActionButtons onAddItem={() => handleAddItem("Referral")} itemType="Referral Content" />
                );
            case "CLUSTER_MANAGER":
                return roles.includes(USER_ROLE_KEYS.C2B_OPS_QC_CJ_INCENTIVE_ADMIN) && (
                    <ActionButtons themeColor={"primary"} onAddItem={() => setIsOpen(!isOpen)} itemType="Cluster" />
                );
            default:
                return null;
        }
    };

    return (
        <div className="ops-challan-container">
            <div className="ops-grid-layout-container">
                {details ? (
                    renderDetailsComponent()
                ) : (
                    <>
                        <GridLayout
                            gap={{
                                rows: "5px",
                                cols: 10,
                            }}
                        >
                            <GridLayoutItem
                                row={1}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div className={styles.listingHeader}>Trigger Manager</div>
                                <div style={{ display: 'flex' }}>
                                    {renderActionButtons()}
                                </div>
                            </GridLayoutItem>
                            <GridLayoutItem row={3}>
                                <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                                    {renderTabStrips()}
                                </TabStrip>
                            </GridLayoutItem>
                        </GridLayout>
                    </>
                )}
            </div>
        </div>
    );
};
export default TriggerManager;
