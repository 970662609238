import { KendoCard, KendoListView } from "../../../../../../../components";
import { numberFormat } from "../../../../../../../utils/utils";
import { defaultStyle, ChargeItem } from "../view-modals";

const DeductibleChargesCard = (props) => {
	const { deductibleChargeDetails } = props || {};
	const renderHeaderTitle = () => {
		return (
			<div className="justify-between">
				<div className="k-font-medium" style={{ fontSize: "12px" }}>
					Deductible charges
				</div>
				<div
					className="k-font-bold k-font-size-lg"
					style={{ color: "#D92D20",fontSize: "12px"}}>
					{numberFormat(props.chargesAmount)}
				</div>
			</div>
		);
	};

	return (
		<KendoCard
			headerTitle={renderHeaderTitle()}
			cardStyle={{ marginTop: "10px" }}
			details={
				<KendoListView
					data={deductibleChargeDetails}
					MyListRender={ChargeItem}
				/>
			}
		/>
	);
};
export default DeductibleChargesCard;
