import React from 'react';
import { Button } from '@progress/kendo-react-buttons';

function ActionButtons({ onAddItem, itemType }) {
    return (
        <div>
            <Button
                className="m-1 assign-button"
                themeColor={"primary"}
                onClick={() => onAddItem(itemType)}
            >
                Add {itemType}
            </Button>
        </div>
    );
}

export default ActionButtons;
