import React from 'react'
import Listing from './listing'
import styles from './styles.module.css'

function LmsRetailView() {

  return (
    <div className="ops-challan-container">
      <div className="ops-grid-layout-container">
        <div className={`${styles['head']} lms-retail`}>
          <div>
              <Listing/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LmsRetailView