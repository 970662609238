import misMatch from "../../assets/images/close.svg";
import tick from "../../assets/images/right-tick.svg";

export const inspection_report_keys = {
	TITLE: "name",
	CONDITION: "condition",
	REMARKS: "remark",
};

export const ConditionsCell = (props) => {
	const { dataItem } = props || {};
	const status = dataItem?.[inspection_report_keys.CONDITION];
	return (
		<td className="k-table-td ">
			{!!status ? (
				<img src={tick} alt={"tick"} />
			) : (
				<img src={misMatch} alt={"mis"} />
			)}
		</td>
	);
};

export const inspectionReportColumns = {
	[inspection_report_keys.TITLE]: {
		field: inspection_report_keys.TITLE,
		label: "Parts",
	},
	[inspection_report_keys.CONDITION]: {
		field: inspection_report_keys.CONDITION,
		label: "Conditions",
		cell: ConditionsCell,
	},
	[inspection_report_keys.REMARKS]: {
		field: inspection_report_keys.REMARKS,
		label: "Remarks",
	},
};

export const sample_data = [
	{
		title: "Pillar - LHS A",
		condition: "mismatch",
		remarks: "Rusted",
	},
	{
		title: "Apron - LHS B",
		condition: "mismatch",
		remarks: "Rusted",
	},
	{
		title: "Door - LHS Rear",
		condition: "mismatch",
		remarks: "Dented",
	},
	{
		title: "Roof",
		condition: "match",
		remarks: "--",
	},
];
