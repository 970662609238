import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import LabelComponent from '../label';
import styles from '../../style.module.css';

const DateTimePickerComponent = ({ name, label, placeholder, triggerDate }) => (
    <div className={styles.mb30}>
        <LabelComponent title={label} />
        <Field name={name}>
            {({ field, form }) => {
                let dateValue = field.value ? new Date(field.value) : null;
                
                if (isNaN(dateValue)) {
                    dateValue = null;
                }

                const dateTimePickerProps = {};

                if (name === 'expiryDateTime' && triggerDate) {
                    dateTimePickerProps.min = new Date(triggerDate);
                }
                
                return (
                    <DateTimePicker
                        value={dateValue}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            form.setFieldValue(field.name, newValue);
                            if (name === 'triggerDateTime') {
                                form.setFieldValue('expiryDateTime', null);
                            }
                        }}
                        disabled={name == 'expiryDateTime' && !triggerDate}
                        placeholder={placeholder}
                        className={styles["k-datetimepicker"]}
                        {...dateTimePickerProps}
                    />
                );
            }}
        </Field>
        <ErrorMessage name={name} component="div" className={styles.error} />
    </div>
);

export default DateTimePickerComponent;
