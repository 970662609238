import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import './assign-modal.scss';
import CrossIcon from '../../common/assets/images/crossIcon';
import { MY_DEALERS_TL_VIEW_DEFAULT_FILTER, TL_VIEW_CALENDAR } from '../../common/constants/filters.constants';
import { useEffect, useRef, useState } from 'react';
import DateRangeCalendar from '../../call-logs/date-range-calendar';
import { useSelector } from 'react-redux';
import { formatDateToDMY } from '../../common/utils/utils';

const AssignModal = ({ setIsAssignModalOpen, setDate, date, setSelectedPA, selectedPA, filter, setFilter, updateResetSelection, resetSelection, setSelectedDealers, tabSelected }) => {

  const [showCalendar, setShowCalendar] = useState(false)
  const { allPAs } = useSelector(state => state.myDealers);

  const dateContainerRef = useRef();


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dateContainerRef.current && !dateContainerRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dateContainerRef]);


  useEffect(() => {

    if (filter === TL_VIEW_CALENDAR[3]) {
      setShowCalendar(true)
    }
    else {
      setShowCalendar(false)
    }

  }, [filter])



  const handleChange = (e) => {
    setFilter(e.value)
  }

  const handleReassign = () => {
    setIsAssignModalOpen(true)
  }

  const handleDropdownChange = (e) => {
    if (e.target.value === MY_DEALERS_TL_VIEW_DEFAULT_FILTER[1]) {
      setSelectedPA(null)
      return;
    }
    setSelectedPA(e.target.value)
  }

  const isDisabled = (filter === TL_VIEW_CALENDAR[3] && (date.dateRange.start === null || date.dateRange.end === null)) || selectedPA === null || filter === TL_VIEW_CALENDAR[0]

  const getDropdownValue = () => {
    if (filter === TL_VIEW_CALENDAR[3] && date.dateRange.start && date.dateRange.end) {
      const customDateLabel = formatDateToDMY(date.dateRange.start).slice(0, 10) + ' - ' + formatDateToDMY(date.dateRange.end).slice(0, 10);


      const updatedCalendar = TL_VIEW_CALENDAR.map(item => {
        if (item.id === TL_VIEW_CALENDAR[3].id) {
          return { ...item, label: customDateLabel };
        }
        return item;
      }).find(item => item.id === TL_VIEW_CALENDAR[3].id);

      return updatedCalendar;
    }
    return filter;

  };


  if (allPAs.length === 0) {
    return null
  }

  return (
    <div className='modal-container'>
      {tabSelected !== 1 && (
        <>
          <DropDownList
            className='pa-email-dropdown'
            textField="email"
            data={[MY_DEALERS_TL_VIEW_DEFAULT_FILTER[1], ...allPAs]}
            value={selectedPA || MY_DEALERS_TL_VIEW_DEFAULT_FILTER[1]}
            onChange={handleDropdownChange}
          />

          {showCalendar && (
            <div className="date-container" ref={dateContainerRef}>
              <DateRangeCalendar
                onClose={() => setShowCalendar(false)}
                onApply={(start, end) => {
                  setDate({ dateRange: { start, end } });
                  setShowCalendar(false);
                }}
                startDate={date.dateRange.start}
                endDate={date.dateRange.end}
              />
            </div>
          )}

          <DropDownList
            className='duration-dropdown'
            data={TL_VIEW_CALENDAR}
            textField='label'
            value={getDropdownValue()}
            onChange={handleChange}
            onOpen={() => setShowCalendar(TL_VIEW_CALENDAR[3] === filter)}
          />
        </>
      )}

      <Button onClick={handleReassign} className='reassign-btn' disabled={isDisabled && tabSelected === 0}>
        {tabSelected === 1 ? "Cancel Reassignment" : "Reassign Dealers"}
      </Button>
      <CrossIcon className="cross-icon" onClick={() => {
        setSelectedDealers([]);
        updateResetSelection(!resetSelection);
      }} />
    </div>
  );
}

export default AssignModal