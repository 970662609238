export const SegmentEvents = {
    Viz_sorted_by: 'Viz_sorted_by',
    Viz_set_filters: 'Viz_set_filters',
    Viz_searched_car: 'Viz_searched_car',
    Viz_clicked_unlock_ra_details: 'Viz_clicked_unlock_ra_details',
    Viz_car_details_page_opened: 'Viz_car_details_page_opened',
    Viz_clicked_mark_as_contacted: 'Viz_clicked_mark_as_contacted',
    Viz_copied_text: 'viz_copied_text',
    Viz_impression: 'Viz_impression',
    Login_try: 'Login_try',
    Login: 'Login',
    Logout: 'Logout',
    Viz_share_car: 'Viz_share_car',
    Viz_Dealer_car_default_list: 'Viz_Dealer_car_default_list',
    Viz_all_cars_default_list: 'Viz_all_cars_default_list',
    Auto_logout: 'Auto_logout',
    clicked_share_button: 'clicked_share_button',
    clicked_share_confirm: 'clicked_share_confirm',
    selected_car_check_box: 'selected_car_check_box',
    Viz_dealer_car_list: 'viz_dealer_car_list',
    Viz_changed_text: 'viz_changed_text',
    Viz_contact_modification: 'viz_contact_modification',
    Viz_clicked_call_button: 'viz_clicked_call_button',
    Viz_call_logs_searched_text: 'viz_call_logs_searched_text',
}

export const SegmentsEventTimeout = 500;

export const WEB_SOCKET_ENDPOINT = '/web/v1/notifications/subscription?receiverId='

export const EVENT_TYPES = {
    NOTIFICATION_EVENT: "NOTIFICATION_EVENT",
    CALL_EVENT: "CALL_EVENT",
}

export const CALL_EVENTS = {
    RECORDING_FINISHED: 'RecordingFinished',
    NO_ANSWER: 'NoAnswer',
    DISCONNECTED: 'Disconnected',
    ANSWERED: 'Answered',
    MISSED: 'Missed',
    INITIATED: 'Initiated',
    RECORDING_STARTED: 'RecordingStarted',
}