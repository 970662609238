import { challan_request_keys } from "../../../viewModels";
import {
  CHALLAN_REQUEST_STATUSES,
  FORM_ELEMENT_TYPE,
} from "../../../../../../utils/constants/values.constants";
export const DD_DATA_SOURCES = {
  [challan_request_keys.CHALLAN_SOURCE]: [],
  [challan_request_keys.STATUS]: [
    { label: "Assigned", value: CHALLAN_REQUEST_STATUSES.ASSIGNED },
    { label: "Reopened", value: CHALLAN_REQUEST_STATUSES.REOPENED },
    { label: "Resolved", value: CHALLAN_REQUEST_STATUSES.RESOLVED },
    { label: "Unassigned", value: CHALLAN_REQUEST_STATUSES.CREATED },
  ],
};
export const formData = {
  [challan_request_keys.CHALLAN_SOURCE]: {
    name: challan_request_keys.CHALLAN_SOURCE,
    label: "Source",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
  },
  [challan_request_keys.STATUS]: {
    name: challan_request_keys.STATUS,
    label: "Status",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
  },
};
