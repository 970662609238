import { StackLayout } from "@progress/kendo-react-layout";
import classes from "../styles/shareLink.module.css";
import { showToastMessage } from "../../../../utils/utils";
import { SFService } from "../../../../services";
import { sellerFinanceCRM } from "../../../../utils/constants/url.constants";
import { useState } from "react";
import { isNumber } from "../utils/helper";

const ShareLink = ({ leadId, token, shareCount }) => {
    const [shareCountState, setshareCountState] = useState(
        isNumber(shareCount) ? 5 - shareCount : null
    );
    const [isLoading, setIsLoading] = useState(false);

    const handleShareLink = async () => {
        const body = {
            token,
            url: sellerFinanceCRM() + `seller-financing/${token}`,
        };
        try {
            setIsLoading(true);
            const response = await SFService.shareLink(leadId, body);
            const { detail = {} } = response;
            if (
                "currentShareLinkCount" in detail &&
                "maxShareCountLimit" in detail &&
                isNumber(detail.currentShareLinkCount) &&
                isNumber(detail.maxShareCountLimit)
            ) {
                setshareCountState(
                    detail.maxShareCountLimit - detail.currentShareLinkCount
                );
            }
            showToastMessage("Shared link with customer");
        } catch (error) {
            console.log(error);
            showToastMessage("Unable to share link", false);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <StackLayout className={classes.shareLinkContainer}>
            {isLoading ? (
                <span className={classes.shareLoader}></span>
            ) : (
                <span
                    role="button"
                    className={`k-icon k-font-icon k-i-redo ${
                        !shareCountState && classes.disabled
                    }`}
                    onClick={shareCountState && handleShareLink}
                />
            )}
            <div
                className={classes.shareCounter}
                title={
                    isNumber(shareCountState) &&
                    `${shareCountState} shares left`
                }
            >
                {isNumber(shareCountState) ? shareCountState + "/5" : "-"}
            </div>
        </StackLayout>
    );
};

export default ShareLink;
