import React, { useEffect, useState } from 'react';
import { KendoCard } from '../../../../../../components';
import useLazyQuery from '../../../../../../hooks/useLazyQuery';
import { commonApis } from '../../../../../../services/commonApi';
import styles from './style.module.css';
import { Button } from '@progress/kendo-react-buttons';
import { showToastMessage } from '../../../../../../utils/utils';
import { Dialog } from '@progress/kendo-react-dialogs';

function NewBusinesses(props) {
  const { appointmentId } = props;
  const [isSellerModalActive, setIsSellerModalActive] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { apiCbFunction: getSfIntend, data: sfIntend, isLoading: sfIntendLoading } = useLazyQuery(commonApis.getSfIntend, { appointmentId });
  const { apiCbFunction: getC2cIntend, data: c2cIntend, isLoading: c2cIntendLoading } = useLazyQuery(commonApis.getC2cIntend);
  const { apiCbFunction: addSfIntend, isLoading: addSfIntendLoading } = useLazyQuery(commonApis.sendSfIntend);
  const { apiCbFunction: addC2cIntend, isLoading: addC2cIntendLoading } = useLazyQuery(commonApis.sendC2cIntend);

  const sfIntendClickHandler = async () => {
    if (!addSfIntendLoading && selectedStatus) {
      const res = await addSfIntend({
        body: {
          appointmentId: appointmentId,
          source: "RA_LEAD",
          status: "PITCHED",
          consent: selectedStatus
        }
      });
      if (res?.success) {
        showToastMessage('Intent captured successfully');
        getSfIntend({ appointmentId });
        setIsSellerModalActive(false);
      } else {
        showToastMessage(res?.title ?? 'Some error occurred', false);
      }
    }
  };

  const c2cIntendClickHandler = async () => {
    if (!addC2cIntendLoading) {
      const res = await addC2cIntend({
        body: {
          appointmentId: appointmentId,
          source: "i2p",
          status: "PITCHED"
        }
      });
      if (res?.success) {
        showToastMessage('Intent captured successfully');
        getC2cIntend({ appointmentId });
      } else {
        showToastMessage(res?.title ?? 'Some error occurred', false);
      }
    }
  };

  useEffect(() => {
    if (appointmentId) {
      getSfIntend({ appointmentId });
      // getC2cIntend({ appointmentId });
    }
  }, [appointmentId]);

  useEffect(() => {
    if (sfIntend?.detail?.consent) {
      setSelectedStatus(sfIntend?.detail?.consent)
    }
  }, [sfIntend])

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const getDetails = () => (
    <div>
      <div className={`${styles['quote_container']} ${styles.last}`}>
        <div className={styles['quote_container_data']}>
          <span className={`${styles['quote_container_data--label']}`}>
            Interested for Loan
          </span>
          <span className={`${styles['quote_container_data--value']}`}>
            {
              (sfIntendLoading || addSfIntendLoading)
                ?
                "Loading"
                :
                sfIntend?.detail?.intentCaptured && sfIntend?.detail?.consent != null
                  ?
                  <>
                    {sfIntend?.detail?.consent}
                    {/* <span onClick={() => setIsSellerModalActive(true)} className={styles.sf_intent_change}>
                      <span className={`k-icon k-font-icon k-i-edit ${styles['edit_icon']}`}></span>
                      Change
                    </span> */}
                  </>
                  :
                  <Button
                    className={styles['quote_container_data--button']}
                    onClick={() => setIsSellerModalActive(true)}
                  >
                    Submit Intent
                  </Button>
            }
          </span>
        </div>
        {
          false && 
          <div className={styles['quote_container_data']}>
            <span className={`${styles['quote_container_data--label']}`}>
              Intend for C2C
            </span>
            <span className={`${styles['quote_container_data--value']}`}>
              {
                (c2cIntendLoading || addC2cIntendLoading)
                ? 
                  "Loading"
                : 
                  c2cIntend?.detail?.intentCaptured
                  ? 
                    "Received"
                  : 
                    <Button
                      className={styles['quote_container_data--button']}
                      onClick={c2cIntendClickHandler}
                    >
                      Send
                    </Button>
              }
            </span>
          </div>
        }
      </div>
    </div>
  );

  return (
    <>
      {isSellerModalActive &&
        <Dialog className={styles.seller_intent_modal} width={573}>
          <div className={styles.seller_intent_modal_div}>
            <div className={styles.sim_header}>Submit Intent</div>
            <div className={styles.sim_description}>Would you be interested in loan against car/vehicle refinance or personal loan? With loan against car, you can avail funds without even selling your car.</div>
            <div className={styles.sim_input}>
              <div className={styles.sim_input_radio}>
                <input
                  type="radio"
                  id="pitched"
                  name="status"
                  value="YES"
                  checked={selectedStatus === 'YES'}
                  onChange={handleStatusChange}
                />
                <label htmlFor="pitched" className={styles.sim_input_label}>Yes</label><br />
              </div>
              <div className={styles.sim_input_radio}>
                <input
                  type="radio"
                  id="not_pitched"
                  name="status"
                  value="NO"
                  checked={selectedStatus === 'NO'}
                  onChange={handleStatusChange}
                />
                <label htmlFor="not_pitched" className={styles.sim_input_label}>No</label>
              </div>
            </div>
            <div className={styles.sim_content}>
              <div className={styles.sim_content_header}>Loan against car/vehicle refinance</div>
              <div className={styles.sim_content_data}>
                <div className={styles.sim_content_left_part}>
                  <p className={styles.sim_content_left_part_data}>Loan amount upto</p>
                  <p className={styles.sim_content_left_part_data}>&nbsp;</p>
                  <p className={styles.sim_content_left_part_data}>Rate of interest</p>
                  <p className={styles.sim_content_left_part_data}>Time to get funds</p>
                  <p className={styles.sim_content_left_part_data}>Lending partners</p>
                </div>
                <div className={styles.sim_content_right_part}>
                  <p className={styles.sim_content_right_part_data}>100% of car’s valuation (no active car loan)</p>
                  <p className={styles.sim_content_right_part_data}>200% of car’s valuation (active car loan)</p>
                  <p className={styles.sim_content_right_part_data}>13%-18% (reducing)</p>
                  <p className={styles.sim_content_right_part_data}>2-3 days</p>
                  <p className={styles.sim_content_right_part_data}>IDFC, AU Small Finance, Bajaj, HeroFin, Piramal</p>
                </div>
              </div>
            </div>
            <div className={styles.sim_content}>
              <div className={styles.sim_content_header}>Personal Loan (no collateral required)</div>
              <div className={styles.sim_content_data}>
                <div className={styles.sim_content_left_part}>
                  <p className={styles.sim_content_left_part_data}>Loan amount upto</p>
                  <p className={styles.sim_content_left_part_data}>Rate of interest</p>
                  <p className={styles.sim_content_left_part_data}>Time to get funds</p>
                  <p className={styles.sim_content_left_part_data}>Lending partners</p>
                </div>
                <div className={styles.sim_content_right_part}>
                  <p className={styles.sim_content_right_part_data}>Upto 25 lakh</p>
                  <p className={styles.sim_content_right_part_data}>Starts from 11%</p>
                  <p className={styles.sim_content_right_part_data}>Within 30 minutes</p>
                  <p className={styles.sim_content_right_part_data}>Aditya Birla finance, Tata Capital</p>
                </div>
              </div>
            </div>
            <div className={styles.sim_content}>
              <div className={styles.sim_content_header}>Documents required</div>
              <div className={styles.sim_content_data}>
                <p className={styles.sim_content_left_part_data}>PAN, Aadhar, RC, last 6 months bank statements, statement of accounts (if open loan)</p>
              </div>
            </div>
            <div className={styles.sim_actions}>
              <div
                className={selectedStatus ? styles.sim_actions_submit : styles.sim_actions_submit_disabled}
                onClick={sfIntendClickHandler}
              >
                {addSfIntendLoading ? 'Loading' : 'Submit Intent'}
              </div>
              <div className={styles.sim_actions_cancel} onClick={() => setIsSellerModalActive(false)}>Cancel</div>
            </div>
          </div>
        </Dialog>
      }
      <KendoCard
        cardStyle={{ background: "#F0F2F5" }}
        headerTitle="New Businesses"
        details={getDetails()}
      />
    </>
  );
}

export default NewBusinesses;
