import classes from "../styles/leadsListing.module.css";

const GridLoader = ({ isLoading, children }) => {
  return (
      <div className={classes.gridLoaderContainer}>
          {isLoading && (
              <div className={classes.loaderContainer}>
                  <div className={classes.loader}></div>
              </div>
          )}
          {children}
      </div>
  );
};

export default GridLoader;
