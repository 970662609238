import { KendoCard } from "../../../../../../components";
import DocumentsForm from "./DocumentsForm";

const OtherDocuments = ({ appointmentId }) => {
    return (
        <KendoCard
            cardStyle={{
                background: "white",
            }}
            headerTitle="Other Documents"
            details={
                <DocumentsForm appointmentId={appointmentId}/>
            }
        />
    );
};
export default OtherDocuments;
