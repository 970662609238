export const WARNING_ICON_COLOR = "#F4B242"
export const DANGER_ICON_COLOR = "#FF2E00"
export const PRIMARY_COLOR = "#EF6E0B"
export const CAR_SHARED_TAG_COLOR = "#6938EF"
export const CAR_VIEWED_TAG_COLOR = "#4DBB3E"
export const BID_TAG_COLOR = "#DD2590"
export const SHARED_CAR_VIEWED_COLOR = "#0C534F"
export const DEFAULT_TAG_COLOR = '#465166'
export const SNACKBAR_ALERT_CASE_COLOR = "#E31B54"
export const SNACKBAR_POSITIVE_CASE_COLOR = "#00BA67"
export const SNACKBAR_INFO_CASE_COLOR = "#22599E"
export const ICON_COLOR = "#1C1B1F"