import { RETAIL_CONSUMER } from "../types";
import { RetailService, VehicleService, LocationService } from "../../services";
export const fetchLeadMonConfigs =
  (cityId, locationId, params = "") =>
  (dispatch) => {
    dispatch({ type: RETAIL_CONSUMER.FETCH_LOADING_START });
    RetailService.fetchLeadMonConfigs(cityId, locationId, params)
      .then((resp) => {
        dispatch({
          type: RETAIL_CONSUMER.FETCH_LEAD_MON_CONFIG_SUCCESS,
          payload: {
            data: resp?.detail?.leadMons,
            numberOfElements: resp?.detail?.length,
            totalElements: resp?.detail?.totalRecords,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: RETAIL_CONSUMER.FETCH_LEAD_MON_CONFIG_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: RETAIL_CONSUMER.FETCH_LOADING_END });
      });
  };

export const fetchCityList = () => (dispatch) => {
  VehicleService.fetchCityList().then((resp) => {
    dispatch({
      type: RETAIL_CONSUMER.FETCH_CITY_SUCCESS,
      payload: resp?.detail,
    });
  });
};

export const fetchMakers = () => (dispatch) => {
  VehicleService.fetchMakers().then((resp) => {
    dispatch({
      type: RETAIL_CONSUMER.FETCH_MAKERS_SUCCESS,
      payload: resp?.detail,
    });
  });
};

export const fetchMakeModels = (makeId) => (dispatch) => {
  VehicleService.fetchModels(makeId)
    .then((resp) => {
      dispatch({
        type: RETAIL_CONSUMER.FETCH_MODEL_SUCCESS,
        payload: resp?.detail,
      });
    })
    .catch(() => {
      dispatch({
        type: RETAIL_CONSUMER.FETCH_MODEL_SUCCESS,
        payload: [],
      });
    });
};

export const fetchMakeYears = (makeId) => (dispatch) => {
  VehicleService.fetchMakeYears(makeId)
    .then((resp) => {
      dispatch({
        type: RETAIL_CONSUMER.FETCH_YEAR_SUCCESS,
        payload: resp?.detail,
      });
    })
    .catch(() => {
      dispatch({
        type: RETAIL_CONSUMER.FETCH_YEAR_SUCCESS,
        payload: [],
      });
    });
};

export const fetchCityLocations = (cityId) => (dispatch) => {
  LocationService.featchCityLocations(cityId)
    .then((resp) => {
      dispatch({
        type: RETAIL_CONSUMER.FETCH_LOCATION_SUCCESS,
        payload: resp?.detail,
      });
    })
    .catch(() => {
      dispatch({
        type: RETAIL_CONSUMER.FETCH_LOCATION_SUCCESS,
        payload: [],
      });
    });
};

export const resetModels = () => (dispatch) => {
  dispatch({
    type: RETAIL_CONSUMER.RESET_MODEL,
    payload: [],
  });
};

export const resetYears = () => (dispatch) => {
  dispatch({
    type: RETAIL_CONSUMER.RESET_YEAR,
    payload: [],
  });
};

export const resetLocations = () => (dispatch) => {
  dispatch({
    type: RETAIL_CONSUMER.RESET_LOCATION,
    payload: [],
  });
};

export const resetLeadMonConfigs = () => (dispatch) => {
  dispatch({
    type: RETAIL_CONSUMER.RESET_LEAD_MON_CONFIG,
    payload: [],
  });
};
