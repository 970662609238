import React from "react";

export const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <div className="k-loading-image" />
      </div>
    </div>
  );
};
