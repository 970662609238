import { debounce } from 'lodash'
import styles from './styles.module.css'
import { getDisplayedCoulmns, getUserRoles, showToastMessage } from '../../../../utils/utils'
import { ALLOWED_SORT_PARAMS, INITIAL_SORT, LMS_COLUMN_KEYS, SORT_DIRECTION_KEYS, getColumnHeader } from '../view-model'
import CustomGrid from '../../../../common/custom-grid/component'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import useLazyQuery from '../../../../hooks/useLazyQuery'
import { lmsRetailApi } from '../../../../services/lms-retail-apis'
import { Loader } from '../../../../common/Loader'
import { commonApis } from '../../../../services/commonApi'
import { USER_ROLE_KEYS } from '../../../../utils/constants/menu.constants'

const ALLOWED_EMAILS_FOR_GROUP_ASSIGNMENT=['nishank.agarwal@cars24.com','ankit.rai@cars24.com']

function Listing() {
  const userRoles = getUserRoles();
  const [filterPayload,setFilterPayload] = useState();
  const [filters, setFilters] = useState();
  const [sortParams, setSortParams] = useState(INITIAL_SORT);
  const { apiCbFunction: getLeads, data: leadsData, isLoading: isLeadsDataLoading, errorMessage} = useLazyQuery(lmsRetailApi.getLeads);
  const { apiCbFunction: downloadGroupAssignment, data: downloadGroupAssignmentData, isLoading:downloadGroupAssignmentLoading } = useLazyQuery(commonApis.downloadGroupAssignment);
  const { apiCbFunction:uploadGroupAssignment, data:uploadGroupAssignmentData , isLoading:uploadGroupAssignmentLoading } = useLazyQuery(commonApis.uploadGroupAssignment);
  const [paginationParams, setPaginationParams] = useState({ pageNumber: 0, pageSize: 8 });

  const pageChangeHandler = ({ page, size }) => {
    setPaginationParams({ pageNumber: page, pageSize: size })
  }

  const filterApiCaller = (data) => {
    setFilterPayload(data)
    const initialPage =  { pageNumber: 0, pageSize: 8 }
    setPaginationParams(initialPage)
  }
  const debouncedFilterApiCaller = useCallback(debounce(filterApiCaller, 300), [paginationParams])
  const handleFilterButtonClick = (e) => {
    setFilters(e);
    debouncedFilterApiCaller(e?.filters)
  };

  const sortChange = (e) => {
    let sortData = !!e?.sort.length ? e?.sort : INITIAL_SORT;
    let sortKey = sortData[0]?.field;
    let sortDir = sortData[0].dir;
    if (!e?.sort?.length) {
        sortDir =
            sortParams[0].dir === SORT_DIRECTION_KEYS.DESC
                ? SORT_DIRECTION_KEYS.ASC
                : SORT_DIRECTION_KEYS.DESC;
    }
    if (ALLOWED_SORT_PARAMS.includes(sortKey)) {
      setSortParams([{ field: sortData[0].field, dir: sortDir }]);
    }   
  };

  const uploadBtnHandler = async (e) =>{
    const formData = new FormData()
    formData.append("user-group-mapping", e.target.files[0]);
    const res = await uploadGroupAssignment({
      body:formData
    })
    if(res?.status == 200){
      showToastMessage('File Uploaded Successfully',true)
    }
    else{
      showToastMessage(res?.message??'Some error occured',false)
    }
  }

  function downloadCSV(csv, filename) {
    const csvFile = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(csvFile);
    link.download = filename;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const downloadBtnHandler = async () => {
    const csvData = await downloadGroupAssignment()
    downloadCSV(csvData, 'data.csv');
  }

  useEffect(() => {
    getLeads({
      page: paginationParams.pageNumber,
      size: paginationParams.pageSize || 8,
      filter: filterPayload,
      sortParams:sortParams
    })
  }, [paginationParams, sortParams])

  return (
    <div className={styles['head']}>
      <div className={styles['head_header']}>
      </div>
      <div className={styles['head_grid']}>
        {
          isLeadsDataLoading ?
            <Loader />
            :
            <>
              <div className={styles.title_div}>
                <h1 className={styles.title}>RETARGETING NEGO</h1>
                {
                  userRoles?.includes(USER_ROLE_KEYS.TECH_SUPPORT) && 
                  <div className={styles.title_div_actions}>
                    <label for='input-file' className={styles.title_div_actions_label}>
                      {uploadGroupAssignmentLoading?'Loading':'Upload'}
                    </label>
                    <input 
                      id='input-file' 
                      type='file' 
                      disabled={uploadGroupAssignmentLoading}
                      className={styles.title_div_actions_input}
                      onChange={uploadBtnHandler}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                    <button 
                      onClick={downloadBtnHandler}
                      className={styles.title_div_actions_button}
                    >
                      {
                        downloadGroupAssignmentLoading?'Loading':'Download'
                      }
                    </button>
                  </div>
                }
              </div>
              {
                errorMessage?.subCode === "404"?
                  <div className={styles.unathorized}>
                    <h3 className={styles.unathorized_404}>404</h3>
                    <h3 className={styles.unathorized_h3}>No RA assigned to ZM</h3>
                  </div>
                :
                  <CustomGrid
                    displayedColumns={getDisplayedCoulmns(LMS_COLUMN_KEYS)}
                    data={leadsData?.content}
                    count={leadsData?.totalElements}
                    columnMap={getColumnHeader({
                      raEmailFilterValues:leadsData?.hierarchy,
                      filterValues:leadsData?.filters,
                      filters:filters,
                      handleFilterButtonClick:handleFilterButtonClick
                    })}
                    pageSizes={[]}
                    isPagination={true}
                    pageChange={pageChangeHandler}
                    paginationParams={paginationParams}
                    sortable={true}
                    sortParms={sortParams}
                    onSortChange={sortChange}
                  />
              }
            </>
        }
      </div>
    </div>
  )
}

export default Listing
