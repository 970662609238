import { Dialog } from "@progress/kendo-react-dialogs";
import { FormComboBox } from "../../../../../../components";
import { Button } from "@progress/kendo-react-buttons";
import { showToastMessage } from "../../../../../../utils/utils";
import { PrAutomationService } from "../../../../../../services";
import { PR_AUTOMATION } from "../../../../../../store/types";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  GridToolbar,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import {
  GetApprovalFrom,
  RemoveARowCell,
  SerialNumberCell,
} from "../viewModel";
import React, { useEffect, useState } from "react";
import styles from "../../../negotiation/assets/styles/pr.styles.module.css";
import { fetchBusinessApprovals } from "../../../../../../store/actions/pr-automation";
import CustomTitle from "../../shared/custom-title";
const RaiseBa = (props) => {
  const { toggleRaiseBa } = props || {};
  const [baTypeOptions, setBaTypeOptions] = useState([]);
  const [selectedBas, setSelectedBas] = useState([]);
  const dispatch = useDispatch();
  const prDetails = useSelector((state) => state.prAutomation);
  const { baTypes } = prDetails || {};
  const lms = useSelector((state) => state.lms);
  const { appointmentId } = lms || {};
  const editField = "inEdit";
  const intialBaState = {
    issueType: "",
    approvalFrom: "",
  };
  useEffect(() => {
    const list =
      baTypes &&
      baTypes.length &&
      baTypes.map((item) => {
        return { id: item.id, issueType: item.issueType };
      });
    setBaTypeOptions(list);
  }, [baTypes]);
  const handleSubmit = () => {
    const payload = {
      appointmentId,
      businessApprovals:
        selectedBas?.length &&
        selectedBas?.map((item) => {
          return { issueType: item.issueType, issueDescription: "" };
        }),
    };
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.businessApproval(payload)
      .then((res) => {
        if (res && res.status === 200) {
          showToastMessage("Business Approvals raised successfully", true);
          dispatch(fetchBusinessApprovals(appointmentId));
          toggleRaiseBa();
        }
      })
      .catch((error) => {
        showToastMessage(error?.message || error?.error, false);
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
  const handleRemove = (index) => {
    const _selectedBas = selectedBas.filter((item, i) => i !== index);
    setSelectedBas(_selectedBas);
  };

  const handleAdd = () => {
    setSelectedBas([
      ...selectedBas,
      {
        ...intialBaState,
      },
    ]);
  };
  const onChangeType = (e, props) => {
    const { dataIndex } = props || {};
    const { value } = e.target;
    const newData = selectedBas?.map((item, index) =>
      index === dataIndex
        ? {
            ...item,
            issueType: value?.issueType,
            approvalFrom: GetApprovalFrom(value?.issueType, baTypes),
          }
        : item
    );
    setSelectedBas(newData);
  };
  const isError = (props) => {
    const { dataIndex } = props || {};
    let result = false;
    const otherValues = selectedBas
      ?.filter((bas, index) => index !== dataIndex)
      ?.map((item) => item.issueType);
    if (
      selectedBas?.[dataIndex]?.issueType &&
      otherValues.includes(selectedBas?.[dataIndex]?.issueType)
    ) {
      result = true;
    }
    return result;
  };
  const isErrorOnTypes = () => {
    let isError = false;
    selectedBas?.forEach((item1, index1) => {
      selectedBas?.forEach((item2, index2) => {
        if (item1.issueType === item2.issueType && index1 !== index2) {
          isError = true;
        }
      });
    });
    return isError;
  };
  return (
    <Dialog
      title={CustomTitle("Business approvals")}
      onClose={toggleRaiseBa}
      width={"40%"}
      contentStyle={{ width: "auto" }}
    >
      <Grid data={selectedBas || []} editField={editField} dataItemKey="id">
        <GridToolbar>
          <Button themeColor={"primary"} icon="plus-circle" onClick={handleAdd}>
            Add approval
          </Button>
        </GridToolbar>
        <GridNoRecords>
          <span>No Business approval required!</span>
        </GridNoRecords>
        <Column
          title="S.No."
          field="sno"
          width="60px"
          cell={SerialNumberCell}
        />
        <Column
          title="Type of BA"
          field="issueType"
          cell={(props) => (
            <td>
              <FormComboBox
                id={"id"}
                key={"id"}
                onChange={(e) => onChangeType(e, props)}
                data={baTypeOptions || []}
                value={
                  baTypeOptions?.find(
                    (item) => item.issueType === props.dataItem?.issueType
                  ) || null
                }
                wrapperStyle={{ marginBottom: "0px" }}
                dataItemKey="id"
                textField="issueType"
                touched={true}
                validationMessage={isError(props) && "Duplicate values!"}
                valid={!isError(props)}
              />
            </td>
          )}
        />
        <Column title="Approval from" field="approvalFrom" />
        <Column
          title="Action"
          field="action"
          width="70px"
          cell={(props) => (
            <RemoveARowCell
              {...props}
              onClick={handleRemove}
              isDisabled={false}
            />
          )}
        />
      </Grid>
      <div className={`k-mt-10 ${styles.alignRight}`}>
        <Button
          onClick={toggleRaiseBa}
          className={`${styles.cancelCta} k-mr-5`}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          className={styles.submitCta}
          disabled={
            !selectedBas ||
            !selectedBas.length ||
            isErrorOnTypes() ||
            selectedBas?.find((item) => !item.issueType || !item.approvalFrom)
          }
        >
          Submit
        </Button>
      </div>
    </Dialog>
  );
};

export default RaiseBa;
