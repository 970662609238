import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React from "react";
import styles from "./styles.module.css";

const DynamicDataList = ({
	columnList = [],
	itemPerRow = 4,
	customIndexStyle = {},
}) => {
	let columnListPerRow = [];
	for (let i = 0; i < columnList?.length; i += itemPerRow) {
		columnListPerRow.push(columnList.slice(i, i + itemPerRow));
	}
	const renderColumns = (column = [], rowIndex) => {
		return column.map((item, index) => {
			const cellIndex = rowIndex * itemPerRow + index;
			const isCustomIndex = customIndexStyle?.index === cellIndex;
			const cellStyles = {
				headerStyle: isCustomIndex ? customIndexStyle?.headerStyle : {},
				valueStyle: isCustomIndex ? customIndexStyle?.valueStyle : {},
			};
			{return !item?.isHide &&  (
				<GridLayoutItem col={index + 1} key={`key-col-${rowIndex}-${index}`}>
					<div key={`col-${index}`} className={styles.dynamic_List_Cell} style={{maxWidth:"180px"}}>
						<span
							key={`col-title-${index}`}
							className={styles.dynamic_List_Cell_title}
							style={cellStyles.headerStyle}>
							{item?.title}
						</span>
                        {!!item?.isCell ? item?.cell(column)
                            : 
                            <span
							key={`col-value-${index}`}
							className={styles.dynamic_List_Cell_value}
							style={cellStyles.valueStyle}>
							{item?.value}
						</span>
                        }
					</div>
				</GridLayoutItem>
			);}
		});
	};

	return (
		<GridLayout
			key={`GridLayout`}
			style={{ padding: "10px 26px" }}
			gap={{
				cols: itemPerRow,
			}}>
			{columnListPerRow?.map((colum, rowIndex) => {
				return <>{renderColumns(colum, rowIndex)}</>;
			})}
		</GridLayout>
	);
};

export default DynamicDataList;
