import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import React, { useRef, useState } from 'react';
import styles from "./style.module.css";
import { commonApis } from '../../../../../../../services/commonApi';
import useLazyQuery from '../../../../../../../hooks/useLazyQuery';
import { Loader } from '@progress/kendo-react-indicators';
import { showToastMessage } from '../../../../../../../utils/utils';


function PriceCalculator({
  appointmentId,
  isPriceCalculatorOpen,
  setIsPriceCalculatorOpen,
}) {

  const [amount, setAmount] = useState('');
  const amountRef = useRef(0)
  const holdAmountRef = useRef(0)
  const chargesAmountRef = useRef(0)
  const { apiCbFunction: getPriceBreakdowm, data: priceBreakdownData, isLoading: isPriceBreakdownDataLoading } = useLazyQuery(commonApis.getPriceBreakdowm);

  const handleInputChange = (e) => {
		const value = e.target.value;
		const numberValue = parseFloat(value);
    holdAmountRef.current = 0
    chargesAmountRef.current = 0
    if (value ===  '') {
      setAmount('')
      return;
    }
		if (!isNaN(numberValue)) {
			setAmount(numberValue);
		}
	};

  const submitHandler = () => {
    holdAmountRef.current = 0
    chargesAmountRef.current = 0
    amountRef.current = Number(amount)
    if(!amount){
      showToastMessage('Amount cannot be empty',false)
      return;
    }
    if(Number(amount) <= 0){
      showToastMessage('Amount cannot be less than 1',false)
      return;
    }
    getPriceBreakdowm({
      appointmentId: appointmentId,
      price: amount
    })
  }

  return (
    <Dialog
      className={styles.price_modal}
      width={'410px'}
    >
      <div className={styles['head']}>
        <div className={styles['head_userAction']}>
          <div className={styles['head_userAction--heading']}>Price Calculator</div>
          <div className={styles['head_userAction--subheading']}>For #{appointmentId}</div>
          <div className={styles['head_userActionInput']}>
            <div className={styles['head_userActionInput--label']}>Raise OCB at</div>
            <input
              type='text'
              className={styles['head_userActionInput--input']}
              value={amount}
              onChange={handleInputChange}
            />
          </div>
        </div>
        {
          isPriceBreakdownDataLoading?
            <Loader/>
          :
            priceBreakdownData && amountRef.current == amount && 
            <div className={styles['head_showDetails']}>
              <div className={styles['head_showDetails--heading']}>
                Details
              </div>
              <>
                <div className={styles['head_showDetailsPriceBreakDown']}>
                  <div className={styles['head_showDetailsBreakdown']}>
                    {
                      priceBreakdownData?.detail?.holds.map((item) => {
                        holdAmountRef.current += item.amount
                        return (
                          item.amount!=0?
                            <div className={styles['head_showDetailsBreakdown_details']}>
                              <span className={styles['head_showDetailsBreakdown_details--label']}>{item.label}</span>
                              <span className={styles['head_showDetailsBreakdown_details--positiveAmount']}>{item.amount}</span>
                            </div>
                          :
                            <></>
                        )
                      })
                    }
                  </div>
                  <div className={styles['head_showDetailsBreakdown']}>
                    {
                      priceBreakdownData?.detail?.charges.map((item) => {
                        chargesAmountRef.current -= item.amount
                        return (
                          item.amount!=0?
                            <div className={styles['head_showDetailsBreakdown_details']}>
                              <span className={styles['head_showDetailsBreakdown_details--label']}>{item.label}</span>
                              <span className={styles['head_showDetailsBreakdown_details--negativeAmount']}>- {item.amount}</span>
                            </div>
                          :
                            <></>
                        )
                      })
                    }
                  </div>
                </div>
                <div className={styles['head_showDetailsBreakdown_details']}>
                  <span className={styles['head_showDetailsBreakdown_details--label']}>In hand amount</span>
                  <span className={styles['head_showDetailsBreakdown_details--finalPrice']}>{amountRef.current + chargesAmountRef.current}</span>
                </div>
              </>
            </div>
        }
        <div className={styles['head_userButtons']}>
          <button className={styles['head_userButtons--submit']} onClick={submitHandler}>Calculate</button>
          <button className={styles['head_userButtons--cancel']} onClick={() => { setIsPriceCalculatorOpen(false) }}>Close</button>
        </div>
      </div>
    </Dialog>
  )
}

export default PriceCalculator
