import { Button } from "@progress/kendo-react-buttons";
import { Slider } from "@progress/kendo-react-inputs";
import classes from "../styles/AudioPlayer.module.css";
import { useEffect, useRef, useState, useCallback } from "react";
import { formatAudioDuration } from "../utils/helper";

const AudioPlayer = ({ src, id }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const audioRef = useRef(null);
    const isPlayingRef = useRef(false);

    const togglePlay = () => {
        if (src) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying((prev) => !prev);
        }
    };

    const updateProgress = () => {
        const audio = audioRef.current;
        if (audio) {
            const value = (audio.currentTime / audio.duration) * 100;
            setProgress(value);
        }
    };

    const handleEnded = () => {
        setIsPlaying(false);
        setProgress(0);
    };

    const handleSliderChange = (e) => {
        const audio = audioRef.current;
        if (audio) {
            const time = (audio.duration / 100) * e.value;
            audio.currentTime = time;
            setProgress(e.value);
        }
    };

    const skipTime = (seconds) => {
        const audio = audioRef.current;
        if (audio) {
            const newTime = Math.min(
                Math.max(audio.currentTime + seconds, 0),
                audio.duration
            );
            audio.currentTime = newTime;
            setProgress((newTime / audio.duration) * 100);
        }
    };

    const handleKeyDown = useCallback((e) => {
        if (isPlayingRef.current) {
            if (e.key === "ArrowRight") {
                skipTime(10);
            } else if (e.key === "ArrowLeft") {
                skipTime(-10);
            }
        }
    }, []);

    useEffect(() => {
        isPlayingRef.current = isPlaying;
    }, [isPlaying]);

    useEffect(() => {
        const audio = audioRef.current;
        if (audio) {
            audio.addEventListener("timeupdate", updateProgress);
            audio.addEventListener("ended", handleEnded);
        }

        window.addEventListener(`keydown-${id}`, handleKeyDown);

        return () => {
            if (audio) {
                audio.removeEventListener("timeupdate", updateProgress);
                audio.removeEventListener("ended", handleEnded);
            }
            window.removeEventListener(`keydown-${id}`, handleKeyDown);
        };
    }, [id, handleKeyDown]);

    useEffect(() => {
        const handleGlobalKeyDown = (e) => {
            if (isPlayingRef.current) {
                window.dispatchEvent(
                    new KeyboardEvent(`keydown-${id}`, { key: e.key })
                );
            }
        };

        window.addEventListener("keydown", handleGlobalKeyDown);

        return () => {
            window.removeEventListener("keydown", handleGlobalKeyDown);
        };
    }, [id]);

    return (
        <div className={classes.audioPlayer}>
            <Button
                className={classes.audioPlayerBtn}
                themeColor="primary"
                iconClass={`k-icon k-font-icon ${
                    isPlaying ? "k-i-pause" : "k-i-play"
                }`}
                onClick={togglePlay}
            ></Button>
            <Slider
                className={classes.progressBar}
                value={progress}
                onChange={handleSliderChange}
                step={0.1}
                min={0}
                max={100}
            />
            {audioRef && audioRef.current?.duration && (
                <p className={classes.durationText}>
                    {formatAudioDuration(audioRef.current.duration)}
                </p>
            )}
            <audio ref={audioRef} src={src} />
        </div>
    );
};

export default AudioPlayer;
