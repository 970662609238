import "./tab-rfc-details.scss";
import { useEffect, useState } from "react";
import { Rating } from "@progress/kendo-react-inputs";
import AppChips from "../../components/app-chips";
import ImageSliderModal from "../image-slider-modal";
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import DangerIcon from "../../../../../assets/img/dangerIcon";
import SuccessIcon from "../../../../../assets/img/successIcon";
import { WARNING_ICON_COLOR } from "../../constants/color.constants";
import FlagIcon from "../../../../../assets/img/flagIcon";
import { IRItemStatus } from "../../constants/fields.constants";
import { getVizPanelServiceData } from "../../constants/env.constants";
import carImage from '../../../common/assets/images/car-image.svg'

const TabRfcDetails = ({ carDetails }) => {

	const defaultTab = {
		title: 'Car Highlights',
		key: 'CarHighlights',
		rating: 0,
	};
	const [tabs, setTabs] = useState([defaultTab]);
	const [activeTab, setActiveTab] = useState(tabs[0]);
	const [previewImages, setPreviewImages] = useState([]);

	const apiMetaData = getVizPanelServiceData();



	useEffect(() => {
		const newTabs = carDetails?.carDetails?.sectionHeaders ? [defaultTab, ...carDetails?.carDetails?.sectionHeaders] : [defaultTab];
		setTabs([...newTabs]);
	}, [carDetails])


	useEffect(() => {
		let newTabs = carDetails?.carDetails?.sectionHeaders ? [defaultTab, ...carDetails.carDetails.sectionHeaders] : [defaultTab];
		setTabs([...newTabs]);
	}, [carDetails]);

	const getDocuments = (key) => {
		const index = carDetails?.carDetails?.inspectionDetails?.findIndex(ele => ele.key === key);
		if (index !== undefined && index > -1) {
			let documents = [];
			carDetails.carDetails.inspectionDetails[index].elements?.mandatory?.forEach(ele => {
				documents = [...documents, ...ele.data];
			});

			return (
				<>
					{documents.map((item) => (
						<div className="plane-grid" key={item.title}>
							<div className="label document-label">{item.title}</div>
							<div className="value">{item?.value?.join(', ')}</div>
						</div>
					))}
				</>
			);
		}
		return null;
	};

	const getIconComponent = (status) => {
		switch (status) {
			case IRItemStatus.Good:
				return <SuccessIcon />;
			case IRItemStatus.Average:
				return <SuccessIcon fill={WARNING_ICON_COLOR} />;
			case IRItemStatus.Severe:
				return <DangerIcon />;
			case IRItemStatus.NA:
			case IRItemStatus.Default:
				return <div className="na-container">NA</div>;
			default:
				return null;
		}
	};


	const RatingGridItem = ({ item }) => (
		<div className="plane-grid" key={`rating${item?.title}`}>
			<div className="label">
				<span className="label-icon">{getIconComponent(item?.status)}</span>
				{item?.info && <span className="k-icon k-font-icon k-i-information info-icon"></span>}
				<span className="label-text">{item?.title}</span>
			</div>
			<div className="value">{item?.subTitle}</div>
			<div className="image-count">
				<span
					className="relative"
					style={{ cursor: item?.mediaResponse?.images?.length > 0 ? 'pointer' : 'not-allowed' }}
					onClick={() => item?.mediaResponse?.images?.length > 0 && setPreviewImages(item?.mediaResponse?.images || [])}
				>
					<span className="k-icon k-font-icon k-i-image"></span>
					<span className="count">
						{item?.mediaResponse?.images?.length > 0 ? item.mediaResponse.images.length : 0}
					</span>
				</span>
			</div>
		</div>
	);

	const getAdditionalRatingBlocks = (key) => {
		const inspectionDetails = carDetails?.carDetails?.inspectionDetails || [];
		const index = inspectionDetails.findIndex((ele) => ele.key === key);

		const engineGallery = carDetails?.carDetails?.fullGallery.Engine;

		if (index === -1) return null;


		let mandatoryInfo = [];
		carDetails.carDetails.inspectionDetails[index].elements?.mandatory?.forEach(ele => {
			mandatoryInfo = [...mandatoryInfo, ...ele.data];
		});

		let optionalInfo = [];
		carDetails?.carDetails?.inspectionDetails[index].elements?.optional?.forEach(ele => {
			optionalInfo = [...optionalInfo, ...ele.data];
		});

		let orangeFlags = carDetails?.carDetails?.inspectionDetails[index].elements?.orangeFlag;


		return (
			<>
				<div>
					{mandatoryInfo.map((item, idx) => (
						<RatingGridItem item={item} key={`mandatory${item?.title}${idx}`} />
					))}
					{optionalInfo.map((item, idx) => (
						<RatingGridItem item={item} key={`optional${item?.title}${idx}`} />
					))}
				</div>
				{index === 2 ? <div className="engine-video-container">
					{engineGallery?.map((item, idx) => {
						const { title, video, url } = item;
						const imageUrl = url ? `${apiMetaData.imagesUrl}/${url}` : carImage;
						const videoUrl = `${apiMetaData.videoUrl}/${url}`;

						return (
							<div className="plane-grid" key={`engineGallery${title}${idx}`}>
								<div className="label document-label">
									<span className="label-text">{title}</span>
								</div>
								<div className="value engine-additional-info">
									{video ? (
										<video controls className="engine-info">
											<source src={videoUrl} type="video/mp4" />
										</video>
									) : (
										<img className="engine-info" src={imageUrl} alt="engine-image" />
									)}
								</div>
							</div>
						);
					})}

				</div> : null}
				{orangeFlags && (
					<div className="flag-content-container">
						{orangeFlags.map((item) => (
							<div className="flag-content" key={item}>
								<FlagIcon />
								<span className="flag-text">{item}</span>
							</div>
						))}
					</div>
				)}
			</>
		);
	};


	const renderDetails = () => {
		switch (activeTab?.key) {
			case 'CarHighlights':
				return (
					<div>
						<AppChips data={carDetails?.carHighlights || []} />
					</div>
				);
			case 'carDocuments':
				return (
					<div>
						{getDocuments(activeTab?.key)}
					</div>
				);
			case 'EngineTransmission':
			case 'AirConditioning':
			case 'electricals':
			case 'steering':
			case 'exterior':
			case 'engine':
			case 'ac':
				return (<div>
					{getAdditionalRatingBlocks(activeTab?.key)}
				</div>)
			default:
				return null;
		}
	}

	const getRatingClass = (rating) => {
		if (rating >= 4) {
			return 'app-rating-high';
		} else if (rating >= 3) {
			return 'app-rating-medium';
		} else {
			return 'app-rating-low';
		}
	};


	return (
		<div className="tab-rfc-details">
			<div className="rfc-options">
				{tabs.map((ele) => {
					return <ExpansionPanel
						title={<span className={`${activeTab?.key === ele.key ? 'active-rfc-option' : ''}`}>{ele.title}</span>}
						subtitle={ele.rating > 0 ? (
							<div className={`app-rating ${getRatingClass(ele?.rating)}`}>
								<Rating value={ele?.rating} readonly={true} precision="half" defaultValue={ele?.rating} />
							</div>
						) : null}
						tabIndex={0}
						key={ele.key}
						expanded={activeTab?.key === ele.key}
						onAction={() => setActiveTab(activeTab?.key === ele.key ? null : ele)}>
						<Reveal>
							{activeTab?.key === ele.key && (
								<ExpansionPanelContent>
									<div className="rfc-details">
										{renderDetails()}
									</div>
								</ExpansionPanelContent>
							)}
						</Reveal>
					</ExpansionPanel>
				})}
			</div>
			{
				previewImages?.length > 0 && <ImageSliderModal
					close={() => setPreviewImages([])}
					list={previewImages}
					title={carDetails?.appointmentDetails?.year + ' ' + carDetails?.appointmentDetails?.make + ' ' + carDetails?.appointmentDetails?.model} />
			}
		</div >
	);
};

export default TabRfcDetails;
