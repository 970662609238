import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { useSelector, useDispatch } from "react-redux";
import React from "react";
import styles from "../../../negotiation/assets/styles/pr.styles.module.css";
import AuditCheckCard from "./audit-check-card";
import auditComplete from "../../assets/images/checkComplete.svg";
import auditFlaggedImage from "../../assets/images/warning.svg";
import auditFailed from "../../assets/images/error.svg";
import { fetchOrderDetails } from "../../../../../../store/actions/pr-automation";
import { PrAutomationService } from "../../../../../../services";
import {
  getLoginUserEmail,
  showToastMessage,
} from "../../../../../../utils/utils";
import { PR_AUTOMATION } from "../../../../../../store/types";
import useAuditChecks from "../../../hooks/useAuditChecks";

const AuditChecks = (props) => {
  const dispatch = useDispatch();
  const { netPayableAmount, setIsAuditLoader, setIsAuditChecks } = props || {};
  const prDetails = useSelector((state) => state.prAutomation);
  const { auditCheckDetails = {}, orderData = {} } = prDetails || {};
  const {
    purchase: { purchaseRequestId },
  } = orderData || {};
  const {
    deliveryPaymentType = "",
    redirectUrl = "",
    auditFlagged = null,
  } = auditCheckDetails || {};
  const lms = useSelector((state) => state.lms);
  const { appointmentId } = lms || {};
  const [handleInitiateAudit] = useAuditChecks({
    setIsAuditLoader,
    setIsAuditChecks,
  });
  const handleSubmitPr = () => {
    const payload = {
      purchaseRequestId,
      action: "SUBMIT",
      deliveryPaymentType: "DSI",
      userName: getLoginUserEmail(),
    };
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.submitPr(appointmentId, payload)
      .then((res) => {
        if (res && res.status === 200) {
          showToastMessage("PR Submitted successfully", true);
          dispatch(
            fetchOrderDetails({
              appointmentId,
              documentCodes: ["AADHAR_CARD", "PAN_CARD_COPY"],
            })
          );
          setIsAuditChecks(false);
        }
      })
      .catch((error) => {
        showToastMessage(error?.message || error?.error || "Error", false);
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
  const handleRedirectToAdmin = () => {
    window.location.href = redirectUrl;
    setIsAuditChecks(false);
  };
  const cardRender = () => {
    switch (deliveryPaymentType + auditFlagged.toString()) {
      case "DSIfalse":
        return (
          <AuditCheckCard
            netPayableAmount={netPayableAmount}
            auditImage={auditComplete}
            heading="Audit Check Complete"
            text="Net Payable amount"
            headingCss={styles.auditCheckComplete}
            actions={
              <div>
                <Button className={styles.popCta} onClick={handleSubmitPr}>
                  Confirm Submission
                </Button>
                <Button
                  className={`${styles.cancelCta} k-ml-5`}
                  onClick={() => setIsAuditChecks(false)}
                >
                  Cancel
                </Button>
              </div>
            }
          />
        );
      case "TOKENfalse":
      case "TOKENtrue":
        return (
          <AuditCheckCard
            netPayableAmount={netPayableAmount}
            auditImage={auditFlaggedImage}
            heading="Audit Flagged"
            subHeading="Audit has been flagged in this case, and token is applicable. Please proceed from the admin panel."
            text="Net Payable amount"
            headingCss={styles.auditFlagged}
            actions={
              <div>
                <Button
                  className={styles.popCta}
                  onClick={handleRedirectToAdmin}
                >
                  Proceed
                </Button>
                <Button
                  className={`${styles.cancelCta} k-ml-5`}
                  onClick={() => setIsAuditChecks(false)}
                >
                  Cancel
                </Button>
              </div>
            }
          />
        );
      case "false":
        return (
          <AuditCheckCard
            auditImage={auditFailed}
            heading="Audit Check Failed!"
            subHeading="We could not fetch a valid response. Retry in case this was a system error."
            headingCss={styles.auditFailed}
            actions={
              <div>
                <Button className={styles.popCta} onClick={handleInitiateAudit}>
                  Retry Submission
                </Button>
                <Button
                  className={`${styles.cancelCta} k-ml-5`}
                  onClick={() => setIsAuditChecks(false)}
                >
                  Cancel
                </Button>
              </div>
            }
          />
        );
      case "DSI_OR_TOKENfalse":
        return (
          <AuditCheckCard
            netPayableAmount={netPayableAmount}
            auditImage={auditFlaggedImage}
            heading="Token or DSI Applicable"
            subHeading="Audit has not been flagged but both DSI & token are applicable in this case. Please proceed from the admin panel."
            text="Net Payable amount"
            headingCss={styles.auditFlagged}
            actions={
              <div>
                <Button className={styles.popCta} onClick={handleSubmitPr}>
                  Proceed with DSI
                </Button>
                <Button
                  className={`${styles.cancelCta} k-ml-5`}
                  onClick={handleRedirectToAdmin}
                >
                  Proceed with Token
                </Button>
              </div>
            }
          />
        );
      default:
        return null;
    }
  };
  return (
    <Dialog
      title={<div></div>}
      onClose={() => setIsAuditChecks(false)}
      width="610px"
      contentStyle={{ width: "auto" }}
    >
      {cardRender()}
    </Dialog>
  );
};

export default AuditChecks;
