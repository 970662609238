import '../all-cars/side-bar-filters.scss';
import carImage from '../common/assets/images/car-image.svg';
import '../common/assets/styles/common.scss';
import { ScrollView } from "@progress/kendo-react-scrollview";

import HoursLeft from '../common/components/hours-left';
import { formatNumbers, numberWithIndicator, showToastMessage } from '../common/utils/utils';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { copyToClipboard, downloadPDF, getLoginUserEmail } from '../../../utils/utils';

import { ALL_CARS_TABLE_VIEW, CARD_TYPE, CAR_DETAILS_TAB_LIST } from '../common/constants/table.constants';
import PAIsBids from './pais-bid';
import CurrentBids from './current-bids';
import CarsOCBView from './cars-ocb-view';
import NegoBids from './nego-bid';
import NegoView from './nego-view';
import AppChips from '../common/components/app-chips';
import { useEffect, useState } from 'react';
import { ALL_CARS_OCB_LIVE_FILTER } from '../common/constants/filters.constants';
import { useDispatch, useSelector } from 'react-redux';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import TabRfcDetails from '../common/components/tab-rfc-details';
import TabCarDetails from '../common/components/tab-car-details';
import TabCurrentBids from '../common/components/tab-current-bids';
import TabUnlockRADetails from '../common/components/tab-unlock-ra-details';
import { getPaiCarDetails, setModal } from '../store/actions/allCars';
import { getVizPanelServiceData } from '../common/constants/env.constants';
import FlagIcon from '../../../assets/img/flagIcon';
import { DANGER_ICON_COLOR } from '../common/constants/color.constants';
import { trackAnalytics } from '../common/services/analytics';
import { SegmentEvents } from '../common/constants/events.constants';
import { AppLoader } from '../common/components/app-loader';
import TabRecoDetails from '../common/components/tab-reco-details';
import { hasRADetailsAccess } from '../common/utils/viz-roles';



const CarDetails = ({ type, dealerType, refresh = () => { } }) => {

  const { carDetails, userInfo, selectedTab, filters } = useSelector((state) => state.allCars);
  const { isLoading } = useSelector((state) => state.appLoader)
  const [availableTabs, setAvailableTabs] = useState([])
  const dispatch = useDispatch();
  const apiMetaData = getVizPanelServiceData();
  const { rtoCode, inspectionCity } = carDetails?.appointmentDetails || {};
  const email = getLoginUserEmail();

  useEffect(() => {
    let tabs = getAvailableTypes(type);

    setAvailableTabs(tabs);
  }, [])





  const getSelectedTab = () => {
    const selectedIndex = CAR_DETAILS_TAB_LIST.findIndex(tab => tab.id === selectedTab);

    if (type === CARD_TYPE.dealers) {
      if ((filters?.dealerCarCategory?.id === ALL_CARS_TABLE_VIEW.dealersNegoView || filters?.dealerCarCategory?.id === ALL_CARS_TABLE_VIEW.dealersProcuredView) && selectedTab === 5) {
        return hasRADetailsAccess(userInfo?.type) ? selectedIndex - 2 : selectedIndex - 1;
      } else {
        return selectedIndex - 1;
      }
    }

    if (type === CARD_TYPE.pais) {
      return selectedIndex - 1;
    }

    if (type === CARD_TYPE.cars && (dealerType === ALL_CARS_OCB_LIVE_FILTER[2].id || dealerType === ALL_CARS_OCB_LIVE_FILTER[4].id)) {
      if (selectedTab === 5) {
        return selectedIndex - 2;
      } else {
        return selectedIndex - 1;
      }

    }

    return hasRADetailsAccess(userInfo.type) ? selectedIndex : selectedIndex - 1;
  };


  const [selected, setSelected] = useState(getSelectedTab());

  const getAvailableTypes = (type) => {
    const hasAccess = hasRADetailsAccess(userInfo?.type);
    const baseTabs = [CAR_DETAILS_TAB_LIST[1], CAR_DETAILS_TAB_LIST[2]];

    switch (type) {
      case CARD_TYPE.cars:
        if (dealerType === ALL_CARS_OCB_LIVE_FILTER[2].id || dealerType === ALL_CARS_OCB_LIVE_FILTER[4].id) {
          return hasAccess ? [...baseTabs, CAR_DETAILS_TAB_LIST[4]] : baseTabs;
        }
        return hasAccess ? CAR_DETAILS_TAB_LIST : [CAR_DETAILS_TAB_LIST[0], ...baseTabs, CAR_DETAILS_TAB_LIST[3]];

      case CARD_TYPE.dealers:
        if (filters?.dealerCarCategory?.id === ALL_CARS_TABLE_VIEW.dealersOCBView ||
          filters?.dealerCarCategory?.id === ALL_CARS_TABLE_VIEW.dealersAuctionView) {
          return hasAccess ? [...baseTabs, CAR_DETAILS_TAB_LIST[3], CAR_DETAILS_TAB_LIST[4]] : [...baseTabs, CAR_DETAILS_TAB_LIST[3]];
        }
        return hasAccess ? [...baseTabs, CAR_DETAILS_TAB_LIST[4]] : baseTabs;

      case CARD_TYPE.pais:
        return baseTabs;

      default:
        return [];
    }
  };


  const carHighlights = [
    'Engine ' + carDetails?.appointmentDetails?.engineRating, formatNumbers(carDetails?.appointmentDetails?.odometerReading) + ' km', numberWithIndicator(carDetails?.appointmentDetails?.ownerNumber) + ' owner', carDetails?.appointmentDetails?.fuelType + ''
  ];

  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  const handleCopyToClipboard = () => {
    let trackData = {
      email_id: email,
      copiedText: carDetails?.appointmentDetails?.appointmentId,
    }
    copyToClipboard(carDetails?.appointmentDetails?.appointmentId).then(() => {
      showToastMessage('Copied to clipboard', true, 1000);
      trackAnalytics(SegmentEvents.Viz_copied_text, trackData);
    }).catch(() => {
      showToastMessage('Something went wrong', false, 1000);
    });
  }

  const handleClose = () => {
    type === CARD_TYPE.pais && dispatch(getPaiCarDetails(false))
    dispatch(setModal(false))
  }


  if (isLoading)
    return <AppLoader />;


  return (
    <div className="side-bar-filters">
      <div className="overlay" onClick={handleClose}></div>
      <div className="car-details-container">
        <span className="k-icon k-font-icon k-i-close close-icon" onClick={handleClose}></span>

        <div className='car-details'>
          <div className='car-image-container'>

            <ScrollView
              className='carousel-container'
              automaticViewChange={false}
            >
              {carDetails?.appointmentDetails?.shortGallery?.map((item, index) => {
                return (
                  <img
                    src={item?.url ? `${apiMetaData.imagesUrl}/${item.url}` : carImage}
                    alt={`car-image ${index + 1}`}
                    draggable={false}
                    className='car-img'
                  />
                );
              })}
            </ScrollView>

          </div>
          <div className='car-content'>
            <div className="flex-between flex-center">
              <span className="car-title">{carDetails?.appointmentDetails.year} {carDetails?.appointmentDetails.make} {carDetails?.appointmentDetails.model} {carDetails?.appointmentDetails.variant}</span>
              <span className="hours-left">
                <HoursLeft
                  hours={carDetails?.ocbDetails?.endTime || carDetails?.liveAuctionDetails?.endTime || carDetails?.paiDetails?.endTime}
                /></span>
            </div>

            <div className="car-highlights">
              {carHighlights?.map((ele, index) => (
                <span key={ele} className="highlight-chip">{ele}
                  {index == 0 && <span className="word-space-left k-icon k-font-icon k-i-star app-rating-secondary"></span>
                  }

                </span>
              ))}
            </div>


            <div className='flex-between'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span className="car-sub-title">App. ID &nbsp;</span>
                <Tooltip
                  position="top"
                  className="call-tooltip"
                >
                  <span
                    title="Click to copy"
                    className="car-sub-title call cursor-pointer"
                    onClick={handleCopyToClipboard}>
                    {carDetails?.appointmentDetails?.appointmentId}

                  </span>
                </Tooltip>
                <span
                  className="k-icon k-font-icon k-i-file-pdf pdf-icon"
                  onClick={() => downloadPDF(carDetails?.appointmentDetails?.carId)}
                ></span>
              </div>
              <span className="car-sub-title">
                {rtoCode}
                {inspectionCity && ` | ${inspectionCity}`}
              </span>
            </div>
            <hr />

            <div className="flex bid-block">
              {type === CARD_TYPE.pais ? <PAIsBids carDetails={carDetails} /> : null}
              {type === CARD_TYPE.cars ? (
                dealerType === ALL_CARS_OCB_LIVE_FILTER[1].id ? (
                  <CurrentBids carDetails={carDetails} />
                ) : dealerType === ALL_CARS_OCB_LIVE_FILTER[2].id || dealerType === ALL_CARS_OCB_LIVE_FILTER[4].id ? (
                  <NegoView carDetails={carDetails} />
                ) : (
                  <CarsOCBView carDetails={carDetails} />
                )
              ) : null}

              {type === CARD_TYPE.dealers ? (
                dealerType === ALL_CARS_TABLE_VIEW.dealersAuctionView ? <CurrentBids carDetails={carDetails} /> :
                  (dealerType === ALL_CARS_TABLE_VIEW.dealersOCBView ? <NegoBids carDetails={carDetails} /> :
                    <NegoView carDetails={carDetails} />)) : null}


              <div className="dealers-info-block">
                {carDetails?.dealerTags?.length > 0 && <div className="label"> Dealer's Info</div>}
                <div> <AppChips
                  data={carDetails?.dealerTags || []} className={'secondary-chips'}
                /></div>
              </div>

              {carDetails?.carDetails?.redFlags ?

                <div className="flag-content-container">
                  {carDetails.carDetails.redFlags.map((item) => {
                    return (
                      <div className="flag-content" key={item}>
                        <span>
                          <FlagIcon fill={DANGER_ICON_COLOR} />
                        </span>
                        <span className="flag-text">{item}</span>
                      </div>
                    )
                  })}
                </div>

                : null}

              <TabStrip
                selected={selected}
                onSelect={handleSelect}
                className="app-tabs"
              >
                {availableTabs &&
                  availableTabs.length &&
                  availableTabs.map((tab, index) => {
                    return (
                      <TabStripTab title={
                        <div>
                          {tab.label}
                        </div>
                      }
                        key={'availableTab' + index}>
                        {
                          {
                            [CAR_DETAILS_TAB_LIST[0].id]: <TabRecoDetails appointmentId={carDetails?.appointmentDetails.appointmentId} />,
                            [CAR_DETAILS_TAB_LIST[1].id]: <TabRfcDetails carDetails={carDetails} />,
                            [CAR_DETAILS_TAB_LIST[2].id]: <TabCarDetails carDetails={carDetails} />,
                            [CAR_DETAILS_TAB_LIST[3].id]: <TabCurrentBids appointmentId={carDetails?.appointmentDetails.appointmentId} />,
                            [CAR_DETAILS_TAB_LIST[4].id]: <TabUnlockRADetails carDetails={carDetails} />,
                          }[tab.id]
                        }
                      </TabStripTab>
                    );
                  })}
              </TabStrip>
            </div>

          </div>


        </div>
      </div>
    </div>

  )
}

export default CarDetails;