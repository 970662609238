import AppTable from '../../common/components/app-table'
import { FIELDS_KEY, TABLE_LABELS } from '../../common/constants/table.constants';
import './list-view.scss'
import ControlCenterTableCell from '../table-cell'
import { Loader } from '@progress/kendo-react-indicators';

const CustomCell = cellProps => {
  return <ControlCenterTableCell
    {...cellProps}
  />

};
const ListView = ({ logsData, setPage, page, isLoading }) => {


  const columns = [
    {
      field: FIELDS_KEY.UploadedOn,
      title: TABLE_LABELS.UploadedOn,
      cells: CustomCell,
    },
    {
      field: FIELDS_KEY.FileName,
      title: TABLE_LABELS.FileName,
      cells: CustomCell,
    }, {
      field: FIELDS_KEY.FileStatus,
      title: TABLE_LABELS.FileStatus,
      cells: CustomCell,
    },
    {
      field: FIELDS_KEY.Comment,
      title: TABLE_LABELS.Comment,
      cells: CustomCell,
    },

  ];



  const handlePageChange = pageNumber => {
    setPage({
      skip: pageNumber.page.skip / pageNumber.page.take,
      take: pageNumber.page.take
    });
  }



  return (
    <div className="right-container">
      {isLoading ?
        <div className='loading-container'>  <Loader type='infinite-spinner' /></div>
        :
        <AppTable
          admin={true}
          wrapperClassName="control-center-table"
          headerData={[
            ...columns
          ]}
          data={logsData.data || []}
          isHoverable
          totalCount={logsData?.pagination?.totalElements}
          take={page.take}
          skip={page.skip}
          handlePageChange={handlePageChange}
        />
      }

    </div>
  )
}

export default ListView