function DeleteIcon(props) {
  return (
    <svg
      width={16}
      height={18}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 18c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 011 16V3H0V1h5V0h6v1h5v2h-1v13c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0113 18H3zM13 3H3v13h10V3zM5 14h2V5H5v9zm4 0h2V5H9v9z"
        fill="#EB5757"
      />
    </svg>
  )
}

export default DeleteIcon
