import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { useState } from "react";
import titleBar from "../../shared/title-bar";
import { BaGrid } from "../../shared/ba-grid";
import useBaColumns from "../../../hooks/useBaColumns";

const BusinessApprovals = () => {
  const [showBusinessApproval, setShowBusinessApproval] = useState(false);
  const [updatedBusinessApproval, visibleColumns, baTitle] = useBaColumns();

  return (
    <ExpansionPanel
      title={titleBar("Business Approvals", baTitle)}
      subtitle={"View approval details"}
      className="k-mt-4 k-rounded-lg"
      expanded={showBusinessApproval}
      tabIndex={0}
      onAction={() => setShowBusinessApproval(!showBusinessApproval)}
    >
      {showBusinessApproval && (
        <ExpansionPanelContent>
          <BaGrid
            updatedBusinessApproval={updatedBusinessApproval}
            visibleColumns={visibleColumns}
          />
        </ExpansionPanelContent>
      )}
    </ExpansionPanel>
  );
};

export default BusinessApprovals;
