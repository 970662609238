import { Dialog } from "@progress/kendo-react-dialogs";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {
  getLoginUserEmail,
  showToastMessage,
} from "../../../../../../utils/utils";
import { PrAutomationService } from "../../../../../../services";
import { PR_AUTOMATION } from "../../../../../../store/types";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import styles from "../../../negotiation/assets/styles/pr.styles.module.css";
import { fetchOrderDetails } from "../../../../../../store/actions/pr-automation";
import CustomTitle from "../../shared/custom-title";
const MarkReasons = (props) => {
  const { toggleMarkReasons } = props || {};
  const [selectedValue, setSelectedValue] = useState("");
  const lms = useSelector((state) => state.lms);
  const { appointmentId } = lms || {};
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const payload = {
      appointmentId,
      accountType: "PRIMARY",
      mismatchReason: selectedValue,
      mismatchMarkedBy: getLoginUserEmail(),
    };
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.markReasons(payload)
      .then((res) => {
        if (res && res.status === 200) {
          showToastMessage("Mismatch reason marked successfully", true);
          dispatch(
            fetchOrderDetails({
              appointmentId,
              documentCodes: ["AADHAR_CARD", "PAN_CARD_COPY"],
            })
          );
          toggleMarkReasons();
        }
      })
      .catch((error) => {
        showToastMessage(error?.message || error?.error, false);
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
  const handleChange = React.useCallback(
    (e) => {
      setSelectedValue(e.value);
    },
    [setSelectedValue]
  );
  return (
    <Dialog
      title={CustomTitle("Confirm name mismatch reasons")}
      onClose={toggleMarkReasons}
      width="610px"
      contentStyle={{ width: "auto", marginTop: "24px" }}
    >
      <GridLayout
        cols={[
          {
            width: "50%",
          },
          {
            width: "50%",
          },
        ]}
        gap={{
          rows: "28px",
          cols: "10px",
        }}
      >
        <GridLayoutItem row={1} col={1}>
          <RadioButton
            name="friend"
            value="FRIEND_FAMILY_ACCOUNT"
            checked={selectedValue === "FRIEND_FAMILY_ACCOUNT"}
            label="It's friend/family's car (3rd party)"
            onChange={handleChange}
            size="large"
            className={styles.radio}
          />
        </GridLayoutItem>
        <GridLayoutItem row={1} col={2}>
          <RadioButton
            name="company"
            value="COMPANY_OWNED_CAR"
            checked={selectedValue === "COMPANY_OWNED_CAR"}
            label="It's a company owned car"
            onChange={handleChange}
            size="large"
            className={styles.radio}
          />
        </GridLayoutItem>
        <GridLayoutItem row={2} col={1}>
          <RadioButton
            name="nameOnRc"
            value="WRONG_NAME_ON_RC"
            checked={selectedValue === "WRONG_NAME_ON_RC"}
            label="Name on RC is wrong"
            onChange={handleChange}
            size="large"
            className={styles.radio}
          />
        </GridLayoutItem>
      </GridLayout>
      <div className={`k-mt-15 ${styles.alignRight}`}>
        <Button
          onClick={toggleMarkReasons}
          className={`${styles.cancelCta} k-mr-5`}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          className={styles.submitCta}
          disabled={!selectedValue}
        >
          Send for approval
        </Button>
      </div>
    </Dialog>
  );
};

export default MarkReasons;
