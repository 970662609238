import { TYPE_LOADER, TYPE_PAIS } from "../types";
import { vizPanelService } from "../../services";
import { getReqParams, showToastMessage } from "../../common/utils/utils";
export const fetchPAIs =
  (params = {}, ongoing = false, firstCall = true) =>
    (dispatch) => {
      if (firstCall) {
        dispatch({ type: TYPE_LOADER.SHOW_LOADER });
      }
      let reqParams = getReqParams(params);
      vizPanelService.getPAIs(reqParams)
        .then((resp) => {
          if (firstCall) {
            dispatch({ type: TYPE_LOADER.HIDE_LOADER });
          }
          if (ongoing) {
            dispatch({
              type: TYPE_PAIS.FETCH_PAIS__ONGOING_SUCCESS,
              payload: {
                data: [...resp.data],
                totalElements: resp.pagination.totalElements,
              },
            });
          } else {
            dispatch({
              type: TYPE_PAIS.FETCH_PAIS_SUCCESS,
              payload: {
                data: [...resp.data],
                page: resp.pagination.page,
                pageSize: resp.pagination.pageSize,
                totalElements: resp.pagination.totalElements,
                totalPages: resp.pagination.totalPages,
              },
            });
          }
        })
        .catch((err) => {
          if (firstCall) {
            dispatch({ type: TYPE_LOADER.HIDE_LOADER });
          }
          showToastMessage(err?.error || err?.message, false);
          dispatch({
            type: TYPE_PAIS.FETCH_PAIS_FAILURE,
            payload: err,
          });
        })

    };

export const updatePaisFilters = (filter) => (dispatch) => {
  dispatch({ type: TYPE_PAIS.UPDATE_PAIS_FILTERS, payload: filter });
}

export const clearPaisData =
  () =>
    (dispatch) => {
      dispatch({ type: TYPE_PAIS.CLEAR_PAIS_DATA });
    };