import { getDateTime } from '../../../../viz-panel/common/utils/utils'
import styles from './styles.module.css'

const TableDateCell = ({ dataItem }) => {
  return (
    <td>
      <div className={styles['date-cell']}>
        {getDateTime(dataItem?.eventDate || dataItem?.validTillDate)}
      </div>
    </td>
  )
}

export default TableDateCell