import { Dialog} from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import React, { useContext, useState } from 'react'
import LmsStateManagementContext from '../../context/lms-stage-management-context';
import styles from './style.module.css';

const DATA = [
  { label: "Break", value: "BREAK" },
  { label: "Meeting", value: "MEETING" },
  { label: "Working on PR", value: "WORKING_ON_PR" },
  { label: "Working on mail", value: "WORKING_ON_MAIL" },
  { label: "Checkout", value: "CHECKOUT" },
]

function LmsActivityPopup({
  onSubmit
}) {

  const { addRaActivityHandler,setShowPopup,isRaActive } = useContext(LmsStateManagementContext)
  const [ inactiveReason, setInactiveReason ] = useState(DATA?.[0].value)

  const dropdownHandler = (e) =>{
    const selectedValue = e.target.value.value;
    setInactiveReason(selectedValue);
  }

  const submitHandler = () =>{
    addRaActivityHandler({activityStatus:isRaActive?'INACTIVE':'ACTIVE',inactivityReason:inactiveReason})
    if(onSubmit){
      onSubmit()
    }
    setShowPopup(false)
  }

  return (
    <Dialog
      title="Activity Tracker"
      closeIcon={false}
      width={"400px"}
      contentStyle={{ width: "auto" }}
      className={styles.head}
    >
      <div className={styles.head_content}>
        <p className={styles.head_reason_label}>Please select the reason</p>
        <DropDownList
          className={styles.head_reason_dropdown}
          data={DATA}
          textField="label"
          dataItemKey="value"
          value={DATA.find(item => item.value === inactiveReason)}
          placeHolder="Please select the reason for being inactive"
          onChange={dropdownHandler}
          style={{ backgroundColor: 'white', border: '1px solid #ebf5ff', color: 'black' }}
          popupSettings={{className:styles.options_dropdown}}
        />
        <button className={styles.submitHandler} onClick={submitHandler}>Submit</button>
      </div>  
    </Dialog>
  )
}

export default LmsActivityPopup
