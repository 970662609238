import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { toast } from "react-toastify";
import {
    LOCAL_STORAGE_KEYS,
    NUMBERS,
    FF_PAGE_TYPES,
    Lms_roles,
    FORM_ELEMENT_TYPE,
    NAME_ERROR,
} from "../utils/constants/values.constants";
import {
    USER_ROLE_KEYS,
    ROLE_ACCESS_MENUS,
    PANEL_MAIN_MENU_ITEMS,
} from "../utils/constants/menu.constants";
import { getSessionToken, getJwtRoles } from "@descope/react-sdk";
import { getTenantId } from "../utils/constants/url.constants";
import { showInspectorPDF } from "../views/viz-panel/store/actions/allCars";
import { INVALID_MOBILE_NUMBER, PAN_REGEX, PINCODE_REGEX, VALID_MOBILE_NUMBER } from "./constants/regex.constants";
import { uniqueId } from "lodash";
dayjs.extend(utc);
dayjs.extend(timezone);

export const getUserToken = () => {
    let userToken = JSON.parse(localStorage.getItem("okta-token-storage"));
    if (userToken && userToken.accessToken && userToken.accessToken.accessToken) {
        return userToken.accessToken.accessToken;
    }
    return null;
};

export const removeNewLines = (str) => {
    if (typeof str === "string") {
        return str.trim().replaceAll("\n", " ");
    }
    return str;
};

export const dateDifferenceInDays = (date1 = "") => {
    const d1 = new Date(date1);
    const d2 = new Date(current_Date());

    const diff = d2.getTime() - d1.getTime();
    const daydiff = diff / (1000 * 60 * 60 * 24);
    return daydiff ? daydiff : 0;
};

export const getDisplayedCoulmns = (columns) => {
    return Object.values(columns);
};

export const current_Date = () => {
    const today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    const date = yyyy + "-" + mm + "-" + dd;
    return date;
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const convertObjectToParams = (params) => {
    if (typeof params === "string") {
        return params;
    }
    let finalParams = "";
    Object.keys(params).forEach((key, index) => {
        if (index === 0) {
            finalParams += "?";
        }
        finalParams += key + "=" + params[key];
        if (index + 1 !== Object.keys(params).length) {
            finalParams += "&";
        }
    });
    return finalParams;
};

export const allCommonProperties = (objects) => {
    if (!objects.length) return {};

    let first = objects[0];

    let commonKeys = Object.keys(first).filter(function (p) {
        return objects.every(function (o) {
            return first[p] === o[p];
        });
    });

    let commonKeysWithValue = {};
    commonKeys.forEach((key) => (commonKeysWithValue[key] = first[key]));

    return commonKeysWithValue;
};

export const getDateFormateMMDDYYY = (dateString) => {
    const dateObj = dateString.split("/");
    const date = dateObj[0];
    dateObj[0] = dateObj[1];
    dateObj[1] = date;
    return dateObj.join("/");
};

export const tzDate = (date) => {
    let DATE = date ? new Date(date) : new Date();
    let TIMEZONE_DATE = DATE.toLocaleString("en-US", {
        timeZone: TIMEZONE[countryCheck],
    });
    return new Date(TIMEZONE_DATE);
};

export const customDateFormat = (date, separator = "-") => {
    if (!date) return "";

    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join(separator);
};

export const dateFormat = (e) => {
    let dateTime = tzDate(e);
    return dayjs(dateTime).format("YYYY-MM-DD");
};

export const timeFormat = (e) => {
    let newTime = tzDate(e);
    return dayjs(newTime).format("hh:mm A");
};
export const numberFormat = (num) => {
    return num?.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 0,
    });
};
export const isValidTimeForSlot = () => {
    const startTime = tzDate().getTime();
    const endDate = tzDate();
    if (countryCheck === Country.AE) {
        endDate.setHours(22, 59, 59, 999);
    } else if (countryCheck === Country.KSA) {
        endDate.setHours(20, 59, 59, 999);
    } else if (countryCheck === Country.AU) {
        endDate.setHours(23, 59, 59, 999);
    } else {
        endDate.setHours(16, 59, 59, 999);
    }
    const endTime = endDate.getTime();

    return endTime >= startTime;
};

export const isValidAccountNumber = (accountNumber) => {
    const regex = /^[a-zA-Z0-9].*[a-zA-Z0-9]$/;
    return regex.test(accountNumber);
}

export const getFinalRanges = (
    startTime,
    endTime,
    difference,
    valTime = tzDate(),
    initialLeadTime
) => {
    let CurrentDate = tzDate();
    const isItSameDate =
        valTime?.getDate() === CurrentDate.getDate() &&
        valTime?.getMonth() === CurrentDate.getMonth();
    if (isItSameDate && !isValidTimeForSlot()) {
        return [];
    }

    let ranges = [];
    let finalRange = [];
    ranges = getRange(startTime, endTime, difference, [], initialLeadTime);

    let newDate = tzDate().getTime();
    let currentTime =
        valTime && valTime?.getDate() !== CurrentDate.getDate()
            ? new Date(newDate + difference * 60000)
            : new Date(newDate + initialLeadTime * 60000);

    const hour = currentTime.getHours();
    const minute = currentTime.getMinutes();
    const mins = Number(minute) > 10 ? minute : "0" + minute;

    let newTime = "";
    if (valTime === undefined) {
        newTime = hour + "." + mins;
    } else if (!isItSameDate) {
        newTime = startTime;
    } else {
        newTime = hour + "." + mins;
    }

    if (ranges && ranges.length) {
        ranges.forEach((element) => {
            let splitedElement = element.split("-")[0];
            if (
                parseFloat(splitedElement) >= parseFloat(newTime) ||
                countryCheck == Country.AU
            ) {
                finalRange.push(element.replace(".", ":"));
            }
        });
    }

    return finalRange;
};

export const getRange = (startTimeNew, endTime, difference, ranges) => {
    let rangeStart = startTimeNew;

    let rangeEnd = 0,
        startTimeSplit = startTimeNew.split("."),
        minsPlusDifference = Number(startTimeSplit[1]) + Number(difference),
        timeArray = [],
        newHour = 0,
        newMins = 0;

    if (minsPlusDifference > 60) {
        if (difference > 60) {
            let newHoursToAdd = Math.floor(difference / 60);
            let minsAfterAddingHours =
                Number(startTimeSplit[1]) + (difference - 60 * newHoursToAdd);
            let minsAfterRemovingHours =
                Number(startTimeSplit[1]) - (difference - 60 * newHoursToAdd);
            newHour = Number(startTimeSplit[0]) + newHoursToAdd;
            newHour = minsAfterAddingHours >= 60 ? newHour + 1 : newHour;
            newMins =
                minsAfterAddingHours >= 60
                    ? minsAfterRemovingHours
                    : minsAfterAddingHours;
        } else {
            newHour = Number(startTimeSplit[0]) + 1;
            newMins = difference - (60 - startTimeSplit[1]);
        }
    } else if (minsPlusDifference === 60) {
        newHour = Number(startTimeSplit[0]) + 1;
        newMins = 0;
    } else {
        newHour = startTimeSplit[0];
        newMins = minsPlusDifference;
    }

    if (newMins < 10) {
        newMins = "0" + newMins;
    }
    if (newHour < 10) {
        newHour = "0" + newHour;
    }

    timeArray = [newHour, newMins];
    rangeEnd = timeArray.join(".");

    if (parseFloat(rangeEnd) <= parseFloat(endTime)) {
        ranges.push(rangeStart + "-" + rangeEnd);
        getRange(rangeEnd, endTime, difference, ranges);
    }

    return ranges;
};

export const IsAlphaNumeric = (alphane) => {
    var numaric = alphane;
    for (var j = 0; j < numaric.length; j++) {
        var alphaa = numaric.charAt(j);
        var hh = alphaa.charCodeAt(0);
        //eslint-disable-next-line no-empty
        if ((hh > 47 && hh < 58) || (hh > 64 && hh < 91) || (hh > 96 && hh < 123)) {
        } else {
            return false;
        }
    }

    return true;
};

export const IsAlphaNumericThai = (alphane) => {
    var numaric = alphane;
    let thaiUnicodeRegex = "^[\u0E00-\u0E7FA-Za-z0-9\\-]*$";
    let thaiCharacter = numaric.match(thaiUnicodeRegex);
    if (thaiCharacter) {
        return true;
    }
    return false;
};
export const convertToNumber = (value) => {
    return value?.replace(/₹|,/g, "");
};

export const isObjectEmpty = (dataObject) =>
    Object.keys(dataObject).length === 0;

export const formatDateTime = (dateTime) => {
    if (!dateTime) {
        return "";
    }
    return (
        dayjs(dateTime)
            // .tz(TIMEZONE[countryCheck])
            .format("YYYY-MM-DD hh:mm A")
    );
};

export const saveToLocalStorage = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
        console.log(err);
    }
};

export const getFromLocalStorage = (key) => {
    let val = localStorage.getItem(key);
    try {
        if (val) return JSON.parse(val);
    } catch (err) {
        return null;
    }
    return val;
};

export const removeFromLocalStorage = (key) => {
    localStorage.removeItem(key);
};

export const showToastMessage = (
    message,
    isSuccess = true,
    autoClose = 10000,
    id = uniqueId()
) => {
    if (isSuccess) {
        toast.success(message, {
            hideProgressBar: true,
            autoClose: autoClose,
            toastId: id
        });
    } else {
        toast.error(message, {
            hideProgressBar: true,
            autoClose: autoClose,
            closeOnClick: true,
            toastId: id
        });
    }
};

export const GetQueryString = (field) => {
    var href = window.location.href;
    var reg = new RegExp("[?&]" + field + "=([^&#]*)", "i");
    var string = reg.exec(href);
    return string ? string[1] : null;
};

export const isNullish = (obj) => {
    return Object.values(obj).every((value) => {
        if (value === null) {
            return true;
        }
        return false;
    });
};

export const getUIValue = (value, fieldClicked) => {
    switch (fieldClicked) {
        default:
            return value ? value : "-";
    }
};

export const createTimeRange = (from, to, separator, intervals) => {
    const timeRange = [];

    for (let i = from; i <= to; i++) {
        for (let j = 0; j < intervals.length; j++) {
            let time = `${i}${separator}${intervals[j]}`;
            if (i < 10) {
                time = `0${time}`;
            }
            timeRange.push(time);
        }
    }
    timeRange.pop();
    return timeRange;
};

export const dashifyYYYYMMDD = (date) => {
    return date.toString().replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
};

export const copyToClipboard = (textToCopy) => {
    try {
        return navigator.clipboard.writeText(textToCopy);
    } catch (e) {
        console.error(e);
    }

};

export const downloadPDF = async (id) => {
    showInspectorPDF(id);
}

export const convertTime12to24 = (from, suffix, to, toSuffix) => {
    let fromSlot = null;
    let toSlot = null;
    if (from && to) {
        if (from && suffix.toLowerCase().indexOf("am") !== -1) {
            fromSlot = parseInt(from, 10);
        } else if (from && suffix.toLowerCase().indexOf("pm") !== -1) {
            fromSlot = (parseInt(from, 10) % 12) + 12;
        }

        if (to && toSuffix.toLowerCase().indexOf("pm") !== -1) {
            toSlot = (parseInt(to, 10) % 12) + 12;
        } else if (to && toSuffix.toLowerCase().indexOf("am") !== -1) {
            toSlot = parseInt(to, 10);
        }

        return `${fromSlot}:00-${toSlot}:00`;
    } else {
        return false;
    }
};

export const UpdateUserAssignGroup = () => {
    // const oktaTokenData = getFromLocalStorage(LOCAL_STORAGE_KEYS.OKTA_TOKEN_DATA);
    const userGroups = [];
    const allowedGroups = Object.values(USER_ROLE_KEYS);
    const userAccessGroups = allowedGroups.filter((item) =>
        userGroups.includes(item)
    );
    if (userAccessGroups.includes(USER_ROLE_KEYS.C2B_RETAIL_LMS_RA)) {
        saveToLocalStorage(
            LOCAL_STORAGE_KEYS.USER_ROLE,
            USER_ROLE_KEYS.C2B_RETAIL_LMS_RA
        );
    } else if (userAccessGroups.includes(USER_ROLE_KEYS.FINDER)) {
        saveToLocalStorage(LOCAL_STORAGE_KEYS.USER_ROLE, USER_ROLE_KEYS.FINDER);
    } else if (userAccessGroups.includes(USER_ROLE_KEYS.DISPOSE)) {
        saveToLocalStorage(LOCAL_STORAGE_KEYS.USER_ROLE, USER_ROLE_KEYS.DISPOSE);
    } else if (
        userAccessGroups.includes(USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_AGENT)
    ) {
        saveToLocalStorage(
            LOCAL_STORAGE_KEYS.USER_ROLE,
            USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_AGENT
        );
    } else if (userAccessGroups.includes(USER_ROLE_KEYS.B2B_DEALER_PAYOUT_VIEW)) {
        saveToLocalStorage(
            LOCAL_STORAGE_KEYS.USER_ROLE,
            USER_ROLE_KEYS.B2B_DEALER_PAYOUT_VIEW
        );
    } else if (userAccessGroups.includes(USER_ROLE_KEYS.B2B_DEALER_PAYOUT_FLAG)) {
        saveToLocalStorage(
            LOCAL_STORAGE_KEYS.USER_ROLE,
            USER_ROLE_KEYS.B2B_DEALER_PAYOUT_FLAG
        );
    } else if (userAccessGroups.includes(USER_ROLE_KEYS.B2B_DEALER_PAYOUT_PAY)) {
        saveToLocalStorage(
            LOCAL_STORAGE_KEYS.USER_ROLE,
            USER_ROLE_KEYS.B2B_DEALER_PAYOUT_PAY
        );
    } else if (userAccessGroups.includes(USER_ROLE_KEYS.C2B_RETAIL_LMS_ADMIN)) {
        saveToLocalStorage(
            LOCAL_STORAGE_KEYS.USER_ROLE,
            USER_ROLE_KEYS.C2B_RETAIL_LMS_ADMIN
        );
    } else if (userAccessGroups.includes(USER_ROLE_KEYS.B2B_NCD_ADMIN)) {
        saveToLocalStorage(
            LOCAL_STORAGE_KEYS.USER_ROLE,
            USER_ROLE_KEYS.B2B_NCD_ADMIN
        );
    }
    saveToLocalStorage(LOCAL_STORAGE_KEYS.USER_ACCESS_ROLES, userAccessGroups);
};
export const getLoginUserEmail = () => {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));
    return user?.email;
};

export const getLoginUserName = () => {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));
    return user?.name;
};

export const getLoginUserId = () => {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));
    return user?.userId;
};

export const getLoginUserAccessToken = () => {
    const userInfo = getFromLocalStorage(LOCAL_STORAGE_KEYS.OKTA_USER_LOGIN_INFO);
    return userInfo?.email;
};
export const getHomePageByRole = () => {
    const roles = getUserRoles();
    let routePath = "/";
    if (roles.includes(USER_ROLE_KEYS.C2B_RETAIL_LMS_RA)) {
        // const linkedPath = window.location.pathname;
        // if (linkedPath.split("negoview")?.[1]) {
        //   routePath = window.location.pathname;
        const previous_pathname = getFromLocalStorage(
            LOCAL_STORAGE_KEYS.PREV_PATHNAME
        );
        if (previous_pathname) {
            routePath = previous_pathname;
        } else {
            routePath = "/retail/lms/negoview";
        }
    } else if (
        roles.some((role) =>
            [
                USER_ROLE_KEYS.DISPOSE,
                USER_ROLE_KEYS.FINDER,
                USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_VIEW,
            ].includes(role)
        )
    ) {
        routePath = "/operations/challans";
    } else if (roles.includes(USER_ROLE_KEYS.B2B_NCD_ADMIN)) {
        routePath = "/dealer/fulfillments";
    } else if (roles.includes(USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN)) {
        routePath = "/operations/admin/finders";
    } else if (roles.includes(USER_ROLE_KEYS.C2B_OPS_FF_VERIFICATION_AGENT)) {
        routePath = "/operations/verification";
    } else if (roles.includes(USER_ROLE_KEYS.C2B_OPS_FF_ADMIN)) {
        routePath = "/operations/adminstratorView";
    } else if (roles.includes(USER_ROLE_KEYS.C2B_OPS_CJ_VERIFY_ADMIN)) {
        routePath = "/inspection/cj-footprint";
    }
     else if (
        roles.some((role) =>
            [
                USER_ROLE_KEYS.B2B_DEALER_PAYOUT_VIEW,
                USER_ROLE_KEYS.B2B_DEALER_PAYOUT_PAY,
                USER_ROLE_KEYS.B2B_DEALER_PAYOUT_FLAG,
            ].includes(role)
        )
    ) {
        routePath = "/dealer/payouts";
    } else if (
        roles.some((role) =>
            [
                USER_ROLE_KEYS.C2B_INSP_ANNOUNCEMENT_ADMIN,
                USER_ROLE_KEYS.C2B_INSP_CJ_REFERRAL_ADMIN,
                USER_ROLE_KEYS.C2B_OPS_QC_CJ_INCENTIVE_ADMIN
            ].includes(role)
        )
    ) {
        routePath = "/inspection/admin";
    } else if (roles.includes(USER_ROLE_KEYS.C2B_RETAIL_LMS_ADMIN)) {
        routePath = "/retail/leadmon";
    } else if (roles.includes(USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_ADMIN)) {
        routePath = "/operations/admin/services";
    } else if (roles.includes(USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_AGENT)) {
        routePath = "/operations/services";
    } else if (roles.includes(USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_VIEW)) {
        routePath = "/operations/services";
    } else if (roles.includes(USER_ROLE_KEYS.C2B_CONSUMER_DATA_ADMIN)) {
        routePath = "/consumer/settings";
    } else if (roles.includes(USER_ROLE_KEYS.C2B_RETAIL_VAS_ADMIN)) {
        routePath = "/consumer/vas/priceupdate";
    } else if (roles.includes(USER_ROLE_KEYS.C2B_VIZ_PANEL)) {
        routePath = "/viz/my-dealers";
    } else if (roles.includes(USER_ROLE_KEYS.C2B_OPS_QC_HYPO_ADMIN)) {
        routePath = "/qc/hypo-qc-panel";
    } else if (roles.includes(USER_ROLE_KEYS.C2B_FINANCE_SELLER_AGENT)) {
        routePath = "/seller-finance/leads";
    } else if (roles.includes(USER_ROLE_KEYS.C2B_FINANCE_SELLER_ADMIN)) {
        routePath = "/seller-finance/leads";
    }
    else if (roles.includes(USER_ROLE_KEYS.RA) || roles.includes(USER_ROLE_KEYS.RM)) {
        routePath = "/lms/retargeting";
    }
    else if (roles.includes(USER_ROLE_KEYS.C2B_DEALER_BUYBACK)) {
        routePath = "/dealer/buy-back";
    }
    return routePath;
};

export const convertToFlatArray = (items) => {
    const flat = [];
    items.forEach((item) => {
        if (!!item?.children?.length) {
            flat.push(item);
            flat.push(...convertToFlatArray(item.children));
        } else {
            flat.push(item);
        }
    });
    return flat;
};

export const onBackLogin = () => {
    window.location = `${window.origin}/login`;
};
export const formatDate = (date) => {
    if (!date) {
        return "";
    }
    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", {
        month: "2-digit",
    });
    const day = date.toLocaleString("default", { day: "2-digit" });
    return [year, month, day].join("-");
};

export const formatDateV2 = (date) => {
    if (!date) {
        return "";
    }
    return date.substring(0, 10);
};

export const getStatus = (name) => {
    switch (name) {
        case "ASSIGNED":
            return { css: "status-assigned", label: "Assigned" };
        case "RESOLVED":
            return { css: "status-resolved", label: "Resolved" };
        case "FULFILLED":
            return { css: "status-resolved", label: "Fulfilled" };
        case "REOPENED":
            return { css: "unable-fullfil", label: "Reopened" };
        case "UNABLE_TO_FULFILL":
            return { css: "unable-fullfil", label: "Unable to fulfill" };
        case "CREATED":
            return { css: "status-unassigned", label: "Unassigned" };
        case "REJECTED":
            return { css: "status-rejected", label: "Rejected" };
        case "UNASSIGNED":
            return { css: "status-unassigned", label: "Unassigned" };
        case "RAISE_ACCEPTED":
            return {
                css: "status-raise-accepted",
                label: (
                    <>
                        <span className="k-icon k-i-circle" />
                        Raise Accepted
                    </>
                ),
            };
        case "RAISE_REJECTED":
            return {
                css: "status-raise-rejected",
                label: (
                    <>
                        <span className="k-icon k-i-circle" />
                        Raise Rejected
                    </>
                ),
            };
        case "PAYMENT_RAISED":
            return {
                css: "status-raised",
                label: (
                    <>
                        <span className="k-icon k-i-circle" />
                        Payment Raised
                    </>
                ),
            };
        case "AMENDED":
            return {
                css: "status-amended",
                label: (
                    <>
                        <span className="k-icon k-i-circle" />
                        Raise Amended
                    </>
                ),
            };
        case "ACCEPTED":
            return { css: "status-raise-accepted", label: "Accepted" };
        case "PENDING":
            return {
                css: "status-pending",
                label: (
                    <>
                        <span className="k-icon k-i-circle" />
                        PENDING
                    </>
                ),
            };
        default:
            break;
    }
};

export const getRequestItemStatus = (name) => {
    switch (name) {
        case "CREATED":
            return { css: "status-item-saved", label: "Saved" };
        case "REJECTED":
            return { css: "status-item-rejected", label: "Rejected" };
        case "SUBMITTED":
        case "ASSIGNED":
        case "AGENT_ASSIGNED":
            return { css: "status-item-submitted", label: "Submitted" };
        case "PAID":
            return { css: "dispose-paid", label: "Paid" };
        case "UNABLE_TO_FULFILL":
            return { css: "unable-fullfil", label: "Unable to Fullfil" };
        case "RAISE_ACCEPTED":
            return { css: "status-raise-accepted", label: "Raise Accepted" };
        case "RAISE_REJECTED":
            return { css: "status-raise-rejected", label: "Raise Rejected" };
        default:
            break;
    }
};
export const getChallanItemStatus = (name) => {
    switch (name) {
        case "ASSIGNED":
            return { css: "status-assigned", label: "Assigned" };
        case "UNASSIGNED":
        case "CREATED":
            return { css: "status-unassigned", label: "Unassigned" };
        case "PAID":
            return { css: "dispose-paid", label: "Paid" };
        case "FULFILLED":
            return { css: "status-resolved", label: "Fulfilled" };
        case "SUBMITTED":
            return { css: "status-unassigned", label: "Unassigned" };
        case "AGENT_ASSIGNED":
            return { css: "dispose-agent-assigned", label: "Agent Assigned" };
        case "UNABLE_TO_FULFILL":
            return { css: "unable-fullfil", label: "Unable to Fulfill" };
        case "VC_PROCEEDING":
            return { css: "dispose-vc-proceeding", label: "VC Proceeding" };
        case "RAISE_ACCEPTED":
        case "ACCEPTED":
            return {
                css: "status-raise-accepted",
                label: (
                    <>
                        <span className="k-icon k-i-circle" />
                        Raise Accepted
                    </>
                ),
            };
        case "RAISE_REJECTED":
            return {
                css: "status-raise-rejected",
                label: (
                    <>
                        <span className="k-icon k-i-circle" />
                        Raise Rejected
                    </>
                ),
            };
        case "PAYMENT_RAISED":
            return {
                css: "status-raised",
                label: (
                    <>
                        <span className="k-icon k-i-circle" />
                        Payment Raised
                    </>
                ),
            };
        case "RAISE_AMENDED":
        case "AMENDED":
            return {
                css: "status-amended",
                label: (
                    <>
                        <span className="k-icon k-i-circle" />
                        Raise Amended
                    </>
                ),
            };
        case "REJECTED":
            return {
                css: "unable-fullfil",
                label: "Rejected",
            };
        case "PENDING":
            return {
                css: "status-pending",
                label: (
                    <>
                        <span className="k-icon k-i-circle" />
                        Pending
                    </>
                ),
            };
        default:
            break;
    }
};

export const getLeftMenus = () => {
    const groups = getUserRoles();
    let menus = {};
    Object.values(USER_ROLE_KEYS).forEach((value) => {
        if (groups.includes(value)) {
            const groupMenu = ROLE_ACCESS_MENUS[value];
            groupMenu &&
                Object.keys(groupMenu).map((key) => {
                    const childs = !!menus[key] ? menus[key] : [];
                    menus = {
                        ...menus,
                        [key]: [...childs, ...groupMenu[key]],
                    };
                });
        }
    });
    let panelMenus = [];
    Object.keys(menus).map((key) => {
        const menuData = menus[key];
        const menuItem = PANEL_MAIN_MENU_ITEMS[key];
        let subMenus = [];
        if (!menuData.length) {
            subMenus = Object.values(menuItem.children);
        } else {
            menuData.map((subMenuKey) => {
                subMenus = [...subMenus, menuItem.children[subMenuKey]];
            });
        }
        const finalSubMenus = subMenus.filter(
            (item, index) => subMenus.indexOf(item) === index
        );
        panelMenus = [...panelMenus, { ...menuItem, children: [...finalSubMenus] }];
    });
    return panelMenus;
};

export const getFilename = (path) => {
    const pathSegments = (path || "").split(/[\\\/]/).filter(Boolean);
    // Check if the last segment is a number
    const lastSegment = pathSegments[pathSegments.length - 1];
    if (!isNaN(lastSegment)) {
        // If it's a number, get the second last segment
        return pathSegments[pathSegments.length - 2] || "";
    }
    // If the last segment is not a number, return it
    return lastSegment || "";
};

export const getRoundedByTwoDigit = (value) => {
    if (value === NUMBERS.ZERO) {
        return NUMBERS.ZERO;
    } else if (!value || isNaN(value)) {
        return "";
    } else {
        return parseFloat(value).toFixed(NUMBERS.TWO);
    }
};

export const shortenString = (inputString, maxLength) => {
    if (inputString?.length <= maxLength) {
        return inputString;
    } else {
        return inputString?.substring(0, maxLength) + "... ";
    }
};

export const getUserRoles = () => {
    const sessionToken = getSessionToken();
    const tenantId = getTenantId();
    let roles = [];
    if (sessionToken && tenantId) {
        roles = getJwtRoles(sessionToken, tenantId);
    }
    const containsRole = roles.some((role) => Lms_roles.includes(role));
    if (containsRole) {
        roles.push(USER_ROLE_KEYS.C2B_RETAIL_LMS_RA);
    }
    return roles;
};
export const getFFUserType = () => {
    const userAccessRoles = getUserRoles();
    const pageName = getPageName();
    if (
        userAccessRoles.includes(USER_ROLE_KEYS.FINDER) &&
        [FF_PAGE_TYPES.COMMON, FF_PAGE_TYPES.FINDERS].includes(pageName)
    ) {
        return USER_ROLE_KEYS.FINDER;
    } else if (
        userAccessRoles.includes(USER_ROLE_KEYS.DISPOSE) &&
        [FF_PAGE_TYPES.COMMON, FF_PAGE_TYPES.DISPOSE].includes(pageName)
    ) {
        return USER_ROLE_KEYS.DISPOSE;
    } else if (
        userAccessRoles.includes(USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_VIEW) &&
        FF_PAGE_TYPES.COMMON === pageName
    ) {
        return USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_VIEW;
    } else if (
        userAccessRoles.includes(USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN) &&
        [FF_PAGE_TYPES.FINDERS, FF_PAGE_TYPES.DISPOSE].includes(pageName)
    ) {
        return USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN;
    } else if (
        userAccessRoles.includes(USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_ADMIN) &&
        pageName === FF_PAGE_TYPES.SERVICE_HISTORY
    ) {
        return USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_ADMIN;
    } else if (
        userAccessRoles.includes(USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_AGENT) &&
        pageName === FF_PAGE_TYPES.SERVICE_HISTORY
    ) {
        return USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_AGENT;
    } else if (
        userAccessRoles.includes(USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_VIEW) &&
        pageName === FF_PAGE_TYPES.SERVICE_HISTORY
    ) {
        return USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_VIEW;
    } else if (userAccessRoles.includes(USER_ROLE_KEYS.C2B_OPS_CJ_VERIFY_ADMIN) &&
        [
            FF_PAGE_TYPES.CJ_FOOTPRINT,
        ].includes(pageName)
    ) {
        return USER_ROLE_KEYS.C2B_OPS_CJ_VERIFY_ADMIN;
    } else if (userAccessRoles.includes(USER_ROLE_KEYS.C2B_INSP_ANNOUNCEMENT_ADMIN) && pageName == FF_PAGE_TYPES.INCENTIVE_ADMIN_PAGE) {
        return USER_ROLE_KEYS.C2B_INSP_ANNOUNCEMENT_ADMIN;
    } else if (userAccessRoles.includes(USER_ROLE_KEYS.C2B_INSP_CJ_REFERRAL_ADMIN) && pageName == FF_PAGE_TYPES.INCENTIVE_ADMIN_PAGE) {
        return USER_ROLE_KEYS.C2B_INSP_CJ_REFERRAL_ADMIN;
    } else if (userAccessRoles.includes(USER_ROLE_KEYS.C2B_OPS_QC_CJ_INCENTIVE_ADMIN) && pageName == FF_PAGE_TYPES.INCENTIVE_ADMIN_PAGE) {
        return USER_ROLE_KEYS.C2B_OPS_QC_CJ_INCENTIVE_ADMIN;
    } else if (
        userAccessRoles.includes(USER_ROLE_KEYS.C2B_OPS_FF_VERIFICATION_AGENT) &&
        pageName === FF_PAGE_TYPES.VERIFICATION
    ) {
        return USER_ROLE_KEYS.C2B_OPS_FF_VERIFICATION_AGENT;
    } else if (
        userAccessRoles.includes(USER_ROLE_KEYS.C2B_OPS_FF_ADMIN) &&
        pageName === FF_PAGE_TYPES.ADMINSTRATOR_VIEW
    ) {
        return USER_ROLE_KEYS.C2B_OPS_FF_ADMIN;
    } else if (userAccessRoles.includes(USER_ROLE_KEYS.C2B_FINANCE_SELLER_AGENT) &&
        pageName === FF_PAGE_TYPES.LEADS
    ) {
        return USER_ROLE_KEYS.C2B_FINANCE_SELLER_AGENT
    } else if (userAccessRoles.includes(USER_ROLE_KEYS.C2B_FINANCE_SELLER_ADMIN) &&
        pageName === FF_PAGE_TYPES.LEADS
    ) {
        return USER_ROLE_KEYS.C2B_FINANCE_SELLER_ADMIN
    }
    else if (userAccessRoles.includes(USER_ROLE_KEYS.C2B_RETAIL_VAS_ADMIN)) {
        return USER_ROLE_KEYS.C2B_RETAIL_VAS_ADMIN;
    } else if (
        userAccessRoles.includes(USER_ROLE_KEYS.C2B_OPS_QC_CJ_INCENTIVE_ADMIN) &&
        [
            FF_PAGE_TYPES.INCENTIVE_ADMIN_PAGE,
            FF_PAGE_TYPES.INCENTIVE_PANEL,
        ].includes(pageName)
    ) {
        return USER_ROLE_KEYS.C2B_OPS_QC_CJ_INCENTIVE_ADMIN;
    } else {
        return USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN;
    }
};
export const getPageName = () => {
    return getFilename(window.location.pathname);
};

export const underscoreToTitleCase = (input) => {
    const stringWithSpaces = input.replace(/_/g, " ");

    const titleCaseString = stringWithSpaces?.replace(/\w\S*/g, (match) => {
        return match?.charAt(0)?.toUpperCase() + match?.substr(1)?.toLowerCase();
    });

    return titleCaseString;
};

export const getArrayItemValueByLabel = (arr, label) => {
    return arr?.find((item) => item.label === label)?.value;
};
export const getArrayItemLabelByValue = (arr, value) => {
    return arr?.find((item) => item.value === value)?.label;
};

export const getUserFirstNameFromEmail = (email) => {
    const atIndex = !!email ? email.indexOf("@") : 0;
    if (atIndex > 0) {
        const name = email?.substring(0, atIndex);
        const dotIndex = name.indexOf(".");
        if (dotIndex > 0) {
            return name.substring(0, dotIndex);
        } else {
            return name;
        }
    } else {
        return email;
    }
};

export const isFilterFieldPresent = (arr, field) => {
    if (!arr) return false; // Check if arr is null or undefined

    return arr?.some(
        (obj) =>
            obj?.filters && obj?.filters.some((filter) => filter?.field === field)
    );
};

export const formatDateYYYYMMDDtoDDMMM = (inputDate) => {
    const dateObj = new Date(inputDate);

    const formattedDate = dateObj.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
    });
    return formattedDate;
};

export const isToday = (dateString) => {
    const today = new Date();
    const inputDate = new Date(dateString);

    return (
        today.getFullYear() === inputDate.getFullYear() &&
        today.getMonth() === inputDate.getMonth() &&
        today.getDate() === inputDate.getDate()
    );
};

export const isTomorrow = (dateString) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const inputDate = new Date(dateString);

    return (
        tomorrow.getFullYear() === inputDate.getFullYear() &&
        tomorrow.getMonth() === inputDate.getMonth() &&
        tomorrow.getDate() === inputDate.getDate()
    );
};

export const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
        const context = this;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(context, args), delay);
    };
};
export const setDefaultValueToForm = (formData, data) => {
    const updatedFormData = { ...formData };
    Object.keys(updatedFormData).forEach(key => {
        if (data[key] !== undefined) {
            if (updatedFormData[key].elementtype == FORM_ELEMENT_TYPE.SELECT) {
                const res = updatedFormData[key]?.source?.filter((item) => item.value == data[key])
                updatedFormData[key].value = res?.length ? res[0] : null
            }
            else {
                updatedFormData[key].value = data[key];
            }
        }
    });
    return updatedFormData;
};
export const getFormDataValue = (formData) => {
    const res = {}
    Object.keys(formData).forEach(key => {
        if (formData[key].disabled == undefined || !formData[key].disabled) {
            if (formData[key].elementtype == FORM_ELEMENT_TYPE.SELECT) {
                res[formData[key].name] = formData[key].value?.value
            }
            else {
                res[formData[key].name] = formData[key].value
            }
        }
    });
    return res;
}
export const getDateYearsAgo = (year) => dayjs().subtract(year, "year").toDate();

export const mobileNumberValidator = (mobile) => {
    return VALID_MOBILE_NUMBER.test(parseInt(mobile)) &&
        !INVALID_MOBILE_NUMBER.test(parseInt(mobile));
};

export const nameValidator = (name) => {
    if (!name) {
        return { isValid: false, errorType: NAME_ERROR.FULL_NAME_ERROR };
    }

    name = name.toLowerCase();
    const result = { isValid: true, errorType: "" };
    const specialRegex = /^[\d\w. ]*$/;
    const digitRegex = /\d+/;
    const nameRegex = /^[a-zA-Z]+(?:[.\s][a-zA-Z]+)+(?<![.\s])$/;

    if (!specialRegex.test(name) || digitRegex.test(name)) {
        return { isValid: false, errorType: NAME_ERROR.NAME_FORMAT_ERROR };
    }
    if (!nameRegex.test(name)) {
        return { isValid: false, errorType: NAME_ERROR.FULL_NAME_ERROR };
    }

    return result;
};

export const panValidator = (pan) => {
    const isValid = PAN_REGEX.test(String(pan).toLowerCase());
    return isValid;
};

export const pincodeValidator = (value) => PINCODE_REGEX.test(value);

export const ageValidator = (value) =>
    dayjs(value).isValid()
        ? dayjs(new Date()).diff(value, "y") > 18
            ? ""
            : "Age must be 18+"
        : "Enter valid age";

export const preventNegative = (e) => {
    if (e.code === "Minus") {
        e.preventDefault();
    }
};

export const isLmsPage = () =>{
  const allowedUrls = [
    '/retail/lms',
    '/lms/retargeting',
    '/qc/hypo-qc-panel',
  ];
  const pathname = window.location.pathname;
  return(allowedUrls.some(url => pathname.startsWith(url)))
}

export function daysFromCurrentDate(dateString) {
    const specifiedDate = new Date(dateString);
    const currentDate = new Date();
    const differenceInMillis = currentDate - specifiedDate;

    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const differenceInDays = Math.round(differenceInMillis / millisecondsPerDay);
    return differenceInDays + 1;
}

export function getValuesString(data) {
    if (!Array.isArray(data)) {
        throw new Error('Input must be an array');
    }

    return data.map(item => item.value).join(',');
}

export const yieldToMain = () => {
    return new Promise((resolve) => {
        window.setTimeout(resolve, 0);
    });
};
