import React, { useState } from 'react';
import styles from './style.module.css';
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import useQuery from '../../../../../../hooks/useQuery';
import { GridLayout, GridLayoutItem} from '@progress/kendo-react-layout';
import { KendoCard } from '../../../../../../components';
import { commonApis } from '../../../../../../services/commonApi';
import { PrAutomationService } from '../../../../../../services';
import { useDispatch } from 'react-redux';
import { fetchOrderDetails } from '../../../../../../store/actions/pr-automation';
import { showToastMessage } from '../../../../../../utils/utils';
import Call from '../../../../../../assets/lms/VectorCallIcon.png'
import AddCall from '../../../../../../assets/lms/call-addAddCall.png'
import ExotelDailerContext from '../../../../../../context/exotel-dailer-context';
import { useContext } from 'react';
import useLazyQuery from '../../../../../../hooks/useLazyQuery';

function CustomerInfo(props) {

  const { MMVY, variant, lastInspectionDate, ownerNameOnRc, virtualNumber, appointmentId,secondaryPhoneNumber,isViewAllPr,setIsViewAllPr,toggleApprovals,isLatestPrVisible} = props
  const dispatch = useDispatch();
  const {setShowDailerPopup} = useContext(ExotelDailerContext)
  const [isContactEdit, setIsContactEdit] = useState(false);
  const [updatedSecondaryPhoneNumber, setUpdatedSecondaryPhoneNumber] = useState();
  const { data: raDetails, isLoading: isRaDetailsLoading } = useQuery(commonApis.getRADetails, { appointmentId: appointmentId });
  const { apiCbFunction: sendDiyLink, isLoading: isDiyLinkApiLoading} = useLazyQuery(commonApis.sendConsentGeneration);

  const handleSaveContact = () => {
      const payload = {
          appointmentId: appointmentId,
          secondary_phone: updatedSecondaryPhoneNumber,
      };
      PrAutomationService.updateAppointment("phone", payload).then((res) => {
          if (res && res.status === 200) {
              dispatch(
                  fetchOrderDetails({
                      appointmentId,
                      documentCodes: ["AADHAR_CARD", "PAN_CARD_COPY"],
                  })
              );
              setIsContactEdit(false);
          }
      }).catch((err)=>{
        showToastMessage('Some error occured',false)
      })
  };

  const callClickHandler = () =>{
    setShowDailerPopup(true)
  }

  const sendDiyClickHandler = async () =>{
    if(!isDiyLinkApiLoading){
      const res = await sendDiyLink({
        body:{
          "appointmentId": appointmentId,
          "requestType": "CREDIT_REPORT_CONSENT_REQUEST"
        }
      })
      showToastMessage(res?.message??'Some error Occured',res?.status == 200)
    }
  }

  const getDetails = () => {
    return (
      <GridLayout className={styles['get_more_price_container']}>
        <div className={styles['quote_container']}>
          <div className={styles['quote_container_data']}>
            <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
              Owner Name
            </span>
            <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
              {ownerNameOnRc}
            </span>
          </div>
          <div className={styles['quote_container_data']}>
            <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
              Appointment Id
            </span>
            <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
              {appointmentId}
            </span>
          </div>
          <div className={styles['quote_container_data']}>
            <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
              MM
            </span>
            <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
              {MMVY}
            </span>
          </div>
          <div className={styles['quote_container_data']}>
            <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
              VYF
            </span>
            <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
              {variant}
            </span>
          </div>
        </div>
        <div className={styles['quote_container']}>
          <div className={styles['quote_container_data']}>
            <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
              Appointment Date
            </span>
            <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
              {lastInspectionDate}
            </span>
          </div>
          <div className={styles['quote_container_data']}>
            <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
              Virtual Number
            </span>
            <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
              {
                virtualNumber?
                  <>
                    <span>{virtualNumber}</span>
                    <span><img src={Call} className={styles.call_icon} onClick={callClickHandler}/></span>
                  </>
                :
                  'NA'
              }
            </span>
          </div>
          <div className={styles['quote_container_data']}>
            <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
              Secondary Number
            </span>
            <span className={`k-font-bold ${styles['quote_container_data--secondaryNumber']}`}>
              {
                isContactEdit 
                ? 
                  <>
                    <span className={`k-ml-2 ${styles['secondaryButton--inputSpan']}`}>
                      <Input
                        type={"text"}
                        className={styles['secondaryButton--input']}
                        width={"100px"}
                        id={"secondaryPhoneNumber"}
                        key={"secondaryPhoneNumber"}
                        valid={updatedSecondaryPhoneNumber?.toString()?.length === 10}
                        value={updatedSecondaryPhoneNumber || ""}
                        placeholder="Enter 10 digit phone no."
                        onChange={(e) =>
                          setUpdatedSecondaryPhoneNumber(
                            e.target.value ? parseInt(e.target.value) : null
                          )
                        }
                      />
                    </span>
                    <Button
                      themeColor="success"
                      className={styles['secondaryButton--submit']}
                      icon="success"
                      onClick={handleSaveContact}
                      size={"small"}
                      fillMode={"flat"}
                      disabled={
                        !updatedSecondaryPhoneNumber ||
                        updatedSecondaryPhoneNumber?.toString()?.length !== 10
                      }
                    />
                    <Button
                      className={styles['secondaryButton--cancel']}
                      themeColor="secondary"
                      icon="clear"
                      onClick={() => {
                        setIsContactEdit(false);
                        setUpdatedSecondaryPhoneNumber(secondaryPhoneNumber);
                      }}
                      size={"small"}
                      fillMode={"flat"}
                    />
                  </> 
                : 
                  secondaryPhoneNumber 
                  ? 
                    <>
                      <span className={`k-ml-2 ${styles['quote_container_data--value']}`}>{secondaryPhoneNumber}</span>
                    </>
                  : 
                    <>
                      <Button
                        themeColor="primary"
                        onClick={() => setIsContactEdit(true)}
                        size={"small"}
                        fillMode={"flat"}
                        className={styles['secondaryButton--addNew']}
                      >
                        <span className={styles['secondaryButton--addNew--icon']}><img src={AddCall}/></span>
                        Add
                      </Button>
                    </>
              }
            </span>
          </div>
        </div>
        <div className={`${styles['last']} ${styles['quote_container']}`}>
          <div className={`${styles['quote_container_data']}`}>
            <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
              PA Name
            </span>
            <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
              {raDetails?.detail?.name??'NA'}
            </span>
          </div>
          <div className={styles['quote_container_data']}>
            <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
              PA Number
            </span>
            <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
              {raDetails?.detail?.primaryPhone??'NA'}
            </span>
          </div>
          <div className={styles['quote_container_data']}>
            <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
              DIY Link
            </span>
            <span className={`k-font-bold ${styles['quote_container_data--secondaryNumber']}`}>
              <Button
                themeColor="primary"
                onClick={sendDiyClickHandler}
                size={"small"}
                fillMode={"flat"}
                className={styles['secondaryButton--addNew']}
                disabled={isDiyLinkApiLoading}
              >
                {isDiyLinkApiLoading?'Loading':'Send'}
              </Button>
            </span>
          </div>
        </div>
      </GridLayout>
    )
  }

  return (
    <>
      <KendoCard
        cardStyle={{ background: "#F0F2F5" }}
        headerTitle={'Customer Information'}
        details={getDetails()}
      />
    </>
  );
}

export default CustomerInfo
