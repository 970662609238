export const SCORE_CARD_COLUMN_KEYS = {
  PARAMETER:"parameter",
  SUB_PARAMETER:"subParameter",
  REASON:"reason",
  SCORE:"score",
  IDEALINSIGHT:"idealInsight",
}


export const getColumnHeader = ({}) => {
  return ({
    [SCORE_CARD_COLUMN_KEYS.PARAMETER]: {
      field: SCORE_CARD_COLUMN_KEYS.PARAMETER,
      label: "Action Item",
    },
    [SCORE_CARD_COLUMN_KEYS.SUB_PARAMETER]: {
      field: SCORE_CARD_COLUMN_KEYS.SUB_PARAMETER,
      label: "To-do",
    },
    [SCORE_CARD_COLUMN_KEYS.SCORE]: {
      field: SCORE_CARD_COLUMN_KEYS.SCORE,
      label: "Score",
      width:'75px',
      cell:(props)=>{
        const {dataItem} = props

        return(
          <td>
            <span
              style={{
                border:'1px solid',
                borderRadius:'16px',
                padding:'4px 8px',
                fontSize:'12px',
                ...SCORE_COLOR_STYLES[dataItem?.[SCORE_CARD_COLUMN_KEYS.SCORE]??'1/3']
              }}
            >
              {dataItem?.[SCORE_CARD_COLUMN_KEYS.SCORE]}            
            </span>
          </td>
        )
      }
    },
    [SCORE_CARD_COLUMN_KEYS.REASON]: {
      field: SCORE_CARD_COLUMN_KEYS.REASON,
      label: "Reason",
    },
    [SCORE_CARD_COLUMN_KEYS.IDEALINSIGHT]: {
      field: SCORE_CARD_COLUMN_KEYS.IDEALINSIGHT,
      label: "What you should do?",
    },
  })
}

export const SCORE_COLOR_STYLES = {
  '1/3':{
    backgroundColor:'#FBEAE9',
    borderColor:'#F4C4C1',
    color:'#D92D22',
  },
  '2/3':{
    backgroundColor:'#FFFAED',
    borderColor:'#FFE399',
    color:'#FFB800',
  },
  '3/3':{
    backgroundColor:'#E6F2EC',
    borderColor:'#BADACB',
    color:'#0A7A46',
  },
  '0/3':{
    backgroundColor:'#FBEAE9',
    borderColor:'#F4C4C1',
    color:'#D92D22',
  },
  '-1/3':{
    backgroundColor:'#FBEAE9',
    borderColor:'#F4C4C1',
    color:'#D92D22',
  },
  '-2/3':{
    backgroundColor:'#FBEAE9',
    borderColor:'#F4C4C1',
    color:'#D92D22',
  },
  '-3/3':{
    backgroundColor:'#FBEAE9',
    borderColor:'#F4C4C1',
    color:'#D92D22',
  },
}
