import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React from "react";
import { getChallanItemStatus } from "../../../../utils/utils";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { USER_ROLE_KEYS } from "../../../../utils/constants/menu.constants";
// import { FF_PAGE_TYPES } from "../../../../utils/constants/values.constants";
import { Button } from "@progress/kendo-react-buttons";
const LayoutStatusBanner = (props) => {
  const { status = "", isReopenChallanItem = false } = props;
  const itemStatus = getChallanItemStatus(status);
  return (
    <GridLayout
      gap={{
        cols: 2,
      }}
    >
      <GridLayoutItem col={1} colSpan={10}>
        <span
          className="k-icon k-i-arrow-chevron-left"
          onClick={props.onBackClick}
          style={{
            marginRight: "10px",
            marginBottom: "5px",
            fontSize: "18px",
            color: "#003E72",
            cursor: "pointer",
          }}
        />
        <span
          style={{ color: "#003E72", fontSize: "20px", marginRight: "40px" }}
        >
          {props.label}
        </span>
        <span
          className={itemStatus?.css}
          style={{ minWidth: "10%", maxWidth: "15%" }}
        >
          {itemStatus?.label}
        </span>
        {props?.source === "LeadMon" && (
          <span
            className="leadmon-tag"
            style={{ minWidth: "10%", maxWidth: "15%" }}
          >
            LeadMon
          </span>
        )}
      </GridLayoutItem>
      <GridLayoutItem
        col={12}
        colSpan={4}
        style={{ display: "flex", justifyContent: "end" }}
      >
        {!!props.isSubmitRequired && !props?.isAdmin && (
          <Button themeColor={"primary"} onClick={props?.onSubmitHandler}>
            Submit
          </Button>
        )}
        {isReopenChallanItem && !!props?.isAdmin && (
          <Button themeColor={"primary"} onClick={props?.reOpenItemHander}>
            Reopen
          </Button>
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

export default LayoutStatusBanner;
