import { ALL_PAIS_SORT_BY_FILTER, } from "../common/constants/filters.constants";
import "./all-pais.scss";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { updatePaisFilters } from '../store/actions/pais';
import { useDispatch, useSelector } from 'react-redux';
const AllPAIsFilters = () => {
	const dispatch = useDispatch();
	const { filters } = useSelector((state) => state.pais);


	return (
		<>
			<div className="all-cars-filters">
				<DropDownList
					className="app-dropdown"
					data={ALL_PAIS_SORT_BY_FILTER}
					textField="label"
					value={filters.sortBy}
					onChange={(e) => {
						dispatch(updatePaisFilters({ sortBy: e.value }));
					}}
				/></div>

		</>
	);
};
export default AllPAIsFilters;
