import { useEffect, useState, useCallback } from "react";

export function useMonitorDomChange(selector) {
  const [text, setText] = useState({
    oldText: '',
    newText: '',
    targetedNode: null,
  });

  const handleMutation = useCallback((mutation) => {
    if (mutation.type !== 'characterData') return;

    const oldValue = mutation.oldValue;
    const newValue = mutation.target.nodeValue;
    const labelElement = mutation.target.parentElement?.previousElementSibling;
    const changedLabel = labelElement?.textContent || '';

    const targetedNode = document.getElementById('text-element')?.dataset || null;

    setText({
      oldText: `${changedLabel} ${oldValue}`,
      newText: `${changedLabel} ${newValue}`,
      targetedNode
    });
  }, []);

  useEffect(() => {
    const targetNodes = document.querySelectorAll(selector);

    if (targetNodes.length === 0) {
      console.warn(`No elements found for selector: ${selector}`);
      return;
    }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach(handleMutation);
    });

    const observerOptions = {
      characterDataOldValue: true,
      characterData: true,
      subtree: true,
    };

    targetNodes.forEach((element) => observer.observe(element, observerOptions));

    return () => observer.disconnect();
  }, [selector, handleMutation]);

  return text;
}