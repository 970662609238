import {
  // getLoginUserEmail,
  getFFUserType,
} from "../utils/utils";
export default (api) => {
  const fetchAllRegion = () => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/v1/challan-region`, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const fetchCourtByRegion = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/v1/challan-region/${params}`, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const addRegion = (payload) => {
    // const params = { ...payload, createdBy: getLoginUserEmail() };
    return new Promise((resolve, reject) => {
      api
        .post(`/api/v1/challan-region/add`, payload, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const updateRegion = (payload) => {
    // const params = { ...payload, createdBy: getLoginUserEmail() };
    return new Promise((resolve, reject) => {
      api
        .put(`/api/v1/challan-region/update`, payload, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const deleteRegion = (payload) => {
    // const params = { ...payload, createdBy: getLoginUserEmail() };
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/v1/challan-region/delete/${payload}`, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const addCourt = (payload) => {
    // const params = { ...payload, createdBy: getLoginUserEmail() };
    return new Promise((resolve, reject) => {
      api
        .post(`/api/v1/challan-court/add`, payload, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const updateCourt = (payload) => {
    // const params = { ...payload, createdBy: getLoginUserEmail() };
    return new Promise((resolve, reject) => {
      api
        .put(`/api/v1/challan-court/update`, payload, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteCourt = (payload) => {
    // const params = { ...payload, createdBy: getLoginUserEmail() };
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/v1/challan-court/delete/${payload}`, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const fetchAllDelayReason = () => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/v1/challan-data/delay-reason/all`, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const fetchAllOffenceReason = () => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/v1/challan-data/offences/all`, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const fetchAllUnFulfillReason = () => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/v1/challan-data/unfulfill-reason/all`, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const fetchAllRejectReason = () => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/v1/challan-data/reject-reason/all`, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const fetchAllVerificationRejectReason = () => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/v1/challan-data/verification-reject-reason/all`, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const addDelayReason = (payload) => {
    // const params = { ...payload, createdBy: getLoginUserEmail() };
    return new Promise((resolve, reject) => {
      api
        .post(`/api/v1/challan-data/delay-reason/add`, payload, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const addRejectReason = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/api/v1/challan-data/reject-reason/add`, payload, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const addVerificationRejectReason = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/api/v1/challan-data/verification-reject-reason/add`, payload, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const addUnfulfillReason = (payload) => {
    // const params = { ...payload, createdBy: getLoginUserEmail() };
    return new Promise((resolve, reject) => {
      api
        .post(`/api/v1/challan-data/unfulfill-reason/add`, payload, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteDelayReason = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/v1/challan-data/delay-reason/delete/${payload}`, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteRejectReason = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/v1/challan-data/reject-reason/delete/${payload}`, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteVerificationRejectReason = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .delete(
          `/api/v1/challan-data/verification-reject-reason/delete/${payload}`,
          {
            headers: { "user-type": `${getFFUserType()}` },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteUnfulfillReason = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/v1/challan-data/unfulfill-reason/delete/${payload}`, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteOffenseReason = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/v1/challan-data/offences/delete/${payload}`, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const updateDelayReason = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .put(`/api/v1/challan-data/delay-reason/update`, payload, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const updateRejectReason = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .put(`/api/v1/challan-data/reject-reason/update`, payload, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const updateVerificationRejectReason = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .put(
          `/api/v1/challan-data/verification-reject-reason/update`,
          payload,
          {
            headers: { "user-type": `${getFFUserType()}` },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const updateUnfulfillReason = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .put(`/api/v1/challan-data/unfulfill-reason/update`, payload, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const updateOffenseReason = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .put(`/api/v1/challan-data/offences/update`, payload, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const addOffenceReason = (payload) => {
    // const params = { ...payload, createdBy: getLoginUserEmail() };
    return new Promise((resolve, reject) => {
      api
        .post(`/api/v1/challan-data/offences/add`, payload, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const addAgent = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/api/v1/challan-agent/add`, payload, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const fetchAllAgent = () => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/v1/challan-agent/all`, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const syncUsers = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/api/v1/challan-data/users/add`, payload, {
          headers: { "user-type": `${getFFUserType()}` },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    fetchAllRegion,
    fetchCourtByRegion,
    addRegion,
    updateRegion,
    deleteRegion,
    addCourt,
    updateCourt,
    deleteCourt,
    fetchAllDelayReason,
    fetchAllOffenceReason,
    addDelayReason,
    updateDelayReason,
    deleteDelayReason,
    addOffenceReason,
    addAgent,
    fetchAllAgent,
    fetchAllRejectReason,
    addRejectReason,
    updateRejectReason,
    deleteRejectReason,
    fetchAllUnFulfillReason,
    addUnfulfillReason,
    deleteUnfulfillReason,
    updateUnfulfillReason,
    deleteOffenseReason,
    updateOffenseReason,
    syncUsers,
    fetchAllVerificationRejectReason,
    addVerificationRejectReason,
    deleteVerificationRejectReason,
    updateVerificationRejectReason,
  };
};
