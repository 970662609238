import React from "react";
import DownArrow from "../../assets/img/dn-arrow.svg";
const ExpandCell = (props) => {
  const onClickExpandCell = () => {
    props.onExpandCellChange(props.dataItem);
  };
  return (
    <td className="k-command-cell">
      <div
        className={props.dataItem.expanded ? "icon-expand" : "icon-collapsed"}
      >
        <img alt="" src={DownArrow} onClick={() => onClickExpandCell()} />
      </div>
    </td>
  );
};
export default ExpandCell;
