import React, { useState, useEffect } from 'react';
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { SELECTED_FIELD_KEY, cj_verification_keys, dispose_columns } from './viewModal';
import CustomGrid from '../../../common/custom-grid/component';
import { useDispatch, useSelector } from 'react-redux';
import { convertObjectToParams, showToastMessage } from '../../../utils/utils';
import { getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { fetchCjVerificationList } from '../../../store/actions/vehicle-inspection';
import { VisService } from '../../../services';
import styles from "./styles.module.css";
import "../../operations/assets/style/challan-layout.css";
import { Loader } from '../../../common/Loader';

function VerifyAdmin() {

    const [paginationParams, setPaginationParams] = useState({
        pageNumber: 0,
        pageSize: 12,
    });
    const [selectedState, setSelectedState] = useState({});
    const dispatch = useDispatch();

    const idGetter = getter(cj_verification_keys.CJ_VERIFICATION_ID);

    const getDisplayedColumns = () => {
        const displayColumns = Object.values(dispose_columns).filter((item) => !item.hide);
        return displayColumns.map((item) => item.field);
    };
    const onRowSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: cj_verification_keys.CJ_VERIFICATION_ID,
        });
        setSelectedState(newSelectedState);
    }

    const onHeaderSelectionChange = (event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
            item[SELECTED_FIELD_KEY] = checked;
        });
        setSelectedState(newSelectedState);
    };

    const cjList = useSelector((state) => state.vehicleInspection);

    const fetchCjList = () => {
        let qsParams = convertObjectToParams(paginationParams);
        dispatch(fetchCjVerificationList(qsParams));
    };

    useEffect(() => {
        fetchCjList();
    }, [paginationParams]);

    const getSelectedVerificationRowIDs = (rows, data) => {
        let emailIds = [];
        Object.keys(rows).filter((key) => {
            const state = rows[key];
            const row = data.find((item) => item.verificationId === parseInt(key));
            if (state && row) {
                return emailIds.push(row.email);
            }
        });
        return emailIds;
    };

    const resetVerificationList = () => {
        fetchCjList();
    };

    const verifyData = (isVerify) => {
        const selectedRows = getSelectedVerificationRowIDs(selectedState, cjList?.vehicleInspection?.data);
        if (!!selectedRows.length) {
            VisService.verifyCjList({ emailIds: selectedRows, isVerified: isVerify ? true : false })
                .then((resp) => {
                    showToastMessage(`CJ ${isVerify ? 'Verified' : 'Unverified'} successfully`);
                    resetVerificationList();
                    setSelectedState({});
                })
                .catch((err) => {
                    showToastMessage(err?.message || "Failed", false);
                });
        } else {
            showToastMessage("Please select atleast one row to continue", false);
        }
    };

    const pageChange = ({ page = 0, size = 12 }) => {
        setPaginationParams({ pageNumber: page, pageSize: size });
    };

    return (
        <div className={`${styles.wrapper} ops-grid-layout-container`}>
            <GridLayout
                gap={{
                    rows: "0px",
                    cols: 10,
                }}
            >
                <GridLayoutItem
                    row={1}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div className={styles["listing-header"]}>CJ Footprint Verification</div>
                    <div>
                        <Button
                            className={`m-1 ${styles["assign-button"]}`}
                            themeColor={"primary"}
                            onClick={() => verifyData(true)}>
                            Verify CJs
                        </Button>
                        <Button
                            className={`m-1 ${styles["assign-button"]}`}
                            themeColor={"primary"}
                            onClick={() => verifyData(false)}>
                            Unverify CJs
                        </Button>
                    </div>
                </GridLayoutItem>
                <GridLayoutItem row={3}>
                    <div className="">
                        {!!cjList?.isLoading ? (
                            <Loader />
                        ) : (
                            <CustomGrid
                                displayedColumns={getDisplayedColumns()}
                                columnMap={dispose_columns}
                                data={cjList?.vehicleInspection?.data?.map((item, index) => ({
                                    ...item,
                                    [SELECTED_FIELD_KEY]: selectedState[idGetter(item)],
                                    [cj_verification_keys.CJ_VERIFICATION_ID]: index
                                }))}
                                idField={cj_verification_keys.CJ_VERIFICATION_ID}
                                selectedField={SELECTED_FIELD_KEY}
                                onSelectionChange={onRowSelectionChange}
                                onHeaderSelectionChange={onHeaderSelectionChange}
                                selectable={true}
                                fixedHeight={{ height: "650px" }}
                                isPagination={true}
                                pageChange={pageChange}
                                count={cjList?.vehicleInspection?.totalCount}
                                paginationParams={paginationParams}
                            />
                        )}
                    </div>
                </GridLayoutItem>
            </GridLayout>

        </div>
    )
}

export default VerifyAdmin