import React, { useState, useEffect } from "react";
import {
  challan_item_keys,
  getDisplayedCoulmns,
  StatusCell,
  OffenseDateCell,
  TaskCreationDateCell,
} from "./viewModel";
import CustomGrid from "../../../../common/custom-grid/component";
import { Loader } from "../../../../common/Loader";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ChallanTask from "../create-task";
import { formData } from "../create-task/viewModel";
import { useDispatch } from "react-redux";
import {
  showToastMessage,
  formatDate,
  // getFromLocalStorage,
  getLoginUserEmail,
  getRoundedByTwoDigit,
  getFFUserType,
} from "../../../../utils/utils";
import {
  // LOCAL_STORAGE_KEYS,
  CHALLAN_REQUEST_STATUSES,
  NUMBERS,
  API_HEADER_CONSTANTS,
} from "../../../../utils/constants/values.constants";
import { getOpsChallanServiceMetaData } from "../../../../utils/constants/url.constants";
import { ChallanService } from "../../../../services";
import { updateRowChannelRequest } from "../../../../store/actions/ops-challan";
import { SingleFileUpload } from "../../../../components";
import AddDelay from "./component/add-delay";
import { USER_ROLE_KEYS } from "../../../../utils/constants/menu.constants";
import { getSessionToken } from "@descope/react-sdk";
const ChallanDetail = (props) => {
  const [challanItems, setChallanItems] = useState([]);
  const [openCreateTaskDialog, setOpenCreateTaskDialog] = useState(false);
  const [openUploadProofDialog, setOpenUploadProofDialog] = useState(false);
  const [openDelayDialog, setOpenDelayDialog] = useState(false);
  const [createOrderData, setCreateOrderData] = useState(formData);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionSubmit, setIsActionSubmit] = useState(false);
  const dispatch = useDispatch();
  const apiMetaData = getOpsChallanServiceMetaData();
  const userType = getFFUserType();

  const isEdit =
    props.dataItem?.status.toUpperCase() ===
      CHALLAN_REQUEST_STATUSES.ASSIGNED &&
    userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN;
  const [isChallanItemAvailable, setIsChallanItemAvailable] = useState(false);
  const [currentChallanItem, setCurrentChallanItem] = useState({});
  const [isEditItem, setIsEditItem] = useState(false);
  const openCreateTask = () => {
    setOpenCreateTaskDialog(true);
  };
  const closeCreateTask = () => {
    setCurrentChallanItem({});
    setOpenCreateTaskDialog(false);
  };
  const openUploadDialogProof = () => {
    setOpenUploadProofDialog(!openUploadProofDialog);
  };

  const openAddDelayDialog = () => {
    setOpenDelayDialog(!openDelayDialog);
  };
  const submitCreateTask = () => {
    setIsActionSubmit(true);
    const payload = {
      [challan_item_keys.CHALLAN_REQUEST_ID]:
        props.dataItem[challan_item_keys.CHALLAN_REQUEST_ID],
      [challan_item_keys.APPOINTMENT_ORDER_ID]:
        props.dataItem[challan_item_keys.APPOINTMENT_ORDER_ID],
      [challan_item_keys.REG_NUMBER]:
        props.dataItem[challan_item_keys.REG_NUMBER],
      [challan_item_keys.NOTICE_NUMBER]:
        createOrderData[challan_item_keys.NOTICE_NUMBER]?.value,
      [challan_item_keys.CHALLAN_OFFENSE_ID]:
        createOrderData[challan_item_keys.CHALLAN_NAME]?.value?.value,
      [challan_item_keys.CHALLAN_NAME]:
        createOrderData[challan_item_keys.CHALLAN_NAME]?.value?.label,
      [challan_item_keys.CHALLAN_TYPE]:
        createOrderData[challan_item_keys.CHALLAN_TYPE]?.value?.value,
      [challan_item_keys.OFFENCE_DATE]: formatDate(
        createOrderData[challan_item_keys.OFFENCE_DATE]?.value
      ),
      [challan_item_keys.CHALLAN_COURT_ID]:
        createOrderData[challan_item_keys.CHALLAN_COURT]?.value?.value,
      [challan_item_keys.CHALLAN_COURT]:
        createOrderData[challan_item_keys.CHALLAN_COURT]?.value?.label,
      [challan_item_keys.CHALLAN_AMOUNT]:
        createOrderData[challan_item_keys.CHALLAN_AMOUNT]?.value,
      [challan_item_keys.REMARKS]:
        createOrderData[challan_item_keys.REMARKS]?.value,
    };
    //check validity of existing payload //todo
    if (!payload[challan_item_keys.NOTICE_NUMBER]) {
      showToastMessage("Please select valid Notice Number", false);
      return;
    } else if (!payload[challan_item_keys.CHALLAN_TYPE]) {
      showToastMessage("Please select valid Challan  Mode", false);
      return;
    } else if (!payload[challan_item_keys.CHALLAN_OFFENSE_ID]) {
      showToastMessage("Please select Offense type", false);
      return;
    } else if (!payload[challan_item_keys.OFFENCE_DATE]) {
      showToastMessage("Please select valid Offense Date", false);
      return;
    } else if (!payload[challan_item_keys.CHALLAN_COURT_ID]) {
      showToastMessage("Please select valid Court", false);
      return;
    } else if (!payload[challan_item_keys.CHALLAN_AMOUNT]) {
      showToastMessage("Please select valid amount", false);
      return;
    }
    ChallanService.createChallanItem({
      id: currentChallanItem[challan_item_keys.CHALLAN_ID],
      payload: { ...currentChallanItem, ...payload },
    })
      .then((resp) => {
        setIsChallanItemAvailable(true);
        setCurrentChallanItem(props.dataItem);
        openCreateTask();
        showToastMessage(resp?.message);
        dispatch(updateRowChannelRequest(props.dataItem));
        setIsEditItem(false);
        setIsActionSubmit(false);
        closeCreateTask();
        setCreateOrderData(formData);
      })
      .catch((err) => {
        showToastMessage(err.message, false);
      });
  };
  const onRequestAction = (action, idChallanItem = null) => {
    if (!!props.dataItem?.idChallanRequest && !!props.dataItem?.itemCount) {
      if (action === "resolve") {
        const params = [props.dataItem?.idChallanRequest];
        ChallanService.markAsResolvedRequests(params)
          .then((resp) => {
            showToastMessage(resp?.message);
            dispatch(updateRowChannelRequest(props.dataItem));
          })
          .catch((err) => {
            showToastMessage(err.message, false);
          })
          .finally(() => {
            setIsActionSubmit(false);
          });
      } else if (action === "submitall") {
        ChallanService.submitAllChallans(props.dataItem?.idChallanRequest)
          .then((resp) => {
            showToastMessage(resp?.message);
            dispatch(updateRowChannelRequest(props.dataItem));
          })
          .catch((err) => {
            showToastMessage(err.message, false);
          })
          .finally(() => {
            setIsActionSubmit(false);
          });
      } else if (action === "uploadproof") {
        openUploadDialogProof();
      } else if (action === "submitChallanItem") {
        ChallanService.submitChallanItem(idChallanItem)
          .then((resp) => {
            showToastMessage(resp?.message);
            dispatch(updateRowChannelRequest(props.dataItem));
          })
          .catch((err) => {
            showToastMessage(err.message, false);
          });
      } else if (action === "addDelay") {
        openAddDelayDialog();
      }
    }
  };
  const getUploadProofUrl = () => {
    return `${apiMetaData.url}/api/v1/challan-request/upload/${props.dataItem.idChallanRequest}`;
  };
  const closeUploadProofDialog = () => {
    dispatch(updateRowChannelRequest(props.dataItem));
    setOpenUploadProofDialog(false);
  };
  const onEditChallanItem = (data) => {
    setIsEditItem(true);
    setCurrentChallanItem(data);
    openCreateTask();
  };

  const NoticeNumberCell = (event) => {
    const { idChallanItem, noticeNumber, status } = event.dataItem;
    if (
      ["CREATED", "REJECTED"].includes(status) &&
      userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN
    ) {
      return (
        <td
          className="hyper-link"
          onClick={() => onEditChallanItem(event.dataItem)}
        >
          {noticeNumber}
        </td>
      );
    } else {
      return <td>{noticeNumber}</td>;
    }
  };
  const ChallanAmountCell = (event) => {
    return (
      <td className="td-custom-text">
        {getRoundedByTwoDigit(
          event?.dataItem[challan_item_keys.CHALLAN_AMOUNT]
        )}
      </td>
    );
  };
  const SubmitChallanCell = (event) => {
    const { idChallanItem, status } = event.dataItem || {};
    return (
      <td className="td-custom-text">
        {status === "CREATED" &&
          userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN && (
            <Button
              themeColor={"primary"}
              onClick={() =>
                onRequestAction("submitChallanItem", idChallanItem)
              }
              className="m-2"
            >
              Submit
            </Button>
          )}
      </td>
    );
  };
  const columns = {
    [challan_item_keys.NOTICE_NUMBER]: {
      label: "Notice Number",
      cell: NoticeNumberCell,
    },
    [challan_item_keys.CHALLAN_TYPE]: {
      label: "Challan Mode",
      field: challan_item_keys.CHALLAN_TYPE,
    },
    [challan_item_keys.CHALLAN_NAME]: {
      label: "Offense Type",
      field: challan_item_keys.CHALLAN_NAME,
    },
    [challan_item_keys.OFFENCE_DATE]: {
      label: "Offense Date",
      cell: OffenseDateCell,
    },
    [challan_item_keys.CHALLAN_COURT]: {
      label: "Challan Court",
      field: challan_item_keys.CHALLAN_COURT,
    },
    [challan_item_keys.CREATED_AT]: {
      label: "Task Creation Date",
      cell: TaskCreationDateCell,
    },
    [challan_item_keys.CHALLAN_AMOUNT]: {
      label: "Challan Amount",
      cell: ChallanAmountCell,
    },
    [challan_item_keys.AGEING]: {
      label: "Ageing",
      field: challan_item_keys.AGEING,
    },
    [challan_item_keys.STATUS]: {
      label: "Status",
      width: "120px",
      cell: StatusCell,
    },
    [challan_item_keys.REMARKS]: {
      label: "Remarks",
      width: "120px",
      field: challan_item_keys.REMARKS,
    },
    [challan_item_keys.SUBMIT_CHALLAN]: {
      label: "",
      width: "120px",
      cell: SubmitChallanCell,
    },
  };

  useEffect(() => {
    const itemCount =
      props.dataItem?.channelItems?.data?.count ||
      props.dataItem?.itemCount ||
      0;
    setIsChallanItemAvailable(itemCount > NUMBERS.ZERO);
    setChallanItems(props.dataItem?.channelItems?.data);
  }, [props.dataItem?.channelItems]);
  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : (
        <>
          <CustomGrid
            displayedColumns={getDisplayedCoulmns(columns)}
            data={challanItems}
            columnMap={columns}
            pageChange={() => {}}
            isColumnCustomized={false}
          />
          {isEdit && (
            <div className="add-items-cta">
              <Button
                themeColor={"primary"}
                fillMode="flat"
                onClick={openCreateTask}
                className="m-2"
                disabled={isActionSubmit}
              >
                + Add a Challan
              </Button>
              {isChallanItemAvailable && (
                <>
                  <Button
                    onClick={() => onRequestAction("addDelay")}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base m-2"
                    disabled={isActionSubmit}
                    themeColor={"primary"}
                  >
                    Add Delay
                  </Button>
                  <Button
                    onClick={() => onRequestAction("uploadproof")}
                    className="k-button k-button-md k-button-outline k-button-outline-primary k-rounded-md m-2"
                    disabled={isActionSubmit}
                  >
                    Upload Proof
                  </Button>
                  <Button
                    themeColor={"primary"}
                    onClick={() => onRequestAction("submitall")}
                    className="m-2"
                    disabled={isActionSubmit}
                  >
                    Submit All Challans
                  </Button>
                  <Button
                    onClick={() => onRequestAction("resolve")}
                    className="resolve-ticket k-button k-button-md k-button-solid k-rounded-md m-2"
                    disabled={isActionSubmit}
                  >
                    Resolve Ticket
                  </Button>
                </>
              )}
            </div>
          )}
          {openCreateTaskDialog && (
            <Dialog title={"Add a Task"} onClose={closeCreateTask}>
              <ChallanTask
                item={currentChallanItem}
                data={createOrderData}
                setData={setCreateOrderData}
              />
              <DialogActionsBar>
                <Button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  onClick={submitCreateTask}
                  themeColor={"primary"}
                >
                  Submit
                </Button>
              </DialogActionsBar>
            </Dialog>
          )}
          {openUploadProofDialog && (
            <Dialog title={"Upload Proof"} onClose={openUploadDialogProof}>
              <div>
                <SingleFileUpload
                  id={"key-upload-single-file"}
                  key={"key-upload-single-file"}
                  label={"Upload proof file"}
                  saveMethod="patch"
                  saveUrl={getUploadProofUrl()}
                  saveHeaders={{
                    [API_HEADER_CONSTANTS.X_AUTH_KEY]: getSessionToken(),
                    "user-type": userType,
                    "user-email": getLoginUserEmail(),
                  }}
                />
              </div>
              <DialogActionsBar>
                <Button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  onClick={() => closeUploadProofDialog()}
                  themeColor={"primary"}
                >
                  Close
                </Button>
              </DialogActionsBar>
            </Dialog>
          )}
          {openDelayDialog && (
            <Dialog title={"Delay Reason"} onClose={openAddDelayDialog}>
              <AddDelay
                userGroup={USER_ROLE_KEYS.FINDER}
                challanItems={[props?.dataItem]}
                onClose={openAddDelayDialog}
                setIsRefreshRequired={props.setIsRefreshRequired}
              />
            </Dialog>
          )}
        </>
      )}
    </>
  );
};
export default ChallanDetail;
