import { useRef, useEffect, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { NUMBERS } from "../../utils/constants/values.constants";
import styles from "./styles.module.css";
import { ExcelExport } from "@progress/kendo-react-excel-export";
const CustomGrid = (props) => {
  const _export = useRef(null);
  const ref = useRef(null);
  const { pageNumber, pageSize } = props.paginationParams || {};

  const [pageSizeValue, setPageSizeValue] = useState();
  const isDisplayed = (key) => {
    return props.displayedColumns.includes(key);
  };

  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = event.page.take;
    const skip = event.page.skip;
    const currentPageSize = targetEvent.value || pageSize;
    if (targetEvent.value) {
      setPageSizeValue(currentPageSize);
    }
    props.pageChange({
      page: Math.floor(skip / take),
      size: currentPageSize,
    });
  };
  useEffect(() => {
    if (props.isDataExport) {
      if (_export.current !== null) {
        _export.current.save();
        setTimeout(() => {
          props.onExportDataEnd();
        }, 1000);
      }
    }
  }, [props.isDataExport]);

  return (
    <div className={`gridData ${props.gridClass || ""}`} style={{ ...props.gridStyle }}>
      {props.title && (
          <div className={styles.header}>
              <span>{props.title}</span>
          </div>
          )}
      <Tooltip openDelay={100} position="bottom" anchorElement="target">
        <ExcelExport data={props.data} ref={_export}>
          <Grid
            ref={ref}
            data={props.data}
            skip={pageNumber * pageSize}
            take={pageSize}
            total={props.count}
            pageable={
              props.isPagination && {
                buttonCount: 4,
                pageSizes: props.pageSizes || [10, 50, 75, 100],
                pageSizeValue: pageSizeValue,
              }
            }
            onPageChange={pageChange}
            detail={props.detail}
            onExpandChange={props.onExpandChange}
            expandField={props.expandField}
            dataItemKey={props.idField}
            selectedField={props.selectedField}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: "multiple",
            }}
            onSelectionChange={props.onSelectionChange}
            onHeaderSelectionChange={props.onHeaderSelectionChange}
            filterable={props?.filterable}
            filter={props?.filter}
            onFilterChange={props.onFilterChange}
            sortable={!!props.sortable}
            sort={props?.sortParms}
            onSortChange={props?.onSortChange}
            style={props.count > NUMBERS.FIVE ? props?.fixedHeight : ""}
            rowRender={props?.rowRender}
            className={props?.gridClassName}
          >
            {!!props.selectable && (
              <GridColumn field={props.selectedField} width="50px" />
            )}
            {Object.keys(props.columnMap).map((columnName) => {
              if (!isDisplayed(columnName)) {
                return;
              }
              const column = props.columnMap[columnName];
              if (column["cell"]) {
                return (
                  <GridColumn
                    key={columnName}
                    field={column["field"]}
                    headerCell={column["header"]}
                    cell={column["cell"]}
                    title={column["label"]}
                    width={column["width"]}
                    filterable={!!column?.filterable}
                    {...(!!column?.filterable && { field: column["field"] })}
                    filterCell={column?.filterCell}
                    columnMenu={column?.columnMenu}
                    headerClassName={column?.headerClassName}
                  />
                );
              } else {
                return (
                  <GridColumn
                    key={columnName}
                    field={column["field"]}
                    title={column["label"]}
                    width={column["width"]}
                    filterable={!!column?.filterable}
                    filterCell={column?.filterCell}
                    columnMenu={column?.columnMenu}
                    headerClassName={column?.headerClassName}
                  />
                );
              }
            })}
          </Grid>
        </ExcelExport>
      </Tooltip>
    </div>
  );
};

export default CustomGrid;
// const FILE_VERSION = process.env.REACT_APP_FILE_VERSION;
