const Footer = () => {
  return (
    <footer className="footer">
      <strong>
        Copyright © {new Date().getFullYear()} <a href="https://cars24.com">CARS24</a>.
      </strong>{" "}
      All rights reserved.
    </footer>
  );
}

export default Footer;

