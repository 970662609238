import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ba_column_keys,
  ba_columns,
  getBadgeV2,
  getStatus,
  getStatusMapping,
} from "../../negotiation/pr-automation/viewModel";

const useBaColumns = () => {
  const prDetails = useSelector((state) => state.prAutomation);
  const { businessApprovals } = prDetails || {};
  const [updatedBusinessApproval, setUpdatedBusinessApproval] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(ba_columns);
  const [baTitle, setBaTitle] = useState();
  const getTitle = (updatedBusinessApproval) => {
    let rejectFound = false;
    let pendingFound = false;
    if (updatedBusinessApproval && updatedBusinessApproval.length) {
      for (let i = 0; i < updatedBusinessApproval.length; i++) {
        const item = updatedBusinessApproval[i];
        if (Object.values(item)?.includes("Rejected")) {
          rejectFound = true;
        }
        if (Object.values(item)?.includes("Pending")) {
          pendingFound = true;
        }
      }
    }
    if (rejectFound) {
      return getBadgeV2("Something's not right", "reject");
    } else if (pendingFound) {
      return getBadgeV2("Approval awaited", "pending");
    } else {
      return getBadgeV2("Good to go", "success");
    }
  };
  useEffect(() => {
    setBaTitle(getTitle(updatedBusinessApproval));
  }, [updatedBusinessApproval]);

  useEffect(() => {
    let list = [];
    businessApprovals;
    businessApprovals.forEach((item) => {
      if (item.status) {
        list.push({
          [ba_column_keys.BA_TYPE]: item.issueType,
          [ba_column_keys.SALES_APPROVAL]: getStatus(
            item.salesApprovalApplicable,
            item.salesAllowedPurchase
          ),
          [ba_column_keys.OPS_APPROVAL]: getStatus(
            item.opsApprovalApplicable,
            item.opsAllowedPurchase
          ),
          [ba_column_keys.HEAD_APPROVAL]: getStatus(
            item.cityHeadApprovalApplicable,
            item.cityHeadAllowedPurchase
          ),
        });
      }
    });
    setUpdatedBusinessApproval(list || []);
  }, [businessApprovals]);
  useEffect(() => {
    setVisibleColumns({
      ...ba_columns,
      [ba_column_keys.SALES_APPROVAL]: {
        ...ba_columns[ba_column_keys.SALES_APPROVAL],
        cell: (props) => {
          return (
            <td className="k-table-td">
              {getBadgeV2(
                props?.dataItem[ba_column_keys.SALES_APPROVAL],
                getStatusMapping?.[
                  props?.dataItem[ba_column_keys.SALES_APPROVAL]
                ]
              )}
            </td>
          );
        },
      },
      [ba_column_keys.OPS_APPROVAL]: {
        ...ba_columns[ba_column_keys.OPS_APPROVAL],
        cell: (props) => {
          return (
            <td className="k-table-td">
              {getBadgeV2(
                props?.dataItem[ba_column_keys.OPS_APPROVAL],
                getStatusMapping?.[props?.dataItem[ba_column_keys.OPS_APPROVAL]]
              )}
            </td>
          );
        },
      },
      [ba_column_keys.HEAD_APPROVAL]: {
        ...ba_columns[ba_column_keys.HEAD_APPROVAL],
        cell: (props) => {
          return (
            <td className="k-table-td">
              {getBadgeV2(
                props?.dataItem[ba_column_keys.HEAD_APPROVAL],
                getStatusMapping?.[
                  props?.dataItem[ba_column_keys.HEAD_APPROVAL]
                ]
              )}
            </td>
          );
        },
      },
    });
  }, [ba_columns]);
  return [updatedBusinessApproval, visibleColumns, baTitle];
};
export default useBaColumns;
