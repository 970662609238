import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FORM_ELEMENT_TYPE } from "../../../../utils/constants/values.constants";
import { challan_item_keys } from "../../components/challan-detail-revamp/viewModel";
import { PAYMENT_STATUSES } from "../../components/dispose-challan-item-popup/viewModel";
// import { ChallanService } from "../../../../services";

const renderFinderProofCell = (props) => {
  const linkTo = "";
  return (
    <Link to={linkTo} style={{ color: "#0d6efd" }}>
      URL_Link
    </Link>
  );
};

export const dispose_detail_list_keys = [
  {
    title: "Challan Id",
    field: "challanUniqueId",
  },
  {
    title: "Reg Number",
    field: "regNumber",
  },
  {
    title: "Notice Number",
    field: "noticeNumber",
  },
  {
    title: "Challan Mode",
    field: "type",
  },
  {
    title: "Source",
    field: "sourceCode",
  },
  {
    title: "Region",
    field: "region",
  },
  {
    title: "Challan Court",
    field: "court",
  },
  {
    title: "Challan Amount",
    field: "amount",
  },
  {
    title: "Offence Date",
    field: "offenceDate",
  },
  {
    title: "Offence Type",
    field: "offenceName",
  },
  {
    title: "Remarks",
    field: "remarks",
  },
  {
    title: "Task Date",
    field: "createdAt",
  },
  {
    title: "Closing Date",
    field: "paymentDate",
  },
  {
    title: "Finder Proof",
    field: "challanPdfUrl",
    isCell: true,
    cell: renderFinderProofCell,
  },
  {
    title: "Assignd To",
    field: "assignedTo",
  },
  {
    title: "Government Fee",
    field: "settlementAmount",
  },
  {
    title: "Assigned Agent",
    field: "agent",
  },
  {
    title: "Agent Fee",
    field: "agentFees",
  },
  {
    title: 'Payment Gateway Url',
    field: 'paymentGatewayUrl',
  },
  {
    title: 'Phone Number',
    field: 'phoneNumber'
  }
];
export const dispose_detail_cta_key = {
  ASSIGN_AGENT: "assignAgent",
  CONVERT: "Convert",
  REJECT: "Reject",
};

export const dispose_detail_cta_list = {
  [dispose_detail_cta_key.ASSIGN_AGENT]: {
    title: "Assign Agent",
    handleClick: null,
    hide: false,
  },
  [dispose_detail_cta_key.CONVERT]: {
    title: "Convert",
    handleClick: null,
    hide: false,
  },
  [dispose_detail_cta_key.REJECT]: {
    title: "Reject",
    handleClick: null,
    hide: false,
  },
};

export const INIT_DD_DATA_SOURCES = {
  [challan_item_keys.REGION]: [],
  [challan_item_keys.CHALLAN_COURT]: [],
  [challan_item_keys.AGENT_NAME]: [],
  [challan_item_keys.CHALLAN_TYPE]: [
    { label: "Mark as Paid", value: "PAID" },
    { label: "Unable to Fulfill", value: "UNABLE_TO_FULFILL" },
  ],
  [challan_item_keys.ASSIGN_TO]: [],
  [challan_item_keys.FULFILLMENT_MODE]: [
    { label: 'Settled through call', value: 'settled_through_call' },
    { label: 'Settled through Link', value: 'settled_through_link' },
  ],
};

export const CHALLAN_PAYMENT_FORM_DATA = {
  [challan_item_keys.REG_NUMBER]: {
    name: challan_item_keys.REG_NUMBER,
    label: "Vehicle No.",
    elementtype: FORM_ELEMENT_TYPE.INPUT_RIGHT_TICK,
    maxLength: 12,
    autocomplete: "false",
    value: "",
  },
  [challan_item_keys.NOTICE_NUMBER]: {
    name: challan_item_keys.NOTICE_NUMBER,
    label: "Notice No.",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    maxLength: 12,
    autocomplete: "false",
    value: "",
    hide: true,
  },
  [challan_item_keys.CHALLAN_AMOUNT]: {
    name: challan_item_keys.CHALLAN_AMOUNT,
    label: "Challan Amount",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    disabled: true,
    value: null,
  },
  [challan_item_keys.CHALLAN_TYPE]: {
    name: challan_item_keys.CHALLAN_TYPE,
    label: "Challan Mode",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    disabled: true,
    value: null,
  },
  [challan_item_keys.CHALLAN_TYPE]: {
    name: challan_item_keys.CHALLAN_TYPE,
    label: "Challan Status",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    source: INIT_DD_DATA_SOURCES[challan_item_keys.CHALLAN_TYPE],
    value: null,
    isDisplayLabel: true,
    placeholder: "Select Challan Status",
  },
  [challan_item_keys.AGENT_FESS]: {
    name: challan_item_keys.AGENT_FESS,
    label: "Agent Fees",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    type: "number",
    value: null,
    hide: true,
    placeholder: "Enter Agent Fees",
  },
  [challan_item_keys.SETTLEMENT_AMOUNT]: {
    name: challan_item_keys.SETTLEMENT_AMOUNT,
    label: "Government fee",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    type: "number",
    value: null,
    hide: true,
    placeholder: "Enter Government fee",
  },
  [challan_item_keys.TOTAL_PAYMENT]: {
    name: challan_item_keys.TOTAL_PAYMENT,
    label: "Total Payment",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    type: "number",
    value: null,
    disabled: true,
    hide: true,
    placeholder: "Total Payment",
  },
  [challan_item_keys.FILE_SINGLE_UPLOAD]: {
    name: challan_item_keys.FILE_SINGLE_UPLOAD,
    label: "Upload RTO website screenshot",
    elementtype: FORM_ELEMENT_TYPE.UPLOAD_FILE,
    accept: ".pdf, .png, .jpg",
    value: null,
    hide: true,
    handleFileView: null,
    showFileView: false,
  },
  [challan_item_keys.GOVERNMENT_FEE_PROOF]: {
    name: challan_item_keys.GOVERNMENT_FEE_PROOF,
    label: "Upload Government Fee Receipt",
    elementtype: FORM_ELEMENT_TYPE.UPLOAD_FILE,
    accept: ".pdf, .png, .jpg",
    value: null,
    hide: true,
    handleFileView: null,
    showFileView: false,
  },
  [challan_item_keys.REMARKS]: {
    name: challan_item_keys.REMARKS,
    label: "Reason for not fulfilling",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    value: null,
    hide: true,
    isDisplayLabel: true,
  },
  [challan_item_keys.EXCEPTION_PROOF]: {
    name: challan_item_keys.EXCEPTION_PROOF,
    label: "Exception Proof",
    elementtype: FORM_ELEMENT_TYPE.UPLOAD_FILE,
    maxLength: 200,
    value: null,
    hide: true,
    isCheckedFile: true,
    checkboxValue: false,
    checkboxLabel: "Is this an exception case?",
    handleFileView: null,
    showFileView: false,
  },
  [challan_item_keys.FULFILLMENT_MODE]: {
    name: challan_item_keys.FULFILLMENT_MODE,
    label: 'Fulfillment Mode',
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    hide: true,
    source: INIT_DD_DATA_SOURCES[challan_item_keys.FULFILLMENT_MODE],
    value: null,
    isDisplayLabel: true,
    placeholder: 'Select Fulfillment Mode',
    },
};

export const ASSIGN_AGENT_FORM_DATA = {
  [challan_item_keys.REGION]: {
    name: challan_item_keys.REGION,
    label: "Select Region for Disposal",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    source: [],
    value: null,
    isDisplayLabel: true,
  },
  [challan_item_keys.CHALLAN_COURT]: {
    name: challan_item_keys.CHALLAN_COURT,
    label: "Select Court for Disposal",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    source: [],
    value: null,
    isDisplayLabel: true,
  },
  [challan_item_keys.AGENT_NAME]: {
    name: challan_item_keys.AGENT_NAME,
    label: "Select Agent for Disposal",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    source: [],
    value: null,
    isDisplayLabel: true,
  },
};

export const REJECT_CHALLAN_FORM_DATA = {
  [challan_item_keys.REMARKS]: {
    name: challan_item_keys.REMARKS,
    label: "Reason for rejection",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
  },
  [challan_item_keys.FILE_SINGLE_UPLOAD]: {
    name: challan_item_keys.FILE_SINGLE_UPLOAD,
    label: "Upload proof for rejection",
    elementtype: FORM_ELEMENT_TYPE.UPLOAD_FILE,
    accept: ".pdf, .png, .jpg",
  },
};

export const ADMIN_ASSIGN_AGENT_POPUP = {
  [challan_item_keys.APPOINTMENT_ORDER_ID]: {
    name: challan_item_keys.APPOINTMENT_ORDER_ID,
    label: "Appointment Number",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    disabled: true,
    value: null,
  },
  [challan_item_keys.REG_NUMBER]: {
    name: challan_item_keys.REG_NUMBER,
    label: "Vehicle Reg number",
    elementtype: FORM_ELEMENT_TYPE.INPUT_RIGHT_TICK,
    maxLength: 12,
    disabled: true,
    autocomplete: "false",
    value: "",
  },
  [challan_item_keys.SOURCE_CODE]: {
    name: challan_item_keys.SOURCE_CODE,
    label: "Source",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    disabled: true,
    value: null,
  },
  [challan_item_keys.ASSIGN_TO]: {
    name: challan_item_keys.ASSIGN_TO,
    label: "Select Assign To",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    source: [],
    value: null,
    isDisplayLabel: true,
  },
};

export const CHECKBOX_FILE_VIEW = "fileCheckbox";

export const DISPOSER_ASSIGNED_VALUES = {
  SELF: "SELF",
  EXTERNAL_AGENT: "EXTERNAL"
}

export const DISPOSER_ASSIGNED_DATA = [
  { label: "Self", value: DISPOSER_ASSIGNED_VALUES.SELF },
  { label: "External Agent", value: DISPOSER_ASSIGNED_VALUES.EXTERNAL_AGENT },
]

export const ADMIN_DISPOSER_ASSIGNED_DATA = [
  { label: "C24 Disposer", value: DISPOSER_ASSIGNED_VALUES.SELF },
  { label: "External Agent", value: DISPOSER_ASSIGNED_VALUES.EXTERNAL_AGENT },
]
