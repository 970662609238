import { SERVICE_HISTORY } from "../types";
import { ServiceHistory } from "../../services";
// import { challan_request_keys } from "../../views/operations/challan-list/viewModels";
export const fetchServiceHistoryList =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: SERVICE_HISTORY.FETCH_LOADING_START });
    ServiceHistory.getServiceHistory(params)
      .then((resp) => {
        const {
          detail: { content, numberOfElements, totalElements },
        } = resp || {};
        const data = content.map((item) => {
          const { make, model, variant, year } = item;
          return {
            ...item,
            carDetails: `${make}/${model}/${variant}/${year}`,
          };
        });
        dispatch({
          type: SERVICE_HISTORY.FETCH_SERVICE_LIST_SUCCESS,
          payload: {
            data: data,
            numberOfElements: numberOfElements,
            totalElements: totalElements,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: SERVICE_HISTORY.FETCH_SERVICE_LIST_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: SERVICE_HISTORY.FETCH_LOADING_END });
      });
  };
export const updateAssignmentTypeParams =
  (params = {}) =>
  (dispatch) => {
    dispatch({
      type: SERVICE_HISTORY.SET_ASSIGNMENT_TYPE,
      payload: params,
    });
  };
export const updateServiceHistoryItemRow =
  (params = {}) =>
  (dispatch) => {
    dispatch({
      type: SERVICE_HISTORY.UPDATE_SERVICE_LIST_ITEM,
      payload: params,
    });
  };
export const updateServiceHistoryAssignmentTypeParams =
  (params) => (dispatch) => {
    dispatch({
      type: SERVICE_HISTORY.SET_ASSIGNMENT_TYPE,
      payload: params,
    });
  };
export const setSearchQuery =
  (params = {}) =>
  (dispatch) => {
    dispatch({
      type: SERVICE_HISTORY.SET_SEARCH_QUERY,
      payload: params,
    });
  };

export const setServiceHistorySearchQuery =
  (params = {}) =>
  (dispatch) => {
    dispatch({
      type: SERVICE_HISTORY.SET_SEARCH_QUERY,
      payload: params,
    });
  };

export const updateServiceHistoryFiltersParams =
  (params = {}) =>
  (dispatch) => {
    dispatch({
      type: SERVICE_HISTORY.SET_SERVICEHISTORY_FILTERS,
      payload: params,
    });
  };
export const resetServiceHistoryItems = () => (dispatch) => {
  dispatch({
    type: SERVICE_HISTORY.RESET_SERVICE_LIST_ITEMS,
  });
};
