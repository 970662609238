import { CHALLAN_REQUEST_STATUSES } from "../../../utils/constants/values.constants";
import { challan_request_keys, StatusCell } from "../challan-list/viewModels";
import { formatDateV2 } from "../../../utils/utils";
// import { Link } from "react-router-dom";

export const CreationDateCell = (event) => {
  return (
    <td className="k-table-td">{formatDateV2(event?.dataItem?.createdAt)}</td>
  );
};

// const AppointmentIdLinkCell = (props) => {
// 	const { dataItem } = props || {};
// 	const linkTo = `/operations/challans/finders/${
// 		dataItem[challan_request_keys.REQUEST_ID]
// 	}`;
// 	return (
// 		<td className="k-table-td">
// 			{
// 				<Link
// 					to={linkTo}
// 					style={{ color: "#003E72", textDecoration: "underline" }}>
// 					{dataItem[challan_request_keys.APPOINTMENT_ORDER_ID]}
// 				</Link>
// 			}
// 		</td>
// 	);
// };
export const filter_sources = {
  [challan_request_keys.CHALLAN_SOURCE]: [],
};
export const finder_columns = {
  [challan_request_keys.APPOINTMENT_ORDER_ID]: {
    field: challan_request_keys.APPOINTMENT_ORDER_ID,
    label: "Appt Id/Order Id",
    // cell: AppointmentIdLinkCell,
  },
  [challan_request_keys.REG_NUMBER]: {
    field: challan_request_keys.REG_NUMBER,
    label: "Reg Number",
  },

  [challan_request_keys.CHALLAN_SOURCE]: {
    field: challan_request_keys.CHALLAN_SOURCE,
    label: "Source",
  },
  [challan_request_keys.STATUS]: {
    cell: StatusCell,
    label: "Status",
    field: challan_request_keys.STATUS,
  },
  [challan_request_keys.ASSIGN_TO]: {
    field: challan_request_keys.ASSIGN_TO,
    label: "Assgined Finder",
    hide: true,
  },
  [challan_request_keys.RETAIL_REGION]: {
    field: challan_request_keys.RETAIL_REGION,
    label: "Retail Region",
  },
  [challan_request_keys.ATTEMP_COUNT]: {
    field: challan_request_keys.ATTEMP_COUNT,
    label: "Attempt Count",
  },
  [challan_request_keys.CREATED_AT]: {
    cell: CreationDateCell,
    field: challan_request_keys.CREATED_AT,
    label: "Creation Date",
  },
};

export const DD_DATA_SOURCES = {
  [challan_request_keys.CHALLAN_SOURCE]: [],
  [challan_request_keys.STATUS]: [
    { label: "Assigned", value: CHALLAN_REQUEST_STATUSES.ASSIGNED },
    { label: "Reopened", value: CHALLAN_REQUEST_STATUSES.REOPENED },
    { label: "Resolved", value: CHALLAN_REQUEST_STATUSES.RESOLVED },
    { label: "Unassigned", value: CHALLAN_REQUEST_STATUSES.CREATED },
  ],
  [challan_request_keys.ASSIGN_TO]: [],
};
