import React, { useEffect, useState } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox, TextBox } from "@progress/kendo-react-inputs";

import './app-table-cell.scss';
import { CARD_TYPE, FIELDS_KEY, CAR_DETAILS_TAB_LIST, CONTACT_STATUS } from "../../constants/table.constants";
import AppChips from "../app-chips"
import CarCard from "../car-card";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useDispatch, useSelector } from 'react-redux';
import { getCarDetails, setCarAppointmentId, setCarDetailsTab, setModal, setTpPrice, updateCarsFilters } from '../../../store/actions/allCars';
import { showMarkAsContacted } from '../../../store/actions/myDealers';
import { ALL_CARS_OCB_LIVE_FILTER, MY_DEALERS_CARS_VIEW_FILTER } from '../../constants/filters.constants';
import { trackAnalytics } from '../../services/analytics';
import { CALL_EVENTS, SegmentEvents } from '../../constants/events.constants';
import { getLoginUserEmail } from '../../../../../utils/utils';
import MissedCallIcon from '../../assets/images/missedCallIcon';
import { convertEpochTime, convertEpochToCustomFormat, getCurrentFilter, getUserId, showToastMessage } from '../../utils/utils';
import { hasRADetailsAccess } from '../../utils/viz-roles';
import RupeeIcon from '../../assets/images/rupeeIcon';
import callIcon from '../../assets/images/call-icon.svg'

const AppTableCell = ({
  field,
  dataItem = {},
  onRowClick = () => { },
  isDropdown = true,
  onDropdownChange = () => { },
  dealerId,
  logAnalytic = () => { },
  ...props
}) => {


  const eventData = { dataItem };
  const tooltip = React.useRef(null);
  const dispatch = useDispatch();
  const { ocbLive, dealerCarCategory } = useSelector((state) => state.allCars?.filters);
  const { userInfo } = useSelector((state) => state.allCars);
  const [filter, setFilter] = useState(null);


  const getFilterId = (type) => {
    switch (type) {
      case CARD_TYPE.dealers:
        return dealerCarCategory?.id;
      case CARD_TYPE.cars:
        return ocbLive?.id
      case CARD_TYPE.pais:
        return 'pai';
      default:
        return null;
    }
  };



  useEffect(() => {
    if (ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[3].id) {
      setFilter(getCurrentFilter(dataItem?.appointmentState, dataItem))
    }
  }, [dataItem?.appointmentState])

  const carDetails = (id) => {
    let payload;

    if (getFilterId(props?.type) === ALL_CARS_OCB_LIVE_FILTER[3].id) {
      payload = { appointmentCategory: getCurrentFilter(dataItem?.appointmentState, dataItem), userId: getUserId(userInfo) }
      dispatch(updateCarsFilters({ anyCar: { id: getCurrentFilter(dataItem?.appointmentState, dataItem), label: getCurrentFilter(dataItem?.appointmentState, dataItem) } }))
    }
    else {
      payload = { appointmentCategory: getFilterId(props?.type) || ocbLive?.id, userId: getUserId(userInfo) };
    }

    if (dealerId || (dataItem && dataItem.dealerDetails && dataItem.dealerDetails.dealerId)) {
      payload = { ...payload, dealerId: dealerId || dataItem?.dealerDetails?.dealerId };
    }

    const trackEvent = { ...dataItem, segmentEvent: SegmentEvents.Viz_car_details_page_opened };

    logAnalytic(trackEvent);

    dispatch(setCarAppointmentId(dataItem?.appointmentDetails?.appointmentId))
    dispatch(setCarDetailsTab(id));
    dispatch(getCarDetails(dataItem?.appointmentDetails?.appointmentId, payload))
    dispatch(setModal(true));

  }

  const CarBasicInfo = () => {
    return (
      <>
        <CarCard
          data={{
            ...dataItem?.appointmentDetails,
            ...dataItem?.ocbDetails,
            ...dataItem?.liveAuctionDetails,
            ...dataItem?.paiDetails,
            ...dataItem?.negoDetails,
            ...dataItem?.dealerDetails,
            ...dataItem?.procurementDetails,
            ...dataItem?.additionalInfo,
            carSharedWithDealer: dataItem?.carSharedWithDealer
          } || {}}

          appointmentState={dataItem?.appointmentState}
          dealerId={dealerId}
          dealerType={dealerCarCategory?.id}
          type={props?.type}
          carTag={dataItem?.tag}
          logAnalytic={(data) => logAnalytic({ ...dataItem, segmentEvent: SegmentEvents.Viz_impression, ...data })}
          isGrid={true}
        />
      </>
    );
  };

  const CarHighlights = () => {
    const highlights = dataItem?.carHighlights?.length > 0 ? dataItem?.carHighlights.slice(0, 4) : [];
    return (
      <>
        <div className='app-chips-container app-scrollbar'>
          <AppChips
            data={highlights || []}
          />
        </div>
        <div className='pt-10 view-all cell-view-all-text'>
          {dataItem?.carHighlights?.length > 4 && <span className='cursor-pointer' onClick={() => carDetails(CAR_DETAILS_TAB_LIST[1].id)}>View All</span>}
        </div>
      </>
    );
  };

  const CompanyName = () => {

    const { currentCallEvent, isCallOngoing } = useSelector((state) => state.myDealers);

    const missedCallTime = dataItem?.contactStatus?.missedInboundTimestamps?.[0] || null;


    return (
      <>
        <div className='company-name-container'>
          <div className='company-info'>
            <span className="activity-status">
              {dataItem?.activityStatus?.lastSeen
                ? <>Last Seen {convertEpochTime(dataItem.activityStatus.lastSeen)}</>
                : dataItem?.activityStatus?.isOnline
                  ? (
                    <>
                      <span className='online-status' />
                      &nbsp;
                      Online
                    </>
                  )
                  : null}
            </span>

            <div className='company-container'>
              <div className='company-name-body'>
                <span className='company-name'>{dataItem?.dealerName}</span>

                {missedCallTime && <div className='missed-call-icon-container'>
                  <MissedCallIcon width={16} height={16} />
                  <span className='activity-status'>{convertEpochToCustomFormat(missedCallTime)}</span>
                </div>
                }

              </div>

              {isCallOngoing && currentCallEvent?.dealerId === dataItem?.dealerId ? (
                currentCallEvent?.event === CALL_EVENTS.RECORDING_STARTED ? (
                  <AppChips className='connected-call-chip' data={['Connected']} />
                ) : (
                  <AppChips className='connecting-call-chip' data={['Connecting']} />
                )
              ) : (
                dataItem?.contactStatus?.status === CONTACT_STATUS.CONTACTED ? (
                  <AppChips className='primary-chips' data={['Contacted']} />
                ) : dataItem?.contactStatus?.status === CONTACT_STATUS.ATTEMPTED ? (
                  <AppChips
                    className='connected-call-chip'
                    data={[`Attempted ${dataItem?.contactStatus?.attemptCount > 0 ? `(${dataItem.contactStatus.attemptCount})` : ''}`]}
                  />
                ) : (
                  <AppChips className='missed-call-chip' data={['Not Contacted']} />
                )
              )
              }




            </div>
          </div>

        </div >
      </>
    );
  };



  const DealerInfo = () => {
    return (
      <>
        <div className='secondary-chips'>
          <AppChips
            data={dataItem?.dealerTags || []}
          />
        </div>
      </>
    );
  };




  const ProviderName = () => {
    return (
      <div>
        {dataItem?.unnatiApplicable && <span className="k-icon k-font-icon k-i-check contacted-cell"></span>}
      </div>
    );
  }

  const StatusChip = (props) => {
    return (
      <Chip
        {...props}
        className=""
      />
    );
  }

  const Status = () => {
    return (
      <>
        <div className='secondary-chips'>
          <AppChips
            chip={StatusChip}
            data={dataItem?.negoDetails?.status ? [...dataItem?.negoDetails?.status] : []}
          />
        </div>
      </>
    );
  };


  const Contacted = () => {
    return (
      <>
        <div className=''>
          <a
            onMouseOver={(event) =>
              tooltip.current && tooltip.current.handleMouseOver(event)
            }
            onMouseOut={(event) =>
              tooltip.current && tooltip.current.handleMouseOut(event)
            }
          >
            {dataItem?.contacted && dataItem?.transacting && <div className="k-icon k-font-icon k-i-check contacted-cell"
              title={'Contacted Already'}></div>}
            {!dataItem?.transacting && <div className="k-icon k-font-icon k-i-close non-transacting-cell" title={'Non Transacting'}></div>}

            <Tooltip
              ref={tooltip}
              anchorElement="target"
              position="bottom"
              openDelay={300}
              className={!dataItem?.transacting ? "error-tooltip" : "primary-tooltip"}
            />
          </a>
        </div>
      </>
    );
  };

  const MarkAsContacted = () => {
    const email = getLoginUserEmail();
    return (
      <>
        <div className='flex-center-center'>
          {dataItem?.contacted ? <AppChips
            data={['Contacted']}
          />
            :
            <Checkbox className='app-checkbox' onClick={() => {
              dispatch(showMarkAsContacted({ userId: userInfo?.id, dealerCode: dataItem?.dealerCode }));
              trackAnalytics(SegmentEvents.Viz_clicked_mark_as_contacted, { email_id: email, dealer_id: dealerId || null })
            }
            } />
          }

        </div>
      </>
    )
  }
  const DealerName = () => {

    return (
      <>
        <div className='dealer-info'>
          <span className='dealer-name'>{dataItem?.primaryPersonName || dataItem?.dealerInfo?.dealerName}</span>
          <div className='dealer-type-chip'>
            {dataItem?.dealerType && <div>{dataItem?.dealerType}</div>}
            {dataItem?.unnatiApplicable ? <AppChips className='unnati-applicable-chip' data={['UNNATI']} /> : null}
            {dataItem?.loyaltyApplicable ? <AppChips className='loyalty-applicable-chip' data={['LOYALTY']} /> : null}
            {dataItem?.bucket ? <AppChips className='bucket-applicable-chip' data={[dataItem.bucket]} /> : null}
          </div>
        </div>
      </>
    );
  };


  const DealerCode = () => {
    return (
      <>
        <div className='dealer-info'>
          <span>{dataItem?.dealerCode || dataItem?.dealerInfo?.dealerCode}</span>
        </div>
      </>
    )
  }


  const RecommondedDealers = () => {

    return (
      <>
        <div className='recommonded-dealer-cell'>
          {dataItem?.recoDealers?.slice(0, 4).map((ele) => (
            <div className='option' key={ele.dealerId}>
              <span className='reco-dealer-name'>
                {ele?.dealerName}
              </span>
              {ele?.contactNumbers ? <span
                className="k-icon k-font-icon k-i-information"
                title={`Contact Details: 
                ${ele?.contactNumbers[0]?.number ? `${ele.contactNumbers[0].number}` : ''}
                ${ele?.contactNumbers[1]?.number ? `${ele.contactNumbers[1].number}` : ''}`}
              /> : null}

            </div>
          ))}
          <div className='pt-10 view-all cell-view-all-text'>
            {dataItem?.recoDealers?.length > 4 && <div className='cursor-pointer' onClick={() => carDetails(CAR_DETAILS_TAB_LIST[0].id)}>View All</div>}
          </div>
        </div>

      </>
    );
  };

  const Action = () => {

    return (
      <>
        <div className='action-cell'>
          <Button
            className='app-primary-btn'
            themeColor={"primary"}
            fillMode={'solid'}
            onClick={() => { carDetails(CAR_DETAILS_TAB_LIST[1].id) }}
          >
            RFC Details
          </Button>
          <Button
            className='app-primary-btn'
            themeColor={"primary"}
            fillMode={'solid'}
            onClick={() => { carDetails(CAR_DETAILS_TAB_LIST[2].id) }}
          >
            Car Details
          </Button>

          {
            props?.type === CARD_TYPE.cars &&
              (
                (ocbLive?.id === ALL_CARS_OCB_LIVE_FILTER[3]?.id &&
                  ![ALL_CARS_OCB_LIVE_FILTER[2]?.id, ALL_CARS_OCB_LIVE_FILTER[4]?.id].includes(filter)) ||
                (ocbLive?.id !== ALL_CARS_OCB_LIVE_FILTER[3]?.id &&
                  ![ALL_CARS_OCB_LIVE_FILTER[2]?.id, ALL_CARS_OCB_LIVE_FILTER[4]?.id].includes(ocbLive?.id))
              ) ||
              props?.type === CARD_TYPE.dealers &&
              ![MY_DEALERS_CARS_VIEW_FILTER[2]?.id, MY_DEALERS_CARS_VIEW_FILTER[3]?.id].includes(dealerCarCategory?.id) ? (
              <Button
                className="app-primary-btn"
                themeColor={"primary"}
                fillMode={"solid"}
                onClick={() => { carDetails(CAR_DETAILS_TAB_LIST[3].id) }}
              >
                Bids
              </Button>
            ) : null
          }

          {(props?.type === CARD_TYPE.dealers || props?.type === CARD_TYPE.cars) && hasRADetailsAccess(userInfo?.type) ?
            <Button
              className='app-primary-btn'
              themeColor={"primary"}
              fillMode={'solid'}
              onClick={() => {
                carDetails(CAR_DETAILS_TAB_LIST[4].id)
              }}
            >
              Unlock RA Details
            </Button>
            :
            null}

        </div>
      </>
    );
  };

  const TargetPrice = () => {

    const [value, setValue] = useState();
    const [priceSettled, setPriceSettled] = useState([]);

    const handleTpPrice = () => {
      const { appointmentId } = dataItem?.appointmentDetails;

      dispatch(setTpPrice({ appointmentId, userId: userInfo?.id, targetPrice: value })).then((res) => {
        setPriceSettled(res.appointmentId)
      }).catch(() => {
        showToastMessage('Failed to set target price', false, 1500)
      })

    }

    const handleKeyDown = (e) => {
      if (e.key === '-') {
        e.preventDefault();
      }
    };



    return (
      <div className='target-price-container'>
        {!priceSettled.includes(dataItem?.appointmentDetails?.appointmentId) ?
          <>
            <div className='tp-header'>
              <span className='tp-text'>Enter Target Price</span>
              <TextBox
                style={value && value == 0 ? { borderColor: 'red' } : {}}
                placeholder='Enter Target Price'
                value={value}
                type='number'
                prefix={
                  () => <RupeeIcon width={10} height={11} className="rupee-icon" />
                }
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <Button disabled={isNaN(value) || value <= 0} className='tp-submit' onClick={handleTpPrice}>Submit</Button>
          </>
          : <span className='tp-text'>Target price set successfully.</span>}
      </div>
    )
  }

  return (
    <React.Fragment>
      <td className="k-table-td relative" onClick={() => onRowClick(eventData)}>
        {
          {
            [FIELDS_KEY.CAR_BASIC_INFO]: <CarBasicInfo />,
            [FIELDS_KEY.CAR_HIGHLIGHTS]: <CarHighlights />,
            [FIELDS_KEY.RECOMMENDED_DEALERS]: <RecommondedDealers />,
            [FIELDS_KEY.ACTION]: <Action />,
            [FIELDS_KEY.DealersInfo]: <DealerInfo />,
            [FIELDS_KEY.DealersCode]: <DealerCode />,
            [FIELDS_KEY.CompanyName]: <CompanyName />,
            [FIELDS_KEY.Status]: <Status />,
            [FIELDS_KEY.Provider]: <ProviderName />,
            [FIELDS_KEY.Contacted]: <Contacted />,
            [FIELDS_KEY.MarkAsContacted]: <MarkAsContacted />,
            [FIELDS_KEY.DealerName]: <DealerName />,
            [FIELDS_KEY.SetTargetPrice]: <TargetPrice />,
          }[field]
        }
      </td>
    </React.Fragment>
  );
};
export default AppTableCell;
