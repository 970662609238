import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { createRef, useState, useEffect } from "react";
import {
	FormInput,
	FormSwitch,
	KendoCard,
	FormComboBox,
	FormMultiSelect,
} from "../../../../../../components";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import style from "../../../../assets/style/inspection.module.css";
import FormulaBuilder from "../forumula-builder";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { Label } from "@progress/kendo-react-labels";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import { today, tomorrow } from "../../viewModal";
import { CjIncentivesService } from "../../../../../../services";
import {
	cj_incentive_keys,
	INCENTIVE_TYPE_KEYS,
	INCENTIVE_TYPE_SOURCES,
	NUDGE_ICON_TYPES,
	NUDGE_ICON_SOURCES,
	getImagepath,
} from "../../../view-modal";
import { showToastMessage, debounce } from "../../../../../../utils/utils";
import { replaceAndOr } from "../../../../utils/helper";
const headerStyle = {
	color: "#003E72",
	fontWeight: "500",
	fontSize: "14px",
	background: "#FFFF",
};
const {
	Bold,
	Italic,
	Underline,
	AlignLeft,
	AlignRight,
	AlignCenter,
	OrderedList,
	UnorderedList,
} = EditorTools;

const IncentiveInfo = (props = {}) => {
	const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] =
		useState(false);
	const {
		isNewIncentive = false,
		data,
		updateData,
		clusters,
		resetIncentiveDetail,
		systemMetrices = [],
		setIsIncentiveCreatePopupOpen,
		setRefresh,
		onBackHandler,
		setClusterParam,
		setClusters
	} = props;
	const onIncentiveNameChanged = (event) => {
		const { value = "" } = event?.target;
		if (isNewIncentive && !!value) {
			const payload = {
				incentiveName: value,
			};
			CjIncentivesService.addIncentive(payload)
				.then((resp) => {
					props.updateData(resp?.detail);
					setIsIncentiveCreatePopupOpen(false);
					setRefresh(true);
				})
				.catch((err) => {
					showToastMessage(
						err?.message || err || "Failed to create incentive",
						false
					);
				});
		}
	};

	const onInputChanged = (event) => {
		const {
			target: {
				value,
				props: { id: key },
			},
		} = event;
		let updatedKeys = { [key]: value };
		if (key === cj_incentive_keys.IS_RANGE) {
			if (value) {
				updatedKeys = {
					...updatedKeys,
					[cj_incentive_keys.IS_RANGE]: true,
					[cj_incentive_keys.VALIDITY_START_DATE]: today,
					[cj_incentive_keys.VALIDITY_END_DATE]: tomorrow,
					[cj_incentive_keys.VALID_RANGE]: { start: today, end: tomorrow },
				};
			} else {
				updatedKeys = {
					...updatedKeys,
					[cj_incentive_keys.IS_RANGE]: false,
					[cj_incentive_keys.VALID_RANGE]: {},
					[cj_incentive_keys.VALIDITY_START_DATE]: null,
					[cj_incentive_keys.VALIDITY_END_DATE]: null,
				};
			}
		} else if (key === cj_incentive_keys.STATUS) {
			updatedKeys = {
				[key]: value ? "active" : "inactive",
			};
		} else if (key === cj_incentive_keys.TYPE) {
			updatedKeys = {
				[key]: value?.value,
			};
		} else if (key === cj_incentive_keys.CLUSTER) {
			setClusters((clusters) => ({ ...clusters, filteredClusters: [] }));
		}
		updateData((prev) => ({ ...prev, ...updatedKeys }));
	};

	const handleEditorChange = (event, key) => {
		const { html, value: { content: { size = 0 } = {} } = {} } = event;
		if (key === cj_incentive_keys.DESCRIPTION && size < 350) {
			updateData((prev) => ({
				...prev,
				[cj_incentive_keys.DESCRIPTION]: html,
			}));
		} else if (key === cj_incentive_keys.NUDGE_DESCRIPTION && size < 50) {
			updateData((prev) => ({
				...prev,
				[cj_incentive_keys.NUDGE_DESCRIPTION]: html,
			}));
		}
	};

	const onIncentiveValidityRange = (event) => {
		const { value } = event?.target;
		updateData((prev) => ({
			...prev,
			[cj_incentive_keys.VALIDITY_START_DATE]: value?.start,
			[cj_incentive_keys.VALIDITY_END_DATE]: value?.end,
			[cj_incentive_keys.VALID_RANGE]: value,
		}));
	};

	const onUpdateIncentive = (event) => {
		let _params = { ...data };
		if (_params[cj_incentive_keys.NUDGE_ICON] !== null && typeof _params[cj_incentive_keys.NUDGE_ICON] === "object") {
			_params[cj_incentive_keys.NUDGE_ICON] = _params[cj_incentive_keys.NUDGE_ICON].value 
		}
		delete _params[cj_incentive_keys.VALID_RANGE];
		delete _params[cj_incentive_keys.IS_RANGE];
		if (
			!!_params?.[cj_incentive_keys.NUDGE_ICON] &&
			!_params?.[cj_incentive_keys.NUDGE_DESCRIPTION]
		) {
			showToastMessage(
				"Incentive Nudge Description should not be empty",
				false
			);
			return;
		} else if (
			!!_params?.[cj_incentive_keys.NUDGE_DESCRIPTION] &&
			!_params?.[cj_incentive_keys.NUDGE_ICON]
		) {
			showToastMessage("Incentive Nudge Icon should not be empty", false);
			return;
		}
		const _clusters = _params[cj_incentive_keys.CLUSTER]?.map(
			(item) => item?.[cj_incentive_keys.ID_CLUSTER]
		);
		const _conditions = _params[cj_incentive_keys.CONDITIONS]?.map((item) => {
			const _cond = replaceAndOr(
				item?.[cj_incentive_keys.FORMULA_EXP]?.value || ""
			);
			const _value = replaceAndOr(
				item?.[cj_incentive_keys.FORMULA_RESULT]?.value || ""
			);
			return {
				[cj_incentive_keys.ID_INCENTIVE_CONDITION]:
					item?.[cj_incentive_keys.ID_INCENTIVE_CONDITION],
				condition: _cond,
				value: _value,
				targetValuePresent:
					_cond?.indexOf("target_") !== -1 || _value?.indexOf("target_") !== -1,
			};
		});

		_params = {
			..._params,
			[cj_incentive_keys.CONDITIONS]: _conditions,
			[cj_incentive_keys.CLUSTER]: _clusters,
		};

		CjIncentivesService.updateIncentive(_params[cj_incentive_keys.ID], _params)
			.then((resp) => {
				showToastMessage(resp?.message || "Incentive is updated successfully");
				onBackHandler()
			})
			.catch((err) => {
				console.log({ err });
				showToastMessage(err?.message || "Failed to update", false);
			});
	};

	const itemRender = (li, itemProps) => {
		const { dataItem, index } = itemProps;
		const itemChildren = (
			<>
				<img src={getImagepath(dataItem.value)} alt={dataItem.value} />
				{li.props.children}
			</>
		);
		return React.cloneElement(li, li.props, itemChildren);
	};

	const onFilterChange = (e) => {
		const { filter } = e
		if(filter?.value?.length > 2) {
			setClusterParam(filter?.value)
		}
	}

	const getClusterList = () => {
		if(clusters?.filteredClusters?.length > 0) {
			return clusters?.filteredClusters
		}
		return clusters?.initialClusters || []
	}
	return (
		<>
			<GridLayout
				cols={[
					{
						width: "50%",
					},
					{
						width: "50%",
					},
				]}
				gap={{
					rows: "10px",
					cols: "10px",
				}}>
				<GridLayoutItem row={1} col={1} style={{ width: "98%" }}>
					<FormInput
						id={cj_incentive_keys.NAME}
						key={cj_incentive_keys.NAME}
						onChange={onInputChanged}
						onBlur={onIncentiveNameChanged}
						value={data?.[cj_incentive_keys.NAME]}
						label={"Incentive Name (30 Characters)"}
					/>
				</GridLayoutItem>
				<GridLayoutItem row={1} col={2} style={{ width: "98%" }}>
					<FormComboBox
						id={cj_incentive_keys.TYPE}
						key={cj_incentive_keys.TYPE}
						textField="label"
						dataItemKey="value"
						data={INCENTIVE_TYPE_SOURCES}
						onChange={onInputChanged}
						value={INCENTIVE_TYPE_SOURCES.find(
							(item) => item.value === data?.[cj_incentive_keys.TYPE]
						)}
						label={"Incentive Type"}
						isDisplayLabel={true}
					/>
				</GridLayoutItem>
				<GridLayoutItem row={2} col={1} style={{ width: "98%" }}>
					<FormComboBox
						id={cj_incentive_keys.NUDGE_ICON}
						key={cj_incentive_keys.NUDGE_ICON}
						data={NUDGE_ICON_SOURCES}
						textField="label"
						dataItemKey="value"
						onChange={onInputChanged}
						itemRender={itemRender}
						value={NUDGE_ICON_SOURCES.find(
							(item) => item.value === data?.[cj_incentive_keys.NUDGE_ICON]
						)}
						label={"Incentive Nudge"}
						isDisplayLabel={true}
					/>
				</GridLayoutItem>
				<GridLayoutItem row={2} col={2} style={{ width: "98%" }}>
					<FormMultiSelect
						id={cj_incentive_keys.CLUSTER}
						key={cj_incentive_keys.CLUSTER}
						textField="name"
						dataItemKey="idCluster"
						filterable={true}
						data={getClusterList().map((item) => {
							return { name: item.name, idCluster: item.idCluster };
						})}
						onChange={onInputChanged}
						onFilterChange={debounce(onFilterChange)}
						defaultValue={data?.[cj_incentive_keys.CLUSTER]}
						label={"Cluster"}
						isDisplayLabel={true}
					/>
				</GridLayoutItem>
				<GridLayoutItem row={3} col={1} style={{ width: "98%" }}>
					<Label>{"Incentive Nudge Description (50 Characters)"}</Label>
					<Editor
						id={cj_incentive_keys.NUDGE_DESCRIPTION}
						key={cj_incentive_keys.NUDGE_DESCRIPTION}
						tools={[
							[
								Bold,
								Italic,
								Underline,
								AlignLeft,
								AlignRight,
								AlignCenter,
								OrderedList,
								UnorderedList,
							],
						]}
						value={
							data?.[cj_incentive_keys.NUDGE_DESCRIPTION] || "[var] [threshold]"
						}
						onChange={(e) =>
							handleEditorChange(e, cj_incentive_keys.NUDGE_DESCRIPTION)
						}
						contentStyle={{
							height: 100,
						}}
					/>
				</GridLayoutItem>
				<GridLayoutItem row={3} col={2} style={{ width: "98%" }}>
					<Label>{"Incentive Description (350 Characters)"}</Label>
					<Editor
						id={cj_incentive_keys.DESCRIPTION}
						key={cj_incentive_keys.DESCRIPTION}
						tools={[
							[
								Bold,
								Italic,
								Underline,
								AlignLeft,
								AlignRight,
								AlignCenter,
								OrderedList,
								UnorderedList,
							],
						]}
						value={data?.[cj_incentive_keys.DESCRIPTION] || ""}
						onChange={(e) =>
							handleEditorChange(e, cj_incentive_keys.DESCRIPTION)
						}
						contentStyle={{
							height: 100,
						}}
					/>
				</GridLayoutItem>
				<GridLayoutItem row={4} col={1} style={{ width: "98%" }}>
					<div className={style.rangeValidityContainer}>
						<Label>{"Validity (Select range if applicable)"}</Label>
						<div style={{ display: "flex", alignItems: "baseline" }}>
							<span style={{ marginRight: "10px" }}>Always</span>
							<FormSwitch
								size={"small"}
								onLabel={""}
								offLabel={""}
								id={cj_incentive_keys.IS_RANGE}
								key={cj_incentive_keys.IS_RANGE}
								defaultChecked={!!data?.[cj_incentive_keys.VALIDITY_START_DATE] && !!data?.[cj_incentive_keys.VALIDITY_END_DATE]}
								onChange={onInputChanged}
							/>
							<span style={{ marginLeft: "10px" }}>Range</span>
						</div>
					</div>
					{!!data?.[cj_incentive_keys.IS_RANGE] && (
						<DateRangePicker
							onChange={onIncentiveValidityRange}
							defaultValue={data?.[cj_incentive_keys.VALID_RANGE]}
						/>
					)}
				</GridLayoutItem>
				<GridLayoutItem row={4} col={2} style={{ width: "98%" }}>
					<div className={style.validityContainer}>
						<div className={style.radiogroup}>
							<FormSwitch
								id={cj_incentive_keys.STATUS}
								key={cj_incentive_keys.STATUS}
								size={"small"}
								onLabel={""}
								offLabel={""}
								onChange={onInputChanged}
								defaultChecked={data?.[cj_incentive_keys.STATUS] === "active"}
								label={"Should incentive be Active"}
							/>
						</div>
						<div className={style.radiogroup}>
							<FormSwitch
								id={cj_incentive_keys.ALERT}
								key={cj_incentive_keys.ALERT}
								label={"Publish Alert to CJ App"}
								size={"small"}
								onLabel={""}
								offLabel={""}
								defaultChecked={!!data?.[cj_incentive_keys.ALERT]}
								onChange={onInputChanged}
							/>
						</div>
					</div>
				</GridLayoutItem>
				{data?.[cj_incentive_keys.ID] && (
					<GridLayoutItem row={5} col={2} style={{ width: "98%" }}>
						<div className={style.deleteformulaBtn}>
							<Button
								themeColor={"primary"}
								fillMode={"outline"}
								onClick={() => setIsDeleteConfirmationPopupOpen(true)}>
								Delete Incentive
							</Button>
						</div>
					</GridLayoutItem>
				)}
			</GridLayout>
			<GridLayout
				gap={{
					rows: "30px",
					cols: "10px",
				}}>
				<GridLayoutItem>
					<KendoCard
						headerTitle="Condition Section"
						cardStyle={{
							background: "#EBF5FF",
						}}
						headerStyle={headerStyle}
						details={
							<FormulaBuilder
								data={data}
								updateData={updateData}
								systemMetrices={systemMetrices}
							/>
						}
					/>
				</GridLayoutItem>
				<GridLayoutItem>
					<div className={style.formulaBtn}>
						<Button
							className="mx-2"
							themeColor={"primary"}
							fillMode={"outline"}
							onClick={resetIncentiveDetail}>
							Reset
						</Button>
						<Button
							className="mx-2"
							themeColor={"primary"}
							onClick={onUpdateIncentive}>
							Confirm
						</Button>
					</div>
				</GridLayoutItem>
			</GridLayout>
			{isDeleteConfirmationPopupOpen && (
				<Dialog
					title="Delete Incentive"
					width={"20%"}
					contentStyle={{ width: "auto" }}
					onClose={() => setIsDeleteConfirmationPopupOpen(false)}>
					Are you sure you want to <strong>Delete the incentive?</strong>
					<DialogActionsBar>
						<Button
							className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
							onClick={() => setIsDeleteConfirmationPopupOpen(false)}>
							No
						</Button>
						<Button
							className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
							onClick={() => setIsDeleteConfirmationPopupOpen(false)}
							themeColor={"primary"}>
							Yes
						</Button>
					</DialogActionsBar>
				</Dialog>
			)}
		</>
	);
};
export default IncentiveInfo;
