export const CONDITION_SECTION_ACCORDIAN = {
	SYSTEM_METRICS: "System Metrics",
	ARITHMETIC_OPERATORS: "Arithmetic Operators",
	TARGETS: "Targets",
};
export const ARTHEMETIC_OPERATORS = {
	PLUS: "+",
	MINUS: "-",
	MULTIPLY: "*",
	DIVIDE: "/",
	LEFT_PARANTHESIS: "(",
	RIGHT_PARANTHESIS: ")",
	EQUAL: "=",
	LESS_THEN: "<",
	GREATER_THEN: ">",
	AND: "And",
	OR: "Or",
	LESS_THAN_EQUAL: "<=",
	GREATER_THAN_EQUAL: ">=",
};

export const SYSTEM_METRICS = {
	DISTANCE: "Distance",
	NO_OF_INSPECTION: "No_of_inspection",
	NO_OF_INSPECTION_DAYS: "No_of_inspection_Days",
	NO_OF_INSPECTION_MONTH: "No_of_inspection_month",
	NO_OF_STOCKINS: "No_of_stockins",
};

// export const ARTHEMETIC_OPERATORS = [
// 	{
// 		label: ARTHEMETIC_OPERATORS_VALUES.PLUS,
// 		value: ARTHEMETIC_OPERATORS_VALUES.PLUS,
// 	},
// 	{
// 		label: ARTHEMETIC_OPERATORS_VALUES.MINUS,
// 		value: ARTHEMETIC_OPERATORS_VALUES.MINUS,
// 	},
// 	{
// 		label: ARTHEMETIC_OPERATORS_VALUES.MULTIPLY,
// 		value: ARTHEMETIC_OPERATORS_VALUES.MULTIPLY,
// 	},
// 	{
// 		label: ARTHEMETIC_OPERATORS_VALUES.DIVIDE,
// 		value: ARTHEMETIC_OPERATORS_VALUES.DIVIDE,
// 	},
// 	{
// 		label: ARTHEMETIC_OPERATORS_VALUES.LEFT_PARANTHESIS,
// 		value: ARTHEMETIC_OPERATORS_VALUES.LEFT_PARANTHESIS,
// 	},
// 	{
// 		label: ARTHEMETIC_OPERATORS_VALUES.RIGHT_PARANTHESIS,
// 		value: ARTHEMETIC_OPERATORS_VALUES.RIGHT_PARANTHESIS,
// 	},
// 	{
// 		label: ARTHEMETIC_OPERATORS_VALUES.EQUAL,
// 		value: ARTHEMETIC_OPERATORS_VALUES.EQUAL,
// 	},
// 	{
// 		label: ARTHEMETIC_OPERATORS_VALUES.LESS_THEN,
// 		value: ARTHEMETIC_OPERATORS_VALUES.LESS_THEN,
// 	},
// 	{
// 		label: ARTHEMETIC_OPERATORS_VALUES.GREATER_THEN,
// 		value: ARTHEMETIC_OPERATORS_VALUES.GREATER_THEN,
// 	},
// 	{
// 		label: ARTHEMETIC_OPERATORS_VALUES.AND,
// 		value: ARTHEMETIC_OPERATORS_VALUES.AND,
// 	},
// 	{
// 		label: ARTHEMETIC_OPERATORS_VALUES.OR,
// 		value: ARTHEMETIC_OPERATORS_VALUES.OR,
// 	},
// ];
