import {
	ExpansionPanel,
	ExpansionPanelContent,
	GridLayout,
	GridLayoutItem,
} from "@progress/kendo-react-layout";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { KendoCard } from "../../../../../../components";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import titleBar from "../../shared/title-bar";
import styles from "../../../negotiation/assets/styles/pr.styles.module.css";
import {
	NUMBERS,
	getTransactionStatusMapping,
	transactionStatuses,
} from "../../../../../../utils/constants/values.constants";
import { PrAutomationService } from "../../../../../../services";
import { PR_AUTOMATION } from "../../../../../../store/types";
import {
  downloadDocument,
  fetchDocsStatus,
} from "../../../../../../store/actions/pr-automation";
import { showToastMessage } from "../../../../../../utils/utils";
import { getStaticText } from "../../../negotiation/pr-automation/viewModel";
import { Icon } from "@progress/kendo-react-common";
import { isPrInitialized } from "../viewModel";

const BankDetails = (props) => {
  const { toggleMarkReasons } = props || {};
  const dispatch = useDispatch();
  const [showBankDetails, setShowBankDetails] = useState(false);
  const prDetails = useSelector((state) => state.prAutomation);
  const {
    docLoading,
    orderData: {
      pennyDropTxn: { transactionStatus } = {},
      primaryBankDetails = {},
      purchase: { purchaseStatus = "" } = {},
    } = {},
  } = prDetails || {};
  const {
    accountNameFromBank = "",
    accountNumber = "",
    ifscCode = "",
    mismatchReason = "",
  } = primaryBankDetails || {};
  const lms = useSelector((state) => state.lms);
  const { appointmentId } = lms || {};
  const getStatusText = () => {
    let text = "";
    let css = "";
    switch (transactionStatus) {
      case NUMBERS.TWO:
      case NUMBERS.FOUR:
        if (mismatchReason === "FRIEND_FAMILY_ACCOUNT") {
          text = getStaticText.fullyMismatchFriendFamily;
        } else if (mismatchReason === "COMPANY_OWNED_CAR") {
          text = getStaticText.fullyMismatchCompanyOwned;
        } else if (mismatchReason === "WRONG_NAME_ON_RC") {
          text = getStaticText.partialMismatchCompanyOwned;
        } else if (!mismatchReason || mismatchReason === null) {
          text = getStaticText.noReasonMarked;
        }
        css = styles.mismatchReason;
        break;
      case NUMBERS.THREE:
        text = getStaticText.fullyVerified;
        css = styles.bankFullVerifiedText;
        break;
      // case NUMBERS.FOUR:
      //   if (mismatchReason === "WRONG_NAME_ON_RC") {
      //     text = partialMismatchCompanyOwned;
      //   } else if (!mismatchReason || mismatchReason === null) {
      //     text = noReasonMarked;
      //   }
      //   css = styles.mismatchReason;
      //   break;
      default:
        break;
    }
    return { text, css };
  };
  const handleSendLink = () => {
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.sendVerificationLink({ appointmentId })
      .then((res) => {
        if (res && res.status === 200) {
          showToastMessage("Verification link sent successfully", true);
          dispatch(fetchDocsStatus(appointmentId));
        }
      })
      .catch((error) => {
        showToastMessage(error?.message || error?.error, false);
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
  const primaryAccountRender = () => {
    return (
      <>
        <GridLayout
          cols={[
            {
              width: "50%",
            },
            {
              width: "50%",
            },
          ]}
          gap={{
            rows: "24px",
            cols: "10px",
          }}
        >
          {transactionStatuses.includes(transactionStatus) ? (
            <>
              <GridLayoutItem row={1} col={1}>
                <p className={styles.bankAccLabel}>Account Number</p>
                <p className={styles.bankAccValue}>{accountNumber || "NA"}</p>
              </GridLayoutItem>
              <GridLayoutItem row={1} col={2}>
                <p className={styles.bankAccLabel}>Bank IFS Code</p>
                <p className={styles.bankAccValue}>{ifscCode || "NA"}</p>
              </GridLayoutItem>
              <GridLayoutItem row={2} col={1}>
                <p className={styles.bankAccLabel}>Account Holder Name</p>
                <p className={styles.bankAccValue}>
                  {accountNameFromBank || "NA"}
                </p>
              </GridLayoutItem>
            </>
          ) : (
            <GridLayoutItem row={1} col={1}>
              <Button
                onClick={handleSendLink}
                className={styles.submitCta}
                disabled={!isPrInitialized(purchaseStatus)}
              >
                Verification Link
              </Button>
            </GridLayoutItem>
          )}
          {(transactionStatus === NUMBERS.MINUS_ONE ||
            transactionStatus === null) && (
            <GridLayoutItem row={2} colSpan={2}>
              <div className={styles.bankInfoText}>
                {getStaticText.verificationLinkText}
              </div>
            </GridLayoutItem>
          )}
          {[2, 3].includes(transactionStatus) && (
            <GridLayoutItem row={3} colSpan={2}>
              <div className={`${getStatusText().css} justify-between`}>
                {getStatusText().text}
                {getStatusText().text === getStaticText.noReasonMarked &&
                  transactionStatus !== NUMBERS.FOUR && (
                    <Button
                      className={styles.markReason}
                      fillMode="flat"
                      onClick={toggleMarkReasons}
                      disabled={!isPrInitialized(purchaseStatus)}
                    >
                      Mark Reason
                    </Button>
                  )}
              </div>
            </GridLayoutItem>
          )}
        </GridLayout>
      </>
    );
  };
  //***  to be released later
  // const secondaryAccountRender = () => {
  //   return (
  //     <>
  //       <GridLayout
  //         cols={[
  //           {
  //             width: "50%",
  //           },
  //           {
  //             width: "50%",
  //           },
  //         ]}
  //         gap={{
  //           rows: "24px",
  //           cols: "10px",
  //         }}
  //       >
  //         {/* {transactionStatuses.includes(transactionStatus) ? (
  //           <>
  //             <GridLayoutItem row={1} col={1}>
  //               <p className={styles.bankAccLabel}>Account Number</p>
  //               <p className={styles.bankAccValue}>{accountNumber || "NA"}</p>
  //             </GridLayoutItem>
  //             <GridLayoutItem row={1} col={2}>
  //               <p className={styles.bankAccLabel}>Bank IFS Code</p>
  //               <p className={styles.bankAccValue}>{ifscCode || "NA"}</p>
  //             </GridLayoutItem>
  //             <GridLayoutItem row={2} col={1}>
  //               <p className={styles.bankAccLabel}>Account Holder Name</p>
  //               <p className={styles.bankAccValue}>
  //                 {accountNameFromBank || "NA"}
  //               </p>
  //             </GridLayoutItem>
  //           </>
  //         ) : (
  //           <GridLayoutItem row={1} col={1}>
  //             <Button onClick={() => {}} className={styles.submitCta}>
  //               Verification Link
  //             </Button>
  //           </GridLayoutItem>
  //         )} */}
  //         <GridLayoutItem className={styles.addPayment} row={1} colSpan={2}>
  //           <Button
  //             onClick={() => {}}
  //             fillMode="flat"
  //             icon="plus-outline"
  //             className={`${styles.iconButton} `}
  //           ></Button>
  //           <span className="k-ml-2 k-font-medium k-ml-3">
  //             Add new payment account
  //           </span>
  //         </GridLayoutItem>
  //         {/* {transactionStatus === NUMBERS.MINUS_ONE ||
  //           (transactionStatus === null && (
  //             <GridLayoutItem row={2} colSpan={2}>
  //               <div className={styles.bankInfoText}>
  //                 By this link, customer will be able to add Bank Account
  //                 details himself
  //               </div>
  //             </GridLayoutItem>
  //           ))} */}
  //         <GridLayoutItem row={2} colSpan={2}>
  //           <div className={styles.bankInfoText}>
  //             {getStaticText.addBankAccountText}
  //           </div>
  //         </GridLayoutItem>
  //       </GridLayout>
  //     </>
  //   );
  // };
  //***  to be released later

  const headerTitle = (label, status) => {
    return (
      <div className="justify-between" style={{ height: "30px" }}>
        <div className={styles.bankHeader}>{label}</div>
        {status && <div className="status-grey k-mt-1">{status}</div>}
      </div>
    );
  };
  const handleDownload = () => {
    dispatch(downloadDocument(appointmentId, "CONSENT_LETTER"));
  };
  return (
    <ExpansionPanel
      title={titleBar("Bank Details")}
      className="k-mt-4 k-rounded-lg"
      expanded={showBankDetails}
      tabIndex={0}
      onAction={() => setShowBankDetails(!showBankDetails)}
    >
      {showBankDetails && (
        <ExpansionPanelContent>
          <GridLayout
            cols={[
              {
                width: "40%",
              },
              {
                width: "40%",
              },
            ]}
          >
            <GridLayoutItem row={1} col={1}>
              <KendoCard
                cardStyle={{
                  marginTop: "0px",
                  marginRight: "20px",
                }}
                headerTitle={headerTitle(
                  "Primary Bank Account",
                  getTransactionStatusMapping(transactionStatus)
                )}
                details={primaryAccountRender()}
              />
            </GridLayoutItem>
            {/* <GridLayoutItem row={1} col={2}>
              <KendoCard
                cardStyle={{
                  marginTop: "0px",
                }}
                headerTitle={headerTitle("Secondary Bank Account")}
                details={secondaryAccountRender()}
              />
            </GridLayoutItem> */}
          </GridLayout>
          {mismatchReason === "FRIEND_FAMILY_ACCOUNT" && (
            <GridLayoutItem row={2} colSpan={2}>
              <div className={styles.bankThirdPartyText}>
                <span>You’ll need to upload third party documents</span>
                <div className="justify-between">
                  {docLoading && (
                    <Loader className=" k-mr-4" size="medium" type="pulsing" />
                  )}
                  <Button
                    themeColor="secondary"
                    className={styles.uploadCta}
                    onClick={handleDownload}
                  >
                    Consent Letter
                    <Icon className="k-ml-3" name="download" />
                  </Button>
                </div>
              </div>
            </GridLayoutItem>
          )}
        </ExpansionPanelContent>
      )}
    </ExpansionPanel>
  );
};

export default BankDetails;
