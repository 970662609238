import React, { useEffect, useState } from "react";
import {
  FormCheckbox,
  FormComboBox,
  FormInput,
  UploadFile,
} from "../../../../../../../components";
// import { SOURCE_DELAY_REASONS } from '../../../../viewModal';
import { AdministratorService } from "../../../../../../../services";
import { Button } from "@progress/kendo-react-buttons";
import { showToastMessage } from "../../../../../../../utils/utils";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const AgentManagerPopup = ({
  ctaAction,
  selectedRegion,
  selectedCourt,
  openAgentMangerPopup,
  regionList,
  handleReload,
  courtList,
}) => {
  const [region, setRegion] = useState("");
  const [court, setCourt] = useState("");
  const [agent, setAgent] = useState({
    agentName: "",
    sapCode: "",
    gstNo: "",
    adhaarCard: "",
    panCard: "",
  });
  const [selectedPopUpRegion, setSelectedPopUpRegion] =
    useState(selectedRegion);
  const [isStatusActive, setIsStatusActive] = useState(false);
  const [isVerifiedActive, setIsVerifiedActive] = useState(false);
  const [isGovtNoStatus, setIsGovtNoStatus] = useState(false);
  const deleteCheckboxLabel = `Are you sure you want to ${ctaAction}?`;

  useEffect(() => {
    setIsStatusActive(false);
    if (ctaAction === "Edit Region") {
      setRegion(selectedRegion?.label);
      const isRegionActive = !!regionList?.length
        ? regionList?.filter(
            (item) => item?.value === selectedRegion?.value
          )?.[0]?.status
        : false;
      setIsStatusActive(!!isRegionActive);
    } else if (ctaAction === "Edit Court") {
      setCourt(selectedCourt?.label);
      const isCourtActive = !!courtList?.length
        ? courtList?.filter((item) => item?.value === selectedCourt?.value)?.[0]
            ?.status
        : false;
      setIsStatusActive(!!isCourtActive);
    }
  }, []);

  const handleChange = (e) => {
    const { value } = e || {};
    setRegion(value);
  };

  const handleCourtChange = (e) => {
    const { value } = e || {};
    setCourt(value);
  };

  const handlePopUpRegionChange = (e) => {
    const { value } = e || {};
    setSelectedPopUpRegion(value);
  };

  const handleAgentChange = (e, key) => {
    const { value } = e;
    setAgent({
      ...agent,
      [key]: e.target.type === "file" ? e.target.files[0] : value,
    });
  };

  const handleRegionAdd = () => {
    if (!region?.length) {
      showToastMessage("Please Add Region", false);
    } else {
      AdministratorService.addRegion({ challanRegion: region })
        .then((res) => {
          showToastMessage(res?.message);
          handleReload("fetchAllRegion");
          openAgentMangerPopup();
        })
        .catch((err) => {
          showToastMessage(err?.message, false);
        });
    }
  };

  const handleRegionDelete = () => {
    AdministratorService.deleteRegion(selectedRegion?.value)
      .then((res) => {
        showToastMessage(res?.message);
        handleReload("fetchAllRegion");
        openAgentMangerPopup();
      })
      .catch((err) => {
        showToastMessage(err?.message, false);
      });
  };

  const handleRegionEdit = () => {
    AdministratorService.updateRegion({
      idChallanRegion: selectedRegion?.value,
      status: isStatusActive ? 1 : 0,
    })
      .then((res) => {
        showToastMessage(res?.message);
        handleReload("fetchAllRegion");
        openAgentMangerPopup();
      })
      .catch((err) => {
        showToastMessage(err?.message, false);
      });
  };

  const handleCourtAdd = () => {
    if (!court?.length) {
      showToastMessage("Please Add Court", false);
    } else {
      AdministratorService.addCourt({
        idChallanRegion: selectedPopUpRegion?.value,
        courtName: court,
        status: isStatusActive ? 1 : 0,
      })
        .then((res) => {
          showToastMessage(res?.message);
          handleReload("fetchCourtByRegion");
          openAgentMangerPopup();
        })
        .catch((err) => {
          showToastMessage(err?.message, false);
        });
    }
  };

  const handleDeleteCourt = () => {
    AdministratorService.deleteCourt(selectedCourt?.value)
      .then((res) => {
        showToastMessage(res?.message);
        handleReload("fetchCourtByRegion");
        openAgentMangerPopup();
      })
      .catch((err) => {
        showToastMessage(err?.message, false);
      });
  };

  const handleCourtEdit = () => {
    AdministratorService.updateCourt({
      idChallanCourt: selectedCourt?.value,
      status: isStatusActive ? 1 : 0,
    })
      .then((res) => {
        showToastMessage(res?.message);
        handleReload("fetchCourtByRegion");
        openAgentMangerPopup();
      })
      .catch((err) => {
        showToastMessage(err?.message, false);
      });
  };

  const handleAgentAdd = () => {
    if (!agent?.agentName?.length) {
      showToastMessage("Please Enter Agent Name", false);
    } else if (!agent?.sapCode?.length) {
      showToastMessage("Please Enter Sap Code", false);
    } else if (isGovtNoStatus && !agent?.gstNo?.length) {
      showToastMessage("Please Enter GST Number", false);
    } else if (!agent?.adhaarCard?.name?.length) {
      showToastMessage("Please Upload Aadhaar Proof", false);
    } else if (!agent?.panCard?.name?.length) {
      showToastMessage("Please Upload PAN Proof", false);
    } else if (!isVerifiedActive) {
      showToastMessage("Please mark Is Agent Verified ", false);
    } else {
      let formData = new FormData();
      Object.keys(agent).map((key) => {
        formData.append(key, agent[key]);
      });
      formData.append("status", isStatusActive ? 1 : 0);
      formData.append("isVerified", isVerifiedActive ? 1 : 0);
      AdministratorService.addAgent(formData)
        .then((res) => {
          showToastMessage(res?.message);
          handleReload("fetchAllAgent");
          openAgentMangerPopup();
        })
        .catch((err) => {
          showToastMessage(err?.message, false);
        });
    }
  };

  const handleStatusChange = (e) => {
    const { name } = e.target || {};
    const { value } = e || {};
    if (name === "isVerified") {
      setIsVerifiedActive(value);
    } else {
      setIsStatusActive(value);
    }
  };

  const handleGstNoStatus = () => {
    setAgent({
      ...agent,
      ["gstNo"]: "",
    });
    setIsGovtNoStatus(!isGovtNoStatus);
  };

  const handleReset = () => {
    setRegion("");
    setCourt("");
    setAgent({
      agentName: "",
      sapCode: "",
      gstNo: "",
      adhaarCard: "",
      panCard: "",
    });
    setSelectedPopUpRegion({});
    setIsStatusActive(false);
    setIsVerifiedActive(false);
  };

  return (
    <div className="ops-grid-layout-container" style={{ padding: "0px" }}>
      <div className="administrator-popup">
        {ctaAction === "Add Region" && (
          <>
            <FormInput
              id={"addRegion"}
              key={"addRegion"}
              onChange={handleChange}
              value={region}
              label={"Add Region"}
            />
            <div className="popup-cta-container">
              <Button
                themeColor={"primary"}
                fillMode={"outline"}
                className="m-3"
                onClick={handleReset}
              >
                Reset
              </Button>
              <Button
                themeColor={"primary"}
                className="m-3"
                onClick={handleRegionAdd}
              >
                Add
              </Button>
            </div>
          </>
        )}
        {ctaAction === "Delete Region" && (
          <>
            <FormInput
              id={"deleteRegion"}
              key={"deleteRegion"}
              value={selectedRegion?.label}
              disabled={true}
              label={"Delete Region"}
            />
            <FormCheckbox name={"Active"} label={deleteCheckboxLabel} />
            <Button
              themeColor={"primary"}
              className="m-3"
              onClick={handleRegionDelete}
            >
              Confirm
            </Button>
          </>
        )}
        {ctaAction === "Edit Region" && (
          <>
            <FormInput
              id={"editRegion"}
              key={"editRegion"}
              onChange={handleChange}
              value={selectedRegion?.label}
              label={"Edit Region"}
              disabled={true}
            />
            <FormCheckbox
              name={"Active"}
              label={"Active"}
              onChange={handleStatusChange}
              value={isStatusActive}
            />
            <div className="popup-cta-container">
              <Button
                themeColor={"primary"}
                fillMode={"outline"}
                className="m-3"
                onClick={handleReset}
              >
                Reset
              </Button>
              <Button
                themeColor={"primary"}
                className="m-3"
                onClick={handleRegionEdit}
              >
                Edit
              </Button>
            </div>
          </>
        )}
        {ctaAction === "Add Court" && (
          <>
            <FormComboBox
              id={"regionList"}
              onChange={handlePopUpRegionChange} //todo
              data={regionList}
              textField="label"
              dataItemKey="value"
              label={"Select Region"}
              value={selectedPopUpRegion}
              isDisplayLabel={true}
            />
            <FormInput
              id={"addCourt"}
              key={"addCourt"}
              onChange={handleCourtChange}
              value={court}
              label={"Add Court"}
            />
            <FormCheckbox
              name={"Active"}
              label={"Active"}
              onChange={handleStatusChange}
              value={isStatusActive}
            />
            <div className="popup-cta-container">
              <Button
                themeColor={"primary"}
                fillMode={"outline"}
                className="m-3"
                onClick={handleReset}
              >
                Reset
              </Button>
              <Button
                themeColor={"primary"}
                className="m-3"
                onClick={handleCourtAdd}
              >
                Add
              </Button>
            </div>
          </>
        )}
        {ctaAction === "Delete Court" && (
          <>
            <FormComboBox
              id={"regionList"}
              data={regionList}
              textField="label"
              dataItemKey="value"
              label={"Select Region"}
              disabled={true}
              value={selectedRegion}
              isDisplayLabel={true}
            />
            <FormInput
              id={"deleteCourt"}
              key={"deleteCourt"}
              value={selectedCourt?.label}
              disabled={true}
              label={"Selected Court"}
            />
            <FormCheckbox name={"Active"} label={deleteCheckboxLabel} />
            <Button
              themeColor={"primary"}
              className="m-3"
              onClick={handleDeleteCourt}
            >
              Confirm
            </Button>
          </>
        )}

        {ctaAction === "Edit Court" && (
          <>
            <FormComboBox
              id={"regionList"}
              data={regionList}
              textField="label"
              dataItemKey="value"
              label={"Selected Region"}
              value={selectedRegion}
              disabled={true}
              isDisplayLabel={true}
            />
            <FormInput
              id={"editCourt"}
              key={"editCourt"}
              onChange={handleCourtChange}
              value={selectedCourt?.label}
              label={"Selected Court"}
              disabled={true}
            />
            <FormCheckbox
              name={"Active"}
              label={"Active"}
              onChange={handleStatusChange}
              value={isStatusActive}
            />
            <Button
              themeColor={"primary"}
              className="m-3"
              onClick={handleCourtEdit}
            >
              Confirm
            </Button>
          </>
        )}

        {ctaAction === "Add Agent" && (
          <>
            <GridLayout>
              <GridLayoutItem col={1}>
                <FormInput
                  id={"agentName"}
                  key={"agentName"}
                  onChange={(e) => handleAgentChange(e, "agentName")}
                  value={agent?.agentName}
                  label={"Agent Name"}
                />
                <FormCheckbox
                  name={"status"}
                  label={"Is Agent Active?"}
                  onChange={handleStatusChange}
                  value={isStatusActive}
                />
                <FormInput
                  id={"sapCode"}
                  key={"sapCode"}
                  onChange={(e) => handleAgentChange(e, "sapCode")}
                  value={agent?.sapCode}
                  label={"SAP Code"}
                />
                <FormCheckbox
                  name={"isVerified"}
                  label={"Is Agent Verified?"}
                  onChange={handleStatusChange}
                  value={isVerifiedActive}
                />
                <FormCheckbox
                  name={"gstNoStatus"}
                  label={"GST Number"}
                  onChange={handleGstNoStatus}
                  value={isGovtNoStatus}
                />
                {!!isGovtNoStatus && (
                  <FormInput
                    id={"gstNo"}
                    key={"gstNo"}
                    onChange={(e) => handleAgentChange(e, "gstNo")}
                    value={agent?.gstNo}
                    label={"Enter GST Number"}
                  />
                )}
                <UploadFile
                  onChange={(e) => handleAgentChange(e, "adhaarCard")}
                  label={"Aadhaar Proof"}
                />
                <UploadFile
                  onChange={(e) => handleAgentChange(e, "panCard")}
                  label={"PAN Proof"}
                />
              </GridLayoutItem>
              <GridLayoutItem col={1}></GridLayoutItem>
            </GridLayout>
            <div className="popup-cta-container">
              <Button
                themeColor={"primary"}
                fillMode={"outline"}
                className="m-3"
                onClick={handleReset}
              >
                Reset
              </Button>
              <Button
                themeColor={"primary"}
                className="m-3"
                onClick={handleAgentAdd}
              >
                Add
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AgentManagerPopup;
