import { useHistory } from "react-router-dom";

export const payment_column_keys = {
  LEAD_ID: "id",
  LEAD_STATUS: "leadStatus",
  APP_ID: "appointmentId",
  DEALER_CODE: "dealerCode",
  DEALER_NAME: "dealerName",
  DEALER_CONTACT: "dealerContact",
  COMM_AMOUNT: "commission",
  REGISTRATION_NUMBER: "cxRegNo",
  C24_QUOTE: "c24Quote",
  BANK_ACC: "bankAcc",
  BANK: "bank",
  UTR: "utr",
  UPDATED_AT: "updatedAt",
  PAYMENT_STATUS: "paymentStatus",
  ISSUE_STATUS: "issueStatus",
  LEAD_TYPE: "leadType",
  IFSC_CODE: "ifscCode",
  FLAG_COMMENT: "flagComment",
  STOCK_IN_DATE: "stockInDate",
  TDS_AMOUNT: "tdsAmount",
};
export const payment_status_data = [
  { value: "PAYMENT_NOT_REQUIRED", label: "Not Required" },
  { value: "PAYMENT_NOT_STARTED", label: "Not Started" },
  { value: "PAYMENT_IN_PROGRESS", label: "In Progress" },
  { value: "PAYMENT_FLAGGED", label: "Flagged" },
  { value: "PAYMENT_FAILED", label: "Failed" },
  { value: "PAYMENT_COMPLETED", label: "Completed" },
];
export const issue_status_data = [
  { value: "NO_ISSUE", label: "No Issue" },
  { value: "ISSUE_RAISED", label: "Issue Raised" },
  { value: "ISSUE_RESOLVED", label: "Issue Resolved" },
];
export const lead_status_data = [
  { value: "SHARED", label: "Shared" },
  { value: "DUPLICATE", label: "Duplicate" },
  { value: "ACCEPTED", label: "Accepted" },
  { value: "APPOINTMENT_PENDING", label: "Appointment Pending" },
  { value: "INSPECTION_PENDING", label: "Inspection Pending" },
  { value: "IN_NEGOTIATION", label: "In Negotiation" },
  { value: "TOKEN_PAID", label: "Token Paid" },
  { value: "CAR_STOCK_IN", label: "Car Stock In" },
];
export const paymentColumns = {
  [payment_column_keys.LEAD_ID]: {
    field: payment_column_keys.LEAD_ID,
    label: "Lead Id",
    width: "150px",
    filterable: true,
  },
  [payment_column_keys.APP_ID]: {
    field: payment_column_keys.APP_ID,
    label: "APP ID",
    width: "150px",
    filterable: true,
  },
  [payment_column_keys.DEALER_CODE]: {
    field: payment_column_keys.DEALER_CODE,
    label: "DEALER CODE",
    filterable: true,
    width: "150px",
  },
  [payment_column_keys.REGISTRATION_NUMBER]: {
    field: payment_column_keys.REGISTRATION_NUMBER,
    label: "REGISTRATION NO.",
    filterable: true,
    width: "150px",
  },
  [payment_column_keys.C24_QUOTE]: {
    field: payment_column_keys.C24_QUOTE,
    label: "C24 QUOTE",
    width: "150px",
  },
  [payment_column_keys.TDS_AMOUNT]: {
    field: payment_column_keys.TDS_AMOUNT,
    label: "TDS AMOUNT",
    width: "150px",
  },
  [payment_column_keys.COMM_AMOUNT]: {
    field: payment_column_keys.COMM_AMOUNT,
    label: "COMM AMOUNT",
    width: "150px",
  },
  [payment_column_keys.UPDATED_AT]: {
    field: payment_column_keys.UPDATED_AT,
    label: "UPDATED AT",
    width: "150px",
  },
  [payment_column_keys.STOCK_IN_DATE]: {
    field: payment_column_keys.STOCK_IN_DATE,
    width: "150px",
    label: "STOCK IN DATE",
  },
  [payment_column_keys.LEAD_TYPE]: {
    field: payment_column_keys.LEAD_TYPE,
    width: "150px",
    label: "LEAD TYPE",
  },
  [payment_column_keys.LEAD_STATUS]: {
    field: payment_column_keys.LEAD_STATUS,
    width: "150px",
    label: "LEAD STATUS",
    filterable: true,
  },
  [payment_column_keys.PAYMENT_STATUS]: {
    field: payment_column_keys.PAYMENT_STATUS,
    width: "150px",
    label: "PAYMENT STATUS",
    filterable: true,
  },
  [payment_column_keys.ISSUE_STATUS]: {
    field: payment_column_keys.ISSUE_STATUS,
    width: "150px",
    label: "ISSUE STATUS",
    filterable: true,
  },
};

export const getDisplayedCoulmns = (columns) => {
  return Object.keys(columns);
};

export const LeadIdLinkCell = (props) => {
  const { dataItem } = props || {};
  const history = useHistory();
  return (
    <td
      className="k-table-td hyper-link"
      onClick={() => {
        history.replace({
          pathname: `payouts/detail/${dataItem?.id}`,
          state: JSON.stringify(props),
        });
      }}
    >
      {dataItem?.id}
    </td>
  );
};
export const StatusCell = (props) => {
  const { dataItem, data, status } = props || {};
  const getStatusLabel = () => {
    const filteredStatus = data?.find(
      (data) => data.value === dataItem?.[status]
    );
    if (filteredStatus) {
      return filteredStatus.label;
    } else {
      return "";
    }
  };
  return <td className="k-table-td">{getStatusLabel()}</td>;
};
