import { FORM_ELEMENT_TYPE } from "../../../../../../../utils/constants/values.constants";

export const LOAN_STATUS_FORM_DATA = {
    'loanPendingAmount': {
        name: 'loanPendingAmount',
        label: "Loan Amount",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        type: "number",
        value: '',
        placeholder: "Enter Loan Amount",
        disabled: true
    },
    'loanAccountNumber': {
        name: 'loanAccountNumber',
        label: "Loan Account Number",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        type: "text",
        value: '',
        placeholder: "Enter Loan Account Number",
        disabled: true
    },
    'loanStatus': {
        name: 'loanStatus',
        label: "Loan Status",
        elementtype: FORM_ELEMENT_TYPE.SELECT,
        clearButton: false,
        source: [
            { label: "Pending", value: "PENDING" },
            { label: "Closed", value: "CLOSED" },
        ],
        value: null,
        disabled: true
    },
    'hypothecationBank': {
        name: 'hypoBank',
        label: "Hypo Bank",
        elementtype: FORM_ELEMENT_TYPE.SELECT,
        clearButton: false,
        value: null,
        disabled: true
    },
};

export const CONSENT_FORM_DATA = {
    'consent': {
        name: 'consent',
        label: "Consent Status",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        placeholder: "Not available",
        value: null,
        disabled: true
    },
}

export const NUMBER_CORRECTION_FORM_DATA = {
    'creditReportMaskedPhone': {
        name: 'creditReportMaskedPhone',
        label: "Error in Report Generation",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        type: "text",
        value: null,
        placeholder: "Not available",
        disabled: true
    }
}

export const REMARKS_FORM_DATA = {
    'hypoQcStatus': {
        name: 'hypoQcStatus',
        label: "QC Status",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        value: null,
        disabled: true,
        placeholder: 'Not available'
    },
    'hypoQcRemarks': {
        name: 'hypoQcRemarks',
        label: "QC Remarks",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        value: null,
        disabled: true,
        placeholder: 'Not available'
    },
}