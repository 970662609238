import React, {
	useEffect,
	useState
} from 'react';
import "./side-bar-filters.scss";
import {
	ExpansionPanel,
	ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import AppMultiSelect from '../common/components/app-multiselect';
import { fetchSideBarFiltersData } from '../store/actions/allCars';
import { Checkbox, Input, RangeSlider, SliderLabel } from "@progress/kendo-react-inputs";
import { trackAnalytics } from '../common/services/analytics';
import { SegmentEvents } from '../common/constants/events.constants';
import { EVENT_CAR_SOURCE } from '../common/constants/table.constants';
import { getLoginUserEmail } from '../../../utils/utils';

const INPUT_TYPE = {
	RANGE: 'SLIDER',
	MULTI_SELECT: "CHECKED",
	NESTED_CHECKBOX: "NESTED_CHECKBOX"
}


const defaultFilters = [
	{
		id: 'CENTER',
		label: 'Center',
		options: [],
		selected: [],
		type: INPUT_TYPE.NESTED_CHECKBOX
	},
	{
		id: 'INSPECTION_CITY',
		label: 'City',
		options: [],
		selected: null,
		placeholder: 'Select City from Dropdown',
		type: INPUT_TYPE.MULTI_SELECT
	}, {
		id: 'MAKE_MODEL',
		label: 'Make',
		options: [],
		selected: null,
		placeholder: 'Select Make from Dropdown',
		type: INPUT_TYPE.MULTI_SELECT
	}, {
		id: 'Model',
		label: 'Model',
		options: [],
		selected: null,
		placeholder: 'Select Model from Dropdown',
		type: INPUT_TYPE.MULTI_SELECT
	},
	{
		id: 'FUEL_TYPE',
		label: 'Fuel Type',
		options: [],
		selected: null,
		placeholder: 'Select Fuel from Dropdown',
		type: INPUT_TYPE.MULTI_SELECT
	},
	{
		id: 'registrationStates',
		label: 'Registration States',
		options: [],
		selected: null
	},
	{
		id: 'YEAR',
		label: 'Year',
		options: [],
		selected: null,
		type: INPUT_TYPE.RANGE
	}, {
		id: 'ODOMETER',
		label: 'Odo Range',
		options: [],
		selected: null,
		type: INPUT_TYPE.RANGE
	},
];

const SideBarFilters = ({ close, checkedItems, setCheckedItems, clearMoreFilters = () => { }, applied = () => { }, queryParameters = {}, sidebarFilters, sidebarFiltersApplied, applyCarFilters, resetCarFilters }) => {

	const [sidebarFilterOptions, setSidebarFilterOptions] = useState([...defaultFilters]);
	const [expanded, setExpanded] = useState(sidebarFilterOptions[0].id);
	const [stateExpanded, setStateExpanded] = useState("");
	const [searchQuery, setSearchQuery] = useState("");

	const setCheckedItemsFromQueryParams = (queryParameters, sidebarFilterOptions, setCheckedItems) => {
		const storeCityIds = queryParameters["storecityid"]?.split(',') || [];
		const storeIds = queryParameters["storeid"]?.split(',') || [];
		let checkedItemsData = [];


		storeCityIds.forEach(cityId => {
			const filterOption = sidebarFilterOptions[0];
			const store = filterOption?.options.find(option => option.filter.value == cityId);
			if (store && store.children) {
				const matchedChildren = store.children.filter(child => storeIds.includes(child.filter.value.toString()));
				if (matchedChildren.length > 0) {
					checkedItemsData.push({
						state: {
							...store,
							children: matchedChildren
						}
					});
				}
			}
		});

		setCheckedItems(checkedItemsData);

	};


	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => document.body.style.overflow = 'unset';
	}, []);


	useEffect(() => {
		let newFiltersOptions = sidebarFiltersApplied
			? [...sidebarFiltersApplied]
			: defaultFilters.map(filter => ({
				...filter,
				selected: filter.type === INPUT_TYPE.MULTI_SELECT || filter.type === INPUT_TYPE.NESTED_CHECKBOX ? [] : null
			}));

		sidebarFilters?.detail?.forEach((ele) => {
			let index = defaultFilters.findIndex(e => e.id === ele.name);
			if (index > -1) {
				if (newFiltersOptions[index].type === INPUT_TYPE.MULTI_SELECT) {
					newFiltersOptions[index].options = ele.options;
				} else if (newFiltersOptions[index].type === INPUT_TYPE.RANGE) {
					newFiltersOptions[index].options = ele.options[0]?.filter?.values;
				}
			}
		});

		const setOptionsById = (id, options) => {
			const index = defaultFilters.findIndex(e => e.id === id);
			if (index > -1) newFiltersOptions[index].options = options;
		};

		setOptionsById('registrationStates', sidebarFilters?.registrationStates);
		setOptionsById('CENTER', sidebarFilters?.centers?.options || []);

		if (Object.keys(queryParameters).length > 0 && !sidebarFiltersApplied) {
			const params = new URLSearchParams(queryParameters);

			params.forEach((paramValue, paramKey) => {
				if (paramKey === 'model') return;
				const filterIndex = newFiltersOptions.findIndex(
					filterOption => filterOption.id.toLowerCase() === paramKey
				);

				if (filterIndex > -1) {
					const filterOption = newFiltersOptions[filterIndex];

					if (filterOption.type === INPUT_TYPE.MULTI_SELECT) {
						let selectedChildren = [];
						let allMatchingChildren = [];
						const selectedOptions = paramValue.split(',').map(optionDisplay => {
							const matchingOption = filterOption?.options.find(opt => opt.display === optionDisplay);

							if (filterOption.id === 'MAKE_MODEL') {
								const children = queryParameters['model']?.split(',');
								children?.forEach(child => {
									const matchingChild = matchingOption?.children.find(opt => opt.display === child);
									if (matchingChild) {
										selectedChildren.push(matchingChild);
									}
								});

								if (matchingOption?.children) {
									allMatchingChildren.push(...matchingOption.children);
								}

								newFiltersOptions[3].options = allMatchingChildren;
								newFiltersOptions[3].selected = [...selectedChildren];
							}

							return matchingOption;
						});
						filterOption.selected = selectedOptions;
					} else if (filterOption.type === INPUT_TYPE.RANGE) {
						const [start, end] = paramValue.split('-').map(Number);
						filterOption.selected = { start, end };
					} else if (paramKey === "registrationstates") {
						const selectedStates = paramValue.split(',').map(stateName => {
							const matchingState = sidebarFilters?.registrationStates.find(opt => opt.stateName === stateName);
							return matchingState || { stateName: stateName };
						});
						filterOption.selected = selectedStates;
					}
				}

				if (paramKey.includes('storecityid')) {
					const filterOption = newFiltersOptions[0];
					const storeCityIds = queryParameters["storecityid"]?.split(',') || [];
					const storeIds = queryParameters["storeid"]?.split(',') || [];
					let data = [];
					storeCityIds.forEach(cityId => {
						const store = filterOption?.options.find(option => option.filter.value == cityId);
						if (store && store.children) {
							const matchedChildren = store.children.filter(child => storeIds.includes(child.filter.value.toString()));
							if (matchedChildren.length > 0) {
								data.push({
									storeCityId: parseInt(cityId),
									storeIds: matchedChildren.map(child => child.filter.value)
								});
							}
						}
					});
					filterOption.selected = data;
					setCheckedItemsFromQueryParams(queryParameters, newFiltersOptions, setCheckedItems);
				}
			});
		}

		setSidebarFilterOptions([...newFiltersOptions]);
	}, [sidebarFilters]);



	useEffect(() => {
		if (!sidebarFilters || !sidebarFilters?.detail) {
			dispatch(fetchSideBarFiltersData());
		}
	}, [])

	const updateDependentFilters = (filterName, value) => {
		switch (filterName) {
			case 'MAKE_MODEL':
				if (value) {
					setSidebarFilterOptions(prevVal => {
						let data = [];
						value.map(ele => { data = [...data, ...ele.children] })
						prevVal[3].options = [...data];
						if (prevVal[3].selected) {

							let selected = prevVal[3].selected.filter(ele => data.some(e => e.display === ele.display));
							prevVal[3].selected = selected;

						}
						else {
							prevVal[3].selected = null;
						}

						return [
							...prevVal,
						]
					});
				}
				break;
			default: break;
		}
	}



	const applyFilters = () => {
		applyCarFilters(sidebarFilterOptions);
		applied();
		close();
		const email = getLoginUserEmail();
		trackAnalytics(SegmentEvents.Viz_set_filters, {
			email_id: email,
			source: window.location.pathname?.includes('mydealers') ? EVENT_CAR_SOURCE.dealers : EVENT_CAR_SOURCE.cars,
			city: getSelectedLabels('INSPECTION_CITY'),
			make: getSelectedLabels("MAKE_MODEL"),
			model: getSelectedLabels("Model"),
			year: getSelectedLabels('YEAR'),
			odoRange: getSelectedLabels("ODOMETER"),
			fuelType: getSelectedLabels("FUEL_TYPE"),
			rtoCode: getSelectedRTOCode("registrationStates"),
		});
	}

	const getSelectedRTOCode = (key) => {
		let index = sidebarFilterOptions.findIndex(ele => ele.id == key);
		let values = [];
		if (index > -1) {
			sidebarFilterOptions[index]?.selected?.map(ele => values.push(ele.stateName))
		}
		return values.join(',');
	}

	const getSelectedLabels = (key) => {
		let index = sidebarFilterOptions.findIndex(ele => ele.id == key);
		let values = [];
		if (index > -1) {
			if (sidebarFilterOptions[index]?.type === INPUT_TYPE.RANGE) {
				return sidebarFilterOptions[index]?.selected;
			}
			sidebarFilterOptions[index]?.selected?.map(ele => values.push(ele.display))
		}
		return values.join(',');
	}

	const resetFilters = () => {
		resetCarFilters();

		let newFiltersOptions = [...sidebarFilterOptions];
		newFiltersOptions?.map((ele => {
			return ele.selected = null;
		}));

		setSidebarFilterOptions([...newFiltersOptions]);
		clearMoreFilters();
		close();

	}

	const handleParentChange = (state, index) => {
		setCheckedItems(prev => {
			if (!Array.isArray(prev)) {
				prev = [];
			}

			const newCheckedItems = prev.filter(item => item.state.filter.value !== state.filter.value);

			if (!prev.some(item => item.state.filter.value === state.filter.value)) {
				newCheckedItems.push({
					state: {
						...state,
						children: state.children.map(child => ({ ...child, selected: true }))
					}
				});
			}

			return newCheckedItems;
		});

		setSidebarFilterOptions(prev => {
			if (!Array.isArray(prev)) {
				prev = [];
			}

			const selectedItems = [...prev];

			if (!Array.isArray(selectedItems[index].selected)) {
				selectedItems[index].selected = [];
			}

			const parentIndex = selectedItems[index].selected.findIndex(selected => selected.storeCityId === state.filter.value);

			if (parentIndex > -1) {
				selectedItems[index].selected.splice(parentIndex, 1);
			} else {
				selectedItems[index].selected.push({
					storeCityId: state.filter.value,
					storeIds: state.children.map(city => city.filter.value)
				});
			}

			return selectedItems;
		});
	};



	const handleChildChange = (state, city, index) => {
		setSidebarFilterOptions(prev => {
			const selectedItems = [...prev];
			const parentIndex = selectedItems[index].selected.findIndex(selected => selected.storeCityId === state.filter.value);

			if (parentIndex > -1) {
				const storeIds = selectedItems[index].selected[parentIndex].storeIds;
				const cityIndex = storeIds.indexOf(city.filter.value);
				if (cityIndex > -1) {
					storeIds.splice(cityIndex, 1);
					if (storeIds.length === 0) {
						selectedItems[index].selected.splice(parentIndex, 1);
					}
				} else {
					storeIds.push(city.filter.value);
				}
			} else {
				selectedItems[index].selected.push({
					storeCityId: state.filter.value,
					storeIds: [city.filter.value]
				});
			}

			return selectedItems;
		});

		setCheckedItems(prev => {
			const newCheckedItems = [...prev];
			const parentIndex = newCheckedItems.findIndex(item => item.state.filter.value === state.filter.value);

			if (parentIndex > -1) {
				const childIndex = newCheckedItems[parentIndex].state.children.findIndex(child => child.filter.value === city.filter.value);

				if (childIndex > -1) {
					newCheckedItems[parentIndex].state.children.splice(childIndex, 1);
					if (newCheckedItems[parentIndex].state.children.length === 0) {
						newCheckedItems.splice(parentIndex, 1);
					}
				} else {
					newCheckedItems[parentIndex].state.children.push({ ...city, selected: true });
				}
			} else {
				newCheckedItems.push({
					state: {
						...state,
						children: [{ ...city, selected: true }]
					}
				});
			}

			return newCheckedItems;
		});
	};

	const isStateSelected = (checkedItems, state) => {
		if (!Array.isArray(checkedItems)) {
			return false;
		}

		const parentItem = checkedItems.find(item => item.state.filter.value === state.filter.value);
		if (parentItem) {
			if (parentItem.state.children.length === state.children.length) {
				return true;
			} else if (parentItem.state.children.length > 0) {
				return null;
			}
		}
		return false;
	};



	const isCitySelected = (checkedItems, state, city) => {
		if (!Array.isArray(checkedItems)) {
			return false;
		}

		const parentItem = checkedItems.find(item => item.state.filter.value === state.filter.value);
		if (parentItem) {
			const child = parentItem.state.children.find(child => child.filter.value === city.filter.value);
			return !!child;
		}

		return false;
	};

	const handleStateExpand = (state) => {
		setStateExpanded(state);
	};

	const handleSearchChange = (event) => {
		setSearchQuery(event.target.value);
	};


	const getNearestValue = (filter, val) => {
		return filter.options.reduce((prev, curr) =>
			Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev
		);
	};


	const centerFilterOptions = sidebarFilterOptions.find(filter => filter.id === 'CENTER')?.options
		.map(stateObj => ({
			...stateObj,
			children: stateObj.children
				.sort((a, b) => {
					const aChecked = isCitySelected(checkedItems, stateObj, a);
					const bChecked = isCitySelected(checkedItems, stateObj, b);
					if (aChecked && !bChecked) return -1;
					if (!aChecked && bChecked) return 1;
					return 0;
				})
		}))
		.sort((a, b) => {
			const aChecked = isStateSelected(checkedItems, a) !== false;
			const bChecked = isStateSelected(checkedItems, b) !== false;
			if (aChecked && !bChecked) return -1;
			if (!aChecked && bChecked) return 1;
			return 0;
		})
		.filter(
			(stateObj) =>
				stateObj.display.toLowerCase().includes(searchQuery.toLowerCase()) ||
				stateObj.children.some(city => city.display.toLowerCase().includes(searchQuery.toLowerCase()))
		) || [];


	return (
		<div className="side-bar-filters">
			<div className="overlay" onClick={() => close(false)}></div>
			<div className="filters-block">
				<span className="k-icon k-font-icon k-i-close close-icon" onClick={() => close(false)}></span>
				<div className="filter-title">Filter By</div>
				<div className='w-100'>
					{sidebarFilterOptions.map((filter, index) => {
						return <ExpansionPanel
							className="app-expansion-panel"
							title={filter.label}
							expanded={expanded === filter.id}
							tabIndex={0}
							key={filter.id}
							onAction={(event) => {
								setExpanded(event.expanded ? "" : filter.id);
							}}
						>
							<Reveal>
								{expanded === filter.id && (
									<ExpansionPanelContent>
										{filter.type == INPUT_TYPE.MULTI_SELECT && <AppMultiSelect
											data={[...filter.options]}
											filterChange={(e) => {
												setSidebarFilterOptions(prevVal => {
													prevVal[index].selected = e.value;
													return [
														...prevVal,
													]
												});
												updateDependentFilters(filter.id, e.value);
											}}
											value={filter.selected}
											textField={'display'}
											dataItemKey={'filter'}
											placeholder={filter.placeholder}
											popupClass={"full-width-filter"}
											filterable={true}
										/>}


										{filter.id == 'CENTER' && (
											<>
												<Input
													className='search-input'
													value={searchQuery}
													onChange={handleSearchChange}
													placeholder="Search for a state or city"
													onKeyDown={(event) => {
														if (event.key === " ") {
															event.stopPropagation();
														}
													}
													}
												/>


												<div className='center-filter'>
													{centerFilterOptions.map((stateObj) => (
														<ExpansionPanel
															key={stateObj.display}
															title={
																<div className='checkbox-title'>
																	<Checkbox
																		type="checkbox"
																		className='center-checkbox'
																		onClick={(e) => e.stopPropagation()}
																		checked={isStateSelected(checkedItems, stateObj) === true}
																		value={isStateSelected(checkedItems, stateObj)}
																		onChange={() => handleParentChange(stateObj, index)}
																	/>
																	<span className='center-text'>{stateObj.display}</span>
																</div>
															}
															expanded={stateExpanded === stateObj.display}
															onAction={(event) => {
																handleStateExpand(event.expanded ? "" : stateObj.display);
															}}
														>
															<Reveal>
																{stateExpanded === stateObj.display && (
																	<ExpansionPanelContent>
																		<div className='cities-container'>
																			{stateObj.children.map((city) => (
																				<div key={city.display}>
																					<label>
																						<Checkbox
																							type="checkbox"
																							className='center-checkbox'
																							checked={isCitySelected(checkedItems, stateObj, city)}
																							onChange={() => handleChildChange(stateObj, city, index)}
																						/>
																						{city.display}
																					</label>
																				</div>
																			))}
																		</div>
																	</ExpansionPanelContent>
																)}
															</Reveal>
														</ExpansionPanel>
													))}
												</div>
											</>
										)}


										{filter.id == 'registrationStates' && <AppMultiSelect
											data={[...filter.options]}
											filterChange={(e) => {
												setSidebarFilterOptions(prevVal => {
													prevVal[index].selected = e.value;
													return [
														...prevVal,
													]
												});
												updateDependentFilters(filter.id, e.value);
											}}
											value={filter.selected}
											textField={'stateName'}
											dataItemKey={'stateId'}
											placeholder='Select State from Dropdown'
											popupClass={"full-width-filter"}
											filterable={true}
										/>}

										{filter.type == INPUT_TYPE.RANGE &&
											<RangeSlider
												defaultValue={{
													start: filter?.selected?.start || filter?.options[0],
													end: filter.selected?.end || filter?.options[filter.options?.length - 1],
												}}
												step={1}
												min={filter.options[0]}
												max={filter.options[filter.options?.length - 1]}
												onChange={(e) => {
													const nearestStart = getNearestValue(filter, e.value.start);
													const nearestEnd = getNearestValue(filter, e.value.end);

													setSidebarFilterOptions((prevVal) => {
														const updatedFilters = [...prevVal];
														updatedFilters[index].selected = {
															start: nearestStart,
															end: nearestEnd,
														};
														return updatedFilters;
													});
												}}
											>

												{!filter.selected && filter.options && filter.options.length > 0 ?
													<SliderLabel position={filter.options[0]} className="selected-range-label">
														{filter.options[0] === 0 ? '0' : filter.options[0]}
													</SliderLabel>
													: null}

												{filter?.selected ?
													<SliderLabel position={filter.selected.start} className="selected-range-label">
														{filter.selected["start"] === 0 ? '0' : filter.selected.start}
													</SliderLabel>
													: null}

												{filter?.selected ?
													<SliderLabel position={filter.selected.end} className="selected-range-label">
														{filter.selected.end}
													</SliderLabel>
													: null}

												{!filter.selected && filter.options && filter.options.length > 0 ?
													<SliderLabel position={filter.options[filter.options.length - 1]}>
														{filter.options[filter.options.length - 1]}
													</SliderLabel>
													: null}

											</RangeSlider>
										}
									</ExpansionPanelContent>
								)}
							</Reveal>
						</ExpansionPanel>
					})}
				</div>
				<div className="actions-block">
					<div className="btn-block">
						<Button
							className='app-secondary-btn font-medium'
							themeColor={"light"}
							fillMode={'solid'}
							onClick={() => { resetFilters() }}
						>
							RESET
						</Button>
						<Button
							className='app-primary-btn font-medium'
							themeColor={"primary"}
							fillMode={'solid'}
							onClick={() => { applyFilters() }}
						>
							APPLY FILTERS
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
export default SideBarFilters;
