export default (api) => {
  const createLeadMonConfig = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/lead/monetization`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const replicaLeadMonConfig = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/lead/monetization/copy`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchLeadMonConfigs = (cityID, locationId, params = "") => {
    return new Promise((resolve, reject) => {
      api
        .get(`/lead/monetization/${cityID}/${locationId}${params}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const markStatus = (id, params = {}) => {
    return new Promise((resolve, reject) => {
      api
        .put(`/lead/monetization/${id}`, params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchPriceUpdateData = () => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/v1/product/pricing`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    fetchLeadMonConfigs,
    createLeadMonConfig,
    replicaLeadMonConfig,
    markStatus,
    fetchPriceUpdateData,
  };
};
