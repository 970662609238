import { ALL_PAIS_SORT_BY_FILTER } from "../../common/constants/filters.constants";
import { TYPE_PAIS } from "../types";

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  page: 0,
  totalElements: 0,
  pageSize: 0,
  totalPages:0,
  error: {},
  sortBy: "",
  filters:{
    sortBy:ALL_PAIS_SORT_BY_FILTER[0]
  },
  ongoing:{
    data:[],
    totalElements:0
  }
};

const PAIs = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPE_PAIS.FETCH_LOADING_START:
      return {
        ...state,
        isLoading: true,
      };
    case TYPE_PAIS.FETCH_LOADING_END:
      return {
        ...state,
        isLoading: false,
      };
    case TYPE_PAIS.FETCH_PAIS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        error: {},
      };
    case TYPE_PAIS.FETCH_PAIS_FAILURE:
      return {
        ...state,
        data: [],
        totalCount: 0,
        numberOfElements: 0,
        error: action.payload,
      };
      
      case TYPE_PAIS.UPDATE_PAIS_FILTERS:
        return {
          ...state,
          filters: {
            ...state.filters,...action.payload
          },
        };
        case TYPE_PAIS.FETCH_PAIS__ONGOING_SUCCESS:
          return {
            ...state,
            ongoing:{data: action.payload.data,
              totalElements : action.payload.totalElements},
          };
          case TYPE_PAIS.CLEAR_PAIS_DATA:
            return {
              ...state,
              data:[]
            }; 
    default:
      return state;
  }
};
export default PAIs;
