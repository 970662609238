import { cj_incentive_keys } from "../view-modal";
export const today = new Date();
export const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);

export const incentiveDetailModel = {
	[cj_incentive_keys.NAME]: "",
	[cj_incentive_keys.CJ_TYPE]: "",
	[cj_incentive_keys.TYPE]: "",
	[cj_incentive_keys.CLUSTER]: "",
	[cj_incentive_keys.DESCRIPTION]: "",
	[cj_incentive_keys.IS_RANGE]: false,
	[cj_incentive_keys.VALID_RANGE]: {},
	[cj_incentive_keys.STATUS]: false,
	[cj_incentive_keys.ALERT]: false,
	[cj_incentive_keys.CONDITIONS]: [
		{
			[cj_incentive_keys.FORMULA_EXP]: { value: "", isSelected: false },
			[cj_incentive_keys.FORMULA_RESULT]: { value: "", isSelected: false },
		},
	],
	[cj_incentive_keys.TARGET_MATRIX]: [],
	[cj_incentive_keys.NUDGE_ICON]: null,
	[cj_incentive_keys.NUDGE_DESCRIPTION]: "",
};
