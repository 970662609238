import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import styles from "../../../assets/styles/pr.styles.module.css";
const AuditCheckCard = (props) => {
  const {
    netPayableAmount = 0,
    auditImage = "",
    heading = "",
    headingCss = "",
    subHeading = "",
    text = "",
    actions = "",
    alignCenter = false,
  } = props || {};
  return (
    <div className={styles.auditChecks}>
      <GridLayout
        gap={{
          rows: 0,
          cols: 0,
        }}
        cols={[
          {
            width: "30%",
          },
          {
            width: "70%",
          },
        ]}
      >
        <GridLayoutItem className={styles.auditImage} rowSpan={4} col={1}>
          <img src={auditImage} alt="auditImage" />
        </GridLayoutItem>
        <GridLayoutItem className="k-mb-4" row={1} col={2}>
          <h5 className={`k-font-bold  ${headingCss}`}>{heading}</h5>
          <h6 className="k-font-size-sm k-font-normal k-mr-10">{subHeading}</h6>
        </GridLayoutItem>
        <GridLayoutItem row={2} col={2}>
          <h6 className="k-font-size-sm k-font-normal">{text}</h6>
        </GridLayoutItem>
        <GridLayoutItem row={3} col={2}>
          {netPayableAmount ? (
            <h4 className="k-mb-5 k-font-bold">{netPayableAmount}</h4>
          ) : null}
        </GridLayoutItem>
        <GridLayoutItem row={4} col={2}>
          {actions}
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
export default AuditCheckCard;
