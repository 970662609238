import React from 'react';
import styles from './style.module.css'
import { Loader } from '@progress/kendo-react-indicators';
import { SCORE_COLOR_STYLES } from '../ra-score-card-collection/view-model';

function AreaOfImprovement(props) {

    const {scorecardDataLoading,dataByRequestIdLoading,scorecardData,dataByRequestId,scorecardErrorMessage} = props

    const renderCollections = () => {
      if((scorecardDataLoading || dataByRequestIdLoading) || ((scorecardData?.msg == 'SUCCESS' || dataByRequestId?.msg == 'SUCCESS') && dataByRequestId?.data?.status !='PROCESSED')){
        return(
          <Loader/>
        )
      }
      else if (scorecardErrorMessage?.msg == 'Not Found.'){
        return (
          <p className={styles['head_collections--error']}>No records found</p>
        ) 
      }
      else if (dataByRequestId && dataByRequestId?.data?.status =='PROCESSED')
      {
        const data = dataByRequestId?.data?.response?JSON.parse(dataByRequestId?.data?.response):undefined
        const scoringData = JSON.parse(data?.data?.text)
        const aoeData = scoringData?.areaOfImprovement;
        return(
          <div className={styles.head_aoi}>
            {
              aoeData?.parameters?.map((item)=>{
                return(
                  <div className={styles.aoeData}>
                    <div className={styles.aoeData_header}>
                      <div className={styles.aoeData_header_parameter}>{item?.parameter}</div>
                      <div 
                        className={styles.aoeData_header_score}
                        style={SCORE_COLOR_STYLES[item?.score??'1/3']}
                      >
                          {item?.score}
                      </div>
                    </div>
                    <div className={styles.aoeData_content_container}>
                      {
                        item?.subParameter.map((data)=>{
                          return(
                            <div className={styles.aoeData_content}>
                              <div className={styles.aoeData_content_heading}>{data?.heading}</div>
                              <div className={styles.aoeData_content_detail}>{data?.detail}</div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        )
      }
    }

    return (
        <div className={styles['head']}>
          {
            renderCollections()
          }
        </div>
    );
}



export default AreaOfImprovement;


