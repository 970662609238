export const AlertType = {
	SUCCESS: "success",
	ERROR: "error",
	WARNING: "warning",
	INFO: "info",
	NONE: "none",
};
export const APPOINTMENT_ID_REGEX = /\/([0-9]+)[^\/]*$/g;
export const VALID_ROUTES = [
	"/",
	"/home",
	"/retail/makes",
	"/retail/models",
	"/operations/challans",
	"/vas/orders",
	"/dealer/fulfillment",
	"/user",
];

export const NUMBERS = {
	ZERO: 0,
	ONE: 1,
	MINUS_ONE: -1,
	TWO: 2,
	THREE: 3,
	FOUR: 4,
	FIVE: 5,
	SIX: 6,
	SEVEN: 7,
	EIGHT: 8,
	NINE: 9,
	TEN: 10,
	ELEVEN: 11,
	TWELEVE: 12,
	THIRTEEN: 13,
	FIFTY: 50,
	HUNDREAD: 100,
};

export const BREAKPOINTS = {
	xs: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1400,
};

export const paginationOptions = [
	{
		label: 10,
		value: 10,
	},
	{
		label: 30,
		value: 30,
	},
	{
		label: 50,
		value: 50,
	},
	{
		label: 100,
		value: 100,
	},
];

export const defaultGridPageSize = 10;

export const weekDays = [
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
];

export const daysCollection = [
	{ id: 1, day: "monday" },
	{ id: 2, day: "tuesday" },
	{ id: 3, day: "wednesday" },
	{ id: 4, day: "thursday" },
	{ id: 5, day: "friday" },
	{ id: 6, day: "saturday" },
	{ id: 7, day: "sunday" },
];

export const LOCAL_STORAGE_KEYS = {
	OKTA_USER_LOGIN_INFO: "login-user-info",
	OKTA_TOKEN_DATA: "okta-token-storage",
	USER_ACTIVE_SELECTED_MENU: "user-selected-menus",
	USER_ROLE: "user-role",
	USER_ACCESS_ROLES: "user-access-roles",
	USER_INFO: "user-info",
	PREV_PATHNAME: "previous_pathname",
	USER_LOGIN_DATE_TIME: "user-login-date-time",
};

export const CHALLAN_SOURCE_KEYS = {
	DEX: "DEX",
	PSG: "PSG",
	GSD: "GSD",
	GSP: "GSP",
	C2BP: "C2BP",
	CEX: "CEX",
	NBFC: "NBFC",
	VAS: "VAS",
	ALL: "ALL",
};

export const CHALLAN_SOURCES = [
	{ label: "All Tickets", value: "" },
	{ label: "DEX Tickets", value: CHALLAN_SOURCE_KEYS.DEX },
	{ label: "PSG", value: CHALLAN_SOURCE_KEYS.PSG },
	{ label: "GS Delivery", value: CHALLAN_SOURCE_KEYS.GSD },
	{ label: "GS Proactive", value: CHALLAN_SOURCE_KEYS.GSP },
	{ label: "C2B Proactive", value: CHALLAN_SOURCE_KEYS.C2BP },
	{ label: "CeX Refund Tickets", value: CHALLAN_SOURCE_KEYS.CEX },
	{ label: "NBFC", value: CHALLAN_SOURCE_KEYS.NBFC },
	{ label: "VAS", value: CHALLAN_SOURCE_KEYS.VAS },
];

export const DISPOSE_CHALLAN_SOURCES = [
	{ label: "All Challans", value: "" },
	{ label: "Offline", value: CHALLAN_SOURCE_KEYS.DEX },
	{ label: "Online", value: CHALLAN_SOURCE_KEYS.PSG },
];

export const ASSIGNMENT_TYPES = [
	{ label: "All Requests", value: NUMBERS.ZERO },
	{ label: "Assigned to me", value: NUMBERS.ONE },
	{ label: "Unassigned", value: NUMBERS.MINUS_ONE },
];
export const ADMIN_ASSIGNMENT_TYPES = [
	{ label: "All Tickets", value: NUMBERS.ZERO },
	{ label: "Assigned", value: NUMBERS.ONE },
	{ label: "Unassigned", value: NUMBERS.MINUS_ONE },
];
export const FORM_ELEMENT_TYPE = {
	INPUT: "INPUT",
	CHECKBOX: "CHECKBOX",
	RADIO: "RADIO",
	SELECT: "SELECT",
	DATE: "DATE",
	TIME: "TIME",
	DATE_TIME: "DATE_TIME",
	MULTI_SELECT: "MULTI_SELECT",
	TEXTAREA: "TEXTAREA",
	UPLOAD_FILE: "UPLOAD_FILE",
	SEARCH: "SEARCH",
	INPUT_RIGHT_TICK: "INPUT_RIGHT_TICK",
	NUMERIC_TEXTBOX: "NUMERIC_TEXTBOX",
	BUTTON: 'BUTTON',
	RICH_TEXT_EDITOR: "RICH_TEXT_EDITOR",
  UPLOAD_FILE_V2: "UPLOAD_FILE_V2",
};

export const ERROR_CODES = {
	UNAUTHORISED: 401,
	NOT_ACCESS: 422,
	NOT_FOUND: 404,
	SUCCESS: 200,
	FOUND: 302,
	MOVED_PERMANENTLY: 301,
	BAD_REQUEST: 400,
	ERROR: 500,
};

export const CHALLAN_MODE_TYPES = {
	OFFLINE: "OFFLINE",
	ONLINE: "ONLINE",
	VIRTUAL_COURT: "VIRTUAL_COURT",
};

export const CHALLAN_MODES = [
	{ label: "Offline", value: CHALLAN_MODE_TYPES.OFFLINE },
	{ label: "Online", value: CHALLAN_MODE_TYPES.ONLINE },
	{
		label: "Virtual Court",
		value: CHALLAN_MODE_TYPES.VIRTUAL_COURT,
	},
];

export const OFFENSE_TYPES = [
	{ label: "Overspeeding", value: "Overspeeding" },
	{ label: "Light Jumping", value: "Light Jumping" },
	{ label: "No Seat Belt", value: "No Seat Belt" },
	{ label: "No license", value: "No license" },
];

export const CHALLAN_REQUEST_STATUSES = {
	CREATED: "CREATED",
	ASSIGNED: "ASSIGNED",
	RESOLVED: "RESOLVED",
	REOPENED: "REOPENED",
};

export const CHALLAN_ITEM_STATUSES = {
	CREATED: "CREATED",
	SUBMITTED: "SUBMITTED",
	ASSIGNED: "ASSIGNED",
	REJECTED: "REJECTED",
	AGENT_ASSIGNED: "AGENT_ASSIGNED",
	PAID: "PAID",
	UNABLE_TO_FULFILL: "UNABLE_TO_FULFILL",
	PAYMENT_RAISED: "PAYMENT_RAISED",
	RAISE_REJECTED: "RAISE_REJECTED",
	RAISE_ACCEPTED: "RAISE_ACCEPTED",
	RAISE_AMENDED: "RAISE_AMENDED",
	VIRTUAL_COURT_PROCEEDING: "VC_PROCEEDING",
};
export const vahanStatusMapping = {
	0: "Challan Pending",
	1: "Pending",
	2: "Pending",
	3: "Completed",
	4: "Pending",
  5:"Challan Completed",
};
export const hypoMapping = {
	0: "No",
	1: "Yes",
	2: "N/A",
};
export const prStatusMapping = {
	0: "Initiated",
	1: "Draft",
	2: "Pending",
	3: "Approved",
	4: "Declined",
};
export const getServiceHistoryStatus = (
	isServiceHistoryAvailable,
	statusServiceCheck
) => {
	let result = {
		status: "",
		text: "",
		css: "",
	};
	if (statusServiceCheck === 3) {
		if (isServiceHistoryAvailable === 0) {
			result = {
				status: "Not Available",
				text: "Service history docs are not available for this car",
				css: "reject",
			};
		} else if (isServiceHistoryAvailable === 1) {
			result = {
				status: "Available",
				text: "Service history docs available for this car",
				css: "success",
			};
		}
	} else if ([1, 2, 4].includes(statusServiceCheck)) {
		result = serviceStatusMapping[statusServiceCheck];
	}
	return result;
};
export const serviceStatusMapping = {
	// 0: {
	//   status: "Not Applicable",
	//   text: "Service history is not required for this car",
	//   css: "grey",
	// },
	1: {
		status: "Pending",
		text: "Service history docs are pending for this car",
		css: "pending",
	},
	2: {
		status: "Draft",
		text: "Service history docs are draft for this car",
		css: "pending",
	},
	4: {
		status: "Re-requested",
		text: "Service history docs are re-requested for this car",
		css: "grey",
	},
};
export const partyPeshimapping = {
	1: {
		title: "Party Peshi is not required for this car.",
		subtitle: "The RTO doesn't have Parti-peshi in any case.",
	},
	2: {
		title: "",
		subtitle: "The RTO has Parti-peshi only in case of hypothecation.",
	},
	3: {
		title: "Party Peshi is required",
		subtitle: "The RTO has Parti-peshi in every case.",
	},
};
export const INIT_PAGE_PARAMS = {
	page: 0,
	size: 10,
};
export const intialLoanDropdownValue = {
	value: "",
	label: "Add loan details",
};
export const FF_PAGE_TYPES = {
	FINDERS: "finders",
	DISPOSE: "disposers",
	COMMON: "challans",
	SERVICE_HISTORY: "services",
	VERIFICATION: "verification",
	ADMINSTRATOR_VIEW: "adminstratorView",
	DISPOSE_VIEW: "view_disposers",
	LEADS: "leads",
	INSPECTION: 'inspection',
	CJ_FOOTPRINT: 'cj-footprint',
	INCENTIVE_ADMIN_PAGE: "admin",
	INCENTIVE_PANEL: "incentives",
};

export const dealerPayoutOpenIssueStatuses = [
	"RAISED",
	"APPROVAL_PENDING",
	"PAYMENT_PENDING",
	"PAYMENT_IN_PROGRESS",
];
export const dealerPayoutPaymentStatuses = [
	"PAYMENT_PENDING",
	"PAYMENT_IN_PROGRESS",
];
export const API_HEADER_CONSTANTS = {
	X_AUTH_KEY: "x-auth-key",
};

export const insuranceColorMapping = {
	expired: "reject",
	na: "grey",
	comp: "success",
	third_party: "success",
	others: "success",
	zero_dep: "success",
};
export const rcTypeColorMapping = {
	original: "success",
	duplicate: "pending",
	lost: "reject",
	photocopy: "pending",
	na: "grey",
};
export const rcConditionColorMapping = {
	normal: "success",
	damaged: "pending",
	chip_broken: "pending",
	detail_mismatch: "reject",
	others: "success",
	faded: "pending",
};
export const bankStatusMapping = (status) => {
	let value = "";
	let css = "";
	switch (status) {
		case null:
			value = "Pending";
			css = "grey";
			break;
		case 0:
			value = "System Failed";
			css = "reject";
			break;
		case 2:
			value = "Name Mismatch";
			css = "reject";
			break;
		case 3:
			value = "Verified";
			css = "success";
			break;
		case 4:
			value = "Partially Matched";
			css = "pending";
			break;
		default:
			value = "Bank Failed";
			css = "reject";
			break;
	}
	return { value, css };
};

export const latestPrStatuses = [null, "null", undefined];
export const initiatePrStatuses = ["DECLINED", null, "null", undefined];
export const continuePrStatuses = ["INITIALIZED", "SUBMITTED", "APPROVED"];
export const kycDocsStatuses = ["APPROVED", "PENDING"];
export const kycDocsList = [
	"AADHAR_CARD",
	"PAN_CARD_COPY",
	"PAN_CARD_COMPANY_COPY",
];
export const Lms_roles = [
	"RM",
	"RA",
	"CJ",
	"CORP",
	"HOSTESSES",
	"RA_PLL",
	"RM_PLL",
	"TM",
	"ADMIN",
];

export const getTransactionStatusMapping = (status) => {
	switch (status) {
		case null:
		case -1:
			return null;
		case 2:
			return "Full Mismatch";
		case 3:
			return "Fully Verified";
		case 4:
			return "Partial Mismatch";
		default:
			return "Transaction Failed";
	}
};

export const transactionStatuses = [2, 3, 4];
export const WORD_LIMIT_TRUNCATE = {
	LIMIT: 50,
};

export const SORT_DIRECTION_KEYS = {
	DESC: "desc",
	ASC: "asc",
};

export const INITIAL_PAGINATION_PARAMS = {
	pageNumber: 0,
	pageSize: 10,
};

export const VERIFICATION_SPREADSHEET_URL =
	"https://docs.google.com/spreadsheets/d/1eRPmHDPIs6ar9HCaQpZtPKgz8Cr0TsBfP6nxBzOyyi4/edit#gid=1941969572";

export const FILE_VIEW_ACTIONS = {
	DELETE: "delete",
};

export const NAME_ERROR = {
	NAME_FORMAT_ERROR: "Please avoid using special characters and numbers",
	FULL_NAME_ERROR: "Enter full name as per PAN",
};
