import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import React, { useState, useEffect } from 'react';
import FormCardLayout from '../../../../operations/components/layout-form-card';
import BackButton from '../back-button';
import { REFERRAL_DETAIL_FORM_DATA, REFERRAL_DETAIL_KEYS } from './viewModal';
import { onInputChange } from '../../utils/helper';
import { InspectionFeedService } from '../../../../../services';
import { showToastMessage } from '../../../../../utils/utils';

function ReferralForm({ handleBack }) {

    const [data, setData] = useState(REFERRAL_DETAIL_FORM_DATA);

    const handleReset = () => {
        setData(REFERRAL_DETAIL_FORM_DATA);
    }

    const handleConfirm = () => {

        let formData = {}

        Object.keys(data).map((key) => {
            formData[key] = data[key].value ? data[key].value : null;
        })

        InspectionFeedService.addReferralContent({ ...formData })
            .then((resp) => {
                showToastMessage('Added Referral Content Successfully')
                handleBack();
            })
            .catch((err) => {
                showToastMessage('Error Adding Referral Content', false)
                console.log("Error Updating the Status: ", err);
            })
    }

    const handleEditorChange = (event, key) => {
        const newData = {
            ...data,
            [key]: {
                ...data[key],
                value: event.html,
            },
        };
        setData(newData);
    };

    return (
        <GridLayout
            gap={{
                rows: "15px",
                cols: 2,
            }}>
            <GridLayoutItem row={1}>
                <BackButton handleBack={handleBack} text={"Back"} />
                <FormCardLayout
                    headerTitle={"Referral Content"}
                    formData={data}
                    handleChange={(e) => onInputChange(e, data, setData)}
                    isShowCTA={true}
                    handleReset={handleReset}
                    handleConfirm={handleConfirm}
                    handleEditorChange={handleEditorChange}
                />
            </GridLayoutItem>
        </GridLayout>
    )
}
export default ReferralForm;

