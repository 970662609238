import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  getLoginUserEmail,
  showToastMessage,
} from "../../../../../utils/utils";
import { FormNumericTextBox } from "../../../../../components";
import { useDispatch } from "react-redux";
import { DealerProcureService } from "../../../../../services";
import { DEALER_PROCUREMENT } from "../../../../../store/types";

const ApproveRejectAction = (props) => {
  const { updatedFlagData, titleComponent, fetchPayouts } = props || {};
  const [isApproveConfirm, setIsApproveConfirm] = useState(false);
  const [isRejectConfirm, setIsRejectConfirm] = useState(false);
  const [approvedAmount, setApprovedAmount] = useState();
  const dispatch = useDispatch();
  const handleApprove = () => {
    const { adminComment, id, leadId } = updatedFlagData || {};
    const payload = {
      comment: adminComment.value,
      amount: approvedAmount?.toString(),
      email: getLoginUserEmail(),
    };
    const toastMessage = "Payment approved successfully";
    const action = "APPROVE";
    dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_START });
    DealerProcureService.updateFlagSection(
      { leadId: leadId.value, issueId: id.value },
      action,
      payload
    )
      .then(() => {
        showToastMessage(toastMessage, true);
        fetchPayouts(leadId.value);
        toggleApprove();
      })
      .catch((error) => {
        showToastMessage(error?.message, false);
      })
      .finally(() => {
        dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_END });
      });
  };
  const toggleApprove = () => {
    setIsApproveConfirm(!isApproveConfirm);
    setApprovedAmount();
  };
  const toggleReject = () => {
    setIsRejectConfirm(!isRejectConfirm);
  };
  const handleReject = () => {
    const { adminComment, id, leadId } = updatedFlagData || {};
    const payload = {
      comment: adminComment.value,
      email: getLoginUserEmail(),
    };
    const action = "REJECT";
    const toastMessage = "Payment rejected";
    dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_START });
    DealerProcureService.updateFlagSection(
      { leadId: leadId.value, issueId: id.value },
      action,
      payload
    )
      .then(() => {
        showToastMessage(toastMessage, true);
        fetchPayouts(leadId.value);
        toggleReject();
      })
      .catch((error) => {
        showToastMessage(error?.message, false);
      })
      .finally(() => {
        dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_END });
      });
  };

  return (
    <div className="k-form-buttons">
      <div>
        <Button
          themeColor={"primary"}
          size="large"
          disabled={!updatedFlagData?.adminComment?.value?.trim()}
          className="k-mx-6"
          onClick={toggleApprove}
        >
          APPROVE
        </Button>
        <Button
          themeColor={"secondary"}
          size="large"
          disabled={!updatedFlagData?.adminComment?.value?.trim()}
          onClick={toggleReject}
        >
          REJECT
        </Button>
      </div>
      {isApproveConfirm && (
        <Dialog
          title={titleComponent({ title: "Approve grievance" })}
          onClose={toggleApprove}
          width={350}
          contentStyle={{ width: "auto" }}
        >
          <FormNumericTextBox
            id={"amount"}
            key={"amount"}
            label="Approved amount"
            value={approvedAmount || ""}
            min={0}
            type="number"
            onChange={(e) => setApprovedAmount(e.target.value)}
            spinners={false}
            format=""
            valid={approvedAmount > 0}
          />
          <p className="k-w-fit">
            Please confirm that you’ve assessed the issue and have decided to
            approve the concern.
          </p>
          <DialogActionsBar>
            <Button
              onClick={handleApprove}
              rounded="full"
              themeColor={"primary"}
              disabled={!approvedAmount}
            >
              Confirm
            </Button>
            <Button
              onClick={toggleApprove}
              themeColor={"secondary"}
              rounded="full"
            >
              Go back
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {isRejectConfirm && (
        <Dialog
          title={titleComponent({ title: "Reject grievance" })}
          onClose={toggleReject}
          width={350}
          contentStyle={{ width: "auto" }}
        >
          <p className="k-w-fit">
            Please confirm that you’ve assessed the issue and have decided to
            reject the payment.
          </p>
          <DialogActionsBar>
            <Button
              onClick={handleReject}
              rounded="full"
              themeColor={"primary"}
            >
              Confirm
            </Button>
            <Button
              onClick={toggleReject}
              themeColor={"secondary"}
              rounded="full"
            >
              Go back
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};
export default ApproveRejectAction;
