import { Button } from "@progress/kendo-react-buttons";
import classes from "../styles/agentSyncButton.module.css";

const AgentSyncButton = ({ syncAgentList }) => {
    return (
        <Button
            iconClass="k-icon k-font-icon k-i-reload k-i-refresh"
            themeColor="primary"
            onClick={syncAgentList}
            className={classes.agentSyncBtn}
        >
            Sync Agents
        </Button>
    );
};

export default AgentSyncButton;
