import React from "react";
import styles from './Notification.module.css';

export default function Notification({notificationMessage, type='info'}) {
  return (
    <>
      {
        <div
          className={`${styles.notification} ${styles[type]}`}
          dangerouslySetInnerHTML={{ __html: notificationMessage }}
        ></div>
      }
    </>
  );
}