import { OPS_CHALLAN } from "../types";

const INITIAL_STATE = {
	isLoading: false,
	currentIndex: 0,
	challanOrders: {
		data: [],
		totalCount: 0,
		numberOfElements: 0,
	},
	error: {},
	channelSource: "",
	searchQuery: "",
	paginationParams: {},
	userAssignments: 0,
	filters: {},
};

const OpsChallan = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case OPS_CHALLAN.FETCH_ORDERS_START:
			return {
				...state,
				isLoading: true,
			};
		case OPS_CHALLAN.FETCH_ORDERS_END:
			return {
				...state,
				isLoading: false,
			};
		case OPS_CHALLAN.FETCH_ORDERS_SUCCESS:
			return {
				...state,
				challanOrders: {
					data: action.payload.data,
					totalCount: action.payload.totalElements,
					numberOfElements: action.payload.numberOfElements,
				},
				error: {},
			};

		case OPS_CHALLAN.UPDATE_REQUEST_ORDERS:
			const newData = [...state.challanOrders.data];
			const index = newData.findIndex(
				(item) => item.idChallanRequest === action.payload.idChallanRequest
			);
			newData[index] = action.payload;
			return {
				...state,
				currentIndex: index,
				challanOrders: {
					...state.challanOrders,
					data: newData,
				},
				error: {},
			};
		case OPS_CHALLAN.FETCH_ORDERS_FAILURE:
			return {
				...state,
				challanOrders: {},
				error: action.payload,
			};
		case OPS_CHALLAN.SET_PAGINATION_PARAMS:
			return {
				...state,
				paginationParams: action.payload,
			};
		case OPS_CHALLAN.SET_CHALLAN_SOURCE:
			return {
				...state,
				channelSource: action.payload,
			};
		case OPS_CHALLAN.SET_CHALLAN_REQUEST_SEARCH_QUERY:
			return {
				...state,
				searchQuery: action.payload,
			};
		case OPS_CHALLAN.SET_ASSIGNMENT_TYPE:
			return {
				...state,
				userAssignments: action.payload,
			};
		case OPS_CHALLAN.SET_FINDER_FILTERS:
			return {
				...state,
				filters: action.payload,
			};
		case OPS_CHALLAN.REFRESH_FINDER_CHALLAN_REQUEST:
			const currentItemIndex = state.challanOrders.data.findIndex(
				(item) => item.idChallanRequest === action?.payload?.idChallanRequest
			);
			const currentItem = state.challanOrders.data[currentItemIndex];
			let updateCurrentItem = { ...currentItem };
			Object.keys(currentItem).map((key) => {
				updateCurrentItem[key] = action?.payload[key];
			});
			state.challanOrders.data[currentItemIndex] = updateCurrentItem;
			return {
				...state,
			};
		default:
			return state;
	}
};
export default OpsChallan;
