
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './control-center.scss'
import ListView from './list-view'
import UploadView from './upload-view'
import { getAllLogs, getDropDownData, getSampleFile } from '../store/actions/controlCenter';
import { showToastMessage } from '../common/utils/utils';
import { INITIAL_TABLE_SETTINGS } from '../common/constants/table.constants';

const ControlCenter = () => {

  const dispatch = useDispatch()
  const [logsData, setLogsData] = useState([])
  const [dropDownData, setDropDownData] = useState()
  const isInitialLoad = useRef(true)
  const [isLoading, setIsLoading] = useState(false);
  const [refreshPageData, setRefreshPageData] = useState(false);

  const { userInfo } = useSelector((state) => state.allCars)

  const [page, setPage] = useState(INITIAL_TABLE_SETTINGS);

  useEffect(() => {

    if (isInitialLoad.current) {
      dispatch(getDropDownData()).then((resp) => {
        setDropDownData(([{ label: 'Select Purpose', value: '' }, ...resp]))
        isInitialLoad.current = false;
      }
      ).catch((err) => {
        showToastMessage(err?.error || err?.message, false);
      })
    }

    setIsLoading(true);

    dispatch(getAllLogs({ page: page.skip, size: page.take, userId: userInfo?.id })).then((resp) => {
      setIsLoading(false);
      setLogsData(resp);
    }
    ).catch((err) => {
      showToastMessage(err?.error || err?.message, false, 1500);
    })



  }, [page, refreshPageData])

  return (
    <div className="page-details page-details-layout control-center-container">
      <UploadView dropDownData={dropDownData} setRefreshPageData={setRefreshPageData} refreshPageData={refreshPageData} />
      <ListView isLoading={isLoading} logsData={logsData} page={page} setPage={setPage} />

    </div>
  )
}

export default ControlCenter