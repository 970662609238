import FileLoader from "./components/file-loader";

export const CJ_REFERRAL_KEYS = {
    EMAIL: "cjEmail",
    STORE: "storeName",
    NAME: "referralName",
    NUMBER: "referralPhone",
    URL: "cvLocation",
    STATUS: "status",
    EMPLOYEE_ID: 'joinerEmployeeCode',
    DATE: 'createdAt'
};

export const REFERRAL_COLUMN_MAPPER = {
    [CJ_REFERRAL_KEYS.EMAIL]: {
        field: CJ_REFERRAL_KEYS.EMAIL,
        label: "CJ Email ID",
        width: '240px'
    },
    [CJ_REFERRAL_KEYS.STORE]: {
        field: CJ_REFERRAL_KEYS.STORE,
        label: "Store",
    },
    [CJ_REFERRAL_KEYS.DATE]: {
        field: CJ_REFERRAL_KEYS.DATE,
        label: "Referral Date",
        cell: (props) => {

            const date = new Date(props?.dataItem?.[CJ_REFERRAL_KEYS.DATE]);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = date.getFullYear();
            const formattedDate = `${day}/${month}/${year}`;

            return (
                <td className="k-table-td">
                    {props?.dataItem?.[CJ_REFERRAL_KEYS.DATE] && formattedDate}
                </td>
            )
        },
    },
    [CJ_REFERRAL_KEYS.NAME]: {
        field: CJ_REFERRAL_KEYS.NAME,
        label: "Referee Name",
    },
    [CJ_REFERRAL_KEYS.NUMBER]: {
        field: CJ_REFERRAL_KEYS.NUMBER,
        label: "Referee Number",
    },
    [CJ_REFERRAL_KEYS.URL]: {
        field: CJ_REFERRAL_KEYS.URL,
        label: "Referee CV",
        cell: FileLoader
    },
    [CJ_REFERRAL_KEYS.STATUS]: {
        field: CJ_REFERRAL_KEYS.STATUS,
        label: "Status",
    },
    [CJ_REFERRAL_KEYS.EMPLOYEE_ID]: {
        field: CJ_REFERRAL_KEYS.EMPLOYEE_ID,
        label: "Employee ID",
    },
}