import axios from "axios";
import { getSessionToken } from "@descope/react-sdk";
import {
  ERROR_CODES,
  API_HEADER_CONSTANTS,
} from "../utils/constants/values.constants";
import { getLocationServiceMetaData } from "../utils/constants/url.constants";
import { onBackLogin } from "../utils/utils";
const DEBUG = process.env.NODE_ENV === "development";
const apiMetaData = getLocationServiceMetaData();
const api = axios.create({
  baseURL: apiMetaData.url,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    config.headers[API_HEADER_CONSTANTS.X_AUTH_KEY] = `${getSessionToken()}`;
    return config;
  },
  (error) => {
    if (DEBUG) {
      console.error("✉️ ", error);
    }
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (DEBUG) {
      console.info("📩 ", response);
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (DEBUG) {
        console.error("Error: ", error.response.data);
      }
      if (
        error.response &&
        error.response.status === ERROR_CODES.UNAUTHORISED &&
        !window.location.pathname.includes("login")
      ) {
        localStorage.clear();
        onBackLogin();
      }
      return Promise.reject(error.response.data);
    }
    if (DEBUG) {
      console.error("📩 ", error);
    }
    return Promise.reject(error.message);
  }
);

export default api;
