import "../common/assets/styles/common.scss";
import "./car-details.scss";
import AppTable from "../common/components/app-table"
import { FIELDS_KEY, TABLE_LABELS } from "../common/constants/table.constants"
import { useEffect, useState } from "react";
import { currencyFormat } from "../common/utils/utils";
import AppChips from "../common/components/app-chips";
const NegoView = ({ carDetails }) => {
	const tableValues = {
		winningPrice: '',
		status: [],
		yardDetails: [],
		ocbPrice: ''
	}
	const [data, setData] = useState([tableValues]);

	useEffect(() => {
		let negoDetails = carDetails?.negoDetails;

		if (negoDetails) {
			tableValues.winningPrice = currencyFormat(negoDetails?.winningPrice);
			tableValues.status = negoDetails?.status ? negoDetails?.status : [];
			tableValues.yardDetails = negoDetails?.yardDetails ? negoDetails?.yardDetails : [];
			tableValues.ocbPrice = currencyFormat(negoDetails?.ocbPrice);
			setData([{ ...tableValues }]);
		}
	}, [carDetails])


	const SafeCells = safeCellProps => {
		const event = {
			dataItem: safeCellProps.data
		};
		return (
			// eslint-disable-next-line no-use-before-define
			<td onClick={() => navigateTo(event)}>
				{safeCellProps.children}
			</td>
		);
	};
	const tableColumns = [
		{
			field: FIELDS_KEY.FinalNegoPrice,
			title: TABLE_LABELS.FinalNegoPrice,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem.winningPrice}</div>
				</SafeCells>
			),
		},
		{
			field: FIELDS_KEY.OcbPrice,
			title: TABLE_LABELS.OcbPrice,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem.ocbPrice ? dataItem.ocbPrice : ''}</div>
				</SafeCells>
			),
		},
		{
			field: FIELDS_KEY.Status,
			title: TABLE_LABELS.Status,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<AppChips
						className="primary-chips-disabled"
						data={[...dataItem.status]}
					/>
				</SafeCells>
			),
		}, {
			field: FIELDS_KEY.YardDetails,
			title: TABLE_LABELS.YardDetails,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div className="yard-details">{dataItem.yardDetails}</div>
				</SafeCells>
			),
		}];

	return (
		<>
			<div className="current-bids-grid app-table-view-sm app-table">
				<AppTable
					data={data}
					headerData={[
						...tableColumns
					]}
					isHoverable={false}
					totalCount={data.length}
					handlePageChange={() => { }}
					pageable={false}
				/>
			</div>
		</>
	);
};
export default NegoView;
