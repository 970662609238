import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import React from 'react'

const FileView = (props) => {
  return (
    <GridLayout style={{display:"flex", alignItems:"center", justifyContent:"space-between", border:"1px solid #D3DDE8", padding:"10px"}}>
        <GridLayoutItem>
            <span className='k-icon k-i-pdf m-1' />
            Doc Uploaded
        </GridLayoutItem>
        <GridLayoutItem>
            <span className='k-icon k-i-eye m-1' style={{cursor:"pointer", color:"#0059A3"}} onClick={props?.handleFileView}></span>
            {props?.isDelete && <span className='k-icon k-i-close m-1' style={{cursor:"pointer", color:"#0059A3"}} onClick={props?.handleFileDelete}></span>}
        </GridLayoutItem>
  </GridLayout>
  )
}

export default FileView;
