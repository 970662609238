import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { KendoCard, KendoListView } from "../../../../../../components";
import { getBadge } from "../../pr-automation/viewModel";
import {
  NUMBERS,
  getServiceHistoryStatus,
} from "../../../../../../utils/constants/values.constants";
import AllServiceHistoryDetails from "./all-service-history-details";
import { Button } from "@progress/kendo-react-buttons";

const ServiceHistoryCard = () => {
  const prDetails = useSelector((state) => state.prAutomation);
  const { backgroundCheck, isServiceHistoryEligible } = prDetails || {};
  const { isServiceHistoryAvailable, statusServiceCheck } =
    backgroundCheck || {};
  const [isServiceHistoryDetails, setIsServiceHistoryDetails] = useState(false);
  const [status, setStatus] = useState();
  useEffect(() => {
    setStatus(
      getServiceHistoryStatus(isServiceHistoryAvailable, statusServiceCheck)
    );
  }, [isServiceHistoryAvailable, statusServiceCheck]);
  const getServiceHistoryEligible = () => {
    let serviceStatus = "";
    let css = "";
    let text = "";
    if (statusServiceCheck === 0) {
      if (isServiceHistoryEligible) {
        serviceStatus = "Eligible";
        css = "success";
        text = "Service history is eligible for this car";
      } else {
        serviceStatus = "Not Applicable";
        css = "grey";
        text = "Service history is not required for this car";
      }
    } else {
      serviceStatus = status?.status;
      css = status?.css;
      text = status?.text;
    }
    return { serviceStatus, css, text };
  };
  const serviceHistoryDetails = [
    {
      prefix: getBadge(
        getServiceHistoryEligible().serviceStatus,
        getServiceHistoryEligible().css
      ),
      details: getServiceHistoryEligible().text,
    },
  ];
  const renderHeaderTitle = () => {
    return (
      <div className="justify-between">
        <div className="k-font-bold k-font-size-xl">Service History</div>
        {statusServiceCheck === NUMBERS.THREE && (
          <Button
            themeColor="primary"
            onClick={() => setIsServiceHistoryDetails(!isServiceHistoryDetails)}
            size={"small"}
            fillMode={"flat"}
          >
            View all service history details
          </Button>
        )}

        {isServiceHistoryDetails && (
          <AllServiceHistoryDetails
            setIsServiceHistoryDetails={setIsServiceHistoryDetails}
          />
        )}
      </div>
    );
  };
  return (
    <KendoCard
      cardStyle={{
        background: "#F0F2F5",
      }}
      headerTitle={renderHeaderTitle()}
      details={
        getServiceHistoryEligible()?.serviceStatus ? (
          <KendoCard
            details={<KendoListView data={serviceHistoryDetails} />}
            cardStyle={{ marginTop: "0px" }}
          />
        ) : (
          "NA"
        )
      }
    />
  );
};

export default ServiceHistoryCard;
