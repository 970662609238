import { useCallback, useState, useEffect, useMemo, useContext } from "react";
import {
    ADMIN_ASSIGNED_LEAD_COLS,
    ASSIGNED_LEAD_COLS,
    ASSIGNED_LEAD_KEY,
    All_LEAD_KEY,
    LEADS_TYPE,
    UNASSIGNED_LEAD_KEY,
    UNASSIGNGED_LEADS_COLS,
} from "../viewModel";
import { isFilterFieldPresent } from "../../../../utils/utils";
import {
    ColumnMenuCheckboxFilter,
    ColumnMenuDateRangeFilter,
} from "../../../../components/custom-grid-filters";
import EditLead from "../components/EditLead";
import ActionButton from "../components/ActionButton";
import AssignleadDropDown from "../components/AssignLeadDropDown";
import CallButton from "../components/CallButton";
import dayjs from "dayjs";
import {
    filterGridHelper,
    getAdminUnassignedLeadsCols,
    getDifferenceBetweenTime,
    mapDifferenceToColor,
} from "../utils/helper";
import { AgentListContext } from "../context/AgentListContext";
import CustomGridSort from "../../../../components/custom-grid-sort";
import ShareLink from "../components/ShareLink";

const getVisibleColumns = (type, subType) => {
    switch (type) {
        case LEADS_TYPE.UNASSIGNED:
            return UNASSIGNGED_LEADS_COLS;
        case LEADS_TYPE.ASSIGNED:
            return ASSIGNED_LEAD_COLS(subType);
        case LEADS_TYPE.ADMIN_ASSIGNED:
            return ADMIN_ASSIGNED_LEAD_COLS(subType);
        case LEADS_TYPE.ADMIN_UNASSIGNED:
            return getAdminUnassignedLeadsCols();
        default:
            return {};
    }
};

export const useVisibleColumns = ({
    gridFilters,
    setGridFilters,
    filterParams,
    setFilterParams,
    setPaginationParams,
    type,
    setLeadAssignment,
    refreshLeads,
    filterData,
    subType,
}) => {
    const [visibleColumns, setVisibleColumns] = useState(() =>
        getVisibleColumns(type, subType)
    );

    const agentList = useContext(AgentListContext);

    const handleDateRangeFilterChange = (key, dateRange) => {
        const { start, end } = dateRange;
        if (start && end) {
            const formattedStart = dayjs(start).format("YYYY-MM-DD");
            const formattedEnd = dayjs(end).format("YYYY-MM-DD");
            let filter;

            switch (key) {
                case ASSIGNED_LEAD_KEY.CREATED_AT:
                    filter = {
                        createdAtFrom: formattedStart,
                        createdAtTo: formattedEnd,
                    };
                    break;
                case ASSIGNED_LEAD_KEY.UPDATED_AT:
                    filter = {
                        updatedAtFrom: formattedStart,
                        updatedAtTo: formattedEnd,
                    };
                    break;
                default:
                    filter = {};
            }
            setFilterParams((prev) => ({ ...prev, ...filter }));
        } else {
            const tempFilterParams = { ...filterParams };

            switch (key) {
                case ASSIGNED_LEAD_KEY.CREATED_AT:
                    delete tempFilterParams["createdAtFrom"];
                    delete tempFilterParams["createdAtTo"];
                    break;
                case ASSIGNED_LEAD_KEY.UPDATED_AT:
                    delete tempFilterParams["updatedAtFrom"];
                    delete tempFilterParams["updatedAtTo"];
                    break;
            }

            setFilterParams(tempFilterParams);
        }
    };

    const handleGridSort = (key, type) => {
        const tempFilterParams = { ...filterParams };
        let filter = {};
        switch (type) {
            case "asc":
                filter = { [key]: false };
                setFilterParams((prev) => ({ ...prev, ...filter }));
                break;
            case "desc":
                filter = { [key]: true };
                setFilterParams((prev) => ({ ...prev, ...filter }));
                break;
            default:
                delete tempFilterParams[key];
                setFilterParams(tempFilterParams);
        }
    };

    const handleFilterButtonClick = useCallback(
        (key, e, data) => {
            let _filterParamsData = filterGridHelper(
                key,
                e,
                data,
                filterParams,
                setGridFilters,
                setPaginationParams
            );
            setFilterParams(_filterParamsData);
        },
        [filterParams, setFilterParams]
    );

    const handleAssignLead = (item) => {
        setLeadAssignment({ ...item });
    };

    const additionalColumns = useMemo(
        () => ({
            [LEADS_TYPE.ASSIGNED]: (columns) => ({
                [ASSIGNED_LEAD_KEY.PHONE_NUMBER]: {
                    ...columns[ASSIGNED_LEAD_KEY.PHONE_NUMBER],
                    width: "100px",
                },
                [ASSIGNED_LEAD_KEY.LOAN_AMOUNT]: {
                    ...columns[ASSIGNED_LEAD_KEY.LOAN_AMOUNT],
                    width: "110px",
                },
                [ASSIGNED_LEAD_KEY.APPLICATION_ID]: {
                    ...columns[ASSIGNED_LEAD_KEY.APPLICATION_ID],
                    cell: (props) => (
                        <td className="k-table-td">
                            <EditLead
                                dataItem={props?.dataItem}
                                subTabKey={subType}
                            />
                        </td>
                    ),
                    width: "110px",
                },
                [ASSIGNED_LEAD_KEY.STATUS]: {
                    ...columns[ASSIGNED_LEAD_KEY.STATUS],
                    headerClassName: isFilterFieldPresent(
                        gridFilters?.filters,
                        ASSIGNED_LEAD_KEY.STATUS
                    )
                        ? "selected-filter"
                        : "de-selected-filter",
                    columnMenu: (props) => (
                        <ColumnMenuCheckboxFilter
                            {...props}
                            filterData={filterData}
                            userAssignments={null}
                            onFilterButtonClick={handleFilterButtonClick}
                            columnMapKey={ASSIGNED_LEAD_KEY.STATUS}
                            filter={gridFilters}
                        />
                    ),
                    cell: (props) => (
                        <td className="k-table-td k-text-capitalize">
                            {props?.dataItem?.status
                                ? props?.dataItem?.status
                                      .toLowerCase()
                                      .split("_")
                                      .join(" ")
                                : "-"}
                        </td>
                    ),
                    width: "200px",
                },
                [ASSIGNED_LEAD_KEY.SHARE_LINK]: {
                    field: ASSIGNED_LEAD_KEY.SHARE_LINK,
                    label: "Actions",
                    cell: (props) => (
                        <td className="k-table-td">
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "4px",
                                    flexWrap: "wrap",
                                }}
                            >
                                <ShareLink
                                    leadId={props?.dataItem?.leadId}
                                    token={props?.dataItem?.token}
                                    shareCount={props?.dataItem?.linkShareCount}
                                />
                                <CallButton
                                    leadDetails={props?.dataItem}
                                    subTabKey={subType}
                                />
                            </div>
                        </td>
                    ),
                },
                [ASSIGNED_LEAD_KEY.CREATED_AT]: {
                    ...columns[ASSIGNED_LEAD_KEY.CREATED_AT],
                    cell: (props) => {
                        const { createdAt = "" } = props?.dataItem;
                        return (
                            <td>
                                {createdAt && dayjs(createdAt).isValid()
                                    ? dayjs(createdAt).format(
                                          "DD-MM-YYYY hh:mm A"
                                      )
                                    : "-"}
                            </td>
                        );
                    },
                    columnMenu: (props) => (
                        <div>
                            <CustomGridSort
                                {...props}
                                sort={filterParams.isCreatedSort}
                                columnMapKey={"isCreatedSort"}
                                onSortChange={(key, value) => {
                                    props?.onCloseMenu?.();
                                    handleGridSort(key, value);
                                }}
                            />
                            <ColumnMenuDateRangeFilter
                                {...props}
                                columnMapKey={ASSIGNED_LEAD_KEY.CREATED_AT}
                                filter={{
                                    start: filterParams?.createdAtFrom,
                                    end: filterParams?.createdAtTo,
                                }}
                                onFilterChange={handleDateRangeFilterChange}
                            />
                        </div>
                    ),
                },
                [ASSIGNED_LEAD_KEY.UPDATED_AT]: {
                    ...columns[ASSIGNED_LEAD_KEY.UPDATED_AT],
                    cell: (props) => {
                        const { updatedAt = "" } = props?.dataItem;
                        return (
                            <td>
                                {updatedAt && dayjs(updatedAt).isValid()
                                    ? dayjs(updatedAt).format(
                                          "DD-MM-YYYY hh:mm A"
                                      )
                                    : "-"}
                            </td>
                        );
                    },
                    columnMenu: (props) => (
                        <div>
                            <CustomGridSort
                                {...props}
                                sort={filterParams.isUpdatedSort}
                                columnMapKey={"isUpdatedSort"}
                                onSortChange={(key, value) => {
                                    props?.onCloseMenu?.();
                                    handleGridSort(key, value);
                                }}
                            />
                            <ColumnMenuDateRangeFilter
                                {...props}
                                columnMapKey={ASSIGNED_LEAD_KEY.UPDATED_AT}
                                filter={{
                                    start: filterParams?.updatedAtFrom,
                                    end: filterParams?.updatedAtTo,
                                }}
                                onFilterChange={handleDateRangeFilterChange}
                            />
                        </div>
                    ),
                },
                [ASSIGNED_LEAD_KEY.CITY]: {
                    ...columns[ASSIGNED_LEAD_KEY.CITY],
                    headerClassName: isFilterFieldPresent(
                        gridFilters?.filters,
                        ASSIGNED_LEAD_KEY.CITY
                    )
                        ? "selected-filter"
                        : "de-selected-filter",
                    columnMenu: (props) => (
                        <ColumnMenuCheckboxFilter
                            {...props}
                            filterData={filterData}
                            userAssignments={null}
                            onFilterButtonClick={handleFilterButtonClick}
                            columnMapKey={ASSIGNED_LEAD_KEY.CITY}
                            filter={gridFilters}
                        />
                    ),
                },
                [ASSIGNED_LEAD_KEY.TIME_LEFT]: {
                    ...columns[ASSIGNED_LEAD_KEY.TIME_LEFT],
                    cell: (props) => {
                        const diff = getDifferenceBetweenTime(
                            props?.dataItem?.nextCallAssignedAt
                        );
                        if (!diff) {
                            return <td></td>;
                        }
                        const { hours, mins, totalMinutes } = diff;
                        let timeString = "";
                        if (hours) {
                            timeString = `${hours} hours ${mins} minutes`;
                        } else {
                            timeString = `${mins} minutes`;
                        }

                        const rowClass = mapDifferenceToColor(
                            totalMinutes,
                            props?.dataItem?.lastCallStatus
                        );
                        return <td className={rowClass}>{timeString}</td>;
                    },
                },
                [ASSIGNED_LEAD_KEY.INTERNAL_STATUS]: {
                    ...columns[ASSIGNED_LEAD_KEY.INTERNAL_STATUS],
                    headerClassName: isFilterFieldPresent(
                        gridFilters?.filters,
                        ASSIGNED_LEAD_KEY.INTERNAL_STATUS
                    )
                        ? "selected-filter"
                        : "de-selected-filter",
                    columnMenu: (props) => (
                        <ColumnMenuCheckboxFilter
                            {...props}
                            filterData={filterData}
                            userAssignments={null}
                            onFilterButtonClick={handleFilterButtonClick}
                            columnMapKey={ASSIGNED_LEAD_KEY.INTERNAL_STATUS}
                            filter={gridFilters}
                        />
                    ),
                    cell: (props) => (
                        <td className="k-table-td k-text-capitalize">
                            {props?.dataItem?.dispositionStatus
                                ? props?.dataItem?.dispositionStatus
                                      .toLowerCase()
                                      .split("_")
                                      .join(" ")
                                : "-"}
                        </td>
                    ),
                },
                [ASSIGNED_LEAD_KEY.LAST_CALL_STATUS]: {
                    ...columns[ASSIGNED_LEAD_KEY.LAST_CALL_STATUS],
                    headerClassName: isFilterFieldPresent(
                        gridFilters?.filters,
                        ASSIGNED_LEAD_KEY.LAST_CALL_STATUS
                    )
                        ? "selected-filter"
                        : "de-selected-filter",
                    columnMenu: (props) => (
                        <ColumnMenuCheckboxFilter
                            {...props}
                            filterData={filterData}
                            userAssignments={null}
                            onFilterButtonClick={handleFilterButtonClick}
                            columnMapKey={ASSIGNED_LEAD_KEY.LAST_CALL_STATUS}
                            filter={gridFilters}
                        />
                    ),
                    cell: (props) => (
                        <td className="k-table-td k-text-capitalize">
                            {props?.dataItem?.lastCallStatus
                                ? props?.dataItem?.lastCallStatus
                                      .toLowerCase()
                                      .split("_")
                                      .join(" ")
                                : "-"}
                        </td>
                    ),
                },
                [ASSIGNED_LEAD_KEY.LEAD_SOURCE]: {
                    ...columns[ASSIGNED_LEAD_KEY.LEAD_SOURCE],
                    columnMenu: (props) => (
                        <ColumnMenuCheckboxFilter
                            {...props}
                            filterData={filterData}
                            userAssignments={null}
                            onFilterButtonClick={handleFilterButtonClick}
                            columnMapKey={ASSIGNED_LEAD_KEY.LEAD_SOURCE}
                            filter={gridFilters}
                        />
                    ),
                    cell: (props) => (
                        <td>{props?.dataItem?.leadSource || "-"}</td>
                    ),
                },
            }),
            [LEADS_TYPE.ADMIN_ASSIGNED]: (columns) => ({
                [All_LEAD_KEY.PHONE_NUMBER]: {
                    ...columns[All_LEAD_KEY.PHONE_NUMBER],
                    width: "120px",
                },
                [All_LEAD_KEY.LOAN_AMOUNT]: {
                    ...columns[All_LEAD_KEY.LOAN_AMOUNT],
                    width: "110px",
                },
                [All_LEAD_KEY.APPLICATION_ID]: {
                    ...columns[All_LEAD_KEY.APPLICATION_ID],
                    cell: (props) => (
                        <td className="k-table-td">
                            <EditLead
                                dataItem={props?.dataItem}
                                subTabKey={subType}
                            />
                        </td>
                    ),
                    width: "110px",
                },
                [All_LEAD_KEY.ASSIGNMENT]: {
                    ...columns[All_LEAD_KEY.ASSIGNMENT],
                    cell: (props) => (
                        <AssignleadDropDown
                            key={props?.dataItem?.leadId}
                            leadId={props?.dataItem?.leadId || ""}
                            refreshLeads={refreshLeads}
                            selectedAgent={props?.dataItem?.assignedTo}
                            agentList={agentList}
                        />
                    ),
                    width: "160px",
                },
                [ASSIGNED_LEAD_KEY.STATUS]: {
                    ...columns[ASSIGNED_LEAD_KEY.STATUS],
                    headerClassName: isFilterFieldPresent(
                        gridFilters?.filters,
                        ASSIGNED_LEAD_KEY.STATUS
                    )
                        ? "selected-filter"
                        : "de-selected-filter",
                    columnMenu: (props) => (
                        <ColumnMenuCheckboxFilter
                            {...props}
                            filterData={filterData}
                            userAssignments={null}
                            onFilterButtonClick={handleFilterButtonClick}
                            columnMapKey={ASSIGNED_LEAD_KEY.STATUS}
                            filter={gridFilters}
                        />
                    ),
                    cell: (props) => (
                        <td className="k-table-td k-text-capitalize">
                            {props?.dataItem?.status
                                ? props?.dataItem?.status
                                      .toLowerCase()
                                      .split("_")
                                      .join(" ")
                                : "-"}
                        </td>
                    ),
                    width: "200px",
                },
                [ASSIGNED_LEAD_KEY.CREATED_AT]: {
                    ...columns[ASSIGNED_LEAD_KEY.CREATED_AT],
                    cell: (props) => {
                        const { createdAt = "" } = props?.dataItem;
                        return (
                            <td>
                                {createdAt && dayjs(createdAt).isValid()
                                    ? dayjs(createdAt).format(
                                          "DD-MM-YYYY hh:mm A"
                                      )
                                    : "-"}
                            </td>
                        );
                    },
                    columnMenu: (props) => (
                        <div>
                            <CustomGridSort
                                {...props}
                                sort={filterParams.isCreatedSort}
                                columnMapKey={"isCreatedSort"}
                                onSortChange={(key, value) => {
                                    props?.onCloseMenu?.();
                                    handleGridSort(key, value);
                                }}
                            />
                            <ColumnMenuDateRangeFilter
                                {...props}
                                columnMapKey={ASSIGNED_LEAD_KEY.CREATED_AT}
                                filter={{
                                    start: filterParams?.createdAtFrom,
                                    end: filterParams?.createdAtTo,
                                }}
                                onFilterChange={handleDateRangeFilterChange}
                            />
                        </div>
                    ),
                },
                [ASSIGNED_LEAD_KEY.UPDATED_AT]: {
                    ...columns[ASSIGNED_LEAD_KEY.UPDATED_AT],
                    cell: (props) => {
                        const { updatedAt = "" } = props?.dataItem;
                        return (
                            <td>
                                {updatedAt && dayjs(updatedAt).isValid()
                                    ? dayjs(updatedAt).format(
                                          "DD-MM-YYYY hh:mm A"
                                      )
                                    : "-"}
                            </td>
                        );
                    },
                    columnMenu: (props) => (
                        <div>
                            <CustomGridSort
                                {...props}
                                sort={filterParams.isUpdatedSort}
                                columnMapKey={"isUpdatedSort"}
                                onSortChange={(key, value) => {
                                    props?.onCloseMenu?.();
                                    handleGridSort(key, value);
                                }}
                            />
                            <ColumnMenuDateRangeFilter
                                {...props}
                                columnMapKey={ASSIGNED_LEAD_KEY.UPDATED_AT}
                                filter={{
                                    start: filterParams?.updatedAtFrom,
                                    end: filterParams?.updatedAtTo,
                                }}
                                onFilterChange={handleDateRangeFilterChange}
                            />
                        </div>
                    ),
                },
                [ASSIGNED_LEAD_KEY.CITY]: {
                    ...columns[ASSIGNED_LEAD_KEY.CITY],
                    headerClassName: isFilterFieldPresent(
                        gridFilters?.filters,
                        ASSIGNED_LEAD_KEY.CITY
                    )
                        ? "selected-filter"
                        : "de-selected-filter",
                    columnMenu: (props) => (
                        <ColumnMenuCheckboxFilter
                            {...props}
                            filterData={filterData}
                            userAssignments={null}
                            onFilterButtonClick={handleFilterButtonClick}
                            columnMapKey={ASSIGNED_LEAD_KEY.CITY}
                            filter={gridFilters}
                        />
                    ),
                },
                [ASSIGNED_LEAD_KEY.TIME_LEFT]: {
                    ...columns[ASSIGNED_LEAD_KEY.TIME_LEFT],
                    cell: (props) => {
                        const diff = getDifferenceBetweenTime(
                            props?.dataItem?.nextCallAssignedAt
                        );
                        if (!diff) {
                            return <td></td>;
                        }
                        const { hours, mins, totalMinutes } = diff;
                        let timeString = "";
                        if (hours) {
                            timeString = `${hours} hours ${mins} minutes`;
                        } else {
                            timeString = `${mins} minutes`;
                        }
                        const rowClass = mapDifferenceToColor(
                            totalMinutes,
                            props?.dataItem?.lastCallStatus
                        );
                        return <td className={rowClass}>{timeString}</td>;
                    },
                },
                [ASSIGNED_LEAD_KEY.INTERNAL_STATUS]: {
                    ...columns[ASSIGNED_LEAD_KEY.INTERNAL_STATUS],
                    headerClassName: isFilterFieldPresent(
                        gridFilters?.filters,
                        ASSIGNED_LEAD_KEY.INTERNAL_STATUS
                    )
                        ? "selected-filter"
                        : "de-selected-filter",
                    columnMenu: (props) => (
                        <ColumnMenuCheckboxFilter
                            {...props}
                            filterData={filterData}
                            userAssignments={null}
                            onFilterButtonClick={handleFilterButtonClick}
                            columnMapKey={ASSIGNED_LEAD_KEY.INTERNAL_STATUS}
                            filter={gridFilters}
                        />
                    ),
                    cell: (props) => (
                        <td className="k-table-td k-text-capitalize">
                            {props?.dataItem?.dispositionStatus
                                ? props?.dataItem?.dispositionStatus
                                      .toLowerCase()
                                      .split("_")
                                      .join(" ")
                                : "-"}
                        </td>
                    ),
                },
                [ASSIGNED_LEAD_KEY.LAST_CALL_STATUS]: {
                    ...columns[ASSIGNED_LEAD_KEY.LAST_CALL_STATUS],
                    headerClassName: isFilterFieldPresent(
                        gridFilters?.filters,
                        ASSIGNED_LEAD_KEY.LAST_CALL_STATUS
                    )
                        ? "selected-filter"
                        : "de-selected-filter",
                    columnMenu: (props) => (
                        <ColumnMenuCheckboxFilter
                            {...props}
                            filterData={filterData}
                            userAssignments={null}
                            onFilterButtonClick={handleFilterButtonClick}
                            columnMapKey={ASSIGNED_LEAD_KEY.LAST_CALL_STATUS}
                            filter={gridFilters}
                        />
                    ),
                    cell: (props) => (
                        <td className="k-table-td k-text-capitalize">
                            {props?.dataItem?.lastCallStatus
                                ? props?.dataItem?.lastCallStatus
                                      .toLowerCase()
                                      .split("_")
                                      .join(" ")
                                : "-"}
                        </td>
                    ),
                },
                [ASSIGNED_LEAD_KEY.RESTORE]: {
                    ...columns[ASSIGNED_LEAD_KEY.RESTORE],
                    cell: (props) => (
                        <AssignleadDropDown
                            leadId={props?.dataItem?.leadId || ""}
                            refreshLeads={refreshLeads}
                            agentList={agentList}
                            type="restore"
                        />
                    ),
                },
                [ASSIGNED_LEAD_KEY.LEAD_SOURCE]: {
                    ...columns[ASSIGNED_LEAD_KEY.LEAD_SOURCE],
                    columnMenu: (props) => (
                        <ColumnMenuCheckboxFilter
                            {...props}
                            filterData={filterData}
                            userAssignments={null}
                            onFilterButtonClick={handleFilterButtonClick}
                            columnMapKey={ASSIGNED_LEAD_KEY.LEAD_SOURCE}
                            filter={gridFilters}
                        />
                    ),
                    cell: (props) => (
                        <td>{props?.dataItem?.leadSource || "-"}</td>
                    ),
                },
            }),
            [LEADS_TYPE.ADMIN_UNASSIGNED]: (columns) => ({
                [All_LEAD_KEY.PHONE_NUMBER]: {
                    ...columns[All_LEAD_KEY.PHONE_NUMBER],
                    width: "120px",
                },
                [All_LEAD_KEY.LOAN_AMOUNT]: {
                    ...columns[All_LEAD_KEY.LOAN_AMOUNT],
                    width: "110px",
                },
                [All_LEAD_KEY.APPLICATION_ID]: {
                    ...columns[All_LEAD_KEY.APPLICATION_ID],
                    cell: (props) => (
                        <td className="k-table-td">
                            <EditLead
                                dataItem={props?.dataItem}
                                subTabKey={subType}
                            />
                        </td>
                    ),
                    width: "110px",
                },
                [All_LEAD_KEY.ASSIGNMENT]: {
                    ...columns[All_LEAD_KEY.ASSIGNMENT],
                    cell: (props) => (
                        <AssignleadDropDown
                            leadId={props?.dataItem?.leadId || ""}
                            refreshLeads={refreshLeads}
                            selectedAgent={props?.dataItem?.assignedTo}
                            agentList={agentList}
                        />
                    ),
                    width: "200px",
                },
                [ASSIGNED_LEAD_KEY.CREATED_AT]: {
                    ...columns[ASSIGNED_LEAD_KEY.CREATED_AT],
                    cell: (props) => {
                        const { createdAt = "" } = props?.dataItem;
                        return (
                            <td>
                                {createdAt && dayjs(createdAt).isValid()
                                    ? dayjs(createdAt).format(
                                          "DD-MM-YYYY hh:mm A"
                                      )
                                    : "-"}
                            </td>
                        );
                    },
                    width: "200px",
                    columnMenu: (props) => (
                        <div>
                            <CustomGridSort
                                {...props}
                                sort={filterParams.isCreatedSort}
                                columnMapKey={"isCreatedSort"}
                                onSortChange={(key, value) => {
                                    props?.onCloseMenu?.();
                                    handleGridSort(key, value);
                                }}
                            />
                            <ColumnMenuDateRangeFilter
                                {...props}
                                columnMapKey={ASSIGNED_LEAD_KEY.CREATED_AT}
                                filter={{
                                    start: filterParams?.createdAtFrom,
                                    end: filterParams?.createdAtTo,
                                }}
                                onFilterChange={handleDateRangeFilterChange}
                            />
                        </div>
                    ),
                },
                [ASSIGNED_LEAD_KEY.UPDATED_AT]: {
                    ...columns[ASSIGNED_LEAD_KEY.UPDATED_AT],
                    cell: (props) => {
                        const { updatedAt = "" } = props?.dataItem;
                        return (
                            <td>
                                {updatedAt && dayjs(updatedAt).isValid()
                                    ? dayjs(updatedAt).format(
                                          "DD-MM-YYYY hh:mm A"
                                      )
                                    : "-"}
                            </td>
                        );
                    },
                    width: "200px",
                    columnMenu: (props) => (
                        <div>
                            <CustomGridSort
                                {...props}
                                sort={filterParams.isUpdatedSort}
                                columnMapKey={"isUpdatedSort"}
                                onSortChange={(key, value) => {
                                    props?.onCloseMenu?.();
                                    handleGridSort(key, value);
                                }}
                            />
                            <ColumnMenuDateRangeFilter
                                {...props}
                                columnMapKey={ASSIGNED_LEAD_KEY.UPDATED_AT}
                                filter={{
                                    start: filterParams?.updatedAtFrom,
                                    end: filterParams?.updatedAtTo,
                                }}
                                onFilterChange={handleDateRangeFilterChange}
                            />
                        </div>
                    ),
                },
                [ASSIGNED_LEAD_KEY.STATUS]: {
                    ...columns[ASSIGNED_LEAD_KEY.STATUS],
                    headerClassName: isFilterFieldPresent(
                        gridFilters?.filters,
                        ASSIGNED_LEAD_KEY.STATUS
                    )
                        ? "selected-filter"
                        : "de-selected-filter",
                    columnMenu: (props) => (
                        <ColumnMenuCheckboxFilter
                            {...props}
                            filterData={filterData}
                            userAssignments={null}
                            onFilterButtonClick={handleFilterButtonClick}
                            columnMapKey={ASSIGNED_LEAD_KEY.STATUS}
                            filter={gridFilters}
                        />
                    ),
                    cell: (props) => (
                        <td className="k-table-td k-text-capitalize">
                            {props?.dataItem?.status
                                ? props?.dataItem?.status
                                      .toLowerCase()
                                      .split("_")
                                      .join(" ")
                                : "-"}
                        </td>
                    ),
                },
                [ASSIGNED_LEAD_KEY.CITY]: {
                    ...columns[ASSIGNED_LEAD_KEY.CITY],
                    headerClassName: isFilterFieldPresent(
                        gridFilters?.filters,
                        ASSIGNED_LEAD_KEY.CITY
                    )
                        ? "selected-filter"
                        : "de-selected-filter",
                    columnMenu: (props) => (
                        <ColumnMenuCheckboxFilter
                            {...props}
                            filterData={filterData}
                            userAssignments={null}
                            onFilterButtonClick={handleFilterButtonClick}
                            columnMapKey={ASSIGNED_LEAD_KEY.CITY}
                            filter={gridFilters}
                        />
                    ),
                },
                [ASSIGNED_LEAD_KEY.LEAD_SOURCE]: {
                    ...columns[ASSIGNED_LEAD_KEY.LEAD_SOURCE],
                    columnMenu: (props) => (
                        <ColumnMenuCheckboxFilter
                            {...props}
                            filterData={filterData}
                            userAssignments={null}
                            onFilterButtonClick={handleFilterButtonClick}
                            columnMapKey={ASSIGNED_LEAD_KEY.LEAD_SOURCE}
                            filter={gridFilters}
                        />
                    ),
                    cell: (props) => (
                        <td>{props?.dataItem?.leadSource || "-"}</td>
                    ),
                },
            }),
            [LEADS_TYPE.UNASSIGNED]: (columns) => ({
                [UNASSIGNED_LEAD_KEY.ASSIGNMENT]: {
                    ...columns[UNASSIGNED_LEAD_KEY.ASSIGNMENT],
                    cell: (props) => (
                        <ActionButton
                            label="Assign to me"
                            handleClick={() =>
                                handleAssignLead(props?.dataItem)
                            }
                        />
                    ),
                },
            }),
        }),
        [gridFilters, refreshLeads, agentList, filterData]
    );

    useEffect(() => {
        setVisibleColumns((columns) => ({
            ...columns,
            ...additionalColumns[type](columns),
        }));
    }, [gridFilters, handleFilterButtonClick, agentList, filterData]);

    return visibleColumns;
};
