import {
    GridLayout,
    GridLayoutItem,
    StackLayout,
} from "@progress/kendo-react-layout";
import { KendoCard } from "../../../../components";
import { headerStyle, style } from "../constants";
import DocumentCard from "./DocumentCard";
import { useMemo } from "react";
import { Button } from "@progress/kendo-react-buttons";
import classes from "../styles/DocumentsForm.module.css";

const getGridConfig = (index) => {
    return {
        row: Math.ceil(index.toFixed(2) / 2),
        col: index % 2 === 0 ? 2 : index % 2,
    };
};

const DocumentsForm = ({
    data,
    token,
    onUpload,
    onDocumentsFormSubmit,
    isSubmitDisabled,
    onVerify,
}) => {
    const sortedDocuments = useMemo(
        () =>
            data
                ? Object.values(data).sort(
                      (value1, value2) => value1?.rank - value2?.rank
                  )
                : [],
        [data]
    );

    return (
        <KendoCard
            headerTitle="Documents"
            cardStyle={style}
            headerStyle={headerStyle}
            details={
                <StackLayout orientation="vertical">
                    <GridLayout
                        gap={{
                            rows: 5,
                            cols: 10,
                        }}
                        style={{
                            gridTemplateColumns: "repeat(2,minmax(0,1fr))",
                        }}
                    >
                        {sortedDocuments.map((document, index) => {
                            const { row, col } = getGridConfig(index + 1);
                            return (
                                <GridLayoutItem
                                    row={row}
                                    col={col}
                                    key={document?.docCode}
                                >
                                    <DocumentCard
                                        docData={document}
                                        token={token}
                                        onUpload={onUpload}
                                        onVerify={onVerify}
                                    />
                                </GridLayoutItem>
                            );
                        })}
                    </GridLayout>
                    <Button
                        onClick={onDocumentsFormSubmit}
                        themeColor="primary"
                        className={`m-1 ${classes.docSubmitBtn}`}
                        disabled={isSubmitDisabled}
                    >
                        Submit
                    </Button>
                </StackLayout>
            }
        />
    );
};

export default DocumentsForm;
