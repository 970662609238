import { ComboBox } from "@progress/kendo-react-dropdowns"
import styles from "./styles.module.css"
import AppChips from "../components/app-chips"
import Modal from "../../components/modal"

const ViewSegmentation = ({ data, setIsOpen, isOpen, filterChange }) => {
  return (
    <Modal isOpen={isOpen} close={() => setIsOpen(false)} heading="Entity Ids">
      <ComboBox
        popupSettings={{
          className: styles['entity-id-popup']
        }}
        data={data.map((ele) => ele.entityCode)}
        filterable
        onFilterChange={filterChange}
        placeholder="Start typing to search or select from dropdown"
      />
      <div className={styles["app-chips"]}>
        <AppChips data={data} />
      </div>
    </Modal>
  )
}
export default ViewSegmentation