import {
	service_history_column_keys,
	AgeingCell,
	CreatedDateCell,
	ClosureDateCell,
	StatusCell,
} from "../service-history/viewModels";
const getYears = () => {
	const currentYear = new Date().getFullYear();
	let years = [];
	for (let i = 0; i <= 15; i++) {
		const yearString = (currentYear - i).toString();
		years.push({ label: yearString, value: yearString });
	}
	return years;
};
export const DD_DATA_SOURCES = {
	[service_history_column_keys.STATUS]: [
		{ label: "Assigned", value: "ASSIGNED" },
		{ label: "Unassigned", value: "UNASSIGNED" },
		{ label: "Fulfilled", value: "FULFILLED" },
		{ label: "Unable to Fulfill", value: "UNABLE_TO_FULFILL" },
	],
	[service_history_column_keys.CAR_DETAILS]: getYears(),
	[service_history_column_keys.SOURCE]: [
		{ label: "VAS", value: "VAS" },
		{ label: "LEADMON", value: "LeadMon" },
		{ label: "DEALER", value: "DEALER" },
		{ label: "CUSTOMER", value: "CUSTOMER" },
		{ label: "INTERNAL OCB", value: "INTERNAL_OCB"}
	],
};
export const service_history_columns = {
	[service_history_column_keys.APPOINTMENT_ORDER_NUMBER]: {
		field: service_history_column_keys.APPOINTMENT_ORDER_NUMBER,
		label: "Appt. Id",
	},
	[service_history_column_keys.REG_NUMBER]: {
		field: service_history_column_keys.REG_NUMBER,
		label: "Reg No.",
	},
	[service_history_column_keys.STATUS]: {
		field: service_history_column_keys.STATUS,
		label: "Status",
		cell: StatusCell,
	},
	[service_history_column_keys.CAR_DETAILS]: {
		field: service_history_column_keys.CAR_DETAILS,
		label: "Car Details",
	},
	[service_history_column_keys.SOURCE]: {
		field: service_history_column_keys.SOURCE,
		label: "Source",
	},

	[service_history_column_keys.AGENT]: {
		field: service_history_column_keys.AGENT,
		label: "Agent Assigned",
	},
	[service_history_column_keys.AGEING]: {
		field: service_history_column_keys.AGEING,
		cell: AgeingCell,
		label: "Ageing",
	},
	[service_history_column_keys.CREATION_DATE]: {
		field: service_history_column_keys.CREATION_DATE,
		cell: CreatedDateCell,
		label: "Creation Date",
	},
	[service_history_column_keys.CLOSURE_DATE]: {
		field: service_history_column_keys.CLOSURE_DATE,
		cell: ClosureDateCell,
		label: "Closure Date",
	},
};
