export const lead_mon_keys = {
  LM_ID: "id",
  MAKE_ID: "makeId",
  MAKER_NAME: "makeName",
  MODEL_ID: "modelId",
  MODEL_NAME: "modelName",
  LOCATION_ID: "locationId",
  LOCATION_NAME: "locationName",
  CITY_ID: "cityId",
  CITY_NAME: "cityName",
  YEAR_ID: "year_id",
  YEAR: "year",
  FROM_YEAR: "from_year",
  TO_YEAR: "to_year",
  STATUS: "status",
  IS_LEAD_MON: 'isLeadMon',
  IS_LUXURY_CAR: 'isLuxuryCar',
  PARTNER_ID: 'partnerId',
  TP_THRESHOLD: 'tpThreshold'
};

export const lead_mon_columns = {
  // [lead_mon_keys.LM_ID]: {
  //   field: lead_mon_keys.LM_ID,
  //   label: "ID",
  // },
  [lead_mon_keys.CITY_NAME]: {
    keyid: lead_mon_keys.CITY_ID,
    field: lead_mon_keys.CITY_NAME,
    label: "City",
    filterable: true,
  },
  [lead_mon_keys.LOCATION_NAME]: {
    keyid: lead_mon_keys.LOCATION_ID,
    field: lead_mon_keys.LOCATION_NAME,
    label: "Store",
    filterable: true,
  },
  [lead_mon_keys.MAKER_NAME]: {
    keyid: lead_mon_keys.MAKE_ID,
    field: lead_mon_keys.MAKER_NAME,
    label: "Make",
    filterable: true,
  },
  [lead_mon_keys.MODEL_NAME]: {
    keyid: lead_mon_keys.MODEL_ID,
    field: lead_mon_keys.MODEL_NAME,
    label: "Model",
    filterable: true,
  },
  [lead_mon_keys.YEAR]: {
    keyid: lead_mon_keys.YEAR,
    field: lead_mon_keys.YEAR,
    label: "YEAR",
    filterable: true,
  },
  [lead_mon_keys.STATUS]: {
    keyid: lead_mon_keys.STATUS,
    field: lead_mon_keys.STATUS,
    filterable: true,
    label: "STATUS",
  },
  [lead_mon_keys.IS_LEAD_MON]: {
    keyid: lead_mon_keys.IS_LEAD_MON,
    field: lead_mon_keys.IS_LEAD_MON,
    // filterable: true,
    label: "Is Lead Mon",
  },
  [lead_mon_keys.IS_LUXURY_CAR]: {
    keyid: lead_mon_keys.IS_LUXURY_CAR,
    field: lead_mon_keys.IS_LUXURY_CAR,
    // filterable: true,
    label: "Is Luxury Car",
  },
  [lead_mon_keys.PARTNER_ID]: {
    keyid: lead_mon_keys.PARTNER_ID,
    field: lead_mon_keys.PARTNER_ID,
    label: "Partner ID",
  },
  [lead_mon_keys.TP_THRESHOLD]: {
    keyid: lead_mon_keys.TP_THRESHOLD,
    field: lead_mon_keys.TP_THRESHOLD,
    label: "TP Threshold",
  },
};
export const getDisplayedCoulmns = (columns) => {
  return Object.keys(columns);
};

export const removeFilterItems = (array, itemKeys = []) => {
  return array.filter((item) => !itemKeys.includes(item.field));
};
