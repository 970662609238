import TabStrips from "../components/tab-strips";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import { dateDifferenceInDays } from "../../../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
      fetchBusinessApprovals,
      fetchC24QuoteDetails,
      fetchHypoBanks,
      fetchOrderDetails,
      fetchServiceHistoryEligibility,
      fetchVahanMismatch,
} from "../../../../../store/actions/pr-automation";
import dayjs from "dayjs";
import {
      NUMBERS,
      latestPrStatuses
} from "../../../../../utils/constants/values.constants";
import { validateAppointmentId } from "../../../../../store/actions/lms";
import SelectOwnershipType from "../components/select-ownership-type";
import styles from '../assets/styles/pr.styles.module.css';
import NegoHeader from "../components/header";
import { Loader } from "@progress/kendo-react-indicators";

const PRAutomation = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const appointmentIdToken = props?.match?.params?.appointmentId;
    const prDetails = useSelector((state) => state.prAutomation);
    const lms = useSelector((state) => state.lms);
    const { orderData, backgroundCheck, virtualNumber ,crmLeadDetails} = prDetails || {};
    const { purchase: { purchaseStatus } = {} } = orderData || {};
    const { appointmentId } = lms || {};
    const {
        make = "",
        model = "",
        ownerNameOnRc = "",
        variant = "",
        manufacturingYear = "",
        fuelType = "",
        lastInspectionDate = "",
        secondaryPhoneNumber = "",
        isHypothecation
    } = orderData || {};
    const { statusVahanCheck } = backgroundCheck || {};
    const [loanAmount, setLoanAmount] = useState(0);
    const [initiatePr, setInitiatePr] = useState(false);
    const [isApprovals, setIsApprovals] = useState(false);
    const [isViewAllPr, setIsViewAllPr] = useState(false);
    const copyCheckPointText = () => {
        navigator.clipboard.writeText(appointmentId);
    }


    useEffect(() => {
        dispatch(fetchHypoBanks());
    }, []);

    useEffect(() => {
        dispatch(validateAppointmentId(appointmentIdToken));
    }, [appointmentIdToken]);

    useEffect(() => {
        if (appointmentId) {
            dispatch(fetchBusinessApprovals(appointmentId));
        }
    }, [appointmentId]);

    useEffect(() => {
        if (appointmentId) {
            dispatch(fetchC24QuoteDetails({ appointmentId, }));
        }
    }, [appointmentId]);

    useEffect(() => {
        if (appointmentId && [0,1,3,5].includes(statusVahanCheck)) {
            dispatch(fetchVahanMismatch(appointmentId));
        }
    }, [appointmentId, statusVahanCheck]);

    useEffect(() => {
        if (appointmentId) {
            dispatch(fetchOrderDetails({ appointmentId, documentCodes: ["AADHAR_CARD", "PAN_CARD_COPY"], }));
        }
    }, [appointmentId]);

    useEffect(() => {
        if (appointmentId) {
            dispatch(fetchServiceHistoryEligibility(appointmentId));
        }
    }, [appointmentId]);

  //*** to be released later
  const toggleInitiatePr = () => {
    setInitiatePr(!initiatePr);
  };
  const toggleApprovals = () => {
    setIsApprovals(!isApprovals);
    if (!isApprovals) {
      history.push(
        `/retail/lms/negoview/${appointmentIdToken}/${make}/${model}`
      );
    }
  };
  //*** to be released later

  if(lms?.error?.code == 403){
    return(
      <div className={styles.unathorized}>
        <h3 className={styles.unathorized_403}>403</h3>
        <h3 className={styles.unathorized_h3}>Access Denied</h3>
        <p className={styles.unathorized_p}>You donot have permission to access this page.</p>
      </div>
    )
  }

  return (
    <>
      <div className={`k-mr-2 ${styles.pr_automation}`}>
        {
          crmLeadDetails?
            <NegoHeader 
              appointmentId={appointmentId}
              crmDetails={crmLeadDetails}
              MMVY={make || model ? make + " " + model : "NA"}
              variant={(manufacturingYear && manufacturingYear + " | ") +(variant && variant + " | ") +fuelType || ""}
              ownerNameOnRc={ownerNameOnRc ? ownerNameOnRc : undefined}
            />
          :
            <div className={styles.nego_header_loader}>
              <Loader/>
            </div>
        }
        <GridLayout
          gap={{rows: 10,cols: 10,}}
          cols={[{width: "0%",},{width: "100%",},]}
        >
          <GridLayoutItem row={1} col={2} rowSpan={3} >
            <TabStrips
              loanAmount={loanAmount}
              setLoanAmount={setLoanAmount}
              appointmentId={appointmentId}
              isHypothecation={isHypothecation}
              toggleInitiatePr={toggleInitiatePr}
              toggleApprovals={toggleApprovals}
              MMVY={make || model ? make + " " + model : "NA"}
              variant={(manufacturingYear && manufacturingYear + " | ") +(variant && variant + " | ") +fuelType || ""}
              lastInspectionDate={
                lastInspectionDate? 
                "Day " +Math.round(dateDifferenceInDays(lastInspectionDate)) + " | " +dayjs(lastInspectionDate).format("DD-MMM-YYYY")
								: "NA"
              }
              ownerNameOnRc={ownerNameOnRc ? ownerNameOnRc : undefined}
              virtualNumber={virtualNumber ? virtualNumber : undefined}
              secondaryPhoneNumber={secondaryPhoneNumber}
              isViewAllPr={isViewAllPr}
              setIsViewAllPr={setIsViewAllPr}
              isLatestPrVisible={purchaseStatus && !latestPrStatuses.includes(purchaseStatus)}
            />
          </GridLayoutItem>
        </GridLayout>
        {initiatePr && (
          <SelectOwnershipType
            toggleInitiatePr={toggleInitiatePr}
            appointmentId={appointmentId}
            fetchOrderDetails={fetchOrderDetails}
          />
        )}
      </div>
    </>
  );
};
export default PRAutomation;
