import React, { useEffect, useState } from "react";
// import qs from "qs";
import {
  FormCheckbox,
  // FormDatePicker,
  FormComboBox,
  FormInput,
  FormMultiSelect,
  FormSearchInput,
} from "../../../../components";
import { Button } from "@progress/kendo-react-buttons";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { FormData, NCM_ELEMENT, NCM_FIELD_LABELS, NCM_VALIDATION_MESSAGE } from "./viewModels";
import { DealerProcureService, NewCarService } from "../../../../services";
import { FORM_ELEMENT_TYPE } from "../../../../utils/constants/values.constants";
import { centre_column_keys } from "../centre-detail/viewModal";
import { DEALER_PROCUREMENT } from "../../../../store/types";
import { showToastMessage } from "../../../../utils/utils";
import styles from './createCenter.module.css';

const CreateCenter = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.Centre) || {};
  const history = useHistory();
  const search = window.location.href;
  const isUpdate = search.includes("updateDealerCenter");

  const { dealerId, centreId } = useParams();

  const [formData, setFormData] = useState(FormData);
  const [cityAreaData, setCityAreaData] = useState([]);
  const [centre, setCentre] = useState({
    centreName: "",
    centreAddress: "",
    pincode: "",
    cityId: "",
    cityName: "",
    area: "",
    latitude: "",
    longitude: "",
    status: "Active",
    ucmName: "",
    ucmPhone: "",
    ucmEmail: "",
    raPhone: "",
    raName: "",
    raEmail: "",
    makeId: "",
    isNewCarEnabled: true,
    dealerNcmList: []
  });

  const [isPincodeValid, setIsPincodeValid] = useState(null);

  useEffect(() => {
    if (!!isUpdate) {
      if (!!data?.length) {
        const selectedData = data.filter(
          (item) => item?.dealerCentreId == centreId
        );
        setCentre({ ...selectedData, dealerNcmList: [] });
      }

      dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_START });
      DealerProcureService.fetchCentreById(dealerId, centreId)
        .then((res) => {
          let tempDealerNcmList = res?.data?.ncmList;
          let area = res?.data?.area;
          let areaArray = area?.split(',');
          setCentre({
            ...res?.data, dealerNcmList: transformArray(tempDealerNcmList, false), area: area ? transformAreaArray(areaArray) : null
          })
          setFormData((prevState) => {
            const ncmElement = prevState[centre_column_keys.NCM]
            return {
              ...prevState,
              [centre_column_keys.NCM]: {
                ...ncmElement,
                elements: transformArray(tempDealerNcmList, true)
              }
            }
          })
        }
        )
        .catch((err) => console.log({ err }))
        .finally(() => {
          dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_END });
        });
    }
  }, []);

  const transformAreaArray = (areaList) => {
    return areaList.length > 0 ? areaList.map((item) => ({ label: item, value: item })) : null
  }

  const transformArray = (arr, isForm = false) => {
    return arr.map(({ name, email, mobile, ncmId, isNotificationEnabled }) => {
      const transformedItem = {
        ncmName: { value: name, valid: true },
        ncmEmail: { value: email, valid: true },
        ncmMobile: { value: mobile, valid: true },
        isNotificationEnabled: { value: isNotificationEnabled || false, valid: true },
        ncmId: { value: ncmId, valid: true }
      };

      if (!isForm) {
        transformedItem.ncmId = { value: ncmId, valid: true };
      }

      return transformedItem;
    });
  };

  const handleChange = (e) => {
    const { id } =
      e?.target?.element?.id === "pincode"
        ? e?.target?.element
        : e?.target?.props || {};
    const { value } = e || {};
    setCentre({
      ...centre,
      [id]: e?.target?.props?.elementtype === "SELECT" ? (value?.value ?? (id == 'area' ? "" : null)) : value,
      ...(id == 'cityId' && { area: "" })
    });
  };

  const transformNcm = (ncm, centre, isUpdate) => {
    const {
      ncmName: { value: name },
      ncmMobile: { value: mobile },
      ncmEmail: { value: email },
      isNotificationEnabled: { value: isNotificationEnabled },
      ncmId
    } = ncm;

    const transformedNcm = { name, mobile, email, isNotificationEnabled };

    if (isUpdate) {
      if (ncmId?.value) {
        transformedNcm.ncmId = ncmId.value;
      } else {
        const { cityId, dealerId, dealerCentreId, makeId } = centre;
        transformedNcm.cityId = cityId;
        transformedNcm.dealerId = dealerId;
        transformedNcm.dealerCentreId = dealerCentreId;
        transformedNcm.makeId = makeId;
      }
    }

    return transformedNcm;
  };

  const transformNcmList = (centre, isUpdate) => {
    return centre.dealerNcmList.map(ncm => transformNcm(ncm, centre, isUpdate));
  };


  const handleCreate = () => {

    if (!validateNCMs()) {
      return;
    }

    dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_START });
    const transformedNcmList = transformNcmList(centre, isUpdate);
    const ncmList = centre.dealerNcmList?.length > 0 ? transformedNcmList : [];
    const areaList = Array.isArray(centre?.area) ? centre?.area?.map((item) => item?.value) : [];
    getCreateUpdate()(dealerId, { ...centre, dealerNcmList: [], ncmList, area: areaList?.length > 0 ? areaList?.join(',') : "" })
      .then(() => {
        showToastMessage(
          `${isUpdate ? "Centre Updated" : "Centre Created"}`,
          true
        );
        history.push(`/dealer/fulfillments/centerdetailview/${dealerId}`);
      })
      .catch((error) => {
        showToastMessage("Error", false);
      })
      .finally(() => {
        dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_END });
      });
  };



  const getCreateUpdate = () => {
    return isUpdate
      ? DealerProcureService.updateCentre
      : DealerProcureService.createCentre;
  };

  const handleOnBack = () => {
    history.push(`/dealer/fulfillments/centerdetailview/${dealerId}`);
  };

  const handleSearch = () => {
    DealerProcureService.fetchCityDetails(centre?.pincode)
      .then((res) => {
        const { data } = res || {};
        setCentre({
          ...centre,
          [centre_column_keys.CITY_ID]: data?.cityId,
          [centre_column_keys.CITY_NAME]: data?.cityName,
        });
        setIsPincodeValid(true);
      })
      .catch((err) => {
        setCentre({
          ...centre,
          [centre_column_keys.CITY_ID]: "",
          [centre_column_keys.CITY_NAME]: "",
        });
        setIsPincodeValid(false);
      });
  };

  const handleClearSearch = () => {
    setCentre({
      ...centre,
      [centre_column_keys.PINCODE]: "",
    });
    setIsPincodeValid(null);
  };

  const handleAddNCM = () => {
    setFormData((prevState) => {
      const ncmElement = prevState[centre_column_keys.NCM]
      return {
        ...prevState,
        [centre_column_keys.NCM]: {
          ...ncmElement,
          elements: [...ncmElement.elements, NCM_ELEMENT]
        }
      }
    })
    setCentre((prevState) => ({
      ...prevState,
      dealerNcmList: [...prevState.dealerNcmList, NCM_ELEMENT]
    }))
  }

  const handleNewCarStatusChange = (e) => {
    const { value } = e;
    setCentre((prevState) => ({
      ...prevState,
      isNewCarEnabled: value
    }))
  }

  const handleNCMChange = (e, index, key) => {
    const { value } = e;
    setCentre((prevState) => {
      const updatedNCMs = prevState.dealerNcmList.map((ncm, idx) => {
        if (idx === index) {
          return {
            ...ncm,
            [key]: {
              ...ncm[key],
              value: value,
            }
          }
        }
        return ncm;
      });
      return {
        ...prevState,
        dealerNcmList: updatedNCMs
      }
    })
  }

  const validateNCMs = () => {
    let isValid = true;
    const updatedNCMs = centre.dealerNcmList.map(ncm => {
      let ncmCopy = { ...ncm }
      for (const key in ncmCopy) {
        if (key != 'isNotificationEnabled' && ncmCopy[key].value.trim() === "") {
          isValid = false;
          ncmCopy = {
            ...ncmCopy,
            [key]: {
              ...ncmCopy[key],
              valid: false
            }
          }
        }
      }
      return ncmCopy;
    });
    setCentre((prevState) => ({
      ...prevState,
      dealerNcmList: updatedNCMs
    }))
    return isValid;
  }

  const fetchCityAreaData = (cityId) => {
    NewCarService.getCities(cityId)
      .then((response) => {
        const mappedResponse = (response?.data || []).map(({ name }) => ({ value: name, label: name }))
        setCityAreaData(mappedResponse);
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  useEffect(() => {
    if (centre?.cityId) {
      fetchCityAreaData(centre.cityId);
    }
  }, [centre.cityId]);

  useEffect(() => {
    setFormData((prevState) => {
      const cityAreaElement = prevState[centre_column_keys.CITY_AREA]
      return {
        ...prevState,
        [centre_column_keys.CITY_AREA]: {
          ...cityAreaElement,
          source: cityAreaData
        }
      }
    })
  }, [cityAreaData])

  const handleWhatsAppChange = (index, value) => {
    setCentre((prevState) => {
      const updatedNCMs = prevState.dealerNcmList.map((ncm, idx) => {
        return {
          ...ncm,
          isNotificationEnabled: { value: idx === index ? value : false, valid: true }
        };
      });
      return {
        ...prevState,
        dealerNcmList: updatedNCMs
      };
    });
  };

  const handleRemoveNcmFromForm = (elementId) => {
    setFormData((prevState) => {
      const ncmElement = prevState[centre_column_keys.NCM]
      return {
        ...prevState,
        [centre_column_keys.NCM]: {
          ...ncmElement,
          elements: ncmElement.elements.filter((item, index) => elementId != index)
        }
      }
    })
    setCentre((prevState) => {
      return {
        ...prevState,
        dealerNcmList: prevState.dealerNcmList.filter((item, index) => elementId != index)
      }
    })
  }

  const handleDeleteNcm = (ncm, elementId) => {
    if (ncm.ncmId) {
      let payload = {
        ncmId: ncm?.ncmId.value,
        mobile: ncm?.ncmMobile.value
      }
      DealerProcureService.deteleNcm(payload)
        .then((resp) => {
          handleRemoveNcmFromForm(elementId);
          showToastMessage("NCM Deleted Successfully")
        })
        .catch((err) => {
          console.log('Error Deleting NCM', err)
        })
    } else {
      handleRemoveNcmFromForm(elementId);
    }
  }
  
  return (
    <div>
      <div className="topbar-action">
        <span>Add Centre</span>
        <Button themeColor={"primary"} className="m-3" onClick={handleOnBack}>
          Back
        </Button>
      </div>
      <div className="create-dealer-wrapper">
        <div className="create-dealer-form">
          {Object.keys(formData).map((key) => {
            const elementMetaData = formData[key];
            switch (elementMetaData.elementtype) {

              case FORM_ELEMENT_TYPE.MULTI_SELECT:
                return (
                  <FormMultiSelect
                    id={key}
                    key={key}
                    onChange={handleChange}
                    data={elementMetaData.source}
                    textField="label"
                    dataItemKey="value"
                    label={elementMetaData.label}
                    isDisplayLabel={true}
                    disabled={elementMetaData?.name === centre_column_keys.CITY_AREA && cityAreaData.length == 0 && !centre?.cityId}
                    value={elementMetaData?.source?.filter(source => Array.isArray(centre?.[key]) && centre?.[key]?.some(centreItem => centreItem?.value === source?.value))
                    }
                    {...elementMetaData}
                  />
                )

              case FORM_ELEMENT_TYPE.SELECT:
                return (
                  ((elementMetaData?.name !== centre_column_keys.STATUS ||
                    (elementMetaData?.name === centre_column_keys.STATUS &&
                      isUpdate) || (elementMetaData?.name === centre_column_keys.CITY_AREA)) && (
                      <FormComboBox
                        id={key}
                        key={key}
                        onChange={handleChange}
                        data={elementMetaData.source}
                        textField="label"
                        dataItemKey="value"
                        label={elementMetaData.label}
                        isDisplayLabel={true}
                        disabled={elementMetaData?.name === centre_column_keys.CITY_AREA && cityAreaData.length == 0 && !centre?.cityId}
                        value={
                          !!Object.keys(centre || {}).length
                            ? elementMetaData?.source?.find(
                              (item) => item.value === centre[key]
                            )
                            : ""
                        }
                        {...elementMetaData}
                      />
                    ))
                );
              case FORM_ELEMENT_TYPE.SEARCH:
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "20px",
                      width: "25%",
                    }}
                  >
                    <FormSearchInput
                      value={
                        !!Object.keys(centre || {}).length ? centre[key] : ""
                      }
                      placeholder={"Pincode"}
                      handleChange={handleChange}
                      iconName={"search"}
                      handleInputSuffixAction={handleSearch}
                      handleClearSearch={handleClearSearch}
                      id={key}
                      style={{ width: "200px" }}
                    />

                    {isPincodeValid ? (
                      <span
                        class="k-icon k-i-check-circle k-icon-lg"
                        style={{ color: "green" }}
                      ></span>
                    ) : isPincodeValid === null ? (
                      ""
                    ) : (
                      <span
                        class="k-icon k-i-close-circle k-icon-lg"
                        style={{ color: "red" }}
                      ></span>
                    )}
                  </div>
                );
              case "BREAK":
                return (
                  <span
                    style={{
                      width: "100%",
                      borderBottom: "1px solid #e5e7eb",
                      padding: "10px",
                    }}
                  />
                );
              default:
                return (
                  <>
                    {elementMetaData.name === centre_column_keys.NCM ? (
                      elementMetaData.elements.map((element, index, elements) => {
                        return (
                          <div className={styles.ncmWrapper}>
                            <div className={styles.fieldWrapper}>
                              {Object.keys(element).map((item) => (item != 'isNotificationEnabled' && item != 'ncmId') && (
                                <FormInput
                                  id={item}
                                  key={item}
                                  type={item === "ncmEmail" ? "email" : "text"}
                                  label={`${NCM_FIELD_LABELS[item]} ${elements.length > 1 ? index + 1 : ""}`}
                                  onChange={(e) => handleNCMChange(e, index, item)}
                                  validationMessage={NCM_VALIDATION_MESSAGE[item]}
                                  valid={(centre && centre.dealerNcmList && centre.dealerNcmList[index]) ? centre.dealerNcmList[index][item].valid : ""}
                                  value={(centre && centre.dealerNcmList && centre.dealerNcmList[index]) ? centre.dealerNcmList[index][item].value : ""}
                                  touched={!(centre && centre.dealerNcmList && centre.dealerNcmList[index] && centre.dealerNcmList[index][item].valid)}
                                />
                              ))}
                            </div>
                            <div className={styles.ncmOptionWrapper}>

                              <div className={styles.radioButton}>
                                <div style={{ marginBottom: '4px' }}>Do you wish to opt for WhatsApp notifications?</div>
                                <div style={{ display: 'flex' }}>
                                  <label style={{ display: 'flex' }}>
                                    <input
                                      type="radio"
                                      value="yes"
                                      checked={centre?.dealerNcmList?.[index]?.isNotificationEnabled?.value === true}
                                      onChange={() => handleWhatsAppChange(index, true)}
                                    />
                                    <span style={{ marginLeft: '3px' }}>
                                      Yes
                                    </span>
                                  </label>
                                  <label style={{ marginLeft: '30px', display: 'flex' }}>
                                    <input
                                      type="radio"
                                      value="no"
                                      checked={centre.dealerNcmList?.[index]?.isNotificationEnabled?.value === false}
                                      onChange={() => handleWhatsAppChange(index, false)}
                                    />
                                    <span style={{ marginLeft: '3px' }}>
                                      No
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <Button
                                themeColor={"primary"}
                                onClick={() => handleDeleteNcm(element, index)}
                              >
                                Delete NCM
                              </Button>
                            </div>

                          </div>
                        )
                      })
                    )
                      :
                      <FormInput
                        id={key}
                        key={key}
                        onChange={handleChange}
                        value={
                          !!Object.keys(centre || {}).length ? centre[key] : ""
                        }
                        {...elementMetaData}
                      />
                    }
                  </>
                )
            }
          })}
        </div>

        <div className={styles.addNewNCM} onClick={handleAddNCM}>
          <span class="k-icon k-font-icon k-i-plus"></span>
          <span>Add NCM</span>
        </div>

        <div className={styles.newCarStatusWrapper}>
          <FormCheckbox
            label="The counter is active for new cars"
            id="newCarStatus"
            checked={centre.isNewCarEnabled}
            onChange={handleNewCarStatusChange}
          />
        </div>

        <Button themeColor={"primary"} onClick={handleCreate} className="m-3">
          {isUpdate ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default CreateCenter;
