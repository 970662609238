import CustomGrid from "../../../../../common/custom-grid/component";
import { getDisplayedCoulmns } from "../../../payment-list/viewModels";
import { GrievanceLogColumns } from "../../viewModels";

const GrievanceLog = (props) => {
  const { detailsData } = props || {};
  return (
    <>
      <legend
        style={{
          color: "#0d6efd",
          fontWeight: "600",
          marginTop: "20px",
          fontSize: "16px",
        }}
      >
        GRIEVANCE LOG
      </legend>
      <CustomGrid
        displayedColumns={getDisplayedCoulmns(GrievanceLogColumns)}
        data={detailsData?.issues?.filter(
          (issue) => issue.status === "RESOLVED" || issue.status === "REJECTED"
        )}
        count={10}
        columnMap={GrievanceLogColumns}
      />
    </>
  );
};
export default GrievanceLog;
