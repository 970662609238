import { OPS_VERIFIACTION } from "../types/ops-verfication";

const INITIAL_STATE = {
	isLoading: false,
	currentIndex: 0,
	data: [],
	totalCount: 0,
	numberOfElements: 0,
	error: {},
	searchQuery: "",
	userAssignments: 0,
	filters: {},
};

const OpsVerification = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case OPS_VERIFIACTION.FETCH_LOADING_START:
			return {
				...state,
				isLoading: true,
			};
		case OPS_VERIFIACTION.FETCH_LOADING_END:
			return {
				...state,
				isLoading: false,
			};
		case OPS_VERIFIACTION.FETCH_VERIFICATION_LIST_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				totalCount: action.payload.totalElements,
				numberOfElements: action.payload.numberOfElements,
				error: {},
			};
		case OPS_VERIFIACTION.FETCH_VERIFICATION_LIST_FAILURE:
			return {
				...state,
				data: [],
				totalCount: 0,
				numberOfElements: 0,
				error: action.payload,
			};
		case OPS_VERIFIACTION.UPDATE_VERIFICATION_ROW_ITEM:
			const currentItemIndex = state.data.findIndex(
				(item) =>
					item.idChallanVerification === action?.payload?.idChallanVerification
			);
			const currentItem = state.data[currentItemIndex];
			let updateCurrentItem = { ...currentItem };
			Object.keys(currentItem).map((key) => {
				updateCurrentItem[key] = action?.payload[key];
			});
			state.data[currentItemIndex] = updateCurrentItem;
			return {
				...state,
			};
		case OPS_VERIFIACTION.SET_ASSIGNMENT_TYPE:
			return {
				...state,
				userAssignments: action.payload,
			};
		case OPS_VERIFIACTION.SET_SEARCH_QUERY:
			return {
				...state,
				searchQuery: action.payload,
			};
		case OPS_VERIFIACTION.SET_VERIFICATION_FILTERS:
			return {
				...state,
				filters: action.payload,
			};
		default:
			return state;
	}
};
export default OpsVerification;
