import React, { useState } from "react";
import { FormComboBox, FormInput } from "../../../../components";
import { Button } from "@progress/kendo-react-buttons";
// import { createDealers } from "../../../../store/actions/dealer-procurement";
import { useDispatch } from "react-redux";
import { showToastMessage } from "../../../../utils/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { FormData } from "./viewModels";
import { FORM_ELEMENT_TYPE } from "../../../../utils/constants/values.constants";
// import { dealer_column_keys } from "../../fulfillment-list/viewModels";
import { DEALER_PROCUREMENT } from "../../../../store/types";
import { DealerProcureService } from "../../../../services";

const CreateDealer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [dealer, setDealer] = useState({
    dealerCode: "",
    dealerName: "",
    dealerType: "",
    companyCode: "",
    permanentAddress: "",
    presentAddress: "",
    mobileNo: "",
    alternateNo: "",
    emailAddress: "",
    status: "Active",
  });

  const handleChange = (e) => {
    const { id } = e?.target?.props || {};
    const { value } = e || {};
    setDealer({
      ...dealer,
      [id]: e?.target?.props?.elementtype === "SELECT" ? value?.value : value,
    });
  };

  const handleCreate = () => {
    dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_START });
    DealerProcureService.createDealers(dealer)
      .then(() => {
        showToastMessage("Dealer Created", true);
        history.push("/dealer/fulfillments");
      })
      .catch((error) => {
        showToastMessage(error?.message, false);
      })
      .finally(() => {
        dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_END });
      });
  };
  const handleOnBack = () => {
    history.push("/dealer/fulfillments");
  };
  return (
    <div>
      <div className="topbar-action">
        <span>Add Dealer</span>
        <Button themeColor={"primary"} className="m-3" onClick={handleOnBack}>
          Back
        </Button>
      </div>
      <div className="create-dealer-wrapper">
        <div className="create-dealer-form">
          {Object.keys(FormData).map((key) => {
            const elementMetaData = FormData[key];
            switch (elementMetaData.elementtype) {
              case FORM_ELEMENT_TYPE.SELECT:
                return (
                  <FormComboBox
                    id={key}
                    key={key}
                    onChange={handleChange}
                    data={elementMetaData.source}
                    textField="label"
                    dataItemKey="value"
                    value={FormData[key]?.value}
                    {...elementMetaData}
                  />
                );
              default:
                return (
                  <FormInput
                    id={key}
                    key={key}
                    onChange={handleChange}
                    value={FormData[key]?.value}
                    {...elementMetaData}
                  />
                );
            }
          })}
        </div>
        <Button themeColor={"primary"} onClick={handleCreate} className="m-3">
          Submit
        </Button>
      </div>
    </div>
  );
};

export default CreateDealer;
