function RupeeIcon(props) {
  return (
    <svg
      width={8}
      height={10}
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.452 2.48l-.314 1.08H.794l.314-1.08h6.344zM4.608 10L.992 5.775l-.007-.847h1.668c.483 0 .882-.08 1.197-.24.319-.164.558-.385.717-.663.16-.278.24-.59.24-.936 0-.388-.076-.727-.226-1.019a1.559 1.559 0 00-.71-.69c-.32-.169-.74-.253-1.259-.253H.808l.32-1.08h1.484c.793 0 1.447.12 1.962.362.52.237.907.584 1.162 1.04.256.455.383 1.006.383 1.654A3.39 3.39 0 015.798 4.6c-.21.437-.558.781-1.046 1.032-.483.25-1.135.376-1.955.376l3.363 3.91V10H4.608zM7.452.047l-.314 1.08H2.024L2.34.047h5.113z"
        fill="#7E8594"
      />
    </svg>
  )
}

export default RupeeIcon
