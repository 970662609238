import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

export const GridDetailSectionWithCell = ({
    dataList = [],
    columnList = [],
    itemPerRow = 6,
  }) => {
    let subArrays = [];
    // const _columnList = columnList?.filter(({ isShown = true }) => isShown);
    for (let i = 0; i < columnList?.length; i += itemPerRow) {
      subArrays.push(columnList.slice(i, i + itemPerRow));
    }
    const renderCoumns = (column = []) => {
      return column.map((item) => {
        const _Item = {
          ...item,
          // width: item.width ? item.width : "234px",
        };
        return <Column {..._Item} />;
      });
    };
    return (
      <div className="Kendo_main_div">
        {subArrays?.map((colum) => {
          return (
            <div className="mb-4">
              <Grid data={dataList}>{renderCoumns(colum)}</Grid>
            </div>
          );
        })}
      </div>
    );
  };