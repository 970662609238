export const TYPE_MY_DEALERS = {
  FETCH_LOADING_START: "FETCH_LOADING_START",
  FETCH_LOADING_END: "FETCH_LOADING_END",
  FETCH_MY_DEALERS_SUCCESS: "FETCH_MY_DEALERS_SUCCESS",
  FETCH_MY_DEALERS_FAILURE: "FETCH_MY_DEALERS_FAILURE",
  UPDATE_DEALERS_FILTERS: "UPDATE_DEALERS_FILTERS",
  SET_DEALER_ID: "SET_DEALER_ID",
  REFRESH_DEALERS: "REFRESH_DEALERS",
  SHOW_CONTACTED_CONFIRM: "SHOW_CONTACTED_CONFIRM",
  SET_SIDEBAR__DEALER_FILTERS_PAYLOAD: "SET_SIDEBAR__DEALER_FILTERS_PAYLOAD",
  SET_SIDEBAR_DEALER_FILTERS_APPLIED: "SET_SIDEBAR_DEALER_FILTERS_APPLIED",
  SET_ONGOING_CALL_STATUS: "SET_ONGOING_CALL_STATUS",
  SET_CURRENT_CALL_EVENT: "SET_CURRENT_CALL_EVENT",
  SET_DEALER_FILTER_STATUS: "SET_DEALER_FILTER_STATUS",
  SET_DEALER_FILTER_QUERY_PARAM: "SET_DEALER_FILTER_QUERY_PARAM",
  SET_CORRELATION_ID: "SET_CORRELATION_ID",
  SET_REASSIGN_PA: "SET_REASSIGN_PA",
  SET_NOTIFICATION_MODAL: "SET_NOTIFICATION_MODAL",
  SET_INITIAL_MOUNT:"SET_INITIAL_MOUNT",

};
