import { useSelector } from 'react-redux';

import CustomGrid from '../../../../common/custom-grid/component'
import { AppLoader } from '../../../viz-panel/common/components/app-loader';
import { BUY_BACK_STATE, ValidDateCell, ValidTillDateCell, getDisplayColumns, table_column_keys } from '../../common/constants/buyback.constants';
import ActionCell from '../components/table-action-cell';
import { INIT_PAGE_PARAMS } from '../../../../utils/constants/values.constants';


export const TableData = ({ pageParam, pageChange }) => {

  const { buyBackData, isLoading } = useSelector((state) => state.buyBackData);

  const buyBackTableColumns = {
    [table_column_keys.APPOINTMENT_ID]: {
      field: table_column_keys.APPOINTMENT_ID,
      label: "Appointment ID",
      width: 150

    },
    [table_column_keys.AUCTION_ID]: {
      field: table_column_keys.AUCTION_ID,
      label: "Auction ID",
      width: 120

    },
    [table_column_keys.DEALER_ID]: {
      field: table_column_keys.DEALER_ID,
      label: "Dealer ID",
      width: 120
    },
    [table_column_keys.BUYBACK_STATE]: {
      field: table_column_keys.BUYBACK_STATE,
      label: "Buyback State",
      cell: ({ dataItem }) => {
        return (
          <td className="text-center">
            {dataItem?.buybackState === BUY_BACK_STATE[0].id ? BUY_BACK_STATE[0].label : dataItem?.buybackState === BUY_BACK_STATE[1].id ? BUY_BACK_STATE[1].label : dataItem?.buybackState === BUY_BACK_STATE[2].id ? BUY_BACK_STATE[2].label : BUY_BACK_STATE[3].label}
          </td>
        );
      },
      width: 150,
    },
    [table_column_keys.EVENT_DATE]: {
      field: table_column_keys.EVENT_DATE,
      label: "Event Date",
      cell: ValidDateCell,
      width: 180,

    },
    [table_column_keys.VALID_TILL_DATE]: {
      field: table_column_keys.VALID_TILL_DATE,
      label: "Buyback Valid Till",
      cell: ValidTillDateCell,
      width: 180,

    },
    [table_column_keys.BUYBACK_PRICE]: {
      field: table_column_keys.BUYBACK_PRICE,
      label: "Buyback Price",
      width: 120

    },
    [table_column_keys.ACTION]: {
      field: table_column_keys.ACTION,
      label: "Action",
      cell: ({ dataItem }) => {
        return (
          <ActionCell dataItem={dataItem} pageParam={pageParam} />
        )
      },
      width: 120

    }

  }

  return (
    <>
      {isLoading ? <AppLoader /> :
        <CustomGrid
          displayedColumns={getDisplayColumns()}
          data={buyBackData.data ? [...buyBackData.data] : []}
          columnMap={buyBackTableColumns}
          pageSize={pageParam.pageSize}
          paginationParams={{
            pageNumber: pageParam?.page,
            pageSize: pageParam?.size,
          }}
          pageChange={pageChange}
          isPagination
          pageSizes={[10, 25, 50, 100, 200]}
          count={buyBackData?.totalPages * INIT_PAGE_PARAMS.size}

        />
      }
    </>
  )
}
