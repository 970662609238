import { useEffect, useState } from "react";
import { fetchReferrals } from "../helper";

export const useFetchReferrals = (paginationParams, filterParams, sortParms, setStoreList, triggerStatusUpdate) => {

    const [referralData, setReferralData] = useState(null);
    const [totalReferralCount, setTotalReferralCount] = useState(0);

    useEffect(() => {
        fetchReferrals(paginationParams, filterParams, setReferralData, setTotalReferralCount, sortParms, setStoreList);
    }, [paginationParams, filterParams, sortParms, triggerStatusUpdate]);

    return { referralData, totalReferralCount };
};