import dayjs from "dayjs";
import {
    ageValidator,
    mobileNumberValidator,
    nameValidator,
    panValidator,
    pincodeValidator,
    preventNegative,
} from "../../../../utils/utils";
import {
    EMPLOYMENT_TYPE_MAPPING,
    HOUSE_TYPE_MAPPING,
    INCOME_DETAIL_KEYS,
    PAN_DETAIL_KEYS,
    VALIDATION_METHOD,
} from "../constants";

export const updatePanCardFormData = (sellerInfo, setPanCardFormData) => {
    setPanCardFormData((columns) => {
        const dob = sellerInfo.dob
            ? dayjs(sellerInfo.dob, "YYYY-MM-DD").toDate()
            : null;
        return {
            ...columns,
            [PAN_DETAIL_KEYS.DOB]: {
                ...columns[PAN_DETAIL_KEYS.DOB],
                value: dob,
                touched: dob !== null,
                validationMessage: ageValidator(dob),
            },
            [PAN_DETAIL_KEYS.NAME]: {
                ...columns[PAN_DETAIL_KEYS.NAME],
                value: sellerInfo.name,
                validationMessage: nameValidator(sellerInfo.name).errorType,
                touched: sellerInfo.name !== null,
            },
            [PAN_DETAIL_KEYS.PHONE_NUMBER]: {
                ...columns[PAN_DETAIL_KEYS.PHONE_NUMBER],
                value: sellerInfo.phoneNumber,
                validationMessage: mobileNumberValidator(sellerInfo.phoneNumber)
                    ? ""
                    : "Enter Correct Phone Number",
                touched: sellerInfo.phoneNumber !== null,
                disabled: true,
            },
            [PAN_DETAIL_KEYS.PAN_NUMBER]: {
                ...columns[PAN_DETAIL_KEYS.PAN_NUMBER],
                value: sellerInfo.panNumber,
                validationMessage: panValidator(sellerInfo.panNumber)
                    ? ""
                    : "Please enter right PAN no.",
                touched: sellerInfo.panNumber !== null,
            },
            [PAN_DETAIL_KEYS.PINCODE]: {
                ...columns[PAN_DETAIL_KEYS.PINCODE],
                value: sellerInfo.pincode,
                validationMessage: pincodeValidator(sellerInfo.pincode)
                    ? ""
                    : "Please recheck the PIN code",
                touched: sellerInfo.pincode !== null,
            },
            [PAN_DETAIL_KEYS.GENDER]: {
                ...columns[PAN_DETAIL_KEYS.GENDER],
                ...(sellerInfo.gender && {
                    value: {
                        label: sellerInfo.gender,
                        value: sellerInfo.gender,
                    },
                }),
            },
            [PAN_DETAIL_KEYS.CIBIL_CONSENT]: {
                ...columns[PAN_DETAIL_KEYS.CIBIL_CONSENT],
                value: {
                    label: sellerInfo.cibilConsent || "Yes",
                    value: sellerInfo.cibilConsent || "Yes",
                },
            },
        };
    });
};

export const updateIncomeDetailFormData = (
    sellerInfo,
    setIncomeDetailFormData
) => {
    setIncomeDetailFormData((columns) => ({
        ...columns,
        [INCOME_DETAIL_KEYS.HOUSE_TYPE]: {
            ...columns[INCOME_DETAIL_KEYS.HOUSE_TYPE],
            ...(sellerInfo.houseType && {
                value: {
                    label:
                        HOUSE_TYPE_MAPPING[sellerInfo.houseType] ||
                        sellerInfo.houseType,
                    value: sellerInfo.houseType,
                },
            }),
        },
        [INCOME_DETAIL_KEYS.EMPLOYMENT_TYPE]: {
            ...columns[INCOME_DETAIL_KEYS.EMPLOYMENT_TYPE],
            ...(sellerInfo.employmentType && {
                value: {
                    label:
                        EMPLOYMENT_TYPE_MAPPING[sellerInfo.employmentType] ||
                        sellerInfo.employmentType,
                    value: sellerInfo.employmentType,
                },
            }),
        },
        [INCOME_DETAIL_KEYS.MONTHLY_INCOME]: {
            ...columns[INCOME_DETAIL_KEYS.MONTHLY_INCOME],
            value: sellerInfo.monthlyIncome,
            onKeyPress: preventNegative,
        },
        [INCOME_DETAIL_KEYS.REASON_FOR_LOAN]: {
            ...columns[INCOME_DETAIL_KEYS.REASON_FOR_LOAN],
            ...(sellerInfo.reasonForLoan && {
                value: {
                    label: sellerInfo.reasonForLoan,
                    value: sellerInfo.reasonForLoan,
                },
            }),
        },
        [INCOME_DETAIL_KEYS.REQUIRED_LOAN_AMOUNT]: {
            ...columns[INCOME_DETAIL_KEYS.REQUIRED_LOAN_AMOUNT],
            value: sellerInfo.requiredLoanAmount,
            onKeyPress: preventNegative,
        },
    }));
};

export const updateDocumentFormData = (documentInfo, setDocumentFormData) => {
    const formData = generateFormData(documentInfo);
    setDocumentFormData(formData);
};

const generateFormData = (documents) => {
    const result = {};

    Object.keys(documents).forEach((docCode) => {
        const doc = documents[docCode];
        const docUrl =
            doc.attachments && doc.attachments.length > 0
                ? doc.attachments[0].docUrl
                : null;

        const formDataEntry = {
            name: doc.docCode,
            label: `Upload ${doc.docName}`,
            elementtype: "UPLOAD_FILE",
            showFileView: !!docUrl,
            value: null,
        };

        if (docUrl) {
            formDataEntry.handleFileView = () => {
                window.open(docUrl);
            };
        }
        if (formDataEntry.name !== "ADDITIONAL_DOCS") {
            result[formDataEntry.name] = formDataEntry;
        }

        if (doc.subDocuments) {
            Object.keys(doc.subDocuments).forEach((subDocCode) => {
                const subDoc = doc.subDocuments[subDocCode];
                const subDocUrl =
                    subDoc.attachments && subDoc.attachments.length > 0
                        ? subDoc.attachments[0].docUrl
                        : null;

                const subFormDataEntry = {
                    name: subDoc.docCode,
                    label: `Upload ${subDoc.docName}`,
                    elementtype: "UPLOAD_FILE",
                    showFileView: !!subDocUrl,
                    value: null,
                };

                if (subDocUrl) {
                    subFormDataEntry.handleFileView = () => {
                        window.open(subDocUrl);
                    };
                }

                result[subFormDataEntry.name] = subFormDataEntry;
            });
        }
        result["docData"] = { ...documents };
    });

    return result;
};

export const getFileType = (type) => {
    const fileType = type.split("/")[0];

    if (fileType === "image") {
        return fileType;
    } else if (fileType === "application" && type.includes("pdf")) {
        return "pdf";
    }

    return null;
};

export const onInputChange = (e, formData, setFormData) => {
    const { value } = e;
    const { id } = e.target.props || {};
    const key = id || e.target.name;
    updateCurrentFormData(
        {
            key,
            value: key === "panNumber" ? value?.toUpperCase() || value : value,
            isFile: false,
        },
        formData,
        setFormData
    );
};

const updateCurrentFormData = (
    { key, value, isFile = false },
    formData,
    setFormData
) => {
    let updatedPopupData;

    updatedPopupData = {
        ...formData,
        [key]: {
            ...formData[key],
            value,
            touched: true,
            validationMessage: VALIDATION_METHOD[key]?.(value),
        },
    };

    setFormData(updatedPopupData);
};

export function formatAudioDuration(value) {
    if (isNaN(value)) {
      return "0m 0s";
    }
  
    const duration = value;
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
  
    return `${minutes}m ${seconds}s`;
  }
