function PlayIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M9.5 16.5l7-4.5-7-4.5v9zM12 22a9.738 9.738 0 01-3.9-.787 10.099 10.099 0 01-3.175-2.138A10.1 10.1 0 012.788 15.9 9.738 9.738 0 012 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 012.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0112 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 013.175 2.137c.9.9 1.613 1.959 2.137 3.175A9.737 9.737 0 0122 12a9.738 9.738 0 01-.788 3.9 10.1 10.1 0 01-2.137 3.175c-.9.9-1.958 1.613-3.175 2.138A9.738 9.738 0 0112 22z"
          fill="#EF6E0B"
        />
      </g>
    </svg>
  )
}

export default PlayIcon
