import { showToastMessage } from '../../../../utils/utils';
import { TYPE_BUY_BACK } from '../types';
import buyBackService from '../../buy-back/services';

export const fetchBuyBackData =
  (params) =>
    (dispatch) => {
      dispatch({ type: TYPE_BUY_BACK.FETCH_LOADING_START });

      buyBackService.getBuyBackData(params)
        .then((resp) => {
          dispatch({
            type: TYPE_BUY_BACK.SET_BUY_BACK_DATA,
            payload: {
              data: resp.buybackInfo,
              page: resp.page,
              pageSize: resp.pageSize,
              totalPages: resp.totalPages,
            },
          });
        })
        .catch((err) => {
          showToastMessage(err?.error || err?.message, false);
          dispatch({ type: TYPE_BUY_BACK.FETCH_LOADING_END });

        })
        .finally(() => {
          dispatch({ type: TYPE_BUY_BACK.FETCH_LOADING_END });
        }
        );

    };

export const fetchBuyBackApproval =
  (params) =>
    (dispatch) => {
      dispatch({ type: TYPE_BUY_BACK.FETCH_LOADING_START });

      return new Promise((res, rej) => {
        buyBackService.getBuyBackApproval(params)
          .then(() => {
            dispatch({ type: TYPE_BUY_BACK.SET_REFRESH, payload: true });
            res()
          })
          .catch((err) => {
            dispatch({ type: TYPE_BUY_BACK.FETCH_LOADING_END });
            rej(err?.error || err?.message);
          })
      })
    };


export const fetchInspectionDetails = (params) => () => {
  return new Promise((resolve, reject) => {
    buyBackService.getInspectionDetails(params)
      .then((resp) => {
        resolve(resp.data)
      })
      .catch((err) => {
        reject(err?.error || err?.message);
      })
  })
}





