import { Reveal } from "@progress/kendo-react-animation";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import React, { useState } from "react";
import { other_vahan_columns, vahan_columns } from "../viewModel";
import titleBar from "../../../shared/title-bar";
import CustomTitle from "../../../shared/custom-title";

const AllVahanCheckDetails = (props) => {
  const { setIsVahanDetails, vahanMismatch, otherChecksList } = props || {};
  const [showVahanChecks, setShowVahanChecks] = useState(false);
  const [showOtherChecks, setShowOtherChecks] = useState(false);
  const rowRender = (trElement, props) => {
    const mismatch = props.dataItem.vahanInspectionMismatch;
    const yellow = {
      backgroundColor: "#FEF0C7",
    };
    const trProps = {
      style: mismatch ? yellow : {},
    };
    return React.cloneElement(
      trElement,
      {
        ...trProps,
      },
      trElement.props.children
    );
  };
  return (
    <Dialog
      title={CustomTitle("All Vahan details")}
      onClose={() => setIsVahanDetails(false)}
      width={"50%"}
      contentStyle={{ width: "auto" }}
    >
      <div>
        <ExpansionPanel
          title={titleBar("Vahan checks")}
          className="k-rounded-lg"
          style={{
            background: "#F0F2F5",
          }}
          expanded={showVahanChecks}
          tabIndex={0}
          onAction={() => setShowVahanChecks(!showVahanChecks)}
        >
          <Reveal>
            {showVahanChecks && (
              <ExpansionPanelContent>
                <Grid data={vahanMismatch || []} rowRender={rowRender}>
                  {Object.keys(vahan_columns).map((columnName) => {
                    const column = vahan_columns[columnName];
                    return (
                      <GridColumn
                        key={columnName}
                        field={column["field"]}
                        title={column["label"]}
                      />
                    );
                  })}
                </Grid>
              </ExpansionPanelContent>
            )}
          </Reveal>
        </ExpansionPanel>
        <ExpansionPanel
          title={titleBar("Other Vahan status")}
          className="k-mt-4 k-rounded-lg"
          style={{
            background: "#F0F2F5",
          }}
          expanded={showOtherChecks}
          tabIndex={0}
          onAction={() => setShowOtherChecks(!showOtherChecks)}
        >
          <Reveal>
            {showOtherChecks && (
              <ExpansionPanelContent>
                <Grid data={otherChecksList || []}>
                  {Object.keys(other_vahan_columns).map((columnName) => {
                    const column = other_vahan_columns[columnName];
                    return (
                      <GridColumn
                        key={columnName}
                        field={column["field"]}
                        title={column["label"]}
                      />
                    );
                  })}
                </Grid>
              </ExpansionPanelContent>
            )}
          </Reveal>
        </ExpansionPanel>
      </div>
    </Dialog>
  );
};
export default AllVahanCheckDetails;
