export const DEALER_PAYOUT = {
  FETCH_LOADING_START: "FETCH_LOADING_START",
  FETCH_LOADING_END: "FETCH_LOADING_END",
  FETCH_PAYOUT_LEADS_SUCCESS: "FETCH_PAYOUT_LEADS_SUCCESS",
  FETCH_PAYOUT_LEADS_FAILURE: "FETCH_PAYOUT_LEADS_FAILURE",
  FETCH_PAYOUT_DETAILS_SUCCESS: "FETCH_PAYOUT_DETAILS_SUCCESS",
  FETCH_PAYOUT_DETAILS_FAILURE: "FETCH_PAYOUT_DETAILS_FAILURE",
  SET_LEAD_TYPE_FILTER: "SET_LEAD_TYPE_FILTER",
  UPDATE_PAYOUTS_FLAG: "UPDATE_PAYOUTS_FLAG",
};
