export default (api) => {

  const getBuyBackData = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/v1/buyback/approvals`, { params })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const getBuyBackApproval = (params) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/v1/buyback/approval`, params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const getInspectionDetails = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/v1/buyback/inspection-comparison`, {
          params: {
            appointmentId: params
          }
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  return {
    getBuyBackData,
    getBuyBackApproval,
    getInspectionDetails
  }
}