import { useEffect, useState } from 'react'

import styles from './styles.module.css'
import { useDispatch } from 'react-redux'
import { fetchBuyBackData } from '../store/actions/buyback'
import { INIT_PAGE_PARAMS } from '../../../utils/constants/values.constants'
import './table-data/index'
import { TableData } from './table-data/index'

const BuyBack = () => {

  const dispatch = useDispatch();

  const [pageParam, setPageParam] = useState(INIT_PAGE_PARAMS);


  useEffect(() => {
    dispatch(fetchBuyBackData(
      {
        page: pageParam.page,
        size: pageParam.size,
      }
    ))
  }, [pageParam])

  const pageChange = ({ page = 0, size = 10 }) => {
    setPageParam({ page, size });
  };

  return (
    <div className={`${styles['buy-back-container']} middleContent`}>
      <span className={styles['panel-name']}>Buyback Panel </span>


      <div className={styles['table-container']}>
        <TableData pageParam={pageParam} pageChange={pageChange} />
      </div>

    </div>
  )
}

export default BuyBack