import { TYPE_CALLLOGS } from "../types/callLogs"
import { vizPanelService } from '../../services'


export const setFilter = (filter) => (dispatch) => {
  dispatch({ type: TYPE_CALLLOGS.SET_FILTER_STATUS, payload: filter })
}

export const setCurrentSort = (sort) => (dispatch) => {
  dispatch({ type: TYPE_CALLLOGS.SET_CURRENT_SORT, payload: sort })
}

export const getCallRecording = (params) => () => {
  return new Promise((res, rej) => {
    vizPanelService.fetchCallRecording(params).then((resp) => {
      res(resp);
    }).catch((err) => {
      rej(err)
    })
  })
}
