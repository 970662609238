import { Hint } from "@progress/kendo-react-labels";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {  useState } from "react";
import { TextArea } from "@progress/kendo-react-inputs";
import { useDispatch } from "react-redux";
import { Button } from "@progress/kendo-react-buttons";

import styles from './styles.module.css';
import { fetchBuyBackApproval, fetchBuyBackData } from "../../../store/actions/buyback";
import Modal from "../../../components/modal";
import BuyBackDetails from "../buyback-details";
import { APPROVAL_ACTION, maxLength } from "../../../common/constants/buyback.constants";
import { showToastMessage } from "../../../../../utils/utils";
import useBodyOverflow from "../../../common/hooks/useBodyOverflow";


const ActionCell = ({ dataItem, pageParam }) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState("");
  const [actionType, setActionType] = useState("");
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (e) => {
    setValue(e.value);
  };

  useBodyOverflow(isModalOpen)

  const handleAction = (confirm) => {
    if (confirm) {
      dispatch(fetchBuyBackApproval({
        appointmentId: dataItem?.appointmentId,
        action: actionType,
        dealerId: dataItem?.dealerId
      })).then(() => {
        const message = actionType === APPROVAL_ACTION.APPROVE ? "Buyback approved successfully" : "Buyback rejected successfully";
        showToastMessage(message)
        refreshPageData();

      }).catch((err) => {
        showToastMessage(err, false)
      });
    }
    setVisible(false);

  };

  const toggleDialog = (type) => {
    setVisible(!visible);
    setActionType(type);
  };

  const refreshPageData = () => {
    dispatch(fetchBuyBackData(
      {
        page: pageParam.page,
        size: pageParam.size,
      }
    ));

  }

  return (
    <>
      <td className={styles['action-cell-container']}>
        <Button onClick={() => setIsModalOpen(true)}>View</Button>
      </td>

      {visible ? (
        <Dialog title={"Please confirm"} className={styles['dialog-container']} onClose={() => toggleDialog("")}>
          <div className={styles['confirmation-text-container']}>
            <span className={styles['confirmation-text']}>
              Are you sure you want to {actionType === APPROVAL_ACTION.APPROVE ? APPROVAL_ACTION.APPROVE.toLowerCase() : APPROVAL_ACTION.REJECT.toLowerCase()} ?
            </span>
            <TextArea onChange={handleChange} autoSize={true} maxLength={maxLength} placeholder="Please type your feedback" />
            <Hint direction={"end"}>
              {value.length} / {maxLength}
            </Hint>
          </div>
          <DialogActionsBar>
            <Button type="button" onClick={() => handleAction(false)}>
              No
            </Button>
            <Button type="button" onClick={() => handleAction(true)}>
              Yes
            </Button>
          </DialogActionsBar>
        </Dialog>
      ) : null}


      <Modal isOpen={isModalOpen} close={() => setIsModalOpen(false)} heading="Report Comparison">
        <BuyBackDetails dataItem={dataItem} toggleDialog={toggleDialog} />
      </Modal>
    </>
  );
}

export default ActionCell




