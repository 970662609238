import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { KendoCard } from "../../../../../../components";
import InsuranceDetailsCard from "./insurance-card";
import RcCard from "./rc-card";
const InsuranceRcCard = () => {
  return (
    <KendoCard
      cardStyle={{
        background: "#F0F2F5",
      }}
      headerTitle="Insurance & RC"
      details={
        <GridLayout
          cols={[
            {
              width: "50%",
            },
            {
              width: "50%",
            },
          ]}
        >
          <GridLayoutItem col={1}>
            <InsuranceDetailsCard />
          </GridLayoutItem>
          <GridLayoutItem col={2}>
            <RcCard />
          </GridLayoutItem>
        </GridLayout>
      }
    />
  );
};
export default InsuranceRcCard;
