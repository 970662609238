import csApi from "./challanApi";
import csService from "./challanService";
import dpApi from "./dealerProcurementApi";
import prApi from "./prAutomationApi";
import inventoryApi from "./inventoryApi.js";
import dmsApi from "./dmsApi";
import dealerApi from "./dealerApi";
import lmsApi from "./lmsApi.js";
import dpService from "./dealerProcurementService";
import prService from "./prAutomationService";
import inventroyService from "./inventoryService.js";
import dmsService from "./dmsService";
import dealerService from "./dealerService";
import shApi from "./serviceHistoryApi";
import shService from "./serviceHistoryService";
import consApi from "./consumerApi";
import consService from "./consumerService";
import vfApi from "./verificationApi";
import vfService from "./verificationService";
import rcApi from "./retailConsumerApi.js";
import rcService from "./retailConsumerService.js";
import locApi from "./locationApi";
import locService from "./locationService";
import vehApi from "./vehicleApi";
import vehService from "./vehicleService";
import lmsService from "./lmsService.js";
import advApi from "./administratorApi.js";
import advService from "./administratorService.js";
import vhApi from "./vahanApi";
import vhService from "./vahanService";
import vasService from './vasService'
import vasApi from "./vasAPi"
import sellerFinanceApi from "./seller-finance/sellerFinanceApi.js";
import sellerFinanceService from "./seller-finance/sellerFinanceService.js";
import visService from "./vehicleInspectionService.js";
import visApi from './vehicleInspectionApi.js';
import cjiApi from "./cjIncentiveApi.js";
import cjiService from "./cjIncentiveService.js";
import irApi from './inspectionReferralApi.js'
import irService from "./inspectionReferralService.js";
import ifApi from './inspectionFeedApi.js';
import ifService from './inspectionFeedService.js'
import isApi from './inspectionSocialApi.js'
import isService from './inspectionSocialService.js'
import newCarService from './newCarService';

const cs = csService(csApi);
const dp = dpService(dpApi);
const pr = prService(prApi);
const inventory = inventroyService(inventoryApi);
const dms = dmsService(dmsApi);
const dealer = dealerService(dealerApi);
const lms = lmsService(lmsApi);
const sh = shService(shApi);
const cons = consService(consApi);
const vf = vfService(vfApi);
const rc = rcService(rcApi);
const loc = locService(locApi);
const veh = vehService(vehApi);
const adv = advService(advApi);
const vahan = vhService(vhApi);
const vas = vasService(vasApi);
const sf = sellerFinanceService(sellerFinanceApi)
const vis = visService(visApi);
const ir = irService(irApi)
const ifs = ifService(ifApi)
const is = isService(isApi)
const ns = newCarService();


const cji = cjiService(cjiApi);
export {
  cs as ChallanService,
  dp as DealerProcureService,
  pr as PrAutomationService,
  inventory as InventoryService,
  dms as DmsService,
  dealer as dealerService,
  lms as LmsService,
  sh as ServiceHistory,
  cons as ConsumerService,
  vf as VerficationService,
  rc as RetailService,
  loc as LocationService,
  veh as VehicleService,
  adv as AdministratorService,
  vahan as VahanService,
  vas as VasService,
  vis as VisService,
  sf as SFService,
  cji as CjIncentivesService,
  ir as InspectionReferralService,
  ifs as InspectionFeedService,
  is as InspectionSocialService,
  ns as NewCarService
};
