import { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';

import styles from './styles.module.css';
import useBodyOverflow from '../../common/hooks/useBodyOverflow';
import SegmentationForm from '../components/segment-form';
import Modal from '../../components/modal'


const CreateSegmentation = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  useBodyOverflow(isModalOpen);

  return (
    <div className={styles.headerButtons}>
      <Button themeColor="primary" fillMode="outline" onClick={() => setIsModalOpen(true)}>
        Create Segment
      </Button>
      <Modal isOpen={isModalOpen} close={() => setIsModalOpen(false)} heading="Create Segment">
        <div className={styles.modalContent}>
          <SegmentationForm
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      </Modal>
    </div>
  );
};
export default CreateSegmentation;
