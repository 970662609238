import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { ChipList, Chip } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useSelector } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import {
  formatDateYYYYMMDDtoDDMMM,
  isToday,
  isTomorrow,
} from "../../../../../../../utils/utils";
import styles from "../../../../negotiation/assets/styles/pr.styles.module.css";
import CustomTitle from "../../../shared/custom-title";
import { AddressForm } from "../address-form";

const getSelectedTime = (pickupDate, slotFrom, slotTo, slotData) => {
  if (slotFrom && slotTo) {
    const filteredSlot =
      slotData &&
      slotData?.[pickupDate]?.find(
        (item) => item.from === slotFrom && item.to === slotTo
      );
    return filteredSlot?.slotKey;
  }
};
const getTimeSlots = (slotData, pickupDate) => {
  const filteredData = slotData && slotData?.[pickupDate];
  if (filteredData && filteredData.length) {
    const list = filteredData.map((slot) => {
      return {
        text: slot.from + " - " + slot.to,
        value: slot.slotKey,
      };
    });
    return list || [];
  }
};
const PickUpDetails = (props) => {
  const { togglePickUp } = props || {};
  const [sortedBookSlot, setSortedBookSlot] = useState();
  const [dateSlot, setDateSlot] = useState([]);
  const prDetails = useSelector((state) => state.prAutomation);
  const {
    bookSlotData: { slotData = {} },
    orderData: { pickupDetails = {} },
  } = prDetails || {};
  const { pickupDate, slotFrom, slotTo } = pickupDetails || {};
  const [selectedDate, setSelectedDate] = useState(pickupDate);
  const [selectedTime, setSelectedTime] = useState(
    getSelectedTime(pickupDate, slotFrom, slotTo, slotData)
  );
  const [timeSlot, setTimeSlot] = useState(getTimeSlots(slotData, pickupDate));
  useEffect(() => {
    if (slotData && Object.keys(slotData)?.length) {
      const entries = Object.entries(slotData);

      entries.sort((a, b) => a[0].localeCompare(b[0]));

      const sortedBookSlotData = Object.fromEntries(entries);
      setSortedBookSlot(sortedBookSlotData);
    }
  }, [slotData]);
  useEffect(() => {
    if (sortedBookSlot && Object.keys(sortedBookSlot)?.length) {
      const list = Object.keys(sortedBookSlot)?.map((slot) => {
        return {
          text: isToday(slot)
            ? "Today"
            : isTomorrow(slot)
            ? "Tomorrow"
            : formatDateYYYYMMDDtoDDMMM(slot),
          value: slot,
        };
      });
      setDateSlot(list);
      if (!pickupDate) {
        const firstDateSlot = Object.keys(sortedBookSlot)?.[0];
        setSelectedDate(firstDateSlot);
        const timeSlotList = getTimeSlots(slotData, firstDateSlot);
        setTimeSlot(timeSlotList);
        setSelectedTime(timeSlotList?.[0]?.value);
      }
    }
  }, [sortedBookSlot, slotData, pickupDate]);
  const CustomChip = (props, selectedValue, isTime) => {
    const { dataItem } = props || {};
    return (
      <Chip
        {...props}
        className={`${styles.customChips} ${
          selectedValue === dataItem.value
            ? styles.selectedChip
            : styles.unSelectedChip
        } ${isTime && styles.timeSlot}`}
      />
    );
  };
  const handleDateSelect = useCallback(
    (event) => {
      if (dateSlot?.length) {
        setSelectedDate(event.value);
        const filteredData = slotData && slotData?.[event.value];
        if (filteredData && filteredData.length) {
          const list = filteredData.map((slot) => {
            return {
              text: slot.from + " - " + slot.to,
              value: slot.slotKey,
            };
          });
          setTimeSlot(list);
          setSelectedTime(list?.[0].value);
        }
      }
    },
    [dateSlot, slotData]
  );
  const handleTimeSelect = useCallback(
    (event) => {
      if (timeSlot?.length) {
        setSelectedTime(event.value);
      }
    },
    [timeSlot]
  );
  return (
    <Dialog
      title={CustomTitle("Pickup Details")}
      onClose={togglePickUp}
      width="820px"
      className={styles.pickUpDialog}
      contentStyle={{ width: "auto" }}
    >
      <GridLayout
        cols={[
          {
            width: "100%",
          },
        ]}
        gap={{
          rows: "24px",
          cols: "10px",
        }}
      >
        <GridLayoutItem row={1}>
          <h2 className={styles.slotLabel}>Seller's preferred day</h2>
          <ChipList
            data={dateSlot?.length ? dateSlot : [{ text: "NA", value: "NA" }]}
            selection="single"
            chip={(props) => CustomChip(props, selectedDate)}
            onChange={handleDateSelect}
          />
          <div className={styles.divider}></div>
        </GridLayoutItem>
        <GridLayoutItem row={2}>
          <h2 className={styles.slotLabel}>Preferred time slot</h2>
          <ChipList
            data={timeSlot?.length ? timeSlot : [{ text: "NA", value: "NA" }]}
            selection="single"
            chip={(props) => CustomChip(props, selectedTime, true)}
            onChange={handleTimeSelect}
          />
          <div className={styles.divider}></div>
        </GridLayoutItem>
        <GridLayoutItem row={3}>
          <h2 className={styles.slotLabel}>Pickup Address</h2>
          <AddressForm
            {...props}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
          />
        </GridLayoutItem>
      </GridLayout>
    </Dialog>
  );
};

export default PickUpDetails;
