import { DEALER_PAYOUT } from "../types";
import { DealerProcureService } from "../../services";
import { showToastMessage } from "../../utils/utils";
export const fetchPayouts =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: DEALER_PAYOUT.FETCH_LOADING_START });
    DealerProcureService.fetchPayoutLeads(params)
      .then((resp) => {
        dispatch({
          type: DEALER_PAYOUT.FETCH_PAYOUT_LEADS_SUCCESS,
          payload: {
            data: resp.content,
            numberOfElements: resp.numberOfElements,
            totalElements: resp.totalElements,
          },
        });
      })
      .catch((err) => {
        showToastMessage(err?.error || err?.message, false);
        dispatch({
          type: DEALER_PAYOUT.FETCH_PAYOUT_LEADS_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: DEALER_PAYOUT.FETCH_LOADING_END });
      });
  };

export const UpdatePayoutsFilterQuery =
  (params = "") =>
  (dispatch) => {
    dispatch({
      type: DEALER_PAYOUT.SET_LEAD_TYPE_FILTER,
      payload: params,
    });
  };
export const fetchPayoutDetails =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: DEALER_PAYOUT.FETCH_LOADING_START });
    DealerProcureService.fetchPayoutDetails(params)
      .then((resp) => {
        dispatch({
          type: DEALER_PAYOUT.FETCH_PAYOUT_DETAILS_SUCCESS,
          payload: {
            data: [resp],
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: DEALER_PAYOUT.FETCH_PAYOUT_DETAILS_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: DEALER_PAYOUT.FETCH_LOADING_END });
      });
  };
