import React from "react";
import {
	ASSIGNMENT_TYPES,
	FF_PAGE_TYPES,
} from "../../../../../../utils/constants/values.constants";
import { useDispatch, useSelector } from "react-redux";
import {
	updateAssignmentTypeParams,
	updateChallanItemAssignmentTypeParams,
	updateChallanItemDelayedParams,
} from "../../../../../../store/actions/ops-challan";
const AssignmentTypesFilter = (props) => {
	const dispatch = useDispatch();
	const session = useSelector((state) =>
		props?.pageKey === FF_PAGE_TYPES.FINDERS
			? state.opsChallan
			: state.opsChallanItem
	);
	const { userAssignments: selectedFilter, delayed: isDelay = false } = session;
	const onFilterSelect = (key) => {
		if (props?.pageKey === FF_PAGE_TYPES.FINDERS) {
			dispatch(updateAssignmentTypeParams(key));
		} else {
			dispatch(updateChallanItemAssignmentTypeParams(key));
		}
	};

	const onDelaySelect = () => {
		dispatch(updateChallanItemDelayedParams(true));
	};
	
	return (
		<ul className="ops-channel-assignment-filter-items">
			{ASSIGNMENT_TYPES.map((item) => {
				return (
					<li
						key={item.value}
						onClick={() => onFilterSelect(item.value)}
						className={
							selectedFilter === item.value ? "assignment-filterSelected" : ""
						}>
						{item.label}
					</li>
				);
			})}
			{props?.pageKey === FF_PAGE_TYPES.DISPOSE && (
				<li
					className={isDelay ? "assignment-filterSelected" : ""}
					onClick={onDelaySelect}>
					Delayed
				</li>
			)}
		</ul>
	);
};

export default AssignmentTypesFilter;
