import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import classes from "../styles/DocumentsForm.module.css";
import { TextArea } from "@progress/kendo-react-inputs";
import { StackLayout } from "@progress/kendo-react-layout";
import { useRef, useState } from "react";

const ActionConfirmationModal = ({
    type,
    isOpen,
    onClose,
    onConfirm,
    isBtnLoading,
}) => {
    const [remarks, setRemarks] = useState("");

    return isOpen ? (
        <Dialog title="Confirmation" onClose={onClose}>
            <p className="m-0 mb-2 text-base">
                Are you sure you want to <strong>{type}</strong> this document ?
            </p>
            {type === "reject" ? (
                <StackLayout orientation="vertical">
                    <label
                        htmlFor="remarks"
                        className="k-font-weight-semibold text-base"
                    >
                        Remarks
                    </label>
                    <TextArea
                        id="remarks"
                        onChange={(e) => {
                            setRemarks(e.target.value);
                        }}
                    />
                </StackLayout>
            ) : null}
            <DialogActionsBar>
                <div className={classes.actionBtnContainer}>
                    <Button
                        className={`w-100 ${isBtnLoading ? "opacity-25" : ""}`}
                        themeColor="primary"
                        onClick={() => onConfirm(remarks.current)}
                        disabled={type === "reject" && remarks.length === 0}
                    >
                        Yes
                    </Button>
                    {isBtnLoading && (
                        <Loader className={classes.confirmModalLoader} />
                    )}
                </div>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActionsBar>
        </Dialog>
    ) : null;
};

export default ActionConfirmationModal;
