import React, { useState } from "react";
import { UploadFile, UploadFileV2 } from "../../../../components";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const UploadFileLayout = (props) => {
    const [isFileUploaded, setIsFileUploaded] = useState(false);

    const CustomListItemUI = () => {
        return (
            <GridLayout
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px solid #D3DDE8",
                    padding: "10px",
                }}
            >
                <GridLayoutItem>
                    <span className="k-icon k-i-pdf m-1"></span>
                    Doc Uploaded
                </GridLayoutItem>
                <GridLayoutItem>
                    <span
                        className="k-icon k-i-eye m-1"
                        style={{ cursor: "pointer", color: "#0059A3" }}
                        onClick={props?.handleFileView}
                    ></span>
                </GridLayoutItem>
            </GridLayout>
        );
    };

    const handleAgentChange = (e) => {
        props.handleChange(e);
        setIsFileUploaded(true);
    };

    return (
        <div>
            <UploadFile
                id={"key-upload-single-file"}
                key={"key-upload-single-file"}
                label={"Upload proof file"}
                listItemUI={CustomListItemUI}
                onChange={(e) => handleAgentChange(e)}
                {...props}
            />
            {
                props?.showStatus &&
                <span
                    style={{ position: 'absolute', top: '2px', right: 0, ...props?.statusStyle ?? {} }}
                >
                    {props?.showStatus}
                </span>
            }
            {props?.showFileView && <CustomListItemUI />}
        </div>
    );
};

export default UploadFileLayout;

export const UploadFileLayoutV2 = (props) => {
  
  const handleAgentChange = (e) => {
      props.handleChange(e);
  };

  return (
      <div>
          <UploadFileV2
              id={"key-upload-single-file"}
              key={"key-upload-single-file"}
              label={"Upload"}
              onChange={(e) => handleAgentChange(e)}
              {...props}
          />
      </div>
  );
};
