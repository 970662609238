import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormComboBox, FormInput } from "../../../components";
import { Button } from "@progress/kendo-react-buttons";
import { FormData } from "./viewModels";
import { lead_mon_keys } from "../lead-mon/viewModels";
import { FORM_ELEMENT_TYPE } from "../../../utils/constants/values.constants";
import { showToastMessage } from "../../../utils/utils";
import {
  fetchCityLocations,
  fetchMakeModels,
  fetchMakeYears,
  resetLeadMonConfigs,
} from "../../../store/actions/retail-consumer";
import { RetailService } from "../../../services";
import LeadMonActions from "../components/leadmon-actions";
const LeadMonDetail = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const masterData = useSelector((state) => state.retailConsumers);
  const [data, setData] = useState(FormData);
  const {
    cities = [],
    locations = [],
    makers = [],
    models = [],
    years = [],
  } = masterData;

  const onInputChange = (e) => {
    const { name, value } = e;
    const { id } = e.target.props || {};
    const key = id || e.target.name;
    setData((items) => ({
      ...items,
      [key]: { ...items[key], value: value },
    }));
    if (key === lead_mon_keys.MAKER_NAME && !!value?.value) {
      dispatch(fetchMakeModels(value.value));
      dispatch(fetchMakeYears(value.value));
    } else if (key === lead_mon_keys.CITY_NAME && !!value?.value) {
      dispatch(fetchCityLocations(value.value));
    }
  };
  const goBack = () => {
    history.push("/retail/leadmon");
  };
  const onCreateLeadMon = () => {
    // valid payload
    const payload = {
      cityId: data[lead_mon_keys.CITY_NAME]?.value?.value,
      locationId: data[lead_mon_keys.LOCATION_NAME]?.value?.value,
      makeId: data[lead_mon_keys.MAKER_NAME]?.value?.value,
      modelId: data[lead_mon_keys.MODEL_NAME]?.value?.value,
      startYear: data[lead_mon_keys.FROM_YEAR]?.value?.value,
      endYear: data[lead_mon_keys.TO_YEAR]?.value?.value,
      status: "Active",
      isLeadMon: data[lead_mon_keys.IS_LEAD_MON]?.value?.value,
      isLuxuryCar: data[lead_mon_keys.IS_LUXURY_CAR]?.value?.value,
      partnerId: data[lead_mon_keys.PARTNER_ID]?.value,
      tpThreshold: data[lead_mon_keys.TP_THRESHOLD]?.value,
    };
    if (!payload?.cityId) {
      showToastMessage("Please select City", false);
      return;
    } else if (!payload?.locationId) {
      showToastMessage("Please select store", false);
      return;
    } else if (!payload?.makeId) {
      showToastMessage("Please select maker", false);
      return;
    } else if (!payload?.modelId) {
      showToastMessage("Please select model", false);
      return;
    } else if (!payload?.startYear) {
      showToastMessage("Please select start year", false);
      return;
    } else if (!payload?.endYear) {
      showToastMessage("Please select end year", false);
      return;
    } else if (payload?.endYear <= payload?.startYear) {
      showToastMessage("Please select from year less than to year", false);
      return;
    } else if (payload?.isLeadMon != 1 && payload?.isLeadMon != 0) {
      showToastMessage("Please select Lead Mon or not.", false);
      return;
    } else if (payload?.isLuxuryCar === 1 && !payload?.partnerId) {
      showToastMessage("Please enter Partner ID for Luxury Car", false);
      return;
    } else if (payload?.isLuxuryCar === 1 && !payload?.tpThreshold) {
      showToastMessage("Please enter TP Threshold for Luxury Car", false);
      return;
    }
    RetailService.createLeadMonConfig(payload)
      .then((resp) => {
        showToastMessage(resp?.message);
        dispatch(resetLeadMonConfigs());
        history.push("/retail/leadmon");
      })
      .catch((err) => {
        showToastMessage(err?.message || "Failed to create", false);
      });
  };
  useEffect(() => {
    if (!cities.length || !makers.length) {
      history.push("/retail/leadmon");
    } else {
      const cityList = cities.map((item) => {
        return {
          label: item.city_name,
          value: item.city_id,
        };
      });
      const makeList = makers.map((item) => {
        return {
          label: item.make_display,
          value: item.make_id,
        };
      });
      setData((data) => ({
        ...data,
        [lead_mon_keys.CITY_NAME]: {
          ...data[lead_mon_keys.CITY_NAME],
          source: cityList,
        },
        [lead_mon_keys.MAKER_NAME]: {
          ...data[lead_mon_keys.MAKER_NAME],
          source: makeList,
        },
      }));
    }
  }, [cities, makers]);

  useEffect(() => {
    const stores = locations.map((item) => {
      const { locationId, name } = item;
      return {
        label: name,
        value: locationId,
      };
    });
    setData((data) => ({
      ...data,
      [lead_mon_keys.LOCATION_NAME]: {
        ...data[lead_mon_keys.LOCATION_NAME],
        source: stores,
      },
    }));
  }, [locations]);

  useEffect(() => {
    const modelList = models.map((item) => {
      return {
        label: item.model_display,
        value: item.model_id,
      };
    });
    setData((data) => ({
      ...data,
      [lead_mon_keys.MODEL_NAME]: {
        ...data[lead_mon_keys.MODEL_NAME],
        source: modelList,
      },
    }));
  }, [models]);

  useEffect(() => {
    const yearList = years.map((item) => {
      return {
        label: item.year,
        value: item.year,
      };
    });
    setData((data) => ({
      ...data,
      [lead_mon_keys.FROM_YEAR]: {
        ...data[lead_mon_keys.FROM_YEAR],
        source: yearList,
      },
      [lead_mon_keys.TO_YEAR]: {
        ...data[lead_mon_keys.TO_YEAR],
        source: yearList,
      },
    }));
  }, [years]);

  return (
    <>
      <div className="ops-channel-page-bar">
        <LeadMonActions goBack={goBack} isDetail={true} />
      </div>
      <div className="create-dealer-wrapper">
        <div className="create-dealer-form">
          {Object.keys(data).map((key) => {
            const elementMetaData = data[key];
            switch (elementMetaData.elementtype) {
              case FORM_ELEMENT_TYPE.SELECT:
                return (
                  <FormComboBox
                    id={key}
                    key={key}
                    onChange={onInputChange}
                    data={data[key]?.source || []}
                    textField="label"
                    dataItemKey="value"
                    value={data[key]?.value}
                    {...elementMetaData}
                  />
                );
              default:
                return (
                  <FormInput
                    id={key}
                    key={key}
                    onChange={onInputChange}
                    value={props.data?.[key]?.value}
                    {...elementMetaData}
                  />
                );
            }
          })}
        </div>
        <Button
          themeColor={"primary"}
          onClick={onCreateLeadMon}
          className="m-3"
        >
          Submit
        </Button>
      </div>
    </>
  );
};
export default LeadMonDetail;
