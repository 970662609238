import {  getGenAiBaseUrl } from "../utils/constants/url.constants";
import { getFFUserType, getLoginUserEmail } from "../utils/utils";
import apiInterceptor from "./apiInterceptor";

const genAiService = (api) => {

    const getSumaryBySid = (payload) => {
      const url = `api/v1/calls/summary`;
      return api.post(`${url}`, payload.body);
    };

    const getHighlights = (payload) => {
      const url = `api/v1/calls/highlights`;
      return api.post(`${url}`, payload.body);
    };

    const getDataByRequestId = (payload) => {
      const url = `api/v1/fulfillment/${payload.requestId}/status`;
      return api.get(`${url}`);
    };

    const getScorecard = (payload) => {
      const url = `api/v1/calls/score`;
      return api.post(`${url}`, payload.body);
    };

    const getChatHistory = (payload) => {
      const {pageNumber,pageSize,appointmentId} = payload || {}
      const url = `api/v1/chats/history?size=${pageSize}&sort=messageDate,DESC&appointment=${appointmentId}&page=${pageNumber}`;
      return api.get(`${url}`,{
        headers:{'x-channel-name':'test'}
      });
    };

    return {
      getSumaryBySid,
      getHighlights,
      getDataByRequestId,
      getScorecard,
      getChatHistory
    };
};

export const genAiApis = genAiService(apiInterceptor(getGenAiBaseUrl()));
