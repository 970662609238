import React, { useEffect, useState } from 'react'
import { AdministratorService } from '../../../../../../../services';
import { FormCheckbox, FormInput } from '../../../../../../../components';
import { Button } from '@progress/kendo-react-buttons';
import { showToastMessage } from '../../../../../../../utils/utils';

const ReasonManagerPopup = ({ctaAction, selectedRejection, selectedDelay, selectedUnFullfill, selectedOffence, openReasonMangerPopup, handleReload, delayList, offenceList, rejectList, unFulfillList, selectedVerificationRejection, verificationRejectList}) => {
    
    const [delayReason,setDelayReason] = useState("");
    const [offenceReason, setOffenceReason] = useState("");
    const [rejectReason,setRejectReason] = useState("");
    const [verificationRejectReason, setVerificationRejectReason] = useState("");
    const [unFullfillReason,setUnFullfillReason] = useState("");
    const [isStatusActive, setIsStatusActive] = useState(false);
    const deleteCheckboxLabel = `Are you sure you want to ${ctaAction}?`

    useEffect(() => {
        if(ctaAction === "Edit Delay Reason"){
            setDelayReason(selectedDelay?.label);
            const isDelayActive = !!delayList?.length ? delayList?.filter((item) => item?.value === selectedDelay?.value)?.[0]?.status : false;
            setIsStatusActive(!!isDelayActive);
        }
        else if(ctaAction === "Edit Offence Reason"){
            const isOffenseActive = !!offenceList?.length ? offenceList?.filter((item) => item?.value === selectedOffence?.value)?.[0]?.status : false;
            setIsStatusActive(!!isOffenseActive);
        }
        else if(ctaAction === "Edit Reject Reason"){
            const isRejectReason = !!rejectList?.length ? rejectList?.filter((item) => item?.value === selectedRejection?.value)?.[0]?.status : false;
            setIsStatusActive(!!isRejectReason);
        }
        else if(ctaAction === "Edit Unable to Fullfill Reason"){
            const isUnFullfillReason = !!unFulfillList?.length ? unFulfillList?.filter((item) => item?.value === selectedUnFullfill?.value)?.[0]?.status : false;
            setIsStatusActive(!!isUnFullfillReason);
        }
        else if(ctaAction === "Edit Verification Reject Reason"){
          const isVerificationRejectReason = !!verificationRejectList?.length ? verificationRejectList?.filter((item) => item?.value === selectedVerificationRejection?.value)?.[0]?.status : false;
          setIsStatusActive(!!isVerificationRejectReason);
      }
    },[])

    const handleReasonChange = (e) => {
        const { value } = e || {};
        setDelayReason(value);
    }

    const handleRejectChange = (e) => {
        const { value } = e || {};
        setRejectReason(value);
    }

    const handleVerificationRejectChange = (e) => {
      const { value } = e || {};
      setVerificationRejectReason(value);
  }

    const handleOffenceChange = (e) => {
        const { value } = e || {};
        setOffenceReason(value);
    }

    const handleUnFullfillChange = (e) => {
      const { value } = e || {};
      setUnFullfillReason(value);
  }
    const handleDelayReasonAdd = () => {
      if(!delayReason?.length){
        showToastMessage("Enter Delay Reason", false);
      }
      else{
        AdministratorService.addDelayReason({reason:delayReason, status:isStatusActive ? 1 : 0}).then((res => {
          showToastMessage(res?.message);
          handleReload();
          openReasonMangerPopup();
        })).catch((err) => {
          showToastMessage(err?.message, false)
        });
      }
    }

    const handleRejectReasonAdd = () => {
      if(!rejectReason?.length){
        showToastMessage("Enter Reject Reason", false);
      }
      else{
        AdministratorService.addRejectReason({reason:rejectReason, status:isStatusActive ? 1 : 0}).then((res => {
          showToastMessage(res?.message);
          handleReload();
          openReasonMangerPopup();
        })).catch((err) => {
          showToastMessage(err?.message, false)
        });
      }
    }

    const handleVerificationRejectReasonAdd = () => {
      if(!verificationRejectReason?.length){
        showToastMessage("Enter Reject Reason", false);
      }
      else{
        AdministratorService.addVerificationRejectReason({reason:verificationRejectReason, status:isStatusActive ? 1 : 0}).then((res => {
          showToastMessage(res?.message);
          handleReload();
          openReasonMangerPopup();
        })).catch((err) => {
          showToastMessage(err?.message, false)
        });
      }
    }

    const handleUnfulfillReasonAdd = () => {
      if(!unFullfillReason?.length){
        showToastMessage("Enter Unable to Fullfill Reason", false);
      }
      else{
        AdministratorService.addUnfulfillReason({reason:unFullfillReason, status:isStatusActive ? 1 : 0}).then((res => {
          showToastMessage(res?.message);
          handleReload();
          openReasonMangerPopup();
        })).catch((err) => {
          showToastMessage(err?.message, false)
        });
      }
    }

    const handleDelayReasonEdit = () => {
        AdministratorService.updateDelayReason({idChallanReason:selectedDelay?.value, status:isStatusActive ? 1 : 0}).then((res => {
            showToastMessage(res?.message);
            handleReload();
            openReasonMangerPopup();
          })).catch((err) => {
            showToastMessage(err?.message, false)
          });
        }

      const handleRejectReasonEdit = () => {
          AdministratorService.updateRejectReason({idChallanReason:selectedRejection?.value, status:isStatusActive ? 1 : 0}).then((res => {
              showToastMessage(res?.message);
              handleReload();
              openReasonMangerPopup();
            })).catch((err) => {
              showToastMessage(err?.message, false)
            });
          }

      const handleVerificationRejectReasonEdit = () => {
          AdministratorService.updateVerificationRejectReason({idChallanReason:selectedVerificationRejection?.value, status:isStatusActive ? 1 : 0}).then((res => {
              showToastMessage(res?.message);
              handleReload();
              openReasonMangerPopup();
            })).catch((err) => {
              showToastMessage(err?.message, false)
            });
          }

      const handleUnfulfillReasonEdit = () => {
            AdministratorService.updateUnfulfillReason({idChallanReason:selectedUnFullfill?.value, status:isStatusActive ? 1 : 0}).then((res => {
                showToastMessage(res?.message);
                handleReload();
                openReasonMangerPopup();
              })).catch((err) => {
                showToastMessage(err?.message, false)
              });
            }

    const handleOffenseReasonEdit = () => {
        AdministratorService.updateOffenseReason({idChallanOffence:selectedOffence?.value, status:isStatusActive ? 1 : 0}).then((res => {
            showToastMessage(res?.message);
            handleReload();
            openReasonMangerPopup();
          })).catch((err) => {
            showToastMessage(err?.message, false)
          });
        }

    const handleDelayReasonDelete = () => {
        AdministratorService.deleteDelayReason(selectedDelay?.value).then((res => {
            showToastMessage(res?.message);
            handleReload();
            openReasonMangerPopup();
          })).catch((err) => {
            showToastMessage(err?.message, false)
          });
        }

    const handleRejectReasonDelete = () => {
          AdministratorService.deleteRejectReason(selectedRejection?.value).then((res => {
              showToastMessage(res?.message);
              handleReload();
              openReasonMangerPopup();
            })).catch((err) => {
              showToastMessage(err?.message, false)
            });
          }
    const handleVerificationRejectReasonDelete = () => {
          AdministratorService.deleteVerificationRejectReason(selectedVerificationRejection?.value).then((res => {
              showToastMessage(res?.message);
              handleReload();
              openReasonMangerPopup();
            })).catch((err) => {
              showToastMessage(err?.message, false)
            });
          }

    const handleUnfulfillReasonDelete = () => {
        AdministratorService.deleteUnfulfillReason(selectedUnFullfill?.value).then((res => {
            showToastMessage(res?.message);
            handleReload();
            openReasonMangerPopup();
          })).catch((err) => {
            showToastMessage(err?.message, false)
          });
        }
    const handleOffenseReasonDelete = () => {
          AdministratorService.deleteOffenseReason(selectedOffence?.value).then((res => {
              showToastMessage(res?.message);
              handleReload();
              openReasonMangerPopup();
            })).catch((err) => {
              showToastMessage(err?.message, false)
            });
          }
    
    const handleOffenceReasonAdd = () => {
      if(!offenceReason?.length){
        showToastMessage("Add Offence Reason",false);
      }
      else{
        AdministratorService.addOffenceReason({offenceName:offenceReason}).then((res => {
            showToastMessage(res?.message);
            handleReload();
            openReasonMangerPopup();
          })).catch((err) => {
            showToastMessage(err?.message, false)
          }); 
      }
    }

    const handleStatusChange = (e) => {
        const {value} = e || {};
        setIsStatusActive(value);
    }

    const handleReset = () => {
      setDelayReason("");
      setOffenceReason("");
      setRejectReason("");
      setUnFullfillReason("");
      setIsStatusActive(false);
    }


  return (
    <div className='ops-grid-layout-container' style={{padding:"0px"}}>
      <div className='administrator-popup'>
          {
        ctaAction === "Add Delay Reason" && (
          <>
            <FormInput
                      id={"addDelayReason"}
                      key={"addDelayReason"}
                      onChange={handleReasonChange}
                      value={delayReason}
                      label={"Enter Delay Reason"}
            />
            <FormCheckbox name={"Active"} label={"Active"} onChange={handleStatusChange} value={isStatusActive}/>
            <div className='popup-cta-container'>
            <Button themeColor={"primary"} fillMode={"outline"} className="m-3" onClick={handleReset}>Reset</Button>
            <Button themeColor={"primary"} className="m-3" onClick={handleDelayReasonAdd}>Confirm</Button>
            </div>
          </>
        )
      }

  {
        ctaAction === "Delete Delay Reason" && (
          <>
            <FormInput
                      id={"deleteDelayReason"}
                      key={"deleteDelayReason"}
                      onChange={handleReasonChange}
                      value={selectedDelay?.label}
                      disabled={true}
                      label={"Delete Delay Reason"}
            />
             <FormCheckbox name={"Active"} label={deleteCheckboxLabel} />
            <Button themeColor={"primary"} className="m-3" onClick={handleDelayReasonDelete}>Confirm</Button>
          </>
        )
      }

  {
        ctaAction === "Edit Delay Reason" && (
          <>
            <FormInput
                    id={"editDelayReason"}
                    key={"editDelayReason"}
                    onChange={handleReasonChange}
                    value={selectedDelay?.label}
                    label={"Edit Delay Reason"}
                    disabled={true}
        />
          <FormCheckbox name={"Active"} label={"Active"} onChange={handleStatusChange} value={isStatusActive}/>
        <Button themeColor={"primary"} className="m-3" onClick={handleDelayReasonEdit}>Confirm</Button>
          </>
        )
      }

  {
        ctaAction === "Add Offence" && (
          <>
            <FormInput
                      id={"addDelayReason"}
                      key={"addDelayReason"}
                      onChange={handleOffenceChange}
                      value={offenceReason}
                      label={"Add Offence Reason"}
            />
            <div className='popup-cta-container'>
            <Button themeColor={"primary"} fillMode={"outline"} className="m-3" onClick={handleReset}>Reset</Button>
            <Button themeColor={"primary"} className="m-3" onClick={handleOffenceReasonAdd}>Confirm</Button>
            </div>
          </>
        )
      }

      {
        ctaAction === "Delete Offence Reason" && (
          <>
            <FormInput
                      id={"deleteOffenceReason"}
                      key={"deleteOffenceReason"}
                      value={selectedOffence?.label}
                      disabled={true}
                      label={"Delete Offence Reason"}
            />
             <FormCheckbox name={"Active"} label={deleteCheckboxLabel} />
            <Button themeColor={"primary"} className="m-3" onClick={handleOffenseReasonDelete}>Confirm</Button>
          </>
        )
      }
      {
        ctaAction === "Edit Offence Reason" && (
          <>
            <FormInput
                    id={"editOffenceReason"}
                    key={"editOffenceReason"}
                    value={selectedOffence?.label}
                    label={"Edit Offence Reason"}
                    disabled={true}
        />
          <FormCheckbox name={"Active"} label={"Active"} onChange={handleStatusChange} value={isStatusActive}/>
        <Button themeColor={"primary"} className="m-3" onClick={handleOffenseReasonEdit}>Confirm</Button>
          </>
        )
      }

     {
        ctaAction === "Add Reject Reason" && (
          <>
            <FormInput
                      id={"addRejectReason"}
                      key={"addRejectReason"}
                      onChange={handleRejectChange}
                      value={rejectReason}
                      label={"Enter Reject Reason"}
            />
            <FormCheckbox name={"Active"} label={"Active"} onChange={handleStatusChange} value={isStatusActive}/>
            <div className='popup-cta-container'>
            <Button themeColor={"primary"} fillMode={"outline"} className="m-3" onClick={handleReset}>Reset</Button>
            <Button themeColor={"primary"} className="m-3" onClick={handleRejectReasonAdd}>Confirm</Button>
            </div>
          </>
        )
      }
      {
        ctaAction === "Delete Reject Reason" && (
          <>
            <FormInput
                      id={"deleteRejectReason"}
                      key={"deleteRejectReason"}
                      onChange={handleReasonChange}
                      value={selectedRejection?.label}
                      disabled={true}
                      label={"Delete Reject Reason"}
            />
             <FormCheckbox name={"Active"} label={deleteCheckboxLabel} />
            <Button themeColor={"primary"} className="m-3" onClick={handleRejectReasonDelete}>Confirm</Button>
          </>
        )
      }
      {
        ctaAction === "Edit Reject Reason" && (
          <>
            <FormInput
                    id={"editRejectReason"}
                    key={"editRejectReason"}
                    onChange={handleRejectChange}
                    value={selectedRejection?.label}
                    label={"Edit Reject Reason"}
                    disabled={true}
        />
        <FormCheckbox name={"Active"} label={"Active"} onChange={handleStatusChange} value={isStatusActive}/>
        <Button themeColor={"primary"} className="m-3" onClick={handleRejectReasonEdit}>Confirm</Button>
          </>
        )
      }

{
        ctaAction === "Add Verification Reject Reason" && (
          <>
            <FormInput
                      id={"addVerificationRejectReason"}
                      key={"addVerificationRejectReason"}
                      onChange={handleVerificationRejectChange}
                      value={verificationRejectReason}
                      label={"Enter Reject Reason"}
            />
            <FormCheckbox name={"Active"} label={"Active"} onChange={handleStatusChange} value={isStatusActive}/>
            <div className='popup-cta-container'>
            <Button themeColor={"primary"} fillMode={"outline"} className="m-3" onClick={handleReset}>Reset</Button>
            <Button themeColor={"primary"} className="m-3" onClick={handleVerificationRejectReasonAdd}>Confirm</Button>
            </div>
          </>
        )
      }
      {
        ctaAction === "Delete Verification Reject Reason" && (
          <>
            <FormInput
                      id={"deleteVerificationRejectReason"}
                      key={"deleteVerificationRejectReason"}
                      value={selectedVerificationRejection?.label}
                      disabled={true}
                      label={"Delete Verification Reject Reason"}
            />
             <FormCheckbox name={"Active"} label={deleteCheckboxLabel} />
            <Button themeColor={"primary"} className="m-3" onClick={handleVerificationRejectReasonDelete}>Confirm</Button>
          </>
        )
      }
      {
        ctaAction === "Edit Verification Reject Reason" && (
          <>
            <FormInput
                    id={"editVerificationRejectReason"}
                    key={"editVerificationRejectReason"}
                    value={selectedVerificationRejection?.label}
                    label={"Edit Reject Reason"}
                    disabled={true}
        />
        <FormCheckbox name={"Active"} label={"Active"} onChange={handleStatusChange} value={isStatusActive}/>
        <Button themeColor={"primary"} className="m-3" onClick={handleVerificationRejectReasonEdit}>Confirm</Button>
          </>
        )
      }
      {
        ctaAction === "Add Unable to Fullfill Reason" && (
          <>
            <FormInput
                      id={"addUnFullfillReason"}
                      key={"addUnFullfillReason"}
                      onChange={handleUnFullfillChange}
                      value={unFullfillReason}
                      label={"Enter Unable to Fullfill Reason"}
            />
            <FormCheckbox name={"Active"} label={"Active"} onChange={handleStatusChange} value={isStatusActive}/>
            <div className='popup-cta-container'>
            <Button themeColor={"primary"} fillMode={"outline"} className="m-3" onClick={handleReset}>Reset</Button>
            <Button themeColor={"primary"} className="m-3" onClick={handleUnfulfillReasonAdd}>Confirm</Button>
            </div>
          </>
        )
      }

      {
        ctaAction === "Delete Unable to Fullfill Reason" && (
          <>
            <FormInput
                      id={"deleteUnFullfillReason"}
                      key={"deleteUnFullfillReason"}
                      value={selectedUnFullfill?.label}
                      disabled={true}
                      label={"Delete Unable to Fullfill Reason"}
            />
            <FormCheckbox name={"Active"} label={deleteCheckboxLabel}/>
            <Button themeColor={"primary"} className="m-3" onClick={handleUnfulfillReasonDelete}>Confirm</Button>
          </>
        )
      }

{
        ctaAction === "Edit Unable to Fullfill Reason" && (
          <>
            <FormInput
                    id={"editUnFullfillReason"}
                    key={"editUnFullfillReason"}
                    value={selectedUnFullfill?.label}
                    label={"Edit Unable to Fullfill Reason"}
                    disabled={true}
        />
        <FormCheckbox name={"Active"} label={"Active"} onChange={handleStatusChange} value={isStatusActive}/>
        <Button themeColor={"primary"} className="m-3" onClick={handleUnfulfillReasonEdit}>Confirm</Button>
          </>
        )
      }
      
      </div>
    </div>
  )
}

export default ReasonManagerPopup
