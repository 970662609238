import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    StackLayout,
    TabStrip,
    TabStripTab,
} from "@progress/kendo-react-layout";
import { ASSIGNED_LEAD_KEY, SFTabStrips } from "./viewModel";
import { getUserRoles, showToastMessage } from "../../../utils/utils";
import { USER_ROLE_KEYS } from "../../../utils/constants/menu.constants";
import { SFService } from "../../../services";
import UnassignedLead from "./containers/UnassignedLeads";
import AssignedLeads from "./containers/AssignedLeads";
import { ASSIGNED_LEAD_FILTERS } from "./viewModel";
import useMetadata from "../common/hooks/useMetadata";
import gridClasses from "./styles/LeadsGrid.module.css";
import LeadsFilter from "./components/LeadsFilter";
import { getTabFromState } from "./utils/helper";
import { LeadsFilterContextProvider } from "./context/LeadsFilterContext";
import classes from "./styles/leadsListing.module.css";
import AgentSyncButton from "./components/AgentSyncButton";
import { TOAST_MESSAGES } from "./constants";
import { AgentListContextProvider } from "./context/AgentListContext";
import { useLocation } from "react-router-dom";

const Leads = () => {
    const { state = {} } = useLocation();
    const { subTabKey = "" } = state;
    const { tab, subTab } = getTabFromState(subTabKey);

    const [selectedTab, setSelectedTab] = useState(tab);
    const [filterData, setFilterData] = useState(ASSIGNED_LEAD_FILTERS);
    const [leadsFilter, setLeadsFilter] = useState({
        type: null,
        value: "",
    });
    const [agentList, setAgentList] = useState([]);

    const { metadata, loading } = useMetadata();

    const roles = useMemo(
        () =>
            Object.values(getUserRoles()).reduce((acc, curr) => {
                acc[curr] = true;
                return acc;
            }, {}),
        []
    );

    const currentRole = useMemo(() => {
        if (roles[USER_ROLE_KEYS.C2B_FINANCE_SELLER_AGENT]) {
            return USER_ROLE_KEYS.C2B_FINANCE_SELLER_AGENT;
        } else if (roles[USER_ROLE_KEYS.C2B_FINANCE_SELLER_ADMIN]) {
            return USER_ROLE_KEYS.C2B_FINANCE_SELLER_ADMIN;
        }
    }, [roles]);

    const renderTabStrips = () =>
        SFTabStrips[currentRole]?.map((item) => {
            return (
                <TabStripTab title={item.label} key={item.key}>
                    {renderTabStripComponent(item.key)}
                </TabStripTab>
            );
        });

    const renderTabStripComponent = (key) => {
        switch (key) {
            case "UNASSIGNED":
                return <UnassignedLead />;
            case "ASSIGNED":
                return (
                    <AssignedLeads
                        filterData={filterData}
                        setFilterData={setFilterData}
                        initialSubTab={subTab}
                    />
                );
            case "ADMIN_UNASSIGNED":
                return <UnassignedLead isAdmin filterData={filterData} />;
            case "ADMIN_ASSIGNED":
                return (
                    <AssignedLeads
                        isAdmin
                        filterData={filterData}
                        setFilterData={setFilterData}
                        initialSubTab={subTab}
                    />
                );
            default:
                return <></>;
        }
    };

    const handleTabSelect = (e) => {
        setSelectedTab(e.selected);
    };

    const fetchAgentList = async () => {
        try {
            setAgentList([]);
            const resp = await SFService.fetchAgentList();
            if (resp && resp.success) {
                setAgentList(resp?.detail || []);
            }
        } catch (error) {
            console.log(error);
            showToastMessage(
                error?.errorMessage || TOAST_MESSAGES.SYNC_AGENTS.ERROR.message,
                false,
                _,
                TOAST_MESSAGES.SYNC_AGENTS.ERROR.id
            );
        }
    };

    const handleSyncAgents = useCallback(async () => {
        try {
            const resp = await SFService.syncAgents();
            if (resp && resp.success) {
                fetchAgentList();
                showToastMessage(
                    TOAST_MESSAGES.SYNC_AGENTS.SUCCESS.message,
                    true,
                    _,
                    TOAST_MESSAGES.SYNC_AGENTS.SUCCESS.id
                );
            }
        } catch (error) {
            console.log(error);
            showToastMessage(
                error?.errorMessage || TOAST_MESSAGES.SYNC_AGENTS.ERROR.message,
                false,
                _,
                TOAST_MESSAGES.SYNC_AGENTS.ERROR.id
            );
        }
    }, []);

    const handleFetchCities = async () => {
        try {
            const response = await SFService.fetchCities();
            const { detail = [] } = response || {};
            setFilterData((filterData) => ({
                ...filterData,
                city: detail?.map((city) => ({ label: city, value: city })),
            }));
        } catch (error) {
            console.log(error);
            showToastMessage(
                error?.errorMessage || "Failed to fetch cities",
                false,
                _,
                "fetch-city-error"
            );
        }
    };

    const handleLeadsFilterChange = (filter) => {
        const { type = null, value = "" } = filter;

        setLeadsFilter({
            type,
            value,
        });
    };

    useEffect(() => handleFetchCities(), []);

    useEffect(
        () =>
            currentRole === USER_ROLE_KEYS.C2B_FINANCE_SELLER_ADMIN
                ? fetchAgentList()
                : null,
        [currentRole]
    );

    useEffect(() => {
        if (metadata && !loading) {
            setFilterData((prev) => ({
                ...prev,
                [ASSIGNED_LEAD_KEY.STATUS]: Object.entries(
                    metadata?.leadStatus || {}
                ).map(([value, label]) => ({ label, value })),

                [ASSIGNED_LEAD_KEY.INTERNAL_STATUS]: Object.entries(
                    metadata?.dispositionStatus || {}
                ).map(([value, label]) => ({ label, value })),

                [ASSIGNED_LEAD_KEY.LAST_CALL_STATUS]: Object.entries(
                    metadata?.callStatus || {}
                ).map(([value, label]) => ({ label, value })),
                [ASSIGNED_LEAD_KEY.LEAD_SOURCE]: Object.entries(
                    metadata?.leadSource || {}
                ).map(([value, label]) => ({ label, value })),
            }));
        }
    }, [metadata, loading]);

    return (
        <div className="ops-challan-container">
            <div className="ops-grid-layout-container relative">
                <AgentListContextProvider value={agentList}>
                    <LeadsFilterContextProvider value={leadsFilter}>
                        <TabStrip
                            className={gridClasses.sfLeadsTabs}
                            selected={selectedTab}
                            onSelect={handleTabSelect}
                        >
                            {renderTabStrips()}
                        </TabStrip>
                    </LeadsFilterContextProvider>
                    <StackLayout className={classes.filtersContainer}>
                        <LeadsFilter
                            isAdmin={
                                currentRole ===
                                USER_ROLE_KEYS.C2B_FINANCE_SELLER_ADMIN
                            }
                            onChange={handleLeadsFilterChange}
                        />
                        {currentRole ===
                            USER_ROLE_KEYS.C2B_FINANCE_SELLER_ADMIN && (
                            <AgentSyncButton syncAgentList={handleSyncAgents} />
                        )}
                    </StackLayout>
                </AgentListContextProvider>
            </div>
        </div>
    );
};

export default Leads;
