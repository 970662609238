import "./confirm-contacted-modal.scss";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useDispatch, useSelector } from "react-redux";
import { showMarkAsContacted } from "../../../store/actions/myDealers";
import { markDealerContacted } from "../../../store/actions/myDealers";
const ConfirmContactedModal = ({  }) => {
  const { markAsContactedConfirm } = useSelector((state) => state.myDealers);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(showMarkAsContacted(false));
  }
  return (
    <>
      <div >
        <Dialog
          title={"Confirm"}
          className="app-confirm-contact-modal" onClose={() => onClose()}>

          <DialogActionsBar>
            <div className="modal-title">Are you sure you want to mark as contacted?</div>
            <div className="modal-btn-block">
              <Button
                className='app-cancel-btn'
                fillMode={'outline'}
                onClick={() => { onClose() }}
              >
                Cancel
              </Button>
              <Button
                className='app-primary-btn'
                fillMode={'outline'}
                onClick={() => {
                  dispatch(markDealerContacted({ ...markAsContactedConfirm }))
                  onClose()
                }}
              >
                Mark as contacted
              </Button>
            </div>
          </DialogActionsBar>

        </Dialog>
      </div>
    </>
  );
};
export default ConfirmContactedModal;
