export default (api) => {
  const fetchSlots = (params = "") => {
    const url = "api/v1/appointment";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}/${params}/pickup/slots`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const schedulePickup = (payload) => {
    const url = `v1/pickup-request/schedule`;
    return api.post(`${url}`, payload);
  };
  return {
    fetchSlots,
    schedulePickup,
  };
};
