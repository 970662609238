import { getBaseUrl } from "../utils/constants/url.constants";
import { getFFUserType, getLoginUserEmail } from "../utils/utils";
import apiInterceptor from "./apiInterceptor";

const hypoQcService = (api) => {
    const BASE_ENDPOINT = 'oms/purchase/v1/hypo';

    const getAllData = (payload) => {
        const { page, size, filter } = payload || { page: 0, size: 10, filter: {} }
        let filterPayload = ''
        filter?.map((item) => {
            filterPayload = filterPayload + `&${item.field}=${item.value}`
        })
        const endpoint = `${BASE_ENDPOINT}/list?pageNumber=${page}&pageSize=${size}${filterPayload}`;

        return api.get(endpoint, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        })
    };

    const getDetails = (payload) => {
        const endpoint = `${BASE_ENDPOINT}/${payload.appointmentId}/details`;

        return api.get(endpoint, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        })
    };

    const downloadCsv = (payload) => {
        const { page, size, filter } = payload || { page: 0, size: 10, filter: {} }
        let filterPayload = ''
        filter?.map((item) => {
            filterPayload = filterPayload + `&${item.field}=${item.value}`
        })
        const endpoint = `${BASE_ENDPOINT}/data-file?pageNumber=${page}&pageSize=${size}${filterPayload}`;

        return api.get(endpoint, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        })
    };

    return {
        getAllData,
        getDetails,
        downloadCsv
    };
};

export const hypoQcApis = hypoQcService(apiInterceptor(getBaseUrl()));

