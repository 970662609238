export const recurringOptions = [
    { label: 'Never', value: 'NEVER' },
    { label: 'Daily', value: 'DAILY' },
    { label: 'Weekly', value: 'WEEKLY' },
    { label: 'Monthly', value: 'MONTHLY' },
];

export const DATE_TIME_PICKER_DATA = [
    {
        title: 'Trigger Date & Time',
        name: 'triggerDateTime',
        placeholder: 'Select Trigger Date & Time'
    },
    {
        title: 'Expiry Date & Time',
        name: 'expiryDateTime',
        placeholder: 'Select Expiry Date & Time'
    },
]

export const CARD_STYLE = {
    background: "#FFFFFF",
    minHeight: "180px",
    border: "15px solid #EBF5FF",
    boxShadow: "unset",
    borderRadius: "8px",
};

export const HEADER_STYLE = {
    color: "#003E72",
    fontWeight: "600",
    fontSize: "18px",
};