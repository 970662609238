import { useEffect, useRef, useState, useCallback } from "react";

/**
 * A custom React hook for implementing polling functionality.
 *
 * @param {Function} asyncCallback - An asynchronous function to be called at each polling interval.
 * @param {Array} [dependencies=[]] - An array of dependencies that will trigger the effect to re-run when changed.
 * @param {Object} [options={}] - Configuration options for the polling behavior.
 * @param {number} [options.interval=10000] - The interval between polls in milliseconds.
 * @param {Function} [options.onCleanUp=()=>{}] - A function to be called when the effect is cleaned up.
 * @param {Function} [options.onError=()=>{}] - A function to handle errors that occur during the asyncCallback execution.
 * @param {boolean} [options.enabled=false] - Whether polling should be enabled initially.
 * @param {number} [options.duration=null] - The duration in milliseconds after which polling should automatically stop. If null, polling continues indefinitely.
 * @param {Function} [options.onDurationExpire=null] - A function to be called when the polling duration expires.
 * 
 * @returns {Array} An array containing:
 *   - {boolean} isPolling - The current polling state.
 *   - {Function} startPolling - A function to start polling.
 *   - {Function} stopPolling - A function to stop polling.
 *
 * @example
 * const [isPolling, startPolling, stopPolling] = usePollingEffect(
 *   async () => {
 *     // Your polling logic here
 *   },
 *   [dependency1, dependency2],
 *   { 
 *     interval: 5000, 
 *     onError: (error) => console.error(error), 
 *     duration: 60000,
 *     onDurationExpire: () => console.log("Polling duration expired")
 *   }
 * );
 */

function usePollingEffect(
  asyncCallback,
  dependencies = [],
  { 
    interval = 10000,
    onCleanUp = () => {},
    onError = () => {},
    enabled = false,
    duration = null,
    onDurationExpire = null
  } = {},
) {
  const timeoutIdRef = useRef(null);
  const durationTimeoutRef = useRef(null);
  const [isPolling, setIsPolling] = useState(enabled);

  const safeAsyncCallback = useCallback(async () => {
    try {
      const result = await asyncCallback();
      if (result && result.stopPolling) {
        setIsPolling(false);
      }
      return result;
    } catch (error) {
      onError(error);
      return { stopPolling: false };
    }
  }, [asyncCallback, onError]);

  const stopPolling = useCallback(() => {
    setIsPolling(false);
    clearTimeout(durationTimeoutRef.current);
  }, []);

  const handleDurationExpire = useCallback(() => {
    stopPolling();
    if (onDurationExpire) {
      onDurationExpire();
    }
  }, [stopPolling, onDurationExpire]);

  useEffect(() => {
    if (!isPolling) {
      return;
    }

    let _stopped = false;

    (async function pollingCallback() {
      try {
        await safeAsyncCallback();
      } finally {
        timeoutIdRef.current = !_stopped && isPolling && setTimeout(
          pollingCallback,
          interval
        );
      }
    })();

    // Set up duration timeout if specified
    if (duration !== null) {
      durationTimeoutRef.current = setTimeout(handleDurationExpire, duration);
    }

    return () => {
      _stopped = true;
      clearTimeout(timeoutIdRef.current);
      clearTimeout(durationTimeoutRef.current);
      onCleanUp();
    };
  }, [...dependencies, interval, isPolling, safeAsyncCallback, duration, handleDurationExpire]);

  const startPolling = useCallback(() => {
    setIsPolling(true);
    // Reset duration timeout when starting polling
    if (duration !== null) {
      clearTimeout(durationTimeoutRef.current);
      durationTimeoutRef.current = setTimeout(handleDurationExpire, duration);
    }
  }, [duration, handleDurationExpire]);

  return [isPolling, startPolling, stopPolling];
}

export default usePollingEffect;