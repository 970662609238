import { CircularGauge } from '@progress/kendo-react-gauges';

import './score-card-modal.scss';
import AppTable from '../../common/components/app-table';
import { FIELDS_KEY, TABLE_LABELS } from '../../common/constants/table.constants';
import { getColor, roundCallScore } from '../../common/utils/utils';


const centerRenderer = (value) => {
  return (
    <h3
      style={{
        color: getColor(value)?.color,
      }}
    >
      {roundCallScore(value)}/10
    </h3>
  );
};


const ScoreCardModal = ({ dataItem }) => {

  const roundedCallScore = roundCallScore(dataItem?.callScore);

  const arcOptions = {
    value: roundCallScore(dataItem?.callScore) * 10,
    colors: [
      { from: 0, to: 50, color: '#ff6347' },
      { from: 50, to: 80, color: '#FFD700' },
      { from: 80, to: 100, color: '#28A745' }
    ],
    scale: {
      rangeSize: 10
    }

  };

  const columns = [
    {
      field: FIELDS_KEY.ActionItem,
      title: TABLE_LABELS.ActionItem,
      cells: ({ dataItem }) => {
        return (
          <td className="k-table-td relative quality-container">
            <span>{dataItem?.parameter}</span>
          </td>
        )
      },
      maxWidth: 140

    },
    {
      field: FIELDS_KEY.Todo,
      title: TABLE_LABELS.Todo,
      cells: ({ dataItem }) => {
        return (
          <td className="k-table-td relative ">
            <span>{dataItem?.subParameter}</span>
          </td>
        )
      }
    },
    {
      field: FIELDS_KEY.Score,
      title: TABLE_LABELS.Score,
      cells: ({ dataItem }) => {
        const roundedScore = roundCallScore(dataItem.score);
        const color = getColor(roundedScore);
        return (
          <td className="k-table-td relative">
            <span className='score-modal-chips' style={{ color: color.color, backgroundColor: color.background }}>{roundedScore}/3</span>
          </td>
        )
      }
    },
    {
      field: FIELDS_KEY.ReasonForScore,
      title: TABLE_LABELS.ReasonForScore,
      cells: ({ dataItem }) => {
        return (
          <td className="k-table-td relative">
            <span>{dataItem?.reason}</span>
          </td>
        )
      }
    },
    {
      field: FIELDS_KEY.WhatShouldIDo,
      title: TABLE_LABELS.WhatShouldIDo,
      cells: ({ dataItem }) => {
        return (
          <td className="k-table-td relative">
            <span>{dataItem?.idealInsight}</span>
          </td>
        )
      }
    },

  ];

  return (
    <div className="card-container">
      <div className="card-header">
        <div className='gauge-container'>
          <CircularGauge
            {...arcOptions}
            style={{
              display: 'block',
              height: 100
            }}
            centerRender={() => centerRenderer(roundedCallScore)} />
        </div>
        <div className='score-information'>
          <span className='call-score'>
            Call Score <span className='score-value' style={{ color: getColor(roundedCallScore).progressBarColor }}>{roundedCallScore}/10</span>
          </span>
        </div>
      </div>

      <div className='score-modal-table'>
        <AppTable
          data={dataItem?.callScoreParameters}
          headerData={[
            ...columns
          ]}
          pageable={false}
        />
      </div>

    </div>
  )
}

export default ScoreCardModal