import React, { useState, useEffect } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import styles from "../assets/style/ff-layout.module.css";
import TabGridLayout from "../components/layout-tab-grid";
import SearchBarLayout from "../components/layout-search-bar";
import ActionBarLayout from "../components/layout-action-bar";
import srcEditImg from "../../../assets/img/edit-ico.svg";

import {
  CHALLAN_REQUEST_STATUSES,
  // CHALLAN_SOURCE_KEYS,
  FF_PAGE_TYPES,
  NUMBERS,
  SORT_DIRECTION_KEYS,
} from "../../../utils/constants/values.constants";
import {
  challan_request_keys,
  getSelectedAssignRowIds,
  getSelectedMarkAsResolvedRowIds,
  SELECTED_FIELD_KEY,
  page_params,
  getPageTitle,
} from "../challan-list/viewModels";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChallanOrders,
  // getRequestChallanItems,
  updateRequestFiltersParams,
  updateAssignmentTypeParams,
} from "../../../store/actions/ops-challan";
import {
  convertObjectToParams,
  getFFUserType,
  isObjectEmpty,
  showToastMessage,
  getArrayItemValueByLabel,
  getUserFirstNameFromEmail,
  isFilterFieldPresent,
} from "../../../utils/utils";
import { getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { ChallanService } from "../../../services";
import { finder_columns, DD_DATA_SOURCES } from "./viewModal";
import { ColumnMenuCheckboxFilter } from "../../../components/custom-grid-filters";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "@progress/kendo-react-buttons";
import { USER_ROLE_KEYS } from "../../../utils/constants/menu.constants";
import {
  ADMIN_ASSIGN_AGENT_POPUP,
  FORM_TYPES,
} from "../components/dispose-challan-item-popup/viewModel";
import ChallanItemPopup from "../components/dispose-challan-item-popup";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import FinderDetailView from "./finder-detail-container";
const idGetter = getter(challan_request_keys.REQUEST_ID);
const ALLOWED_SORT_PARAMS = [
  challan_request_keys.AGEING,
  challan_request_keys.ATTEMP_COUNT,
];
const initialSort = [
  {
    field: challan_request_keys.CREATED_AT,
    dir: SORT_DIRECTION_KEYS.DESC,
  },
];
const initialFilter = {
  filters: [],
  logic: "and",
};
const FinderContainerView = (props) => {
  const [visibleColumns, setVisibleColumns] = useState(finder_columns);
  const [paginationParams, setPaginationParams] = useState(page_params);
  const [filterParams, setFilterParams] = useState({ isAssigned: 0 });
  const [sources, setSources] = useState(DD_DATA_SOURCES);
  const [selectedState, setSelectedState] = React.useState({});
  const [isRefreshRequired, setIsRefreshRequired] = useState(false);
  const [isDataExport, setIsDataExport] = useState(false);
  const [sortParms, setSortParms] = useState(initialSort);
  const [gridFilters, setGridFilters] = useState(initialFilter);
  const [currentPopup, setCurrentPopup] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [challanRequestData, setChallanRequestData] = useState();
  const dispatch = useDispatch();
  const opsChallan = useSelector((state) => state.opsChallan);
  const {
    isLoading,
    challanOrders: { data: requestData, totalCount } = {},
    userAssignments,
    channelSource,
    // addNewChallanRequest = false,
    searchQuery,
    // filters,
  } = opsChallan || {};
  let loadKey = "";
  const isQA = ["STAGING", "QA"].includes(process.env.REACT_APP_ENV);
  const history = useHistory();
  const userType = getFFUserType();
  const pageKey = getPageTitle();
  const pageChange = ({
    page = page_params.pageNumber,
    size = page_params.pageSize,
  }) => {
    setPaginationParams({ pageNumber: page, pageSize: size });
  };
  const sortChange = (e) => {
    let sortData = !!e?.sort.length ? e?.sort : initialSort;
    let sortKey = sortData[0]?.field;
    let sortDir = sortData[0].dir;
    if (!e?.sort?.length) {
      sortDir =
        sortParms[0].dir === SORT_DIRECTION_KEYS.DESC
          ? SORT_DIRECTION_KEYS.ASC
          : SORT_DIRECTION_KEYS.DESC;
    }
    if (sortKey === challan_request_keys.CREATED_AT) {
      sortKey = challan_request_keys.AGEING;
    }

    if (ALLOWED_SORT_PARAMS.includes(sortKey)) {
      const params = [
        {
          field: sortKey,
          dir: sortDir,
        },
      ];
      setSortParms([{ field: sortData[0].field, dir: sortDir }]);
      fetchChallanRequestOrders("sort", params);
    }
  };
  const fetchChallanRequestOrders = (key, sort = []) => {
    if (!loadKey && !isLoading) {
      loadKey = key;
      const params = { ...paginationParams, ...filterParams };
      let qsParams = convertObjectToParams(params);
      if (!!sort.length) {
        qsParams = `${qsParams}&sortBy=${sort[0].field}&sortDir=${sort[0].dir}`;
      }
      dispatch(fetchChallanOrders(qsParams));
    }
  };
  const handleFilterButtonClick = React.useCallback(
    (key, e, data, assign) => {
      if (!!e) {
        setGridFilters(e);
        setPaginationParams({ pageNumber: page_params.pageNumber, pageSize: page_params.pageSize });
        let _filterParamsData = { ...filterParams };
        // delete _filterParamsData.isAssigned;
        delete _filterParamsData[key];
        if (assign !== NUMBERS.FOUR) {
          _filterParamsData = { ..._filterParamsData, isAssigned: assign };
        }
        e?.filters.map((item) => {
          const updatedFilter = item?.filters.reduce((acc, cur) => {
            const existingItem = acc?.find(
              (transformedItem) => transformedItem[cur.field]
            );
            const itemValue = getArrayItemValueByLabel(
              data[cur.field],
              cur.value
            );
            if (existingItem) {
              existingItem[cur.field] += `,${itemValue}`;
            } else {
              acc.push({ [cur.field]: itemValue });
            }
            return acc;
          }, []);

          const filterParamsData = updatedFilter.reduce(
            (result, obj) => ({ ...result, ...obj }),
            {}
          );
          _filterParamsData = { ..._filterParamsData, ...filterParamsData };
        });
        setFilterParams(_filterParamsData);
        dispatch(updateRequestFiltersParams({ ..._filterParamsData }));
      }
    },
    [userAssignments, filterParams]
  );
  const onRowSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: challan_request_keys.REQUEST_ID,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
      item[SELECTED_FIELD_KEY] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  const AssignToMe = () => {
    const rowIds = getSelectedAssignRowIds(selectedState, requestData);
    if (rowIds.length > NUMBERS.ZERO) {
      ChallanService.assignToMeRequests(rowIds)
        .then((resp) => {
          showToastMessage("Challan requests assigned successfully");
          setTimeout(() => {
            fetchChallanRequestOrders("reload");
            setSelectedState({});
          }, 1000);
        })
        .catch((err) => {
          showToastMessage(err?.message || "Failed", false);
        });
    } else {
      showToastMessage(
        "Please select atleast one unassigned row to continue",
        false
      );
    }
  };
  const MarkAsResolved = () => {
    const rowIds = getSelectedMarkAsResolvedRowIds(selectedState, requestData);
    if (rowIds.length > NUMBERS.ZERO) {
      ChallanService.markAsResolvedRequests(rowIds)
        .then((resp) => {
          showToastMessage("Challan requests resolved successfully");
          setTimeout(() => {
            fetchChallanRequestOrders("reload");
            setSelectedState({});
          }, 1000);
        })
        .catch((err) => {
          showToastMessage(err?.message || "Failed", false);
        });
    } else {
      showToastMessage("Please select atleast one row to continue", false);
    }
  };
  const onExportDataStart = () => {
    setIsDataExport(true);
  };

  const onExportDataEnd = () => {
    setIsDataExport(false);
  };
  const updateCurrentPopupData = ({ key, value, isFile = false }) => {
    let updatedPopupData = {
      ...currentPopup,
      formData: {
        ...currentPopup.formData,
        [key]: {
          ...currentPopup.formData[key],
          ...{ [isFile ? "file" : "value"]: value },
        },
      },
    };
    setCurrentPopup(updatedPopupData);
  };
  const onResetPopup = () => {
    switch (currentPopup.key) {
      case FORM_TYPES.ADMIN_ASSIGN_AGENT_FINDER:
        setCurrentPopup((prev) => ({
          ...prev,
          formData: { ...ADMIN_ASSIGN_AGENT_POPUP },
        }));
        break;
      default:
        setCurrentPopup({});
        break;
    }
  };
  const onPopupConfirm = () => {
    if (currentPopup.key === FORM_TYPES.ADMIN_ASSIGN_AGENT_FINDER) {
      const regNumber = currentPopup.formData[challan_request_keys.REG_NUMBER];
      const assignTo =
        currentPopup.formData[challan_request_keys.ASSIGN_TO]?.value?.value;
      if (!regNumber?.value) {
        showToastMessage(
          "Please select valid vehicle registration number",
          false
        );
        return;
      } else if (!assignTo) {
        showToastMessage("Please select disposer", false);
        return;
      }
      ChallanService.UpdateAssignTo(
        currentPopup?.dataItem?.idChallanRequest,
        assignTo,
        "finder"
      )
        .then((resp) => {
          showToastMessage(resp?.message);
          setOpenDialog(false);
          setTimeout(() => {
            fetchChallanRequestOrders("reload");
            setSelectedState({});
          }, 1000);
        })
        .catch((err) => {
          showToastMessage(err?.message || "Failed", false);
        });
    }
  };
  const onChallanItemOpenDialog = (action, event) => {
    let _assignToData = { ...ADMIN_ASSIGN_AGENT_POPUP };
    _assignToData[challan_request_keys.APPOINTMENT_ORDER_ID].value =
      event[challan_request_keys.APPOINTMENT_ORDER_ID];
    _assignToData[challan_request_keys.REG_NUMBER].value =
      event[challan_request_keys.REG_NUMBER];
    _assignToData[challan_request_keys.CHALLAN_SOURCE].value =
      event[challan_request_keys.CHALLAN_SOURCE];
    setCurrentPopup({
      key: action,
      title: `Assign Finder`,
      dataItem: event,
      formData: _assignToData,
    });
    setOpenDialog(true);
  };
  const AppointmentIdCell = (props) => {
    const apptId = props?.dataItem[challan_request_keys.APPOINTMENT_ORDER_ID];
    return (
      <td className="k-table-td">
        {
          <span
            style={{
              color: "#003E72",
              textDecoration: "underline",
              cursor: "pointer",
              wordBreak: "break-word",
              fontSize: "13px",
            }}
            onClick={() => {
              setChallanRequestData(props?.dataItem);
            }}
          >
            {apptId}
          </span>
        }
      </td>
    );
  };
  const onBackClick = () => {
    setChallanRequestData();
  };
  const AssignedFinderCell = (event) => {
    const { assignedTo = "", status } = event.dataItem;
    const updatedAssignedTo = getUserFirstNameFromEmail(assignedTo);
    const isChallanCycleCompleted = [
      CHALLAN_REQUEST_STATUSES.RESOLVED,
    ].includes(status);

    if (!!assignedTo) {
      return (
        <td className="k-table-td" title={assignedTo}>
          <p
            style={{
              wordWrap: "break-word",
              textAlign: "center",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textTransform: "capitalize",
            }}
          >
            {updatedAssignedTo}
            {userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN &&
              !isChallanCycleCompleted && (
                <div className="assign-me-wrapper">
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#0059A3",
                      fontWeight: "700",
                    }}
                    onClick={() =>
                      onChallanItemOpenDialog(
                        FORM_TYPES.ADMIN_ASSIGN_AGENT_FINDER,
                        event.dataItem
                      )
                    }
                  >
                    <img alt="edit" src={srcEditImg} />
                  </span>
                </div>
              )}
          </p>
        </td>
      );
    } else {
      return (
        <td className="k-table-td">
          <div className="assign-me-wrapper">
            <Button
              themeColor={"primary"}
              fillMode={"solid"}
              onClick={() =>
                onChallanItemOpenDialog(
                  FORM_TYPES.ADMIN_ASSIGN_AGENT_FINDER,
                  event.dataItem
                )
              }
            >
              Assign
            </Button>
          </div>
        </td>
      );
    }
  };
  // page events
  useEffect(() => {
    setVisibleColumns((columns) => ({
      ...columns,
      [challan_request_keys.APPOINTMENT_ORDER_ID]: {
        ...columns[challan_request_keys.APPOINTMENT_ORDER_ID],
        cell: AppointmentIdCell,
      },
    }));
    ChallanService.fetchAllSources().then((resp) => {
      const srcs = resp?.detail?.map((item) => {
        return {
          value: item.code,
          label: item.name,
        };
      });
      // const filterSources = srcs.filter(
      // 	(item) => item.label.toUpperCase() !== CHALLAN_SOURCE_KEYS.VAS
      // );
      setSources((items) => ({
        ...items,
        [challan_request_keys.CHALLAN_SOURCE]: srcs,
      }));
    });
    ChallanService.fetchChallanRegions().then((resp) => {
      const rgns = resp.detail?.map((item) => {
        return {
          label: item.regionName,
          value: item.id,
        };
      });
      setSources((items) => ({
        ...items,
        [challan_request_keys.REGION]: rgns,
      }));
    });
    ChallanService.fetchFinderUsers().then((resp) => {
      const finders = resp.detail?.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
      setSources((items) => ({
        ...items,
        [challan_request_keys.ASSIGN_TO]: finders,
      }));
    });
  }, []);
  useEffect(() => {
    setVisibleColumns((columns) => ({
      ...columns,
      [challan_request_keys.CHALLAN_SOURCE]: {
        ...columns[challan_request_keys.CHALLAN_SOURCE],
        headerClassName: isFilterFieldPresent(
          gridFilters?.filters,
          challan_request_keys.CHALLAN_SOURCE
        )
          ? "selected-filter"
          : "de-selected-filter",
        columnMenu: (props) => {
          return (
            <ColumnMenuCheckboxFilter
              {...props}
              filterData={sources}
              userAssignments={userAssignments}
              onFilterButtonClick={handleFilterButtonClick}
              columnMapKey={challan_request_keys.CHALLAN_SOURCE}
              filter={gridFilters}
            />
          );
        },
      },
      [challan_request_keys.STATUS]: {
        ...columns[challan_request_keys.STATUS],
        headerClassName: isFilterFieldPresent(
          gridFilters?.filters,
          challan_request_keys.STATUS
        )
          ? "selected-filter"
          : "de-selected-filter",
        columnMenu: (props) => {
          return (
            <ColumnMenuCheckboxFilter
              {...props}
              filterData={sources}
              userAssignments={userAssignments}
              onFilterButtonClick={handleFilterButtonClick}
              columnMapKey={challan_request_keys.STATUS}
              filter={gridFilters}
            />
          );
        },
      },
      ...(userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN && {
        [challan_request_keys.ASSIGN_TO]: {
          ...columns[challan_request_keys.ASSIGN_TO],
          cell: AssignedFinderCell,
          hide: false,
          headerClassName: isFilterFieldPresent(
            gridFilters?.filters,
            challan_request_keys.ASSIGN_TO
          )
            ? "selected-filter"
            : "de-selected-filter",
          columnMenu: (props) => {
            return (
              <ColumnMenuCheckboxFilter
                {...props}
                filterData={sources}
                userAssignments={userAssignments}
                onFilterButtonClick={handleFilterButtonClick}
                columnMapKey={challan_request_keys.ASSIGN_TO}
                filter={gridFilters}
              />
            );
          },
        },
      }),
    }));
  }, [userAssignments, sources, gridFilters]);

  useEffect(() => {
    dispatch(updateAssignmentTypeParams(NUMBERS.ZERO));
    setGridFilters(initialFilter);
    if (!!searchQuery) {
      setFilterParams({ [searchQuery?.selectedOption]: searchQuery?.value });
    } else {
      setFilterParams({ isAssigned: 0 });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (userAssignments !== NUMBERS.FOUR) {
      setPaginationParams(page_params);
      let params = { ...filterParams };
      delete params.searchString;
      params = {
        ...params,
        isAssigned: userAssignments,
      };
      setFilterParams(params);
    }
  }, [userAssignments]);

  useEffect(() => {
    let params = { ...filterParams };
    delete params[challan_request_keys.CHALLAN_SOURCE];
    if (!!channelSource) {
      params = {
        ...params,
        [challan_request_keys.CHALLAN_SOURCE]: channelSource,
      };
    }
    setFilterParams(params);
  }, [channelSource]);

  useEffect(() => {
    if (isRefreshRequired) {
      fetchChallanRequestOrders("isRefreshRequired");
      setIsRefreshRequired(false);
    }
  }, [isRefreshRequired]);

  // useEffect(() => {
  // 	console.log({ filters });
  // }, [filters]);
  useEffect(() => {
    fetchChallanRequestOrders("filterParams");
  }, [filterParams]);

  useEffect(() => {
    if (!isObjectEmpty(paginationParams)) {
      fetchChallanRequestOrders("paginationParams");
    }
  }, [paginationParams]);

  const handleVersionChange = () => {
    if (!!isQA) {
      history.push(
        userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN
          ? pageKey === FF_PAGE_TYPES.DISPOSE
            ? "/operations/v1/admin/disposers"
            : "/operations/v1/admin/finders"
          : "/operations/v1/challans"
      );
    }
  };

  const CustomRow = (trElement, data) => {
    const { dataItem } = data || {};
    const trProps = {
      ...trElement.props,
      className: !!dataItem.vahanFlag
        ? "finder-color-coded-row"
        : trElement.props.className,
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  return (
    <>
      {!!challanRequestData ? (
        <FinderDetailView
          rowData={challanRequestData}
          onBackClick={onBackClick}
        />
      ) : (
        <div className="ops-challan-container">
          <div className="ops-grid-layout-container">
            <GridLayout
              gap={{
                rows: "0px",
                cols: 10,
              }}
            >
              <GridLayoutItem
                row={1}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className={styles.listing_header}>Finder Panel</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <SearchBarLayout type={FF_PAGE_TYPES.FINDERS} />
                </div>
              </GridLayoutItem>
              <GridLayoutItem
                row={2}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <ActionBarLayout
                  type={FF_PAGE_TYPES.FINDERS}
                  setIsRefreshRequired={setIsRefreshRequired}
                  AssignToMe={AssignToMe}
                  MarkAsResolved={MarkAsResolved}
                  isDataExport={isDataExport}
                  onExportDataStart={onExportDataStart}
                />
                {!!isQA && (
                  <Button
                    themeColor={"primary"}
                    fillMode={"outline"}
                    onClick={handleVersionChange}
                  >
                    V1
                  </Button>
                )}
              </GridLayoutItem>
              <GridLayoutItem row={3}>
                <div className="">
                  <TabGridLayout
                    isLoading={isLoading}
                    type={FF_PAGE_TYPES.FINDERS}
                    columns={visibleColumns}
                    data={requestData?.map((item) => ({
                      ...item,
                      [SELECTED_FIELD_KEY]: selectedState[idGetter(item)],
                    }))}
                    count={totalCount}
                    selectable={true}
                    selectedField={SELECTED_FIELD_KEY}
                    idField={challan_request_keys.REQUEST_ID}
                    onRowSelectionChange={onRowSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                    isPagination={true}
                    pageChange={pageChange}
                    paginationParams={paginationParams}
                    sortable={true}
                    sortParms={sortParms}
                    sortChange={sortChange}
                    isDataExport={isDataExport}
                    onExportDataEnd={onExportDataEnd}
                    customRow={CustomRow}
                    userAssignments={userAssignments}
                  />
                </div>
              </GridLayoutItem>
            </GridLayout>
            {openDialog && (
              <Dialog
                title={currentPopup?.title}
                onClose={() => {
                  setOpenDialog(false);
                }}
              >
                <ChallanItemPopup
                  {...currentPopup}
                  action={currentPopup.key}
                  updateCurrentPopupData={updateCurrentPopupData}
                />
                <DialogActionsBar>
                  <Button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={onResetPopup}
                  >
                    Reset
                  </Button>
                  <Button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={onPopupConfirm}
                    themeColor={"primary"}
                  >
                    Confirm
                  </Button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default FinderContainerView;
