export const initialFormData = {
	cities: [],
	selectedCities: [],
	selectedRa: [],
	stores: [],
	selectedStore: [],
	clusterName: "",
};

export const STORE_TYPE_LIST = [
	{ label: "store", value: "store" },
	{ label: "B2B", value: "B2B" },
	{ label: "franchise", value: "franchise" },
	{ label: "locality", value: "locality" },
	{ label: "Classified", value: "Classified" },
	{ label: "PnS", value: "PnS" },
	{ label: "CF", value: "CF" },
	{ label: "SF", value: "SF" },
	{ label: "parking_yard", value: "parking_yard" },
	{ label: "B2C", value: "B2C" },
	{ label: "local_store", value: "local_store" },
];

export const cluster_management_keys = {
	CLUSTER_NAME: "name",
	STORE_NAMES: "storeNames",
	STORE_TYPES: "storeTypes",
	CITY_NAMES: "cityNames",
};

export const clusterManagementColumns = {
	[cluster_management_keys.CLUSTER_NAME]: {
		field: cluster_management_keys.CLUSTER_NAME,
		label: "Cluster Name",
	},
	[cluster_management_keys.CITY_NAMES]: {
		field: cluster_management_keys.CITY_NAMES,
		label: "City",
	},
	[cluster_management_keys.STORE_TYPES]: {
		field: cluster_management_keys.STORE_TYPES,
		label: "Type",
	},
	[cluster_management_keys.STORE_NAMES]: {
		field: cluster_management_keys.STORE_NAMES,
		label: "Stores",
	},
};

export const DD_DATA_SOURCES = {
	[cluster_management_keys.CITY_NAMES]: [],
	[cluster_management_keys.STORE_TYPES]: STORE_TYPE_LIST,
};