import { CjIncentivesService, InspectionSocialService } from "../../../../../services";
import { convertObjectToParams, getArrayItemValueByLabel } from "../../../../../utils/utils";
import { recurringOptions } from "../announcement-form/constants";
import { ANNOUNCEMENT_COLUMN_MAPPER } from "./viewModel";

export const getDisplayedColumns = () => {
    const displayColumns = Object.values(ANNOUNCEMENT_COLUMN_MAPPER).filter((item) => !item.hide);
    return displayColumns.map((item) => item.field);
};

export const fetchAnnouncements = (paginationParams, sortParms, filterParams, setAnnouncementData, setTotalAnnouncementCount) => {
    const { pageNumber, pageSize } = paginationParams;
    let updatedPaginationParams = { pageNumber, pageSize };
    let params = { ...updatedPaginationParams };
    params = convertObjectToParams(params);
    if (Array.isArray(sortParms) && sortParms.length > 0) {
        params = `${params}&sortBy=${sortParms[0].field}&sortDirection=${sortParms[0].dir.toUpperCase()}`
    }
    if (filterParams && filterParams.cluster) {
        params = `${params}&clusterIds=${filterParams.cluster}`
    }

    InspectionSocialService.fetchAnnouncements(params)
        .then((resp) => {
            let tempClusterData = [];
            CjIncentivesService.fetchClustersByName()
                .then((response) => {
                    tempClusterData = makeClusterData(response);
                    resp.data.forEach(item => {
                        let recurringType = recurringOptions.find((recurringOption) => recurringOption.value == item.recurringType)
                        item.recurringType = recurringType;

                        if(item.clusterIds) {
                            const selectedClusters = tempClusterData.filter(store => item.clusterIds.some(clusterId => clusterId == store.clusterId));
                            item.clusters = selectedClusters.map(cluster => ({
                                text: cluster.text,
                                clusterId: cluster.value.clusterId,
                                value: {
                                    clusterId: cluster.value.clusterId,
                                    storeIds: cluster.value.storeIds
                                }
                            }));
                        }
                    })
                    setAnnouncementData(resp.data)
                })
                .catch((err) => {
                    console.log('err', err)
                })

            setTotalAnnouncementCount(resp.totalCount);
        })
        .catch((err) => {
            setAnnouncementData(null)
            console.log('Error Fetching Lead List', err)
        })
}

export const onInputChange = (e, formData, setFormData) => {
    const { value } = e;
    const { id } = e.target.props || {};
    const key = id || e.target.name;
    updateCurrentFormData({
        key,
        value: e.target.type === "file" ? e.target.files[0] : value,
        isFile: e.target.type === "file",
    }, formData, setFormData);
};
const updateCurrentFormData = ({ key, value, isFile = false }, formData, setFormData) => {
    let updatedPopupData = {
        ...formData,
        [key]: {
            ...formData[key],
            ...{ [isFile ? "file" : "value"]: value },
        },
    };
    setFormData(updatedPopupData);
};

export const filterGridHelper = (key, e, data, filterParams, setGridFilters, setPaginationParams, isReferral = false) => {
    let _filterParamsData = { ...filterParams };
    delete _filterParamsData[key];
    if (!!e) {
        setGridFilters(e);
        setPaginationParams({ pageNumber: isReferral ? 1 : 0, pageSize: 10 });
        e?.filters.forEach((item) => {
            const updatedFilter = item?.filters.reduce((acc, cur) => {
                const existingItem = acc?.find(
                    (transformedItem) => transformedItem[cur.field]
                );
                const itemValue = getArrayItemValueByLabel(
                    data[cur.field],
                    cur.value
                );
                if (existingItem) {
                    existingItem[cur.field] += `,${itemValue}`;
                } else {
                    acc.push({ [cur.field]: itemValue });
                }
                return acc;
            }, []);
            const filterParamsData = updatedFilter.reduce(
                (result, obj) => ({ ...result, ...obj }),
                {}
            );
            _filterParamsData = { ..._filterParamsData, ...filterParamsData };
        });
    }
    return _filterParamsData;
}

export const makeClusterData = (response) => {
    let clusterData = [];
    
    response?.detail?.map((item) => {
        clusterData.push(
            {
                text: item?.name,
                clusterId: item?.idCluster,
                value: {
                    clusterId: item?.idCluster,
                    storeIds: null
                }
            })
    })

    return clusterData;
}