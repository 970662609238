import {
  FORM_ELEMENT_TYPE,
  CHALLAN_MODES,
  // OFFENSE_TYPES,
} from "../../../../utils/constants/values.constants";
import { challan_item_keys } from "../challan-detail-revamp/viewModel";

export const DD_DATA_SOURCES = {
  [challan_item_keys.CHALLAN_TYPE]: CHALLAN_MODES,
  [challan_item_keys.CHALLAN_NAME]: [],
  [challan_item_keys.CHALLAN_COURT]: [],
};

export const formData = {
  [challan_item_keys.NOTICE_NUMBER]: {
    name: challan_item_keys.NOTICE_NUMBER,
    label: "Notice Number",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
  [challan_item_keys.CHALLAN_TYPE]: {
    name: challan_item_keys.CHALLAN_TYPE,
    label: "Challan Mode",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    source: CHALLAN_MODES,
    isDisplayLabel: true,
  },
  [challan_item_keys.CHALLAN_NAME]: {
    name: challan_item_keys.CHALLAN_NAME,
    label: "Offense Type",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    source: [],
    isDisplayLabel: true,
  },
  [challan_item_keys.OFFENCE_DATE]: {
    name: challan_item_keys.OFFENCE_DATE,
    label: "Challan Date",
    elementtype: FORM_ELEMENT_TYPE.DATE,
    placeholder: "Choose a date...",
    max: new Date(),
    isDisplayLabel: true,
  },
  [challan_item_keys.CHALLAN_COURT]: {
    name: challan_item_keys.CHALLAN_COURT,
    label: "Challan Court",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    source: [],
    isDisplayLabel: true,
  },
  [challan_item_keys.CHALLAN_AMOUNT]: {
    name: challan_item_keys.CHALLAN_AMOUNT,
    label: "Challan Amount",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
  [challan_item_keys.REMARKS]: {
    name: challan_item_keys.REMARKS,
    label: "Remarks",
    multiline: true,
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
};
