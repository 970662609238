import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { daysFromCurrentDate, getUserRoles, isFilterFieldPresent, numberFormat } from "../../../utils/utils";
import styles from './listing/styles.module.css';
import { GridColumnMenuFilter } from "@progress/kendo-react-grid";
import { CheckboxFilterColumnMenuUI, DropdownFilterColumnMenuUI, InputBoxFilterColumnMenuUI } from "./grid-filters";
import PriorityFlags from "../../../assets/lms/16x16PriorityFlags.png";
import NegoIcons from '../../../assets/lms/12x12NegoFlags.png'
import CallIcons from '../../../assets/lms/24x24CallSummary.png'
import { USER_ROLE_KEYS } from "../../../utils/constants/menu.constants";

export const LMS_COLUMN_KEYS = {
  APPOINTMENT_ID: "appointmentId",
  CAR_DETAILS:"carDetails",
  PRIORITY: "priority",
  RA_EMAIL: "raEmail",
  INTENT_DATE: "intentDate",
  NEGO_STATUS: "negoStatus",
  OCB_STATUS: "ocbStatus",
  C24_QUOTE: "c24Quote",
  OVERALL_CALL_STATUS: "lastCallStatus",
  TAGS: "tags",
  ACTION: "action"
}


export const getColumnHeader = ({raEmailFilterValues, filterValues , filters,handleFilterButtonClick}) => {
  const userRoles = getUserRoles();
  const filterChangeHandler = (columnMapKey,e,filterData,userAssignments) =>{
    handleFilterButtonClick(e)
  }
  const getHeaderClassName = (filters,key) => {
    return isFilterFieldPresent(filters?.filters, key) 
      ? styles['selected-filter'] 
      : styles['de-selected-filter'];
  };  

  return ({
    [LMS_COLUMN_KEYS.APPOINTMENT_ID]: {
      field: LMS_COLUMN_KEYS.APPOINTMENT_ID,
      label: "Appointment ID",
      cell: (props) => {
        const { appointmentId, encryptedAppointmentId, customerName } = props?.dataItem ?? {}
        return (
          <td>
            <Link 
              to={`/retail/lms/negoview/${encryptedAppointmentId}`} 
              style={{ textDecoration: 'underline', color: 'rgba(13, 110, 253)' }}
              className={styles.appointmentId}
            >
              {appointmentId}
            </Link>
            <p className={styles.td_part2}>{customerName}</p>
          </td>
        )
      },
      headerClassName: getHeaderClassName(filters,LMS_COLUMN_KEYS.APPOINTMENT_ID),
      columnMenu: (props) => {
        return (
          <GridColumnMenuFilter
            {...props} 
            expanded={true}
            filterUI={()=>InputBoxFilterColumnMenuUI({
              filters:filters,
              name:LMS_COLUMN_KEYS.APPOINTMENT_ID,
              filterChangeHandler:filterChangeHandler
            })} 
            onFilterChange={(e) =>{}}
          />
        );
      },
    },
    [LMS_COLUMN_KEYS.CAR_DETAILS]: {
      field: LMS_COLUMN_KEYS.CAR_DETAILS,
      label: "Car Details",
      cell: (props) => {
        const { make, model, variant, year } = props?.dataItem ?? {}
        return (
          <td className={styles.car_details}>
            <div className={styles.td_part1}>
              {make + " " + model}
            </div>
            <div className={styles.td_part2}>
              {variant + " | " + year}
            </div>
          </td>
        )
      }
    },
    [LMS_COLUMN_KEYS.PRIORITY]: {
      field: LMS_COLUMN_KEYS.PRIORITY,
      label: "Priority",
      width:'120px',
      cell: (props) => {
        const { dataItem } = props;
        return (
          <td className={styles.td_part1}>
            {
              dataItem?.[LMS_COLUMN_KEYS.PRIORITY] &&
              <div className={styles.priority_div}>
                <span
                  className={styles.priority_icon}
                  style={{
                    backgroundImage: `url(${PriorityFlags})`,
                    backgroundPosition:PRIORITY_STYLES[dataItem?.[LMS_COLUMN_KEYS.PRIORITY]??1]?.backgroundPosition
                  }}
                >
                </span>
                {PRIORITY_STYLES[dataItem?.[LMS_COLUMN_KEYS.PRIORITY]??1]?.label}
              </div>
            }
          </td>
        )
      },
      headerClassName: getHeaderClassName(filters,LMS_COLUMN_KEYS.PRIORITY),
      columnMenu: (props) => {
        return(
          <GridColumnMenuFilter 
            {...props} 
            expanded={true}
            filterUI={()=>CheckboxFilterColumnMenuUI({
              name:LMS_COLUMN_KEYS.PRIORITY,
              data:filterValues?.priority,
              filters:filters,
              filterChangeHandler:filterChangeHandler
            })} 
          />
        )
      },
    },
    ...(raEmailFilterValues?.length !== 0 || userRoles.includes(USER_ROLE_KEYS.LMS_PAN_INDIA) ?
      {
      [LMS_COLUMN_KEYS.RA_EMAIL]:{
        field: LMS_COLUMN_KEYS.RA_EMAIL,
        label: "RA Email",
        headerClassName: getHeaderClassName(filters,LMS_COLUMN_KEYS.RA_EMAIL),
        columnMenu: 
          !userRoles.includes(USER_ROLE_KEYS.LMS_PAN_INDIA)?
            (props) => {
              return (
                <GridColumnMenuFilter 
                  {...props} 
                  expanded={true}
                  filterUI={()=>CheckboxFilterColumnMenuUI({
                    name:LMS_COLUMN_KEYS.RA_EMAIL,
                    data:filterValues?.assignee,
                    filters:filters,
                    filterChangeHandler:filterChangeHandler
                  })} 
                />
              );
            }
          :
            undefined
      }
      }:
      {}
    ),
    [LMS_COLUMN_KEYS.INTENT_DATE]: {
      field: LMS_COLUMN_KEYS.INTENT_DATE,
      label: "Intent Days",
      cell: (props) => {
        const { dataItem } = props;
        return(
          <td  className={styles.td_part1} style={{ padding: '15px' }}>
            {Math.abs(daysFromCurrentDate(dataItem?.[LMS_COLUMN_KEYS.INTENT_DATE])) + ' Days'}
          </td>
          )
      },
      headerClassName: getHeaderClassName(filters,LMS_COLUMN_KEYS.INTENT_DATE),
      columnMenu: (props) => {
        return (
          <GridColumnMenuFilter 
            {...props} 
            expanded={true}
            filterUI={()=>DropdownFilterColumnMenuUI({
              name:LMS_COLUMN_KEYS.INTENT_DATE,
              data:filterValues?.intentDate,
              filters:filters,
              filterChangeHandler:filterChangeHandler
            })} 
          />
        );
      },
    },
    [LMS_COLUMN_KEYS.NEGO_STATUS]: {
      field: LMS_COLUMN_KEYS.NEGO_STATUS,
      label: "Nego Status",
      cell: (props) => {
        const { dataItem } = props;
        return(
          <td className={styles.td_part1}>
            {
              dataItem?.[LMS_COLUMN_KEYS.NEGO_STATUS] && 
              <div 
                className={styles.negoStatus_div}
                style={{
                  background:NEGO_STATUS_STYLES[dataItem?.[LMS_COLUMN_KEYS.NEGO_STATUS]]?.backgroundColor,
                  color:NEGO_STATUS_STYLES[dataItem?.[LMS_COLUMN_KEYS.NEGO_STATUS]]?.color,
                }}
              >
                <span
                  className={styles.negoStatus_icon}
                  style={{
                    backgroundImage: `url(${NegoIcons})`,
                    backgroundPositionX:NEGO_STATUS_STYLES[dataItem?.[LMS_COLUMN_KEYS.NEGO_STATUS]]?.backgroundPositionX,
                  }}
                >
                </span>
                {NEGO_STATUS_STYLES[dataItem?.[LMS_COLUMN_KEYS.NEGO_STATUS]]?.label}
              </div>
            }
          </td>
        )
      },
      headerClassName: getHeaderClassName(filters,LMS_COLUMN_KEYS.NEGO_STATUS),
      columnMenu: (props) => {
        return (
          <GridColumnMenuFilter 
            {...props} 
            expanded={true}
            filterUI={()=>CheckboxFilterColumnMenuUI({
              name:LMS_COLUMN_KEYS.NEGO_STATUS,
              data:filterValues?.negoStatus,
              filters:filters,
              filterChangeHandler:filterChangeHandler
            })} 
          />
        );
      },
    },
    [LMS_COLUMN_KEYS.OCB_STATUS]: {
      field: LMS_COLUMN_KEYS.OCB_STATUS,
      label: "OCB Status",
      cell:(props)=>{
        const {dataItem} = props;
        return(
          <td>
            {
              dataItem?.[LMS_COLUMN_KEYS.OCB_STATUS] &&
              <div
                style={{
                  backgroundColor:OCB_STATUS_STYLES[[dataItem?.[LMS_COLUMN_KEYS.OCB_STATUS]]]?.backgroundColor,
                  color:OCB_STATUS_STYLES[[dataItem?.[LMS_COLUMN_KEYS.OCB_STATUS]]]?.color,
                  borderLeft:'3px solid',
                  borderColor:OCB_STATUS_STYLES[[dataItem?.[LMS_COLUMN_KEYS.OCB_STATUS]]]?.borderColor,
                  padding: '5px 10px',
                  borderRadius: '4px'
                }}
              >
                <span
                  style={{
                    fontSize:'12px',
                    fontWeight:600,
                    color:OCB_STATUS_STYLES[[dataItem?.[LMS_COLUMN_KEYS.OCB_STATUS]]]?.labelColor
                  }}
                >
                  {OCB_STATUS_STYLES[[dataItem?.[LMS_COLUMN_KEYS.OCB_STATUS]]]?.label}
                </span>
                {
                  dataItem?.[LMS_COLUMN_KEYS.OCB_STATUS] !== 'ACCEPTED'  && 
                  <div>
                    At &nbsp; 
                    {
                      ['DNP_APPLIED','EXPIRED_WITH_DNP'].includes(dataItem?.[LMS_COLUMN_KEYS.OCB_STATUS])?
                      numberFormat(dataItem?.dealerNegoPrice??0):
                      numberFormat(dataItem?.raisedOCBPrice??0)
                    } 
                    { 
                      dataItem?.[LMS_COLUMN_KEYS.OCB_STATUS] === 'EXPIRED' && 
                      <div>HB/TP = {dataItem?.hbByTpPercentage} %</div>
                    }
                  </div>
                }
              </div>
            }
          </td>
        )
      },
      headerClassName: getHeaderClassName(filters,LMS_COLUMN_KEYS.OCB_STATUS),
      columnMenu: (props) => {
        return (
          <GridColumnMenuFilter 
            {...props} 
            expanded={true}
            filterUI={()=>CheckboxFilterColumnMenuUI({
              name:LMS_COLUMN_KEYS.OCB_STATUS,
              data:filterValues?.ocbStatus,
              filters:filters,
              filterChangeHandler:filterChangeHandler
            })} 
          />
        );
      },
    },
    [LMS_COLUMN_KEYS.C24_QUOTE]: {
      field: LMS_COLUMN_KEYS.C24_QUOTE,
      label: "C24 Quote",
      width:'120px',
      cell: (props) => {
        const { dataItem } = props;
        return (
          <td>
            <div className={styles.td_part1}>{numberFormat(dataItem?.[LMS_COLUMN_KEYS.C24_QUOTE])}</div>
            <div className={styles.td_part2}>
              {
                ['Expired'].includes(dataItem?.c24QuoteTag)
                  ? dataItem?.c24QuoteTag ?? 'NA'
                  : dataItem?.c24QuoteExpiryTime && `Valid Till :- ${dataItem?.c24QuoteExpiryTime?.split(' ')?.[0] ?? ''}`
              }
            </div>
          </td>
        )
      }
    },
    [LMS_COLUMN_KEYS.OVERALL_CALL_STATUS]: {
      field: LMS_COLUMN_KEYS.OVERALL_CALL_STATUS,
      label: "Last Call Status",
      cell: (props) => {
        const { dataItem } = props;
        return(
          <td className={styles.td_part1}>
            {
              dataItem?.[LMS_COLUMN_KEYS.OVERALL_CALL_STATUS] && 
              <div className={styles.lastCall_div}>
                <span
                    className={styles.lastCall_icon}
                    style={{
                      backgroundImage: `url(${CallIcons})`,
                      backgroundPosition:LAST_CALL_STATUS_STYLES[dataItem?.[LMS_COLUMN_KEYS.OVERALL_CALL_STATUS]??1]?.backgroundPosition,
                      marginRight:'15px'
                    }}
                  >
                </span>
                {LAST_CALL_STATUS_STYLES[dataItem?.[LMS_COLUMN_KEYS.OVERALL_CALL_STATUS]]?.label}
              </div>
            }
          </td>
        )
      },
      headerClassName: getHeaderClassName(filters,LMS_COLUMN_KEYS.OVERALL_CALL_STATUS),
      columnMenu: (props) => {
        return (
          <GridColumnMenuFilter 
            {...props} 
            expanded={true}
            filterUI={()=>CheckboxFilterColumnMenuUI({
              name:LMS_COLUMN_KEYS.OVERALL_CALL_STATUS,
              data:filterValues?.callStatus,
              filters:filters,
              filterChangeHandler:filterChangeHandler
            })} 
          />
        );
      },
    },
    [LMS_COLUMN_KEYS.TAGS]: {
      field: LMS_COLUMN_KEYS.TAGS,
      label: "Tags",
      cell: (props) => {
        const { dataItem } = props;
        return (
          <td className={styles.head_tags_container}>
            {
              dataItem?.tags?.map((item) => {
                return (
                  <div
                    key={item}
                    className={`${styles.head_tags_data} ${styles.td_part1}`}
                    style={TAGS_STYLES[item]}
                  >
                    {TAGS_STYLES[item].label}
                  </div>
                )
              })
            }
          </td>
        )
      },
      headerClassName: getHeaderClassName(filters,LMS_COLUMN_KEYS.TAGS),
      columnMenu: (props) => {
        return (
          <GridColumnMenuFilter 
            {...props} 
            expanded={true}
            filterUI={()=>CheckboxFilterColumnMenuUI({
              name:LMS_COLUMN_KEYS.TAGS,
              data:filterValues?.tag,
              filters:filters,
              filterChangeHandler:filterChangeHandler
            })} 
          />
        );
      },
    },
  })
};

export const SORT_DIRECTION_KEYS = {
	DESC: "desc",
	ASC: "asc",
};

export const INITIAL_SORT = [
  {
    field: LMS_COLUMN_KEYS.INTENT_DATE,
    dir: SORT_DIRECTION_KEYS.DESC,
  }
];

export const ALLOWED_SORT_PARAMS=[
  LMS_COLUMN_KEYS.INTENT_DATE,
]

export const NEGO_STATUS_STYLES = {
  IN_NEGO:{
    backgroundColor: '#EFF4FF',
    color:'#155EEF',
    label:'In Nego',
    backgroundPositionX:'-31px'
  },
  PR_PROCESSED:{
    backgroundColor: '#FEF0EF',
    color:'#DC6803',
    label:'Bought',
    backgroundPositionX:'-55px'
  },
  STOCK_IN:{
    backgroundColor: '#ECFDF3',
    color:'#067647',
    label:'Stocked in',
    backgroundPositionX:'-42px'
  },
}

export const OCB_STATUS_STYLES = {
  NOT_RAISED:{
    backgroundColor:'#FFFFFF',
    color:'#465166',
    label:'Not Raised',
    borderColor:'#CBCBCB',
    labelColor:'#475467'
  },
  RUNNING:{
    backgroundColor:'#528BFF14',
    color:'#465166',
    label:'Running',
    borderColor:'#528BFF',
    labelColor:'#528BFF'
  },
  ACCEPTED:{
    backgroundColor:'#ECFDF3CC',
    color:'#465166',
    label:'Accepted',
    borderColor:'#067647',
    labelColor:'#067647'
  },
  DNP_APPLIED:{
    backgroundColor:'#FDB02214',
    color:'#465166',
    label:'DNP Applied',
    borderColor:'#FDB022',
    labelColor:'#FDB022'
  },
  EXPIRED:{
    backgroundColor:'#FEF0EF',
    color:'#465166',
    label:'Expired',
    borderColor:'#EE2112',
    labelColor:'#EE2112'
  },
  NOT_ACCEPTED:{
    backgroundColor:'#FFFFFF',
    color:'#465166',
    label:'Not Accepted',
    borderColor:'#CBCBCB',
    labelColor:'#475467'
  },
  EXPIRED_WITH_DNP:{
    backgroundColor:'#FEF0EF',
    color:'#465166',
    label:'Expired with DNP',
    borderColor:'#EE2112',
    labelColor:'#EE2112'
  },
}

export const LAST_CALL_STATUS_STYLES = {
  MISSED:{
    backgroundColor:'#F5F5F5',
    color:'#525252',
    label:'Missed',
    backgroundPosition:'-15px 0px'
  },
  COMPLETED_TODAY:{
    backgroundColor:'#ECFDF3',
    color:'#067647',
    label:'Completed Today',
    backgroundPosition:'-45px 0px'
  },
  COMPLETED_YESTERDAY:{
    backgroundColor:'#ECFDF3',
    color:'#067647',
    label:'Completed Yesterday',
    backgroundPosition:'-45px 0px'
  },
  NOT_COMPLETED_TODAY:{
    backgroundColor:'#FFFAEB',
    color:'#DC6803',
    label:'Not Completed Today',
    backgroundPosition:'-30px 0px'
  },
  NOT_COMPLETED_YESTERDAY:{
    backgroundColor:'#FFFAEB',
    color:'#DC6803',
    label:'Not Completed Yesterday',
    backgroundPosition:'-30px 0px'
  },
  NO_ATTEMPT:{
    backgroundColor:'#F5F5F5',
    color:'#525252',
    label:'No Attempt',
    backgroundPosition:'0px 0px'
  }
}

export const TAGS_STYLES = {
  HOT:{
    backgroundColor:'#ECFDF3',
    color:'#067647',
    label:'Hot'
  },
  COLD:{
    backgroundColor:'#FEF3F2',
    color:'#B42318',
    label:'Cold'
  },
  WARM:{
    backgroundColor:'#F4F3FF',
    color:'#6938EF',
    label:'Warm'
  },
  CALLBACK_IN_THE_NEXT_DAY_MORNING:{
    backgroundColor:'#F4F3FF',
    color:'#6938EF',
    label:'Callback in the next day morning'
  },
  CALLBACK_IN_THE_AFTERNOON:{
    backgroundColor:'#F4F3FF',
    color:'#6938EF',
    label:'Callback in the afternoon'
  },
  CLEAN_CREDIT_REPORT:{
    backgroundColor:'#F4F3FF',
    color:'#6938EF',
    label:'Clean Credit Report'
  }
}

export const PRIORITY_STYLES = {
  1:{
    backgroundColor:'#FEF3F2',
    color:'#B42318',
    label:'P1',
    backgroundPosition:'-40px 0px'
  },
  2:{
    backgroundColor:'#F4F3FF',
    color:'#6938EF',
    label:'P2',
    backgroundPosition:'-65px 0px'
  },
  3:{
    backgroundColor:'#F4F3FF',
    color:'#6938EF',
    label:'P3',
    backgroundPosition:'-92px 0px'
  },
  4:{
    backgroundColor:'#F4F3FF',
    color:'#6938EF',
    label:'P4',
    backgroundPosition:'-120px 0px'
  },
  5:{
    backgroundColor:'#F4F3FF',
    color:'#6938EF',
    label:'P5',
    backgroundPosition:'-120px 0px'
  }
}