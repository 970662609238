import React, { useCallback, useEffect, useState } from "react";
import {
  challan_request_keys,
  challan_request_columns,
  getDisplayedCoulmns,
  EXPAND_FIELD_KEY,
  SELECTED_FIELD_KEY,
  getSelectedAssignRowIds,
  getSelectedMarkAsResolvedRowIds,
  getPageTitle,
} from "./viewModels";
import { USER_ROLE_KEYS } from "../../../utils/constants/menu.constants";
import { CustomExpandCell } from "../../../common/grid-cells";
import {
  NUMBERS,
  CHALLAN_REQUEST_STATUSES,
  FF_PAGE_TYPES,
} from "../../../utils/constants/values.constants";
import CustomGrid from "../../../common/custom-grid/component";
import Filters from "./filters";
import Actions from "./actions";
import ChallanListToolbarItems from "./toolbar-items";
import ChallanDetail from "../components/challan-detail";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChallanOrders,
  getRequestChallanItems,
} from "../../../store/actions/ops-challan";
import {
  showToastMessage,
  // getLoginUserEmail,
  convertObjectToParams,
  isObjectEmpty,
  // getFromLocalStorage,
  shortenString,
  getFFUserType,
  // getPageName,
  // getUserRoles,
} from "../../../utils/utils";
import { getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { ChallanService } from "../../../services";
import DisposeChallanItems from "../components/dispose-challan-items";
import { Loader } from "../../../common/Loader";
import { formData } from "./filters/components/finder-filters/viewModel";
import AddDelay from "../components/challan-detail-revamp/component/add-delay";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  ADMIN_ASSIGN_AGENT_POPUP,
  FORM_TYPES,
} from "../components/dispose-challan-item-popup/viewModel";
import ChallanItemPopup from "../components/dispose-challan-item-popup";
import { Button } from "@progress/kendo-react-buttons";
const idGetter = getter(challan_request_keys.REQUEST_ID);
const ALLOWED_SORT_PARAMS = [
  challan_request_keys.AGEING,
  challan_request_keys.ATTEMP_COUNT,
];
const ChallanView = () => {
  const [paginationParams, setPaginationParams] = useState({
    pageNumber: 0,
    pageSize: 50,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPopup, setCurrentPopup] = useState();
  const [sortParms, setSortParms] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState({});
  const [filterParams, setFilterParams] = useState({ isAssigned: 0 });
  const dispatch = useDispatch();
  const opsChallan = useSelector((state) => state.opsChallan);
  const [isRefreshRequired, setIsRefreshRequired] = useState(false);
  const [selectedState, setSelectedState] = React.useState({});
  const [isDataExport, setIsDataExport] = useState(false);
  const [pageKey, setPageKey] = useState("");
  const {
    isLoading,
    challanOrders,
    userAssignments,
    channelSource,
    // addNewChallanRequest = false,
    searchQuery,
    filters,
  } = opsChallan || {};
  let loadKey = "";
  const userType = getFFUserType();
  const pageChange = ({ page = 0, size = 10 }) => {
    setPaginationParams({ pageNumber: page, pageSize: size });
  };
  const sortChange = (e) => {
    const sortKey = !!e?.sort?.length && e.sort[0].field;
    if (ALLOWED_SORT_PARAMS.includes(sortKey)) {
      setSortParms(e.sort);
      fetchChallanRequestOrders("sort", e.sort);
    }
  };
  const {
    data: requestData,
    totalCount,
    // numberOfElements,
  } = challanOrders || {};

  useEffect(() => {
    const title = getPageTitle();
    setPageKey(title);
    if (title === FF_PAGE_TYPES.FINDERS) {
      const updatedColumns = {
        ...challan_request_columns,
        [challan_request_keys.ASSIGN_TO]: {
          ...challan_request_columns[challan_request_keys.ASSIGN_TO],
          cell: AssignToFinderCell,
        },
        [challan_request_keys.DELAY_REASON]: {
          cell: DelayReasonCell,
          label: "Delay Reason",
          width: "200px",
        },
        expand: {
          cell: expandCell,
          field: "",
          width: "40px",
        },
      };
      setVisibleColumns(updatedColumns);
    }
  }, []);
  const expandCell = (props) => {
    if (
      props.dataItem?.status.toUpperCase() === CHALLAN_REQUEST_STATUSES.ASSIGNED
    ) {
      return (
        <CustomExpandCell {...props} onExpandCellChange={onExpandCellChange} />
      );
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    let params = { ...filterParams };
    Object.keys(formData).map((key) => {
      delete params[key];
    });
    params = {
      ...params,
      ...filters,
    };
    setFilterParams(params);
  }, [filters]);

  useEffect(() => {
    !!searchQuery
      ? setFilterParams({ [searchQuery?.selectedOption]: searchQuery?.value })
      : setFilterParams({ isAssigned: 0 });
  }, [searchQuery]);

  useEffect(() => {
    if (userAssignments !== NUMBERS.FOUR) {
      let params = { ...filterParams };
      delete params.searchString;
      params = {
        ...params,
        isAssigned: userAssignments,
      };
      setFilterParams(params);
    }
  }, [userAssignments]);

  useEffect(() => {
    let params = { ...filterParams };
    delete params[challan_request_keys.CHALLAN_SOURCE];
    if (!!channelSource) {
      params = {
        ...params,
        [challan_request_keys.CHALLAN_SOURCE]: channelSource,
      };
    }
    setFilterParams(params);
  }, [channelSource]);

  useEffect(() => {
    if (isRefreshRequired) {
      fetchChallanRequestOrders("isRefreshRequired");
      setIsRefreshRequired(false);
    }
  }, [isRefreshRequired]);

  useEffect(() => {
    fetchChallanRequestOrders("filterParams");
  }, [filterParams]);

  useEffect(() => {
    if (!isObjectEmpty(paginationParams)) {
      fetchChallanRequestOrders("paginationParams");
    }
  }, [paginationParams]);

  const fetchChallanRequestOrders = (key, sort = []) => {
    if (pageKey === FF_PAGE_TYPES.FINDERS && !loadKey && !isLoading) {
      loadKey = key;
      const params = { ...paginationParams, ...filterParams };
      let qsParams = convertObjectToParams(params);
      if (!!sort.length) {
        qsParams = `${qsParams}&sortBy=${sort[0].field}&sortDir=${sort[0].dir}`;
      }
      dispatch(fetchChallanOrders(qsParams));
    }
  };

  const resetParams = () => {};

  const itemChange = (event) => {};
  const updateRowDataModels = (props) => {
    dispatch(getRequestChallanItems(props));
  };

  const onExpandCellChange = (props) => {
    const isExpanded =
      props?.expanded === undefined ? props?.aggregates : props?.expanded;
    props.expanded = !isExpanded;
    updateRowDataModels(props);
    if (!props.value || props.details) {
      return;
    }
  };

  const onRowSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: challan_request_keys.REQUEST_ID,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
      item[SELECTED_FIELD_KEY] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  const onExportDataStart = () => {
    setIsDataExport(true);
  };

  const onExportDataEnd = () => {
    setIsDataExport(false);
  };

  const AssignToMe = () => {
    const rowIds = getSelectedAssignRowIds(selectedState, requestData);
    if (rowIds.length > NUMBERS.ZERO) {
      ChallanService.assignToMeRequests(rowIds)
        .then((resp) => {
          showToastMessage("Challan requests assigned successfully");
          setTimeout(() => {
            fetchChallanRequestOrders("reload");
            setSelectedState({});
          }, 1000);
        })
        .catch((err) => {
          showToastMessage(err?.message || "Failed", false);
        });
    } else {
      showToastMessage(
        "Please select atleast one unassigned row to continue",
        false
      );
    }
  };
  const MarkAsResolved = () => {
    const rowIds = getSelectedMarkAsResolvedRowIds(selectedState, requestData);
    if (rowIds.length > NUMBERS.ZERO) {
      ChallanService.markAsResolvedRequests(rowIds)
        .then((resp) => {
          showToastMessage("Challan requests resolved successfully");
          setTimeout(() => {
            fetchChallanRequestOrders("reload");
            setSelectedState({});
          }, 1000);
        })
        .catch((err) => {
          showToastMessage(err?.message || "Failed", false);
        });
    } else {
      showToastMessage("Please select atleast one row to continue", false);
    }
  };
  const onChallanItemOpenDialog = (action, event) => {
    let _assignToData = { ...ADMIN_ASSIGN_AGENT_POPUP };
    _assignToData[challan_request_keys.APPOINTMENT_ORDER_ID].value =
      event[challan_request_keys.APPOINTMENT_ORDER_ID];
    _assignToData[challan_request_keys.REG_NUMBER].value =
      event[challan_request_keys.REG_NUMBER];
    _assignToData[challan_request_keys.CHALLAN_SOURCE].value =
      event[challan_request_keys.CHALLAN_SOURCE];
    setCurrentPopup({
      key: action,
      title: `Assign Finder`,
      dataItem: event,
      formData: _assignToData,
    });
    setOpenDialog(true);
  };
  const AssignToFinderCell = (event) => {
    const { assignedTo = "" } = event.dataItem;
    if (!!assignedTo) {
      return (
        <td>
          <p style={{ wordWrap: "break-word", textAlign: "center" }}>
            {assignedTo}
          </p>
          {userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN && (
            <div className="assign-me-wrapper">
              <span
                className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                onClick={() =>
                  onChallanItemOpenDialog(
                    FORM_TYPES.ADMIN_ASSIGN_AGENT_FINDER,
                    event.dataItem
                  )
                }
              >
                Re-Assign
              </span>
            </div>
          )}
        </td>
      );
    } else {
      return (
        <td>
          <></>
        </td>
      );
    }
  };

  const DelayReasonCell = (event) => {
    const { dataItem } = event || {};
    const [delayPopup, setDelayPopup] = useState(false);

    const openAddDelayDialog = () => {
      setDelayPopup(!delayPopup);
    };

    return (
      <td
        title={!!dataItem?.delayReason ? dataItem?.delayReason : null}
        style={{ textAlign: "center" }}
      >
        {!!dataItem?.delayReason ? (
          <>
            <span>{shortenString(dataItem?.delayReason, 10)}</span>{" "}
            {userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN && (
              <span
                onClick={openAddDelayDialog}
                className="k-icon k-i-edit k-i-pencil"
                style={{ cursor: "pointer" }}
              />
            )}
          </>
        ) : null}
        {delayPopup && (
          <Dialog title={"Delay Reason"} onClose={openAddDelayDialog}>
            <AddDelay
              userGroup={USER_ROLE_KEYS.FINDER}
              challanItems={[dataItem]}
              onClose={openAddDelayDialog}
              setIsRefreshRequired={setIsRefreshRequired}
            />
          </Dialog>
        )}
      </td>
    );
  };

  const DetailComponent = useCallback((e) => {
    return (
      <ul>
        <ChallanDetail {...e} setIsRefreshRequired={setIsRefreshRequired} />
      </ul>
    );
  }, []);
  const updateCurrentPopupData = ({ key, value, isFile = false }) => {
    let updatedPopupData = {
      ...currentPopup,
      formData: {
        ...currentPopup.formData,
        [key]: {
          ...currentPopup.formData[key],
          ...{ [isFile ? "file" : "value"]: value },
        },
      },
    };
    setCurrentPopup(updatedPopupData);
  };
  const showTickRegNumber = (key) => {
    let updatedPopupData = {
      ...currentPopup,
      formData: {
        ...currentPopup.formData,
        [key]: {
          ...currentPopup.formData[key],
          istick: true,
        },
      },
    };
    setCurrentPopup(updatedPopupData);
  };
  const onResetPopup = () => {
    switch (currentPopup.key) {
      case FORM_TYPES.ADMIN_ASSIGN_AGENT_FINDER:
        setCurrentPopup((prev) => ({
          ...prev,
          formData: { ...ADMIN_ASSIGN_AGENT_POPUP },
        }));
        break;
      default:
        setCurrentPopup({});
        break;
    }
  };
  const onPopupConfirm = () => {
    if (currentPopup.key === FORM_TYPES.ADMIN_ASSIGN_AGENT_FINDER) {
      const regNumber = currentPopup.formData[challan_request_keys.REG_NUMBER];
      const assignTo =
        currentPopup.formData[challan_request_keys.ASSIGN_TO]?.value?.value;
      if (!regNumber?.value) {
        showToastMessage(
          "Please select valid vehicle registration number",
          false
        );
        return;
      } else if (!assignTo) {
        showToastMessage("Please select disposer", false);
        return;
      }
      ChallanService.UpdateAssignTo(
        currentPopup?.dataItem?.idChallanRequest,
        assignTo,
        "finder"
      )
        .then((resp) => {
          showToastMessage(resp?.message);
          setOpenDialog(false);
          setTimeout(() => {
            fetchChallanRequestOrders("reload");
            setSelectedState({});
          }, 1000);
        })
        .catch((err) => {
          showToastMessage(err?.message || "Failed", false);
        });
    }
  };
  const renderView = () => {
    switch (pageKey) {
      case FF_PAGE_TYPES.FINDERS:
        return (
          <>
            <div className="ops-channel-page-bar">
              <Actions
                setIsRefreshRequired={setIsRefreshRequired}
                pageKey={pageKey}
                isDataExport={isDataExport}
                onExportDataStart={onExportDataStart}
              />
              <Filters pageKey={pageKey} />
            </div>
            <div className="middleContent">
              <ChallanListToolbarItems
                AssignToMe={AssignToMe}
                MarkAsResolved={MarkAsResolved}
                pageKey={pageKey}
              />
              <>
                {isLoading ? (
                  <Loader />
                ) : (
                  <CustomGrid
                    displayedColumns={getDisplayedCoulmns(visibleColumns)}
                    data={requestData?.map((item) => ({
                      ...item,
                      [SELECTED_FIELD_KEY]: selectedState[idGetter(item)],
                    }))}
                    count={totalCount}
                    pageChange={pageChange}
                    columnMap={visibleColumns}
                    detail={DetailComponent}
                    onExpandChange={onExpandCellChange}
                    expandField={EXPAND_FIELD_KEY}
                    selectedField={SELECTED_FIELD_KEY}
                    idField={challan_request_keys.REQUEST_ID}
                    onSelectionChange={onRowSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                    selectable={true}
                    paginationParams={paginationParams}
                    isPagination={true}
                    isDataExport={isDataExport}
                    onExportDataEnd={onExportDataEnd}
                    sortable={true}
                    sortParms={sortParms}
                    onSortChange={sortChange}
                    fixedHeight={{ height: "650px" }}
                  />
                )}
              </>
              {openDialog && (
                <Dialog
                  title={currentPopup?.title}
                  onClose={() => {
                    setOpenDialog(false);
                  }}
                >
                  <ChallanItemPopup
                    {...currentPopup}
                    action={currentPopup.key}
                    updateCurrentPopupData={updateCurrentPopupData}
                    showTickRegNumber={showTickRegNumber}
                  />
                  <DialogActionsBar>
                    <Button
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      onClick={onResetPopup}
                    >
                      Reset
                    </Button>
                    <Button
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      onClick={onPopupConfirm}
                      themeColor={"primary"}
                    >
                      Confirm
                    </Button>
                  </DialogActionsBar>
                </Dialog>
              )}
            </div>
          </>
        );
      case FF_PAGE_TYPES.DISPOSE:
        return <DisposeChallanItems pageKey={pageKey} />;
      default:
        return <></>;
    }
  };
  return renderView();
};

export default ChallanView;
