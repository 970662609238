function CarSharedIcon(props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1193_308)">
        <path
          d="M11.14 5.379l-3.75 3.75a.375.375 0 01-.64-.265V6.999c-2.677.152-4.512 1.888-5.027 2.438a.562.562 0 01-.97-.45c.175-1.511 1.003-2.966 2.333-4.095C4.191 3.955 5.534 3.36 6.75 3.256V1.364a.375.375 0 01.64-.266l3.75 3.75a.373.373 0 010 .53z"
          fill="#F4F3FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1193_308">
          <path fill="#fff" d="M0 0H12V12H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CarSharedIcon
