import "../common/assets/styles/common.scss";
import "./car-details.scss";
import AppTable from "../common/components/app-table"
import { Button } from "@progress/kendo-react-buttons";
import { FIELDS_KEY, TABLE_LABELS } from "../common/constants/table.constants"
import { useEffect, useState } from "react";
import { currencyFormat, getNumberSuffix, percentageFormat } from "../common/utils/utils";
const CurrentBids = ({ carDetails }) => {
	const tableValues = {
		highestBid: '',
		targetPrice: '',
		HBTP: '',
		auctionCount: '',
	}
	const [data, setData] = useState([tableValues]);

	useEffect(() => {
		let liveAuctionDetails = carDetails?.liveAuctionDetails;
		if (liveAuctionDetails) {
			tableValues.highestBid = currencyFormat(liveAuctionDetails?.currentAuctionHighestBid);
			tableValues.HBTP = percentageFormat(liveAuctionDetails?.highestBidTpRatio);
			tableValues.targetPrice = currencyFormat(carDetails?.appointmentDetails?.targetPrice);
			tableValues.auctionCount = liveAuctionDetails?.auctionCount;
			setData([{ ...tableValues }]);
		}
	}, [carDetails])

	const SafeCells = safeCellProps => {
		const event = {
			dataItem: safeCellProps.data
		};
		return (
			// eslint-disable-next-line no-use-before-define
			<td onClick={() => navigateTo(event)}>
				{safeCellProps.children}
			</td>
		);
	};
	const tableColumns = [
		{
			field: FIELDS_KEY.HighestBid,
			title: TABLE_LABELS.HighestBid,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem.highestBid}</div>
				</SafeCells>
			),
		},
		{
			field: FIELDS_KEY.TargetPrice,
			title: TABLE_LABELS.TargetPrice,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem.targetPrice}</div>
				</SafeCells>
			),
		},
		{
			title: TABLE_LABELS.HBTP,
			field: FIELDS_KEY.HBTP,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem.HBTP}</div>
				</SafeCells>
			),
		}, {
			field: FIELDS_KEY.AuctionType,
			title: TABLE_LABELS.AuctionType,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					{dataItem?.auctionCount ?
						<Button
							className='app-error-btn'
							fillMode={'solid'}

						>
							{getNumberSuffix(dataItem?.auctionCount) + ' Auction'}

						</Button>
						: '-'}
				</SafeCells>
			),
		}];

	return (
		<>
			<div className="current-bids-grid app-table-view-sm app-table">
				<AppTable
					data={data}
					headerData={[
						...tableColumns
					]}
					isHoverable={false}
					totalCount={data.length}
					handlePageChange={() => { }}
					pageable={false}
				/>
			</div>
		</>
	);
};
export default CurrentBids;
