const currentEnv = process.env.REACT_APP_ENV;
export const getTenantId = () => {
  const metaData = {
    QA: "T2XqU4BIJHFHvxdWgUQ8X0NWGkOx",
    STAGING: "T2XqU4BIJHFHvxdWgUQ8X0NWGkOx",
    PRODUCTION: "T2ZOSj4E10QQuONVCOXFngrGzHZk",
  };
  return metaData[currentEnv];
};
export const getVizPanelServiceData = () => {
  const metaData = {
    QA: {
      url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/viz",
      key: "",
      imagesUrl: "https://img-staging.24c.in",
      writeKey: 'r1iQSQhJfpQRMEsqBeImqzcK6FG8RHTA',
      videoUrl: "https://s3.ap-south-1.amazonaws.com/videos-staging.24c.in"
    },
    STAGING: {
      url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/viz",
      key: "",
      imagesUrl: "https://img-staging.24c.in",
      writeKey: 'r1iQSQhJfpQRMEsqBeImqzcK6FG8RHTA',
      videoUrl: "https://s3.ap-south-1.amazonaws.com/videos-staging.24c.in"
    },
    PRODUCTION: {
      url: "https://gateway.24c.in/viz",
      key: "",
      imagesUrl: "http://fastly-production.24c.in",
      videoUrl: "https://fastly-videos.24c.in",
      writeKey: 'gaFM5rkh6mZba3TGzBbxW2jHb8arC84n',
    },
  };
  return metaData[currentEnv];
};