export default (api) => {
  const getSegments = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/v1/segments` + params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const createSegment = (data) => {
    return new Promise((resolve, reject) => {
      api.post(`/v1/segments`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const updateSegment = (data, isRemoved) => {
    return new Promise((resolve, reject) => {
      api.put(`/v1/segments?isRemove=${isRemoved}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  return {
    getSegments,
    createSegment,
    updateSegment
  }
}