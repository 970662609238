
import { Breadcrumb } from "@progress/kendo-react-layout";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { reset } from "../../../store/actions/breadCumb";

const AppBreadcumb = () => {
	const routes  = useSelector((state) => state.breadCumb.routes);
	const dispatch = useDispatch();
	
	const history = useHistory();
	const handleItemSelect = (event) => {
	  const itemIndex = routes.findIndex((curValue) => curValue.id === event.id);
	  if(itemIndex!=routes.length-1 &&  routes[itemIndex]?.link) {
		const newData = [...routes].slice(0, itemIndex + 1);
		dispatch(reset(newData))
		history.push(routes[itemIndex]?.link);
	  }
	//   const newData = data.slice(0, itemIndex + 1);
	//   setData(newData);
	};
	// const handleButtonClick = (event) => {
	//   if (event) {
	// 	setData(items);
	//   }
	// };
	// const handleKeyDown = (event) => {
	//   if (event.nativeEvent.keyCode === 13) {
	// 	const itemIndex = data.findIndex((curValue) => curValue.id === event.id);
	// 	const newData = data.slice(0, itemIndex + 1);
	// 	setData(newData);
	//   }
	// };
	return (
		<>
		 <Breadcrumb  className="app-breadcumb"
        data={routes}
        onItemSelect={handleItemSelect}
		key={'id'}
        // onKeyDown={handleKeyDown}
		/>
		</>
	);
};
export default AppBreadcumb;
