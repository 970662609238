export default (api) => {
  const fetchDealers = (params = "") => {
    const url = "api/v1/dealers";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}${params}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const createDealers = (payload) => {
    const url = "api/v1/dealers";
    return api.post(`${url}`, payload);
  };

  const createCentre = (params, payload) => {
    const url = `api/v1/dealers/${params}/centres`;
    return api.post(`${url}`, payload);
  };
  const updatePayoutsFlag = (params, action, payload) => {
    const url = `v1/db/leads/${params}/${action}`;
    return api.post(`${url}`, payload);
  };
  const updateFlagSection = (params, action, payload) => {
    console.log(params);
    const url = `v1/db/leads/${params.leadId}/${action}/${params.issueId}`;
    return api.put(`${url}`, payload);
  };
  const fetchDealerCentre = (params) => {
    const url = `api/v1/dealers/${params}/centres`;
    return api.get(`${url}`);
  };

  const updateDealer = (params, payload) => {
    const url = `api/v1/dealers/${params}`;
    return api.patch(`${url}`, payload);
  };

  const updateCentre = (dealerId, payload) => {
    const url = `api/v1/dealers/${dealerId}/centres/${payload?.dealerCentreId}`;
    return api.patch(`${url}`, payload);
  };

  const fetchCityDetails = (params) => {
    const url = `api/v1/pincode/${params}`;
    return api.get(`${url}`);
  };

  const fetchDealerById = (params) => {
    const url = `api/v1/dealers/${params}`;
    return api.get(`${url}`);
  };

  const fetchCentreById = (dealerId, centreId) => {
    const url = `api/v1/dealers/${dealerId}/centres/${centreId}`;
    return api.get(`${url}`);
  };

  const fetchPayoutLeads = (params = "") => {
    const url = "v1/db/leads";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}${params}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchPayoutDetails = (id) => {
    const url = `v1/db/leads/${id}/details`;
    return new Promise((resolve, reject) => {
      api
        .get(`${url}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const deteleNcm = (payload) => {
    const url = `api/v1/dealers/ncm`;
    return new Promise((resolve, reject) => {
      api
        .delete(`${url}`, { data: payload })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  return {
    fetchDealers,
    createDealers,
    createCentre,
    fetchDealerCentre,
    updateDealer,
    updateCentre,
    fetchCityDetails,
    fetchDealerById,
    fetchCentreById,
    fetchPayoutLeads,
    fetchPayoutDetails,
    updatePayoutsFlag,
    updateFlagSection,
    deteleNcm
  };
};
