import React, { useEffect, useState } from "react";
import {
  getDisplayedCoulmns,
  fulfillmentColumns,
  // initialFilter,
} from "./viewModels";
import CustomGrid from "../../../common/custom-grid/component";
import Actions from "./actions";
// import FulfillmentDetail from "../components/fulfillment-detail";
import {
  // fetchDealerCentre,
  fetchDealers,
} from "../../../store/actions/dealer-procurement";
import { useDispatch, useSelector } from "react-redux";
import {
  // showToastMessage,
  convertObjectToParams,
  // isObjectEmpty,
} from "../../../utils/utils";
// import { getSelectedState } from "@progress/kendo-react-data-tools";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NUMBERS } from "../../../utils/constants/values.constants";
import { Switch } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
const INIT_PAGE_PARAMS = {
  page: 0,
  size: 10,
};
const FulFillmentView = () => {
  const [paginationParams, setPaginationParams] = useState(INIT_PAGE_PARAMS);
  const [filterParams, setFilterParams] = useState({});
  const [actionFilters, setActionFilters] = useState({});

  const [gridfilters, setGridfilters] = React.useState([]);
  const gridfiltersRef = React.useRef(gridfilters);

  const dispatch = useDispatch();
  // const history = useHistory();
  const dealers = useSelector((state) => state.dealerProcurement);
  let loadKey = "";
  const {
    isLoading,
    data = [],
    totalCount = 0,
    // numberOfElements = 0,
  } = dealers || {};

  useEffect(() => {
    document.addEventListener("keydown", handleFilter, false);
    return () => {
      document.removeEventListener("keydown", handleFilter, false);
    };
  }, []);

  useEffect(() => {
    getDealerProcurementDealers("paginationParams");
  }, [paginationParams]);

  const pageChange = ({ page = 0, size = 10 }) => {
    setPaginationParams({ page, size });
  };
  useEffect(() => {
    getDealerProcurementDealers("filterParams");
  }, [filterParams]);

  useEffect(() => {
    getDealerProcurementDealers("actionFilterParams");
  }, [actionFilters]);

  const getDealerProcurementDealers = (key) => {
    if (!loadKey && !isLoading) {
      loadKey = key;
      let page = { ...paginationParams };
      if (["actionFilterParams", "filterParams"].includes(loadKey)) {
        page = INIT_PAGE_PARAMS;
      }
      const params = { ...page, ...filterParams, ...actionFilters };
      const qsParams = convertObjectToParams(params);
      dispatch(fetchDealers(qsParams));
    }
  };

  const handleFilter = (e) => {
    if (e?.keyCode === NUMBERS.THIRTEEN) {
      const filters = gridfiltersRef?.current?.filters;
      let params = {};
      if (filters && !!filters.length) {
        filters?.forEach((element) => {
          params = { ...params, [element.field]: element.value };
        });
      }
      setFilterParams(params);
    }
  };

  const updateFilterValues = (e) => {
    gridfiltersRef.current = e?.filter;
    setGridfilters(e?.filter);
  };

  const handleStatus = (e) => {
    setPaginationParams(INIT_PAGE_PARAMS);
    if (!!e?.value) {
      setActionFilters({ status: "Inactive" });
    } else {
      setActionFilters();
    }
  };

  return (
    <>
      <div className="ops-channel-page-bar">
        <Actions />
        <div className="action-filter">
          <Label className="status-label">Show Inactive</Label>
          <Switch onChange={(e) => handleStatus(e)} />
        </div>
      </div>
      <div className="middleContent">
        <CustomGrid
          title="Dealer Procurement"
          displayedColumns={getDisplayedCoulmns()}
          data={data || []}
          count={totalCount}
          isPagination={true}
          pageChange={pageChange}
          columnMap={fulfillmentColumns}
          paginationParams={{
            pageNumber: paginationParams?.page,
            pageSize: paginationParams?.size,
          }}
          filterable={true}
          filter={gridfilters}
          onFilterChange={updateFilterValues}
          pageSizes={[10, 20, 25, 50]}
        />
      </div>
    </>
  );
};

export default FulFillmentView;
