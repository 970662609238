import { ComboBox } from "@progress/kendo-react-dropdowns";
import { useContext } from "react";
import classes from "../styles/agentFilter.module.css";
import { AgentListContext } from "../context/AgentListContext";

const AgentFilter = ({ onChange }) => {
    const agentList = useContext(AgentListContext);

    return (
        <ComboBox
            data={agentList}
            placeholder="Select Agent"
            className={classes.agentFilter}
            dataItemKey="email"
            textField="name"
            onChange={onChange}
        />
    );
};

export default AgentFilter;
