import * as React from "react";
import VahanCheckCard from "../vahan-check-card";
import FinalQuoteCard from "../final-quote-card";
import LoanDetailsCard from "../loan-details-card";
import InsuranceRcCard from "../insurance-and-rc-card";
import ServiceHistoryCard from "../service-history-card";
import KycBankDetailsCard from "../kyc-bank-details-card";
import InspectionReportCard from "../inspection-report-card";
import BusinessApprovalsCard from "../business-approvals-card";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Loader } from "../../../../../../common/Loader";
import ActivityFeed from "../activity-feed-tab";
import CustomerInfo from "../customer-info";
import styles from './style.module.css'
import NewBusinesses from "../new-businesses";
import OtherDocuments from "../other-doucments";

const TabStrips = (props) => {

    const { isHypothecation } = props;
    const [selected, setSelected] = React.useState(0);
    const tabList = [
        "Customer Information",
        "Pricing",
        "Inspection Report",
        "KYC & Bank",
        "Vahan Check",
        "Service History",
        "RC & Insurance",
        isHypothecation && "Loan Details",
        "Business Approvals",
        "Other Documents",
        "Activity Feed",
        "New Businesses"
    ];

    const handleSelect = (e) => {
        setSelected(e.selected);
        const element = document.getElementById(tabList[e.selected]);
        element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
        });
    };

    return (
        isHypothecation === undefined ?
            <div style={{ minHeight: '100vh' }}>
                <Loader />
            </div>
            :
            <TabStrip
                selected={selected}
                onSelect={handleSelect}
                tabPosition="left"
                style={{
                    marginTop: "15px",
                }}>
                {tabList.map((tab, index) => {
                    if (tab) {
                        return (
                            <TabStripTab key={tab} title={tab} >
                                <div id={tabList[0]} className={styles.tab_strips_content}>
                                  <CustomerInfo {...props}/>
                                </div>
                                <div id={tabList[1]} className={styles.tab_strips_content}>
                                    <FinalQuoteCard {...props} />
                                </div>
                                <div id={tabList[2]} className={styles.tab_strips_content}>
                                    <InspectionReportCard {...props} />
                                </div>
                                <div id={tabList[3]} className={styles.tab_strips_content}>
                                    <KycBankDetailsCard {...props} />
                                </div>
                                <div id={tabList[4]} className={styles.tab_strips_content}>
                                    <VahanCheckCard {...props} />
                                </div>
                                <div id={tabList[5]} className={styles.tab_strips_content}>
                                    <ServiceHistoryCard />
                                </div>
                                <div id={tabList[6]} className={styles.tab_strips_content}>
                                    <InsuranceRcCard />
                                </div>
                                {
                                    isHypothecation &&
                                    <div id={tabList[7]} className={styles.tab_strips_content}>
                                        <LoanDetailsCard {...props} />
                                    </div>
                                }
                                <div id={tabList[8]} className={styles.tab_strips_content}>
                                    <BusinessApprovalsCard {...props} />
                                </div>
                                <div id={tabList[9]} className={styles.tab_strips_content}>
                                  <OtherDocuments {...props} />
                                </div>
                                <div id={tabList[10]} className={styles.tab_strips_content}>
                                  <ActivityFeed {...props} />
                                </div>
                                <div id={tabList[11]} className={styles.tab_strips_content}>
                                  <NewBusinesses {...props} />
                                </div>
                            </TabStripTab>
                        );
                    }
                })}
            </TabStrip>
    );
};
export default TabStrips;
