import { KendoCard, KendoListView } from "../../../../../../components";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import right from "../../../../../../assets/img/right.svg";
import hourglass from "../../../../../../assets/img/hourglass.svg";
import {
  NUMBERS,
  partyPeshimapping,
  vahanStatusMapping,
} from "../../../../../../utils/constants/values.constants";
import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { other_vahan_column_keys, vahan_columns } from "./viewModel";
import { useDispatch, useSelector } from "react-redux";
import { PrAutomationService } from "../../../../../../services";
import AllVahanCheckDetails from "./all-vahan-check-details";
import { PR_AUTOMATION } from "../../../../../../store/types";
import { showToastMessage } from "../../../../../../utils/utils";
import { fetchOrderDetails } from "../../../../../../store/actions/pr-automation";
import styles from './style.module.css'
const VahanCheckCard = (props) => {
  const { appointmentId } = props || {};
  const prDetails = useSelector((state) => state.prAutomation);
  const { backgroundCheck, vahanMismatch } = prDetails || {};
  const {
    partiPeshiStatus,
    statusVahanCheck,
    statusRcCheck,
    blacklisted,
    theft,
    roadTaxStatus,
    challanPending,
    rtoNocIssued,
    unpaidChallanAmount,
    scrap,
    rtoCodeInspection,
    rtoCodeVahan,
    rtoCodeCurrent,
    rtoVahanArea,
    rtoVahanStateName,
  } = backgroundCheck || {};
  const [vahanCheckDetails, setVahanCheckDetails] = useState([]);
  const [isVahanDetails, setIsVahanDetails] = useState(false);
  const [vahanMismatchList, setVahanMismatchList] = useState([]);
  const [vahanCtas, setVahanCtas] = useState();
  const [vahanChecksList, setVahanChecksList] = useState([]);
  const [otherChecksList, setOtherChecksList] = useState([]);
  const vahanChecksCleared = "All Vahan checks cleared";
  const vahanChecksPending = "Vahan check is underway by quality check team";
  const vahanChecksProblem = "Vahan check has some problems";
  const dispatch = useDispatch();
  useEffect(() => {
    if (vahanMismatch && vahanMismatch.length) {
      const list = vahanMismatch.map((item) => {
        return {
          ...item,
          fromInspection:
            item["fromInspection"] === "null" ? "N/A" : item["fromInspection"],
          fromVahan: item["fromVahan"] === "null" ? "N/A" : item["fromVahan"],
          fromRcCheck:
            item["fromRcCheck"] === "null" ? "N/A" : item["fromRcCheck"],
        };
      });
      setVahanChecksList(list);
    }
  }, [vahanMismatch]);
  useEffect(() => {
    let CTAs = [];
    if (
      statusVahanCheck === NUMBERS.THREE &&
      vahanMismatchList &&
      vahanMismatchList.length
    ) {
      CTAs.push(
        <Button
          themeColor={"secondary"}
          rounded={"full"}
          onClick={handleReRaiseVahan}
          className={styles.reRaiseButton}
        >
          Re-raise Vahan
        </Button>
      );
    }
    setVahanCtas(CTAs);
  }, [statusRcCheck, vahanMismatchList, statusVahanCheck]);
  useEffect(() => {
    let list = [];
    vahanChecksList &&
      vahanChecksList.length &&
      vahanChecksList.forEach((item) => {
        if (item.vahanInspectionMismatch) {
          list.push(item);
        }
      });
    setVahanMismatchList(list);
  }, [vahanChecksList]);
  useEffect(() => {
    const key1 = other_vahan_column_keys.ITEM;
    const key2 = other_vahan_column_keys.DETAILS;
    const listItems = [
      {
        [key1]: "Blacklisted",
        [key2]: blacklisted ? blacklisted : "--",
      },
      {
        [key1]: "Theft",
        [key2]: theft ? theft : "--",
      },
      {
        [key1]: "Road tax status",
        [key2]: roadTaxStatus ? roadTaxStatus : "--",
      },
      {
        [key1]: "Challan pending",
        [key2]: challanPending ? challanPending : "--",
      },
      {
        [key1]: "RTO NOC issued",
        [key2]: rtoNocIssued ? rtoNocIssued : "--",
      },
      {
        [key1]: "Unpaid challan amount",
        [key2]: unpaidChallanAmount ? unpaidChallanAmount : "--",
      },
      {
        [key1]: "Scrap",
        [key2]: scrap ? scrap : "--",
      },
      {
        [key1]: "RTO (Inspection)",
        [key2]: rtoCodeInspection ? rtoCodeInspection : "--",
      },
      {
        [key1]: "RTO (Vahan)",
        [key2]: rtoCodeVahan ? rtoCodeVahan : "--",
      },
      {
        [key1]: "RTO (Current)",
        [key2]: rtoCodeCurrent
          ? rtoCodeCurrent +
            (rtoVahanArea && " - " + rtoVahanArea) +
            (rtoVahanStateName && " - " + rtoVahanStateName)
          : "--",
      },
    ];
    setOtherChecksList(listItems);
  }, [backgroundCheck]);
  useEffect(() => {
    let data = [];
    const vahanCheckStatus = vahanStatusMapping[statusVahanCheck];
    if (vahanCheckStatus === "Completed") {
      data.push({
        prefix: <span className={styles.vahanCheckComplted}>Completed</span>,
        details: vahanChecksCleared,
        value: vahanCtas?.map((cta) => <span className="k-ml-5">{cta}</span>),
      });
    } else if (vahanCheckStatus === "Pending") {
      data.push({
        prefix: <span className={styles.vahanCheckPending}>Pending</span>,
        details: vahanChecksPending,
        value: vahanCtas?.map((cta) => <span className="k-ml-5">{cta}</span>),
      });
    } 
    else if (vahanCheckStatus === "Challan Pending") {
      data.push({
        prefix: <span className={styles.vahanCheckPending}>Pending</span>,
        details: "Challan check is underway by quality check team",
        value: vahanCtas?.map((cta) => <span className="k-ml-5">{cta}</span>),
      });
    }
    else if (vahanCheckStatus === "Challan Completed") {
      data.push({
        prefix: <span className={styles.vahanCheckPending}>Pending</span>,
        details: vahanChecksCleared,
        value: vahanCtas?.map((cta) => <span className="k-ml-5">{cta}</span>),
      });
    } else if (vahanMismatchList && vahanMismatchList.length) {
      data.push({
        prefix: <span className={styles.vahanCheckPending}>Pending</span>,
        details: vahanChecksProblem,
        value: vahanCtas?.map((cta) => <span className="k-ml-5">{cta}</span>),
      });
    } 
    else {
      data.push({ prefix: null, details: null });
    }
    if (partiPeshiStatus) {
      data.push({
        prefix:
          partiPeshiStatus === NUMBERS.THREE ||
          partiPeshiStatus === NUMBERS.TWO ? (
            <span className={styles.vahanCheckPending}>Pending</span>
          ) : partiPeshiStatus === NUMBERS.ONE ? (
            <span className={styles.vahanCheckComplted}>Completed</span>
          ) : null,
        details: (
          <>
            <span>{partyPeshimapping[partiPeshiStatus]?.title}</span>{" "}
            {partyPeshimapping[partiPeshiStatus]?.subtitle && (
              <span className="k-font-size-xs">
                {"(" + partyPeshimapping[partiPeshiStatus]?.subtitle + ")"}
              </span>
            )}
          </>
        ),
      });
    }

    setVahanCheckDetails(data);
  }, [backgroundCheck, vahanCtas]);
  const handleReRaiseVahan = () => {
    const payload = {
      appointmentId,
      rcParticular: NUMBERS.ZERO,
    };
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.reRaiseVahan(payload)
      .then((res) => {
        if (res && res.status === 200) {
          showToastMessage("Vahan Check re-raised successfully", true);
          dispatch(
            fetchOrderDetails({
              appointmentId,
              documentCodes: ["AADHAR_CARD", "PAN_CARD_COPY"],
            })
          );
        }
      })
      .catch((error) => {
        showToastMessage(error?.message, false);
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
  const handleRaiseRCParticularCheck = () => {
    const payload = {
      appointmentId,
      rcParticular: NUMBERS.ONE,
    };
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.raiseRcCheck(payload)
      .then((res) => {
        if (res && res.status === 200) {
          showToastMessage("RC Particular Check raised successfully", true);
          dispatch(
            fetchOrderDetails({
              appointmentId,
              documentCodes: ["AADHAR_CARD", "PAN_CARD_COPY"],
            })
          );
        }
      })
      .catch((error) => {
        showToastMessage(error?.message, false);
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
  const renderHeaderTitle = () => {
    return (
      <div className="justify-between">
        <div className="k-font-bold k-font-size-xl">Vahan Check</div>
        {statusVahanCheck === NUMBERS.THREE && (
          <Button
            themeColor={"secondary"}
            rounded={"full"}
            onClick={() => setIsVahanDetails(!isVahanDetails)}
            className={styles.viewAllVahanButton}
          >
            View all Vahan details
          </Button>
        )} 
        {isVahanDetails && (
          <AllVahanCheckDetails
            setIsVahanDetails={setIsVahanDetails}
            vahanMismatch={vahanChecksList}
            otherChecksList={otherChecksList}
          />
        )}
      </div>
    );
  };
  const renderDetails = () => {
    return (
      <div className={styles.vahanContent}>
        {
          vahanCheckDetails[0]?.details 
          ? 
            <div className={styles.vahanContent_part1}>
              <KendoListView data={[vahanCheckDetails[0]]} />
                {
                  vahanMismatchList && vahanMismatchList.length ? 
                    (
                      <div className={`k-mt-4 ${styles.vahan_check_grid}`}>
                        <Grid data={vahanMismatchList || []}>
                          {Object.keys(vahan_columns).map((columnName) => {
                            const column = vahan_columns[columnName];
                            return (
                              <GridColumn
                                key={columnName}
                                field={column["field"]}
                                title={column["label"]}
                                cell={(props)=>{
                                  return(
                                    <td>{props?.dataItem?.[columnName]==='null'?'':props?.dataItem?.[columnName]}</td>
                                  )
                                }}
                              />
                            );
                          })}
                        </Grid>
                      </div>
                    ) 
                  : 
                    null
                }
            </div>
          : 
            "NA"
        }
        {
          vahanCheckDetails[1] && 
          <KendoListView data={[vahanCheckDetails[1]]} />
        }
      </div>
    );
  };
  return (
    <>
      <KendoCard
        cardStyle={{
          background: "#F0F2F5",
        }}
        headerTitle={renderHeaderTitle()}
        details={renderDetails()}
      />
    </>
  );
};

export default VahanCheckCard;
