import { useState } from "react";
import { FormTextArea } from "../../../../../../components";
import { challan_request_keys } from "../../../../challan-list/viewModels";
import { Button } from "@progress/kendo-react-buttons";
const AddDesc = (props) => {
	const { editedChallanRequest, setEditedChallanRequest } = props;
	const onInputChange = (e) => {
		setEditedChallanRequest({
			...editedChallanRequest,
			[challan_request_keys.DESCRIPTION]: e.target.value,
		});
	};
	return (
		<div>
			<FormTextArea
				name={challan_request_keys.DESCRIPTION}
				label={"Description"}
				placeholder={"Add description"}
				value={props?.editedChallanRequest[challan_request_keys.DESCRIPTION]}
				onChange={onInputChange}
			/>
		</div>
	);
};
export default AddDesc;
