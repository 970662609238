import { FORM_ELEMENT_TYPE } from "../../../../utils/constants/values.constants";
import { centre_column_keys } from "../centre-detail/viewModal";
import { STATUS_DATA } from "../create-dealer/viewModels";

export const FormData = {
    [centre_column_keys.CENTRE_NAME]: {
        name:centre_column_keys.CENTRE_NAME,
        label:"Centre Name",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
    },
    [centre_column_keys.CENTRE_ADDRESS]: {
        name:centre_column_keys.CENTRE_ADDRESS,
        label:"Centre Address",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
    },
    [centre_column_keys.PINCODE]: {
        name:centre_column_keys.PINCODE,
        label:"Pincode",
        elementtype: FORM_ELEMENT_TYPE.SEARCH,
    },
    [centre_column_keys.CITY_ID]: {
        name:centre_column_keys.CITY_ID,
        label:"City Id",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
    },
    [centre_column_keys.CITY_NAME]: {
        name:centre_column_keys.CITY_NAME,
        label:"City Name",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
    },
    [centre_column_keys.LATITUDE]: {
        name:centre_column_keys.LATITUDE,
        label:"Latitude",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
    },
    [centre_column_keys.LONGITUDE]: {
        name:centre_column_keys.LONGITUDE,
        label:"Longitude",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
    },
    [centre_column_keys.STATUS]: {
        name:centre_column_keys.STATUS,
        label:"Status",
        elementtype: FORM_ELEMENT_TYPE.SELECT,
        source: STATUS_DATA
    },
    [centre_column_keys.MAKE_ID]: {
        name: centre_column_keys.MAKE_ID,
        label: "Make ID",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
    },
    [centre_column_keys.CITY_AREA]: {
        name:centre_column_keys.CITY_AREA,
        label:"City Area",
        elementtype: FORM_ELEMENT_TYPE.MULTI_SELECT,
    },
    ["BREAK_KEY_ONE"] : {
        elementtype:"BREAK",
    },
    [centre_column_keys.UCM_NAME]: {
        name:centre_column_keys.UCM_NAME,
        label:"UCM Name",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
    },
    [centre_column_keys.UCM_PHONE]: {
        name:centre_column_keys.UCM_PHONE,
        label:"UCM Phone",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
    },
    [centre_column_keys.UCM_EMAIL]: {
        name:centre_column_keys.UCM_EMAIL,
        label:"UCM Email",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
    },
    ["BREAK_KEY_TWO"] : {
        elementtype:"BREAK",
    },
    [centre_column_keys.RA_PHONE]: {
        name:centre_column_keys.RA_PHONE,
        label:"RA Phone",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
    },
    [centre_column_keys.RA_EMAIL]: {
        name:centre_column_keys.RA_EMAIL,
        label:"RA Email",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
    },
    [centre_column_keys.RA_NAME]: {
        name:centre_column_keys.RA_NAME,
        label:"RA Name",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
    },
    ["BREAK_KEY_THREE"] : {
        elementtype:"BREAK",
    },
    [centre_column_keys.NCM] : {
        name:centre_column_keys.NCM,
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        elements: []
    }
}

export const NCM_ELEMENT = { 
    ncmName: { value: "", valid: true }, 
    ncmEmail: { value: "", valid: true }, 
    ncmMobile: { value: "", valid: true },
    isNotificationEnabled: { value: false, valid: true },
}

export const NCM_FIELD_LABELS = {
    ncmEmail: "NCM Email",
    ncmName: "NCM Name",
    ncmMobile: "NCM Mobile",
}

export const NCM_VALIDATION_MESSAGE = {
    ncmEmail: "Please enter NCM Email",
    ncmName: "Please enter NCM Name",
    ncmMobile: "Please enter NCM Mobile",
}