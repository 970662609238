function DangerIcon(props) {
  return (
    <svg
      width={props?.width || 16}
      height={props?.height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 9V4h2v5H7zM7 10v2h2v-2H7z" fill={props.fill || "#F31700"}/>
      <path
        d="M15 8A7 7 0 11.999 7.999 7 7 0 0115 8zm-1 0c0-3.313-2.688-6-6-6-3.313 0-6 2.688-6 6 0 3.313 2.688 6 6 6 3.313 0 6-2.688 6-6z"
        fill={props.fill || "#F31700"}
      />
    </svg>
  )
}

export default DangerIcon
