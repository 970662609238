import { FORM_ELEMENT_TYPE } from "../../../../utils/constants/values.constants";
import { challan_item_keys } from "../../components/challan-detail-revamp/viewModel";
import { verification_column_keys } from "../viewModal";

export const verification_detail_list_keys = [
	{
		title: "Reg Number",
		field: "regNumber",
	},
	{
		title: "Notice Number",
		field: "noticeNumber",
	},
	{
		title: "Challan Mode",
		field: "type",
	},
	{
		title: "Source",
		field: "sourceCode",
	},
	{
		title: "Challan Court",
		field: "court",
	},
	{
		title: "Challan Amount",
		field: "amount",
	},
	{
		title: "Offence Date",
		field: "offenceDate",
	},
	{
		title: "Offence Type",
		field: "offenceName",
	},
	{
		title: "Finder Proof",
		field: "challanPdfUrl",
	},
	{
		title: "Assigned Agent",
		field: "agent",
	},
	{
		title: "Agent Fee",
		field: "agentFees",
	},
	{
		title: "Government Fee",
		field: "settlementAmount",
	},
	{
		title: "Remarks",
		field: "remarks",
	},
	{
		title: "Raised By",
		field: "createdBy",
	},
	{
		title: "Dispose Date",
		field: "paymentDate",
	},
	{
		title: "Closing Date",
		field: verification_column_keys.CLOSURE_DATE,
	},
	{
		title: "Verification Agent",
		field: verification_column_keys.VERIFIED_BY //todo
	},
	{
		title: "Agent Costing Link",
		field: "agentCostingLink", //todo
	},
];

export const VERIFICATION_STATUS_SOURCES = [
	{
		label: "ACCEPTED",
		value: "ACCEPTED",
	},
	{
		label: "REJECTED",
		value: "REJECTED",
	},
	{
		label: "PENDING",
		value: "PENDING",
	},
];

export const VERIFICATION_PAYMENT_FORM_DATA = {
	[verification_column_keys.REG_NUMBER]: {
		name: verification_column_keys.REG_NUMBER,
		label: "Vehicle No.",
		elementtype: FORM_ELEMENT_TYPE.INPUT,
		autocomplete: "false",
		disabled: true,
	},
	[verification_column_keys.CHALLAN_AMOUNT]: {
		name: verification_column_keys.CHALLAN_AMOUNT,
		label: "Challan Amount",
		elementtype: FORM_ELEMENT_TYPE.INPUT,
		disabled: true,
		value: null,
	},
	[verification_column_keys.CHALLAN_MODE]: {
		name: verification_column_keys.CHALLAN_MODE,
		label: "Challan Mode",
		elementtype: FORM_ELEMENT_TYPE.INPUT,
		disabled: true,
		value: null,
	},
	[verification_column_keys.REJECTION_REASON]: {
		name: verification_column_keys.REJECTION_REASON,
		label: "Verification Rejection Reason",
		elementtype: FORM_ELEMENT_TYPE.INPUT,
		disabled: true,
		value: null,
		hide: true,
	},
	[verification_column_keys.STATUS]: {
		name: verification_column_keys.STATUS,
		label: "Challan Status",
		elementtype: FORM_ELEMENT_TYPE.INPUT,
		disabled: true,
		value: null,
	},
	[verification_column_keys.AGENT_FESS]: {
		name: verification_column_keys.AGENT_FESS,
		label: "Agent fees ",
		elementtype: FORM_ELEMENT_TYPE.INPUT,
		disabled: true,
		value: null,
	},
	[verification_column_keys.SETTLEMENT_AMOUNT]: {
		name: verification_column_keys.SETTLEMENT_AMOUNT,
		label: "Goverment Fee",
		elementtype: FORM_ELEMENT_TYPE.INPUT,
		disabled: true,
		value: null,
	},
	[verification_column_keys.FILE_SINGLE_UPLOAD]: {
		name: verification_column_keys.FILE_SINGLE_UPLOAD,
		label: "Upload RTO website screenshot",
		elementtype: FORM_ELEMENT_TYPE.UPLOAD_FILE,
		accept: ".pdf, .png, .jpg",
		value: null,
		handleFileView: null,
		showFileView: true,
		disabled: true,
	},
	[verification_column_keys.GOVERNMENT_FEE_PROOF]: {
		name: verification_column_keys.GOVERNMENT_FEE_PROOF,
		label: "Upload Government Fee Receipt",
		elementtype: FORM_ELEMENT_TYPE.UPLOAD_FILE,
		accept: ".pdf, .png, .jpg",
		value: null,
		handleFileView: null,
		showFileView: true,
		disabled: true,
	},
	[verification_column_keys.EXCEPTION_PROOF]: {
		name: verification_column_keys.EXCEPTION_PROOF,
		label: "Exception Proof",
		elementtype: FORM_ELEMENT_TYPE.UPLOAD_FILE,
		maxLength: 200,
		value: null,
		checkboxValue: true,
		checkboxLabel: "Is this an exception case?",
		handleFileView: null,
		showFileView: true,
		disabled: true,
	},
	[verification_column_keys.VERIFICATION_STATUS]: {
		name: verification_column_keys.VERIFICATION_STATUS,
		label: "Verification Status",
		isDisplayLabel: true,
		elementtype: FORM_ELEMENT_TYPE.SELECT,
		value: null,
		source: VERIFICATION_STATUS_SOURCES,
	},
	[verification_column_keys.VERIFICATION_REJECTION_REASON]: {
		name: verification_column_keys.VERIFICATION_REJECTION_REASON,
		label: "Rejection Reason",
		isDisplayLabel: true,
		elementtype: FORM_ELEMENT_TYPE.SELECT,
		value: null,
		source: [],
		hide: true,
	},
};

export const FF_REASON_TYPES = {
	REJECTED: "reject-reason",
	UNFULFILLED: "unfulfill-reason",
};
