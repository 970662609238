import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React, { useEffect } from "react";
import { service_history_columns } from "../viewModel";
import { useSelector, useDispatch } from "react-redux";
import { fetchServiceHistory } from "../../../../../../../store/actions/pr-automation";

const AllServiceHistoryDetails = (props) => {
  const dispatch = useDispatch();
  const { setIsServiceHistoryDetails } = props || {};
  const prDetails = useSelector((state) => state.prAutomation);
  const { serviceHistory } = prDetails || {};
  const lms = useSelector((state) => state.lms);
  const { appointmentId } = lms || {};
  useEffect(() => {
    if (appointmentId) {
      dispatch(fetchServiceHistory(appointmentId));
    }
  }, [appointmentId]);
  return (
    <Dialog
      title={"All Sevice history details"}
      onClose={() => setIsServiceHistoryDetails(false)}
      width={"50%"}
      contentStyle={{ width: "auto" }}
    >
      <Grid data={serviceHistory || []}>
        {Object.keys(service_history_columns).map((columnName) => {
          const column = service_history_columns[columnName];
          return (
            <GridColumn
              key={columnName}
              field={column["field"]}
              title={column["label"]}
            />
          );
        })}
      </Grid>
    </Dialog>
  );
};
export default AllServiceHistoryDetails;
