
import { useEffect, useState } from "react";
import { ChipList } from "@progress/kendo-react-buttons";

const AppChips = ({ data = [], isPlainArray = true, className = "primary-chips" }) => {
	const [chips, setChips] = useState([]);
	useEffect(() => {
		if (isPlainArray) {
			let newData = data.map((ele, index) => {
				return {
					text: ele,
					value: "tab" + index,
				}
			});
			setChips([...newData]);

		} else {
			setChips([...data]);
		}
	}, [data.length])
	return (
		<>
			<div className={`app-chips ${className}`}>
				<ChipList
					defaultData={[...chips]}
					data={[...chips]}
					selection="multiple"
				/></div>
		</>
	);
};
export default AppChips;
