import React from 'react';
import styles from './style.module.css'
import { getDisplayedCoulmns} from '../../../../../../../utils/utils';
import CustomGrid from '../../../../../../../common/custom-grid/component';
import { SCORE_CARD_COLUMN_KEYS, getColumnHeader } from './view-model';
import { Loader } from '@progress/kendo-react-indicators';

function RAScoreCardCollection(props) {
    const {scorecardDataLoading,dataByRequestIdLoading,scorecardData,dataByRequestId,scorecardErrorMessage,ownerNameOnRc} = props

    const renderCollections = () => {
      if((scorecardDataLoading || dataByRequestIdLoading) || ((scorecardData?.msg == 'SUCCESS' || dataByRequestId?.msg == 'SUCCESS') && dataByRequestId?.data?.status != 'PROCESSED')){
        return(
          <Loader/>
        )
      }
      else if (scorecardErrorMessage?.msg == 'Not Found.'){
        return (
          <p className={styles['head_collections--error']}>No records found</p>
        ) 
      }
      else if (dataByRequestId && dataByRequestId?.data?.status =='PROCESSED')
      {
        const data = dataByRequestId?.data?.response?JSON.parse(dataByRequestId?.data?.response):undefined
        const scoringData = JSON.parse(data?.data?.text)
        return(
          <div className={styles.head_scorecard}>
            <div className={styles.head_scorecard_header}>
              <div className={styles['progress-bar']}>{scoringData?.summarizedScore?.finalScore}</div>
              <div className={styles.head_scorecard_header_content}>
                <p className={styles.head_scorecard_header_content_p1}>Your score {ownerNameOnRc?'with ' + ownerNameOnRc:''}</p>
                <p className={styles.head_scorecard_header_content_p2}>Well Done! Keep up the good work</p>
              </div>
            </div>
            <div className={styles.scorecard_grid}>
              <CustomGrid
                displayedColumns={getDisplayedCoulmns(SCORE_CARD_COLUMN_KEYS)}
                data={scoringData?.scoring}
                count={scoringData?.scoring?.length}
                columnMap={getColumnHeader({})}
                isPagination={false}
              />
            </div>
          </div>
        )
      }
    }

    return (
        <div className={styles['head']}>
          {
            renderCollections()
          }
        </div>
    );
}



export default RAScoreCardCollection;


