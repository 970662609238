import { FORM_ELEMENT_TYPE } from "../../../../../utils/constants/values.constants";

export const getPrStatus = (status) => {
  let result = { text: "", color: "" };
  switch (status) {
    case "INITIALIZED":
      result = { text: "Initialized", color: "neutral" };
      break;
    case "DECLINED":
      result = { text: "Rejected", color: "reject" };
      break;
    case "SUBMITTED":
      result = { text: "Submitted", color: "pending" };
      break;
    case "APPROVED":
      result = { text: "Approved", color: "success" };
      break;
    default:
      break;
  }
  return result;
};
export const carOwnershipTypes = [
  { label: "Individual", value: "INDIVIDUAL" },
  { label: "Company", value: "COMPANY" },
  { label: "Partnership", value: "PARTNERSHIP" },
  { label: "Proprietorship", value: "PROPRIETORSHIP" },
  { label: "Repossessed", value: "REPOSSESSED" },
  { label: "Others", value: "OTHERS" },
];

export const ba_column_keys = {
  BA_TYPE: "issueType",
  SALES_APPROVAL: "salesApproval",
  OPS_APPROVAL: "opsApproval",
  HEAD_APPROVAL: "cityHeadApproval",
};

export const ba_columns = {
  [ba_column_keys.BA_TYPE]: {
    field: ba_column_keys.BA_TYPE,
    label: "BA Type",
  },
  [ba_column_keys.SALES_APPROVAL]: {
    field: ba_column_keys.SALES_APPROVAL,
    label: "Sales Approval",
  },
  [ba_column_keys.OPS_APPROVAL]: {
    field: ba_column_keys.OPS_APPROVAL,
    label: "Ops Approval",
  },
  [ba_column_keys.HEAD_APPROVAL]: {
    field: ba_column_keys.HEAD_APPROVAL,
    label: "City head approval",
  },
};

export const getStatus = (required, allowed) => {
  let result;
  if (required) {
    switch (allowed) {
      case true:
        result = "Approved";
        break;
      case false:
        result = "Rejected";
        break;
      case null:
        result = "Pending";
        break;
      default:
        result = "NA";
        break;
    }
  } else {
    result = "Not required";
  }
  return result;
};

export const getStatusMapping = {
  Approved: "success",
  Rejected: "reject",
  Pending: "pending",
  NA: "neutral",
  "Not required": "neutral",
};
export const getStaticText = {
  addPickUp:
    "Use this option to select the seller’s preferred day, time slot, and pickup address.",
  fullyMismatchFriendFamily: "As per customer, Car is of friends/family car",
  fullyMismatchCompanyOwned: "As per customer, Car is company owned car",
  partialMismatchCompanyOwned: "As per customer, Name on RC is wrong",
  noReasonMarked: "Customer didn't mark anything",
  fullyVerified: "Penny drop successfully completed",
  verificationLinkText:
    "By this link, customer will be able to add Bank Account details himself",
  addBankAccountText:
    "Use this option only if the customer wants to use a different account for receiving payments",
};
export const pickup_column_keys = {
  STATE: "fromState",
  CITY: "fromCity",
  PINCODE: "fromPincode",
  ADDRESS: "fromAddress",
};
export const PickUpFormData = {
  [pickup_column_keys.STATE]: {
    name: pickup_column_keys.STATE,
    label: "State",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    disabled: false,
    value: "",
    isDisplayLabel: true,
  },
  [pickup_column_keys.CITY]: {
    name: pickup_column_keys.CITY,
    label: "City",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    disabled: false,
    value: "",
    isDisplayLabel: true,
  },
  [pickup_column_keys.PINCODE]: {
    name: pickup_column_keys.PINCODE,
    label: "PIN Code",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    disabled: false,
    value: null,
    type: "number",
    min: 0,
  },
  [pickup_column_keys.ADDRESS]: {
    name: pickup_column_keys.ADDRESS,
    label: "Address",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    disabled: false,
    value: "",
  },
};

export const getBadge = (data, type) => {
  switch (type) {
    case "success": {
      return <span className="status-success">{data}</span>;
    }
    case "grey": {
      return <span className="status-grey">{data}</span>;
    }
    case "pending": {
      return <span className="status-inprogress">{data}</span>;
    }
    case "reject": {
      return <span className="status-reject">{data}</span>;
    }
    case "neutral": {
      return <span className="status-neutral">{data}</span>;
    }
    default: {
      return data;
    }
  }
};

export const getBadgeV2 = (data, type) => {
  switch (type) {
    case "success": {
      return <span className="status-success-v2">{data}</span>;
    }
    case "grey": {
      return <span className="status-grey-v2">{data}</span>;
    }
    case "pending": {
      return <span className="status-inprogress-v2">{data}</span>;
    }
    case "reject": {
      return <span className="status-reject-v2">{data}</span>;
    }
    case "neutral": {
      return <span className="status-neutral-v2">{data}</span>;
    }
    default: {
      return data;
    }
  }
};
