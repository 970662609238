export const replaceAndOr = (value) => {
	// Replace "And" with "&&"
	let expression = value.replace(/\bAnd\b/g, "&&");
	// Replace "Or" with "||"
	expression = expression.replace(/\bOr\b/g, "||");
	return expression;
};

export const replaceSymbolAndOr = (value) => {
	// Replace "&&" with "And"
	let expression = value.replace(/&&/g, " And ");

	// Replace "||" with "Or"
	expression = expression.replace(/\|\|/g, " Or ");

	return expression;
};
