function BidIcon(props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.988 5.533L8.875 7.446l.633 2.85a.806.806 0 01-.028.447.776.776 0 01-.261.358.732.732 0 01-.828.037L5.997 9.631l-2.389 1.507a.729.729 0 01-.828-.037.776.776 0 01-.261-.358.806.806 0 01-.028-.448l.632-2.846L1.01 5.533a.782.782 0 01-.232-.38.806.806 0 01.009-.45.779.779 0 01.247-.369.734.734 0 01.403-.165l2.785-.25L5.31 1.226A.77.77 0 015.585.88a.728.728 0 01.828 0 .77.77 0 01.275.347l1.09 2.691 2.785.25c.147.013.287.071.403.166a.779.779 0 01.247.37.806.806 0 01.01.45.782.782 0 01-.233.379h-.002z"
        fill="#FCEDF8"
      />
    </svg>
  )
}

export default BidIcon
