import { DEALER_PROCUREMENT } from "../types";

const INITIAL_STATE = {
  isLoading: false,
  currentIndex: 0,
  data: [],
  totalCount: 0,
  numberOfElements: 0,
  error: {},
  dealerId:null
};

const DealerProcurement = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DEALER_PROCUREMENT.FETCH_LOADING_START:
      return {
        ...state,
        isLoading: true,
      };
    case DEALER_PROCUREMENT.FETCH_LOADING_END:
      return {
        ...state,
        isLoading: false,
      };
    case DEALER_PROCUREMENT.FETCH_DEALER_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        totalCount: action.payload.totalElements,
        numberOfElements: action.payload.numberOfElements,
        error: {},
      };
    case DEALER_PROCUREMENT.FETCH_DEALER_LIST_FAILURE:
      return {
        ...state,
        data: [],
        totalCount: 0,
        numberOfElements: 0,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default DealerProcurement;
