import { getFFUserType } from "../utils/utils";

export default (api) => {

    const fetchAnnouncements = (params) => {
        return new Promise((resolve, reject) => {
            api
                .get(`/fetch/feed/list${params}`, {
                    headers: { "user-type": `${getFFUserType()}` },
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    const saveAnnouncement = (payload, type = 'save') => {
        return new Promise((resolve, reject) => {
            const request = api.post;

            request(`/feed/${type}`, payload, {
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    "user-type": `${getFFUserType()}` 
                },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    return {
        fetchAnnouncements,
        saveAnnouncement,
    };
};
