import { useState, useEffect, useCallback } from "react";
import { ANNOUNCEMENT_COLUMN_MAPPER, CJ_ANNOUNCEMENT_KEYS } from "../viewModel";
import AnnouncementActions from '../components/announcement-actions'
import { filterGridHelper } from "../helper";
import { ColumnMenuCheckboxFilter } from "../../../../../../components/custom-grid-filters";
import { isFilterFieldPresent } from "../../../../../../utils/utils";
import { CjIncentivesService } from "../../../../../../services";

export const useVisibleColumns = (setAnnouncementDetails, gridFilters, setGridFilters, filterParams, setFilterParams, setPaginationParams, setItemType) => {
    const [visibleColumns, setVisibleColumns] = useState(ANNOUNCEMENT_COLUMN_MAPPER);
    const [store, setStore] = useState({})
    const handleFilterButtonClick = useCallback((key, e, data) => {
        let _filterParamsData = filterGridHelper(key, e, data, filterParams, setGridFilters, setPaginationParams)
        setFilterParams(_filterParamsData);
    }, [filterParams, setFilterParams]);

    useEffect(() => {
        CjIncentivesService.fetchAllClusters({ pageNumber: 0, pageSize: 100 })
            .then((resp) => {
                let tempClusterData = [];
                resp.detail.content.map((item) => {
                    tempClusterData.push(
                        {
                            label: item.name,
                            value: item.idCluster
                        })
                })
                setStore({ [CJ_ANNOUNCEMENT_KEYS.CLUSTER]: tempClusterData })
            })
            .catch((err) => {
                console.log('err', err)
            })
    }, [])

    useEffect(() => {
        setVisibleColumns((columns) => ({
            ...columns,
            [CJ_ANNOUNCEMENT_KEYS.ACTION]: {
                ...columns[CJ_ANNOUNCEMENT_KEYS.ACTION],
                cell: (props) => <AnnouncementActions {...props} setAnnouncementDetails={setAnnouncementDetails} setItemType={setItemType} />,
            },
            [CJ_ANNOUNCEMENT_KEYS.CLUSTER]: {
                ...columns[CJ_ANNOUNCEMENT_KEYS.CLUSTER],
                headerClassName: isFilterFieldPresent(gridFilters?.filters, CJ_ANNOUNCEMENT_KEYS.CLUSTER) ? "selected-filter" : "de-selected-filter",
                columnMenu: (props) => (
                    <ColumnMenuCheckboxFilter
                        {...props}
                        filterData={store}
                        userAssignments={null}
                        onFilterButtonClick={handleFilterButtonClick}
                        columnMapKey={CJ_ANNOUNCEMENT_KEYS.CLUSTER}
                        filter={gridFilters}
                    />
                ),
            }
        }));
    }, [gridFilters, handleFilterButtonClick, setAnnouncementDetails, store]);
    return visibleColumns;
};