import axios from "axios";
import { getSessionToken } from "@descope/react-sdk";
import { ERROR_CODES } from "../utils/constants/values.constants";
import { getNewCarServiceBaseUrl } from "../utils/constants/url.constants";
import { onBackLogin } from "../utils/utils";


const DEBUG = process.env.NODE_ENV === "development";
const URL = getNewCarServiceBaseUrl();
const api = axios.create({
    baseURL: URL,
    headers: {
        "Content-Type": "application/json",
    },
});

api.interceptors.request.use(
    (config) => {
        // config.headers["Authorization"] = `Bearer ${getSessionToken()}`;
        config.headers["x-auth-key"] = `${getSessionToken()}`;
        if (DEBUG) {
            console.info("✉️ ", config);
        }
        return config;
    },
    (error) => {
        if (DEBUG) {
            console.error("✉️ ", error);
        }
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        if (DEBUG) {
            console.info("📩 ", response);
        }
        return response;
    },
    (error) => {
        if (error.response) {
            if (DEBUG) {
                console.error("Error: ", error.response);
            }
            if (
                error &&
                error.response.status === ERROR_CODES.UNAUTHORISED &&
                !window.location.pathname.includes("login")
            ) {
                localStorage.clear();
                onBackLogin();
            }
            return Promise.reject(error.response.data);
        }
        if (DEBUG) {
            console.error("📩 ", error);
        }
        return Promise.reject(error.message);
    }
);

export default function () {

    return {
        getCities: (cityId) => {
            const url = `api/v1/consumer-api/ncd/areas/${cityId}`
            return new Promise((resolve, reject) => {
                api
                    .get(url)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    }

} 
