import "./share-car-modal.scss";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
const ShareCarModal = ({ close, count }) => {

  return (
    <>
      <div >
        <Dialog
          title={`Are you sure you want to share ${count} car(s) with the dealer?`}
          className="app-share-car-modal" onClose={() => close(false)}>
          <DialogActionsBar>
            <Button
              className='app-warning-btn'
              fillMode={'outline'}
              onClick={() => { close(false) }}
            >
              Cancel
            </Button>
            <Button
              className='app-primary-btn share-button '
              fillMode={'outline'}
              onClick={() => { close(true) }}
            >
              Yes, Share
            </Button>

          </DialogActionsBar>

        </Dialog>
      </div>
    </>
  );
};
export default ShareCarModal;
