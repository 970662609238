

export const FULFILLMENT_STATUS = [
    {
        label:"Fulfilled",
        value:"FULFILLED"
    },{
        label:"Unable to Fulfill",
        value:"UNABLE_TO_FULFILL"
    },
]

export const service_detail_keys = {
    REG_NUMBER: "regNumber",
    MAKE: "make",
    MODEL: "model",
    VARIANT: "variant",
    YEAR: "year",
    SOURCE:"source"
  };

  export const serviceDetailsColumns = {
    [service_detail_keys.REG_NUMBER]: {
      field: service_detail_keys.REG_NUMBER,
      label: "Reg Number",
      width: "250px",
    },
    [service_detail_keys.MAKE]: {
        field: service_detail_keys.MAKE,
        label: "Make",
        width: "250px",
      },
      [service_detail_keys.MODEL]: {
        field: service_detail_keys.MODEL,
        label: "Model",
        width: "250px",
      },
      [service_detail_keys.VARIANT]: {
        field: service_detail_keys.VARIANT,
        label: "Variant",
        width: "250px",
      },
      [service_detail_keys.YEAR]: {
        field: service_detail_keys.YEAR,
        label: "Year",
        width: "250px",
      },
      [service_detail_keys.SOURCE]: {
        field: service_detail_keys.SOURCE,
        label: "Source",
        width: "250px",
      }
}

export const getDisplayedCoulmns = () => {
    return Object.keys(serviceDetailsColumns);
  };