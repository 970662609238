// Function to check the validity of a mathematical algebraic equation
export const isValidEquation = (equation) => {
	if (!equation) return true;
	try {
		const tokens = tokenizeEquation(equation);
		// Check syntax and variables
		return checkSyntaxAndVariables(tokens);
	} catch (error) {
		return false;
	}
};

export const isValidParams = (equation, masterParams = []) => {
	if (!equation) return true;
	const tokens = equation.match(
		/[\(\)><=|&]+|[a-zA-Z_][a-zA-Z0-9_]*(?![0-9])/g
	);
	if (!!masterParams?.length && !!tokens?.length) {
		// Iterate over each element in the array
		for (let element of tokens) {
			// Check if the element exists in the master array
			if (!masterParams.includes(element)) {
				return false; // Element not found in master array
			}
		}
	}
	return true; // All elements found in master array
};

/// Function to tokenize the equation
const tokenizeEquation = (equation) => {
	// Tokenize the equation using regular expressions to capture operators, parentheses, and variable names
	return equation.match(
		/[a-zA-Z_][a-zA-Z0-9_]*|<=|>=|!=|==|&&|\|\||[()+\-*/]|[0-9]+/g
	);
};

// Function to check syntax and variables
const checkSyntaxAndVariables = (tokens) => {
	let isValid = true;
	let parenCount = 0;

	for (let i = 0; i < tokens.length; i++) {
		const token = tokens[i];

		if (token === "(") {
			parenCount++;
		} else if (token === ")") {
			parenCount--;
			if (parenCount < 0) {
				isValid = false;
				break;
			}
		} else if (token === "And" || token === "Or") {
			// Check if 'And' or 'Or' is preceded and followed by valid expressions
			const prevToken = tokens[i - 1];
			const nextToken = tokens[i + 1];
			if (
				!prevToken ||
				!nextToken ||
				!isValidExpression(prevToken) ||
				!isValidExpression(nextToken)
			) {
				isValid = false;
				break;
			}
		} else if (!isValidExpression(token)) {
			// Check if token is a valid expression (variable, comparison operator, value)
			isValid = false;
			break;
		}
	}
	// Ensure parentheses are balanced
	return isValid && parenCount === 0;
};

// Function to check if token is a valid expression
const isValidExpression = (token) => {
	// For simplicity, assuming variable names can include letters, underscores, and digits
	return (
		/^[a-zA-Z0-9_]+$/.test(token) ||
		/^[0-9]+$/.test(token) ||
		["<", ">", "<=", ">=", "=", "!=", "*", "/", "+", "-", "(", ")"].includes(
			token
		)
	);
};
