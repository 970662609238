import { SERVICE_HISTORY } from "../types";

const INITIAL_STATE = {
	isLoading: false,
	currentIndex: 0,
	data: [],
	totalCount: 0,
	numberOfElements: 0,
	error: {},
	searchQuery: "",
	userAssignments: 0,
	filters: {},
};

const ServiceHistory = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SERVICE_HISTORY.FETCH_LOADING_START:
			return {
				...state,
				isLoading: true,
			};
		case SERVICE_HISTORY.FETCH_LOADING_END:
			return {
				...state,
				isLoading: false,
			};
		case SERVICE_HISTORY.FETCH_SERVICE_LIST_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				totalCount: action.payload.totalElements,
				numberOfElements: action.payload.numberOfElements,
				error: {},
			};
		case SERVICE_HISTORY.FETCH_SERVICE_LIST_FAILURE:
		case SERVICE_HISTORY.RESET_SERVICE_LIST_ITEMS:
			return {
				...state,
				data: [],
				totalCount: 0,
				numberOfElements: 0,
				error: action.payload,
				searchQuery: "",
			};
		case SERVICE_HISTORY.SET_ASSIGNMENT_TYPE:
			return {
				...state,
				userAssignments: action.payload,
			};
		case SERVICE_HISTORY.UPDATE_SERVICE_LIST_ITEM:
			const currentItemIndex = state.data.findIndex(
				(item) =>
					item.idServiceHistoryRequest ===
					action?.payload?.idServiceHistoryRequest
			);
			const currentItem = state.data[currentItemIndex];
			let updateCurrentItem = { ...currentItem };
			Object.keys(currentItem).map((key) => {
				updateCurrentItem[key] = action?.payload[key];
			});
			const { make, model, variant, year } = updateCurrentItem;
			updateCurrentItem = {
				...updateCurrentItem,
				carDetails: `${make}/${model}/${variant}/${year}`,
			};
			state.data[currentItemIndex] = updateCurrentItem;
			return { ...state };
		case SERVICE_HISTORY.SET_SEARCH_QUERY:
			return {
				...state,
				searchQuery: action.payload,
			};
		case SERVICE_HISTORY.SET_SERVICEHISTORY_FILTERS:
			return {
				...state,
				filters: action.payload,
			};
		default:
			return state;
	}
};
export default ServiceHistory;
