import {
  ExpansionPanel,
  ExpansionPanelContent,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import { useDispatch, useSelector } from "react-redux";
import { getBadge } from "../../pr-automation/viewModel";
import { useEffect, useState } from "react";
import { KendoCard, KendoListView } from "../../../../../../components";
import { Button } from "@progress/kendo-react-buttons";
import cardImage from "../../../negotiation/assets/images/card.svg";
import titleBar from "../../shared/title-bar";
import { DmsService } from "../../../../../../services";
import { PR_AUTOMATION } from "../../../../../../store/types";
import {
  getLoginUserEmail,
  showToastMessage,
} from "../../../../../../utils/utils";
import styles from "../../../negotiation/assets/styles/pr.styles.module.css";
import { isPrInitialized } from "../viewModel";
const KycDocs = (props) => {
  const dispatch = useDispatch();
  const { docCode, setDocCode, filteredRules, setFilteredRules } = props || {};
  const [showKycDocs, setShowKycDocs] = useState(false);
  const prDetails = useSelector((state) => state.prAutomation);
  const {
    docs = [],
    documentStatus = [],
    uploadAuthData = {},
    orderData: { purchase: { purchaseStatus = "" } = {} } = {},
  } = prDetails || {};
  const { accessToken = "" } = uploadAuthData || {};
  useEffect(() => {
    if (docs && docs.length) {
      const list = docs.filter((item) => item.category === "KYC_DOCUMENTS");
      setFilteredRules(list);
    }
  }, [docs]);
  const getStatus = (code) => {
    const status =
      documentStatus?.length &&
      documentStatus?.find((item) => item.docCode === code)
        ?.docketDocumentStatus;
    let docStatus = "Not Uploaded";
    let docCss = "grey";
    switch (status) {
      case "PENDING":
        docStatus = "Uploaded";
        docCss = "pending";
        break;
      case "APPROVED":
        docStatus = "Verified";
        docCss = "success";
        break;
      case "REJECTED":
        docStatus = "Rejected";
        docCss = "reject";
        break;
      default:
        break;
    }
    return { docStatus, docCss };
  };
  const renderComponents = () => {
    const cardsList = [];

    for (let i = 0; i < filteredRules?.length; i += 2) {
      const docPairs = filteredRules.slice(i, i + 2);
      const cards = [];
      docPairs?.length &&
        docPairs?.forEach((doc, num) => {
          const src = doc?.uploads?.[0]?.attachmentName
            ? doc?.uploads?.[0]?.attachmentName
            : cardImage;
          cards.push(
            <GridLayoutItem col={num + 1}>
              <KendoCard
                cardStyle={{
                  marginTop: "0px",
                  marginRight: num === 0 ? "20px" : "",
                }}
                titleStyle={{ fontWeight: "500", fontSize: "18px" }}
                details={
                  <KendoListView
                    data={[
                      {
                        details: (
                          <div
                            className={`${styles.kycImage} ${
                              doc?.uploads?.[0]?.attachmentName
                                ? styles.blurredImage
                                : ""
                            }`}
                          >
                            {doc?.uploads?.[0]?.fileType === "pdf" ? (
                              <object
                                data={src}
                                type="application/pdf"
                              ></object>
                            ) : (
                              <img alt="test" src={src} />
                            )}
                          </div>
                        ),
                        value: getBadge(
                          getStatus(doc?.code)?.docStatus,
                          getStatus(doc?.code)?.docCss
                        ),
                      },
                      {
                        details: (
                          <span className="k-font-medium k-font-size-lg">
                            {doc?.name}
                          </span>
                        ),
                        value: (
                          <Button
                            themeColor="secondary"
                            className={styles.uploadCta}
                            onClick={() => toggleUploadDoc(doc?.code)}
                            disabled={
                              getStatus(doc?.code)?.docStatus === "Verified" ||
                              !isPrInitialized(purchaseStatus)
                            }
                          >
                            {getStatus(doc?.code)?.docStatus === "Not Uploaded"
                              ? "Upload"
                              : "Edit"}
                          </Button>
                        ),
                        itemStyle: { marginTop: "20px" },
                      },
                    ]}
                  />
                }
              />
            </GridLayoutItem>
          );
        });
      cardsList.push(cards);
    }
    return cardsList?.map((cards, index) => (
      <GridLayout
        key={index}
        className={index > 0 ? "k-mt-6" : ""}
        cols={[
          {
            width: "50%",
          },
          {
            width: "50%",
          },
        ]}
      >
        {cards?.map((card) => card)}
      </GridLayout>
    ));
  };
  const toggleUploadDoc = (code) => {
    if (docCode) {
      setDocCode("");
    } else {
      if (!accessToken) {
        getAuthTokenForUpload(code);
      } else {
        setDocCode(code);
      }
    }
  };
  const getAuthTokenForUpload = async (code) => {
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    const payload = {
      userEmail: getLoginUserEmail(),
      client: "c2b-internal-portal",
    };
    DmsService.authenticateUser(payload)
      .then((res) => {
        if (res && res.status === 200) {
          setDocCode(code);
          // setAuthData(res.data && res.data.data);
          dispatch({
            type: PR_AUTOMATION.FETCH_UPLOAD_AUTH_SUCCESS,
            payload: {
              data: res.data.data,
            },
          });
        }
      })
      .catch((err) => {
        showToastMessage(err?.message || "Error", false);
        dispatch({ type: PR_AUTOMATION.FETCH_UPLOAD_AUTH_FAILURE });
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
  return (
    <>
      <ExpansionPanel
        title={titleBar("KYC Docs")}
        className="k-mt-4 k-rounded-lg"
        expanded={showKycDocs}
        tabIndex={0}
        onAction={() => setShowKycDocs(!showKycDocs)}
      >
        {showKycDocs && (
          <ExpansionPanelContent>{renderComponents()}</ExpansionPanelContent>
        )}
      </ExpansionPanel>
    </>
  );
};

export default KycDocs;
