import { OPS_VERIFIACTION } from "../types/ops-verfication";
import { VerficationService } from "../../services";
export const fetchVerificationsList =
	(params = {}, isSearch = false) =>
	(dispatch) => {
		dispatch({ type: OPS_VERIFIACTION.FETCH_LOADING_START });
		VerficationService.fetchVerifications(params, isSearch)
			.then((resp) => {
				const { content, numberOfElements, totalElements } = resp || {};
				dispatch({
					type: OPS_VERIFIACTION.FETCH_VERIFICATION_LIST_SUCCESS,
					payload: {
						data: content,
						numberOfElements: numberOfElements,
						totalElements: totalElements,
					},
				});
			})
			.catch((err) => {
				dispatch({
					type: OPS_VERIFIACTION.FETCH_VERIFICATION_LIST_FAILURE,
					payload: err,
				});
			})
			.finally(() => {
				dispatch({ type: OPS_VERIFIACTION.FETCH_LOADING_END });
			});
	};

export const updateAssignmentTypeParams =
	(params = {}) =>
	(dispatch) => {
		dispatch({
			type: OPS_VERIFIACTION.SET_ASSIGNMENT_TYPE,
			payload: params,
		});
	};

export const updateVerificationAssignmentTypeParams =
	(params = {}) =>
	(dispatch) => {
		dispatch({
			type: OPS_VERIFIACTION.SET_ASSIGNMENT_TYPE,
			payload: params,
		});
	};
export const setSearchQuery =
	(params = {}) =>
	(dispatch) => {
		dispatch({
			type: OPS_VERIFIACTION.SET_SEARCH_QUERY,
			payload: params,
		});
	};

export const setVerificationSearchQuery =
	(params = {}) =>
	(dispatch) => {
		dispatch({
			type: OPS_VERIFIACTION.SET_SEARCH_QUERY,
			payload: params,
		});
	};
export const updateVerificationFiltersParams =
	(params = {}) =>
	(dispatch) => {
		dispatch({
			type: OPS_VERIFIACTION.SET_VERIFICATION_FILTERS,
			payload: params,
		});
	};

export const updateVerificationItemRow =
	(params = {}) =>
	(dispatch) => {
		dispatch({
			type: OPS_VERIFIACTION.UPDATE_VERIFICATION_ROW_ITEM,
			payload: params,
		});
	};
