import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  payment_column_keys,
  paymentColumns,
  payment_status_data,
  getDisplayedCoulmns,
  issue_status_data,
  StatusCell,
  LeadIdLinkCell,
  lead_status_data,
} from "./viewModels";
import CustomGrid from "../../../common/custom-grid/component";
import { INIT_PAGE_PARAMS } from "../../../utils/constants/values.constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchPayouts } from "../../../store/actions/dealer-payouts";
import PaymentsFilters from "../components/payment-filters";
import {
  DropdownFilter,
  TextboxFilter,
} from "../../../components/custom-grid-filters";
import { convertObjectToParams } from "../../../utils/utils";

const PaymentView = () => {
  const dispatch = useDispatch();
  const {
    data = [],
    totalCount = 0,
    leadType = "",
  } = useSelector((state) => state.dealerPayouts) || {};
  const removeColumn = (paymentColumns) => {
    const { leadType, ...restColumns } = paymentColumns;
    return restColumns;
  };
  const [paginationParams, setPaginationParams] = useState(INIT_PAGE_PARAMS);
  const [filterParams, setFilterParams] = useState({});
  const [gridfilters, setGridfilters] = React.useState([]);
  const gridfiltersRef = React.useRef(gridfilters);
  const [visibleColumns, setVisibleColumns] = useState(paymentColumns);
  const [isDataExport, setIsDataExport] = useState(false);

  useEffect(() => {
    const columns =
      leadType === "" ? paymentColumns : removeColumn(paymentColumns);
    setVisibleColumns({
      ...columns,
      [payment_column_keys.LEAD_ID]: {
        ...columns[payment_column_keys.LEAD_ID],
        cell: LeadIdLinkCell,
        filterCell: (props) => (
          <TextboxFilter
            {...props}
            onClearClick={handleFilterButtonClick}
            handleFilterButtonClick={handleFilterButtonClick}
          />
        ),
      },
      [payment_column_keys.APP_ID]: {
        ...columns[payment_column_keys.APP_ID],
        filterCell: (props) => (
          <TextboxFilter
            {...props}
            onClearClick={handleFilterButtonClick}
            handleFilterButtonClick={handleFilterButtonClick}
          />
        ),
      },
      [payment_column_keys.DEALER_CODE]: {
        ...columns[payment_column_keys.DEALER_CODE],
        filterCell: (props) => (
          <TextboxFilter
            {...props}
            onClearClick={handleFilterButtonClick}
            handleFilterButtonClick={handleFilterButtonClick}
          />
        ),
      },
      [payment_column_keys.REGISTRATION_NUMBER]: {
        ...columns[payment_column_keys.REGISTRATION_NUMBER],
        filterCell: (props) => (
          <TextboxFilter
            {...props}
            onClearClick={handleFilterButtonClick}
            handleFilterButtonClick={handleFilterButtonClick}
          />
        ),
      },
      [payment_column_keys.LEAD_STATUS]: {
        ...columns[payment_column_keys.LEAD_STATUS],
        cell: (props) => (
          <StatusCell
            {...props}
            data={lead_status_data}
            status={payment_column_keys.LEAD_STATUS}
          />
        ),
        filterCell: (props) => (
          <DropdownFilter
            {...props}
            data={lead_status_data}
            onFilterButtonClick={handleFilterButtonClick}
          />
        ),
      },
      [payment_column_keys.PAYMENT_STATUS]: {
        ...columns[payment_column_keys.PAYMENT_STATUS],
        cell: (props) => (
          <StatusCell
            {...props}
            data={payment_status_data}
            status={payment_column_keys.PAYMENT_STATUS}
          />
        ),
        filterCell: (props) => (
          <DropdownFilter
            {...props}
            data={payment_status_data}
            onFilterButtonClick={handleFilterButtonClick}
          />
        ),
      },
      [payment_column_keys.ISSUE_STATUS]: {
        ...columns[payment_column_keys.ISSUE_STATUS],
        cell: (props) => (
          <StatusCell
            {...props}
            data={issue_status_data}
            status={payment_column_keys.ISSUE_STATUS}
          />
        ),
        filterCell: (props) => (
          <DropdownFilter
            {...props}
            data={issue_status_data}
            onFilterButtonClick={handleFilterButtonClick}
          />
        ),
      },
    });
  }, [paymentColumns, leadType]);

  useEffect(() => {
    fetchDealerPayouts();
  }, [filterParams, paginationParams, leadType]);

  const pageChange = ({ page = 0, size = 10 }) => {
    setPaginationParams({ page, size });
  };

  const handleFilterButtonClick = () => {
    const filters = gridfiltersRef?.current?.filters;
    let params = {};
    if (filters && !!filters.length) {
      filters?.forEach((element) => {
        params = { ...params, [element.field]: element.value };
      });
    }
    setPaginationParams(INIT_PAGE_PARAMS);
    setFilterParams(params);
  };
  const updateFilterValues = (e) => {
    gridfiltersRef.current = e?.filter;
    setGridfilters(e?.filter);
  };

  const fetchDealerPayouts = () => {
    const params = { ...paginationParams, ...filterParams, leadType };
    if (!leadType) {
      delete params.leadType;
    }
    const qsParams = convertObjectToParams(params);
    dispatch(fetchPayouts(qsParams));
  };

  const onExportDataEnd = () => {
    setIsDataExport(false);
  };
  return (
    <div className="middleContent">
      <PaymentsFilters
        setPaginationParams={setPaginationParams}
        paginationParams={paginationParams}
      />
      <CustomGrid
        displayedColumns={getDisplayedCoulmns(visibleColumns) || []}
        data={data || []}
        count={totalCount}
        columnMap={visibleColumns || {}}
        isPagination={true}
        pageChange={pageChange}
        paginationParams={{
          pageNumber: paginationParams?.page,
          pageSize: paginationParams?.size,
        }}
        filterable={true}
        filter={gridfilters}
        onFilterChange={updateFilterValues}
        pageSizes={[10, 20, 25, 50]}
        isDataExport={isDataExport}
        onExportDataEnd={onExportDataEnd}
      />
    </div>
  );
};

export default PaymentView;
