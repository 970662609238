import { FORM_ELEMENT_TYPE } from "../../../../../utils/constants/values.constants";
import { PRICE_RANGES } from "../custom-pricing-list/viewModal";

export const COHORT_PRICE_STATUS_DATA = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" }
]

export const PRICE_COHORT_KEYS = {
    TITLE: "configName",
    STATE: "stateName",
    CITY: "cityName",
    RANGE: "range",
    CONTROL_PRICE: "controlPrice",
    VARIANT_PRICE: "variantAPrice",
    VARIANT_TWO_PRICE: "variantBPrice",
    STATUS: "status",
};

export const PRICE_COHORT_FORM_DATA = {
    [PRICE_COHORT_KEYS.TITLE]: {
        field: PRICE_COHORT_KEYS.TITLE,
        label: "Config Name",
        placeholder: 'Enter Config Name',
        elementType: FORM_ELEMENT_TYPE.INPUT,
    },
    [PRICE_COHORT_KEYS.STATE]: {
        field: PRICE_COHORT_KEYS.STATE,
        label: "State",
        placeholder: 'Select State',
        elementType: FORM_ELEMENT_TYPE.SELECT,
    },
    [PRICE_COHORT_KEYS.CITY]: {
        field: PRICE_COHORT_KEYS.CITY,
        label: "City",
        placeholder: 'Select City',
        elementType: FORM_ELEMENT_TYPE.SELECT,
    },
    [PRICE_COHORT_KEYS.RANGE]: {
        field: PRICE_COHORT_KEYS.RANGE,
        label: "Car Price Range",
        placeholder: 'Select Price Range',
        elementType: FORM_ELEMENT_TYPE.MULTI_SELECT,
        data: PRICE_RANGES
    },
    [PRICE_COHORT_KEYS.CONTROL_PRICE]: {
        field: PRICE_COHORT_KEYS.CONTROL_PRICE,
        label: "Control Price",
        placeholder: 'Set Control Price',
        elementType: FORM_ELEMENT_TYPE.NUMERIC_TEXTBOX,
    },
    [PRICE_COHORT_KEYS.VARIANT_PRICE]: {
        field: PRICE_COHORT_KEYS.VARIANT_PRICE,
        label: "Variant Price",
        placeholder: 'Set Variant Price',
        elementType: FORM_ELEMENT_TYPE.NUMERIC_TEXTBOX,
    },
    [PRICE_COHORT_KEYS.VARIANT_TWO_PRICE]: {
        field: PRICE_COHORT_KEYS.VARIANT_TWO_PRICE,
        label: "Variant Two Price",
        placeholder: 'Set Variant Two Price',
        elementType: FORM_ELEMENT_TYPE.NUMERIC_TEXTBOX,
    },
    [PRICE_COHORT_KEYS.STATUS]: {
        field: PRICE_COHORT_KEYS.STATUS,
        label: "Status",
        value: COHORT_PRICE_STATUS_DATA[0],
        placeholder: 'Select Status',
        elementType: FORM_ELEMENT_TYPE.SELECT,
        data: COHORT_PRICE_STATUS_DATA
    },
}