import { Button } from "@progress/kendo-react-buttons";
const PaymentActions = (props) => {
  return (
    <>
      <div className="detail-page-actions">
        <div className="k-form-buttons">
          {!!props.isBackRequired && (
            <Button
              className="m-2"
              themeColor={"primary"}
              fillMode="outline"
              onClick={props.onBack}
            >
              Back
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
export default PaymentActions;
