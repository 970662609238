import { Button } from "@progress/kendo-react-buttons";
import { KendoCard } from "../../../../../../components";
import { getBadge } from "../../pr-automation/viewModel";
import { getPrStatus } from "../../../negotiation/pr-automation/viewModel";
import { useSelector } from "react-redux";
import { getItemCreatedBy } from "../get-item-created-by";
import { formatDateTime } from "../../../../../../utils/utils";
import { getAdminPortalUrl } from "../../../../../../utils/constants/url.constants";

const LatestPr = (props) => {
	const { isViewAllPr, setIsViewAllPr, toggleApprovals } = props || {};
	const prDetails = useSelector((state) => state.prAutomation);
	const { orderData: { orderId,purchase: { purchaseStatus, createdDate, purchaseRequestId } = {} } = {} } =
		prDetails || {};
	const purchaseRequestTitle = () => {
    const handleCreatePrClick = () => {
      window.open(getAdminPortalUrl() + `orders/order/view/${orderId}`, '_blank');
    };  

		return (
			<div className="justify-between">
				<div className="k-font-medium">Latest purchase request</div>
        <div>
          {
            [null,'DECLINED','CANCELLED'].includes(purchaseStatus) && 
            <Button
              themeColor="primary"
              onClick={handleCreatePrClick}
              size={"small"}
              fillMode={"flat"}
            >
              Create PR
            </Button>
          }
          <Button
            themeColor="primary"
            onClick={() => setIsViewAllPr(!isViewAllPr)}
            size={"small"}
            fillMode={"flat"}
            icon={isViewAllPr ? "close" : ""}>
            {!isViewAllPr ? "View all" : ""}
          </Button>
        </div>
			</div>
		);
	};
	const renderSubTitle = () => {
		return (
			<div>
				<span className="k-font-normal">Created by: </span>
				<span className="k-font-medium">
					{getItemCreatedBy(prDetails?.orderData?.purchase)}
				</span>
			</div>
		);
	};

  const ViewPrClick = () => {
    window.open(getAdminPortalUrl() + `orders/purchase-request/view/${purchaseRequestId}`, '_blank');
  };

	return (
		<KendoCard
			headerTitle={purchaseRequestTitle()}
			headerStyle={{ paddingBottom: "8px", paddingTop: "8px" }}
			details={
				<>
          {
            purchaseStatus != null 
            ?
              <KendoCard
                headerTitle={
                  <>
                    <span className="k-font-medium k-mr-2">
                      {createdDate && formatDateTime(createdDate)}
                    </span>
                    <span>
                      {getBadge(
                        getPrStatus(purchaseStatus).text,
                        getPrStatus(purchaseStatus).color
                      )}
                    </span>
                    {
                      ![null,'DECLINED','CANCELLED'].includes(purchaseStatus) && 
                      <Button
                        themeColor="primary"
                        size={"small"}
                        fillMode={"flat"}
                        onClick={ViewPrClick}
                      >
                        View PR
                      </Button>  
                    }
                  </>
                }
                headerStyle={{
                  fontWeight: "500",
                  fontSize: "16px",
                  border: 0,
                }}
                headerSubtitle={renderSubTitle()}
                cardStyle={{
                  background: "#F0F2F5",
                  marginTop: "0px",
                }}
              />
            :
              <p>No data found</p>
          }
				</>
			}
		/>
	);
};

export default LatestPr;
