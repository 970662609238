function CallIcon(props) {
  return (
    <svg
      width={12}
      height={13}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.3 12.5c-1.389 0-2.761-.303-4.117-.908-1.355-.606-2.589-1.464-3.7-2.575-1.11-1.111-1.97-2.345-2.575-3.7C.303 3.96 0 2.589 0 1.2 0 1 .067.833.2.7S.5.5.7.5h2.7a.62.62 0 01.417.158c.122.106.194.23.216.375l.434 2.334c.022.177.016.327-.017.45a.701.701 0 01-.183.316L2.65 5.767c.222.41.486.808.792 1.191.305.384.641.753 1.008 1.109.344.344.706.664 1.083.958.378.294.778.564 1.2.808L8.3 8.267c.1-.1.23-.175.392-.225.16-.05.32-.064.475-.042l2.3.467a.772.772 0 01.383.241c.1.117.15.248.15.392v2.7c0 .2-.067.367-.2.5s-.3.2-.5.2zm-9.283-8l1.1-1.1-.284-1.567H1.35c.056.456.133.906.233 1.35.1.445.245.884.434 1.317zm5.966 5.967a8.274 8.274 0 002.684.666V9.667L9.1 9.35l-1.117 1.117z"
        fill={props.fill || '#fff'}
      />
    </svg>
  )
}

export default CallIcon
