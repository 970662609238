import React, { useState } from 'react'
import { UploadFile } from '../../../../../../components';
import FileView from '../../../../../../common/file-view';
import { showToastMessage } from '../../../../../../utils/utils';
import { ChallanService } from '../../../../../../services';
import { FILE_VIEW_ACTIONS } from '../../../../../../utils/constants/values.constants';


const UploadMultipleFile = (props) => {

    const {challanRequestDetail,challanFinderProofList, setChallanFinderProofList} = props || {};

    const handleDeleteResponse = (message,deletedFile) => {
        setChallanFinderProofList(prevFiles => prevFiles.filter(file => file !== deletedFile));
        showToastMessage(message);
    }

    const handleFileView = (file, type) => {
		if (
			!!challanRequestDetail?.idChallanRequest &&
			!!file
		) {
			ChallanService.fetchUploadProofMultipleFileLink(
				challanRequestDetail?.idChallanRequest,
                encodeURIComponent(file),
                type
			)
				.then((resp) => {
					type === FILE_VIEW_ACTIONS.DELETE ? handleDeleteResponse(resp?.message,file) : resp?.detail && window.open(resp?.detail, "_blank", "noopener");
				})
				.catch((err) => {
					console.log(err);
					showToastMessage(err?.message || err?.error, false);
				});
		}
	};

    const onInputChange = (e) => {

        const selectedFile = e.target.files[0];
        const totalFiles = challanFinderProofList?.length;
        
        if (totalFiles < 5) {
            const formData = new FormData();
            formData.append('files', selectedFile);

            ChallanService.finderUpload(challanRequestDetail?.idChallanRequest, formData).then((res) => {
                showToastMessage(res?.message, true);
                setChallanFinderProofList((prevFiles) => ([
                    ...prevFiles,
                    res?.detail,
                ]))
            }).catch((err) => showToastMessage(err?.message, false));

        }
        else {
            showToastMessage('Maximum number of file uploads reached', false);
        }
    }
  return (
    <div>
						<UploadFile
							onChange={onInputChange}
						/>
                        {challanFinderProofList?.map((item) => (
                            <div style={{marginBottom:"10px"}}>                            
                                <FileView handleFileView={() => handleFileView(item)} handleFileDelete = {() => handleFileView(item,FILE_VIEW_ACTIONS.DELETE)} isDelete={true}/>
                            </div>
                        ))}
					</div>
  )
}

export default UploadMultipleFile;