import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import React, { useEffect, useRef, useState } from 'react';
import styles from "./style.module.css";
import { commonApis } from '../../../../../../../services/commonApi';
import useLazyQuery from '../../../../../../../hooks/useLazyQuery';
import useQuery from '../../../../../../../hooks/useQuery';
import { numberFormat, showToastMessage } from '../../../../../../../utils/utils';
import { Loader } from '@progress/kendo-react-indicators';
import { fetchC24QuoteDetails } from '../../../../../../../store/actions/pr-automation';
import { useDispatch } from 'react-redux';

const SLIDER_STEP = 1000
const MAX=99999999
const MIN=0

function RaiseOCBPopup({
  appointmentId,
  setIsRaiseOCBModalOpen,
  auctionId,
  setRelaod
}) {

  const [value, setValue] = useState(50);
  const dispatch = useDispatch()
  const amountRef = useRef(0)
  const holdAmountRef = useRef(0)
  const chargesAmountRef = useRef(0)
  const [errorInTextBox,setErrorInTextBox] = useState(false)
  const { data: ocbSliderData, isLoading: isOcbSliderDataLoading } = useQuery(commonApis.getOCBSliderData, { appointmentId: appointmentId });
  const { apiCbFunction: raiseOCB, isLoading: isRaiseOcbDataLoading } = useLazyQuery(commonApis.raiseOCB);

  const handleNext = () => {
    if(ocbSliderData?.max != 0 && (value == ocbSliderData?.max??MAX)) {
      showToastMessage('OCB Maximum Value Reached',false)
      return
    }
    setValue((prevValue) => Math.min(prevValue + SLIDER_STEP, (ocbSliderData?.max||MAX)));
  };

  const handlePreview = () => {
    if(value == (ocbSliderData?.min??MIN)) {
      showToastMessage('OCB Minimum Value Reached',false)
      return
    }
    setValue((prevValue) => Math.max(prevValue - SLIDER_STEP, (ocbSliderData?.min||MIN)));
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const numberValue = parseFloat(value);
    if (value ===  '') {
      setValue('')
      setErrorInTextBox(`Input cannot be empty`)
    }
    else if(value%SLIDER_STEP !=0){
      setErrorInTextBox(`Input can only be multiple of ${SLIDER_STEP}`)
    }
    else if (value < (ocbSliderData?.min??MIN)) {
      setErrorInTextBox(`Input cannot be less than ${ocbSliderData?.min}`)
    } else if (ocbSliderData?.max != 0 && value > (ocbSliderData?.max??MAX)) {
      setErrorInTextBox(`Input cannot be greater than ${ocbSliderData?.max}`)
    }
    else{
      setErrorInTextBox(false)
    }
    if (!isNaN(numberValue)) {
      setValue(numberValue)
    }
  };

  const handleChange = (event) => {
    setValue(Number(event.target.value));
  };

  useEffect(()=>{
    if(ocbSliderData)
      setValue(ocbSliderData?.optimal)
  },[ocbSliderData])


  const submitHandler = async() => {
    if(errorInTextBox){
      showToastMessage(errorInTextBox,false)
      return
    }
    if(Number(value) == 0){
      showToastMessage('Amount cannot be zero',false)
      return
    }
    if(!isRaiseOcbDataLoading){
      holdAmountRef.current = 0
      chargesAmountRef.current = 0
      amountRef.current = Number(value)
      const res = await raiseOCB({
        body:{
          appointmentId: appointmentId,
          auctionId: auctionId,
          raisedOcbPrice: value
        }
      })
      if(res.status == 200){
        showToastMessage('OCB Raised Successfully',true)
        dispatch(fetchC24QuoteDetails({ appointmentId:appointmentId}));
        setRelaod(Date.now())
      }
      else{
        showToastMessage('Some error occured',false)
      }
    }
  }

  return (
    <Dialog
      className={styles.price_modal}
      width={'410px'}
    >
      <div className={styles['head']}>
        {
          isOcbSliderDataLoading?
            <div className={styles['loader']}>
              <Loader/>
            </div>
          :
            <>
              <div className={styles['head_userAction']}>
                <div className={styles['head_userAction--heading']}>Raise OCB</div>
                <div className={styles['head_userAction--subheading']}>For #{appointmentId}</div>
                <div className={styles['head_userActionInput']}>
                  <div className={styles['head_userActionInput--label']}>Enter Amount</div>
                  <div>
                    <button className={styles['head_userActionInput--negativebutton']} onClick={handlePreview}>-</button>
                    <input
                      type='text'
                      className={styles['head_userActionInput--input']}
                      value={value}
                      onChange={handleInputChange}
                    />
                    <button className={styles['head_userActionInput--positivebutton']} onClick={handleNext}>+</button>
                  </div>
                </div>
                {
                  errorInTextBox &&
                  <div> 
                    {errorInTextBox}
                  </div>
                }
              </div>
              {
                ocbSliderData?.flag && 
                <div className={styles.mainBox}>
                  <input
                    id={styles.stepsall}
                    type="range"
                    min={ocbSliderData?.min??MIN}
                    max={ocbSliderData?.max??MAX}
                    step={SLIDER_STEP}
                    value={value}
                    onChange={handleChange}
                  />
                </div>
              }
              {
                ocbSliderData?.min && ocbSliderData?.max && 
                <div className={styles['steps_labels']}>
                  <span className={styles['steps_labels--min']}>{numberFormat(ocbSliderData?.min??MIN)}</span>
                  <span className={styles['steps_labels--max']}>{numberFormat(ocbSliderData?.max||MAX)}</span>
                </div>
              }
              <div className={styles['head_userButtons']}>
                <button className={styles['head_userButtons--submit']} onClick={submitHandler}>
                  {isRaiseOcbDataLoading?"Loading":"Submit"}
                </button>
                <button className={styles['head_userButtons--cancel']} onClick={() => { setIsRaiseOCBModalOpen(false) }}>Cancel</button>
              </div>
            </>
        }
      </div>
    </Dialog>
  )
}

export default RaiseOCBPopup
