import { getFFUserType } from "../utils/utils";

export default (api) => {

    const fetchCjInspectionList = (params) => {
        return new Promise((resolve, reject) => {
            api
                .get(`/ops/fetch/cj/list${params}`, {
                    headers: { "user-type": `${getFFUserType()}` },
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const verifyCjList = (payload) => {
        return new Promise((resolve, reject) => {
            const request = api.post;

            request(`/ops/verify/cj`, payload, {
                headers: { "user-type": `${getFFUserType()}` },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    return {
        fetchCjInspectionList,
        verifyCjList,
    };
};
