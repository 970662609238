import { convertObjectToParams } from "../utils/utils";

export default (api) => {
  const fetchC24Quote = (params = "") => {
    const url = "c24-quote/v1/quote";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}${convertObjectToParams(params)}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  return {
    fetchC24Quote,
  };
};
