import { FORM_ELEMENT_TYPE } from "../../../utils/constants/values.constants";

export const payment_detail_column_keys = {
  LEAD_ID: "leadId",
  DEALER_ID: "dealerId",
  APP_ID: "appointmentId",
  DEALER_CODE: "dealerCode",
  FLAG_COMMENT: "remarks",
};
export const transaction_column_keys = {
  TXN_ID: "txnId",
  BANK_ACC: "accountNumber",
  BANK_NAME: "bankName",
  UTR: "utr",
  STATUS: "status",
  IFSC_CODE: "ifsc",
  AMOUNT: "amount",
  BENEFICIARY_NAME: "name",
  REMARKS: "remarks",
  DATE: "date",
};

export const grievance_column_keys = {
  DATE: "date",
  PAID_AMOUNT: "amount",
  TOTAL_PAID_AMOUNT: "amountPaidSoFar",
  APPROVED_BY: "approverEmail",
  PAID_BY: "payUserEmail",
  ELAPSED_TIME: "timeTaken",
  STATUS: "status",
  REASON: "flagScenario",
  DEALER_COMMENT: "dealerComment",
  ADMIN_COMMENT: "adminComment",
  CUSTOM_AMOUNT: "customAmount",
};
export const DealerDetailsFormData = {
  [payment_detail_column_keys.DEALER_ID]: {
    name: payment_detail_column_keys.DEALER_ID,
    label: "Dealer Id",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    disabled: true,
    value: "",
  },
  [payment_detail_column_keys.DEALER_CODE]: {
    name: payment_detail_column_keys.DEALER_CODE,
    label: "Dealer Code",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    disabled: true,
    value: "",
  },
};
export const TransactionLogColumns = {
  [transaction_column_keys.TXN_ID]: {
    field: transaction_column_keys.TXN_ID,
    label: "TRANSACTION ID",
    width: "150px",
  },
  [transaction_column_keys.BENEFICIARY_NAME]: {
    field: "beneficiaryDetails." + [transaction_column_keys.BENEFICIARY_NAME],
    label: "BENEFICIARY NAME",
    width: "150px",
  },
  [transaction_column_keys.BANK_NAME]: {
    field: "beneficiaryDetails." + transaction_column_keys.BANK_NAME,
    label: "BANK NAME",
    width: "150px",
  },
  [transaction_column_keys.BANK_ACC]: {
    field: "beneficiaryDetails." + transaction_column_keys.BANK_ACC,
    label: "ACCOUNT NUMBER",
    width: "150px",
  },
  [transaction_column_keys.IFSC_CODE]: {
    field: "beneficiaryDetails." + transaction_column_keys.IFSC_CODE,
    label: "IFSC CODE",
    width: "150px",
  },
  [transaction_column_keys.AMOUNT]: {
    field: transaction_column_keys.AMOUNT,
    label: "AMOUNT",
    width: "150px",
  },
  [transaction_column_keys.STATUS]: {
    field: transaction_column_keys.STATUS,
    label: "STATUS",
    width: "150px",
  },
  [transaction_column_keys.UTR]: {
    field: transaction_column_keys.UTR,
    label: "UTR",
    width: "150px",
  },
  [transaction_column_keys.REMARKS]: {
    field: transaction_column_keys.REMARKS,
    label: "REMARKS",
    width: "150px",
  },
  [transaction_column_keys.DATE]: {
    field: transaction_column_keys.DATE,
    label: "DATE",
    width: "150px",
  },
};

export const GrievanceLogColumns = {
  [grievance_column_keys.DATE]: {
    field: grievance_column_keys.DATE,
    label: "DATE",
    width: "150px",
  },
  [grievance_column_keys.PAID_AMOUNT]: {
    field: grievance_column_keys.PAID_AMOUNT,
    label: "PAID AMOUNT",
    width: "150px",
  },
  [grievance_column_keys.TOTAL_PAID_AMOUNT]: {
    field: grievance_column_keys.TOTAL_PAID_AMOUNT,
    label: "TOTAL PAID AMOUNT",
    width: "150px",
  },
  [grievance_column_keys.APPROVED_BY]: {
    field: grievance_column_keys.APPROVED_BY,
    label: "APPROVER EMAIL",
    width: "200px",
  },
  [grievance_column_keys.PAID_BY]: {
    field: grievance_column_keys.PAID_BY,
    label: "PAY USER EMAIL",
    width: "200px",
  },
  [grievance_column_keys.ELAPSED_TIME]: {
    field: grievance_column_keys.ELAPSED_TIME,
    label: "TIME TAKEN",
    width: "150px",
  },
  [grievance_column_keys.STATUS]: {
    field: grievance_column_keys.STATUS,
    label: "PAYOUT STATE",
    width: "150px",
  },
  [grievance_column_keys.REASON]: {
    field: grievance_column_keys.REASON,
    label: "FLAG SCENARIO",
    width: "150px",
  },
  [grievance_column_keys.DEALER_COMMENT]: {
    field: grievance_column_keys.DEALER_COMMENT,
    label: "DEALER COMMENT",
    width: "150px",
  },
  [grievance_column_keys.ADMIN_COMMENT]: {
    field: grievance_column_keys.ADMIN_COMMENT,
    label: "ADMIN COMMENT",
    width: "150px",
  },
};
export const FlagSectionFormData = {
  [grievance_column_keys.REASON]: {
    name: grievance_column_keys.REASON,
    label: "Flag Scenario",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    disabled: true,
    value: "",
  },
  [grievance_column_keys.ELAPSED_TIME]: {
    name: grievance_column_keys.ELAPSED_TIME,
    label: "Elapsed Time",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    disabled: true,
    value: "",
  },
  [grievance_column_keys.DEALER_COMMENT]: {
    name: grievance_column_keys.DEALER_COMMENT,
    label: "Dealer Comments",
    elementtype: FORM_ELEMENT_TYPE.TEXTAREA,
    disabled: true,
    value: "",
  },
  [grievance_column_keys.ADMIN_COMMENT]: {
    name: grievance_column_keys.ADMIN_COMMENT,
    label: "Admin Comments *",
    elementtype: FORM_ELEMENT_TYPE.TEXTAREA,
    disabled: false,
    value: "",
  },
  [grievance_column_keys.PAID_AMOUNT]: {
    name: grievance_column_keys.PAID_AMOUNT,
    label: "Approved Amount *",
    elementtype: FORM_ELEMENT_TYPE.NUMERIC_TEXTBOX,
    disabled: true,
    value: 0,
    type: "number",
    min: 0,
  },
  [grievance_column_keys.CUSTOM_AMOUNT]: {
    name: grievance_column_keys.CUSTOM_AMOUNT,
    label: "Custom Amount *",
    elementtype: FORM_ELEMENT_TYPE.NUMERIC_TEXTBOX,
    disabled: false,
    value: 0,
    type: "number",
    min: 0,
  },
};
