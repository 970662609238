function AlertPositiveCase(props) {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={25}
        height={24}
      >
        <path fill="#D9D9D9" d="M0.5 0H24.5V24H0.5z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M12.5 17.5c1.133 0 2.162-.32 3.088-.962A5.485 5.485 0 0017.6 14H7.4a5.485 5.485 0 002.012 2.538 5.294 5.294 0 003.088.962zM8.3 11l1.1-1.05L10.45 11l1.05-1.05L9.4 7.8 7.25 9.95 8.3 11zm6.25 0l1.05-1.05L16.7 11l1.05-1.05L15.6 7.8l-2.1 2.15L14.55 11zM12.5 22a9.738 9.738 0 01-3.9-.788 10.099 10.099 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 012.5 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 012.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0112.5 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 013.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0122.5 12a9.738 9.738 0 01-.788 3.9 10.098 10.098 0 01-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137a9.738 9.738 0 01-3.9.788zm0-2c2.233 0 4.125-.775 5.675-2.325 1.55-1.55 2.325-3.442 2.325-5.675 0-2.233-.775-4.125-2.325-5.675C16.625 4.775 14.733 4 12.5 4c-2.233 0-4.125.775-5.675 2.325C5.275 7.875 4.5 9.767 4.5 12c0 2.233.775 4.125 2.325 5.675C8.375 19.225 10.267 20 12.5 20z"
          fill="#00BA67"
        />
      </g>
    </svg>
  )
}

export default AlertPositiveCase
