import React, { useRef } from 'react';
import { Card, CardBody } from '@progress/kendo-react-layout';
import styles from './fileUpload.module.css';

export const FileUpload = React.forwardRef(({ onFileSelect, fileName, accept, style = null, isUrl = false }, ref) => {
    const inputFileRef = useRef(null);

    const handleFileSelect = (e) => {
        e.preventDefault();
        const selectedFile = e.target.files[0];
        onFileSelect(selectedFile);
    };

    const handleClick = (e) => {
        e.preventDefault();
        inputFileRef.current.click();
    };

    const clear = () => {
        if (inputFileRef.current) {
            inputFileRef.current.value = '';
        }
    };

    React.useImperativeHandle(ref, () => ({
        clear,
    }));

    return (
        <div>
            <Card
                style={{
                    ...style,
                    display: 'flex',
                    height: '100%',
                    background: '#F8F9FA',
                    borderStyle: 'none',
                    padding: '9px'
                }}
            >
                <CardBody className={styles.customUpload}>
                    <input
                        type="file"
                        className={styles.inputFile}
                        onChange={handleFileSelect}
                        accept={accept}
                        ref={inputFileRef}
                        style={{ display: 'none' }}
                    />
                    <button className={styles.customChooseFileButton} onClick={handleClick}>
                        {'Select File'}
                    </button>
                    <span className={`${styles.fileNameLabel} ${isUrl && styles.uploaded}`} onClick={() => {isUrl && window.open(fileName, '_blank');}}>
                        {(isUrl ? 'View Uploaded File' : fileName) || 'No File Chosen'}
                    </span>
                </CardBody>
            </Card>
        </div>
    );
});
