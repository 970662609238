import React from 'react'
import { thumborUrl } from '../../../../../../../utils/constants/url.constants'

function FileLoader(event) {
    return (
        <td>
            <a style={{ color: '#0D6EFD', textDecoration: 'underline' }} href={`${thumborUrl()}${event.dataItem.cvLocation}`} target="_blank">
                File URL
            </a>
        </td>
    )
}

export default FileLoader