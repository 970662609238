import { challan_item_keys } from "../challan-detail-revamp/viewModel";
import { challan_request_keys } from "../../challan-list/viewModels";
import { verification_column_keys } from "../../verification-container/viewModal";

export const serviceHistorySearchOptions = [
	{
		label: "Reg Number",
		value: challan_item_keys.REG_NUMBER,
	},
];

export const disposerSearchOptions = [
	{
		label: "Reg Number",
		value: challan_item_keys.REG_NUMBER,
	},
	{
		label: "Notice Number",
		value: challan_item_keys.NOTICE_NUMBER,
	},
];
export const finderSearchOptions = [
	{
		label: "Reg Number",
		value: challan_request_keys.REG_NUMBER,
	},
	{
		label: "Appointment Id",
		value: challan_request_keys.APPOINTMENT_ORDER_ID,
	},
];
export const viewDisposerSearchOptions = [
	{
		label: "Reg Number",
		value: challan_item_keys.REG_NUMBER,
	},
	{
		label: "Notice Number",
		value: challan_item_keys.NOTICE_NUMBER,
	},
	{
		label: "Appointment Id",
		value: challan_request_keys.APPOINTMENT_ORDER_ID,
	},
];

export const verificationSearchOptions = [
	{
		label: "Reg Number",
		value: verification_column_keys.REG_NUMBER,
	},
];
