import dayjs from "dayjs";
import { SFService } from "../../../../services";
import {
    convertObjectToParams,
    getArrayItemValueByLabel,
} from "../../../../utils/utils";
import {
    ASSIGNED_LEAD_COLS,
    ASSIGNED_LEAD_DEFAULT_PAGE_SIZE,
    LEADS_TYPE,
    UNASSIGNGED_LEADS_COLS,
    ASSIGNED_LEAD_KEY,
    All_LEAD_KEY,
    ADMIN_ASSIGNED_LEAD_COLS,
} from "../viewModel";
import classes from "../styles/leadsListing.module.css";
import { ASSIGNED_LEAD_SUBTABS } from "../../common/constants";

export const fetchLeads = (
    paginationParams,
    filterParams,
    selectedAgent,
    leadsFilters,
    setLeadData,
    setTotalLeadCount,
    setIsLoading
) => {
    const { pageNumber, pageSize } = paginationParams;
    let updatedPaginationParams = { page: pageNumber, size: pageSize };
    let params = {
        ...updatedPaginationParams,
        ...(selectedAgent ? { agentId: selectedAgent } : {}),
        ...(leadsFilters && leadsFilters.type && leadsFilters.value
            ? { [leadsFilters.type]: leadsFilters.value }
            : {}),
    };
    if (filterParams) {
        params = { ...params, ...filterParams };
    }
    params = convertObjectToParams(params);
    setIsLoading(true);
    SFService.fetchAllLeads(params)
        .then((resp) => {
            setLeadData(resp.detail.response);
            setTotalLeadCount(resp.detail.totalRecords);
        })
        .catch((err) => {
            setLeadData(null);
            console.log("Error Fetching Lead List", err);
        })
        .finally(() => setIsLoading(false));
};

export const assignLeadToAgent = async (leadId, queryParam) => {
    try {
        const params = convertObjectToParams(queryParam);
        return await SFService.assignAgentLead(leadId, params);
    } catch (ex) {
        console.log("Error assigning the agent", ex);
    }
};

export const getDisplayedColumns = (tab, subTabKey = "") => {
    let columns;

    switch (tab) {
        case LEADS_TYPE.UNASSIGNED:
            columns = UNASSIGNGED_LEADS_COLS;
            break;
        case LEADS_TYPE.ASSIGNED:
            columns = ASSIGNED_LEAD_COLS(subTabKey);
            break;
        case LEADS_TYPE.ADMIN_ASSIGNED:
            columns = ADMIN_ASSIGNED_LEAD_COLS(subTabKey);
            break;
        case LEADS_TYPE.ADMIN_UNASSIGNED:
            columns = getAdminUnassignedLeadsCols();
            break;
        default:
            columns = {};
    }

    const displayColumns = Object.values(columns).filter((item) => !item.hide);
    return displayColumns.map((item) => item.field);
};

export const filterGridHelper = (
    key,
    e,
    data,
    filterParams,
    setGridFilters,
    setPaginationParams
) => {
    let _filterParamsData = { ...filterParams };
    delete _filterParamsData[key];
    if (!!e) {
        setGridFilters(e);
        setPaginationParams({
            pageNumber: 0,
            pageSize: ASSIGNED_LEAD_DEFAULT_PAGE_SIZE,
        });
        e?.filters.forEach((item) => {
            const updatedFilter = item?.filters.reduce((acc, cur) => {
                const existingItem = acc?.find(
                    (transformedItem) => transformedItem[cur.field]
                );
                const itemValue = getArrayItemValueByLabel(
                    data[cur.field],
                    cur.value
                );
                if (existingItem) {
                    existingItem[cur.field] += `,${itemValue}`;
                } else {
                    acc.push({ [cur.field]: itemValue });
                }
                return acc;
            }, []);

            const filterParamsData = updatedFilter.reduce(
                (result, obj) => ({ ...result, ...obj }),
                {}
            );
            _filterParamsData = { ..._filterParamsData, ...filterParamsData };
        });
    }
    return _filterParamsData;
};

export const getAdminUnassignedLeadsCols = () => {
    const assignedLeadsCols = { ...ASSIGNED_LEAD_COLS("DIALER_LEADS") };
    delete assignedLeadsCols[ASSIGNED_LEAD_KEY.COPY_LINK];
    delete assignedLeadsCols[ASSIGNED_LEAD_KEY.INTERNAL_STATUS];
    delete assignedLeadsCols[ASSIGNED_LEAD_KEY.TIME_LEFT];
    return {
        ...assignedLeadsCols,
        [All_LEAD_KEY.ASSIGNMENT]: {
            field: All_LEAD_KEY.ASSIGNMENT,
            label: "Assignment",
        },
    };
};

export const getDifferenceBetweenTime = (to, from = new Date()) => {
    if (!(to && from && dayjs(from).isValid() && dayjs(to).isValid())) {
        return null;
    }

    const fromTime = dayjs(from);
    const toTime = dayjs(to);
    const mins = toTime.diff(fromTime, "minutes");
    const hours = toTime.diff(fromTime, "hours");

    return {
        mins:
            mins < 0
                ? (hours < 0 ? 1 : -1) * (Math.abs(mins) % 60)
                : dayjs().minute(mins).$m,
        hours: hours,
        totalMinutes: mins,
    };
};

export const mapDifferenceToColor = (mins, lastCallStatus) => {
    switch (lastCallStatus) {
        case "FRESH": {
            if (mins > -10) {
                return classes.bgGreen;
            } else if (mins <= -10 && mins > -20) {
                return classes.bgYellow;
            } else if (mins <= -20) {
                return classes.bgRed;
            } else return "";
        }
        default: {
            if (mins >= 0) {
                return classes.bgGreen;
            } else if (mins < 0 && mins > -20) {
                return classes.bgYellow;
            } else if (mins <= -20) {
                return classes.bgRed;
            } else return "";
        }
    }
};

export const debounce = (mainFunction, delay) => {
    let timer;

    return function (...args) {
        clearTimeout(timer);

        timer = setTimeout(() => {
            mainFunction(...args);
        }, delay);
    };
};

export const getTabFromState = (subTabKey) => {
    switch (subTabKey) {
        case ASSIGNED_LEAD_SUBTABS.DIALER_LEADS:
            return {
                tab: 0,
                subTab: 0,
            };
        case ASSIGNED_LEAD_SUBTABS.CONTACT_INTERESTED:
            return {
                tab: 0,
                subTab: 1,
            };
        case ASSIGNED_LEAD_SUBTABS.ALL_DOCS_COLLECTED:
            return {
                tab: 0,
                subTab: 2,
            };
        case ASSIGNED_LEAD_SUBTABS.CONTACT_NOT_INTERESTED:
            return {
                tab: 0,
                subTab: 3,
            };
        case "UNASSIGNED_LEADS":
            return {
                tab: 1,
                subTab: 0,
            };
        default:
            return {
                tab: 0,
                subTab: 0,
            };
    }
};

export const isNumber = (value) => {
    return typeof value === "number" && !isNaN(value);
};
