import { DEALER_PROCUREMENT } from "../types";
import { DealerProcureService } from "../../services";
import { showToastMessage } from "../../utils/utils";

export const fetchDealers =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_START });
    DealerProcureService.fetchDealers(params)
      .then((resp) => {
        dispatch({
          type: DEALER_PROCUREMENT.FETCH_DEALER_LIST_SUCCESS,
          payload: {
            data: resp.content,
            numberOfElements: resp.numberOfElements,
            totalElements: resp.totalElements,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: DEALER_PROCUREMENT.FETCH_DEALER_LIST_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_END });
      });
  };

export const fetchDealerCentre =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_START });
    DealerProcureService.fetchDealerCentre(params)
      .then((resp) => {
        dispatch({
          type: DEALER_PROCUREMENT.GET_DEALER_CENTER_SUCCESS,
          payload: {
            data: resp,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: DEALER_PROCUREMENT.GET_DEALER_CENTER_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_END });
      });
  };

export const updateDealer =
  (params = {}, payload = {}) =>
  (dispatch) => {
    dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_START });
    DealerProcureService.updateDealer(params, payload)
      .then(() => {
        showToastMessage("Dealer Updated", true);
      })
      .catch(() => {
        showToastMessage("Error", false);
      })
      .finally(() => {
        dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_END });
      });
  };
