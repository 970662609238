import { InspectionReferralService } from "../../../../../services";
import { convertObjectToParams } from "../../../../../utils/utils";
import { REFERRAL_COLUMN_MAPPER } from "./viewModal";

export const getDisplayedColumns = () => {
    const displayColumns = Object.values(REFERRAL_COLUMN_MAPPER).filter((item) => !item.hide);
    return displayColumns.map((item) => item.field);
};

export const fetchReferrals = (paginationParams, filterParams, setReferralData, setTotalReferralCount, sortParms, setStoreList) => {
    const { pageNumber, pageSize } = paginationParams;
    let updatedPaginationParams = { page: pageNumber, size: pageSize };
    let params = { ...updatedPaginationParams, ...filterParams, client: 'portal' };
    params = convertObjectToParams(params);

    if(sortParms) {
        params = `${params}&sortBy=${sortParms[0]?.field}&sortDirection=${sortParms[0]?.dir?.toUpperCase()}`
    }
    
    InspectionReferralService.fetchReferralList(params)
        .then((resp) => {
            setStoreList(resp?.filters?.STORE_NAME.map((item) => ({ label: item, value: item })))
            setReferralData(resp?.referrals)
            setTotalReferralCount(resp?.filteredItems);
        })
        .catch((err) => {
            setReferralData(null)
            setTotalReferralCount(0)
            console.log('Error Fetching Lead List', err)
        })
}
