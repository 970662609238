export const onInputChange = (e, formData, setFormData) => {
    const { value } = e;
    const { id } = e.target.props || {};
    const key = id || e.target.name;
    updateCurrentFormData({
        key,
        value: e.target.type === "file" ? e.target.files[0] : value,
        isFile: e.target.type === "file",
    }, formData, setFormData);
};
const updateCurrentFormData = ({ key, value, isFile = false }, formData, setFormData) => {
    let updatedPopupData = {
        ...formData,
        [key]: {
            ...formData[key],
            ...{ [isFile ? "file" : "value"]: value },
        },
    };
    setFormData(updatedPopupData);
};

export function isMp4Url(url) {
    return url?.toLowerCase()?.endsWith('.mp4');
}