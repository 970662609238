import React, { useEffect, useState } from "react";
// import styles from "../../assets/style/ff-layout.module.css";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import DetailCardLayout from "../../components/layout-detail-card";
import {
  CHALLAN_ITEM_STATUSES,
  CHALLAN_MODE_TYPES,
  CHALLAN_SOURCE_KEYS,
  NUMBERS,
  FORM_ELEMENT_TYPE,
} from "../../../../utils/constants/values.constants";
import {
  ASSIGN_AGENT_FORM_DATA,
  CHALLAN_PAYMENT_FORM_DATA,
  // cta_action_key,
  dispose_detail_cta_key,
  dispose_detail_cta_list,
  dispose_detail_list_keys,
  REJECT_CHALLAN_FORM_DATA,
  ADMIN_ASSIGN_AGENT_POPUP,
  INIT_DD_DATA_SOURCES,
  CHECKBOX_FILE_VIEW,
  DISPOSER_ASSIGNED_DATA,
  DISPOSER_ASSIGNED_VALUES,
} from "./viewModal";
import { FF_REASON_TYPES } from "../viewModal";
import FormCardLayout from "../../components/layout-form-card";
import {
  FORM_TYPES,
  PAYMENT_STATUSES,
  calculateTotalPayment,
} from "../../components/dispose-challan-item-popup/viewModel";
import {
  formatDateV2,
  getFFUserType,
  //   getUserRoles,
  showToastMessage,
} from "../../../../utils/utils";
import { ChallanService, VerficationService } from "../../../../services";
import { challan_item_keys } from "../../components/challan-detail-revamp/viewModel";
import LayoutStatusBanner from "../../components/layout-status-banner";
import ChallanItemPopup from "../../components/dispose-challan-item-popup";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
// import UploadFileLayout from "../../components/layout-upload-file";
import { USER_ROLE_KEYS } from "../../../../utils/constants/menu.constants";
import CellTooltip from "../../../../common/cell-tooltip";
import { updateDisposeChallanItem } from "../../../../store/actions/ops-challan";
import { useDispatch } from "react-redux";
import { FormClearInput, FormRadioGroup } from "../../../../components";
import FinderProofView from "../../components/layout-multiple-finder-proof";
const DisposeDetailView = (props) => {
  const { rowData = {}, onBackClick } = props;
  const { idChallanItem } = rowData;
  const [disposeData, setDisposeData] = useState([]);
  const [detailListKeys, setDetailListKeys] = useState([]);
  const [formData, setFormData] = useState(CHALLAN_PAYMENT_FORM_DATA);
  const [detailCardCta, setDetailCardCta] = useState(dispose_detail_cta_list);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPopup, setCurrentPopup] = useState();
  const [isShowCTA, setIsShowCTA] = useState(false);
  const [fileCheckboxValue, setFileCheckboxValue] = useState(
    !!disposeData?.exceptionProof
  );
  const [assignedDisposer, setAssignedDisposer] = useState();

  const dispatch = useDispatch();
  const [reOpenPopup, setReOpenPopup] = useState(false);
  const [reOpenReason, setReOpenReason] = useState("");
  const isRaisePayment = [
    PAYMENT_STATUSES.PAID,
    PAYMENT_STATUSES.PAYMENT_RAISED,
    PAYMENT_STATUSES.RAISE_ACCEPTED,
  ].includes(disposeData?.status);

  const isAmendRaise =
    disposeData?.status === CHALLAN_ITEM_STATUSES.RAISE_REJECTED ||
    disposeData?.status === CHALLAN_ITEM_STATUSES.RAISE_AMENDED;
  const isUnableToFulfil =
    disposeData?.status === PAYMENT_STATUSES.UNABLE_TO_FULFILL;
  const userType = getFFUserType();
  const isAdmin = [
    USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN,
    USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_VIEW,
  ].includes(userType);
  const [refresh, setRefresh] = useState(false);

  const isOnlineFlow = disposeData[challan_item_keys.CHALLAN_TYPE]?.toUpperCase() === CHALLAN_MODE_TYPES.ONLINE && disposeData?.[challan_item_keys.AGENT_TYPE] === DISPOSER_ASSIGNED_VALUES.SELF;
  const isOfflineFlow = disposeData[challan_item_keys.CHALLAN_TYPE]?.toUpperCase() === CHALLAN_MODE_TYPES.OFFLINE || disposeData?.[challan_item_keys.AGENT_TYPE] === DISPOSER_ASSIGNED_VALUES.EXTERNAL_AGENT;

  useEffect(() => {
    ChallanService.fetchReasons(FF_REASON_TYPES.UNFULFILLED).then((resp) => {
      const { detail } = resp;
      const reasons = detail.map((item) => {
        return {
          label: item.reason,
          value: item.reason,
        };
      });
      setFormData((data) => ({
        ...data,
        [challan_item_keys.REMARKS]: {
          ...data[challan_item_keys.REMARKS],
          source: reasons,
        },
      }));
    });
  }, []);
  useEffect(() => {
    setRefresh(true);
  }, [idChallanItem]);

  useEffect(() => {
    RefreshData();
  }, [refresh]);

  const RefreshData = () => {
    if (refresh) {
      ChallanService.fetchDisposeChallanDetailInfo(idChallanItem)
        .then((res) => {
          const updatedList = dispose_detail_list_keys?.map((item) => ({
            ...item,
            value: res?.detail?.[item?.field],
          }));
          setDetailListKeys(updatedList);
          let data = {...res?.detail};
          const {agent='', agentType='', status=''} = data;
          if (!!agent && !agentType) {
            data = {...data, agentType: DISPOSER_ASSIGNED_VALUES.EXTERNAL_AGENT};
          } else if (![CHALLAN_ITEM_STATUSES.CREATED, CHALLAN_ITEM_STATUSES.SUBMITTED, CHALLAN_ITEM_STATUSES.ASSIGNED].includes(status) && !agent && !agentType) {
            data = {...data, agentType: DISPOSER_ASSIGNED_VALUES.SELF};
          }
          setDisposeData(data);
          dispatch(updateDisposeChallanItem(data));
        })
        .catch((err) => {
          showToastMessage(err?.message, false);
        })
        .finally(() => {
          setRefresh(false);
        });
    }
  };

  useEffect(() => {
    const updatedDetailCardData = detailListKeys?.map((item) => ({
      ...item,
      isCell: true,
      cell: () => (
        <CellTooltip
          value={
            item?.field === challan_item_keys.CREATED_AT
              ? formatDateV2(item?.value)
              : item?.value
          }
          position="right"
        />
      ),
      ...(item?.field === "challanPdfUrl" && {
        //TODO
        cell: ProofCell,
      }),
      ...(item?.field === 'paymentGatewayUrl' && {
        cell: PaymentGatewayCell,
      }),
      ...([challan_item_keys.AGENT_NAME, challan_item_keys.AGENT_FESS].includes(
        item?.field
      ) &&
        (isOnlineFlow) && {
        isHide: true,
      }),
    }));
    setDetailListKeys(updatedDetailCardData);
    // dis
    let updatedFormData = { ...formData };
    let updatedChallanTypes =
      INIT_DD_DATA_SOURCES[challan_item_keys.CHALLAN_TYPE];
    if (disposeData?.type === CHALLAN_MODE_TYPES.VIRTUAL_COURT) {
      updatedChallanTypes = [
        ...updatedChallanTypes,
        {
          label: "Virtual Court Proceeding",
          value: PAYMENT_STATUSES.VIRTUAL_COURT_PROCEEDING,
        },
      ];
    }
    updatedFormData = {
      ...updatedFormData,
      [challan_item_keys.CHALLAN_TYPE]: {
        ...updatedFormData[challan_item_keys.CHALLAN_TYPE],
        source: updatedChallanTypes,
      },
    };
    // update formdata from dispose data
    let _formdata = { ...formData };
    Object.keys(_formdata).map((key) => {
      updatedFormData[key].disabled =
        isAdmin || isRaisePayment || isAmendRaise || isUnableToFulfil;
      if (key !== challan_item_keys.REG_NUMBER) {
        updatedFormData[key].value = disposeData[key];
      }
      if (challan_item_keys.REMARKS === key && isUnableToFulfil) {
        updatedFormData[key].elementtype = FORM_ELEMENT_TYPE.INPUT;
      }
    });
    updatedFormData = {
      ...updatedFormData,
      [challan_item_keys.CHALLAN_AMOUNT]: {
        ...formData[challan_item_keys.CHALLAN_AMOUNT],
        value: disposeData?.amount,
      },
      [challan_item_keys.TOTAL_PAYMENT]: {
        ...formData[challan_item_keys.TOTAL_PAYMENT],
        value:
          disposeData[challan_item_keys.AGENT_FESS] +
          disposeData[challan_item_keys.SETTLEMENT_AMOUNT],
      },
      ...(isRaisePayment && {
        [challan_item_keys.REG_NUMBER]: {
          ...formData[challan_item_keys.REG_NUMBER],
          value: disposeData?.regNumber,
        },
        [challan_item_keys.CHALLAN_TYPE]: {
          ...formData[challan_item_keys.CHALLAN_TYPE],
          value: updatedChallanTypes.find(
            (item) => item.value === disposeData[challan_item_keys.STATUS]
          ),
        },
        [challan_item_keys.AGENT_FESS]: {
          ...formData[challan_item_keys.AGENT_FESS],
          hide:
            isOnlineFlow,
          value: disposeData?.agentFees,
        },
        [challan_item_keys.SETTLEMENT_AMOUNT]: {
          ...formData[challan_item_keys.SETTLEMENT_AMOUNT],
          hide: false,
          value: disposeData?.settlementAmount,
        },
        [challan_item_keys.TOTAL_PAYMENT]: {
          ...formData[challan_item_keys.TOTAL_PAYMENT],
          hide:
            isOnlineFlow,
          value: disposeData?.agentFees + disposeData?.settlementAmount,
        },
        [challan_item_keys.FILE_SINGLE_UPLOAD]: {
          ...formData[challan_item_keys.FILE_SINGLE_UPLOAD],
          hide: false,
          handleFileView: () => handleDownloadProof("dipose"),
          showFileView: true,
        },
        [challan_item_keys.GOVERNMENT_FEE_PROOF]: {
          ...formData[challan_item_keys.GOVERNMENT_FEE_PROOF],
          hide: false,
          showFileView: true,
          handleFileView: () => handleDownloadProof("governmentFeeProof"),
        },
        [challan_item_keys.EXCEPTION_PROOF]: {
          ...formData[challan_item_keys.EXCEPTION_PROOF],
          hide: false,
          disabled: isAdmin
            ? true
            : disposeData?.status === PAYMENT_STATUSES.PAYMENT_RAISED,
          checkboxValue: true,
          handleFileView: () => handleDownloadProof(),
          showFileView: true,
        },
        [challan_item_keys.FULFILLMENT_MODE]: {
          ...formData[challan_item_keys.FULFILLMENT_MODE],
          value: INIT_DD_DATA_SOURCES[challan_item_keys.FULFILLMENT_MODE].filter(item => item.value === disposeData?.settlementMedium)?.[0],
          hide: !disposeData?.settlementMedium,
        }
      }),
      ...(isAmendRaise && {
        [challan_item_keys.REG_NUMBER]: {
          ...formData[challan_item_keys.REG_NUMBER],
          value: disposeData?.regNumber,
        },
        [challan_item_keys.NOTICE_NUMBER]: {
          ...formData[challan_item_keys.NOTICE_NUMBER],
          hide: false,
          value: disposeData?.noticeNumber,
        },
        [challan_item_keys.CHALLAN_TYPE]: {
          ...formData[challan_item_keys.CHALLAN_TYPE],
          hide: true,
        },
        [challan_item_keys.AGENT_FESS]: {
          ...formData[challan_item_keys.AGENT_FESS],
          hide:
            isOnlineFlow,
          value: disposeData?.agentFees,
          disabled: disposeData?.status === PAYMENT_STATUSES.RAISE_AMENDED,
        },
        [challan_item_keys.SETTLEMENT_AMOUNT]: {
          ...formData[challan_item_keys.SETTLEMENT_AMOUNT],
          hide: false,
          value: disposeData?.settlementAmount,
          disabled: disposeData?.status === PAYMENT_STATUSES.RAISE_AMENDED,
        },
        [challan_item_keys.TOTAL_PAYMENT]: {
          ...formData[challan_item_keys.TOTAL_PAYMENT],
          hide:
            isOnlineFlow,
          value: disposeData?.agentFees + disposeData?.settlementAmount,
        },
        [challan_item_keys.FILE_SINGLE_UPLOAD]: {
          ...formData[challan_item_keys.FILE_SINGLE_UPLOAD],
          hide: false,
          disabled: disposeData?.status === PAYMENT_STATUSES.RAISE_AMENDED,
          showFileView: true,
          handleFileView: () => handleDownloadProof("dipose"),
        },
        [challan_item_keys.GOVERNMENT_FEE_PROOF]: {
          ...formData[challan_item_keys.GOVERNMENT_FEE_PROOF],
          hide: false,
          hide: false,
          disabled:
            disposeData?.status === PAYMENT_STATUSES.RAISE_AMENDED && !isAdmin,
          showFileView: true,
          handleFileView: () => handleDownloadProof("governmentFeeProof"),
        },
        [challan_item_keys.EXCEPTION_PROOF]: {
          ...formData[challan_item_keys.EXCEPTION_PROOF],
          hide: false,
          disabled: isAdmin
            ? true
            : disposeData?.status === PAYMENT_STATUSES.PAYMENT_RAISED,
          showFileView: true,
          handleFileView: () => handleDownloadProof(),
        },
        [challan_item_keys.REMARKS]: {
          ...formData[challan_item_keys.REMARKS],
          label: "Remarks",
          hide: false,
        },
      }),

      ...(isUnableToFulfil && {
        [challan_item_keys.REG_NUMBER]: {
          ...formData[challan_item_keys.REG_NUMBER],
          value: disposeData?.regNumber,
        },
        [challan_item_keys.CHALLAN_AMOUNT]: {
          ...formData[challan_item_keys.CHALLAN_AMOUNT],
          value: disposeData?.amount,
        },
        [challan_item_keys.CHALLAN_TYPE]: {
          ...formData[challan_item_keys.CHALLAN_TYPE],
          value: updatedChallanTypes.find(
            (item) => item.value === disposeData[challan_item_keys.STATUS]
          ),
        },
        [challan_item_keys.REMARKS]: {
          ...formData[challan_item_keys.REMARKS],
          label: "Reason for Not Fulfilling",
          value: disposeData?.remarks,
          hide: false,
        },
      }),
    };
    setFormData(updatedFormData);

    const updatedDetailCardCtaList = {
      ...detailCardCta,
      [dispose_detail_cta_key.ASSIGN_AGENT]: {
        ...detailCardCta[dispose_detail_cta_key.ASSIGN_AGENT],
        hide: !showCTA(dispose_detail_cta_key.ASSIGN_AGENT),
        title: !!disposeData?.agent ? "Edit Agent" : "Assign Agent",
        handleClick: () =>
          onChallanItemOpenDialog(
            FORM_TYPES.ASSIGN_AGENT,
            disposeData,
            !!disposeData?.agent ? true : false
          ),
      },
      [dispose_detail_cta_key.CONVERT]: {
        ...detailCardCta[dispose_detail_cta_key.CONVERT],
        hide: !showCTA(dispose_detail_cta_key.CONVERT),
        handleClick: () =>
          onCovertChallan({
            formType: FORM_TYPES.REJECT_CHALLAN,
            rowData: disposeData,
          }),
      },
      [dispose_detail_cta_key.REJECT]: {
        ...detailCardCta[dispose_detail_cta_key.REJECT],
        hide: !showCTA(dispose_detail_cta_key.REJECT),
        handleClick: () =>
          onChallanItemOpenDialog(FORM_TYPES.REJECT_CHALLAN, disposeData),
      },
    };
    setDetailCardCta(updatedDetailCardCtaList);
    showPaymentCTA();
    setAssignedDisposer(disposeData[challan_item_keys.AGENT_TYPE] || DISPOSER_ASSIGNED_VALUES.SELF)
  }, [disposeData]);

  const showPaymentCTA = () => {
    setIsShowCTA(false);
    const {
      assignedTo = "",
      agent = "",
      type = "",
      status = "",
      agentType = "",
    } = disposeData || {};
    const itemType = !!type && type.toUpperCase();
    const isChallanCycleComplete = [
      PAYMENT_STATUSES.PAID,
      PAYMENT_STATUSES.UNABLE_TO_FULFILL,
      "REJECTED",
      // CHALLAN_ITEM_STATUSES.RAISE_REJECTED,
      CHALLAN_ITEM_STATUSES.PAYMENT_RAISED,
      CHALLAN_ITEM_STATUSES.RAISE_ACCEPTED,
      CHALLAN_ITEM_STATUSES.RAISE_AMENDED,
    ].includes(status);
    if (
      !isChallanCycleComplete &&
      !!assignedTo &&
      (((itemType === CHALLAN_MODE_TYPES.OFFLINE || agentType === DISPOSER_ASSIGNED_VALUES.EXTERNAL_AGENT) && !!agent) ||
        [CHALLAN_MODE_TYPES.ONLINE, CHALLAN_MODE_TYPES.VIRTUAL_COURT].includes(//check
          itemType
        )) &&
      !isAdmin
    ) {
      setIsShowCTA(true);
    } else if (
      !isChallanCycleComplete &&
      !!assignedTo &&
      !agent &&
      (itemType === CHALLAN_MODE_TYPES.OFFLINE || agentType === DISPOSER_ASSIGNED_VALUES.EXTERNAL_AGENT) &&
      !isAdmin
    ) {
      setIsShowCTA(true);
    } else if (
      status === PAYMENT_STATUSES.PAID &&
      [CHALLAN_MODE_TYPES.OFFLINE, CHALLAN_MODE_TYPES.ONLINE].includes(
        itemType
      ) &&
      !isAdmin
    ) {
      setIsShowCTA(true);
    } else if (status === PAYMENT_STATUSES.RAISE_REJECTED && !isAdmin) {
      setIsShowCTA(true);
    }
  };

  const showCTA = (key) => {
    const {
      assignedTo = "",
      agent = "",
      status = "",
      sourceCode = "",
      type = "",
      agentType = "",
    } = disposeData || {};
    const itemType = !!type && type.toUpperCase();
    const isChallanCycleComplete = [
      CHALLAN_ITEM_STATUSES.PAID,
      CHALLAN_ITEM_STATUSES.UNABLE_TO_FULFILL,
      CHALLAN_ITEM_STATUSES.PAYMENT_RAISED,
      CHALLAN_ITEM_STATUSES.REJECTED,
      CHALLAN_ITEM_STATUSES.RAISE_REJECTED,
      CHALLAN_ITEM_STATUSES.RAISE_ACCEPTED,
      CHALLAN_ITEM_STATUSES.RAISE_AMENDED,
    ].includes(status);
    if (isAdmin) {
      return false;
    } else if (key === dispose_detail_cta_key.REJECT) {
      return (
        !!assignedTo &&
        !isChallanCycleComplete &&
        sourceCode !== CHALLAN_SOURCE_KEYS.VAS
      );
    } else if (key === dispose_detail_cta_key.CONVERT) {
      return (
        !isChallanCycleComplete &&
        !!assignedTo &&
        ((sourceCode === CHALLAN_SOURCE_KEYS.VAS &&
          itemType === CHALLAN_MODE_TYPES.ONLINE) ||
          (sourceCode !== CHALLAN_SOURCE_KEYS.VAS &&
            status === PAYMENT_STATUSES.VIRTUAL_COURT_PROCEEDING))
      );
    } else if (key === dispose_detail_cta_key.ASSIGN_AGENT) {
      const showAssignAgent =
        !!assignedTo &&
        ([CHALLAN_MODE_TYPES.OFFLINE].includes(itemType) || agentType === DISPOSER_ASSIGNED_VALUES.EXTERNAL_AGENT) &&
        !isChallanCycleComplete;
      return showAssignAgent;
    }
    return false;
  };

  const ProofCell = () => {
    const isViewOnlyUser = [USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_VIEW].includes(
      getFFUserType()
    );
    return (
      <>
        {!isViewOnlyUser ? (
          <FinderProofView
            challanFinderProofs={disposeData?.challanFinderProofs}
            fkChallanRequest={disposeData?.fkChallanRequest}
            handleFileView={onDownloadFile}
          />
        ) : (
          <p>URL_Link</p>
        )}
      </>
    );
  };

  const PaymentGatewayCell = () => {
    const paymentGatewayUrl = disposeData?.paymentGatewayUrl;
    if (paymentGatewayUrl) {
      return (
        <div
          onClick={() => {
            navigator.clipboard.writeText(paymentGatewayUrl);
            showToastMessage('Url copied to clipboard', true);
          }}
          className="payment-gateway-cell"
        >
          Copy Url
        </div>
      );
    } else {
      return '-'
    }
  };

  const onDownloadFile = (id, file) => {
    if (id) {
      ChallanService.fetchUploadProofMultipleFileLink(id, file)
        .then((resp) => {
          resp?.detail && window.open(resp?.detail, "_blank", "noopener");
        })
        .catch((err) => {
          console.log(err);
          showToastMessage(err?.message, false);
        });
    }
  };

  const onInputChange = (e) => {
    const { value } = e;
    const { id } = e.target.props || {};
    const key = id || e.target.name;

    if (key === CHECKBOX_FILE_VIEW) {
      setFileCheckboxValue(!fileCheckboxValue);
    } else {
      updateCurrentFormData({
        key,
        value: e.target.type === "file" ? e.target.files[0] : value,
        isFile: e.target.type === "file",
      });
    }
  };

  const updateCurrentFormData = ({ key, value, isFile = false }) => {
    let updatedPopupData = {
      ...formData,
      [key]: {
        ...formData[key],
        ...{ [isFile ? "file" : "value"]: value },
      },
    };
    if (key === challan_item_keys.CHALLAN_TYPE) {
      updatedPopupData = {
        ...updatedPopupData,

        [challan_item_keys.REMARKS]: {
          ...updatedPopupData[challan_item_keys.REMARKS],
          elementtype:
            value?.value === PAYMENT_STATUSES.UNABLE_TO_FULFILL
              ? FORM_ELEMENT_TYPE.SELECT
              : FORM_ELEMENT_TYPE.INPUT,
          label:
            value?.value === PAYMENT_STATUSES.UNABLE_TO_FULFILL
              ? "Reason for not fulfilling"
              : "Remarks",
          placeholder:
            value?.value === PAYMENT_STATUSES.UNABLE_TO_FULFILL
              ? "Select reason for not fulfilling"
              : "Enter Remarks",
          hide: !value,
          value: null,
        },
        [challan_item_keys.AGENT_FESS]: {
          ...updatedPopupData[challan_item_keys.AGENT_FESS],
          hide: !(
            value?.value === PAYMENT_STATUSES.PAID &&
            isOfflineFlow
          ),
        },
        [challan_item_keys.SETTLEMENT_AMOUNT]: {
          ...updatedPopupData[challan_item_keys.SETTLEMENT_AMOUNT],
          hide: !(value?.value === PAYMENT_STATUSES.PAID),
        },
        [challan_item_keys.TOTAL_PAYMENT]: {
          ...updatedPopupData[challan_item_keys.TOTAL_PAYMENT],
          hide: !(
            value?.value === PAYMENT_STATUSES.PAID &&
            isOfflineFlow
          ),
        },
        [challan_item_keys.FILE_SINGLE_UPLOAD]: {
          ...updatedPopupData[challan_item_keys.FILE_SINGLE_UPLOAD],
          hide: !(value?.value === PAYMENT_STATUSES.PAID),
        },
        [challan_item_keys.GOVERNMENT_FEE_PROOF]: {
          ...updatedPopupData[challan_item_keys.GOVERNMENT_FEE_PROOF],
          hide: !(value?.value === PAYMENT_STATUSES.PAID),
        },
        [challan_item_keys.FULFILLMENT_MODE]: {
          ...updatedPopupData[challan_item_keys.FULFILLMENT_MODE],
          hide: !(value?.value === PAYMENT_STATUSES.PAID && !!disposeData?.paymentGatewayUrl),
        },
      };
    } else if (
      key === challan_item_keys.AGENT_FESS ||
      key === challan_item_keys.SETTLEMENT_AMOUNT
    ) {
      updatedPopupData = {
        ...updatedPopupData,

        [challan_item_keys.TOTAL_PAYMENT]: {
          ...updatedPopupData[challan_item_keys.TOTAL_PAYMENT],
          value: calculateTotalPayment(formData, key, value),
        },
      };
    }
    setFormData(updatedPopupData);
  };

  const updateCurrentPopupData = ({ key, value, isFile = false }) => {
    let updatedPopupData = {
      ...currentPopup,
      formData: {
        ...currentPopup.formData,
        [key]: {
          ...currentPopup.formData[key],
          ...{ [isFile ? "file" : "value"]: value },
        },
      },
    };
    setCurrentPopup(updatedPopupData);
  };

  const onInputLostFocus = (e) => {
    !!e.target.name &&
      ChallanService.validateRegNumber(
        disposeData?.idChallanItem,
        e.target.value
      ).then((resp) => {
        showTickRegNumber(e.target.name, true);
      });
  };

  const showTickRegNumber = (key, isForm = false) => {
    if (isForm) {
      let updatedPopupData = {
        ...formData,
        [key]: {
          ...formData[key],
          istick: true,
        },
      };
      setFormData(updatedPopupData);
    } else {
      let updatedPopupData = {
        ...currentPopup,
        formData: {
          ...currentPopup.formData,
          [key]: {
            ...currentPopup.formData[key],
            istick: true,
          },
        },
      };
      setCurrentPopup(updatedPopupData);
    }
  };

  const handleConfirm = () => {
    const regNumber = formData[challan_item_keys.REG_NUMBER];
    //validate
    const status = formData[challan_item_keys.CHALLAN_TYPE]?.value?.value;
    const settlementAmt = formData[challan_item_keys.SETTLEMENT_AMOUNT]?.value;
    const agentFees = formData[challan_item_keys.AGENT_FESS]?.value;
    const paymentproof = formData[challan_item_keys.FILE_SINGLE_UPLOAD]?.file;
    const remarks =
      formData[challan_item_keys.REMARKS]?.value?.value ||
      formData[challan_item_keys.REMARKS]?.value;
    const governmentFeeProof =
      formData[challan_item_keys.GOVERNMENT_FEE_PROOF]?.file;
    const settlementMedium = formData[challan_item_keys.FULFILLMENT_MODE]?.value?.value;
    if (regNumber?.value === "_") {
      showToastMessage("Please select reason", false);
      return;
    } else if (!regNumber?.istick) {
      showToastMessage("Please select valid registration number", false);
      return;
    } else if (
      isOfflineFlow &&
      !agentFees &&
      status === PAYMENT_STATUSES.PAID
    ) {
      showToastMessage("Please select agent fees", false);
      return;
    } else if (!status) {
      showToastMessage("Please select challan status", false);
      return;
    } else if (
      isOfflineFlow &&
      !settlementAmt &&
      status === PAYMENT_STATUSES.PAID
    ) {
      showToastMessage("Please select government fee", false);
      return;
    } else if (!paymentproof && status === PAYMENT_STATUSES.PAID) {
      showToastMessage("Please upload payment proof", false);
      return;
    } else if (status === PAYMENT_STATUSES.UNABLE_TO_FULFILL && !remarks) {
      showToastMessage("Please upload reason for unfulfillment", false);
      return;
    } else if (!settlementMedium && status === PAYMENT_STATUSES.PAID && disposeData?.paymentGatewayUrl) {
      showToastMessage('Please select fulfillment mode', false);
      return;
    }
    let payload = new FormData();
    if (status === PAYMENT_STATUSES.PAID) {
      payload.append(challan_item_keys.FILE_SINGLE_UPLOAD, paymentproof);
      payload.append(challan_item_keys.SETTLEMENT_AMOUNT, settlementAmt);
      if (isOfflineFlow) {
        payload.append(challan_item_keys.AGENT_FESS, agentFees);
      }
      if (disposeData?.paymentGatewayUrl) {
        payload.append(challan_item_keys.FULFILLMENT_MODE, settlementMedium);
      }
    }
    payload.append(challan_item_keys.STATUS, status);
    payload.append(challan_item_keys.REMARKS, remarks);
    !!governmentFeeProof &&
      payload.append(
        challan_item_keys.GOVERNMENT_FEE_PROOF,
        governmentFeeProof
      );
    ChallanService.paymentChallanItem(disposeData?.idChallanItem, payload)
      .then((resp) => {
        showToastMessage(resp?.message);
        // refresh
        setRefresh(true);
      })
      .catch((err) => {
        showToastMessage(err?.message || "Failed", false);
      });
  };

  const handleRaisePayment = () => {
    let raisePaymentFormData = new FormData();
    raisePaymentFormData.append("isException", fileCheckboxValue);
    !!formData[challan_item_keys.EXCEPTION_PROOF]?.file &&
      raisePaymentFormData.append(
        "exception-proof",
        formData[challan_item_keys.EXCEPTION_PROOF]?.file
      );
    ChallanService?.raisePayment(
      disposeData?.idChallanItem,
      raisePaymentFormData
    )
      .then((res) => {
        showToastMessage("Payment Raised");
        setRefresh(true);
      })
      .catch((err) => {
        showToastMessage(err?.message, false);
      });
  };

  const handleAmendRaiseConfirm = () => {
    let amendRaiseFormData = new FormData();
    amendRaiseFormData.append("isException", fileCheckboxValue);
    !!formData[challan_item_keys.EXCEPTION_PROOF]?.file &&
      amendRaiseFormData.append(
        "exception-proof",
        formData[challan_item_keys.EXCEPTION_PROOF]?.file
      );
    amendRaiseFormData.append(
      "payment-proof",
      formData[challan_item_keys.GOVERNMENT_FEE_PROOF]?.file
    );
    !!formData[challan_item_keys.SETTLEMENT_AMOUNT]?.value &&
      amendRaiseFormData.append(
        "settlementAmount",
        formData[challan_item_keys.SETTLEMENT_AMOUNT]?.value
      );
    !!formData[challan_item_keys.AGENT_FESS]?.value &&
      amendRaiseFormData.append(
        "agentFee",
        formData[challan_item_keys.AGENT_FESS]?.value
      );
    ChallanService?.amendPayment(disposeData?.idChallanItem, amendRaiseFormData)
      .then((res) => {
        showToastMessage("Raised payment Amended");
        setRefresh(true);
      })
      .catch((err) => {
        showToastMessage(err?.message, false);
      });
  };
  // need to refresh parent item
  const gethandleConfirmAction = () => {
    if (!!isRaisePayment) {
      handleRaisePayment();
    } else if (!!isAmendRaise) {
      handleAmendRaiseConfirm();
    } else {
      handleConfirm();
    }
  };

  const onChallanItemOpenDialog = (action, event, isEdit = false) => {
    let isPopup = true;
    switch (action) {
      case FORM_TYPES.ASSIGN_AGENT:
        const popUtitle = isEdit ? "Update Agent" : "Assign Agent";
        let _agentData = {};
        Object.keys(ASSIGN_AGENT_FORM_DATA).map((key) => {
          _agentData = {
            ..._agentData,
            [key]: { ...ASSIGN_AGENT_FORM_DATA[key], value: null },
          };
        });
        setCurrentPopup({
          key: action,
          title: `${popUtitle} - (Notice Number: ${event[challan_item_keys.NOTICE_NUMBER]
            })`,
          dataItem: event,
          formData: { ..._agentData },
        });
        break;
      case FORM_TYPES.REJECT_CHALLAN:
        const isConvert =
          event[challan_item_keys.CHALLAN_TYPE] === CHALLAN_MODE_TYPES.ONLINE &&
          event[challan_item_keys.SOURCE_CODE] === CHALLAN_SOURCE_KEYS.VAS;
        let challan_form_data = { ...REJECT_CHALLAN_FORM_DATA };
        if (isConvert) {
          challan_form_data[challan_item_keys.REMARKS].label =
            "Reason for conversion";
          challan_form_data[challan_item_keys.FILE_SINGLE_UPLOAD].label =
            "Proof of conversion";
        }
        setCurrentPopup({
          key: action,
          title: `${isConvert ? "CONVERT TO OFFLINE" : "Reject Challan"
            } - (Notice Number: ${event[challan_item_keys.NOTICE_NUMBER]})`,
          dataItem: event,
          formData: challan_form_data,
          isConvert: isConvert,
        });
        break;
      case FORM_TYPES.ADMIN_ASSIGN_AGENT_DISPOSE:
        let _assignToData = { ...ADMIN_ASSIGN_AGENT_POPUP };
        _assignToData[challan_item_keys.APPOINTMENT_ORDER_ID].value =
          event[challan_item_keys.APPOINTMENT_ORDER_ID];
        _assignToData[challan_item_keys.REG_NUMBER].value =
          event[challan_item_keys.REG_NUMBER];
        _assignToData[challan_item_keys.SOURCE_CODE].value =
          event[challan_item_keys.SOURCE_CODE];
        setCurrentPopup({
          key: action,
          title: `Assign Disposer`,
          dataItem: event,
          formData: _assignToData,
        });
        break;
      default:
        isPopup = false;
        setCurrentPopup({});
        break;
    }
    setOpenDialog(isPopup);
  };

  const onCovertChallan = ({ formType, rowData }) => {
    const { status = "", idChallanItem } = rowData || {};
    if (status === PAYMENT_STATUSES.VIRTUAL_COURT_PROCEEDING) {
      ChallanService.convertChallanVirtualCourtProceeding(idChallanItem)
        .then((resp) => {
          showToastMessage(resp?.message);
          // resetChallanItems();
          setRefresh(true);
        })
        .catch((err) => {
          showToastMessage(err?.message || "Failed to convert", false);
        });
    } else {
      onChallanItemOpenDialog(formType, rowData);
    }
  };

  const onResetPopup = () => {
    switch (currentPopup.key) {
      case FORM_TYPES.ASSIGN_AGENT:
        setCurrentPopup((prev) => ({
          ...prev,
          formData: { ...ASSIGN_AGENT_FORM_DATA },
        }));
        break;
      case FORM_TYPES.REJECT_CHALLAN:
        setCurrentPopup((prev) => ({
          ...prev,
          formData: { ...REJECT_CHALLAN_FORM_DATA },
        }));
        break;
      case FORM_TYPES.CHALLAN_PAYMENT:
        setCurrentPopup((prev) => ({
          ...prev,
          formData: { ...CHALLAN_PAYMENT_FORM_DATA },
        }));
        break;
      case FORM_TYPES.ADMIN_ASSIGN_AGENT_DISPOSE:
        setCurrentPopup((prev) => ({
          ...prev,
          formData: { ...ADMIN_ASSIGN_AGENT_POPUP },
        }));
        break;
      default:
        setCurrentPopup({});
        break;
    }
  };

  const onPopupConfirm = () => {
    if (currentPopup.key === FORM_TYPES.ASSIGN_AGENT) {
      const payload = {
        [challan_item_keys.CHALLAN_COURT]:
          currentPopup.formData[challan_item_keys.CHALLAN_COURT]?.value?.label,
        [challan_item_keys.REGION]:
          currentPopup.formData[challan_item_keys.REGION]?.value?.label,
        idChallanAgent:
          currentPopup.formData[challan_item_keys.AGENT_NAME]?.value?.value,
        [challan_item_keys.AGENT_NAME]:
          currentPopup.formData[challan_item_keys.AGENT_NAME]?.value?.label,
      };
      if (!currentPopup.formData[challan_item_keys.REGION]?.value?.value) {
        showToastMessage("Please select region", false);
        return;
      } else if (
        !currentPopup.formData[challan_item_keys.CHALLAN_COURT]?.value?.value
      ) {
        showToastMessage("Please select court", false);
        return;
      } else if (
        !currentPopup.formData[challan_item_keys.AGENT_NAME]?.value?.value
      ) {
        showToastMessage("Please select agent", false);
        return;
      }
      ChallanService.assignAgentToChallanItem(
        currentPopup?.dataItem?.idChallanItem,
        payload
      )
        .then((resp) => {
          showToastMessage(resp?.message);
          setOpenDialog(false);
          // resetChallanItems();
          setRefresh(true);
        })
        .catch((err) => {
          showToastMessage(err?.message || "Failed", false);
        });
    } else if (currentPopup.key === FORM_TYPES.REJECT_CHALLAN) {
      const reason =
        currentPopup.formData[challan_item_keys.REMARKS]?.value?.value;
      const fileProof =
        currentPopup.formData[challan_item_keys.FILE_SINGLE_UPLOAD]?.file;
      if (!reason) {
        showToastMessage("Please select reason", false);
        return;
      } else if (!fileProof) {
        showToastMessage("Please upload rejection proof", false);
        return;
      }
      let formData = new FormData();
      formData.append("file", fileProof);
      formData.append("remarks", reason);
      const action = currentPopup.isConvert ? "convert" : "reject";
      ChallanService.updateChallanItemAction(
        currentPopup?.dataItem?.idChallanItem,
        formData,
        action
      )
        .then((resp) => {
          showToastMessage(resp?.message);
          setOpenDialog(false);
          setRefresh(true);
        })
        .catch((err) => {
          showToastMessage(err?.message || "Failed", false);
        });
    } else if (currentPopup.key === FORM_TYPES.CHALLAN_PAYMENT) {
      const regNumber = currentPopup.formData[challan_item_keys.REG_NUMBER];
      //validate
      const status =
        currentPopup.formData[challan_item_keys.CHALLAN_TYPE]?.value?.value;
      const settlementAmt =
        currentPopup.formData[challan_item_keys.SETTLEMENT_AMOUNT]?.value;
      const agentFees =
        currentPopup.formData[challan_item_keys.AGENT_FESS]?.value;
      const paymentproof =
        currentPopup.formData[challan_item_keys.FILE_SINGLE_UPLOAD]?.file;
      const remarks = currentPopup.formData[challan_item_keys.REMARKS]?.value;
      const governmentFeeProof =
        currentPopup.formData[challan_item_keys.GOVERNMENT_FEE_PROOF]?.file;
      if (regNumber?.value === "_") {
        showToastMessage("Please select reason", false);
        return;
      } else if (!regNumber?.istick) {
        showToastMessage("Please select valid registration number", false);
        return;
      } else if (
        (currentPopup?.dataItem?.type === CHALLAN_MODE_TYPES.OFFLINE || disposeData?.[challan_item_keys.AGENT_TYPE] === DISPOSER_ASSIGNED_VALUES.EXTERNAL_AGENT) &&
        !agentFees &&
        status === PAYMENT_STATUSES.PAID
      ) {
        showToastMessage("Please select agent fees", false);
        return;
      } else if (!status) {
        showToastMessage("Please select challan status", false);
        return;
      } else if (
        (currentPopup?.dataItem?.type === CHALLAN_MODE_TYPES.OFFLINE || disposeData?.[challan_item_keys.AGENT_TYPE] === DISPOSER_ASSIGNED_VALUES.EXTERNAL_AGENT) &&
        !settlementAmt &&
        status === PAYMENT_STATUSES.PAID
      ) {
        showToastMessage("Please select government fee", false);
        return;
      } else if (!paymentproof && status === PAYMENT_STATUSES.PAID) {
        showToastMessage("Please upload payment proof", false);
        return;
      } else if (status === PAYMENT_STATUSES.UNABLE_TO_FULFILL && !remarks) {
        showToastMessage("Please upload reason for unfulfillment", false);
        return;
      } else if (!settlementMedium && status === PAYMENT_STATUSES.PAID) {
        showToastMessage('Please select fulfillment mode', false);
        return;
      }
      let formData = new FormData();
      if (status === PAYMENT_STATUSES.PAID) {
        formData.append(challan_item_keys.FILE_SINGLE_UPLOAD, paymentproof);
        if (currentPopup?.dataItem?.type === CHALLAN_MODE_TYPES.OFFLINE || disposeData?.[challan_item_keys.AGENT_TYPE] === DISPOSER_ASSIGNED_VALUES.EXTERNAL_AGENT) {
          formData.append(challan_item_keys.AGENT_FESS, agentFees);
          formData.append(challan_item_keys.SETTLEMENT_AMOUNT, settlementAmt);
        }
      }
      formData.append(challan_item_keys.STATUS, status);
      formData.append(challan_item_keys.REMARKS, remarks);
      status !== PAYMENT_STATUSES.UNABLE_TO_FULFILL &&
        !!governmentFeeProof &&
        formData.append(
          challan_item_keys.GOVERNMENT_FEE_PROOF,
          governmentFeeProof
        );
      ChallanService.paymentChallanItem(
        currentPopup?.dataItem?.idChallanItem,
        formData
      )
        .then((resp) => {
          showToastMessage(resp?.message);
          setOpenDialog(false);
          // resetChallanItems();
          setRefresh(true);
        })
        .catch((err) => {
          showToastMessage(err?.message || "Failed", false);
        });
    } else if (currentPopup.key === FORM_TYPES.ADMIN_ASSIGN_AGENT_DISPOSE) {
      const regNumber = currentPopup.formData[challan_item_keys.REG_NUMBER];
      const assignTo =
        currentPopup.formData[challan_item_keys.ASSIGN_TO]?.value?.value;
      if (!regNumber?.value) {
        showToastMessage(
          "Please select valid vehicle registration number",
          false
        );
        return;
      } else if (!assignTo) {
        showToastMessage("Please select disposer", false);
        return;
      }
      ChallanService.UpdateAssignTo(
        currentPopup?.dataItem?.idChallanItem,
        assignTo,
        "dispose"
      )
        .then((resp) => {
          showToastMessage(resp?.message);
          setOpenDialog(false);
          // resetChallanItems();
          setRefresh(true);
        })
        .catch((err) => {
          showToastMessage(err?.message || "Failed", false);
        });
    }
  };

  const handleDownloadProof = (file) => {
    if (file === "dipose") {
      VerficationService.getDisposeProof(disposeData?.idChallanItem)
        .then((res) => {
          window.open(res?.detail, "_blank");
        })
        .catch((err) => showToastMessage(err?.message, false));
    } else if (file === "governmentFeeProof") {
      VerficationService.getGovernmentFeeProof(disposeData?.idChallanItem)
        .then((res) => {
          window.open(res?.detail, "_blank");
        })
        .catch((err) => showToastMessage(err?.message, false));
    } else {
      VerficationService.getExceptionProof(disposeData?.idChallanItem)
        .then((res) => {
          window.open(res?.detail, "_blank");
        })
        .catch((err) => showToastMessage(err?.message, false));
    }
  };
  const reOpenItemHander = () => {
    setReOpenPopup(!reOpenPopup);
  };
  const onConfirmReopenPopup = () => {
    ChallanService.reOpenChallanItem(idChallanItem, reOpenReason)
      .then((resp) => {
        setRefresh(true);
        reOpenItemHander();
        setReOpenReason("");
        setRefresh(true);
      })
      .catch((err) => {
        showToastMessage(err?.message, false);
      });
  };

  const handleRadioChange = (e) => {
    const { value } = e || {};
    setAssignedDisposer(value);
  };

  const handleConfirmAssignedDisposer = () => {
    ChallanService.updateAssignedDisposer(idChallanItem, { agentType: assignedDisposer })
      .then((res) => {
        showToastMessage(res?.message);
        setRefresh(true);
      })
      .catch((err) => showToastMessage(err?.message));
  }

  const getDisposerAssignedView = () => (
    <div style={{ display: "flex", background: "#F8FCFF", padding: "40px 27px", justifyContent: "space-between", alignItems: "center" }}>
      <div className="search-tag k-form-buttons">
        <FormRadioGroup
          data={DISPOSER_ASSIGNED_DATA}
          onChange={handleRadioChange}
          isDisplayLabel={"true"}
          label={"Disposer Assigned"}
          value={assignedDisposer}
        />
      </div>
      <Button themeColor={"primary"} fillMode={"outline"} onClick={handleConfirmAssignedDisposer}>Confirm</Button>
    </div>
  );

  return (
    <div className="ops-challan-container">
      <div className="ops-grid-layout-container">
        <GridLayout
          gap={{
            rows: "15px",
            cols: 10,
          }}
        >
          <GridLayoutItem row={1}>
            <LayoutStatusBanner
              status={disposeData?.status}
              label={`Notice No. #${disposeData?.noticeNumber}`}
              onBackClick={onBackClick}
              isReopenChallanItem={
                disposeData?.status === CHALLAN_ITEM_STATUSES.UNABLE_TO_FULFILL
              }
              reOpenItemHander={reOpenItemHander}
              isAdmin={USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN === userType}
            />
          </GridLayoutItem>
          <GridLayoutItem row={2}>
            <DetailCardLayout
              headerTitle={"Challan Info"}
              itemPerRow={NUMBERS.FOUR}
              columnList={detailListKeys}
              ctaActions={detailCardCta}
              customComponent={(USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN !== userType && 
                [CHALLAN_MODE_TYPES.ONLINE, CHALLAN_MODE_TYPES.VIRTUAL_COURT].includes(disposeData?.type) && 
                disposeData?.status === CHALLAN_ITEM_STATUSES.ASSIGNED && !disposeData[challan_item_keys.AGENT_TYPE]) ? getDisposerAssignedView() : <></>}
              customIndexStyle={{
                index: 2,
                valueStyle: {
                  color:
                    disposeData?.type === CHALLAN_MODE_TYPES.OFFLINE
                      ? "red"
                      : "green",
                },
              }}
            />
          </GridLayoutItem>
          {(USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN === userType || !!disposeData?.agentType) &&
            <GridLayoutItem row={3}>
              <FormCardLayout
                headerTitle={
                  isRaisePayment
                    ? "Payment Raise"
                    : isAmendRaise
                      ? "Amend Raise"
                      : "Payment"
                }
                formData={formData}
                handleChange={onInputChange}
                onInputLostFocus={onInputLostFocus}
                handleConfirm={gethandleConfirmAction}
                isShowCTA={isShowCTA}
                data={disposeData}
                checkboxValue={fileCheckboxValue}
                setCheckboxValue={setFileCheckboxValue}
              />
            </GridLayoutItem>
          }
        </GridLayout>

        {openDialog && (
          <Dialog
            title={currentPopup?.title}
            onClose={() => {
              setOpenDialog(false);
            }}
          >
            <ChallanItemPopup
              {...currentPopup}
              action={currentPopup.key}
              updateCurrentPopupData={updateCurrentPopupData}
              showTickRegNumber={showTickRegNumber}
            />
            <DialogActionsBar>
              <Button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={onResetPopup}
              >
                Reset
              </Button>
              <Button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={onPopupConfirm}
                themeColor={"primary"}
              >
                Confirm
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
        {reOpenPopup && (
          <Dialog
            title={
              <p>
                <div
                  style={{
                    cursor: "pointer",
                    color: "#0059A3",
                    fontWeight: "700",
                    fontSize: "18px",
                  }}
                >
                  Reopen Request
                </div>
                <div>Enter the reason to Reopen</div>
              </p>
            }
            onClose={reOpenItemHander}
          >
            <div>
              <FormClearInput
                id={"reopenreason"}
                key={"reopenreason"}
                value={reOpenReason}
                label={"Reason"}
                onChange={(e) => setReOpenReason(e.target.value)}
                handleClear={() => setReOpenReason("")}
                placeholder="Enter Reason"
              />
            </div>
            <DialogActionsBar>
              <Button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={() => setReOpenReason("")}
              >
                Reset
              </Button>
              <Button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={onConfirmReopenPopup}
                themeColor={"primary"}
              >
                Confirm
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default DisposeDetailView;
