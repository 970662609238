import { useState, useEffect } from "react";
import PaymentActions from "../components/payment-actions";
import { Form, FormElement } from "@progress/kendo-react-form";
import { useDispatch, useSelector } from "react-redux";
import {
  DealerDetailsFormData,
  FlagSectionFormData,
  TransactionLogColumns,
  grievance_column_keys,
} from "./viewModels";
import { getDisplayedCoulmns } from "../payment-list/viewModels";
import { fetchPayoutDetails } from "../../../store/actions/dealer-payouts";
import {
  dealerPayoutOpenIssueStatuses,
  FORM_ELEMENT_TYPE,
} from "../../../utils/constants/values.constants";
import { USER_ROLE_KEYS } from "../../../utils/constants/menu.constants";
import { FormInput } from "../../../components";
import { useHistory } from "react-router-dom";
import { getLoginUserEmail, getUserRoles } from "../../../utils/utils";
import CustomGrid from "../../../common/custom-grid/component";
import GrievanceLog from "./components/grievance-log";
import FlagSection from "./components/flag-section";
const PaymentDetail = (props) => {
  const { match: { params: { leadId } } = {} } = props;
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.dealerPayouts);
  const [detailsData, setDetailsData] = useState({});
  const [dealerFormData, setDealerFormData] = useState(DealerDetailsFormData);
  const [flagData, setFlagData] = useState(FlagSectionFormData);
  const [updatedFlagData, setUpdatedFlagData] = useState(flagData);
  const [transactionData, setTransactionData] = useState([]);
  const history = useHistory();
  const onBack = () => {
    history.push("/dealer/payouts");
  };

  useEffect(() => {
    fetchPayouts(leadId);
  }, [leadId]);
  const fetchPayouts = (key) => {
    if (!isLoading) {
      dispatch(fetchPayoutDetails(key));
    }
  };
  useEffect(() => {
    if (data && data.length) {
      onPaymentDetail(data[0]);
      setDetailsData(data[0]);
    }
  }, [data]);
  console.log(flagData);
  useEffect(() => {
    let data = { ...flagData };
    if (flagData?.status?.value === "APPROVAL_PENDING") {
      delete data[grievance_column_keys.PAID_AMOUNT];
      delete data[grievance_column_keys.CUSTOM_AMOUNT];
    } else if (flagData?.status?.value === "PAYMENT_PENDING") {
      if (data[grievance_column_keys.ADMIN_COMMENT]) {
        data[grievance_column_keys.ADMIN_COMMENT]["disabled"] = true;
      }
    } else if (flagData?.status?.value === "PAYMENT_IN_PROGRESS") {
      if (data[grievance_column_keys.ADMIN_COMMENT]) {
        data[grievance_column_keys.ADMIN_COMMENT]["disabled"] = true;
      }
      if (data[grievance_column_keys.CUSTOM_AMOUNT]) {
        data[grievance_column_keys.CUSTOM_AMOUNT]["disabled"] = true;
      }
    }
    if (
      flagData?.status?.value === "PAYMENT_PENDING" ||
      flagData?.status?.value === "PAYMENT_IN_PROGRESS"
    ) {
      data = {
        ...data,
        [grievance_column_keys.CUSTOM_AMOUNT]: {
          ...data?.[grievance_column_keys.CUSTOM_AMOUNT],
          value: data?.[grievance_column_keys.PAID_AMOUNT]?.value,
        },
      };
    }
    setUpdatedFlagData({ ...data });
  }, [flagData]);
  const onPaymentDetail = (data) => {
    let updatedData = { ...dealerFormData };
    let _updatedFlagData = { ...FlagSectionFormData };
    Object.keys(data)?.forEach((key) => {
      updatedData = {
        ...updatedData,
        [key]: {
          ...updatedData[key],
          value: data[key],
          disabled: true,
        },
      };
    });
    const filteredIssue = data?.issues?.find((issue) =>
      dealerPayoutOpenIssueStatuses.includes(issue.status)
    );
    if (filteredIssue) {
      Object.keys(filteredIssue)?.forEach((key) => {
        _updatedFlagData = {
          ..._updatedFlagData,
          [key]: {
            ..._updatedFlagData[key],
            value: filteredIssue[key],
          },
        };
      });
      setFlagData((items) => ({ ...items, ..._updatedFlagData }));
    } else {
      setFlagData({});
    }
    setDealerFormData((items) => ({ ...items, ...updatedData }));
    if (data && data.transactions && data.transactions.length) {
      setTransactionData(data.transactions);
    } else {
      setTransactionData([]);
    }
  };

  const isPayRoleEnabled = () => {
    let flag = false;
    const userRoles = getUserRoles();
    if (userRoles?.includes(USER_ROLE_KEYS.B2B_DEALER_PAYOUT_PAY)) {
      flag = true;
    }
    return flag;
  };

  return (
    <>
      <div className="ops-channel-page-bar">
        <PaymentActions isBackRequired={true} onBack={onBack} />
      </div>
      <div className="middleContent">
        <Form
          render={() => (
            <FormElement>
              <fieldset className={"k-form-fieldset"}>
                <legend
                  style={{
                    color: "#0d6efd",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  DEALER DETAILS
                </legend>
                <div className="create-form">
                  {Object.keys(dealerFormData).map((key) => {
                    const elementMetaData = dealerFormData[key];
                    if (
                      elementMetaData.elementtype === FORM_ELEMENT_TYPE.INPUT
                    ) {
                      return (
                        <FormInput
                          id={key}
                          key={key}
                          {...elementMetaData}
                          value={dealerFormData?.[key]?.value}
                        />
                      );
                    }
                  })}
                </div>
              </fieldset>
            </FormElement>
          )}
        />

        <legend
          style={{
            color: "#0d6efd",
            fontWeight: "600",
            fontSize: "16px",
            marginTop: "10px",
          }}
        >
          TRANSACTION LOGS
        </legend>
        <CustomGrid
          displayedColumns={getDisplayedCoulmns(TransactionLogColumns)}
          data={transactionData}
          count={10}
          columnMap={TransactionLogColumns}
        />

        {/* {(dealerPayoutOpenIssueStatuses?.includes(flagData?.status?.value) ||
          detailsData?.paymentStatus === "PAYMENT_IN_PROGRESS") && */}
        {dealerPayoutOpenIssueStatuses?.includes(flagData?.status?.value) &&
          isPayRoleEnabled() && (
            <FlagSection
              detailsData={detailsData}
              fetchPayouts={fetchPayouts}
              updatedFlagData={updatedFlagData}
              setUpdatedFlagData={setUpdatedFlagData}
              leadId={leadId}
            />
          )}
        <GrievanceLog detailsData={detailsData} />
      </div>
    </>
  );
};
export default PaymentDetail;
