import { Button } from "@progress/kendo-react-buttons";
import React, { useEffect, useState } from "react";
import { KendoCard } from "../../../../../../components";
import { getBadge } from "../../pr-automation/viewModel";
import { getPrStatus } from "../../../negotiation/pr-automation/viewModel";
import { fetchPrList } from "../../../../../../store/actions/pr-automation";
import { useSelector, useDispatch } from "react-redux";
import { getItemCreatedBy } from "../get-item-created-by";
import { formatDateTime } from "../../../../../../utils/utils";

const OtherPrs = (props) => {
	const { toggleApprovals } = props || {};
	const dispatch = useDispatch();
	const prDetails = useSelector((state) => state.prAutomation);
	const [filteredPrList, setFilteredPrList] = useState([]);
	const { prList, orderData: { purchase: { purchaseRequestId } = {} } = {} } =
		prDetails || {};
	const lms = useSelector((state) => state.lms);
	const { appointmentId } = lms || {};
	useEffect(() => {
		let list = [];
		if (prList && prList.length) {
			list = prList.filter(
				(item) => item.purchaseRequestId !== purchaseRequestId
			);
		}
		setFilteredPrList(list);
	}, [prList, purchaseRequestId]);
	useEffect(() => {
		if (appointmentId) {
			dispatch(fetchPrList({ appointmentId }));
		}
	}, [appointmentId]);
	const renderSubTitle = (item) => {
		return (
			<div>
				<span className="k-font-normal">Created by: </span>
				<span className="k-font-medium">{getItemCreatedBy(item)}</span>
			</div>
		);
	};
	return (
		<KendoCard
			headerTitle={"Other purchase requests"}
			titleStyle={{ fontSize: "14px", fontWeight: "500" }}
			details={
				<>
					{filteredPrList && filteredPrList.length ? (
						filteredPrList.map((item, index) => {
							const status = getPrStatus(item.purchaseStatus);
							return (
								<KendoCard
									headerTitle={
										<>
											<span className="k-font-medium k-mr-2">
												{formatDateTime(item.createdDate)}
											</span>
											<span className="k-mr-2">
												{getBadge(status?.text, status?.color)}
											</span>
											<Button
												themeColor="primary"
												icon="arrow-chevron-right"
												// onClick={toggleApprovals} ***//todo disable route to approval page
												size={"small"}
												fillMode={"flat"}
												disabled={true}
											/>
										</>
									}
									headerSubtitle={renderSubTitle(item)}
									cardStyle={{
										background: "#F0F2F5",
										marginTop: !index ? "0px" : "15px",
									}}
								/>
							);
						})
					) : (
						<div>No data found</div>
					)}
				</>
			}
		/>
	);
};

export default OtherPrs;
