import React from "react";
import styles from "./style.module.css";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { WORD_LIMIT_TRUNCATE } from "../../utils/constants/values.constants";

export const truncateText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

const CellTooltip = (props) => {
  const { label, value, maxLength = WORD_LIMIT_TRUNCATE.LIMIT } = props || {};

  const updatedValue = truncateText(value, maxLength);

  const TooltipContentTemplate = (props) => {
    return <div className={styles.tooltipTitle}>{props.title}</div>;
  };

  const KendoToolTip = ({ title, text, position = "right" }) => {
    return (
      <Tooltip
        className={styles.tooltip}
        anchorElement="target"
        position={position}
        content={() => <TooltipContentTemplate title={title} />}
      >
        <span className={styles.cellTooltipValue} title={title}>
          {text}
        </span>
      </Tooltip>
    );
  };

  return (
    <div>
      {!!label && <span className={styles.cellTooltipTitle}>{label}</span>}
      {updatedValue?.length > maxLength ? (
        <KendoToolTip title={value} text={updatedValue} position={"right"} />
      ) : (
        <span className={styles.cellTooltipValue}>{value}</span>
      )}
    </div>
  );
};

export default CellTooltip;
