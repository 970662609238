export const vahan_column_keys = {
  NAME: "details",
  LABEL: "label",
  FROM_INSPECTION: "fromInspection",
  FROM_VAHAN_QC: "fromVahanQc",
  MISMATCH: "vahanInspectionMismatch",
  FROM_RC_CHECK: "fromRcCheck",
  FROM_VAHAN: "fromVahan",
};
export const other_vahan_column_keys = {
  DETAILS: "details",
  ITEM: "item",
};

export const vahan_columns = {
  [vahan_column_keys.LABEL]: {
    field: vahan_column_keys.LABEL,
    label: "Details",
  },
  [vahan_column_keys.FROM_INSPECTION]: {
    field: vahan_column_keys.FROM_INSPECTION,
    label: "From Inspection",
  },
  [vahan_column_keys.FROM_VAHAN_QC]: {
    field: vahan_column_keys.FROM_VAHAN_QC,
    label: "From Vahan QC",
  },
  [vahan_column_keys.FROM_VAHAN]: {
    field: vahan_column_keys.FROM_VAHAN,
    label: "From Vahan",
  },
};
export const other_vahan_columns = {
  [other_vahan_column_keys.ITEM]: {
    field: other_vahan_column_keys.ITEM,
    label: "Item",
  },
  [other_vahan_column_keys.DETAILS]: {
    field: other_vahan_column_keys.DETAILS,
    label: "Details",
  },
};

export const getDisplayedCoulmns = (columns) => {
  return Object.keys(columns);
};
