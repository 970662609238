// import { CustomLinkItem } from "../../../common/grid-cells";
import {
  CHALLAN_REQUEST_STATUSES,
  //   LOCAL_STORAGE_KEYS,
  FF_PAGE_TYPES,
} from "../../../utils/constants/values.constants";
import { USER_ROLE_KEYS } from "../../../utils/constants/menu.constants";
import {
  //   convertObjectToParams,
  formatDateV2,
  showToastMessage,
  getStatus,
  //   getFromLocalStorage,
  getPageName,
  //   shortenString,
  getUserRoles,
} from "../../../utils/utils";
import { ChallanService } from "../../../services";
// import { useState } from "react";
// import { Button } from "@progress/kendo-react-buttons";
// import { Dialog } from "@progress/kendo-react-dialogs";
// import AddDelay from "../components/challan-detail-revamp/component/add-delay";

export const EDIT_FIELD_KEY = "inEdit";
export const EXPAND_FIELD_KEY = "expanded";
export const SELECTED_FIELD_KEY = "selected";
export const challan_request_keys = {
  REQUEST_ID: "idChallanRequest",
  CHALLAN_SOURCE_ID: "fkChallanSource",
  CHALLAN_SOURCE: "sourceCode",
  APPOINTMENT_ORDER_ID: "appointmentOrderId",
  REG_NUMBER: "regNumber",
  RETAIL_REGION: "retailRegion",
  PROACTIVE: "proactive",
  STATUS: "status",
  ASSIGN_TO: "assignedTo",
  CHALLAN_COUNT: "itemCount",
  DESCRIPTION: "description",
  CREATED_BY: "createdBy",
  CREATED_AT: "createdAt",
  AGEING: "ageing",
  PROOF: "challanProof",
  ATTEMP_COUNT: "attemptCount",
  DELAY_REASON: "delayReason",
};

// const appointmentIdCell = (props) => {
//   return (
//     <CustomLinkItem
//       {...props}
//       withTooltip={false}
//       dataLink={`/operations/challans/${props.dataItem.appointmentId}`}
//       dataText={props.dataItem?.appointmentId}
//     />
//   );
// };
export const CreationDateCell = (event) => {
  return (
    <td className="td-custom-text">
      {formatDateV2(event?.dataItem?.createdAt)}
    </td>
  );
};
const onDownloadFile = (id, fileName) => {
  if (!!id && !!fileName) {
    ChallanService.fetchUploadProofFileLink(id)
      .then((resp) => {
        resp?.detail && window.open(resp?.detail, "_blank", "noopener");
      })
      .catch((err) => {
        console.log(err);
        showToastMessage(err?.message || err?.error, false);
      });
  }
};
const ProofCell = (event) => {
  const fileName = (event.dataItem?.challanProof || "").replace(
    /^.*[\\\/]/,
    ""
  );
  return (
    <td
      className="hyper-link"
      onClick={() => onDownloadFile(event.dataItem?.idChallanRequest, fileName)}
    >
      {fileName}
    </td>
  );
};

export const StatusCell = (event) => {
  const status = getStatus(event.dataItem?.status);
  return (
    <td>
      <span className={status?.css}>{status?.label}</span>
    </td>
  );
};

export const challan_request_columns = {
  [challan_request_keys.REG_NUMBER]: {
    field: challan_request_keys.REG_NUMBER,
    label: "REG NUMBER",
    width: "120px",
  },
  [challan_request_keys.APPOINTMENT_ORDER_ID]: {
    field: challan_request_keys.APPOINTMENT_ORDER_ID,
    label: "APPT ID/ORDER ID",
    width: "200px",
  },
  [challan_request_keys.CHALLAN_SOURCE]: {
    field: challan_request_keys.CHALLAN_SOURCE,
    label: "SOURCE",
    width: "80px",
  },
  [challan_request_keys.STATUS]: {
    cell: StatusCell,
    label: "STATUS",
    width: "120px",
    field: challan_request_keys.STATUS,
  },
  [challan_request_keys.RETAIL_REGION]: {
    field: challan_request_keys.RETAIL_REGION,
    label: "RETAIL REGION",
    width: "140px",
  },
  [challan_request_keys.ASSIGN_TO]: {
    field: challan_request_keys.ASSIGN_TO,
    label: "FINDER",
    width: "250px",
  },
  [challan_request_keys.CREATED_AT]: {
    cell: CreationDateCell,
    label: "CREATION DATE",
    width: "200px",
  },
  [challan_request_keys.CHALLAN_COUNT]: {
    field: challan_request_keys.CHALLAN_COUNT,
    label: "NO OF CHALLAN",
    width: "150px",
  },
  [challan_request_keys.AGEING]: {
    field: challan_request_keys.AGEING,
    label: "AGEING",
    width: "120px",
  },
  [challan_request_keys.ATTEMP_COUNT]: {
    field: challan_request_keys.ATTEMP_COUNT,
    label: "Attemp Count",
    width: "120px",
  },
  [challan_request_keys.PROOF]: {
    cell: ProofCell,
    label: "Proof",
    width: "200px",
  },
  [challan_request_keys.DESCRIPTION]: {
    field: challan_request_keys.DESCRIPTION,
    label: "DESCRIPTION",
    width: "200px",
  },
};

export const getDisplayedCoulmns = (columns) => {
  return Object.keys(columns);
};

export const getSelectedAssignRowIds = (rows, data) => {
  const Ids = Object.keys(rows).filter((key) => {
    const state = rows[key];
    const row = data.find((item) => item.idChallanRequest === parseInt(key));
    const status = row?.status.toUpperCase();
    if (
      state &&
      [
        CHALLAN_REQUEST_STATUSES.CREATED,
        CHALLAN_REQUEST_STATUSES.REOPENED,
      ].includes(status)
    ) {
      return key;
    }
  });
  return Ids;
};

export const getSelectedMarkAsResolvedRowIds = (rows, data) => {
  const Ids = Object.keys(rows).filter((key) => {
    const state = rows[key];
    const row = data.find((item) => item.idChallanRequest === parseInt(key));
    if (state) {
      return key;
    }
  });
  return Ids;
};

export const getPageTitle = () => {
  const userGroups = getUserRoles() || [];
  const pageName = getPageName();
  if (
    (userGroups.includes(USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN) &&
      pageName === FF_PAGE_TYPES.FINDERS) ||
    (userGroups.includes(USER_ROLE_KEYS.FINDER) &&
      pageName === FF_PAGE_TYPES.COMMON)
  ) {
    return FF_PAGE_TYPES.FINDERS;
  } else if (
    (userGroups.includes(USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN) &&
      pageName === FF_PAGE_TYPES.DISPOSE) ||
    (userGroups.includes(USER_ROLE_KEYS.DISPOSE) &&
      pageName === FF_PAGE_TYPES.COMMON)
  ) {
    return FF_PAGE_TYPES.DISPOSE;
  }
};
export const page_params = {
  pageNumber: 0,
  pageSize: 50,
};
