export const PR_AUTOMATION = {
  FETCH_PR_SUBMIT_LOADING_START: "FETCH_PR_SUBMIT_LOADING_START",
  FETCH_PR_SUBMIT_LOADING_END: "FETCH_PR_SUBMIT_LOADING_END",
  FETCH_DOC_LOADING_START: "FETCH_DOC_LOADING_START",
  FETCH_DOC_LOADING_END: "FETCH_DOC_LOADING_END",
  FETCH_LOADING_START: "FETCH_LOADING_START",
  FETCH_LOADING_END: "FETCH_LOADING_END",
  FETCH_DOWNLOAD_DOC_SUCCESS: "FETCH_DOWNLOAD_DOC_SUCCESS",
  FETCH_AUDIT_CHECKS_SUCCESS: "FETCH_AUDIT_CHECKS_SUCCESS",
  FETCH_AUDIT_CHECKS_FAILURE: "FETCH_AUDIT_CHECKS_FAILURE",
  FETCH_PR_DETAILS_SUCCESS: "FETCH_PR_DETAILS_SUCCESS",
  FETCH_PR_DETAILS_FAILURE: "FETCH_PR_DETAILS_FAILURE",
  FETCH_VAHAN_MISMATCH_SUCCESS: "FETCH_VAHAN_MISMATCH_SUCCESS",
  FETCH_VAHAN_MISMATCH_FAILURE: "FETCH_VAHAN_MISMATCH_FAILURE",
  FETCH_SERVICE_HISTORY_SUCCESS: "FETCH_SERVICE_HISTORY_SUCCESS",
  FETCH_SERVICE_HISTORY_FAILURE: "FETCH_SERVICE_HISTORY_FAILURE",
  FETCH_PR_C24QUOTE_SUCCESS: "FETCH_PR_C24QUOTE_SUCCESS",
  FETCH_PR_C24QUOTE_FAILURE: "FETCH_PR_C24QUOTE_FAILURE",
  FETCH_HYPO_BANKS_SUCCESS: "FETCH_HYPO_BANKS_SUCCESS",
  FETCH_HYPO_BANKS_FAILURE: "FETCH_HYPO_BANKS_FAILURE",
  FETCH_BUSINESS_APPROVAL_SUCCESS: "FETCH_BUSINESS_APPROVAL_SUCCESS",
  FETCH_BUSINESS_APPROVAL_FAILURE: "FETCH_BUSINESS_APPROVAL_FAILURE",
  FETCH_STATES_SUCCESS: "FETCH_STATES_SUCCESS",
  FETCH_STATES_FAILURE: "FETCH_STATES_FAILURE",
  FETCH_CITIES_SUCCESS: "FETCH_CITIES_SUCCESS",
  FETCH_CITIES_FAILURE: "FETCH_CITIES_FAILURE",
  FETCH_KYC_DOCS_SUCCESS: "FETCH_KYC_DOCS_SUCCESS",
  FETCH_KYC_DOCS_FAILURE: "FETCH_KYC_DOCS_FAILURE",
  FETCH_DOCS_STATUS_SUCCESS: "FETCH_DOCS_STATUS_SUCCESS",
  FETCH_DOCS_STATUS_FAILURE: "FETCH_DOCS_STATUS_FAILURE",
  FETCH_BUSINESS_APPROVAL_TYPES_SUCCESS:
    "FETCH_BUSINESS_APPROVAL_TYPES_SUCCESS",
  FETCH_BUSINESS_APPROVAL_TYPES_FAILURE:
    "FETCH_BUSINESS_APPROVAL_TYPES_FAILURE",
  FETCH_SLOTS_SUCCESS: "FETCH_SLOTS_SUCCESS",
  FETCH_SLOTS_FAILURE: "FETCH_SLOTS_FAILURE",
  FETCH_SERVICE_ELIGIBLE_SUCCESS: "FETCH_SERVICE_ELIGIBLE_SUCCESS",
  FETCH_SERVICE_ELIGIBLE_FAILURE: "FETCH_SERVICE_ELIGIBLE_FAILURE",
  FETCH_PR_LIST_SUCCESS: "FETCH_PR_LIST_SUCCESS",
  FETCH_PR_LIST_FAILURE: "FETCH_PR_LIST_FAILURE",
  FETCH_UPLOAD_AUTH_SUCCESS: "FETCH_UPLOAD_AUTH_SUCCESS",
  FETCH_UPLOAD_AUTH_FAILURE: "FETCH_UPLOAD_AUTH_FAILURE",
  FETCH_SLOTS_LOADING_START: "FETCH_SLOTS_LOADING_START",
  FETCH_SLOTS_LOADING_END: "FETCH_SLOTS_LOADING_END",
};
