import { vas_price_update_column_keys } from "../../viewModal";

export const vas_price_detail_form = {
    [vas_price_update_column_keys.PRODUCT_UINIQUE_IDENTIFIER]: {
        field: vas_price_update_column_keys.PRODUCT_UINIQUE_IDENTIFIER,
        label: "Identifier",
    },
    [vas_price_update_column_keys.CONTROL_PRICE]: {
        field: vas_price_update_column_keys.CONTROL_PRICE,
        label: "Control Price",
    },
    [vas_price_update_column_keys.VARIANT_PRICE]: {
        field: vas_price_update_column_keys.VARIANT_PRICE,
        label: "Variant Price",
    },
    [vas_price_update_column_keys.VARIANT_TWO_PRICE]: {
        field: vas_price_update_column_keys.VARIANT_TWO_PRICE,
        label: "Variant Two Price",
    },
    [vas_price_update_column_keys.DISCOUNT]: {
        field: vas_price_update_column_keys.DISCOUNT,
        label: "Discount",
    },
    [vas_price_update_column_keys.TAX_RATE]: {
        field: vas_price_update_column_keys.TAX_RATE,
        label: "Tax Rate",
    },
    [vas_price_update_column_keys.TAX_TYPE]: {
        field: vas_price_update_column_keys.TAX_TYPE,
        label: "Tax Type",
    },
    [vas_price_update_column_keys.STATUS]: {
        field: vas_price_update_column_keys.STATUS,
        label: "Status",
        value: "Active"
    },
}

export const VAS_PRICE_STATUS_DATA = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" }
]