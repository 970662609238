export const RETAIL_CONSUMER = {
  FETCH_LOADING_START: "FETCH_LOADING_START",
  FETCH_LOADING_END: "FETCH_LOADING_END",
  FETCH_LEAD_MON_CONFIG_SUCCESS: "FETCH_LEAD_MON_CONFIG_SUCCESS",
  FETCH_LEAD_MON_CONFIG_FAILURE: "FETCH_LEAD_MON_CONFIG_FAILURE",
  SET_LEAD_TYPE_FILTER: "SET_LEAD_TYPE_FILTER",
  FETCH_MAKERS_SUCCESS: "FETCH_MAKERS_SUCCESS",
  FETCH_MODEL_SUCCESS: "FETCH_MODEL_SUCCESS",
  RESET_MODEL: "RESET_MODEL",
  FETCH_VARIANT_SUCCESS: "FETCH_VARIANT_SUCCESS",
  FETCH_YEAR_SUCCESS: "FETCH_YEAR_SUCCESS",
  RESET_YEAR: "RESET_YEAR",
  FETCH_CITY_SUCCESS: "FETCH_CITY_SUCCESS",
  FETCH_LOCATION_SUCCESS: "FETCH_LOCATION_SUCCESS",
  RESET_LOCATION: "RESET_LOCATION",
  RESET_LEAD_MON_CONFIG: "RESET_LEAD_MON_CONFIG",
};
