import { FormComboBox, FormDatePicker } from "../../../../../../components";
import { challan_item_keys } from "../../../../components/challan-detail-revamp/viewModel";
import { Button } from "@progress/kendo-react-buttons";
import { useState, useEffect } from "react";
import { dispose_filters_formData, DD_DATA_SOURCES } from "./viewModel";
import { FORM_ELEMENT_TYPE } from "../../../../../../utils/constants/values.constants";
import { ChallanService } from "../../../../../../services";
import { formatDate } from "../../../../../../utils/utils";
import { updateChallanItemFiltersParams } from "../../../../../../store/actions/ops-challan";
import { useDispatch } from "react-redux";
const DisposeFilters = (props) => {
  const [isFilteringComplete, setIsFilteringComplete] = useState(false);
  const [filters, setFilters] = useState(dispose_filters_formData);
  const [sources, setSources] = useState(DD_DATA_SOURCES);
  const dispatch = useDispatch();
  const onInputChange = (e) => {
    const { value } = e;
    const { id } = e.target.props;
    const key = id || e.target.name;
    setFilters((items) => ({
      ...items,
      [key]: { ...filters[key], value: value },
    }));
    if (key === challan_item_keys.REGION) {
      setSources((items) => ({
        ...items,
        [challan_item_keys.CHALLAN_COURT]: [],
      }));
    }
  };

  useEffect(() => {
    ChallanService.fetchChallanRegions().then((resp) => {
      const rgns = resp.detail?.map((item) => {
        return {
          label: item.regionName,
          value: item.id,
        };
      });
      setSources((items) => ({
        ...items,
        [challan_item_keys.REGION]: rgns,
      }));
    });
    ChallanService.fetchAllOffenses().then((resp) => {
      const srcs = resp?.detail?.map((item) => {
        return {
          value: item.idChallanOffence,
          label: item.offenceName,
        };
      });
      setSources((offenses) => ({
        ...offenses,
        [challan_item_keys.CHALLAN_OFFENSE_ID]: srcs,
      }));
    });
    ChallanService.fetchAgentsByCourt().then((resp) => {
      const agents = resp.detail?.map((item) => {
        return {
          label: item.agentName,
          value: item.id,
        };
      });
      setSources((items) => ({
        ...items,
        [challan_item_keys.AGENT_NAME]: agents,
      }));
    });
  }, []);
  useEffect(() => {
    const region = filters[challan_item_keys.REGION]?.value?.value;
    ChallanService.fetchCourtsByRegion(region).then((resp) => {
      const courts = resp.detail?.map((item) => {
        return {
          label: item.courtName,
          value: item.id,
        };
      });
      setSources((items) => ({
        ...items,
        [challan_item_keys.CHALLAN_COURT]: courts,
      }));
    });
  }, [filters[challan_item_keys.REGION]?.value]);

  const onFilterApply = () => {
    let filterParams = {};
    Object.values(filters).map((item) => {
      const itemValue = item?.value;
      if (!!itemValue && item.elementtype === FORM_ELEMENT_TYPE.SELECT) {
        filterParams = {
          ...filterParams,
          [item.name]: [
            challan_item_keys.CHALLAN_COURT,
            challan_item_keys.REGION,
            challan_item_keys.AGENT_NAME,
          ].includes(item.name)
            ? item?.value?.label
            : item?.value?.value || "",
        };
      } else if (!!itemValue && item.elementtype === FORM_ELEMENT_TYPE.DATE) {
        filterParams = {
          ...filterParams,
          [item.name]: itemValue ? formatDate(item?.value) : "",
        };
      }
    });
    dispatch(updateChallanItemFiltersParams(filterParams));
  };
  return (
    <>
      <div className="ops-dispose-filters">
        {Object.keys(filters).map((key) => {
          const elementMetaData = filters[key];
          switch (elementMetaData.elementtype) {
            case FORM_ELEMENT_TYPE.SELECT:
              return (
                <div className="ops-field-filter">
                  <FormComboBox
                    id={key}
                    key={key}
                    onChange={onInputChange}
                    data={sources[key]}
                    textField="label"
                    dataItemKey="value"
                    {...elementMetaData}
                  />
                </div>
              );
            case FORM_ELEMENT_TYPE.DATE:
              return (
                <div className="ops-field-filter">
                  <FormDatePicker
                    id={key}
                    key={key}
                    onChange={onInputChange}
                    {...elementMetaData}
                  />
                </div>
              );
            default:
              return <></>;
          }
        })}
        <div className="ops-channel-filter-action">
          <Button
            themeColor={"primary"}
            onClick={onFilterApply}
            disabled={isFilteringComplete}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  );
};
export default DisposeFilters;
