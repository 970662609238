import {
	FORM_ELEMENT_TYPE,
	CHALLAN_MODE_TYPES,
} from "../../../../utils/constants/values.constants";
import {
	FormInput,
	FormComboBox,
	UploadFile,
	FormInputTick,
	FormRadioGroup,
} from "../../../../components";
import { challan_item_keys } from "../challan-detail-revamp/viewModel";
import { useEffect, useState } from "react";
import {
	FORM_TYPES,
	INIT_DD_DATA_SOURCES,
	PAYMENT_STATUSES,
} from "./viewModel";
import { ChallanService } from "../../../../services";
import { FF_REASON_TYPES } from "../../dispose-container/viewModal";
const ChallanItemPopup = (props) => {
	const [sourecs, setSourecs] = useState(INIT_DD_DATA_SOURCES);
	const [currentRegion, setCurrentRegion] = useState();
	const [currentCourt, setCurrentCourt] = useState();
	const onInputChange = (e) => {
		const { value } = e;
		const { id } = e.target.props || {};
		const key = id || e.target.name;
		props.updateCurrentPopupData({
			key,
			value: e.target.type === "file" ? e.target.files[0] : value,
			isFile: e.target.type === "file",
			isHideRequired: false,
		});
		if (key === challan_item_keys.REGION) {
			setCurrentRegion(value);
			setSourecs({
				...sourecs,
				[challan_item_keys.CHALLAN_COURT]: [],
			});
		} else if (key === challan_item_keys.CHALLAN_COURT) {
			setCurrentCourt(value);
		}
	};

	const handleRadioChange = (e, key) => {
		const { value } = e || {};

		props.updateCurrentPopupData({
			key,
			value: value,
			isFile: false,
			isHideRequired: true,
		});
	};
	const onInputLostFocus = (e) => {
		if (e.target.value !== "_") {
			ChallanService.validateRegNumber(
				props?.dataItem?.idChallanItem,
				e.target.value
			)
				.then((resp) => {
					props.showTickRegNumber(e.target.name, false);
				})
				.catch((err) => props.showTickRegNumber(e.target.name, false));
		}
	};
	useEffect(() => {
		let statuses = INIT_DD_DATA_SOURCES[challan_item_keys.CHALLAN_TYPE];
		if (props?.dataItem?.type === CHALLAN_MODE_TYPES.VIRTUAL_COURT) {
			statuses = [
				...statuses,
				{
					label: "Virtual Court Proceeding",
					value: PAYMENT_STATUSES.VIRTUAL_COURT_PROCEEDING,
				},
			];
			setSourecs({
				...sourecs,
				[challan_item_keys.CHALLAN_TYPE]: statuses,
			});
		}
	}, []);
	useEffect(() => {
		const isDataFetch =
			([
				FORM_TYPES.ASSIGN_AGENT,
				FORM_TYPES.ADMIN_RE_ASSIGN_AGENT_DISPOSE,
			].includes(props.action) &&
				!!props?.dataItem?.idChallanItem) ||
			FORM_TYPES.ADMIN_BULK_ASSIGN_AGENT_DISPOSE === props.action;
		if (isDataFetch) {
			ChallanService.fetchChallanRegions().then((resp) => {
				const rgns = resp.detail?.map((item) => {
					return {
						label: item.regionName,
						value: item.id,
					};
				});
				const regionName = props.dataItem[challan_item_keys.REGION];
				if (!!regionName) {
					const region = rgns.find((item) => item.label === regionName);
					setCurrentRegion(region);
					props.formData[challan_item_keys.REGION].value = region;
				}
				setSourecs((items) => ({
					...items,
					[challan_item_keys.REGION]: rgns,
				}));
			});
			//
			ChallanService.fetchAgentsByCourt().then((resp) => {
				const agents = resp.detail?.map((item) => {
					return {
						label: item.agentName,
						value: item.id,
					};
				});
				const agentName = props.dataItem[challan_item_keys.AGENT_NAME];
				if (!!agentName) {
					const agent = agents.find((item) => item.label === agentName);
					console.log({ agent });
					props.formData[challan_item_keys.AGENT_NAME].value = agent;
				}
				setSourecs((items) => ({
					...items,
					[challan_item_keys.AGENT_NAME]: agents,
				}));
			});
		}
		if ([FORM_TYPES.ADMIN_ASSIGN_AGENT_DISPOSE].includes(props.action)) {
			ChallanService.fetchDisposeUsers().then((resp) => {
				const disposers = resp.detail?.map((item) => {
					return {
						label: item,
						value: item,
					};
				});
				const selectedAgent = props.dataItem[challan_item_keys.ASSIGN_TO];
				if (!!selectedAgent) {
					const agent = disposers.find((item) => item.label === selectedAgent);
					props.formData[challan_item_keys.ASSIGN_TO].value = agent;
				}
				setSourecs((items) => ({
					...items,
					[challan_item_keys.ASSIGN_TO]: disposers,
				}));
			});
		}
		if (
			[
				FORM_TYPES.ADMIN_ASSIGN_AGENT_FINDER,
				FORM_TYPES.ADMIN_BULK_ASSIGN_AGENT_DISPOSE,
			].includes(props.action)
		) {
			ChallanService.fetchFinderUsers().then((resp) => {
				const disposers = resp.detail?.map((item) => {
					return {
						label: item,
						value: item,
					};
				});
				const assignTo = props.dataItem[challan_item_keys.ASSIGN_TO];
				if (!!assignTo) {
					const agent = disposers.find((item) => item.label === assignTo);
					props.formData[challan_item_keys.ASSIGN_TO].value = agent;
				}
				setSourecs((items) => ({
					...items,
					[challan_item_keys.ASSIGN_TO]: disposers,
				}));
			});
		}
		if (props.action === FORM_TYPES.REJECT_CHALLAN) {
			ChallanService.fetchReasons(FF_REASON_TYPES.REJECTED).then((resp) => {
				const reasons = resp.detail?.map((item) => {
					return {
						label: item.reason,
						value: item.reason,
					};
				});
				setSourecs((items) => ({
					...items,
					[challan_item_keys.REMARKS]: reasons,
				}));
			});
		}
	}, [props.action]);
	useEffect(() => {
		if (
			[
				FORM_TYPES.ASSIGN_AGENT,
				FORM_TYPES.ADMIN_RE_ASSIGN_AGENT_DISPOSE,
				FORM_TYPES.ADMIN_BULK_ASSIGN_AGENT_DISPOSE,
			].includes(props.action) &&
			!!currentRegion?.value
		) {
			ChallanService.fetchCourtsByRegion(currentRegion.value).then((resp) => {
				const courts = resp.detail?.map((item) => {
					return {
						label: item.courtName,
						value: item.id,
					};
				});
				const courtName = props.dataItem[challan_item_keys.CHALLAN_COURT];
				if (!!courtName) {
					const court = courts.find((item) => item.label === courtName);
					setCurrentCourt(court);
					props.formData[challan_item_keys.CHALLAN_COURT].value = court;
				}
				setSourecs((items) => ({
					...items,
					[challan_item_keys.CHALLAN_COURT]: courts,
				}));
			});
		}
	}, [currentRegion]);

	return (
		<div>
			{Object.keys(props.formData).map((key) => {
				const elementMetaData = props.formData[key];
				if (!!elementMetaData.elementtype && !elementMetaData.hide) {
					switch (elementMetaData.elementtype) {
						case FORM_ELEMENT_TYPE.SELECT:
							return (
								<FormComboBox
									id={key}
									key={key}
									onChange={onInputChange}
									data={sourecs[key]}
									textField="label"
									dataItemKey="value"
									value={props.formData[key]?.value || null}
									{...elementMetaData}
								/>
							);
						case FORM_ELEMENT_TYPE.UPLOAD_FILE:
							return (
								<UploadFile
									id={key}
									key={key}
									onChange={onInputChange}
									{...elementMetaData}
								/>
							);
						case FORM_ELEMENT_TYPE.INPUT_RIGHT_TICK:
							return (
								<FormInputTick
									id={key}
									key={key}
									onChange={onInputChange}
									onBlur={onInputLostFocus}
									value={props.formData[key]?.value || ""}
									{...elementMetaData}
								/>
							);
						case FORM_ELEMENT_TYPE.RADIO:
							return (
								<div className="search-tag k-form-buttons">
									<FormRadioGroup
										data={props.formData[key]?.data}
										onChange={(e) => handleRadioChange(e, key)}
										{...elementMetaData}
										key={key}
										id={key}
										name={key}
									/>
								</div>
							);
						default:
							return (
								<FormInput
									id={key}
									key={key}
									onChange={onInputChange}
									value={props.formData[key]?.value || ""}
									{...elementMetaData}
								/>
							);
					}
				}
			})}
		</div>
	);
};
export default ChallanItemPopup;
