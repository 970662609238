import SegmentationForm from '../components/segment-form';
import Modal from '../../components/modal'


const UpdateSegmentation = ({ dataItem, setIsModalOpen, isModalOpen }) => {
  return (
    <Modal isOpen={isModalOpen} close={() => setIsModalOpen(false)} heading="Update Segmentation">
      <SegmentationForm
        isEdit
        initialValues={dataItem}
        setIsModalOpen={setIsModalOpen}
      />
    </Modal>
  );
};
export default UpdateSegmentation;