import { getFFUserType } from "../utils/utils";
export default (api) => {
	const fetchAllIncentives = (params) => {
		return new Promise((resolve, reject) => {
			api
				.get(`api/incentive/list${params}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const addIncentive = (payload) => {
		return new Promise((resolve, reject) => {
			api
				.post(`/api/incentive`, payload, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const updateIncentive = (id, payload) => {
		return new Promise((resolve, reject) => {
			api
				.put(`/api/incentive/${id}`, payload, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const fetchIncentiveDetails = (id) => {
		return new Promise((resolve, reject) => {
			api
				.get(`/api/incentive/${id}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const fetchAllClusters = (params) => {
		return new Promise((resolve, reject) => {
			api
				.get(`/api/v1/cluster/list`, {
					params: params,
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const fetchAllCities = () => {
		return new Promise((resolve, reject) => {
			api
				.get(`/api/v1/cities`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const fetchAllStores = (params) => {
		return new Promise((resolve, reject) => {
			api
				.get(`/api/v1/stores`, {
					params: params,
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	// api/v1/metrics/list
	const fetchAllMetrics = (params) => {
		return new Promise((resolve, reject) => {
			api
				.get(`/api/v1/metrics/list`, {
					params: params,
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const addCluster = (payload) => {
		return new Promise((resolve, reject) => {
			api
				.post(`/api/v1/cluster`, payload, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const addIncentiveTargetFile = (id, payload) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`/api/v1/incentive/target/upload/${id}`, payload, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const validateExpression = (expression, type) => {
		const payload = {
			conditionExpression: expression,
			expressionType: type,
		};
		return new Promise((resolve, reject) => {
			api
				.post(`/api/incentive/validate`, payload, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const fetchClustersByName = (params) => {
		return new Promise((resolve, reject) => {
			api
				.get(`/api/v1/clusters`, {
					params: params,
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const fetchStoreIdByCluster = (payload) => {
        return new Promise((resolve, reject) => {
            api
                .get(`/api/v1/cluster/${payload}`, {
                    headers: { "user-type": `${getFFUserType()}` },
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

	return {
		addIncentiveTargetFile,
		fetchAllIncentives,
		addIncentive,
		updateIncentive,
		fetchIncentiveDetails,
		fetchAllClusters,
		fetchAllCities,
		fetchAllStores,
		addCluster,
		fetchAllMetrics,
		validateExpression,
		fetchClustersByName,
		fetchStoreIdByCluster
	};
};
