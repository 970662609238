import React from 'react';
import { Editor, EditorTools } from '@progress/kendo-react-editor';

const {
	Bold, Italic, Underline, AlignLeft, AlignCenter, AlignRight, AlignJustify, OrderedList, UnorderedList
} = EditorTools;

const RichTextEditor = ({ field, form }) => {
	return (
		<div>
			<Editor
				tools={[
					[Bold, Italic, Underline],
					[AlignLeft, AlignCenter, AlignRight, AlignJustify],
					[OrderedList, UnorderedList]
				]}
				contentStyle={{ height: 200 }}
				value={field.value}
				onChange={(e) => form.setFieldValue(field.name, e.html)}
			/>
		</div>
	);
};

export default RichTextEditor;
