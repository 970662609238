import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect, useState } from "react";
import LayoutStatusBanner from "../../components/layout-status-banner";
import {
	VERIFICATION_PAYMENT_FORM_DATA,
	verification_detail_list_keys,
} from "./viewModal";
import DetailCardLayout from "../../components/layout-detail-card";
import {
	NUMBERS,
	VERIFICATION_SPREADSHEET_URL,
} from "../../../../utils/constants/values.constants";
import { SCREEN_TYPE, verification_column_keys } from "../viewModal";
import CellTooltip from "../../../../common/cell-tooltip";
import { formatDateV2, showToastMessage } from "../../../../utils/utils";
import FormCardLayout from "../../components/layout-form-card";
import { VerficationService } from "../../../../services";
import FinderProofView from "../../components/layout-multiple-finder-proof";
import { challan_item_keys } from "../../components/challan-detail-revamp/viewModel";
import { updateVerificationItemRow } from "../../../../store/actions/ops-verification";
import { useDispatch } from "react-redux";
const renderAgentCostingLink = () => {
	const spreadsheetLink = VERIFICATION_SPREADSHEET_URL;
	return (
		<a href={spreadsheetLink} target="_blank" rel="noopener noreferrer">
			Agent costing sheet.xls
		</a>
	);
};

const VerificationDetailContainer = (props) => {
	const { idChallanVerification, setScreen } = props;
	const [verificationInfo, setVerificationInfo] = useState(
		verification_detail_list_keys
	);
	const [formData, setFormData] = useState(VERIFICATION_PAYMENT_FORM_DATA);
	const [rejectionReason, setRejectionReason] = useState([]);
	const [verificationData, setVerificationData] = useState();
	const [refresh, setRefresh] = useState(false);
	const dispatch = useDispatch();
	const RefreshData = () => {
		if (refresh) {
			VerficationService.fetchChallanItems(idChallanVerification)
				.then((resp) => {
					let updatedRes = resp?.detail["challan-item"] || {};
					let verificationRes = resp?.detail["verification-item"] || {};
					let updatedList = verification_detail_list_keys?.map((item) => ({
						...item,
						value: [
							verification_column_keys.CLOSURE_DATE,
							verification_column_keys.VERIFICATION_AGENT,
							verification_column_keys.RAISED_BY,
							verification_column_keys.VERIFIED_BY,
						].includes(item?.field)
							? verificationRes?.[item?.field]
							: updatedRes?.[item?.field],
					}));
					dispatch(updateVerificationItemRow(verificationRes));
					setVerificationInfo(updatedList);
					setVerificationData(resp?.detail);
				})
				.finally(() => {
					setRefresh(false);
				});
		}
	};

	useEffect(() => {
		VerficationService.fetchAllVerificationRejectReason()
			.then((res) => {
				const reasons = res.detail?.map((item) => {
					return {
						label: item.reason,
						value: item.reason,
					};
				});
				setRejectionReason(reasons);
			})
			.catch((err) => showToastMessage(err?.message));
	}, []);

	useEffect(() => {
		setRefresh(true);
	}, [idChallanVerification]);

	useEffect(() => {
		RefreshData();
	}, [refresh]);

	useEffect(() => {
		const updatedChallanRequestInfo = verificationInfo?.map((item) => ({
			...item,
			isCell: true,
			cell: () => (
				<CellTooltip
					value={
						[
							"offenceDate",
							challan_item_keys.PAYMENT_DATE,
							verification_column_keys.CLOSURE_DATE,
						].includes(item?.field)
							? formatDateV2(item?.value)
							: item?.value
					}
					position="right"
				/>
			),
			...(item?.field === "agentCostingLink" && {
				cell: renderAgentCostingLink,
			}),
			...(item?.field === "challanPdfUrl" && {
				cell: renderFinderProofLink,
			}),
		}));
		setVerificationInfo(updatedChallanRequestInfo);
		let updatedFormData = { ...formData };
		const updatedRes = verificationData?.["challan-item"] || {};
		updatedFormData = {
			...updatedFormData,
			[verification_column_keys.REG_NUMBER]: {
				...updatedFormData[verification_column_keys.REG_NUMBER],
				value: updatedRes[verification_column_keys.REG_NUMBER],
			},
			[verification_column_keys.CHALLAN_AMOUNT]: {
				...updatedFormData[verification_column_keys.CHALLAN_AMOUNT],
				value: updatedRes[verification_column_keys.CHALLAN_AMOUNT],
			},
			[verification_column_keys.CHALLAN_MODE]: {
				...updatedFormData[verification_column_keys.CHALLAN_MODE],
				value: updatedRes[verification_column_keys.CHALLAN_MODE],
			},
			[verification_column_keys.STATUS]: {
				...updatedFormData[verification_column_keys.STATUS],
				value: updatedRes[verification_column_keys.STATUS],
			},
			[verification_column_keys.AGENT_FESS]: {
				...updatedFormData[verification_column_keys.AGENT_FESS],
				value: updatedRes[verification_column_keys.AGENT_FESS],
			},
			[verification_column_keys.SETTLEMENT_AMOUNT]: {
				...updatedFormData[verification_column_keys.SETTLEMENT_AMOUNT],
				value: updatedRes[verification_column_keys.SETTLEMENT_AMOUNT],
			},
			[verification_column_keys.FILE_SINGLE_UPLOAD]: {
				...updatedFormData[verification_column_keys.FILE_SINGLE_UPLOAD],
				handleFileView: () => {
					handleDownloadProof(
						verification_column_keys.FILE_SINGLE_UPLOAD,
						updatedRes?.idChallanItem
					);
				},
			},
			[verification_column_keys.GOVERNMENT_FEE_PROOF]: {
				...updatedFormData[verification_column_keys.GOVERNMENT_FEE_PROOF],
				handleFileView: () => {
					handleDownloadProof(
						verification_column_keys.GOVERNMENT_FEE_PROOF,
						updatedRes?.idChallanItem
					);
				},
			},
			[verification_column_keys.EXCEPTION_PROOF]: {
				...updatedFormData[verification_column_keys.EXCEPTION_PROOF],
				handleFileView: () => {
					handleDownloadProof(
						verification_column_keys.EXCEPTION_PROOF,
						updatedRes?.idChallanItem
					);
				},
				isCheckedFile: !updatedRes?.exceptionProof,
			},

			[verification_column_keys.VERIFICATION_STATUS]: {
				...updatedFormData[verification_column_keys.VERIFICATION_STATUS],
				value: ["ACCEPTED", "REJECTED", "PENDING"].includes(
					verificationData?.["verification-item"]?.status
				)
					? {
						label: verificationData?.["verification-item"]?.status,
						value: verificationData?.["verification-item"]?.status,
					}
					: {},
				disabled: !["ASSIGNED", "PENDING"].includes(
					verificationData?.["verification-item"]?.status
				),
			},
			[verification_column_keys.VERIFICATION_REJECTION_REASON]: {
				...updatedFormData[
				verification_column_keys.VERIFICATION_REJECTION_REASON
				],
				value: {
					label: verificationData?.["verification-item"]?.remarks,
					value: verificationData?.["verification-item"]?.remarks,
				},
				hide: verificationData?.["verification-item"]?.status !== "REJECTED",
				source: rejectionReason,
				disabled: !["ASSIGNED", "PENDING"].includes(
					verificationData?.["verification-item"]?.status
				),
			},
		};
		setFormData(updatedFormData);
	}, [verificationData]);

	const onInputChange = (e) => {
		const { value } = e;
		const { id } = e.target.props || {};
		const key = id || e.target.name;
		updateCurrentFormData({
			key,
			value: e.target.type === "file" ? e.target.files[0] : value,
			isFile: e.target.type === "file",
		});
	};

	const updateCurrentFormData = ({ key, value, isFile = false }) => {
		let updatedPopupData = {
			...formData,
			[key]: {
				...formData[key],
				...{ [isFile ? "file" : "value"]: value },
			},
		};
		if (key === verification_column_keys.VERIFICATION_STATUS) {
			updatedPopupData = {
				...updatedPopupData,
				[verification_column_keys.VERIFICATION_REJECTION_REASON]: {
					...updatedPopupData[
					verification_column_keys.VERIFICATION_REJECTION_REASON
					],
					hide: value?.value !== "REJECTED",
					source: rejectionReason,
					value: null,
				},
			};
		}
		setFormData(updatedPopupData);
	};

	const handleDownloadProof = (file, key) => {
		if (file === verification_column_keys.FILE_SINGLE_UPLOAD) {
			VerficationService.getDisposeProof(key)
				.then((res) => {
					window.open(res?.detail, "_blank");
				})
				.catch((err) => showToastMessage(err?.message, false));
		} else if (file === verification_column_keys.GOVERNMENT_FEE_PROOF) {
			VerficationService.getGovernmentFeeProof(key)
				.then((res) => {
					window.open(res?.detail, "_blank");
				})
				.catch((err) => showToastMessage(err?.message, false));
		} else if (file === verification_column_keys.EXCEPTION_PROOF) {
			VerficationService.getExceptionProof(key)
				.then((res) => {
					window.open(res?.detail, "_blank");
				})
				.catch((err) => showToastMessage(err?.message, false));
		}
	};

	const handleOnSubmit = () => {
		const verficationStatus =
			formData[verification_column_keys.VERIFICATION_STATUS]?.value?.value;
		const rejectReason =
			formData[verification_column_keys.VERIFICATION_REJECTION_REASON]?.value
				?.value;
		if (!verficationStatus) {
			showToastMessage("Please select Verification Status", false);
		} else if (verficationStatus === "REJECTED" && !rejectReason) {
			showToastMessage("Please select Reason", false);
		} else {
			let formData = new FormData();
			formData.append("status", verficationStatus);
			verficationStatus === "REJECTED" &&
				formData.append("reason", rejectReason);
			VerficationService.submitOpsVerificationDetails(
				idChallanVerification,
				formData
			)
				.then((res) => {
					showToastMessage(res?.message);
					setRefresh(true);
				})
				.catch((err) => showToastMessage(err?.message, false));
		}
	};

	const renderFinderProofLink = () => {
		return (
			<FinderProofView
				challanFinderProofs={
					verificationData?.["challan-item"]?.challanFinderProofs
				}
				fkChallanRequest={verificationData?.["challan-item"]?.fkChallanRequest}
				handleFileView={handleFinderProofView}
			/>
		);
	};

	const handleFinderProofView = (id, file) => {
		if (id) {
			VerficationService.fetchUploadProofMultipleFileLink(id, file)
				.then((resp) => {
					resp?.detail && window.open(resp?.detail, "_blank", "noopener");
				})
				.catch((err) => {
					console.log(err);
					showToastMessage(err?.message, false);
				});
		}
	};

	const handleOnBackClick = () => {
		setScreen(SCREEN_TYPE.VERIFICATION_LISTING);
	};
	return (
		<GridLayout
			gap={{
				rows: "15px",
				cols: 10,
			}}>
			<GridLayoutItem row={1}>
				<LayoutStatusBanner
					label={`Appt. ID. #${verificationData?.["verification-item"]?.appointmentOrderId}`}
					status={verificationData?.["verification-item"]?.status}
					onBackClick={handleOnBackClick}
					isSubmitRequired={["ASSIGNED", "PENDING"].includes(
						verificationData?.["verification-item"]?.status
					)}
					onSubmitHandler={handleOnSubmit}
				/>
			</GridLayoutItem>
			<GridLayoutItem row={2}>
				<DetailCardLayout
					headerTitle={"Challan Info"}
					itemPerRow={NUMBERS.FOUR}
					columnList={verificationInfo}
				/>
			</GridLayoutItem>
			<GridLayoutItem row={3}>
				<FormCardLayout
					headerTitle={"Payment Raise"}
					formData={formData}
					handleChange={onInputChange}
					isShowCTA={false}
				/>
			</GridLayoutItem>
		</GridLayout>
	);
};

export default VerificationDetailContainer;
