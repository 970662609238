import React, { useEffect, useState } from 'react';
import { KendoCard } from '../../../../../../components';
import { ExpansionPanel, ExpansionPanelContent, GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { Reveal } from '@progress/kendo-react-animation';
import { Loader } from '@progress/kendo-react-indicators';
import RACallSummaryCollection from './ra-call-summary-collection';
import RAScoreCardCollection from './ra-score-card-collection';
import Highlights from './highlights';
import AreaOfImprovement from './area-of-improvemnt';
import LatestPr from '../latest-pr';
import OtherPrs from '../other-prs';
import { genAiApis } from '../../../../../../services/genAiApi';
import useLazyQuery from '../../../../../../hooks/useLazyQuery';
import { SCORE_COLOR_STYLES } from './ra-score-card-collection/view-model';
import { getLoginUserEmail, yieldToMain } from '../../../../../../utils/utils';
import styles from './style.module.css';
import ChatHistory from './chat-history';
import { sendEvent } from '../../../../../../tracking';

function ActivityFeed(props) {
    const [currentExpandedTabs, setCurrentExpandedTabs] = useState([]);
    const { apiCbFunction: getScorecard, data: scorecardData, isLoading: scorecardDataLoading, errorMessage:scorecardErrorMessage} = useLazyQuery(genAiApis.getScorecard);
    const { apiCbFunction: getDataByRequestId, data: dataByRequestId, isLoading: dataByRequestIdLoading } = useLazyQuery(genAiApis.getDataByRequestId);

    useEffect(() => {
        getScorecard({
            body: {
                journey: "RETAIL_ASSOCIATE",
                action: "RA_TRANSCRIPT_SCORE",
                appointment: props?.appointmentId,
                agentId: getLoginUserEmail(),
            },
        });
    }, []);

    useEffect(() => {
      let callCount = 0;
      const intervals = [15000, 20000, 25000];
      const fetchData = () => {
        if (callCount < 3) {
          setTimeout(() => {
            getDataByRequestId({
                requestId: dataByRequestId?.data?.requestId || scorecardData?.data?.requestId,
            });
            callCount += 1;
            fetchData();
          }, intervals[callCount]);
        }
      };
      if ((scorecardData?.msg === 'SUCCESS' || dataByRequestId?.msg === 'SUCCESS') && dataByRequestId?.data?.response == null) {
        fetchData();
      }
      return () => {
        callCount = 3;
      };
    }, [scorecardData, dataByRequestId]);
  

    const data = [
        {
            id: "highlights",
            title: "Highlights",
            component: <Highlights {...props} />,
            gaEventsData:{
              event:'highlights_drop_down_click',
              eventCategory:'summary_audit',
              eventAction:'highlights_click',
            }
        },
        {
            id: "call_summary",
            title: "Call Logs",
            component: <RACallSummaryCollection {...props} />,
            gaEventsData:{
              event:'summary_click',
              eventCategory:'summary_audit',
              eventAction:'specific_summary_viewed',
            }
        },
        {
          id: "chat_history",
          title: "Chat History",
          component: <ChatHistory {...props} />,
          gaEventsData:{
            event:'summary_click',
            eventCategory:'summary_audit',
            eventAction:'summary_tab_click',
            data:'chat'
          }
        },
        {
            id: "area_of_improvement",
            title: "Areas of Improvement",
            component: (
                <AreaOfImprovement
                    scorecardDataLoading={scorecardDataLoading}
                    dataByRequestIdLoading={dataByRequestIdLoading}
                    scorecardData={scorecardData}
                    dataByRequestId={dataByRequestId}
                    scorecardErrorMessage={scorecardErrorMessage}
                    {...props}
                />
            ),
            gaEventsData:{
              event:'improvement_drop_down_click',
              eventCategory:'score_audit',
              eventAction:'areas_of_improvement_click',
            }
        },
        {
            id: "score_card",
            title: "Score Card",
            component:<RAScoreCardCollection
                        scorecardDataLoading={scorecardDataLoading}
                        dataByRequestIdLoading={dataByRequestIdLoading}
                        scorecardData={scorecardData}
                        dataByRequestId={dataByRequestId}
                        scorecardErrorMessage={scorecardErrorMessage}
                        {...props}
                      />,
            gaEventsData:{
              event:'score_card_drop_down_click',
              eventCategory:'score_audit',
              eventAction:'score_card_click',
            }
        },
        {
            id: "purchase_requests",
            title: "Latest PRs",
            component: (
                <>
                    <LatestPr
                        isViewAllPr={props?.isViewAllPr}
                        setIsViewAllPr={props?.setIsViewAllPr}
                        toggleApprovals={props?.toggleApprovals}
                    />
                    {props?.isViewAllPr && <OtherPrs toggleApprovals={props?.toggleApprovals} />}
                </>
            ),
        },
    ].filter(Boolean);

    const expandPanelClickHandler = (event, item) => {
        setCurrentExpandedTabs(prevState =>
            event.expanded ? prevState.filter(id => id !== item.id) : [...prevState, item.id]
        );
        item.gaEventsData && 
        sendEvent({
          event:item?.gaEventsData?.event,
          eventAction:item?.gaEventsData?.eventAction,
          eventCategory:item?.gaEventsData?.eventCategory,
          eventLabel:`${event?.expanded ? 'CLOSE':'OPEN'}|${item.gaEventsData?.data??''}|${props?.appointmentId}|${getLoginUserEmail()}`
        })
    };

    const viewScoreCardClickHandler = async() => {
        expandPanelClickHandler({ expanded: false }, { id: 'score_card' });
        props?.handleSelect({selected:8})
        await yieldToMain();
        sendEvent({
          event:'scoring_cta_click',
          eventCategory:'score_audit',
          eventAction:'score_cta_click',
          eventLabel:`${props?.appointmentId}|${getLoginUserEmail()}`
        })
    };

    useEffect(()=>{
      sendEvent({
        event:'nego_page_viewed',
        eventCategory:'page_viewed',
        eventAction:'nego_page_viewed',
        eventLabel:`${props?.appointmentId}|${getLoginUserEmail()}`
      })
    },[])

    const renderSummarizeScoreCard = () => {
        if (dataByRequestId?.data?.response != null) {
            const responseData = JSON.parse(dataByRequestId?.data?.response);
            const scoringData = JSON.parse(responseData?.data?.text);
            const summarizedScorecard  = scoringData.summarizedScore;

            return (
                <div className={styles.head_ss_container}>
                    <p className={styles.head_ss_container_header}>Your score {props?.ownerNameOnRc?'with ' + props?.ownerNameOnRc:''}</p>
                    <div className={styles.head_ss_container_overallScore}>
                        {summarizedScorecard?.finalScore}
                    </div>
                    <div className={styles.head_ss_container_content}>
                        <p className={styles.head_ss_container_remark}>Well Done!</p>
                        <div className={styles.head_ss_content_collection}>
                            {summarizedScorecard?.parameters.map((item, index) => (
                                <div key={index} className={styles.head_ss_container_data}>
                                    <p className={styles.head_ss_container_parameter}>{item?.parameter}</p>
                                    <p
                                        className={styles.head_ss_container_score}
                                        style={{ color: SCORE_COLOR_STYLES[item?.score ?? '1/3'].color }}
                                    >
                                        {item?.score}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <button className={styles.head_ss_container_btn} onClick={viewScoreCardClickHandler}>
                            View Score Card
                        </button>
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            <div>{renderSummarizeScoreCard()}</div>
            <KendoCard
                cardStyle={{ background: "#F0F2F5" }}
                headerTitle="Activity Feed"
                details={
                    <GridLayout className={`activity_feed_tab ${styles.activity_feed}`}>
                        <GridLayoutItem>
                            {data.map(item => (
                                <ExpansionPanel
                                    title={item.title}
                                    expanded={currentExpandedTabs.includes(item.id)}
                                    tabIndex={0}
                                    key={item.id}
                                    onAction={event => expandPanelClickHandler(event, item)}
                                    className={currentExpandedTabs.includes(item.id) ? styles.expanded_panel : null}
                                >
                                    <Reveal>
                                        {currentExpandedTabs.includes(item.id) && (
                                            <ExpansionPanelContent>{item.component}</ExpansionPanelContent>
                                        )}
                                    </Reveal>
                                </ExpansionPanel>
                            ))}
                        </GridLayoutItem>
                    </GridLayout>
                }
            />
        </>
    );
}

export default ActivityFeed;
