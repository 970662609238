import React, { useEffect, useState } from "react";
import { FormComboBox, FormInput } from "../../../../../../components";
// import { SOURCE_DELAY_REASONS } from "./viewModels";
import { Button } from "@progress/kendo-react-buttons";
// import { challan_item_keys } from "../../viewModel";
import { USER_ROLE_KEYS } from "../../../../../../utils/constants/menu.constants";
import { showToastMessage } from "../../../../../../utils/utils";
import { ChallanService } from "../../../../../../services";
import { updateChallanItemAssignmentTypeParams } from "../../../../../../store/actions/ops-challan";
import { useDispatch } from "react-redux";
import { NUMBERS } from "../../../../../../utils/constants/values.constants";

const AddDelay = (props) => {
  const { challanItems } = props || {};
  const dispatch = useDispatch();

  const [delayReason, setDelayReason] = useState();
  const [delayReasonList, setDelayReasonList] = useState([]);
  useEffect(() => {
    ChallanService.fetchAllDelayReason()
      .then((res) => {
        const delayReasonList =
          (!!res?.detail?.length &&
            res?.detail?.map((item) => ({
              label: item?.reason,
              value: item?.reason,
            }))) ||
          [];
        setDelayReasonList(delayReasonList);
      })
      .catch((err) => showToastMessage(err?.message, false));
  }, []);

  const handleChange = (e) => {
    const { value } = e;
    const { id } = e.target.props;
    setDelayReason(value?.label);
  };

  const handleReset = () => {
    setDelayReason("");
  };
  const handleConfirm = () => {
    if (!delayReason) {
      showToastMessage("Please select Delay Reason", false);
    } else {
      const payload = {
        delayReason: delayReason,
      };
      if (props.userGroup === USER_ROLE_KEYS.DISPOSE) {
        let formData = new FormData();
        formData.append("delayReason", delayReason);
        ChallanService.addDelayReasonChallanItem(
          challanItems[0]?.idChallanItem,
          formData
        )
          .then((res) => {
            showToastMessage(res?.message);
            props?.resetChallanItems();
            props?.onClose();
            dispatch(updateChallanItemAssignmentTypeParams(NUMBERS.ZERO));
          })
          .catch((err) => {
            showToastMessage(err?.message, false);
          });
      } else if (props.userGroup === USER_ROLE_KEYS.FINDER) {
        let formData = new FormData();
        formData.append("delayReason", delayReason);
        ChallanService.addDelayReasonChallanRequest(
          challanItems[0]?.idChallanRequest,
          formData
        )
          .then((res) => {
            showToastMessage(res?.message);
            props.setIsRefreshRequired(true);
            props.onClose();
          })
          .catch((err) => showToastMessage(err?.message, false));
      }
    }
  };
  return (
    <div>
      <div
        style={{ fontSize: "20px", fontWeight: "700", marginBottom: "25px" }}
      >
        Appointment No: {challanItems[0]?.appointmentOrderId}
      </div>
      <div
        style={{ fontSize: "20px", fontWeight: "700", marginBottom: "25px" }}
      >
        Vehicle Reg Number: {challanItems[0]?.regNumber}
      </div>
      <div style={{ margin: "20px 0px" }}>
        Source: {challanItems[0]?.sourceCode}
      </div>
      <FormComboBox
        id={"delayReason"}
        onChange={handleChange}
        data={delayReasonList}
        textField="label"
        dataItemKey="value"
        value={{ label: delayReason, value: delayReason }}
        // value={FormData[key]?.value}
        // {...elementMetaData}
        label={"Delay Reason"}
      />

      <Button
        onClick={handleReset}
        className="k-button k-button-md k-button-outline k-button-outline-primary k-rounded-md m-2"
        themeColor={"primary"}
      >
        Reset
      </Button>
      <Button
        onClick={handleConfirm}
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base m-2"
        themeColor={"primary"}
      >
        Confirm
      </Button>
    </div>
  );
};

export default AddDelay;
