import React, { useEffect, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { KendoCard } from "../../../../components";
import IncentiveInfo from "./components/incentive-info";
import { incentiveDetailModel } from "./viewModal";
import { Loader } from "../../../../common/Loader";
import CreateIncentivePopup from "./components/create-incentive-popup";
import { cj_incentive_keys } from "../view-modal";
import { CjIncentivesService } from "../../../../services";
import { INITIAL_PAGINATION_PARAMS } from "../../../../utils/constants/values.constants";
import { replaceSymbolAndOr } from "../../utils/helper";
const style = {
	background: "#FFFFFF",
	minHeight: "180px",
	border: "15px solid #EBF5FF",
	boxShadow: "unset",
	borderRadius: "8px",
};
const headerStyle = {
	color: "#003E72",
	fontWeight: "600",
	fontSize: "18px",
};

const IncentiveDetail = (props = {}) => {
	const { rowItem, onBackHandler } = props;
	const [dataItem, setDataItem] = useState();
	const [refresh, setRefresh] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [clusterParam, setClusterParam] = useState("");
	const [clusters, setClusters] = useState({
		filteredClusters: [],
		initialClusters: []
	});
	const [systemMetrices, setSystemMetrices] = useState([]);
	const [isIncentiveCreatePopupOpen, setIsIncentiveCreatePopupOpen] =
		useState(false);

	useEffect(() => {
		setIsLoading(true);
		CjIncentivesService.fetchClustersByName(INITIAL_PAGINATION_PARAMS).then(
			(resp) => {
				setClusters({
					...clusters,
					initialClusters : resp?.detail || []
				});
				setRefresh(true);
				setIsLoading(false);
			}
		);
		CjIncentivesService.fetchAllMetrics().then((resp) => {
			setSystemMetrices(resp?.detail);
			setIsLoading(false)
		});
	}, []);

	useEffect(() => {
		if (clusterParam !== "" && clusterParam !== null) {
			CjIncentivesService.fetchClustersByName({clusterName: clusterParam}).then(
				(resp) => {
					setClusters({
						...clusters,
						filteredClusters : resp?.detail || []
					});
				}
			);
		}
	},[clusterParam, setClusterParam])

	useEffect(() => {
		setIsIncentiveCreatePopupOpen(!rowItem[cj_incentive_keys.ID]);
		setDataItem((prev) => ({ ...prev, ...rowItem }));
	}, [rowItem]);

	useEffect(() => {
		!!refresh && !isIncentiveCreatePopupOpen && RefreshData();
	}, [refresh, isIncentiveCreatePopupOpen]);

	const resetIncentiveDetail = () => {
		setDataItem(rowItem);
	};

	const RefreshData = () => {
		setIsLoading(true);
		CjIncentivesService.fetchIncentiveDetails(dataItem[cj_incentive_keys.ID])
			.then((resp) => {
				const {
					clusters = [],
					incentiveConditions = [],
					incentiveDetail = {},
					targets = [],
				} = resp?.detail || {};

				let _incentiveConditions =
					incentiveDetailModel[cj_incentive_keys.CONDITIONS];
				let _cond = incentiveConditions.map((item) => {
					return {
						[cj_incentive_keys.FORMULA_EXP]: {
							value: replaceSymbolAndOr(item?.conditionExpression || ""),
						},
						[cj_incentive_keys.FORMULA_RESULT]: {
							value: replaceSymbolAndOr(item?.valueExpression || ""),
						},
						[cj_incentive_keys.ID_INCENTIVE_CONDITION]:
							item?.[cj_incentive_keys.ID_INCENTIVE_CONDITION],
					};
				});
				const _startDate = incentiveDetail?.[cj_incentive_keys.VALIDITY_START_DATE] ? new Date(
					incentiveDetail?.[cj_incentive_keys.VALIDITY_START_DATE]) : null;
				const _endDate = incentiveDetail?.[cj_incentive_keys.VALIDITY_END_DATE] ? new Date(
					incentiveDetail?.[cj_incentive_keys.VALIDITY_END_DATE]) : null;
				setDataItem((prev) => ({
					...prev,
					...incentiveDetail,
					[cj_incentive_keys.CLUSTER]: clusters,
					[cj_incentive_keys.TARGET_MATRIX]: targets,
					[cj_incentive_keys.IS_RANGE]:
						!!incentiveDetail?.[cj_incentive_keys.VALIDITY_START_DATE] &&
						!!incentiveDetail?.[cj_incentive_keys.VALIDITY_END_DATE],
					[cj_incentive_keys.CONDITIONS]: !!_cond?.length
						? _cond
						: _incentiveConditions,
					[cj_incentive_keys.VALIDITY_START_DATE]: _startDate,
					[cj_incentive_keys.VALIDITY_END_DATE]: _endDate,
					[cj_incentive_keys.VALID_RANGE]: {
						start: _startDate,
						end: _endDate,
					},
				}));
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setIsLoading(false);
				setRefresh(false);
			});
	};

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<GridLayout
					gap={{
						rows: "15px",
						cols: 10,
					}}>
					<GridLayoutItem row={1}>
						<span
							className="k-icon k-i-arrow-chevron-left"
							onClick={onBackHandler}
							style={{
								marginRight: "10px",
								marginBottom: "5px",
								fontSize: "18px",
								color: "#003E72",
								cursor: "pointer",
							}}
						/>
						<span
							style={{
								color: "#003E72",
								fontSize: "20px",
								marginRight: "40px",
							}}>
							Back
						</span>
					</GridLayoutItem>
					<GridLayoutItem row={2}>
						{!!dataItem && (
							<KendoCard
								headerTitle="CJ Incentive"
								cardStyle={style}
								headerStyle={headerStyle}
								details={
									<IncentiveInfo
										data={dataItem}
										updateData={setDataItem}
										resetIncentiveDetail={resetIncentiveDetail}
										clusters={clusters}
										systemMetrices={systemMetrices}
										isNewIncentive={isIncentiveCreatePopupOpen}
										setRefresh={setRefresh}
										setIsIncentiveCreatePopupOpen={
											setIsIncentiveCreatePopupOpen
										}
										onBackHandler={onBackHandler}
										setClusterParam={setClusterParam}
										clusterParam={clusterParam}
										setClusters={setClusters}
									/>
								}
							/>
						)}
					</GridLayoutItem>
				</GridLayout>
			)}
		</>
	);
};

export default IncentiveDetail;
