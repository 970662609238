import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import "./share-car-modal.scss";
import successIcon from "../../assets/images/check-mark-green-circle.svg";

const ShareCarSuccessModal = ({ count, isSlider }) => {
  return (
    <>

      <Dialog closeIcon={false}
        className="app-share-car-confirm-modal" >
        {/* <div className="modal-title">Select an option to share car with dealer</div> */}
        <DialogActionsBar>
          <div className="share-success-block">
            <img src={successIcon} alt="cars24" className="success-check-icon" title="contactNumber" />
            {isSlider ?
              <div className="description">Car shared with {count} dealer(s).</div>
              :
              <div className="description">{count} car(s) shared successfully.</div>
            }
          </div>
        </DialogActionsBar>

      </Dialog>

    </>
  );
};
export default ShareCarSuccessModal;
