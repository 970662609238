function NotificationFlag(props) {
  return (
    <svg
      width={11}
      height={12}
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.333 11.5V.167h6L6.6 1.5h3.734v6.667H5.667L5.4 6.833H1.667V11.5H.333zm6.434-4.667H9v-4H5.5L5.234 1.5H1.667v4H6.5l.267 1.333z"
        fill="#22599E"
      />
    </svg>
  )
}

export default NotificationFlag
