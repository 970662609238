import "./image-slide-modal.scss";
import { ScrollView } from "@progress/kendo-react-scrollview";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useEffect, useState } from "react";
import { getVizPanelServiceData } from "../../constants/env.constants";
import carImage from "../../assets/images/car-image.svg";
const ImageSliderModal = ({ title, close, list }) => {

  const apiMetaData = getVizPanelServiceData();

  const [sliderOpacity, setOpacity] = useState(0);
  useEffect(() => {
    setTimeout(() => setOpacity(1), 400);
  }, [list]);

  return (
    <>
      <div >
        <Dialog
          title={title}
          className="app-image-slide-modal" onClose={() => close(false)}>
          {/* <div className="modal-title">Select an option to share car with dealer</div> */}
          {/* <DialogActionsBar> */}
          <div className="image-slide-container app-slider">
            <ScrollView
              style={{
                width: 770,
                height: 580,

              }}
              automaticViewChange={false}
            >
              {list.map((item, index) => {
                return (
                  <div className="image-with-text" key={index}
                    style={{
                      opacity: sliderOpacity
                    }}>
                    {/* <p>
                Showing image {item.position} of {items.length}.
              </p> */}
                    <h2 className="image-title">{item.title}</h2>
                    {
                      item.video ?

                        <video controls className="slider-video" style={{
                          width: 770,
                          height: 526,

                        }}>
                          <source src={apiMetaData.videoUrl + '/' + item.url} type="video/mp4" />
                        </video>
                        :
                        <img
                          src={apiMetaData.imagesUrl + '/' + item.url}
                          className="slider-img"
                          alt={`Cars24`}
                          style={{
                            width: 770,
                            height: 526,

                          }}
                          draggable={false}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = carImage;
                          }} />
                    }

                    {/*  */}
                  </div>
                );
              })}
            </ScrollView>
          </div>
          {/* </DialogActionsBar> */}

        </Dialog>
      </div>
    </>
  );
};
export default ImageSliderModal;
