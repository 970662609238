import { Card, CardBody, StackLayout } from "@progress/kendo-react-layout";
import {
    DOCUMENT_STATUS,
    ERROR_CODES,
    STATUS_MAPPING,
    TOAST_MESSAGES,
    UPLOADED_BY,
    VERIFICATION_STATUS,
} from "../constants";
import { Button } from "@progress/kendo-react-buttons";
import { eyeIcon, uploadIcon } from "@progress/kendo-svg-icons";
import { useEffect, useRef, useState } from "react";
import { getFileType } from "../utils/helper";
import { SFService } from "../../../../services";
import { showToastMessage, yieldToMain } from "../../../../utils/utils";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ActionConfirmationModal from "./ActionConfirmationModal";
import classes from "../styles/DocumentsForm.module.css";
import { useLocation } from "react-router-dom";
import { sendEvent } from "../../../../tracking";
import sfEventParser from "../../../../tracking/parser/seller-finance";
import { SF_EVENT_CATEGORIES } from "../../common/constants";

const DocumentCard = ({ docData, token, onUpload, onVerify }) => {
    const {
        docName = "",
        documentState = "",
        docCode = "",
        isMandatory,
        attachments,
    } = docData || {};

    const [isUploading, setIsUploading] = useState(false);
    const [showDocumentDialog, setShowDocumentDialog] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDocumentLoading, setIsDocumentLoading] = useState(true);
    const inputRef = useRef(null);
    const documentActionType = useRef(null); // 'approve' | 'reject'
    const { pathname } = useLocation();
    const splitPathname = pathname.split("/");
    const leadId = splitPathname[splitPathname.length - 1];

    const mapStatusToClassname = () => {
        switch (documentState) {
            case STATUS_MAPPING.PENDING:
                return classes.pending;
            case STATUS_MAPPING.REJECTED:
                return classes.verificationFailed;
            case STATUS_MAPPING.UNDER_VERIFICATION:
                return classes.underVerification;
            case STATUS_MAPPING.VERIFIED:
                return classes.verified;
        }
    };

    const handleUploadFile = async (e) => {
        try {
            setIsUploading(true);

            const uploadedFile = e?.target?.files?.[0];
            const fileType = getFileType(uploadedFile?.type);

            const body = new FormData();
            body.append("file", uploadedFile);
            body.append("token", token);
            body.append("page", 1);
            body.append("docCode", docCode);
            body.append("fileType", fileType);
            body.append("uploadedBy", UPLOADED_BY);

            const preApiCallEventPayload = sfEventParser(
                {
                    action: SF_EVENT_CATEGORIES.DOCUMENT_UPLOAD_REQUESTED,
                    label: token,
                },
                {
                    "document_type": docCode
                }
            );
            await yieldToMain();
            sendEvent(preApiCallEventPayload);

            const response = await SFService.uploadDocument(body);
            const {
                detail: { documentUrl, page, fileType: uploadedFileType },
            } = response || {};

            showToastMessage(
                TOAST_MESSAGES.DOCUMENT_UPLOAD.SUCCESS.message,
                true,
                _,
                TOAST_MESSAGES.DOCUMENT_UPLOAD.SUCCESS.id
            );

            onUpload(docCode, {
                docUrl: documentUrl,
                page,
                fileType: uploadedFileType,
            });

            const postApiCallEventPayload = sfEventParser(
                {
                    action: SF_EVENT_CATEGORIES.DOCUMENT_UPLOADED,
                    label: token,
                },
                {
                    "document_type": docCode
                }
            );
            await yieldToMain();
            sendEvent(postApiCallEventPayload);
        } catch (error) {
            const { errorMessage, errorCode } = error;
            if (errorCode === ERROR_CODES.FILE_SIZE_EXCEEDED) {
                showToastMessage(
                    errorMessage,
                    false,
                    _,
                    TOAST_MESSAGES.DOCUMENT_UPLOAD.ERROR.id
                );
            }
        } finally {
            setIsUploading(false);
        }
    };

    const handleClickUpload = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleViewDoc = () => {
        setShowDocumentDialog(true);
    };

    const handleDocumentAction = (type) => {
        documentActionType.current = type;
        setShowDocumentDialog(false);
        setShowConfirmationModal(true);
    };

    const handleCloseConfirmation = () => {
        setShowConfirmationModal(false);
        documentActionType.current = null;
    };

    const handleConfirmAction = async (remarks = "") => {
        try {
            setIsLoading(true);
            const body = {
                leadId,
                docCode,
                page: "1",
                status: VERIFICATION_STATUS[documentActionType?.current],
                remarks,
            };
            const response = await SFService.verifyDocument(body);
            const { success, detail } = response;
            if (success) {
                const { allDocumentVerified, status } = detail;
                onVerify(docCode, status);
                showToastMessage(
                    TOAST_MESSAGES.VERIFY_DOCUMENT.SUCCESS.message(
                        documentActionType?.current
                    ),
                    true,
                    _,
                    TOAST_MESSAGES.VERIFY_DOCUMENT.SUCCESS.id
                );
            }
        } catch (error) {
            console.log(error);
            showToastMessage(
                error?.errorMessage ||
                    TOAST_MESSAGES.VERIFY_DOCUMENT.ERROR.message,
                false,
                _,
                TOAST_MESSAGES.VERIFY_DOCUMENT.ERROR.id
            );
        } finally {
            handleCloseConfirmation();
            setIsLoading(false);
        }
    };

    const docAttachment = attachments?.[0];

    const isViewEnabled = attachments && attachments.length > 0;

    const renderDocumentViewer = () => {
        switch (docAttachment?.fileType) {
            case "image":
                return (
                    <>
                        {isDocumentLoading ? (
                            <Loader className={classes.docLoader} />
                        ) : (
                            <img
                                className={classes.docImg}
                                src={docAttachment?.docUrl}
                                alt="document"
                            />
                        )}
                    </>
                );
            case "pdf":
                return (
                    <>
                        {isDocumentLoading ? (
                            <Loader className={classes.docLoader} />
                        ) : null}
                        <iframe
                            src={docAttachment?.docUrl}
                            className={classes.docPdf}
                            onLoad={() => setIsDocumentLoading(false)}
                        />
                    </>
                );
        }
    };

    useEffect(() => {
        if (docAttachment?.fileType === "image") {
            const img = new Image();
            img.src = docAttachment?.docUrl;
            img.onload = () => setIsDocumentLoading(false);
        }
    }, [docAttachment]);

    return (
        <>
            <Card orientation="horizontal">
                <CardBody>
                    <StackLayout
                        orientation="horizontal"
                        className="k-justify-content-between k-align-items-center"
                    >
                        <p className="m-0 text-base">
                            {docName}{" "}
                            <span className="text-danger">
                                {isMandatory && "*"}
                            </span>
                        </p>
                        {isUploading ? (
                            <div className="d-flex justify-content-end">
                                <Loader />
                            </div>
                        ) : (
                            <div
                                className="d-flex gap-4 justify-content-end"
                                style={{
                                    flex: "2",
                                }}
                            >
                                <p
                                    className={`m-0 my-auto px-2 py-1 rounded ${
                                        classes.wFit
                                    } text-sm ${mapStatusToClassname()}`}
                                >
                                    {DOCUMENT_STATUS[documentState]}
                                </p>
                                <Button
                                    svgIcon={uploadIcon}
                                    onClick={handleClickUpload}
                                    className="text-sm"
                                >
                                    Upload
                                </Button>
                                <Button
                                    svgIcon={eyeIcon}
                                    onClick={handleViewDoc}
                                    className="text-sm"
                                    disabled={!isViewEnabled}
                                >
                                    View
                                </Button>
                            </div>
                        )}
                    </StackLayout>
                </CardBody>
            </Card>
            <input
                ref={inputRef}
                type="file"
                onChange={handleUploadFile}
                className={`d-none ${classes.hiddenInput}`}
                accept="image/*,.pdf"
            />
            {showDocumentDialog && (
                <Dialog
                    contentStyle={{
                        width: "100%",
                    }}
                    title={docName}
                    onClose={() => setShowDocumentDialog(false)}
                >
                    <div className="text-center">
                        {docAttachment &&
                        docAttachment.fileType &&
                        docAttachment.docUrl
                            ? renderDocumentViewer()
                            : ""}
                    </div>
                    <DialogActionsBar>
                        <Button
                            themeColor="primary"
                            onClick={() => handleDocumentAction("approve")}
                        >
                            Approve
                        </Button>
                        <Button onClick={() => handleDocumentAction("reject")}>
                            Reject
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <ActionConfirmationModal
                isOpen={showConfirmationModal}
                type={documentActionType?.current}
                onClose={handleCloseConfirmation}
                onConfirm={handleConfirmAction}
                isBtnLoading={isLoading}
            />
        </>
    );
};

export default DocumentCard;
