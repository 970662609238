import { getBaseUrl } from "../utils/constants/url.constants";
import { getFFUserType, getLoginUserEmail } from "../utils/utils";
import apiInterceptor from "./apiInterceptor";

const lmsNegoService = (api) => {
    const BASE_ENDPOINT = 'lms-comm/call-detail';

    const getCallSummary = (payload) => {
      const endpoint = `/purchase/api/v1/appointment/${payload?.appointmentId}/call-data`;
      
        return api.get(endpoint, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        })
    };

    const getScore = (payload) => {
        const endpoint = `${BASE_ENDPOINT}/score?appointmentId=${payload?.appointmentId}&raEmail=${payload?.userEmail}`;

        return api.get(endpoint, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        })
    };

    return {
        getCallSummary,
        getScore
    };
};

export const lmsNegoApi = lmsNegoService(apiInterceptor(getBaseUrl()));

