import { useDispatch, useSelector } from "react-redux";
import { LEAD_TYPES } from "./viewModel";
import { UpdatePayoutsFilterQuery } from "../../../../store/actions/dealer-payouts";
const PaymentsFilters = (props) => {
  const dispatch = useDispatch();
  const selectedFilter = useSelector((state) => state.dealerPayouts.leadType);
  const onFilterSelect = (value) => {
    dispatch(UpdatePayoutsFilterQuery(value));
    props.setPaginationParams({ ...props.paginationParams, page: 0 });
  };
  return (
    <div className="ops-grid-toolbar-items">
      <ul className="ops-channel-assignment-filter-items">
        {LEAD_TYPES.map((item) => {
          return (
            <li
              key={item.value}
              onClick={() => onFilterSelect(item.value)}
              className={
                selectedFilter === item.value ? "assignment-filterSelected" : ""
              }
            >
              {item.label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PaymentsFilters;
