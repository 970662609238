import { useState, useEffect } from "react";
import { PRICE_COHORT_COLUMN_KEYS, PRICE_COHORT_COLUMN_MAP, predefinedStatusValues } from "../viewModal";
import { ColumnMenuRadioFilter } from "../../../../../inspection/trigger-manager/components/referral-view/components/column-menu-radio-filter";
import styles from '../customPrice.module.css';

export const useVisibleColumns = (
    gridFilters,
    filterParams,
    setFilterParams,
    setCohortScreen,
    setCohortFormData,
    setIsUpdateCohort
) => {

    const [visibleColumns, setVisibleColumns] = useState(PRICE_COHORT_COLUMN_MAP);
    const [filterData, setFilterData] = useState({ [PRICE_COHORT_COLUMN_KEYS.STATUS]: predefinedStatusValues });

    const formHandler = (dataItem) => {
        setIsUpdateCohort(true);
        setCohortFormData(dataItem)   
        setCohortScreen(true);
    }

    useEffect(() => {
        setVisibleColumns((columns) => ({
            ...columns,
            [PRICE_COHORT_COLUMN_KEYS.TITLE]: {
                ...columns[PRICE_COHORT_COLUMN_KEYS.TITLE],
                cell: (props) => {
                    return (
                        <td>
                            <div className={styles.title} onClick={() => formHandler(props?.dataItem)}>
                                {props?.dataItem?.configName}
                            </div>
                        </td>
                    )
                }
            },
            [PRICE_COHORT_COLUMN_KEYS.STATUS]: {
                ...columns[PRICE_COHORT_COLUMN_KEYS.STATUS],
                columnMenu: (props) => (
                    <ColumnMenuRadioFilter
                        {...props}
                        filterData={filterData}
                        columnMapKey={PRICE_COHORT_COLUMN_KEYS.STATUS}
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        title={'Status'}
                    />
                ),
            },
        }));
    }, [filterData, gridFilters, filterParams, setFilterParams]);

    return visibleColumns;
};
