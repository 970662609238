import {
  ExpansionPanel,
  ExpansionPanelContent,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import { useSelector } from "react-redux";
import { useState } from "react";
import { KendoCard } from "../../../../../../components";
import { Chip } from "@progress/kendo-react-buttons";
import titleBar from "../../shared/title-bar";
import { Button } from "@progress/kendo-react-buttons";
import styles from "../../../negotiation/assets/styles/pr.styles.module.css";
import { getStaticText } from "../../../negotiation/pr-automation/viewModel";
import isPickUpDetails from "../../shared/is-pickup-details";
import {
  formatDateYYYYMMDDtoDDMMM,
  isToday,
  isTomorrow,
} from "../../../../../../utils/utils";
import { Input } from "@progress/kendo-react-inputs";
import { isPrInitialized } from "../viewModel";

const BookSlot = (props) => {
  const { togglePickUp } = props || {};
  const [showBookSlot, setShowBookSlot] = useState(false);

  const prDetails = useSelector((state) => state.prAutomation);
  const {
    orderData: {
      pickupDetails = {},
      purchase: { purchaseStatus = "" } = {},
    } = {},
  } = prDetails || {};
  const {
    fromAddress,
    fromCity,
    fromPincode,
    fromState,
    pickupDate,
    slotFrom,
    slotTo,
  } = pickupDetails || {};
  const headerTitle = (label) => {
    return (
      <div className="justify-between" style={{ height: "30px" }}>
        <div className={styles.bankHeader}>{label}</div>
        {isPickUpDetails(pickupDetails) && (
          <Button
            themeColor="secondary"
            className={styles.uploadCta}
            onClick={togglePickUp}
            disabled={!isPrInitialized(purchaseStatus)}
          >
            Re-schedule
          </Button>
        )}
      </div>
    );
  };
  const addPickUpRender = () => {
    return (
      <GridLayout
        cols={[
          {
            width: "100%",
          },
        ]}
        gap={{
          rows: "24px",
          cols: "10px",
        }}
      >
        <GridLayoutItem className={styles.addPayment} row={1} colSpan={2}>
          <Button
            onClick={togglePickUp}
            fillMode="flat"
            icon="plus-outline"
            className={`${styles.iconButton} `}
            disabled={!isPrInitialized(purchaseStatus)}
          ></Button>
          <span className="k-ml-2 k-font-medium k-ml-3">
            Add pickup details
          </span>
        </GridLayoutItem>
        <GridLayoutItem row={2} colSpan={2}>
          <div className={styles.bankInfoText}>{getStaticText.addPickUp}</div>
        </GridLayoutItem>
      </GridLayout>
    );
  };
  const getAddress = () => {
    let value = "";
    if (fromAddress) {
      value = `${fromAddress},`;
    }
    if (fromCity) {
      value = `${value} ${fromCity}, `;
    }
    if (fromState) {
      value = `${value} ${fromState}, `;
    }
    if (fromPincode) {
      value = `${value} ${fromPincode}`;
    }
    return value;
  };

  const reschedulePickUpRender = () => {
    return (
      <GridLayout
        cols={[
          {
            width: "30%",
          },
          {
            width: "40%",
          },
        ]}
        gap={{
          rows: "24px",
          cols: "10px",
        }}
      >
        <GridLayoutItem row={1} col={1}>
          <h2 className={styles.bookSlotLabel}>Seller's preferred day</h2>
          <Chip
            text={
              pickupDate
                ? isToday(pickupDate)
                  ? "Today"
                  : isTomorrow(pickupDate)
                  ? "Tomorrow"
                  : formatDateYYYYMMDDtoDDMMM(pickupDate)
                : "NA"
            }
            className={`${styles.customChips} ${styles.unSelectedChip} ${styles.disabledChip} `}
          />
        </GridLayoutItem>
        <GridLayoutItem row={1} col={2}>
          <h2 className={styles.bookSlotLabel}>Preferred time slot</h2>
          <Chip
            text={`${slotFrom || ""} - ${slotTo || ""}`}
            className={`${styles.customChips} ${styles.unSelectedChip} ${styles.timeSlot} ${styles.disabledChip}`}
          />
        </GridLayoutItem>
        <GridLayoutItem row={2} colSpan={2}>
          <h2 className={styles.bookSlotLabel}>Pickup address</h2>
          <Input value={getAddress()} disabled />
        </GridLayoutItem>
      </GridLayout>
    );
  };
  const bookSlotRender = () => {
    return isPickUpDetails(pickupDetails)
      ? reschedulePickUpRender()
      : addPickUpRender();
  };

  return (
    <ExpansionPanel
      title={titleBar("Book Slot")}
      className="k-mt-4 k-rounded-lg"
      expanded={showBookSlot}
      tabIndex={0}
      onAction={() => setShowBookSlot(!showBookSlot)}
    >
      {showBookSlot && (
        <ExpansionPanelContent>
          <GridLayout
            cols={[
              {
                width: isPickUpDetails(pickupDetails) ? "100%" : "40%",
              },
            ]}
          >
            <GridLayoutItem row={1} col={1}>
              <KendoCard
                cardStyle={{
                  marginTop: "0px",
                }}
                headerTitle={headerTitle("Pickup Details")}
                details={bookSlotRender()}
              />
            </GridLayoutItem>
          </GridLayout>
        </ExpansionPanelContent>
      )}
    </ExpansionPanel>
  );
};

export default BookSlot;
