import { getBaseUrl } from "../utils/constants/url.constants";
import { getFFUserType, getLoginUserEmail } from "../utils/utils";
import apiInterceptor from "./apiInterceptor";

const commonService = (api) => {

    const getHypotecationBanks = (payload) => {
        const endpoint = `oms/purchase/hypo/banks`;
        return api.get(endpoint, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        })
    };

    const getHypotecationDocuments = (payload) => {
        const endpoint = `oms/purchase/api/v2/document/rule-engine/${payload?.appointmentId}?view=CUSTOMER&loanStatusOpen=${payload?.loanStatus ?? false}`;
        return api.get(endpoint, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        })
    };

    const saveLoanDetails = (payload) => {
        const endpoint = `oms/purchase/v1/hypo/${payload?.appointmentId}`;
        return api.post(endpoint, payload.body, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        })
    };

    const downloadGroupAssignment = (payload) => {
      const endpoint = `rms/api/v1/user-mapping`;
      return api.get(endpoint, {
          headers: {
              "user-type": `${getFFUserType()}`,
              "user-email": getLoginUserEmail(),
          },
      })
  };

  const uploadGroupAssignment = (payload) => {
      const endpoint = `rms/api/v1/user-mapping`;
      return api.put(endpoint, payload.body, {
          headers: {
              "user-type": `${getFFUserType()}`,
              "user-email": getLoginUserEmail(),
          },
      })
  };

    const assignHypoQc = (payload) => {
        const endpoint = `oms/purchase/v1/hypo/qc-assign`;
        const body = {
            "appointmentId": payload.appointmentId,
            "assigneeEmail": payload.userEmail
        }
        return api.post(endpoint, body, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        })
    };

    const getSSVendorDocuments = (payload) => {
        const endpoint = `dms/document/list/attachment?docCodes=HYPO_VENDOR_RESULT&appointmentId=${payload?.appointmentId}`;
        return api.get(endpoint, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        })
    };

    const sendConsentGeneration = (payload) => {
        const endpoint = `oms/purchase/v1/comm/send-diy-link`;

        return api.post(endpoint, payload?.body ?? {}, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        })
    };

    const downloadCreditReport = (payload) => {
        const endpoint = `/oms/purchase/dms/v1/credit-report?appointmentId=${payload?.appointmentId}`;
        return api.get(endpoint, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        })
    };

    const uploadDocs = (payload) => {
        const url = `dms/upload`;
        return api.post(`${url}`, payload?.body, { headers: payload?.headers });
    };

    const getTokenForUpload = (payload) => {
        const url = `dms/authenticate/int/user`;
        return api.post(`${url}`, payload);
    };

    const getPriceBreakdowm = (payload) => {
      const url = `/oms/purchase/api/api/customer-payment-rule?appointmentId=${payload.appointmentId}&expectedPrice=${payload.price}`;
      return api.get(url, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        });
    };

    const getOCBSliderData = (payload) => {
      const url = `/price/${payload.appointmentId}/nego-view-price`;
      return api.get(url, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        });
    };

    const getPriceForCustomer = (payload) => {
      const url = `/purchase/api/v1/appointment/${payload.appointmentId}/price-data`;
      return api.get(url, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        });
    };

    const getRADetails = (payload) => {
      const url = `/oms/sale/${payload.appointmentId}/pa-details`;
      return api.get(url, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        });
    };

    const acceptDNP = (payload) => {
      const url = `/crm-engine/api/v1/accept-dnp`;
      return api.post(`${url}`, payload.body);
    };

    const raiseOCB = (payload) => {
      const url = `/crm-engine/api/v1/raise-ocb`;
      return api.post(`${url}`, payload.body);
    };

    const updateQuote = (payload) => {
      const url = `/crm-engine/api/v1/c24-quote`;
      return api.put(`${url}`, payload.body);
    };

    const updateCEP = (payload) => {
      const url = `/lms/update-ecp`;
      return api.post(`${url}`, payload.body);
    };

    const getExotelAccessToken = () => {
      const url = `/lms-comm/api/v1/exotel/app/C2B_INTERNAL_PORTAL`;
      return api.get(url, {
        headers: {
            "user-type": `${getFFUserType()}`,
            "user-email": getLoginUserEmail(),
        },
      });
    };

    const updateTags = (payload) => {
      const url = `/crm-engine/api/v1/lead/tags`;
      return api.post(`${url}`, payload.body);
    };

    const callConnector = (payload) => {
      const url = `lms-comm/api/exotel/connect`;
      return api.post(`${url}`, payload.body);
    };

    const getRaActivity = (payload) => {
      const endpoint = `lms-comm/api/v1/user/activity`;
      return api.get(endpoint, {
          headers: {
              "user-type": `${getFFUserType()}`,
              "user-email": getLoginUserEmail(),
          },
      })
  };

  const addRaActivity = (payload) => {
      const url = `lms-comm/api/v1/user/activity`;
      return api.post(`${url}`, payload?.body, {
        headers: {
            "user-type": `${getFFUserType()}`,
            "user-email": getLoginUserEmail(),
        },
    });
  };

  const getSfIntend = (payload) => {
    const url = `seller-finance/seller-intent/appointment/intent/${payload?.appointmentId}`;
    return api.get(url, {
      headers: {
          "user-type": `${getFFUserType()}`,
          "user-email": getLoginUserEmail(),
      },
    });
  };

  const sendSfIntend = (payload) => {
    const url = `seller-finance/seller-intent/appointment/intent`;
    return api.post(`${url}`, payload.body,{
      "user-type": `${getFFUserType()}`,
      "user-email": getLoginUserEmail(),
    })
  };

  const getC2cIntend = (payload) => {
    const url = `c2c-seller-core/v1/seller/subscription/check?appointmentId=${payload?.appointmentId}`;
    return api.get(url, {
      headers: {
          "user-type": `${getFFUserType()}`,
          "user-email": getLoginUserEmail(),
      },
    });
  };

  const sendC2cIntend = (payload) => {
    const url = `c2c-seller-core/v1/seller/subscription`;
    return api.post(`${url}`, payload.body,{
      "user-type": `${getFFUserType()}`,
      "user-email": getLoginUserEmail(),
    });
  };

    return {
        getHypotecationBanks,
        getHypotecationDocuments,
        saveLoanDetails,
        downloadGroupAssignment,
        uploadGroupAssignment,
        sendConsentGeneration,
        getSSVendorDocuments,
        downloadCreditReport,
        assignHypoQc,
        uploadDocs,
        getTokenForUpload,
        getRaActivity,
        addRaActivity,
        getPriceBreakdowm,
        getOCBSliderData,
        getPriceForCustomer,
        getRADetails,
        raiseOCB,
        acceptDNP,
        updateCEP,
        updateQuote,
        getExotelAccessToken,
        updateTags,
        callConnector,
        getSfIntend,
        sendSfIntend,
        getC2cIntend,
        sendC2cIntend
    };
};

export const commonApis = commonService(apiInterceptor(getBaseUrl()));
