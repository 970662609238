export const PRICE_COHORT_COLUMN_KEYS = {
    TITLE: 'configName',
    STATE: 'stateName',
    CITY: 'cityName',
    CREATED_AT: 'createdAt',
    UPDATED_AT: 'updatedAt',
    PRICE_RANGE: 'carPriceLowerRange',
    CONTROL_PRICE: 'controlPrice',
    PRICE_VARIANT: 'variantAPrice',
    PRICE_VARIANT_2: 'variantBPrice',
    STATUS: 'status',
}

export const PRICE_COHORT_COLUMN_MAP = {
    [PRICE_COHORT_COLUMN_KEYS.TITLE]: {
        field: PRICE_COHORT_COLUMN_KEYS.TITLE,
        label: "Title",
    },
    [PRICE_COHORT_COLUMN_KEYS.STATE]: {
        field: PRICE_COHORT_COLUMN_KEYS.STATE,
        label: "State",
    },
    [PRICE_COHORT_COLUMN_KEYS.CITY]: {
        field: PRICE_COHORT_COLUMN_KEYS.CITY,
        label: "City",
    },
    [PRICE_COHORT_COLUMN_KEYS.CREATED_AT]: {
        field: PRICE_COHORT_COLUMN_KEYS.CREATED_AT,
        label: "Created At",
    },
    [PRICE_COHORT_COLUMN_KEYS.UPDATED_AT]: {
        field: PRICE_COHORT_COLUMN_KEYS.UPDATED_AT,
        label: "Updated At",
    },
    [PRICE_COHORT_COLUMN_KEYS.PRICE_RANGE]: {
        field: PRICE_COHORT_COLUMN_KEYS.PRICE_RANGE,
        label: "Car Price Range",
        cell: (props) => {

            let lower = props?.dataItem?.carPriceLowerRange;
            let upper = props?.dataItem?.carPriceUpperRange;

            let priceRange = PRICE_RANGES.find((item) => item.value.lower == lower && item.value.upper == upper)
            return (
                <td>
                    {priceRange ? priceRange?.label : `${props?.dataItem?.carPriceLowerRange} - ${props?.dataItem?.carPriceUpperRange}`}
                </td>
            )
        }
    },
    [PRICE_COHORT_COLUMN_KEYS.CONTROL_PRICE]: {
        field: PRICE_COHORT_COLUMN_KEYS.CONTROL_PRICE,
        label: "Control Price",
    },
    [PRICE_COHORT_COLUMN_KEYS.PRICE_VARIANT]: {
        field: PRICE_COHORT_COLUMN_KEYS.PRICE_VARIANT,
        label: "Price Variant",
    },
    [PRICE_COHORT_COLUMN_KEYS.PRICE_VARIANT_2]: {
        field: PRICE_COHORT_COLUMN_KEYS.PRICE_VARIANT_2,
        label: "Price Variant 2",
    },
    [PRICE_COHORT_COLUMN_KEYS.STATUS]: {
        field: PRICE_COHORT_COLUMN_KEYS.STATUS,
        label: "Status",
    },
}

export const predefinedStatusValues = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
];

export const PRICE_RANGES = [
    {
        label: 'Less than 2 Lakhs',
        value: {
            lower: 0,
            upper: 200000,
        }
    },
    {
        label: '2 Lakhs - 4 Lakhs',
        value: {
            lower: 200000,
            upper: 400000,
        }
    },
    {
        label: '4 Lakhs - 6 Lakhs',
        value: {
            lower: 400000,
            upper: 600000,
        }
    },
    {
        label: '6 Lakhs - 8 Lakhs',
        value: {
            lower: 600000,
            upper: 800000,
        }
    },
    {
        label: '8 Lakhs - 10 Lakhs',
        value: {
            lower: 800000,
            upper: 1000000,
        }
    },
    {
        label: 'Greater than 10 Lakhs',
        value: {
            lower: 1000000,
            upper: 100000000,
        }
    }
]