import { TYPE_CALLLOGS } from "../types/callLogs";

const initialState = {
  currentSort: null,
  filters: {
    sortBy: {
      conversationTime: 'asc',
      callTime: 'asc'
    },


  }


};

const callLogs = (state = initialState, action) => {
  switch (action.type) {

    case TYPE_CALLLOGS.SET_FILTER_STATUS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      }

    case TYPE_CALLLOGS.SET_CURRENT_SORT:
      return {
        ...state,
        currentSort: action.payload
      }


    default:
      return state;
  }
};

export default callLogs;
