import { useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ChallanCreateOrder from "../../components/create-order";
import { formData } from "../../components/create-order/viewModel";
import { ChallanService } from "../../../../services";
import {
  showToastMessage,
  getLoginUserEmail,
  getFFUserType,
} from "../../../../utils/utils";
import { challan_request_keys } from "../viewModels";
import {
  NUMBERS,
  FF_PAGE_TYPES,
} from "../../../../utils/constants/values.constants";
import { FormRadioGroup, FormSearchInput } from "../../../../components";
import {
  setChallanRequestSearchQuery,
  setChallanDisposeSearchQuery,
} from "../../../../store/actions/ops-challan";
import { useDispatch, useSelector } from "react-redux";
import { USER_ROLE_KEYS } from "../../../../utils/constants/menu.constants";
import { disposerSearchOptions, finderSearchOptions } from "./viewModal";
import { challan_item_keys } from "../../components/challan-detail-revamp/viewModel";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Actions = (props) => {
  const [openCreateOrderDialog, setOpenCreateOrderDialog] = useState(false);
  const [createOrderData, setCreateOrderData] = useState(formData);
  const [isActionSubmit, setIsActionSubmit] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchOptionValue, setSearchOptionValue] = useState(
    challan_item_keys.REG_NUMBER
  );
  const dispatch = useDispatch();
  const query = useSelector((state) => state.opsChallan.searchQuery);
  const userType = getFFUserType();
  const isQA = ["STAGING", "QA"].includes(process.env.REACT_APP_ENV);
  const history = useHistory();
  const openDialogCreateOrder = () => {
    setOpenCreateOrderDialog(!openCreateOrderDialog);
  };

  const submitCreateOrder = () => {
    const payload = {
      [challan_request_keys.APPOINTMENT_ORDER_ID]:
        createOrderData.appointmentOrderId?.value,
      [challan_request_keys.CHALLAN_SOURCE]:
        createOrderData[challan_request_keys.CHALLAN_SOURCE]?.value?.value,
      [challan_request_keys.REG_NUMBER]: createOrderData.regNumber?.value,
      [challan_request_keys.RETAIL_REGION]: createOrderData.retailRegion?.value,
      [challan_request_keys.DESCRIPTION]: createOrderData.description?.value,
      [challan_request_keys.ASSIGN_TO]: getLoginUserEmail(),
    };
    //check validity of existing payload //todo
    if (!payload[challan_request_keys.APPOINTMENT_ORDER_ID]) {
      showToastMessage("Please select valid Appt Id/Order Id", false);
      return;
    } else if (
      payload[challan_request_keys.APPOINTMENT_ORDER_ID].length < NUMBERS.SIX ||
      payload[challan_request_keys.APPOINTMENT_ORDER_ID].length > NUMBERS.ELEVEN
    ) {
      showToastMessage(
        "Please select Appt Id/Order Id of length between 6 to 11",
        false
      );
      return;
    } else if (!payload[challan_request_keys.CHALLAN_SOURCE]) {
      showToastMessage("Please select source type", false);
      return;
    } else if (!payload[challan_request_keys.REG_NUMBER]) {
      showToastMessage("Please select valid Registration Number", false);
      return;
    } else if (
      payload[challan_request_keys.REG_NUMBER].length < NUMBERS.SIX ||
      payload[challan_request_keys.REG_NUMBER].length > NUMBERS.TWELEVE
    ) {
      showToastMessage(
        "Please select Registration Number of length between 6 to 12",
        false
      );
      return;
    } else if (!payload[challan_request_keys.RETAIL_REGION]) {
      showToastMessage("Please select valid Retail region", false);
      return;
    } else if (
      !!payload[challan_request_keys.DESCRIPTION] &&
      payload[challan_request_keys.DESCRIPTION].length >
        NUMBERS.TWO * NUMBERS.HUNDREAD
    ) {
      showToastMessage(
        "Please select Description of length less than 200",
        false
      );
      return;
    }
    setIsActionSubmit(true);
    ChallanService.createOrder(payload)
      .then((resp) => {
        setCreateOrderData(formData);
        props.setIsRefreshRequired(true);
        setOpenCreateOrderDialog(false);
        showToastMessage(resp?.message);
      })
      .catch((err) => {
        showToastMessage(err.message, false);
      })
      .finally(() => {
        setIsActionSubmit(false);
      });
  };

  const handleChange = (e) => {
    setSearchQuery(e?.target?.value);
  };

  const handleSearch = () => {
    if (!!searchQuery && searchQuery.trim() && query !== searchQuery) {
      if (props?.pageKey === FF_PAGE_TYPES.DISPOSE) {
        dispatch(
          setChallanDisposeSearchQuery({
            selectedOption: searchOptionValue,
            value: searchQuery,
          })
        );
      } else {
        dispatch(
          setChallanRequestSearchQuery({
            selectedOption: searchOptionValue,
            value: searchQuery,
          })
        );
      }
    }
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    if (props?.pageKey === FF_PAGE_TYPES.DISPOSE) {
      dispatch(setChallanDisposeSearchQuery(""));
    } else {
      dispatch(setChallanRequestSearchQuery(""));
    }
  };

  useEffect(() => {
    !query && setSearchQuery("");
  }, [query]);

  const handleRadioChange = (e) => {
    const { value } = e || {};
    setSearchOptionValue(value);
  };

  const handleVersionChange = () => {
    if (!!isQA) {
      history.push(
        userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN
          ? props?.pageKey === FF_PAGE_TYPES.DISPOSE
            ? "/operations/admin/disposers"
            : "/operations/admin/finders"
          : "/operations/challans"
      );
    }
  };

  return (
    <>
      <div className="ops-challan-actions">
        <div className="k-form-buttons">
          {!!props.isBackRequired && (
            <Button themeColor={"primary"} fillMode="outline">
              Back
            </Button>
          )}
          {!!isQA && (
            <Button
              themeColor={"primary"}
              fillMode={"outline"}
              onClick={handleVersionChange}
            >
              V2
            </Button>
          )}
        </div>
        {!props.isItemDetail && (
          <div className="search-tag k-form-buttons">
            <FormRadioGroup
              data={
                props?.pageKey === FF_PAGE_TYPES.DISPOSE
                  ? disposerSearchOptions
                  : finderSearchOptions
              }
              onChange={handleRadioChange}
              defaultValue={
                props?.pageKey === FF_PAGE_TYPES.DISPOSE
                  ? disposerSearchOptions[0]?.value
                  : finderSearchOptions[0]?.value
              }
            />
            <FormSearchInput
              value={searchQuery}
              placeholder={"Search"}
              handleChange={handleChange}
              iconName={"search"}
              handleInputSuffixAction={handleSearch}
              handleClearSearch={handleClearSearch}
            />
            <Button
              className="m-2"
              icon="k-icon k-i-download"
              themeColor={"primary"}
              fillMode="outline"
              disabled={props.isDataExport}
              onClick={props.onExportDataStart}
            >
              Export
            </Button>
            {userType === USER_ROLE_KEYS.DISPOSE && (
              <Button
                className="m-2"
                themeColor={"primary"}
                onClick={props.onAssignItems}
              >
                Assign to Me
              </Button>
            )}
            {userType === USER_ROLE_KEYS.FINDER && (
              <Button
                className="m-2"
                themeColor={"primary"}
                onClick={openDialogCreateOrder}
              >
                Create Request
              </Button>
            )}
          </div>
        )}
      </div>
      {openCreateOrderDialog && (
        <Dialog title={"Create Request"} onClose={openDialogCreateOrder}>
          <ChallanCreateOrder
            data={createOrderData}
            setData={setCreateOrderData}
          />
          <DialogActionsBar>
            <Button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={submitCreateOrder}
              themeColor={"primary"}
            >
              Submit
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};
export default Actions;
