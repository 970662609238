import { Button } from "@progress/kendo-react-buttons";
import { GridLayout } from "@progress/kendo-react-layout";
import React from "react";
import {
  FormCheckbox,
  FormComboBox,
  FormInput,
  FormDatePicker,
  FormInputTick,
  KendoCard,
  //   SingleFileUpload,
  //   UploadFile,
} from "../../../../components";
import {
  FORM_ELEMENT_TYPE,
  //   CHALLAN_ITEM_STATUSES,
} from "../../../../utils/constants/values.constants";
import UploadFileLayout, { UploadFileLayoutV2 } from "../layout-upload-file";
import { CHECKBOX_FILE_VIEW } from "../../dispose-container/dispose-detail-container/viewModal";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { Label } from "@progress/kendo-react-labels";

const FormCardLayout = (props) => {
  const {
      formData,
      isShowCTA = true,
      showResetCTA = true,
      checkboxValue,
      label,
      confirmCTAText = "Confirm",
      isConfirmDisabled = false,
      handleEditorChange = null,
  } = props || {};

  const {
    Bold,
    Italic,
    Underline,
    AlignLeft,
    AlignRight,
    AlignCenter,
    OrderedList,
    UnorderedList,
    ViewHtml,
    FontSize,
    FontName
  } = EditorTools;

  const style = {
    background: "#FFFFFF",
    minHeight: "180px",
    border: "15px solid #EBF5FF",
    boxShadow: "unset",
    borderRadius: "8px",
  };

  const headerStyle = {
    color: "#003E72",
    fontWeight: "600",
    fontSize: "18px",
  };

    return (
        <div>
            <KendoCard
                headerTitle={props.headerTitle}
                cardStyle={{ ...style, ...props?.cardStyle }}
                headerStyle={headerStyle}
                details={
                    <>
                        <GridLayout
                            className="formData_gridLayout"
                            gap={{ rows: 20, cols: 100, }}
                            style={{ display: "flex", flexWrap: "wrap", padding: "0px 24px", }}
                        >
                            {formData &&
                                Object.keys(formData)?.map((key, index) => {
                                    const elementMetaData = formData[key];
                                    if (!elementMetaData.hide) {
                                        switch (elementMetaData.elementtype) {
                                            case FORM_ELEMENT_TYPE.SELECT:
                                                return (
                                                    <div style={{ width: "44%" }} className="form_layout_elements">
                                                        <FormComboBox
                                                            id={key}
                                                            key={key}
                                                            onChange={props?.handleChange}
                                                            data={elementMetaData?.source}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            filterable={true}
                                                            isDisplayLabel={elementMetaData?.label}
                                                            value={formData[key]?.value}
                                                            {...elementMetaData}
                                                        />
                                                    </div>
                                                );
                                            case FORM_ELEMENT_TYPE.UPLOAD_FILE:
                                                return (
                                                      <div style={{ width: "44%" }} className="form_layout_elements">
                                                            {!!elementMetaData.isCheckedFile ? (
                                                                  <>
                                                                        <div style={{ marginTop: "20px" }}>
                                                                              <FormCheckbox
                                                                                    name={CHECKBOX_FILE_VIEW}
                                                                                    label={
                                                    elementMetaData?.checkboxLabel
                                                }
                                                                                    onChange={props?.handleChange}
                                                                                    value={checkboxValue}
                                                                                    disabled={
                                                    elementMetaData?.disabled
                                                }
                                                                              />
                                                                        </div>
                                                                        {!!checkboxValue && (
                                                                              <UploadFileLayout
                                                                                    id={key}
                                                                                    key={key}
                                                                                    handleChange={
                                                    props?.handleChange
                                                }
                                                                                    {...elementMetaData}
                                                                              />
                                                                        )}
                                                                  </>
                                                            ) : (
                                                                  <UploadFileLayout
                                                                        id={key}
                                                                        key={key}
                                                                        handleChange={(e) =>
                                            props?.handleChange(e)
                                        }
                                                                        {...elementMetaData}
                                                                  />
                                                            )}
                                                      </div>
                                                );
                                            case FORM_ELEMENT_TYPE.INPUT_RIGHT_TICK:
                                                return (
                                                    <div style={{ width: "44%" }} className="form_layout_elements">
                                                        <FormInputTick
                                                            id={key}
                                                            key={key}
                                                            onChange={props?.handleChange}
                                                            onBlur={props?.onInputLostFocus}
                                                            value={formData[key]?.value || ""}
                                                            {...elementMetaData}
                                                        />
                                                    </div>
                                                );
                                            case FORM_ELEMENT_TYPE.RICH_TEXT_EDITOR:
                                              return (
                                                <div style={{ width: "44%", marginBottom: '20px' }}>
                                                  <Label
                                                    >
                                                    {elementMetaData?.label}
                                                  </Label>
                                                  <Editor
                                                    id={key}
                                                    key={key}
                                                    tools={[
                                                      [
                                                        Bold,
                                                        Italic,
                                                        Underline,
                                                        AlignLeft,
                                                        AlignRight,
                                                        AlignCenter,
                                                        OrderedList,
                                                        UnorderedList,
                                                        ViewHtml,
                                                        FontSize,
                                                        FontName
                                                      ],
                                                    ]}
                                                    value={formData[key]?.value ? formData[key]?.value : ""}
                                                    onChange={(event) => handleEditorChange(event, key)}
                                                    contentStyle={{ height: 100 }}
                                                    placeholder={elementMetaData?.placeholder}
                                                  />
                                                </div>
                                              )
                                            case FORM_ELEMENT_TYPE.DATE:
                                              return (
                                                <div style={{ width: "44%" }}>
                                                  <FormDatePicker
                                                    id={key}
                                                    key={key}
                                                    value={formData[key]?.value || null}
                                                    onChange={props?.handleChange}
                                                    {...elementMetaData}
                                                  />
                                                </div>
                                              );
                                            case FORM_ELEMENT_TYPE.BUTTON:
                                                return (
                                                    <div style={{ width: "44%" }} className="form_layout_elements">
                                                        <div>
                                                            {
                                                                formData[key]?.label
                                                                &&
                                                                <div className="form_layout_elements_btn">{formData[key]?.label}</div>
                                                            }
                                                            <Button
                                                                onClick={formData[key].onClick}
                                                                themeColor={"primary"}
                                                                fillMode={"outline"}
                                                                className="m-1"
                                                            >
                                                                {formData[key]?.buttonTitle}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                );
                                            case FORM_ELEMENT_TYPE.UPLOAD_FILE_V2:
                                              return (
                                                <div style={{ width: "23%" }} className="form_layout_elements">
                                                  {
                                                    !!elementMetaData.isCheckedFile 
                                                    ? 
                                                      (
                                                        <>
                                                          <div style={{ marginTop: "20px" }}>
                                                            <FormCheckbox
                                                              name={CHECKBOX_FILE_VIEW}
                                                              label={elementMetaData?.checkboxLabel}
                                                              onChange={props?.handleChange}
                                                              value={checkboxValue}
                                                              disabled={elementMetaData?.disabled}
                                                            />
                                                          </div>
                                                              {!!checkboxValue && (
                                                                    <UploadFileLayoutV2
                                                                          id={index}
                                                                          key={index}
                                                                          handleChange={props?.handleChange}
                                                                          {...elementMetaData}
                                                                    />
                                                              )}
                                                        </>
                                                      ) 
                                                    : 
                                                      (
                                                        <UploadFileLayoutV2
                                                              id={index}
                                                              key={index}
                                                              handleChange={(e) =>props?.handleChange(e)}
                                                              {...elementMetaData}
                                                        />
                                                      )
                                                  }
                                                </div>
                                              );
                                            default:
                                                return (
                                                    <div style={{ width: "44%" }} className="form_layout_elements">
                                                        <FormInput
                                                            id={key}
                                                            key={key}
                                                            onChange={props?.handleChange}
                                                            value={formData[key]?.value || ""}
                                                            {...elementMetaData}
                                                        />
                                                    </div>
                                                );
                                        }
                                    }
                                })}
                        </GridLayout>
                        {isShowCTA && (
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            {showResetCTA && 
                                  <Button
                                      onClick={props?.handleReset}
                                      themeColor={"primary"}
                                      fillMode={"outline"}
                                      className="m-1"
                                  >
                                      Reset
                                  </Button>
                }
                                <Button
                                    onClick={props?.handleConfirm}
                                    themeColor={"primary"}
                                    className="m-1"
                                    disabled={isConfirmDisabled}
                                >
                                    {confirmCTAText}
                                </Button>
                            </div>
                        )}
                    </>
                }
            />
        </div>
    );
};

export default FormCardLayout;
