import "../common/assets/styles/common.scss";
import "./car-details.scss";
import AppTable from "../common/components/app-table"
import { FIELDS_KEY, TABLE_LABELS } from "../common/constants/table.constants"
import { useEffect, useState } from "react";
import { currencyFormat, percentageFormat } from "../common/utils/utils";
const CarsOCBView = ({ carDetails }) => {
	const tableValues = {
		highestNegoPrice: '',
		requestPrice: '',
		requestedPrice: '',
		targetPrice: '',
	}
	const [data, setData] = useState([tableValues]);

	useEffect(() => {
		let ocbDetails = carDetails?.ocbDetails;
		if (ocbDetails) {
			tableValues.highestNegoPrice = currencyFormat(ocbDetails?.highestNegoPrice);
			tableValues.requestPrice = percentageFormat(ocbDetails?.requestedPriceTpRatio);
			tableValues.targetPrice = currencyFormat(carDetails?.appointmentDetails?.targetPrice);
			tableValues.requestedPrice = currencyFormat(ocbDetails?.requestedPrice);
			setData([{ ...tableValues }]);
		}
	}, [carDetails])

	const SafeCells = safeCellProps => {
		const event = {
			dataItem: safeCellProps.data
		};
		return (
			// eslint-disable-next-line no-use-before-define
			<td onClick={() => navigateTo(event)}>
				{safeCellProps.children}
			</td>
		);
	};
	const tableColumns = [
		{
			field: FIELDS_KEY.HighestNegoPrice,
			title: TABLE_LABELS.HighestNegoPrice,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem.highestNegoPrice}</div>
				</SafeCells>
			),
		},
		{
			field: FIELDS_KEY.ReqPriceTP,
			title: TABLE_LABELS.ReqPriceTP,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem.requestPrice}</div>
				</SafeCells>
			),
		},
		{
			title: TABLE_LABELS.RequestedPrice,
			field: FIELDS_KEY.RequestedPrice,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem.requestedPrice}</div>
				</SafeCells>
			),
		}, {
			field: FIELDS_KEY.TargetPrice,
			title: TABLE_LABELS.TargetPrice,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem.targetPrice}</div>
				</SafeCells>
			),
		}];

	return (
		<>
			<div className="current-bids-grid app-table-view-sm app-table">
				<AppTable
					data={data}
					headerData={[
						...tableColumns
					]}
					isHoverable={false}
					totalCount={data.length}
					handlePageChange={() => { }}
					pageable={false}
				/>
			</div>
		</>
	);
};
export default CarsOCBView;
