import { PR_AUTOMATION } from "../types";
import {
  dealerService,
  InventoryService,
  PrAutomationService,
} from "../../services";
import { showToastMessage } from "../../utils/utils";

export const fetchKycDocs =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.fetchKycDocs(params)
      .then((resp) => {
        dispatch({
          type: PR_AUTOMATION.FETCH_KYC_DOCS_SUCCESS,
          payload: {
            data: resp.detail,
          },
        });
      })
      .catch((err) => {
        if (err && err?.status !== 404) {
          showToastMessage(err?.message || "Error", false);
        }
        dispatch({
          type: PR_AUTOMATION.FETCH_KYC_DOCS_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
export const fetchDocsStatus =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.fetchDocsStatus(params)
      .then((resp) => {
        dispatch({
          type: PR_AUTOMATION.FETCH_DOCS_STATUS_SUCCESS,
          payload: {
            data: resp.data,
          },
        });
      })
      .catch((err) => {
        if (err && err?.status !== 404) {
          showToastMessage(err?.message || "Error", false);
        }
        dispatch({
          type: PR_AUTOMATION.FETCH_DOCS_STATUS_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
export const fetchPrList =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.fetchPrList(params)
      .then((resp) => {
        dispatch({
          type: PR_AUTOMATION.FETCH_PR_LIST_SUCCESS,
          payload: {
            data: resp.detail,
          },
        });
      })
      .catch((err) => {
        showToastMessage(err?.message || "Error", false);
        dispatch({
          type: PR_AUTOMATION.FETCH_PR_LIST_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };

export const fetchVahanMismatch =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.fetchVahanMismatch(params)
      .then((resp) => {
        dispatch({
          type: PR_AUTOMATION.FETCH_VAHAN_MISMATCH_SUCCESS,
          payload: {
            data: resp.detail,
          },
        });
      })
      .catch((err) => {
        showToastMessage(err?.message || "Error", false);
        dispatch({
          type: PR_AUTOMATION.FETCH_VAHAN_MISMATCH_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };

export const fetchServiceHistory =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.fetchServiceHistory(params)
      .then((resp) => {
        dispatch({
          type: PR_AUTOMATION.FETCH_SERVICE_HISTORY_SUCCESS,
          payload: {
            data: resp.detail,
          },
        });
      })
      .catch((err) => {
        showToastMessage(err?.message || "Error", false);
        dispatch({
          type: PR_AUTOMATION.FETCH_SERVICE_HISTORY_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
export const fetchOrderDetails =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.fetchOrderDetails(params)
      .then((resp) => {
        dispatch({
          type: PR_AUTOMATION.FETCH_PR_DETAILS_SUCCESS,
          payload: {
            data: resp.data,
          },
        });
      })
      .catch((err) => {
        showToastMessage(err?.message || "Error", false);
        dispatch({
          type: PR_AUTOMATION.FETCH_PR_DETAILS_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
//temporarily added below api in PR automation actions
export const fetchC24QuoteDetails =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    dealerService
      .fetchC24Quote(params)
      .then((resp) => {
        dispatch({
          type: PR_AUTOMATION.FETCH_PR_C24QUOTE_SUCCESS,
          payload: {
            data: resp.data,
          },
        });
      })
      .catch((err) => {
        showToastMessage(err?.message || "Error", false);
        dispatch({
          type: PR_AUTOMATION.FETCH_PR_C24QUOTE_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
export const fetchHypoBanks =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.fetchHypoBanks(params)
      .then((resp) => {
        dispatch({
          type: PR_AUTOMATION.FETCH_HYPO_BANKS_SUCCESS,
          payload: {
            data: resp.detail,
          },
        });
      })
      .catch((err) => {
        showToastMessage(err?.message || "Error", false);
        dispatch({
          type: PR_AUTOMATION.FETCH_HYPO_BANKS_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
export const fetchServiceHistoryEligibility =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.fetchServiceHistoryEligibility(params)
      .then((resp) => {
        dispatch({
          type: PR_AUTOMATION.FETCH_SERVICE_ELIGIBLE_SUCCESS,
          payload: {
            data: resp.detail,
          },
        });
      })
      .catch((err) => {
        showToastMessage(err?.message || "Error", false);
        dispatch({
          type: PR_AUTOMATION.FETCH_SERVICE_ELIGIBLE_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
export const fetchBusinessApprovals =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.fetchBusinessApprovals(params)
      .then((resp) => {
        dispatch({
          type: PR_AUTOMATION.FETCH_BUSINESS_APPROVAL_SUCCESS,
          payload: {
            data: resp.detail,
          },
        });
      })
      .catch((err) => {
        if (err && err?.status !== 404) {
          showToastMessage(err?.message || "Error", false);
        }
        dispatch({
          type: PR_AUTOMATION.FETCH_BUSINESS_APPROVAL_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
export const fetchBusinessApprovalTypes =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.fetchBusinessApprovalTypes(params)
      .then((resp) => {
        dispatch({
          type: PR_AUTOMATION.FETCH_BUSINESS_APPROVAL_TYPES_SUCCESS,
          payload: {
            data: resp.detail,
          },
        });
      })
      .catch((err) => {
        showToastMessage(err?.message || "Error", false);
        dispatch({
          type: PR_AUTOMATION.FETCH_BUSINESS_APPROVAL_TYPES_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
export const fetchStateList =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.fetchStates(params)
      .then((resp) => {
        dispatch({
          type: PR_AUTOMATION.FETCH_STATES_SUCCESS,
          payload: {
            data: resp.detail,
          },
        });
      })
      .catch((err) => {
        if (err && err?.status !== 404) {
          showToastMessage(err?.message || "Error", false);
        }
        dispatch({
          type: PR_AUTOMATION.FETCH_STATES_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };

export const fetchCityList =
  (params = {}) =>
  (dispatch) => {
    // dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.fetchCities(params)
      .then((resp) => {
        dispatch({
          type: PR_AUTOMATION.FETCH_CITIES_SUCCESS,
          payload: {
            data: resp.detail,
          },
        });
      })
      .catch((err) => {
        if (err) {
          showToastMessage(err?.message || "Error", false);
        }
        dispatch({
          type: PR_AUTOMATION.FETCH_CITIES_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
export const fetchSlots =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: PR_AUTOMATION.FETCH_SLOTS_LOADING_START });
    InventoryService.fetchSlots(params)
      .then((resp) => {
        dispatch({
          type: PR_AUTOMATION.FETCH_SLOTS_SUCCESS,
          payload: {
            data: resp,
          },
        });
      })
      .catch((err) => {
        showToastMessage(err?.message || "Error", false);
        dispatch({
          type: PR_AUTOMATION.FETCH_SLOTS_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_SLOTS_LOADING_END });
      });
  };

export const downloadDocument =
  (params = {}, docCode = "") =>
  (dispatch) => {
    dispatch({ type: PR_AUTOMATION.FETCH_DOC_LOADING_START });
    PrAutomationService.downloadDocument(params, docCode)
      .then((resp) => {
        window.open(resp?.detail, "_blank");
        showToastMessage("Document downloaded successfully", true);
      })
      .catch((err) => {
        showToastMessage(err?.message || "Error", false);
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_DOC_LOADING_END });
      });
  };
