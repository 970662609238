import React, { useState, useEffect } from "react";
import { FormComboBox, FormSearchInput } from "../../../../components";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { FF_PAGE_TYPES } from "../../../../utils/constants/values.constants";
import {
	serviceHistorySearchOptions,
	disposerSearchOptions,
	finderSearchOptions,
	viewDisposerSearchOptions,
	verificationSearchOptions,
} from "./viewModal";
import { useDispatch, useSelector } from "react-redux";
import {
	setChallanRequestSearchQuery,
	setChallanDisposeSearchQuery,
} from "../../../../store/actions/ops-challan";
import { setServiceHistorySearchQuery } from "../../../../store/actions/service-history";
import { setVerificationSearchQuery } from "../../../../store/actions/ops-verification";
const SearchBarLayout = (props) => {
	const { type = "" } = props;
	const [searchOptions, setSearchOptions] = useState([]);
	const [searchOption, setSearchOption] = useState("");
	const [searchQuery, setSearchQuery] = useState("");

	const dispatch = useDispatch();
	const query = useSelector((state) => state.opsChallan.searchQuery);

	useEffect(() => {
		let searchData = serviceHistorySearchOptions;
		switch (type) {
			case FF_PAGE_TYPES.DISPOSE_VIEW:
				searchData = viewDisposerSearchOptions;
				break;
			case FF_PAGE_TYPES.DISPOSE:
				searchData = disposerSearchOptions;
				break;
			case FF_PAGE_TYPES.FINDERS:
				searchData = finderSearchOptions;
				break;
			case FF_PAGE_TYPES.SERVICE_HISTORY:
				searchData = serviceHistorySearchOptions;
				break;
			case FF_PAGE_TYPES.VERIFICATION:
				searchData = verificationSearchOptions;
				break;
			default:
				searchData = serviceHistorySearchOptions;
				break;
		}
		setSearchOptions(searchData);
		setSearchOption(searchData[0]);
	}, [type]);

	useEffect(() => {
		!query && setSearchQuery("");
	}, [query]);

	const onChangeSearchParams = (e) => {
		setSearchOption(e?.value);
	};
	const handleChange = (e) => {
		setSearchQuery(e?.target?.value);
	};
	const handleSearch = () => {
		if (!!searchQuery && searchQuery.trim() && query !== searchQuery) {
			if ([FF_PAGE_TYPES.DISPOSE, FF_PAGE_TYPES.DISPOSE_VIEW].includes(type)) {
				dispatch(
					setChallanDisposeSearchQuery({
						selectedOption: searchOption?.value,
						value: searchQuery,
					})
				);
			} else if (FF_PAGE_TYPES.FINDERS === type)  {
				dispatch(
					setChallanRequestSearchQuery({
						selectedOption: searchOption?.value,
						value: searchQuery,
					})
				);
			} else if (FF_PAGE_TYPES.SERVICE_HISTORY === type) {
				dispatch(
					setServiceHistorySearchQuery({
						selectedOption: searchOption?.value,
						value: searchQuery,
					})
				);
			} else if (FF_PAGE_TYPES.VERIFICATION === type) {
				dispatch(
					setVerificationSearchQuery({
						selectedOption: searchOption?.value,
						value: searchQuery,
					})
				);
			}
		}
	};
	const handleClearSearch = () => {
		setSearchQuery("");
		if ([FF_PAGE_TYPES.DISPOSE, FF_PAGE_TYPES.DISPOSE_VIEW].includes(type)) {
			dispatch(setChallanDisposeSearchQuery(""));
		} else if (FF_PAGE_TYPES.FINDERS === type) {
			dispatch(setChallanRequestSearchQuery(""));
		} else if (FF_PAGE_TYPES.SERVICE_HISTORY === type) {
			dispatch(setServiceHistorySearchQuery(""));
		} else if (FF_PAGE_TYPES.VERIFICATION === type) {
			dispatch(setVerificationSearchQuery(""));
		}
	};
	return (
		<div className="search-bar-container">
			<GridLayout
				gap={{
					cols: 10,
					rows: 2,
				}}>
				<GridLayoutItem col={1}>
					<FormComboBox
						id={"search-bar-layout-combo"}
						key={"search-bar-layout-combo"}
						onChange={onChangeSearchParams}
						data={searchOptions}
						textField="label"
						dataItemKey="value"
						label={searchOption?.label}
						defaultValue={searchOption?.value}
						value={searchOption?.value}
					/>
				</GridLayoutItem>
				<GridLayoutItem col={2}>
					<FormSearchInput
						value={searchQuery}
						placeholder={`Search for ${searchOption?.label}`}
						handleChange={handleChange}
						iconName={"search"}
						handleInputSuffixAction={handleSearch}
						handleClearSearch={handleClearSearch}
					/>
				</GridLayoutItem>
			</GridLayout>
		</div>
	);
};

export default SearchBarLayout;
