import React, { useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  getStatus,
  formatDateV2,
  showToastMessage,
  getUserRoles,
} from "../../../../../utils/utils";
// import DataGrid from "../../../../../common/data-grid";
import {
  FormComboBox,
  KendoCard,
  // SingleFileUpload,
  UploadFile,
} from "../../../../../components";
import { TextBox } from "@progress/kendo-react-inputs";
import {
  FULFILLMENT_STATUS,
  getDisplayedCoulmns,
  serviceDetailsColumns,
} from "./viewModels";
import { ServiceHistory } from "../../../../../services";
import { Loader } from "../../../../../common/Loader";
import ServiceDetailActions from "./components/actions";
import CustomGrid from "../../../../../common/custom-grid/component";
import { useHistory } from "react-router-dom";
import { USER_ROLE_KEYS } from "../../../../../utils/constants/menu.constants";
import { SERVICE_HISTORY_STATUSES } from "../../viewModels";

const ServiceHistoryDetails = (props) => {
  const {
    match: {
      params: { orderId },
    },
  } = props;
  const isAdmin = getUserRoles()?.includes(
    USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_ADMIN
  );
  const [orderData, setOrderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fulfillmentStatus, setFulfillmentStatus] = useState(null);
  const [file, setFile] = useState(null);
  const [reason, setReason] = useState();
  const {
    createdAt,
    make,
    model,
    variant,
    year,
    status,
    regNumber,
    orderId: appOrderId,
    orderItemId,
    idServiceHistoryRequest,
    pdfUrl,
    appointmentOrderNumber,
    source,
  } = orderData;
  const statusData = getStatus(status);
  const fileName = (pdfUrl || "").replace(/^.*[\\\/]/, "");
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    ServiceHistory.getServiceHistoryDetails(orderId)
      .then((resp) => {
        setOrderData(resp?.detail);
        if (resp?.detail?.status === SERVICE_HISTORY_STATUSES.FULFILLED) {
          setFulfillmentStatus(resp?.detail?.status);
        } else if (
          resp?.detail?.status === SERVICE_HISTORY_STATUSES.UNABLE_TO_FULFILL
        ) {
          setFulfillmentStatus(resp?.detail?.status);
          setReason(resp?.detail?.remarks);
        }
      })
      .catch((err) => {
        setOrderData([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [orderId]);

  const onInputChange = (e) => {
    if (e.target.type === "file") {
      setFile(e.target.files[0]);
    } else {
      setFulfillmentStatus(e?.value?.value);
    }
  };
  const handleOnSubmit = () => {
    if (!fulfillmentStatus) {
      showToastMessage("Select fulfillmentStatus", false);
    } else if (
      fulfillmentStatus === SERVICE_HISTORY_STATUSES.FULFILLED &&
      !file
    ) {
      showToastMessage("Add Pdf File", false);
    } else if (
      fulfillmentStatus === SERVICE_HISTORY_STATUSES.UNABLE_TO_FULFILL &&
      !reason
    ) {
      showToastMessage("Add Reason", false);
    } else {
      let formData = new FormData();
      formData.append("status", fulfillmentStatus);
      formData.append("file", file);
      fulfillmentStatus === SERVICE_HISTORY_STATUSES.UNABLE_TO_FULFILL &&
        formData.append("remarks", reason);
      ServiceHistory.serviceHistoryFulfillment(
        idServiceHistoryRequest,
        formData
      ).then((res) => {
        showToastMessage(res?.message);
        history.push("/operations/services");
      });
    }
  };

  const handleOpenFile = () => {
    if (!!orderData) {
      ServiceHistory.getServiceHistoryPdfUrl(appOrderId, orderItemId).then(
        (res) => {
          window.open(res?.detail, "_blank", "noopener");
        }
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ServiceDetailActions
            appOrderId={appOrderId || appointmentOrderNumber}
          />
          <div className="service-history-wrapper">
            <div className="service-history-top-banner">
              <div className="order-id-info">
                <div className="order-id">
                  <span className="order-id-label">
                    {appOrderId
                      ? `OrderID - ${appOrderId}`
                      : `Appointment OrderNumber - ${appointmentOrderNumber}`}
                  </span>
                  <span className="order-id-date">
                    Creation Date: {formatDateV2(createdAt)}
                  </span>
                </div>
                <span className={`${statusData?.css}`}>
                  {statusData?.label}
                </span>
                {source === "LeadMon" && (
                  <span className="leadmon-tag">LeadMon</span>
                )}
              </div>
              {status === SERVICE_HISTORY_STATUSES.ASSIGNED && !isAdmin && (
                <Button
                  className="m-2 service-detail-cta"
                  themeColor={"primary"}
                  onClick={handleOnSubmit}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
          <KendoCard
            headerTitle={"Service History Details"}
            details={
              <CustomGrid
                // title="Dealer Centre List"
                displayedColumns={getDisplayedCoulmns()}
                data={[orderData] || []}
                columnMap={serviceDetailsColumns}
              />
            }
          />
          <KendoCard
            details={
              <GridLayout>
                <GridLayoutItem col={1}>
                  <KendoCard
                    headerTitle={"Attachments"}
                    cardStyle={{
                      background: "#F0F2F5",
                      minHeight: "170px",
                    }}
                    details={
                      <>
                        <span className="data-grid-label">Upload document</span>
                        {fileName ? (
                          <div
                            className="hyper-link serivce-details"
                            onClick={handleOpenFile}
                          >
                            {fileName}
                          </div>
                        ) : (
                          <UploadFile
                            // id={key}
                            // key={key}
                            onChange={onInputChange}
                            accept="application/pdf"
                            disabled={status !== "ASSIGNED" || isAdmin}
                          />
                        )}
                      </>
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem col={2}>
                  <KendoCard
                    headerTitle={"Action"}
                    cardStyle={{
                      minHeight: "170px",
                    }}
                    details={
                      <GridLayout
                        gap={{
                          rows: 2,
                          cols: 10,
                        }}
                      >
                        <GridLayoutItem col={1}>
                          <span>Fulfillment Status</span>
                        </GridLayoutItem>
                        <GridLayoutItem col={2}>
                          <FormComboBox
                            key={"fulfillmentStatus"}
                            onChange={onInputChange}
                            data={FULFILLMENT_STATUS}
                            textField="label"
                            dataItemKey="value"
                            disabled={
                              status !== SERVICE_HISTORY_STATUSES.ASSIGNED ||
                              isAdmin
                            }
                            value={{
                              label: fulfillmentStatus,
                              value: fulfillmentStatus,
                            }}
                          />
                        </GridLayoutItem>
                        {fulfillmentStatus ===
                          SERVICE_HISTORY_STATUSES.UNABLE_TO_FULFILL && (
                          <>
                            <span>Reason</span>
                            <GridLayoutItem col={2}>
                              <TextBox
                                placeholder="Enter"
                                onChange={(e) => setReason(e?.target?.value)}
                                value={reason}
                                disabled={
                                  status !==
                                    SERVICE_HISTORY_STATUSES.ASSIGNED || isAdmin
                                }
                              />
                            </GridLayoutItem>
                          </>
                        )}
                      </GridLayout>
                    }
                  />
                </GridLayoutItem>
              </GridLayout>
            }
          />
        </>
      )}
    </>
  );
};

export default ServiceHistoryDetails;
