
import { CircularGauge } from '@progress/kendo-react-gauges';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';

import './quality-modal.scss';
import { getColor, roundCallScore } from '../../../common/utils/utils';


const centerRenderer = (callScore) => {
  return (
    <h3
      style={{
        color: getColor(callScore)?.color,
      }}
    >
      {roundCallScore(callScore)}/10
    </h3>
  );
};

export const CallQualityModal = forwardRef((props, ref) => {

  const modalRef = useRef(null);
  const { dataItem } = props;

  const arcOptions = {
    value: roundCallScore(dataItem?.callScore) * 10,
    colors: [
      { from: 0, to: 50, color: '#ff6347' },
      { from: 50, to: 80, color: '#FFD700' },
      { from: 80, to: 100, color: '#28A745' }
    ],
    scale: {
      rangeSize: 10
    }

  };


  useEffect(() => {
    const adjustModalPosition = () => {
      const modalElement = modalRef.current;
      const tableElement = ref.current;

      if (modalElement && tableElement) {
        const modalRect = modalElement.getBoundingClientRect();
        const tableRect = tableElement.getBoundingClientRect();
        const availableHeightBelowTable = window.innerHeight - (tableRect.bottom + 100);

        if (modalRect.height > availableHeightBelowTable) {
          modalElement.style.top = `-${availableHeightBelowTable}%`;
        }

      }
    };

    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && !props.isModalOpen) {
        props.closeModal();
      }
    };

    // adjustModalPosition();
    // window.addEventListener('resize', adjustModalPosition);

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', adjustModalPosition);
    };
  }, [modalRef]);

  return (
    <div className='quality-modal-container' ref={modalRef}>
      <div className='modal-header'>
        <span className='title-text'>Your Score with {dataItem.dealerName} </span>
        <div className='gauge-container'>
          <CircularGauge
            {...arcOptions}
            style={{
              display: "block",
              height: 120,
            }}
            centerRender={() => centerRenderer(dataItem?.callScore)}
          />
        </div>
      </div>
      <div className='modal-body'>
        <span className='title-text'>Step up your game! 📈</span>
        {dataItem.callScoreParameters?.map((item, index) => (
          <div key={index} className='justify-between info-container'>
            <span className='info-text'>{item.parameter}</span>
            <span className={`info-text ${item.score === 0 || item.score === 1 ? 'red' : item.score === 2 ? 'yellow' : 'green'}`}>{item.score}/3</span>
          </div>
        ))}
        <Button onClick={() => {
          props.setIsModalOpen(true)
        }}>View Score Card</Button>
      </div>
    </div>
  )
});