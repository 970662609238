import { RETAIL_CONSUMER } from "../types";
const INITIAL_STATE = {
  isLoading: false,
  leadMon: {
    data: [],
    totalCount: 0,
    numberOfElements: 0,
    error: {},
  },
  filters: {},
  makers: [],
  models: [],
  variants: [],
  years: [],
  cities: [],
  locations: [],
};

const RetailConsumer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RETAIL_CONSUMER.FETCH_LOADING_START:
      return {
        ...state,
        isLoading: true,
      };
    case RETAIL_CONSUMER.FETCH_LOADING_END:
      return {
        ...state,
        isLoading: false,
      };
    case RETAIL_CONSUMER.FETCH_LEAD_MON_CONFIG_SUCCESS:
      return {
        ...state,
        leadMon: {
          data: action.payload.data,
          totalCount: action.payload.totalElements,
          numberOfElements: action.payload.numberOfElements,
          error: {},
        },
      };
    case RETAIL_CONSUMER.FETCH_LEAD_MON_CONFIG_FAILURE:
      return {
        ...state,
        leadMon: {
          ...state.leadMon,
          data: [],
          totalCount: 0,
          numberOfElements: 0,
          error: action.payload,
        },
      };
    case RETAIL_CONSUMER.SET_LEAD_TYPE_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case RETAIL_CONSUMER.FETCH_MAKERS_SUCCESS:
      return {
        ...state,
        makers: action.payload,
      };
    case RETAIL_CONSUMER.FETCH_MODEL_SUCCESS:
      return {
        ...state,
        models: action.payload,
      };
    case RETAIL_CONSUMER.RESET_MODEL:
      return {
        ...state,
        models: [],
      };
    case RETAIL_CONSUMER.FETCH_VARIANT_SUCCESS:
      return {
        ...state,
        variants: action.payload,
      };
    case RETAIL_CONSUMER.FETCH_CITY_SUCCESS:
      return {
        ...state,
        cities: action.payload,
      };

    case RETAIL_CONSUMER.FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        locations: action.payload,
      };
    case RETAIL_CONSUMER.RESET_LOCATION:
      return {
        ...state,
        locations: [],
      };
    case RETAIL_CONSUMER.FETCH_YEAR_SUCCESS:
      return {
        ...state,
        years: action.payload,
      };
    case RETAIL_CONSUMER.RESET_YEAR:
      return {
        ...state,
        years: [],
      };
    case RETAIL_CONSUMER.RESET_LEAD_MON_CONFIG:
      return {
        ...state,
        leadMon: {
          data: [],
          totalCount: 0,
          numberOfElements: 0,
        },
      };
    default:
      return state;
  }
};

export default RetailConsumer;
