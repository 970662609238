import React from 'react'
import { useHistory } from "react-router-dom";

function EditLead({ dataItem, subTabKey }) {
    const history = useHistory();
    const { leadId = "" } = dataItem;
    return (
        <span
            onClick={() =>
                history.push(`/seller-finance/leads/${leadId}`, { subTabKey })
            }
            style={{
                color: "#003E72",
                textDecoration: "underline",
                cursor: "pointer",
                wordBreak: "break-word",
                fontSize: "13px",
            }}
        >
            {dataItem?.leadId}
        </span>
    );
}

export default EditLead