import { ProfilePicCell } from "./components/ProfilePicCell";
import { VerificationStatusCell } from "./components/VerificationStatusCell";

export const cj_verification_keys = {
  NAME: "name",
	MOBILE: "mobile",
	EMAIL: "email",
  CJ_VERIFICATION_ID: "verificationId",
  STATUS: "isVerified",
  EXPERIENCE: 'experience',
  SPOKEN_LANGUAGES: 'spokenLanguages',
  PROFILE: 'profilePic'
}

export const dispose_columns = {
  [cj_verification_keys.NAME]: {
    label: "Name",
    field: cj_verification_keys.NAME,
  },
  [cj_verification_keys.MOBILE]: {
    label: "Mobile Number",
    field: cj_verification_keys.MOBILE,
  },
  [cj_verification_keys.EMAIL]: {
    label: "Email ID",
    field: cj_verification_keys.EMAIL,
  },
  [cj_verification_keys.EXPERIENCE]: {
    label: "Experience",
    field: cj_verification_keys.EXPERIENCE,
  },
  [cj_verification_keys.SPOKEN_LANGUAGES]: {
    label: "Spoken Languages",
    field: cj_verification_keys.SPOKEN_LANGUAGES,
  },
  [cj_verification_keys.PROFILE]: {
    label: "Profile",
    field: cj_verification_keys.PROFILE,
    cell: ProfilePicCell,
  },
  [cj_verification_keys.STATUS]: {
    label: "Status",
    field: cj_verification_keys.STATUS,
    cell: VerificationStatusCell,
    width: '300px'
  },
};

export const SELECTED_FIELD_KEY = "selected";