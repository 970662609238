import { showToastMessage } from "../../common/utils/utils";
import { vizPanelService } from "../../services";
import { TYPE_LOADER } from "../types";

export const getAllLogs = (params) => () => {

  return new Promise((res, rej) => {
    vizPanelService.getAllLogs(params).then((resp) => {
      res(resp);
    }).catch((error) => {
      showToastMessage(error?.error || error?.message, false);
      rej(error);
    });
  }
  );
};

export const getPresignedURL = (params) => (dispatch) => {
  dispatch({ type: TYPE_LOADER.SHOW_LOADER });

  return new Promise((res, rej) => {
    vizPanelService.getPresignedURL(params).then((resp) => {
      dispatch({ type: TYPE_LOADER.HIDE_LOADER });
      res(resp);
    }).catch((error) => {
      showToastMessage(error?.error || error?.message, false);
      dispatch({ type: TYPE_LOADER.HIDE_LOADER });
      rej(error);
    });
  }
  );
}

export const getDropDownData = () => (dispatch) => {
  dispatch({ type: TYPE_LOADER.SHOW_LOADER });

  return new Promise((res, rej) => {
    vizPanelService.getDropDownData().then((resp) => {
      dispatch({ type: TYPE_LOADER.HIDE_LOADER });
      res(resp);
    }).catch((error) => {
      showToastMessage(error?.error || error?.message, false);
      dispatch({ type: TYPE_LOADER.HIDE_LOADER });
      rej(error);
    });
  }
  );
}

export const uploadFile = (params) => () => {

  return new Promise((res, rej) => {
    vizPanelService.uploadFile(params).
      then((resp) => {
        res(resp);
      }).catch((error) => {
        showToastMessage(error?.error || error?.message, false);
        rej(error);
      });
  }
  );
}

export const putUploadFile = (params) => (dispatch) => {
  dispatch({ type: TYPE_LOADER.SHOW_LOADER });

  return new Promise((res, rej) => {
    vizPanelService.putUploadFile(params).
      then((resp) => {
        dispatch({ type: TYPE_LOADER.HIDE_LOADER });
        res(resp);
      }).catch((error) => {
        showToastMessage(error?.error || error?.message, false);
        dispatch({ type: TYPE_LOADER.HIDE_LOADER });
        rej(error);
      });
  }
  );
}

export const uploadFileToS3 = (params) => () => {

  return new Promise((res, rej) => {
    vizPanelService.uploadFileToS3(params).
      then(() => {
        res();
      }).catch((error) => {
        showToastMessage(error?.error || error?.message, false);
        rej(error);
      });
  }
  );

}

export const downloadFile = (params) => (dispatch) => {
  dispatch({ type: TYPE_LOADER.SHOW_LOADER });

  return new Promise((res, rej) => {
    vizPanelService.downloadFile(params).
      then((resp) => {
        dispatch({ type: TYPE_LOADER.HIDE_LOADER });
        res(resp);
      }).catch((error) => {
        showToastMessage(error?.error || error?.message, false);
        dispatch({ type: TYPE_LOADER.HIDE_LOADER });
        rej(error);
      });
  }
  );
}
