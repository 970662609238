import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { FileUpload } from './components/file-upload';
import LabelComponent from '../label';
import styles from '../../style.module.css';
import { isMp4Url } from '../../../../utils/helper';
import { thumborUrl, videoUrl } from '../../../../../../../utils/constants/url.constants';

const FileUploadComponent = ({ name, label }) => (
    
    <div className={styles.mb30}>
        <LabelComponent title={label} />
        <Field name={name}>
            {({ field, form }) => {
                const isUrl = typeof field.value === 'string';

                let imagePath = field.value;
                let imageUrl;
                if(imagePath && isUrl) {
                    imageUrl = `${isMp4Url(imagePath) ? videoUrl() : thumborUrl()}${imagePath}`;
                }

                return (
                    <FileUpload
                        fileName={isUrl ? imageUrl : field.value ? field.value.name : ''}
                        onFileSelect={(selectedFile) => form.setFieldValue(name, selectedFile)}
                        accept=".xlsx, .pdf, .jpg, .png, .mp4"
                        id={`${name}_id`}
                        name={name}
                        isUrl={isUrl}
                    />
                );
            }}
        </Field>
        <ErrorMessage name={name} component="div" className={styles.error} />
    </div>
);

export default FileUploadComponent;
