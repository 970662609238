import Footer from "./Footer";
import Header from "./Header";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Drawer, PanelBar, PanelBarUtils } from "@progress/kendo-react-layout";
import { convertToFlatArray, getLeftMenus, getFilename } from "../utils/utils";

const UiLayout = (props) => {
  const menus = getLeftMenus();
  const routes = convertToFlatArray(menus);
  const currentPath = window.location.pathname;
  const fileName = getFilename(currentPath);
  const expandMode = "multiple";
  const [expanded, setExpanded] = useState(true);
  const [panelExpanded, setPanelExpanded] = useState([]);
  const [selectItem, setSelectItem] = useState("");
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(
    // currentPath.includes("/retail/lms/negoview") ? false : true && currentPath.includes("/lms/") ? false : true 
    false
  );
  const components = PanelBarUtils.mapItemsToComponents(menus);

  useEffect(() => {
    const paths = currentPath.split("/");
    let parentName = paths[1];
    if (paths?.length > 3) {
      parentName = `${paths[1]}/${paths[2]}`;
    }
    const parentPath = routes.find((item) => item.path === "/" + parentName);
    parentPath && setPanelExpanded([parentPath?.parent, parentPath?.id]);
    const item = routes.find((item) => item.path.includes(fileName));
    item && setSelectItem(item.id);
  }, [currentPath]);

  const handleNavigation = (e) => {
    const { expandedItems, target } = e || {};
    if (expandedItems) {
      setPanelExpanded(expandedItems);
    }
    const findSelectedMenu = menus?.find((menu) => menu.id === target.props.id);
    const findSelectedParentMenu = menus?.find(
      (menu) => menu.id === target.props.parent
    );
    let selectedTab = "";
    if (findSelectedMenu?.children?.length) {
      if (expandedItems.includes(target.props.id)) {
        let selectedItem;
        if (findSelectedMenu.path !== "/" + currentPath.split("/")[1]) {
          selectedItem = findSelectedMenu?.children?.[0];
        } else {
          selectedItem = routes.find((item) => item.path.includes(fileName));
        }
        selectedTab = selectedItem && selectedItem.id;
      } else {
        selectedTab = target.props.id;
        setSelectItem(selectedTab);
        return;
      }
    } else if (!!findSelectedParentMenu) {
      if (expandedItems.includes(target.props.id)) {
        let selectedItem = findSelectedParentMenu?.children?.[0];
        if (findSelectedParentMenu?.children?.length > 1) {
          selectedItem = findSelectedParentMenu?.children.find((item) => item.id === target.props.id);
        }

        selectedTab = selectedItem && selectedItem.id;
      } else {
        selectedTab = target.props.parent;
        setSelectItem(selectedTab);
        return;
      }
    } else {
      selectedTab = target.props.id;
    }
    setSelectItem(selectedTab);
    const route = routes.find((item) => item.id === selectedTab);
    !!route && history.push({ pathname: route.path, state: route });
  };
  return (
    <div>
      <Header isOpen={isOpen} setIsOpen={setIsOpen} />
      <Drawer expanded={expanded} position={"start"} mode={"push"} className="app-scrollbar">
        <PanelBar
          onSelect={handleNavigation}
          className={`side-menu ${isOpen ? "" : "side-menu-collapsed"}`}
          style={{ padding: "0px" }}
          children={components}
          expanded={panelExpanded}
          selected={selectItem}
          isControlled={true}
          expandMode={expandMode}
        />
        {/* {generateMenus(menus)} */}
        {/* </PanelBar> */}
        <div
          className={`col-lg-12 content-wrapper ${isOpen ? "" : "content-wrapper-expand"
            }`}
        >
          {props.children}
        </div>
        <ToastContainer
          style={{ zIndex: "99999" }}
          position="top-center"
          closeOnClick
        />
        {/* {isDataLoading && <ScreenLoader />} */}
      </Drawer>
      <Footer />
    </div>
  );
};

export default withRouter(UiLayout);
