
export const VERIFICATION_STATUS = [
    {
        label:"ACCEPTED",
        value:"ACCEPTED"
    },
    {
        label:"REJECTED",
        value:"REJECTED"
    },
    {
        label:"PENDING",
        value:"PENDING"
    },
    
]

export const REASON_DD = [
    {
        label:"Incorrect Amount",
        value:"Incorrect Amount"
    },
    {
        label:"Invalid Proof",
        value:"Invalid Proof"
    },
    {
        label:"Challan not created",
        value:"Challan not created"
    },

]

export const verification_detail_keys = [
    {
        title:"Reg Number",
        field:"regNumber"
    },
    {
        title:"Notice Number",
        field:"noticeNumber"
    },
    {
        title:"Offense Type",
        field:"offenceName"
    },
    {
        title:"Offense Date",
        field:"offenceDate"
    },
    {
        title:"Payment Date",
        field:"paymentDate"
    },
    {
        title:"Raised By",
        field:"updatedBy"
    },
    {
        title:"Region",
        field:"region"
    },
    {
        title:"Court",
        field:"court"
    },
    {
        title:"Agent",
        field:"agent"
    },
    {
        title:"Challan Amount",
        field:"amount"
    },
    {
        title:"Agent Fees",
        field:"agentFees"
    },
    {
        title:"Government fee",
        field:"settlementAmount"
    },
];

  
export const sampleData = [
    {
        regNumber:"test",
        noticeNumber:"test",
        offenceDate:"test",
        offenceType:"test",
        disposeDate:"test",
        raisedBy:"test",
        region:"test",
        court:"court",
        agent:"agent",
        challanAmount:"challanAmount",
        agentFee:"agentFee",
        settlementAmount:"settlementAmount"
    }
]

export const proofData = {
    FIND_PROOF:"found_challans.pdf",
    DISPOSE_PROOF:"dispose_challan.pdf",
    EXCEPTION_PROOF:"exception_proof.pdf"
}