import { StackLayout } from "@progress/kendo-react-layout";
import classes from "./style.module.css";

const CustomGridSort = ({ sort, onSortChange, columnMapKey, ...rest }) => {
    const isSorted = sort !== undefined;
    const sortOrder =
        sort === undefined ? null : sort === false ? "asc" : "desc";
    return (
        <StackLayout className={classes.sortContainer}>
            <p
                style={{
                    margin: 0,
                    flex: 0.5,
                }}
            >
                Sort:
            </p>
            <span
                role="button"
                className={`k-icon k-i-arrow-up ${classes.sortIcon} ${
                    sortOrder === "asc" && classes.activeIcon
                }`}
                onClick={() =>
                    isSorted && sortOrder === "asc"
                        ? onSortChange(columnMapKey, null)
                        : onSortChange(columnMapKey, "asc")
                }
            />
            <span
                role="button"
                className={`k-icon k-i-arrow-down ${classes.sortIcon} ${
                    sortOrder === "desc" && classes.activeIcon
                }`}
                onClick={() =>
                    isSorted && sortOrder === "desc"
                        ? onSortChange(columnMapKey, null)
                        : onSortChange(columnMapKey, "desc")
                }
            />
        </StackLayout>
    );
};

export default CustomGridSort;
