import React, { useEffect, useState } from 'react';
import styles from './style.module.css'
import useQuery from '../../../../../../../hooks/useQuery';
import { lmsNegoApi } from '../../../../../../../services/lms-nego-api';
import RACallSummaryCard from '../ra-call-summary-card';
import { Loader } from '@progress/kendo-react-indicators';

function RACallSummaryCollection(props) {

    const { appointmentId} = props;
    const [currentSid, setCurrentSid] = useState(undefined)
    const [callIndex, setCallIndex] = useState(undefined)
    const [callHeader,setCallHeader] = useState(undefined)
    const { data: callData, isLoading: callDataLoading } = useQuery(lmsNegoApi.getCallSummary, { appointmentId: appointmentId })

    const callLogClickHandler = (sid,index) =>{
      setCurrentSid(sid)
      setCallIndex(index)
    }

    useEffect(()=>{
      if(callData && callData?.data?.callDetails?.data){
        setCurrentSid(callData?.data?.callDetails?.data?.[0]?.callLog?.callSid)
        setCallHeader(callData?.data?.callDetails?.data?.[0]?.callLog)
      }
    },[callData])


    const renderCollections = () => {
        if (callDataLoading || !appointmentId) {
            return (<Loader />)
        }
        else if (callData) {
            if (callData?.data?.callDetails?.data?.error || callData?.data?.callDetails?.data?.length == 0) {
                return (
                    <p className={styles['head_collections--error']}>No records found</p>
                )
            }
            return (
                <div className={styles['head_collections']}>
                    <div className={styles['head_call_logs_collection']}>
                      {
                        callData?.data?.callDetails?.data?.map((item, index) => {
                        return(
                          <div 
                            className={`
                              ${styles['head_call_logs_collection_singleLog']}
                              ${(currentSid == item?.callLog?.callSid) ? styles['head_call_logs_collection_singleLog--active']:''}
                            `}
                            onClick={
                              ()=>{
                                callLogClickHandler(item?.callLog?.callSid,index+1)
                                setCallHeader(item?.callLog)
                              }}
                          >
                            Call {index+1}
                          </div>
                        )
                        })
                      }
                    </div>
                    {
                      currentSid && 
                      <div>
                        <RACallSummaryCard
                          key={currentSid}
                          index={callIndex}
                          appointmentId={appointmentId}
                          currentSid={currentSid}
                          callHeader={callHeader}
                        />
                      </div>
                    }
                </div>
            )
        }
    }

    return (
        <div className={styles['head']}>
            {
                renderCollections()
            }
        </div>
    );
}

export default RACallSummaryCollection
