import './car-tag-indicators.scss';

const CarTagIndicator = ({ icon: Icon, backgroundColor, text }) => {
  return (
    <div className="car-tag-indicator" style={{ backgroundColor }}>
      {Icon && <Icon />}
      <span className="indicator-text">{text}</span>
    </div>
  );
};

export default CarTagIndicator;
