function DownloadIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 14.25v5.25a1.5 1.5 0 01-1.5 1.5h-15A1.5 1.5 0 013 19.5v-5.25a.75.75 0 111.5 0v5.25h15v-5.25a.75.75 0 111.5 0zm-9.53.53a.747.747 0 001.06 0l3.75-3.75a.75.75 0 10-1.06-1.06l-2.47 2.47V3.75a.75.75 0 10-1.5 0v8.69L8.78 9.97a.75.75 0 00-1.06 1.06l3.75 3.75z"
        fill={props.fill || "#EF6E0B"}
      />
    </svg>
  )
}

export default DownloadIcon
