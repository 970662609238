export default (api) => {
	const uploadRCDetailsCSV = (params) => {
		return new Promise((resolve, reject) => {
			api
				.post(`/api/v1/rc-details/bulk/refresh/upload`, params)
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	return {
		uploadRCDetailsCSV,
	};
};
