import React from 'react';

function AnnouncementActions(event) {

    const handleEdit = () => {
        event.setAnnouncementDetails(event.dataItem);
        event.setItemType("Announcement")
    }

    return (
        <td>
            <span
                className={`k-icon k-font-icon k-i-edit m-2`}
                style={{ color: "#003E72", cursor: "pointer" }}
                onClick={handleEdit}
            />
        </td>
    )
}

export default AnnouncementActions