import { useEffect, useRef, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { showToastMessage } from "../../../../utils/utils";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { assignLeadToAgent } from "../utils/helper";

export default function AssignleadDropDown({
    leadId,
    refreshLeads,
    selectedAgent,
    agentList,
    type = "assign",
}) {
    const [selectedKey, setSelectedKey] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const selectedAgentRef = useRef(null);

    const defaultItem = {
        name: "Select Agent",
        email: "",
    };

    useEffect(() => {
        const selectedAgentOption = agentList?.find(
            (option) => option.email === selectedAgent
        );
        if (selectedAgentOption) {
            setSelectedKey(selectedAgentOption);
        } else {
            setSelectedKey(defaultItem);
        }
    }, [selectedAgent, agentList]);

    const handleDropDown = (value) => {
        selectedAgentRef.current = value;
        setShowDialog(true);
    };

    const hideDialog = () => setShowDialog(false);

    const handleAssignLead = () => {
        const value = selectedAgentRef.current;
        assignLeadToAgent(leadId, { assignedTo: value?.email })
            .then((resp) => {
                if (resp && resp.success) {
                    showToastMessage(
                        `Lead Assigned Successfully to ${
                            value?.name || "User"
                        }(${value?.email || ""})`,
                        true
                    );
                    // setSelectedKey(value);
                    refreshLeads?.();
                    return;
                }
                throw new Error("Lead Assignment Failed");
            })
            .catch((err) => {
                showToastMessage(
                    err?.message || "Lead Assignment Failed",
                    false,
                    _,
                    "lead-assign-error"
                );
            })
            .finally(() => setShowDialog(false));
    };

    return (
        <>
            {showDialog && (
                <Dialog title={"Please Confirm"} onClose={hideDialog}>
                    <p className="k-text-center">
                        Do you want to {type} the lead <strong>{leadId}</strong>{" "}
                        to <strong>{selectedAgentRef?.current?.name}</strong> ?
                    </p>
                    <DialogActionsBar>
                        <Button themeColor="primary" onClick={handleAssignLead}>
                            Yes
                        </Button>
                        <Button onClick={hideDialog}>No</Button>
                    </DialogActionsBar>
                </Dialog>
            )}
            {agentList?.length > 0 && (
                <td key={leadId}>
                    <DropDownList
                        data={agentList || []}
                        onChange={(e) => handleDropDown(e.value)}
                        textField="name"
                        dataItemKey="email"
                        value={selectedKey}
                    />
                </td>
            )}
        </>
    );
}
