import classes from "../styles/BulkUpload.module.css";
import { StackLayout } from "@progress/kendo-react-layout";
import uploadIcon from "../../../../assets/img/upload-file.svg";
import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useRef, useState } from "react";
import { getFileSize } from "../utils";
import { showToastMessage } from "../../../../utils/utils";
import { SFService } from "../../../../services";
import { BULK_FILE_MAX_SIZE } from "../constants";

const BulkUpload = () => {
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [dragClass, setDragClass] = useState(null);
    const inputRef = useRef(null);

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        if (
            (droppedFile.type === "text/csv" ||
                droppedFile.name.endsWith(".csv")) &&
            droppedFile.size <= BULK_FILE_MAX_SIZE
        ) {
            setFile(droppedFile);
        } else {
            inputRef.current.value = "";
            if (droppedFile.size > BULK_FILE_MAX_SIZE) {
                showToastMessage("File size should not exceed 15MB", false);
            } else {
                showToastMessage("Please drop a CSV file", false);
            }
        }
        setDragClass(null);
    };

    const handleDrag = (e) => {
        e.preventDefault();
        setDragClass("dropzoneHover");
    };

    const handleDragLeave = () => {
        setDragClass(null);
    };

    const handleFileChange = (e) => {
        e.preventDefault();
        const selectedFile = e.target.files[0];
        if (
            (selectedFile.type === "text/csv" ||
                selectedFile.name.endsWith(".csv")) &&
            selectedFile.size <= BULK_FILE_MAX_SIZE
        ) {
            setFile(selectedFile);
        } else {
            inputRef.current.value = "";
            if (selectedFile.size > BULK_FILE_MAX_SIZE) {
                showToastMessage("File size should not exceed 15MB", false);
            } else {
                showToastMessage("Please drop a CSV file", false);
            }
        }
    };

    const handleClearFile = () => {
        setFile(null);
        if (inputRef.current) {
            inputRef.current.value = "";
        }
    };

    const handleUploadLeadsFile = async () => {
        try {
            setProgress(25);
            const formData = new FormData();
            formData.set("file", file);
            const response = await SFService.uploadBulkLead(formData);
            showToastMessage("Uploaded leads successfully", true);
        } catch (error) {
            console.log(error);
            showToastMessage("Unable to upload leads", false);
        } finally {
            setProgress(100);
        }
    };

    useEffect(() => {
        if (progress === 100) {
            setTimeout(() => {
                handleClearFile();
                setProgress(0);
            }, 500);
        }
    }, [progress]);

    return (
        <>
            <StackLayout
                orientation="vertical"
                gap={6}
                className="k-justify-content-center k-align-items-center"
            >
                <div
                    className={`${classes.dragDropContainer} ${
                        dragClass ? classes[dragClass] : ""
                    }`}
                    onDrop={handleDrop}
                    onDragOver={handleDrag}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDragLeave}
                >
                    <img
                        src={uploadIcon}
                        alt="upload"
                        className={classes.uploadIcon}
                    />
                    <p>
                        Drag and Drop file here or{" "}
                        <span
                            onClick={() => inputRef?.current?.click()}
                            role="button"
                            className="k-text-underline"
                        >
                            Choose file
                        </span>
                    </p>
                </div>
                <StackLayout
                    className={`k-justify-content-between k-align-items-center ${classes.container}`}
                >
                    <p className="text-sm">Supported formats: CSV</p>
                    <p className="text-sm k-text-right">Maximum size: 15MB</p>
                </StackLayout>
                {file && (
                    <StackLayout className={classes.fileInfoContainer}>
                        <div
                            className={classes.progressBar}
                            style={{
                                width: `${progress}%`,
                            }}
                        />
                        <div className={classes.fileNameContainer}>
                            <span className="k-icon k-font-icon k-i-file-csv k-i-csv" />
                            <div>
                                <p className="m-0">{file.name}</p>
                                <p className="m-0 text-sm">
                                    {getFileSize(file.size) + " KB"}
                                </p>
                            </div>
                        </div>
                        <span
                            className="k-icon k-font-icon k-i-delete k-i-trash"
                            role="button"
                            onClick={handleClearFile}
                        />
                    </StackLayout>
                )}
                <Button
                    className="k-w-fit my-4"
                    themeColor="primary"
                    disabled={!file || progress}
                    onClick={handleUploadLeadsFile}
                >
                    Upload Leads
                </Button>
            </StackLayout>
            <input
                ref={inputRef}
                type="file"
                className={classes.input}
                accept=".csv"
                onChange={handleFileChange}
            />
        </>
    );
};

export default BulkUpload;
