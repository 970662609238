import { TYPE_BREADCUMB } from "../types";
export const update =
  (params=[]) =>
  (dispatch) => {
    dispatch({ type: TYPE_BREADCUMB.UPDATE,payload: params});
  };

  export const clear =
  (params = []) =>
  (dispatch) => {
    dispatch({ type: TYPE_BREADCUMB.UPDATE ,payload: params});
  };
  export const reset =
  (params = []) =>
  (dispatch) => {
    dispatch({ type: TYPE_BREADCUMB.RESET ,payload: params});
  };
