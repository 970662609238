import React, { useState, useEffect } from "react";
import {
  challan_item_keys,
  getDisplayedCoulmns,
  StatusCell,
  OffenseDateCell,
  // TaskCreationDateCell,
  SELECTED_FIELD_KEY,
  PaymentProofCell,
  getSelectedRowIds,
} from "./viewModel";
import CustomGrid from "../../../../common/custom-grid/component";
// import { Loader } from "../../../../common/Loader";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ChallanTask from "../create-task";
import { formData } from "../create-task/viewModel";
// import { useDispatch } from "react-redux";
import {
  showToastMessage,
  formatDate,
  // getFromLocalStorage,
  // getLoginUserEmail,
  getRoundedByTwoDigit,
  getFFUserType,
  getArrayItemLabelByValue,
  shortenString,
} from "../../../../utils/utils";
import {
  // LOCAL_STORAGE_KEYS,
  CHALLAN_REQUEST_STATUSES,
  NUMBERS,
  // API_HEADER_CONSTANTS,
  CHALLAN_ITEM_STATUSES,
  CHALLAN_MODE_TYPES,
  CHALLAN_MODES,
  WORD_LIMIT_TRUNCATE,
} from "../../../../utils/constants/values.constants";
import { getOpsChallanServiceMetaData } from "../../../../utils/constants/url.constants";
import { ChallanService } from "../../../../services";
// import { updateRowChannelRequest } from "../../../../store/actions/ops-challan";
// import { SingleFileUpload } from "../../../../components";
import { USER_ROLE_KEYS } from "../../../../utils/constants/menu.constants";
// import { getSessionToken } from "@descope/react-sdk";
import { getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import srcEditImg from "../../../../assets/img/edit-ico.svg";
import srcDelImg from "../../../../assets/img/delete-img.svg";
import AddDesc from "./component/add-description";
// import FileView from "../../../../common/file-view";
// import { Upload } from "@progress/kendo-react-upload";
import UploadMultipleFile from "./component/upload-multiple-file";
const idGetter = getter(challan_item_keys.CHALLAN_ID);

const ChallanDetailRevamp = (props) => {
  const {
    challanRequestDetail,
    challanRequestDetailItems: { channelItems = [] } = {},
    idChallanRequest,
    setRefresh,
  } = props;
  const [challanItems, setChallanItems] = useState([]);
  const [openCreateTaskDialog, setOpenCreateTaskDialog] = useState(false);
  const [openUploadProofDialog, setOpenUploadProofDialog] = useState(false);
  const [openAddDescDialog, setOpenAddDescDialog] = useState(false);
  const [createOrderData, setCreateOrderData] = useState(formData);
  const [isActionSubmit, setIsActionSubmit] = useState(false);
  // const dispatch = useDispatch();
  const apiMetaData = getOpsChallanServiceMetaData();
  const userType = getFFUserType();
  const isCTAShow =
    challanRequestDetail?.status?.toUpperCase() ===
      CHALLAN_REQUEST_STATUSES.ASSIGNED &&
    userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN;
  const [isChallanItemAvailable, setIsChallanItemAvailable] = useState(false);
  const [currentChallanItem, setCurrentChallanItem] = useState({});
  const [editedChallanRequest, setEditedChallanRequest] = useState({
    ...challanRequestDetail,
  });
  const [selectedState, setSelectedState] = useState({});
  const [challanFinderProofList, setChallanFinderProofList] = useState(
    challanRequestDetail?.challanFinderProofs || []
  );

  const openCreateTask = () => {
    setOpenCreateTaskDialog(true);
  };
  const closeCreateTask = () => {
    setOpenCreateTaskDialog(false);
  };
  const openUploadDialogProof = () => {
    setOpenUploadProofDialog(!openUploadProofDialog);
  };
  const openAddDescPopup = () => {
    setOpenAddDescDialog(true);
  };
  const closeAddDescPopup = () => {
    setOpenAddDescDialog(false);
    // setRefresh(true); //TODO open after BE service integration
  };

  const submitCreateTask = () => {
    setIsActionSubmit(true);
    const payload = {
      [challan_item_keys.CHALLAN_REQUEST_ID]:
        challanRequestDetail[challan_item_keys.CHALLAN_REQUEST_ID],
      [challan_item_keys.APPOINTMENT_ORDER_ID]:
        challanRequestDetail[challan_item_keys.APPOINTMENT_ORDER_ID],
      [challan_item_keys.REG_NUMBER]:
        challanRequestDetail[challan_item_keys.REG_NUMBER],
      [challan_item_keys.NOTICE_NUMBER]:
        createOrderData[challan_item_keys.NOTICE_NUMBER]?.value,
      [challan_item_keys.CHALLAN_OFFENSE_ID]:
        createOrderData[challan_item_keys.CHALLAN_NAME]?.value?.value,
      [challan_item_keys.CHALLAN_NAME]:
        createOrderData[challan_item_keys.CHALLAN_NAME]?.value?.label,
      [challan_item_keys.CHALLAN_TYPE]:
        createOrderData[challan_item_keys.CHALLAN_TYPE]?.value?.value,
      [challan_item_keys.OFFENCE_DATE]: formatDate(
        createOrderData[challan_item_keys.OFFENCE_DATE]?.value
      ),
      [challan_item_keys.CHALLAN_COURT_ID]:
        createOrderData[challan_item_keys.CHALLAN_COURT]?.value?.value,
      [challan_item_keys.CHALLAN_COURT]:
        createOrderData[challan_item_keys.CHALLAN_COURT]?.value?.label,
      [challan_item_keys.CHALLAN_AMOUNT]:
        createOrderData[challan_item_keys.CHALLAN_AMOUNT]?.value,
      [challan_item_keys.REMARKS]:
        createOrderData[challan_item_keys.REMARKS]?.value,
    };
    //check validity of existing payload //todo
    if (!payload[challan_item_keys.NOTICE_NUMBER]) {
      showToastMessage("Please select valid Notice Number", false);
      return;
    } else if (!payload[challan_item_keys.CHALLAN_TYPE]) {
      showToastMessage("Please select valid Challan  Mode", false);
      return;
    } else if (!payload[challan_item_keys.CHALLAN_OFFENSE_ID]) {
      showToastMessage("Please select Offense type", false);
      return;
    } else if (!payload[challan_item_keys.OFFENCE_DATE]) {
      showToastMessage("Please select valid Offense Date", false);
      return;
    } else if (!payload[challan_item_keys.CHALLAN_COURT_ID]) {
      showToastMessage("Please select valid Court", false);
      return;
    } else if (!payload[challan_item_keys.CHALLAN_AMOUNT]) {
      showToastMessage("Please select valid amount", false);
      return;
    }
    ChallanService.createChallanItem({
      id: currentChallanItem[challan_item_keys.CHALLAN_ID],
      payload: { ...currentChallanItem, ...payload },
    })
      .then((resp) => {
        setIsChallanItemAvailable(true);
        setCurrentChallanItem({});
        showToastMessage(resp?.message);
        closeCreateTask();
        setRefresh(true);
      })
      .catch((err) => {
        showToastMessage(err.message, false);
      })
      .finally(() => {
        setIsActionSubmit(false);
      });
  };
  const onRequestAction = (action, idChallanItem = null) => {
    if (!!idChallanRequest && !!channelItems?.length) {
      if (action === "resolve") {
        const params = [idChallanRequest];
        ChallanService.markAsResolvedRequests(params)
          .then((resp) => {
            showToastMessage(resp?.message);
            setRefresh(true);
          })
          .catch((err) => {
            showToastMessage(err.message, false);
          })
          .finally(() => {
            setIsActionSubmit(false);
          });
      } else if (action === "submitall") {
        ChallanService.submitAllChallans(idChallanRequest)
          .then((resp) => {
            showToastMessage(resp?.message);
            setRefresh(true);
          })
          .catch((err) => {
            showToastMessage(err.message, false);
          })
          .finally(() => {
            setIsActionSubmit(false);
          });
      } else if (action === "uploadproof") {
        openUploadDialogProof();
      } else if (action === "submitChallanItem") {
        ChallanService.submitChallanItem(idChallanItem)
          .then((resp) => {
            showToastMessage(resp?.message);
            setRefresh(true);
          })
          .catch((err) => {
            showToastMessage(err.message, false);
          });
      } else if (action === "addDesc") {
        openAddDescPopup();
      } else if (action === "deleteChallanItem") {
        ChallanService.deleteChallanItem(idChallanItem)
          .then((resp) => {
            showToastMessage(resp?.message);
            setRefresh(true);
          })
          .catch((err) => {
            showToastMessage(err.message, false);
          });
      } else if (action === "submitrows") {
        const selectedRows = getSelectedRowIds(
          selectedState,
          challanItems,
          CHALLAN_ITEM_STATUSES.CREATED
        );
        if (selectedRows?.length) {
          ChallanService.submitChallanItems(selectedRows)
            .then((resp) => {
              showToastMessage(resp?.message);
              setRefresh(true);
            })
            .catch((err) => {
              showToastMessage(err.message, false);
            })
            .finally(() => {
              setIsActionSubmit(false);
            });
        } else {
          showToastMessage(
            "Please select atleast one created row to continue",
            false
          );
        }
      }
    }
  };
  // const getUploadProofUrl = () => {
  //   return `${apiMetaData.url}/api/v1/challan-request/upload/${idChallanRequest}`;
  // };
  const closeUploadProofDialog = () => {
    setRefresh(true);
    setOpenUploadProofDialog(false);
  };
  const onEditChallanItem = (data) => {
    setCurrentChallanItem(data);
    openCreateTask();
  };

  // const NoticeNumberCell = (event) => {
  //   const { idChallanItem, noticeNumber, status } = event.dataItem;
  //   if (
  //     ["CREATED", "REJECTED"].includes(status) &&
  //     userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN
  //   ) {
  //     return (
  //       <td
  //         className="hyper-link"
  //         onClick={() => onEditChallanItem(event.dataItem)}
  //       >
  //         {noticeNumber}
  //       </td>
  //     );
  //   } else {
  //     return <td>{noticeNumber}</td>;
  //   }
  // };
  const ChallanAmountCell = (event) => {
    return (
      <td className="td-custom-text">
        {getRoundedByTwoDigit(
          event?.dataItem[challan_item_keys.CHALLAN_AMOUNT]
        )}
      </td>
    );
  };
  const ChallanTypeCell = (event) => {
    const type = event?.dataItem[challan_item_keys.CHALLAN_TYPE];
    const style = {
      color:
        type === CHALLAN_MODE_TYPES.OFFLINE
          ? "red"
          : type === CHALLAN_MODE_TYPES.ONLINE
          ? "green"
          : "#F6A70A",
    };
    return (
      <td className="k-table-td">
        <span style={style}>
          {getArrayItemLabelByValue(
            CHALLAN_MODES,
            event?.dataItem[challan_item_keys.CHALLAN_TYPE]
          )}
        </span>
      </td>
    );
  };

  const OffenseChallanNameCell = (event) => {
    const value = event?.dataItem[challan_item_keys.CHALLAN_NAME] || "";
    return (
      <td
        className="k-table-td"
        title={event?.dataItem[challan_item_keys.CHALLAN_NAME]}
      >
        {shortenString(value, WORD_LIMIT_TRUNCATE.LIMIT)}
      </td>
    );
  };

  const RemarksCell = (event) => {
    const value = event?.dataItem[challan_item_keys.REMARKS] || "";
    return (
      <td
        className="k-table-td"
        title={event?.dataItem[challan_item_keys.REMARKS]}
      >
        {shortenString(value, WORD_LIMIT_TRUNCATE.LIMIT)}
      </td>
    );
  };
  const SubmitChallanCell = (event) => {
    const { idChallanItem, status } = event.dataItem || {};
    return (
      <td className="td-custom-text">
        {[
          CHALLAN_ITEM_STATUSES.CREATED,
          CHALLAN_ITEM_STATUSES.REJECTED,
        ].includes(status) &&
          userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN && (
            <div className={"icons"}>
              <img
                alt="edit"
                src={srcEditImg}
                onClick={() => onEditChallanItem(event.dataItem)}
              />
              <img
                alt="delete"
                src={srcDelImg}
                onClick={() =>
                  onRequestAction("deleteChallanItem", idChallanItem)
                }
              />
            </div>
          )}
      </td>
    );
  };
  const columns = {
    [challan_item_keys.REG_NUMBER]: {
      label: "Reg No.",
      field: challan_item_keys.REG_NUMBER,
    },
    [challan_item_keys.NOTICE_NUMBER]: {
      label: "Notice No.",
      field: challan_item_keys.NOTICE_NUMBER,
      // cell: NoticeNumberCell,
    },
    [challan_item_keys.CHALLAN_TYPE]: {
      label: "Challan Mode",
      field: challan_item_keys.CHALLAN_TYPE,
      cell: ChallanTypeCell,
    },
    [challan_item_keys.CHALLAN_NAME]: {
      label: "Offense Type",
      field: challan_item_keys.CHALLAN_NAME,
      cell: OffenseChallanNameCell,
    },
    [challan_item_keys.OFFENCE_DATE]: {
      label: "Offense Date",
      cell: OffenseDateCell,
    },
    [challan_item_keys.CHALLAN_COURT]: {
      label: "Challan Court",
      field: challan_item_keys.CHALLAN_COURT,
    },
    [challan_item_keys.CHALLAN_AMOUNT]: {
      label: "Challan Amount",
      cell: ChallanAmountCell,
    },
    [challan_item_keys.STATUS]: {
      label: "Status",
      cell: StatusCell,
    },
    [challan_item_keys.REJECTION_PROOF]: {
      label: "Uploaded Proof",
      field: challan_item_keys.REJECTION_PROOF,
      cell: PaymentProofCell,
    },
    [challan_item_keys.REMARKS]: {
      label: "Remarks",
      field: challan_item_keys.REMARKS,
      cell: RemarksCell,
    },
    ...(userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN && {
      [challan_item_keys.SUBMIT_CHALLAN]: {
        label: "Action",
        cell: SubmitChallanCell,
        hide: false,
      },
    }),
  };

  const onRowSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: challan_item_keys.CHALLAN_ID,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
      item[SELECTED_FIELD_KEY] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  useEffect(() => {
    const itemCount =
      channelItems?.length || challanRequestDetail?.itemCount || 0;
    setIsChallanItemAvailable(itemCount > NUMBERS.ZERO);
    setChallanItems(channelItems);
  }, [channelItems]);

  const CustomRow = (trElement, data) => {
    const { dataItem } = data || {};
    console.log({ dataItem });
    const trProps = {
      ...trElement.props,
      className: !!dataItem.vahanFlag
        ? "finder-color-coded-row"
        : trElement.props.className,
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };
  // const handleFileView = () => {
  //   if (
  //     !!challanRequestDetail?.idChallanRequest &&
  //     !!challanRequestDetail?.challanProof
  //   ) {
  //     ChallanService.fetchUploadProofFileLink(
  //       challanRequestDetail?.idChallanRequest
  //     )
  //       .then((resp) => {
  //         resp?.detail && window.open(resp?.detail, "_blank", "noopener");
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         showToastMessage(err?.message || err?.error, false);
  //       });
  //   }
  // };
  const onEditChallanRequestConfirm = () => {
    ChallanService.updateChallanRequest(editedChallanRequest)
      .then((resp) => {
        showToastMessage(resp?.message);
        closeCreateTask();
        setRefresh(true);
      })
      .catch((err) => {
        showToastMessage(err?.message || err?.error, false);
      });
  };
  const onResetEditChallanRequest = () => {
    setEditedChallanRequest({ ...challanRequestDetail });
  };
  return (
    <>
      {isCTAShow && (
        <div className="add-items-cta-detail-view">
          <Button
            themeColor={"primary"}
            onClick={openCreateTask}
            className="m-1"
            disabled={isActionSubmit}
          >
            Add Challan
          </Button>
          {isChallanItemAvailable && (
            <Button
              themeColor={"primary"}
              fillMode={"outline"}
              onClick={() => onRequestAction("submitrows")}
              className="m-1"
              disabled={isActionSubmit}
            >
              Submit
            </Button>
          )}
        </div>
      )}
      <CustomGrid
        displayedColumns={getDisplayedCoulmns(columns)}
        data={challanItems?.map((item) => ({
          ...item,
          [SELECTED_FIELD_KEY]: selectedState[idGetter(item)],
        }))}
        columnMap={columns}
        selectable={userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN}
        selectedField={SELECTED_FIELD_KEY}
        idField={challan_item_keys.CHALLAN_ID}
        onSelectionChange={onRowSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
        fixedHeight={{ height: "300px" }}
        rowRender={CustomRow}
      />
      {isCTAShow && (
        <div className="add-items-cta-detail-view">
          {isChallanItemAvailable && (
            <>
              <Button
                onClick={() => onRequestAction("uploadproof")}
                className="k-button k-button-md k-button-outline k-button-outline-primary k-rounded-md m-1"
                disabled={isActionSubmit}
              >
                Upload Proof
              </Button>
              <Button
                onClick={() => onRequestAction("addDesc")}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base m-1"
                disabled={isActionSubmit}
                themeColor={"primary"}
                fillMode={"outline"}
              >
                Add Desc.
              </Button>
              <Button
                onClick={() => onRequestAction("resolve")}
                className="k-button k-button-md k-button-solid k-rounded-md m-1"
                themeColor={"primary"}
                disabled={isActionSubmit}
              >
                Resolve Ticket
              </Button>
            </>
          )}
        </div>
      )}
      {openCreateTaskDialog && (
        <Dialog title={"Add a Task"} onClose={closeCreateTask}>
          <ChallanTask
            item={currentChallanItem}
            data={createOrderData}
            setData={setCreateOrderData}
          />
          <DialogActionsBar>
            <Button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={submitCreateTask}
              themeColor={"primary"}
            >
              Submit
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {openUploadProofDialog && (
        <Dialog title={"Upload Proof"} onClose={openUploadDialogProof}>
          <div>
            <UploadMultipleFile
              challanRequestDetail={challanRequestDetail}
              challanFinderProofList={challanFinderProofList}
              setChallanFinderProofList={setChallanFinderProofList}
            />
          </div>
          <DialogActionsBar>
            <Button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => closeUploadProofDialog()}
              themeColor={"primary"}
            >
              Close
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {/* {openDelayDialog && (
				<Dialog title={"Delay Reason"} onClose={closeAddDelayDialog}>
					<AddDelay
						userGroup={USER_ROLE_KEYS.FINDER}
						challanItems={channelItems}
						onClose={closeAddDelayDialog}
					/>
				</Dialog>
			)} */}
      {openAddDescDialog && (
        <Dialog
          title={
            <p>
              <div
                style={{
                  cursor: "pointer",
                  color: "#0059A3",
                  fontWeight: "700",
                  fontSize: "18px",
                }}
              >
                Add Description
              </div>
              <div>Add description for Appt. ID</div>
            </p>
          }
          onClose={closeAddDescPopup}
        >
          <AddDesc
            editedChallanRequest={editedChallanRequest}
            setEditedChallanRequest={setEditedChallanRequest}
          />
          <DialogActionsBar>
            <Button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={onResetEditChallanRequest}
            >
              Reset
            </Button>
            <Button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={onEditChallanRequestConfirm}
              themeColor={"primary"}
            >
              Confirm
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};
export default ChallanDetailRevamp;
