import React, { useState, useEffect } from "react";
import CustomGrid from "../../../../../common/custom-grid/component";
import {
    ASSIGNED_LEAD_DEFAULT_PAGE_SIZE,
    ASSIGNED_LEAD_INITIAL_FILTER,
    ASSIGNED_LEAD_KEY,
    ASSIGNED_SUB_TABS_GRID_CONFIG,
    LEADS_TYPE,
} from "../../viewModel";
import { useVisibleColumns } from "../../hooks/useVisibleColumns";
import { useFetchLeads } from "../../hooks/useFetchLeads";
import { getDisplayedColumns } from "../../utils/helper";
import classes from "../../styles/LeadsGrid.module.css";
import { LAST_CALL_STATUS_FILTER_BINDINGS } from "../../constants";
import useMetadata from "../../../common/hooks/useMetadata";
import GridLoader from "../../components/GridLoader";

const SubTabGrid = ({ isAdmin, filterData, subTabKey, setFilterData }) => {
    const [paginationParams, setPaginationParams] = useState({
        pageNumber: 0,
        pageSize: ASSIGNED_LEAD_DEFAULT_PAGE_SIZE,
    });
    const [filterParams, setFilterParams] = useState({
        isAssigned: true,
        ...(ASSIGNED_SUB_TABS_GRID_CONFIG[subTabKey]?.FILTER_PARAMS || {}),
    });
    const [gridFilters, setGridFilters] = useState(
        ASSIGNED_LEAD_INITIAL_FILTER
    );

    const { leadData, totalLeadCount, refreshLeads, isLoading } = useFetchLeads(
        paginationParams,
        filterParams
    );

    const { metadata, loading } = useMetadata();

    const visibleColumns = useVisibleColumns({
        gridFilters,
        setGridFilters,
        filterParams,
        setFilterParams,
        setPaginationParams,
        type: isAdmin ? LEADS_TYPE.ADMIN_ASSIGNED : LEADS_TYPE.ASSIGNED,
        refreshLeads,
        filterData,
        subType: subTabKey,
    });

    const pageChange = ({
        page = 0,
        size = ASSIGNED_LEAD_DEFAULT_PAGE_SIZE,
    }) => {
        setPaginationParams({ pageNumber: page, pageSize: size });
    };

    useEffect(() => {
        if (metadata && !loading) {
            const callStatuses = Object.entries(metadata?.callStatus || {}).map(
                ([value, label]) => ({ label, value })
            );

            if (LAST_CALL_STATUS_FILTER_BINDINGS[subTabKey]) {
                const allowedFilters =
                    LAST_CALL_STATUS_FILTER_BINDINGS[subTabKey]
                        ?.ALLOWED_FILTERS;
                const bindings =
                    LAST_CALL_STATUS_FILTER_BINDINGS[subTabKey]?.BINDINGS;

                setFilterData((prev) => ({
                    ...prev,
                    [ASSIGNED_LEAD_KEY.LAST_CALL_STATUS]: callStatuses
                        ?.filter(({ _, value }) =>
                            allowedFilters?.includes(value)
                        )
                        ?.map(({ label, value }) => {
                            label = bindings?.[value] || label;
                            return { label, value };
                        }),
                }));
            }
            else {
                setFilterData((prev) => ({
                    ...prev,
                    [ASSIGNED_LEAD_KEY.LAST_CALL_STATUS]: callStatuses,
                }));
            }
        }
    }, [subTabKey, metadata, loading]);

    return (
        <GridLoader isLoading={isLoading}>
            <CustomGrid
                displayedColumns={getDisplayedColumns(
                    isAdmin ? LEADS_TYPE.ADMIN_ASSIGNED : LEADS_TYPE.ASSIGNED,
                    subTabKey
                )}
                columnMap={visibleColumns}
                data={leadData}
                isPagination={true}
                pageChange={pageChange}
                count={totalLeadCount}
                paginationParams={paginationParams}
                fixedHeight={{ maxHeight: "70vh" }}
                gridClassName={classes.sfGrid}
            />
        </GridLoader>
    );
};

export default SubTabGrid;
