import { Form, FormElement } from "@progress/kendo-react-form";
import { FormInput, FormTextArea } from "../../../../../components";
import {
  dealerPayoutOpenIssueStatuses,
  FORM_ELEMENT_TYPE,
} from "../../../../../utils/constants/values.constants";
import ApproveRejectAction from "../approve-reject-action";
import PaySection from "../pay-section";

const FlagSection = (props) => {
  const { fetchPayouts, updatedFlagData, setUpdatedFlagData } = props || {};

  const handleFormChange = (e) => {
    const { value } = e;
    const { id } = e.target.props || {};
    const key = id || e.target.name;
    setUpdatedFlagData((items) => ({
      ...items,
      [key]: {
        ...items[key],
        value: value,
      },
    }));
  };

  const CustomDialogTitleBar = (props) => {
    return <div className="k-font-medium k-font-size-xl">{props.title}</div>;
  };

  return (
    <>
      <Form
        render={() => (
          <FormElement
            style={{
              marginTop: "20px",
            }}
          >
            <fieldset className={"k-form-fieldset"}>
              <div className="align-center k-mb-2">
                <span
                  style={{
                    color: "#0d6efd",
                    fontWeight: "600",
                    fontSize: "16px",
                    marginRight: "10px",
                  }}
                >
                  FLAG SECTION
                </span>
                <span
                  className={
                    updatedFlagData?.status?.value === "APPROVAL_PENDING"
                      ? "status-inprogress"
                      : "status-success"
                  }
                >
                  {updatedFlagData?.status?.value === "PAYMENT_IN_PROGRESS" ||
                  updatedFlagData?.status?.value === "PAYMENT_PENDING"
                    ? "APPROVED"
                    : updatedFlagData?.status?.value?.replace(/_/g, " ")}
                </span>
              </div>
              <div className="box-shadow k-pb-5">
                {dealerPayoutOpenIssueStatuses.includes(
                  updatedFlagData?.status?.value
                ) && (
                  <div className="dealer-payout-grievance-form">
                    {Object.keys(updatedFlagData)?.map((key) => {
                      const elementMetaData = updatedFlagData[key];
                      if (
                        elementMetaData.elementtype === FORM_ELEMENT_TYPE.INPUT
                      ) {
                        return (
                          <FormInput
                            id={key}
                            key={key}
                            onChange={handleFormChange}
                            {...elementMetaData}
                            value={updatedFlagData?.[key]?.value}
                          />
                        );
                      } else if (
                        elementMetaData.elementtype ===
                        FORM_ELEMENT_TYPE.TEXTAREA
                      ) {
                        return (
                          <FormTextArea
                            id={key}
                            key={key}
                            onChange={handleFormChange}
                            {...elementMetaData}
                            value={updatedFlagData?.[key]?.value}
                            valid={
                              updatedFlagData?.[key]?.value &&
                              updatedFlagData?.[key]?.value?.trim()
                            }
                            touched={true}
                            validationMessage={
                              !updatedFlagData?.[key]?.value
                                ? "Please enter comments"
                                : ""
                            }
                          />
                        );
                      }
                    })}
                  </div>
                )}
                {updatedFlagData?.status?.value === "APPROVAL_PENDING" && (
                  <ApproveRejectAction
                    titleComponent={CustomDialogTitleBar}
                    updatedFlagData={updatedFlagData}
                    fetchPayouts={fetchPayouts}
                  />
                )}
              </div>
            </fieldset>
          </FormElement>
        )}
      />
      {(updatedFlagData?.status?.value === "PAYMENT_IN_PROGRESS" ||
        updatedFlagData?.status?.value === "PAYMENT_PENDING") && (
        <PaySection {...props} handleFormChange={handleFormChange} />
      )}
    </>
  );
};
export default FlagSection;
