// import { convertObjectToParams } from "../utils/utils";

export default (api) => {
  const uploadDocs = (payload, headers) => {
    const url = `upload`;
    return api.post(`${url}`, payload, {
      headers,
    });
  };
  const authenticateUser = (payload) => {
    const url = `authenticate/int/user`;
    return api.post(`${url}`, payload);
  };
  return {
    uploadDocs,
    authenticateUser,
  };
};
