function RecoDealerIcon(props) {
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.932 13.25c-.951-1.646-2.418-2.826-4.13-3.385a4.5 4.5 0 10-4.604 0c-1.712.559-3.179 1.739-4.13 3.385a.5.5 0 10.865.5C4.111 11.715 6.192 10.5 8.5 10.5c2.308 0 4.39 1.215 5.567 3.25a.5.5 0 10.865-.5zM5 6a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0z"
        fill="#465166"
      />
    </svg>
  )
}

export default RecoDealerIcon
