import { Link } from "react-router-dom";

const CustomLinkItem = (props) => {
  const errorMsg = props.errorRows?.find(
    (row) => row.id === props.dataItem?.appointmentId
  )?.errorMsg;

  return (
    <>
      {props.dataLink && (
        <td
          title={props.withTooltip ? props.dataText : null}
          className="gridLinkCls"
        >
          <div className="d-flex">
            {errorMsg && (
              <i
                title={errorMsg}
                className="float-right k-icon k-i-warning text-danger mr-1"
                style={{ marginTop: "1.5px" }}
              ></i>
            )}
            <Link
              className="overflowEllipses"
              to={{
                ...props,
                pathname: props.dataLink,
              }}
            >
              {props.dataText}
            </Link>
          </div>
        </td>
      )}
    </>
  );
};

CustomLinkItem.defaultProps = {
  withTooltip: true,
};

export default CustomLinkItem;
