import { setFilterQueryParam, setFilterStatus, setSidebarFilterPayloads } from "../../store/actions/allCars";
import { setDealerFilterQueryParam, setDealerFilterStatus, setSidebarDealerFilterPayloads } from "../../store/actions/myDealers";
import { ALL_CARS_TABLE_VIEW } from "../constants/table.constants";

export const setInitialFilters = (query, queryParameters, sidebarFiltersPayload, view, sidebarFilters, dispatch, sideBarDealerFilterPayload) => {

    if (sidebarFiltersPayload === null && query.length > 0 && view === ALL_CARS_TABLE_VIEW.all) {

        let carFilters = getAppliedFilters(query, queryParameters, sidebarFilters);
        let registrationStates = { states: getSelectedStateValues(sidebarFilters?.registrationStates, queryParameters["registrationstates"]) };
        let stores = getSelectedStores(sidebarFilters?.centers, queryParameters);

        const reqpayload = {
            carFilters,
            registrationStates,
            stores
        };

        if (carFilters.length > 0 || registrationStates.states.length > 0 || stores.length > 0) {
            dispatch(setFilterStatus(true));
            dispatch(setSidebarFilterPayloads(reqpayload));
        }
    }

    if (sideBarDealerFilterPayload === null && query.length > 0 && view !== ALL_CARS_TABLE_VIEW.all) {
        let carFilters = getAppliedFilters(query, queryParameters, sidebarFilters);
        let registrationStates = { states: getSelectedStateValues(sidebarFilters?.registrationStates, queryParameters["registrationstates"]) };
        let stores = getSelectedStores(sidebarFilters?.centers, queryParameters);

        const reqpayload = {
            carFilters,
            registrationStates,
            stores
        };

        if (carFilters.length > 0 || registrationStates.states.length > 0 || stores.length > 0) {
            dispatch(setDealerFilterStatus(true));
            dispatch(setSidebarDealerFilterPayloads(reqpayload));
        }
    }
};

export const updateFilterQueryParam = (queryParameters, view, dispatch) => {
    if (Object.keys(queryParameters).length === 0) {
        return;
    }

    const params = new URLSearchParams(queryParameters).toString();

    if (view === ALL_CARS_TABLE_VIEW.all) {
        dispatch(setFilterQueryParam(params));
    } else {
        dispatch(setDealerFilterQueryParam(params));
    }
};

export const updateHistory = (filterQueryParam, dealerFilterQueryParam, view, history, location) => {
    let queryParam = '';

    if (view === ALL_CARS_TABLE_VIEW.all && filterQueryParam) {
        queryParam = filterQueryParam;
    } else if (view !== ALL_CARS_TABLE_VIEW.all && dealerFilterQueryParam) {
        queryParam = dealerFilterQueryParam;
    }

    if (queryParam) {
        history.push({ pathname: location.pathname, search: `?${queryParam}` });
    }
};

const getAppliedFilters = (query, queryParameters, sidebarFilters) => {
    return query.map(param => getFilterValue(param, sidebarFilters, queryParameters)).filter(Boolean).flat();
};

const getValues = (filterType, param) => {
    const data = param?.split(',')?.map(each =>
        filterType?.options?.find(option => option.display === each)?.filter
    )?.filter(Boolean) || [];

    return [{
        key: data[0]?.key,
        values: data.map(each => each.value)
    }];
};

const getSelectedRangeValues = (filterType, param) => {
    const rangeValues = param.split('-');
    return [{
        key: filterType.name.toLowerCase(),
        values: rangeValues
    }];
};

const getSelectedStateValues = (filter, param) => {
    const states = param?.split(',')?.map(each =>
        filter?.find(state => state.stateName === each)
    )?.filter(Boolean) || [];

    return states;
};

const getSelectedStores = (filter, queryParameters) => {
    const storeCityIds = queryParameters["storecityid"]?.split(',') || [];
    const storeIds = queryParameters["storeid"]?.split(',') || [];
    let data = [];

    storeCityIds.forEach(cityId => {
        const store = filter.options.find(option => option.filter.value == cityId);
        if (store && store.children) {
            const matchedChildren = store.children.filter(child => storeIds.includes(child.filter.value.toString()));
            if (matchedChildren.length > 0) {
                data.push({
                    storeCityId: parseInt(cityId),
                    storeIds: matchedChildren.map(child => child.filter.value)
                });
            }
        }
    });

    return data;
};

const getMakeModelValue = (filterType, param, queryParameters) => {
    const modelValue = queryParameters["model"]?.split(',') || [];
    const reqParam = param?.split(',') || [];

    const data = reqParam.map(each => {
        const selectedOption = filterType?.options?.find(option => option.display === each);
        if (selectedOption) {
            let reqVal = selectedOption?.filter?.value + ":";
            const children = selectedOption?.children || [];
            const choosen = modelValue.map(modelOption =>
                children.find(child => child.display === modelOption)?.filter.value
            )?.filter(Boolean)?.join(',');

            if (choosen) {
                reqVal += choosen;
            }
            return reqVal;
        }
        return null;
    }).filter(Boolean);

    return [{
        key: 'makeModel',
        values: data
    }];
};

const getFilterValue = (param, sidebarFilters, queryParameters) => {
    switch (param) {
        case "inspection_city":
        case "fuel_type":
            return getValues(sidebarFilters?.detail?.find(filter => (filter.name).toLowerCase() === param), queryParameters[param]);
        case "make_model":
            return getMakeModelValue(sidebarFilters?.detail?.find(filter => (filter.name).toLowerCase() === param), queryParameters[param], queryParameters);
        case "year":
        case "odometer":
            return getSelectedRangeValues(sidebarFilters?.detail?.find(filter => (filter.name).toLowerCase() === param), queryParameters[param]);
        default:
            return null;
    }
};
export const buildQueryParam = ({ filterOptions, search, dealerCode, filters, page }) => {

    const params = new URLSearchParams();
    const storeCityIds = [];
    const storeIds = [];

    if (dealerCode) {
        params.append('dealerCode', dealerCode);
    }

    if (page) {
        params.append('page', page);
    }

    filterOptions?.forEach(selectedFilter => {
        if (!selectedFilter.selected) return;

        if (Array.isArray(selectedFilter.selected)) {
            const selectedValues = selectedFilter.selected.map(selected => selected.display || selected.stateName);
            if (selectedValues.length > 0) {
                switch (selectedFilter.id) {
                    case 'CENTER':
                        selectedFilter.selected.forEach(selected => {
                            storeCityIds.push(selected.storeCityId);
                            if (Array.isArray(selected.storeIds)) {
                                storeIds.push(...selected.storeIds);
                            }
                        });
                        break;
                    case 'INSPECTION_CITY':
                    case 'MAKE_MODEL':
                    case 'Model':
                    case 'FUEL_TYPE':
                    case 'registrationStates':
                        params.append(selectedFilter.id.toLowerCase(), selectedValues.join(','));
                        break;
                    default:
                        break;
                }
            } else {
                params.delete(selectedFilter.id.toLowerCase());
            }
        } else if (typeof selectedFilter.selected === 'object' && selectedFilter.selected !== null) {
            switch (selectedFilter.id) {
                case 'YEAR':
                case 'ODOMETER':
                    params.append(selectedFilter.id.toLowerCase(), `${selectedFilter.selected.start}-${selectedFilter.selected.end}`);
                    break;
                default:
                    break;
            }
        }
    });

    if (storeCityIds.length > 0) {
        params.append('storecityid', storeCityIds.join(','));
    } else {
        params.delete('storecityid');
    }

    if (storeIds.length > 0) {
        params.append('storeid', storeIds.join(','));
    } else {
        params.delete('storeid');
    }

    if (search) {
        params.append('searchQuery', search);
    } else {
        params.delete('searchQuery');
    }

    if (dealerCode) {
        params.append('sortBy', filters?.dealerCarSort.id);
        params.append('category', filters?.dealerCarCategory?.id);
    }
    else {
        params.append('sortBy', filters?.sortBy?.id);
        params.append('category', filters?.ocbLive?.id);
    }

    return params;

}