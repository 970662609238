import { StackLayout } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { TextArea } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { useState } from "react";
import classes from "../styles/SingleUpload.module.css";
import { Loader } from "@progress/kendo-react-indicators";
import { SFService } from "../../../../services";
import { showToastMessage } from "../../../../utils/utils";

const SingleUpload = ({ leadSources }) => {
    const [fields, setFields] = useState({
        token: "",
        source: null,
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleUploadLead = async () => {
        try {
            setIsLoading(true);
            const { token, source } = fields;
            const sanitisedTokens = token
                .split(",")
                .map((t) => t.trim())
                .join(",");

            await SFService.uploadSingleLead({
                token: sanitisedTokens,
                source: source?.value,
            });
            showToastMessage("Uploaded leads successfully", true);
            setFields({
                token: "",
                source: null,
            });
        } catch (error) {
            console.log(error);
            showToastMessage("Failed to upload leads", false);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <StackLayout
            orientation="vertical"
            gap={20}
            className={`k-justify-content-center k-align-items-center ${classes.singleUploadContainer}`}
        >
            <StackLayout className={classes.fieldWrapper}>
                <Label>Enter Lead Token</Label>
                <TextArea
                    value={fields.token}
                    onChange={(e) =>
                        setFields((prev) => ({ ...prev, token: e?.value }))
                    }
                />
            </StackLayout>
            <StackLayout className={classes.fieldWrapper}>
                <Label>Enter Lead Source</Label>
                <ComboBox
                    data={leadSources}
                    value={fields.source}
                    dataItemKey="value"
                    textField="label"
                    className={classes.dropdown}
                    onChange={(e) =>
                        setFields((prev) => ({
                            ...prev,
                            source: e?.value,
                        }))
                    }
                />
            </StackLayout>
            <div className={classes.actionBtnContainer}>
                <Button
                    themeColor="primary"
                    className={`${classes.submitBtn} ${
                        isLoading ? "opacity-25" : ""
                    }`}
                    disabled={
                        !(
                            fields.token?.trim()?.length > 0 &&
                            fields.source?.value
                        ) || isLoading
                    }
                    onClick={handleUploadLead}
                >
                    Upload Lead
                </Button>
                {isLoading && <Loader className={classes.confirmModalLoader} />}
            </div>
        </StackLayout>
    );
};

export default SingleUpload;
