import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  getLoginUserEmail,
  numberFormat,
  showToastMessage,
} from "../../../../../utils/utils";
import { useDispatch } from "react-redux";
import { DEALER_PROCUREMENT } from "../../../../../store/types";
import { DealerProcureService } from "../../../../../services";
import { dealerPayoutPaymentStatuses } from "../../../../../utils/constants/values.constants";

const PaymentAction = (props) => {
  const {
    updatedFlagData,
    titleComponent,
    detailsData,
    handleRetry,
    fetchPayouts,
  } = props || {};
  const dispatch = useDispatch();
  const [isPayConfirm, setIsPayConfirm] = useState(false);
  const handlePay = () => {
    const { id, customAmount, leadId } = updatedFlagData || {};
    const payload = {
      amount: customAmount?.value?.toString(),
      email: getLoginUserEmail(),
    };
    const toastMessage = "Payment initiated successfully";
    const action = "PAY";
    dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_START });
    DealerProcureService.updateFlagSection(
      { leadId: leadId.value, issueId: id.value },
      action,
      payload
    )
      .then(() => {
        showToastMessage(toastMessage, true);
        fetchPayouts(leadId.value);
        togglePay();
      })
      .catch((error) => {
        showToastMessage(error?.message, false);
      })
      .finally(() => {
        dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_END });
      });
  };
  const togglePay = () => {
    setIsPayConfirm(!isPayConfirm);
  };
  return (
    <div className="k-form-buttons">
      <div className="k-mt-5">
        {dealerPayoutPaymentStatuses.includes(
          updatedFlagData?.status?.value
        ) && (
          <Button
            themeColor={"primary"}
            size="large"
            disabled={
              updatedFlagData?.status?.value === "PAYMENT_IN_PROGRESS" ||
              !updatedFlagData?.customAmount?.value
            }
            className="k-mx-6"
            onClick={togglePay}
          >
            PAY
          </Button>
        )}
        {/* {detailsData?.paymentStatus === "PAYMENT_IN_PROGRESS" && (
          <Button
            className="k-mx-5"
            size="large"
            themeColor={"primary"}
            type={"submit"}
            onClick={handleRetry}
          >
            RETRY
          </Button>
        )} */}
      </div>
      {isPayConfirm && (
        <Dialog
          title={titleComponent({
            title: `Confirm payment of   ${numberFormat(
              updatedFlagData?.customAmount?.value
                ? parseInt(updatedFlagData?.customAmount?.value)
                : 0
            )}`,
          })}
          onClose={togglePay}
          width={350}
          contentStyle={{ width: "auto" }}
        >
          <p className="k-w-fit">
            Please confirm that you’ve assessed the issue and have decided to
            pay the given amount.
          </p>
          <DialogActionsBar>
            <Button onClick={handlePay} rounded="full" themeColor={"primary"}>
              Confirm
            </Button>
            <Button onClick={togglePay} themeColor={"secondary"} rounded="full">
              Go back
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};
export default PaymentAction;
