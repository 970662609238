import { addDealerContact, refreshDealers } from "../../../../../store/actions/myDealers";
import { SegmentEvents } from "../../../../constants/events.constants";
import { trackAnalytics } from "../../../../services/analytics";
import { showToastMessage } from "../../../../utils/utils";


export const handleAddContact = (newNumber, dealerId, dispatch, setIsNumberAdded, setNewNumber, email, dealerName) => {

  let trackData = {
    email_id: email,
    dealer_id: dealerId,
    dealer_name: dealerName,
    added_number: newNumber
  }

  if (newNumber.length === 10) {
    dispatch(addDealerContact({ dealerId, newNumber }))
      .then(() => {
        setIsNumberAdded(false);
        setNewNumber('');
        dispatch(refreshDealers(false));
        trackAnalytics(SegmentEvents.Viz_contact_modification, trackData);
        showToastMessage('Contact added successfully', true, 1000);
      })
      .catch(() => {
        showToastMessage('Failed to add contact', false, 1000);
      });
  } else {
    alert('Number must be 10 digits');
  }
};
