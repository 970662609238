import {
	formatDateV2,
	getRequestItemStatus,
	getChallanItemStatus,
	getRoundedByTwoDigit,
} from "../../../../utils/utils";
import { ChallanService } from "../../../../services";
import { CHALLAN_ITEM_STATUSES } from "../../../../utils/constants/values.constants";

export const SELECTED_FIELD_KEY = "selected";

export const challan_item_keys = {
	CHALLAN_ID: "idChallanItem",
	REQUEST_ID: "fkChallanRequest",
	CHALLAN_REQUEST_ID: "idChallanRequest",
	APPOINTMENT_ORDER_ID: "appointmentOrderId",
	REG_NUMBER: "regNumber",
	NOTICE_NUMBER: "noticeNumber",
	CHALLAN_NAME: "offenceName",
	CHALLAN_TYPE: "type",
	CHALLAN_COURT: "court",
	CHALLAN_COURT_ID: "idChallanCourt",
	CHALLAN_OFFENSE_ID: "idChallanOffence",
	CHALLAN_PAYMENT_PROOF: "paymentProofUrl",
	STATUS: "status",
	OFFENCE_DATE: "offenceDate",
	CHALLAN_PDF_URL: "challanPdfUrl",
	CHALLAN_AMOUNT: "amount",
	AGENT_FESS: "agentFees",
	FK_CHALLAN_AGENT: "fkChallanAgent",
	AGENT_NAME: "agent",
	SETTLEMENT_AMOUNT: "settlementAmount",
	ASSIGN_TO: "assignedTo",
	CREATED_BY: "createdBy",
	CREATED_AT: "createdAt",
	UPDATED_BY: "updatedBy",
	UPDATE_AT: "updatedAt",
	REMARKS: "remarks",
	AGEING: "ageing",
	PROACTIVE: "proactive",
	EXPECTED_TAT: "expectedTat",
	CHALLAN_PAYMENT: "payment",
	CHALLAN_REJECTED: "rejected",
	FILE_SINGLE_UPLOAD: "file",
	REGION: "region",
	REGION_ID: "idChallanRegion",
	SOURCE_CODE: "sourceCode",
	ATTEMP_COUNT: "attemptCount",
	DELAY_REASON: "delayReason",
	SUBMIT_CHALLAN: "submitChallan",
	TOTAL_PAYMENT: "totalPayment",
	GOVERNMENT_FEE_PROOF: "governmentFeeProof",
	PAYMENT_DATE: "paymentDate",
	EXCEPTION_PROOF: "expectionProof",
	AGENT_TYPE: "agentType",
	FULFILLMENT_MODE: 'settlementMedium',
	PAYMENT_URL: "paymentUrlPresent",
};

export const OffenseDateCell = (event) => {
	return (
		<td className="td-custom-text">
			{formatDateV2(event?.dataItem?.offenceDate)}
		</td>
	);
};
export const ClosureDateCell = (event) => {
	return (
		<td className="td-custom-text">
			{formatDateV2(event?.dataItem?.paymentDate)}
		</td>
	);
};
export const AgentFeesCell = (event) => {
	return (
		<td className="td-custom-text">
			{getRoundedByTwoDigit(event?.dataItem[challan_item_keys.AGENT_FESS])}
		</td>
	);
};

export const ChallanAmountCell = (event) => {
	return (
		<td className="td-custom-text">
			{getRoundedByTwoDigit(event?.dataItem[challan_item_keys.CHALLAN_AMOUNT])}
		</td>
	);
};
const NoticeNumberCell = (event) => {
	const { idChallanItem, noticeNumber, status } = event.dataItem;
	if (
		["CREATED", "REJECTED"].includes(status) &&
		userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN
	) {
		return (
			<td
				className="hyper-link"
				onClick={() => onEditChallanItem(event.dataItem)}>
				{noticeNumber}
			</td>
		);
	} else {
		return <td>{noticeNumber}</td>;
	}
};
export const SettlementAmountCell = (event) => {
	return (
		<td className="td-custom-text">
			{getRoundedByTwoDigit(
				event?.dataItem[challan_item_keys.SETTLEMENT_AMOUNT]
			)}
		</td>
	);
};
export const TaskCreationDateCell = (event) => {
	return (
		<td className="td-custom-text">
			{formatDateV2(event?.dataItem?.createdAt)}
		</td>
	);
};

const onDownloadFile = (id) => {
	if (id) {
		ChallanService.fetchChallanItemUploadProofFileLink(id)
			.then((resp) => {
				resp?.detail && window.open(resp?.detail, "_blank", "noopener");
			})
			.catch((err) => {
				console.log(err);
				// showToastMessage(err?.message || err?.error, false);
			});
	}
};
export const ProofCell = (event) => {
	const fileName = (event.dataItem?.challanPdfUrl || "").replace(
		/^.*[\\\/]/,
		""
	);
	return (
		<td
			className="hyper-link"
			onClick={() => onDownloadFile(event.dataItem?.idChallanItem)}>
			{fileName}
		</td>
	);
};
export const PaymentProofCell = (event) => {
	const fileName = (event.dataItem?.challanPaymentProof || "").replace(
		/^.*[\\\/]/,
		""
	);
	return (
		<td
			className="hyper-link"
			onClick={() => onDownloadFile(event.dataItem?.idChallanItem)}>
			{fileName}
		</td>
	);
};
export const StatusCell = (event) => {
	const status = getRequestItemStatus(event.dataItem?.status);
	return (
		<td className="k-table-td">
			<span className={status?.css}>{status?.label}</span>
		</td>
	);
};

export const DisposeStatusCell = (event) => {
	const status = getChallanItemStatus(event.dataItem?.status);
	return (
		<td
			title={
				event.dataItem?.status === "RAISE_REJECTED"
					? event.dataItem?.remarks
					: null
			}>
			<span className={status?.css}>{status?.label}</span>
		</td>
	);
};

export const dispose_columns = {
	[challan_item_keys.REG_NUMBER]: {
		label: "Reg Number",
		field: challan_item_keys.REG_NUMBER,
		width: "150px",
	},
	[challan_item_keys.NOTICE_NUMBER]: {
		label: "Notice Number",
		// cell: NoticeNumberCell,
		field: challan_item_keys.NOTICE_NUMBER,
		width: "200px",
	},
	[challan_item_keys.CHALLAN_TYPE]: {
		label: "Challan Mode",
		field: challan_item_keys.CHALLAN_TYPE,
		width: "150px",
	},
	[challan_item_keys.SOURCE_CODE]: {
		label: "Source",
		field: challan_item_keys.SOURCE_CODE,
		width: "150px",
		filterable: true,
	},
	[challan_item_keys.SOURCE_CODE]: {
		label: "Region",
		field: challan_item_keys.REGION,
		width: "150px",
		filterable: true,
	},
	[challan_item_keys.CHALLAN_AMOUNT]: {
		label: "Challan Amount",
		cell: ChallanAmountCell,
		width: "150px",
	},
	[challan_item_keys.REMARKS]: {
		label: "Remarks",
		field: challan_item_keys.REMARKS,
		width: "150px",
	},
	[challan_item_keys.ASSIGN_TO]: {
		label: "Assigned Disposer",
		width: "250px",
	},
	[challan_item_keys.STATUS]: {
		label: "Status",
		width: "120px",
		field: challan_item_keys.STATUS,
		cell: DisposeStatusCell,
	},
	[challan_item_keys.CHALLAN_NAME]: {
		label: "Offense Type",
		field: challan_item_keys.CHALLAN_NAME,
		width: "250px",
	},
	[challan_item_keys.OFFENCE_DATE]: {
		label: "Offense Date",
		cell: OffenseDateCell,
		width: "120px",
	},
	[challan_item_keys.CHALLAN_COURT]: {
		label: "Challan Court",
		field: challan_item_keys.CHALLAN_COURT,
		width: "150px",
	},
	[challan_item_keys.CREATED_AT]: {
		label: "Task Date",
		cell: TaskCreationDateCell,
		width: "120px",
	},
	[challan_item_keys.AGENT_NAME]: {
		label: "Assigned Agent",
		width: "250px",
	},
	[challan_item_keys.AGENT_FESS]: {
		label: "Agent Fees",
		cell: AgentFeesCell,
		width: "150px",
	},
	[challan_item_keys.EXPECTED_TAT]: {
		label: "Expected TAT",
		field: challan_item_keys.EXPECTED_TAT,
		width: "150px",
	},
	[challan_item_keys.AGEING]: {
		label: "Ageing",
		width: "80px",
		field: challan_item_keys.AGEING,
	},
	[challan_item_keys.ATTEMP_COUNT]: {
		field: challan_item_keys.ATTEMP_COUNT,
		label: "Attemp Count",
		width: "120px",
	},
	[challan_item_keys.SETTLEMENT_AMOUNT]: {
		cell: SettlementAmountCell,
		label: "Government fee",
		width: "150px",
	},
	[challan_item_keys.CHALLAN_PAYMENT_PROOF]: {
		cell: PaymentProofCell,
		label: "Proof",
		width: "150px",
	},
	[challan_item_keys.PAYMENT_DATE]: {
		label: "Closure Date",
		field: challan_item_keys.PAYMENT_DATE,
		width: "150px",
	},
	[challan_item_keys.CHALLAN_REJECTED]: {
		label: "Reject Challan",
		width: "150px",
	},
	[challan_item_keys.CHALLAN_PAYMENT]: {
		label: "Challan payment",
		width: "150px",
	},
};

export const getDisplayedCoulmns = (columns) => {
	return Object.keys(columns);
};

export const getSelectedAssignToMeRowIds = (rows, data) => {
	const Ids = Object.keys(rows).filter((key) => {
		const state = rows[key];
		const row = data.find((item) => item.idChallanItem === parseInt(key));
		if (
			state &&
			row &&
			row.status.toUpperCase() === CHALLAN_ITEM_STATUSES.SUBMITTED
		) {
			return key;
		}
	});
	return Ids;
};

export const getSelectedRowIds = (rows, data, status) => {
	const Ids = Object.keys(rows).filter((key) => {
		const state = rows[key];
		const row = data.find((item) => item.idChallanItem === parseInt(key));
		if (state && row.status.toUpperCase() === status) {
			return key;
		}
	});
	return Ids;
};

export const getSelectedBulkRowIds = (rows, data, status) => {
	const Ids = Object.keys(rows).filter((key) => {
		const state = rows[key];
		const row = data.find((item) => item.idChallanItem === parseInt(key));
		if (state && !!row) {
			return row?.idChallanItem;
		}
	});
	return Ids;
};
