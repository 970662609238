import React, { useState, useEffect } from "react";
import CustomGrid from "../../../../common/custom-grid/component";
import { useDispatch, useSelector } from "react-redux";
import {
  dispose_columns,
  challan_item_keys,
  getDisplayedCoulmns,
  SELECTED_FIELD_KEY,
  getSelectedAssignToMeRowIds,
} from "../challan-detail/viewModel";
import {
  fetchDisposeChallanItems,
  // updateChallanItemFiltersParams,
} from "../../../../store/actions/ops-challan";
import {
  convertObjectToParams,
  isObjectEmpty,
  showToastMessage,
  // getFromLocalStorage,
  shortenString,
  getFFUserType,
} from "../../../../utils/utils";
import {
  // LOCAL_STORAGE_KEYS,
  CHALLAN_ITEM_STATUSES,
  CHALLAN_SOURCE_KEYS,
  NUMBERS,
  CHALLAN_MODE_TYPES,
} from "../../../../utils/constants/values.constants";
import { getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { ChallanService } from "../../../../services";
import Filters from "../../challan-list/filters";
import Actions from "../../challan-list/actions";
import ChallanListToolbarItems from "../../challan-list/toolbar-items";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import ChallanItemPopup from "../dispose-challan-item-popup";
// import { Loader } from "../../../../common/Loader";
import {
  FORM_TYPES,
  CHALLAN_PAYMENT_FORM_DATA,
  ASSIGN_AGENT_FORM_DATA,
  REJECT_CHALLAN_FORM_DATA,
  PAYMENT_STATUSES,
  ADMIN_ASSIGN_AGENT_POPUP,
  calculateTotalPayment,
} from "../dispose-challan-item-popup/viewModel";
import { dispose_filters_formData } from "../../../operations/challan-list/filters/components/dispose-filters/viewModel";
import RaisePaymentPopUp from "../../verification/components/raise-payment-popup";
import { DropdownFilter } from "../../../../components/custom-grid-filters";
import AddDelay from "../challan-detail-revamp/component/add-delay";
import { USER_ROLE_KEYS } from "../../../../utils/constants/menu.constants";
const idGetter = getter(challan_item_keys.CHALLAN_ID);
const ALLOWED_SORT_PARAMS = [
  challan_item_keys.AGEING,
  challan_item_keys.ATTEMP_COUNT,
];
const DisposeChallanItems = (props) => {
  const dispatch = useDispatch();
  const challanItems = useSelector((state) => state.opsChallanItem);
  const {
    isLoading,
    data,
    totalCount,
    // numberOfElements,
    channelSource,
    userAssignments,
    filters,
    searchQuery,
    delayed,
  } = challanItems;
  const [paginationParams, setPaginationParams] = useState({
    pageNumber: 0,
    pageSize: 50,
  });
  const [filterParams, setFilterParams] = useState({ isAssigned: 0 });
  const [sortParms, setSortParms] = useState([]);
  let loadKey = "";
  const [selectedState, setSelectedState] = useState({});
  const [visibleColumns, setVisibleColumns] = useState({});
  //   const [isRefreshRequired, setIsRefreshRequired] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPopup, setCurrentPopup] = useState();
  const [isDataExport, setIsDataExport] = useState(false);
  const [gridfilters, setGridfilters] = React.useState([]);
  const [sources, setSources] = useState([]);
  const [gridFilterParams, setGridFilterParams] = useState();
  const userType = getFFUserType();

  const gridfiltersRef = React.useRef(gridfilters);

  const [paymentPopup, setPaymentPopup] = useState(false);
  const [selectedDataItem, setSelectedDataItem] = useState({});
  const resetChallanItems = () => {
    loadKey = "";
    fetchChallanItems("reload");
  };
  const onExportDataStart = () => {
    setIsDataExport(true);
  };

  const onExportDataEnd = () => {
    setIsDataExport(false);
  };

  const sortChange = (e) => {
    const sortKey = !!e?.sort?.length && e.sort[0].field;
    if (ALLOWED_SORT_PARAMS.includes(sortKey)) {
      setSortParms(e.sort);
      fetchChallanItems("sort", e.sort);
    }
  };

  const onAssignedToMe = (data) => {
    ChallanService.assignDisposerToChallanItem(data)
      .then((resp) => {
        showToastMessage("Challan items assigned successfully");
        resetChallanItems();
        setSelectedState({});
      })
      .catch((err) => {
        showToastMessage(err?.message || "Failed", false);
      });
  };
  const AssignedDisposerCell = (event) => {
    const { assignedTo = "" } = event.dataItem;
    if (!!assignedTo) {
      return (
        <td>
          <p style={{ wordWrap: "break-word", textAlign: "center" }}>
            {assignedTo}
            {userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN && (
              <div className="assign-me-wrapper">
                <span
                  className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                  onClick={() =>
                    onChallanItemOpenDialog(
                      FORM_TYPES.ADMIN_ASSIGN_AGENT_DISPOSE,
                      event.dataItem
                    )
                  }
                >
                  Re-Assign
                </span>
              </div>
            )}
          </p>
        </td>
      );
    } else {
      return (
        <td>
          <div className="assign-me-wrapper">
            <span
              className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md m-2"
              onClick={() =>
                userType === USER_ROLE_KEYS.DISPOSE
                  ? onAssignedToMe([event.dataItem?.idChallanItem])
                  : onChallanItemOpenDialog(
                      FORM_TYPES.ADMIN_ASSIGN_AGENT_DISPOSE,
                      event.dataItem
                    )
              }
            >
              {userType === USER_ROLE_KEYS.DISPOSE ? "Assign to Me" : "Assign"}
            </span>
          </div>
        </td>
      );
    }
  };
  const updateCurrentPopupData = ({ key, value, isFile = false }) => {
    let updatedPopupData = {
      ...currentPopup,
      formData: {
        ...currentPopup.formData,
        [key]: {
          ...currentPopup.formData[key],
          ...{ [isFile ? "file" : "value"]: value },
        },
      },
    };
    if (
      currentPopup.key === FORM_TYPES.CHALLAN_PAYMENT &&
      key === challan_item_keys.CHALLAN_TYPE
    ) {
      updatedPopupData = {
        ...updatedPopupData,
        formData: {
          ...updatedPopupData.formData,
          [challan_item_keys.REMARKS]: {
            ...updatedPopupData.formData[challan_item_keys.REMARKS],
            label:
              value?.value === PAYMENT_STATUSES.UNABLE_TO_FULFILL
                ? "Reason for not fulfilling"
                : "Remarks",
            hide: false,
          },
          [challan_item_keys.AGENT_FESS]: {
            ...updatedPopupData.formData[challan_item_keys.AGENT_FESS],
            hide: !(
              value?.value === PAYMENT_STATUSES.PAID &&
              currentPopup?.dataItem?.type.toUpperCase() ===
                CHALLAN_MODE_TYPES.OFFLINE
            ),
          },
          [challan_item_keys.SETTLEMENT_AMOUNT]: {
            ...updatedPopupData.formData[challan_item_keys.SETTLEMENT_AMOUNT],
            hide: !(
              value?.value === PAYMENT_STATUSES.PAID &&
              currentPopup?.dataItem?.type.toUpperCase() ===
                CHALLAN_MODE_TYPES.OFFLINE
            ),
          },
          [challan_item_keys.TOTAL_PAYMENT]: {
            ...updatedPopupData.formData[challan_item_keys.TOTAL_PAYMENT],
            hide: !(
              value?.value === PAYMENT_STATUSES.PAID &&
              currentPopup?.dataItem?.type.toUpperCase() ===
                CHALLAN_MODE_TYPES.OFFLINE
            ),
          },
          [challan_item_keys.FILE_SINGLE_UPLOAD]: {
            ...updatedPopupData.formData[challan_item_keys.FILE_SINGLE_UPLOAD],
            hide: !(value?.value === PAYMENT_STATUSES.PAID),
          },
          [challan_item_keys.GOVERNMENT_FEE_PROOF]: {
            ...updatedPopupData.formData[
              challan_item_keys.GOVERNMENT_FEE_PROOF
            ],
            hide: !(value?.value === PAYMENT_STATUSES.PAID),
          },
        },
      };
    } else if (
      currentPopup.key === FORM_TYPES.CHALLAN_PAYMENT &&
      (key === challan_item_keys.AGENT_FESS ||
        key === challan_item_keys.SETTLEMENT_AMOUNT)
    ) {
      updatedPopupData = {
        ...updatedPopupData,
        formData: {
          ...updatedPopupData.formData,
          [challan_item_keys.TOTAL_PAYMENT]: {
            ...updatedPopupData.formData[challan_item_keys.TOTAL_PAYMENT],
            value: calculateTotalPayment(currentPopup.formData, key, value),
          },
        },
      };
    }
    setCurrentPopup(updatedPopupData);
  };
  const showControlCurrentPopup = (key, value) => {
    let updatedPopupData = {
      ...currentPopup,
      formData: {
        ...currentPopup.formData,
        [key]: {
          ...currentPopup.formData[key],
          hide: value,
        },
      },
    };
    setCurrentPopup(updatedPopupData);
  };
  const showTickRegNumber = (key) => {
    let updatedPopupData = {
      ...currentPopup,
      formData: {
        ...currentPopup.formData,
        [key]: {
          ...currentPopup.formData[key],
          istick: true,
        },
      },
    };
    setCurrentPopup(updatedPopupData);
  };
  const onChallanItemOpenDialog = (action, event, isEdit = false) => {
    let isPopup = true;
    switch (action) {
      case FORM_TYPES.ASSIGN_AGENT:
        const popUtitle = isEdit ? "Update Agent" : "Assign Agent";
        let _agentData = {};
        Object.keys(ASSIGN_AGENT_FORM_DATA).map((key) => {
          _agentData = {
            ..._agentData,
            [key]: { ...ASSIGN_AGENT_FORM_DATA[key], value: null },
          };
        });
        setCurrentPopup({
          key: action,
          title: `${popUtitle} - (Notice Number: ${
            event[challan_item_keys.NOTICE_NUMBER]
          })`,
          dataItem: event,
          formData: { ..._agentData },
        });
        break;
      case FORM_TYPES.REJECT_CHALLAN:
        const isConvert =
          event[challan_item_keys.CHALLAN_TYPE] === CHALLAN_MODE_TYPES.ONLINE &&
          event[challan_item_keys.SOURCE_CODE] === CHALLAN_SOURCE_KEYS.VAS;
        let challan_form_data = { ...REJECT_CHALLAN_FORM_DATA };
        if (isConvert) {
          challan_form_data[challan_item_keys.REMARKS].label =
            "Reason for conversion";
          challan_form_data[challan_item_keys.FILE_SINGLE_UPLOAD].label =
            "Proof of conversion";
        }
        setCurrentPopup({
          key: action,
          title: `${
            isConvert ? "CONVERT TO OFFLINE" : "Reject Challan"
          } - (Notice Number: ${event[challan_item_keys.NOTICE_NUMBER]})`,
          dataItem: event,
          formData: challan_form_data,
          isConvert: isConvert,
        });
        break;
      case FORM_TYPES.CHALLAN_PAYMENT:
        let _formData = { ...CHALLAN_PAYMENT_FORM_DATA };
        _formData[challan_item_keys.REMARKS].hide = true;
        _formData[challan_item_keys.CHALLAN_AMOUNT].value =
          event[challan_item_keys.CHALLAN_AMOUNT];
        setCurrentPopup({
          key: action,
          title: `Challan Payment - (Notice Number: ${
            event[challan_item_keys.NOTICE_NUMBER]
          })`,
          dataItem: event,
          formData: _formData,
        });
        break;
      case FORM_TYPES.ADMIN_ASSIGN_AGENT_DISPOSE:
        let _assignToData = { ...ADMIN_ASSIGN_AGENT_POPUP };
        _assignToData[challan_item_keys.APPOINTMENT_ORDER_ID].value =
          event[challan_item_keys.APPOINTMENT_ORDER_ID];
        _assignToData[challan_item_keys.REG_NUMBER].value =
          event[challan_item_keys.REG_NUMBER];
        _assignToData[challan_item_keys.SOURCE_CODE].value =
          event[challan_item_keys.SOURCE_CODE];
        setCurrentPopup({
          key: action,
          title: `Assign Disposer`,
          dataItem: event,
          formData: _assignToData,
        });
        break;
      default:
        isPopup = false;
        setCurrentPopup({});
        break;
    }
    setOpenDialog(isPopup);
  };

  const handleFilterButtonClick = (e) => {
    const { key: name, value } = e || {};
    setGridFilterParams({});
    if (!!value) {
      setGridFilterParams({ sourceCode: value });
    }
  };

  const AssignedAgentCell = (event) => {
    const {
      assignedTo = "",
      agent = "",
      type = "",
      status = "",
    } = event.dataItem || {};
    const isChallanCycleComplete = [CHALLAN_ITEM_STATUSES.REJECTED].includes(
      status
    );
    if (!!assignedTo && type.toUpperCase() === CHALLAN_MODE_TYPES.OFFLINE) {
      if (!!agent) {
        return (
          <td>
            {status === CHALLAN_ITEM_STATUSES.AGENT_ASSIGNED &&
            userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN ? (
              <p
                className="hyper-link"
                onClick={() =>
                  onChallanItemOpenDialog(
                    FORM_TYPES.ASSIGN_AGENT,
                    event.dataItem,
                    true
                  )
                }
              >
                {agent}
              </p>
            ) : (
              <p style={{ wordWrap: "break-word", textAlign: "center" }}>
                {agent}
              </p>
            )}
          </td>
        );
      } else if (
        isChallanCycleComplete ||
        userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN
      ) {
        return <td>{""}</td>;
      } else {
        return (
          <td>
            <div className="assign-agent-wrapper">
              <span
                className="assign-agent k-button k-button-md k-button-solid k-button-solid-warning k-rounded-md m-2"
                onClick={() =>
                  onChallanItemOpenDialog(
                    FORM_TYPES.ASSIGN_AGENT,
                    event.dataItem
                  )
                }
              >
                Assign Agent
              </span>
            </div>
          </td>
        );
      }
    } else {
      return <td>{""}</td>;
    }
  };
  const onCovertChallan = ({ formType, rowData }) => {
    const { status = "", idChallanItem } = rowData || {};
    if (status === PAYMENT_STATUSES.VIRTUAL_COURT_PROCEEDING) {
      ChallanService.convertChallanVirtualCourtProceeding(idChallanItem)
        .then((resp) => {
          showToastMessage(resp?.message);
          resetChallanItems();
        })
        .catch((err) => {
          showToastMessage(err?.message || "Failed to convert", false);
        });
    } else {
      onChallanItemOpenDialog(formType, rowData);
    }
  };
  const RejectedChallanCell = (event) => {
    const {
      assignedTo = "",
      agent = "",
      status = "",
      sourceCode = "",
      type = "",
    } = event.dataItem || {};
    const isChallanCycleComplete = [
      PAYMENT_STATUSES.PAID,
      PAYMENT_STATUSES.UNABLE_TO_FULFILL,
      CHALLAN_ITEM_STATUSES.PAYMENT_RAISED,
      CHALLAN_ITEM_STATUSES.REJECTED,
      CHALLAN_ITEM_STATUSES.RAISE_REJECTED,
      CHALLAN_ITEM_STATUSES.RAISE_ACCEPTED,
      CHALLAN_ITEM_STATUSES.RAISE_AMENDED,
    ].includes(status);
    if (
      !!assignedTo &&
      !isChallanCycleComplete &&
      sourceCode !== CHALLAN_SOURCE_KEYS.VAS &&
      status !== PAYMENT_STATUSES.VIRTUAL_COURT_PROCEEDING &&
      userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN
    ) {
      return (
        <td>
          <div className="reject-challan-wrapper">
            <span
              className="reject-challan assign-agent k-button k-button-md k-button-soliD k-rounded-md m-2"
              onClick={() =>
                onChallanItemOpenDialog(
                  FORM_TYPES.REJECT_CHALLAN,
                  event.dataItem
                )
              }
            >
              Reject
            </span>
          </div>
        </td>
      );
    } else if (
      !!assignedTo &&
      !isChallanCycleComplete &&
      ((sourceCode === CHALLAN_SOURCE_KEYS.VAS &&
        type === CHALLAN_MODE_TYPES.ONLINE) ||
        (sourceCode !== CHALLAN_SOURCE_KEYS.VAS &&
          status === PAYMENT_STATUSES.VIRTUAL_COURT_PROCEEDING)) &&
      userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN
    ) {
      return (
        <td>
          <div className="reject-challan-wrapper">
            <span
              className="reject-challan assign-agent k-button k-button-md k-button-soliD k-rounded-md m-2"
              onClick={() =>
                onCovertChallan({
                  formType: FORM_TYPES.REJECT_CHALLAN,
                  rowData: event.dataItem,
                })
              }
            >
              Convert
            </span>
          </div>
        </td>
      );
    } else {
      return <td>{""}</td>;
    }
  };
  const ChallanPaymentCell = (event) => {
    const {
      assignedTo = "",
      agent = "",
      type = "",
      status = "",
    } = event.dataItem || {};
    const itemType = !!type && type.toUpperCase();
    const isChallanCycleComplete = [
      PAYMENT_STATUSES.PAID,
      PAYMENT_STATUSES.UNABLE_TO_FULFILL,
      "REJECTED",
      CHALLAN_ITEM_STATUSES.RAISE_REJECTED,
      CHALLAN_ITEM_STATUSES.PAYMENT_RAISED,
      CHALLAN_ITEM_STATUSES.RAISE_ACCEPTED,
      CHALLAN_ITEM_STATUSES.RAISE_AMENDED,
    ].includes(status);
    if (
      !isChallanCycleComplete &&
      !!assignedTo &&
      ((itemType === CHALLAN_MODE_TYPES.OFFLINE && !!agent) ||
        [CHALLAN_MODE_TYPES.ONLINE, CHALLAN_MODE_TYPES.VIRTUAL_COURT].includes(
          itemType
        )) &&
      userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN
    ) {
      return (
        <td>
          <span
            className="challan-payment assign-agent k-button k-button-md k-button-soliD k-rounded-md m-2"
            onClick={() =>
              onChallanItemOpenDialog(
                FORM_TYPES.CHALLAN_PAYMENT,
                event.dataItem
              )
            }
          >
            Payment
          </span>
        </td>
      );
    } else if (
      !isChallanCycleComplete &&
      !!assignedTo &&
      !agent &&
      itemType === CHALLAN_MODE_TYPES.OFFLINE &&
      userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN
    ) {
      return (
        <td>
          <span
            className="challan-payment assign-agent k-button k-button-md k-button-soliD k-rounded-md m-2"
            onClick={() =>
              onChallanItemOpenDialog(
                FORM_TYPES.CHALLAN_PAYMENT,
                event.dataItem
              )
            }
          >
            Payment
          </span>
        </td>
      );
    } else if (status === PAYMENT_STATUSES.PAID && itemType === "OFFLINE") {
      const handleOnClick = () => {
        setSelectedDataItem(event?.dataItem);
        openPaymentPopup();
      };
      return (
        <td>
          <span
            className="raise-payment k-button k-button-md k-button-soliD k-rounded-md m-2"
            onClick={handleOnClick}
          >
            Raise Payment
          </span>
        </td>
      );
    } else if (status === PAYMENT_STATUSES.RAISE_REJECTED) {
      const handleOnClick = () => {
        setSelectedDataItem(event?.dataItem);
        openPaymentPopup();
      };
      return (
        <td>
          <span
            className="raise-payment k-button k-button-md k-button-soliD k-rounded-md m-2"
            onClick={handleOnClick}
          >
            Amend Raise
          </span>
        </td>
      );
    } else {
      return <td>{""}</td>;
    }
  };

  const DelayReasonCell = (event) => {
    const { dataItem } = event || {};
    const [delayPopup, setDelayPopup] = useState(false);

    const openAddDelayDialog = () => {
      setDelayPopup(!delayPopup);
    };
    return (
      <td
        title={!!dataItem?.delayReason ? dataItem?.delayReason : null}
        style={{ textAlign: "center" }}
      >
        {!!dataItem?.delayReason ? (
          <>
            {" "}
            <span>{shortenString(dataItem?.delayReason, 10)}</span>{" "}
            {userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN && (
              <span
                onClick={openAddDelayDialog}
                className="k-icon k-i-edit k-i-pencil"
                style={{ cursor: "pointer" }}
              />
            )}
          </>
        ) : (
          <>
            {userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN && (
              <Button
                onClick={openAddDelayDialog}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base m-2"
                themeColor={"primary"}
              >
                Add Delay
              </Button>
            )}
          </>
        )}
        {delayPopup && (
          <Dialog title={"Delay Reason"} onClose={openAddDelayDialog}>
            <AddDelay
              userGroup={USER_ROLE_KEYS.DISPOSE}
              challanItems={[dataItem]}
              onClose={openAddDelayDialog}
              resetChallanItems={resetChallanItems}
            />
          </Dialog>
        )}
      </td>
    );
  };
  useEffect(() => {
    ChallanService.fetchAllSources().then((resp) => {
      const srcs = resp?.detail?.map((item) => {
        return {
          value: item.code,
          label: item.name,
        };
      });
      setSources(srcs);
    });

    setVisibleColumns(() => ({
      ...dispose_columns,
      [challan_item_keys.ASSIGN_TO]: {
        ...dispose_columns[challan_item_keys.ASSIGN_TO],
        cell: AssignedDisposerCell,
      },
      [challan_item_keys.AGENT_NAME]: {
        ...dispose_columns[challan_item_keys.AGENT_NAME],
        cell: AssignedAgentCell,
      },
      [challan_item_keys.CHALLAN_REJECTED]: {
        ...dispose_columns[challan_item_keys.CHALLAN_REJECTED],
        cell: RejectedChallanCell,
      },
      [challan_item_keys.CHALLAN_PAYMENT]: {
        ...dispose_columns[challan_item_keys.CHALLAN_PAYMENT],
        cell: ChallanPaymentCell,
      },
      ...(userAssignments === NUMBERS.FOUR && {
        [challan_item_keys.DELAY_REASON]: {
          label: "Delay Reason",
          cell: DelayReasonCell,
          width: "150px",
        },
      }),
    }));
  }, [userAssignments]);

  useEffect(() => {
    if (!gridFilterParams?.sourceCode) {
      delete filterParams["sourceCode"];
    }
    setFilterParams({ ...filterParams, ...gridFilterParams });
  }, [gridFilterParams]);

  useEffect(() => {
    setVisibleColumns((columns) => ({
      ...columns,
      [challan_item_keys.SOURCE_CODE]: {
        ...columns[challan_item_keys.SOURCE_CODE],
        filterCell: (props) => (
          <DropdownFilter
            {...props}
            data={sources || []}
            onFilterButtonClick={handleFilterButtonClick}
            label={"Source"}
          />
        ),
      },
    }));
  }, [sources]);

  useEffect(() => {
    !!searchQuery
      ? setFilterParams({ [searchQuery?.selectedOption]: searchQuery?.value })
      : setFilterParams({ isAssigned: 0 });
  }, [searchQuery]);

  useEffect(() => {
    let params = { ...filterParams };
    Object.keys(dispose_filters_formData).map((key) => {
      delete params[key];
    });
    params = {
      ...params,
      ...filters,
    };
    setFilterParams(params);
  }, [filters]);

  useEffect(() => {
    if (userAssignments !== null) {
      let params = { ...filterParams };
      delete params["isDelayed"];
      params = {
        ...params,
        isAssigned: userAssignments,
      };
      setFilterParams(params);
    }
  }, [userAssignments]);

  useEffect(() => {
    if (!!delayed) {
      let params = { ...filterParams };
      delete params["isAssigned"];
      params = {
        ...params,
        isDelayed: 1,
      };
      setFilterParams(params);
    }
  }, [delayed]);

  useEffect(() => {
    let params = { ...filterParams };
    delete params[challan_item_keys.CHALLAN_TYPE];
    if (!!channelSource) {
      params = {
        ...params,
        [challan_item_keys.CHALLAN_TYPE]: channelSource,
      };
    }
    setFilterParams(params);
  }, [channelSource]);

  useEffect(() => {
    if (!isObjectEmpty(filterParams)) {
      fetchChallanItems("filterParams");
    }
  }, [filterParams]);

  useEffect(() => {
    if (!isObjectEmpty(paginationParams)) {
      fetchChallanItems("paginationParams");
    }
  }, [paginationParams]);

  const pageChange = ({ page = 0, size = 50 }) => {
    setPaginationParams({ pageNumber: page, pageSize: size });
  };
  const fetchChallanItems = (key, sort = []) => {
    if (!loadKey && !isLoading) {
      loadKey = key;
      const params = { ...paginationParams, ...filterParams };
      let qsParams = convertObjectToParams(params);
      if (!!sort.length) {
        qsParams = `${qsParams}&sortBy=${sort[0].field}&sortDir=${sort[0].dir}`;
      }
      dispatch(fetchDisposeChallanItems(qsParams));
    }
  };
  const onRowSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: challan_item_keys.CHALLAN_ID,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
      item[SELECTED_FIELD_KEY] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  const onAssignItems = () => {
    const challanItems = getSelectedAssignToMeRowIds(selectedState, data);
    if (!!challanItems.length) {
      onAssignedToMe(challanItems);
    } else {
      showToastMessage("Please select atleast one row to continue", false);
    }
  };

  const onResetPopup = () => {
    switch (currentPopup.key) {
      case FORM_TYPES.ASSIGN_AGENT:
        setCurrentPopup((prev) => ({
          ...prev,
          formData: { ...ASSIGN_AGENT_FORM_DATA },
        }));
        break;
      case FORM_TYPES.REJECT_CHALLAN:
        setCurrentPopup((prev) => ({
          ...prev,
          formData: { ...REJECT_CHALLAN_FORM_DATA },
        }));
        break;
      case FORM_TYPES.CHALLAN_PAYMENT:
        setCurrentPopup((prev) => ({
          ...prev,
          formData: { ...CHALLAN_PAYMENT_FORM_DATA },
        }));
        break;
      case FORM_TYPES.ADMIN_ASSIGN_AGENT_DISPOSE:
        setCurrentPopup((prev) => ({
          ...prev,
          formData: { ...ADMIN_ASSIGN_AGENT_POPUP },
        }));
        break;
      default:
        setCurrentPopup({});
        break;
    }
  };

  const onPopupConfirm = () => {
    if (currentPopup.key === FORM_TYPES.ASSIGN_AGENT) {
      const payload = {
        [challan_item_keys.CHALLAN_COURT]:
          currentPopup.formData[challan_item_keys.CHALLAN_COURT]?.value?.label,
        [challan_item_keys.REGION]:
          currentPopup.formData[challan_item_keys.REGION]?.value?.label,
        idChallanAgent:
          currentPopup.formData[challan_item_keys.AGENT_NAME]?.value?.value,
        [challan_item_keys.AGENT_NAME]:
          currentPopup.formData[challan_item_keys.AGENT_NAME]?.value?.label,
      };
      if (!currentPopup.formData[challan_item_keys.REGION]?.value?.value) {
        showToastMessage("Please select region", false);
        return;
      } else if (
        !currentPopup.formData[challan_item_keys.CHALLAN_COURT]?.value?.value
      ) {
        showToastMessage("Please select court", false);
        return;
      } else if (
        !currentPopup.formData[challan_item_keys.AGENT_NAME]?.value?.value
      ) {
        showToastMessage("Please select agent", false);
        return;
      }
      ChallanService.assignAgentToChallanItem(
        currentPopup?.dataItem?.idChallanItem,
        payload
      )
        .then((resp) => {
          showToastMessage(resp?.message);
          setOpenDialog(false);
          resetChallanItems();
        })
        .catch((err) => {
          showToastMessage(err?.message || "Failed", false);
        });
    } else if (currentPopup.key === FORM_TYPES.REJECT_CHALLAN) {
      const reason = currentPopup.formData[challan_item_keys.REMARKS]?.value;
      const fileProof =
        currentPopup.formData[challan_item_keys.FILE_SINGLE_UPLOAD]?.file;
      if (!reason) {
        showToastMessage("Please select reason", false);
        return;
      } else if (!fileProof) {
        showToastMessage("Please upload rejection proof", false);
        return;
      }
      let formData = new FormData();
      formData.append("file", fileProof);
      formData.append("remarks", reason);
      const action = currentPopup.isConvert ? "convert" : "reject";
      ChallanService.updateChallanItemAction(
        currentPopup?.dataItem?.idChallanItem,
        formData,
        action
      )
        .then((resp) => {
          showToastMessage(resp?.message);
          setOpenDialog(false);
          resetChallanItems();
        })
        .catch((err) => {
          showToastMessage(err?.message || "Failed", false);
        });
    } else if (currentPopup.key === FORM_TYPES.CHALLAN_PAYMENT) {
      const regNumber = currentPopup.formData[challan_item_keys.REG_NUMBER];
      //validate
      const status =
        currentPopup.formData[challan_item_keys.CHALLAN_TYPE]?.value?.value;
      const settlementAmt =
        currentPopup.formData[challan_item_keys.SETTLEMENT_AMOUNT]?.value;
      const agentFees =
        currentPopup.formData[challan_item_keys.AGENT_FESS]?.value;
      const paymentproof =
        currentPopup.formData[challan_item_keys.FILE_SINGLE_UPLOAD]?.file;
      const remarks = currentPopup.formData[challan_item_keys.REMARKS]?.value;
      const governmentFeeProof =
        currentPopup.formData[challan_item_keys.GOVERNMENT_FEE_PROOF]?.file;
      if (regNumber?.value === "_") {
        showToastMessage("Please select reason", false);
        return;
      } else if (!regNumber?.istick) {
        showToastMessage("Please select valid registration number", false);
        return;
      } else if (
        currentPopup?.dataItem?.type === CHALLAN_MODE_TYPES.OFFLINE &&
        !agentFees &&
        status === PAYMENT_STATUSES.PAID
      ) {
        showToastMessage("Please select agent fees", false);
        return;
      } else if (!status) {
        showToastMessage("Please select challan status", false);
        return;
      } else if (
        currentPopup?.dataItem?.type === CHALLAN_MODE_TYPES.OFFLINE &&
        !settlementAmt &&
        status === PAYMENT_STATUSES.PAID
      ) {
        showToastMessage("Please select government fee", false);
        return;
      } else if (!paymentproof && status === PAYMENT_STATUSES.PAID) {
        showToastMessage("Please upload payment proof", false);
        return;
      } else if (status === PAYMENT_STATUSES.UNABLE_TO_FULFILL && !remarks) {
        showToastMessage("Please upload reason for unfulfillment", false);
        return;
      }
      let formData = new FormData();
      if (status === PAYMENT_STATUSES.PAID) {
        formData.append(challan_item_keys.FILE_SINGLE_UPLOAD, paymentproof);
        if (currentPopup?.dataItem?.type === CHALLAN_MODE_TYPES.OFFLINE) {
          formData.append(challan_item_keys.AGENT_FESS, agentFees);
          formData.append(challan_item_keys.SETTLEMENT_AMOUNT, settlementAmt);
        }
      }
      formData.append(challan_item_keys.STATUS, status);
      formData.append(challan_item_keys.REMARKS, remarks);
      !!governmentFeeProof &&
        formData.append(
          challan_item_keys.GOVERNMENT_FEE_PROOF,
          governmentFeeProof
        );
      ChallanService.paymentChallanItem(
        currentPopup?.dataItem?.idChallanItem,
        formData
      )
        .then((resp) => {
          showToastMessage(resp?.message);
          setOpenDialog(false);
          resetChallanItems();
        })
        .catch((err) => {
          showToastMessage(err?.message || "Failed", false);
        });
    } else if (currentPopup.key === FORM_TYPES.ADMIN_ASSIGN_AGENT_DISPOSE) {
      const regNumber = currentPopup.formData[challan_item_keys.REG_NUMBER];
      const assignTo =
        currentPopup.formData[challan_item_keys.ASSIGN_TO]?.value?.value;
      if (!regNumber?.value) {
        showToastMessage(
          "Please select valid vehicle registration number",
          false
        );
        return;
      } else if (!assignTo) {
        showToastMessage("Please select disposer", false);
        return;
      }
      ChallanService.UpdateAssignTo(
        currentPopup?.dataItem?.idChallanItem,
        assignTo,
        "dispose"
      )
        .then((resp) => {
          showToastMessage(resp?.message);
          setOpenDialog(false);
          resetChallanItems();
        })
        .catch((err) => {
          showToastMessage(err?.message || "Failed", false);
        });
    }
  };

  const updateFilterValues = (e) => {
    gridfiltersRef.current = e?.filter;
    setGridfilters(e?.filter);
  };

  const openPaymentPopup = () => {
    setPaymentPopup(!paymentPopup);
  };
  return (
    <>
      <div className="ops-channel-page-bar">
        <Actions
          pageKey={props.pageKey}
          onAssignItems={onAssignItems}
          isDataExport={isDataExport}
          onExportDataStart={onExportDataStart}
        />
        <Filters pageKey={props.pageKey} />
      </div>
      <div className="middleContent">
        <ChallanListToolbarItems pageKey={props.pageKey} />
        <>
          {!isLoading && (
            <CustomGrid
              displayedColumns={getDisplayedCoulmns(visibleColumns)}
              columnMap={visibleColumns}
              data={data?.map((item) => ({
                ...item,
                [SELECTED_FIELD_KEY]: selectedState[idGetter(item)],
              }))}
              count={totalCount}
              pageChange={pageChange}
              paginationParams={paginationParams}
              selectedField={SELECTED_FIELD_KEY}
              idField={challan_item_keys.CHALLAN_ID}
              onSelectionChange={onRowSelectionChange}
              onHeaderSelectionChange={onHeaderSelectionChange}
              selectable={true}
              isDataExport={isDataExport}
              onExportDataEnd={onExportDataEnd}
              isPagination={true}
              sortable={true}
              sortParms={sortParms}
              onSortChange={sortChange}
              filterable={true}
              filter={gridfilters}
              onFilterChange={updateFilterValues}
              fixedHeight={{ height: "650px" }}
            />
          )}
          {openDialog && (
            <Dialog
              title={currentPopup?.title}
              onClose={() => {
                setOpenDialog(false);
              }}
            >
              <ChallanItemPopup
                {...currentPopup}
                action={currentPopup.key}
                updateCurrentPopupData={updateCurrentPopupData}
                showTickRegNumber={showTickRegNumber}
              />
              <DialogActionsBar>
                <Button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  onClick={onResetPopup}
                >
                  Reset
                </Button>
                <Button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  onClick={onPopupConfirm}
                  themeColor={"primary"}
                >
                  Confirm
                </Button>
              </DialogActionsBar>
            </Dialog>
          )}
          {paymentPopup && (
            <Dialog
              title={
                selectedDataItem?.status === PAYMENT_STATUSES.PAID
                  ? "Raise Payment"
                  : "Amend Raise Payment"
              }
              onClose={openPaymentPopup}
            >
              <RaisePaymentPopUp
                data={selectedDataItem}
                onClose={openPaymentPopup}
                resetChallanItems={resetChallanItems}
              />
            </Dialog>
          )}
        </>
      </div>
    </>
  );
};
export default DisposeChallanItems;
