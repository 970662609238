const currentEnv = process.env.REACT_APP_ENV;
export const getTenantId = () => {
	const metaData = {
		QA: "T2XqU4BIJHFHvxdWgUQ8X0NWGkOx",
		STAGING: "T2XqU4BIJHFHvxdWgUQ8X0NWGkOx",
		PRODUCTION: "T2ZOSj4E10QQuONVCOXFngrGzHZk",
	};
	return metaData[currentEnv];
};
export const getServiceHistoryMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/c2b-ops",
			key: "Z4nimHuhoi8yZXM2aMPlKTBc",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/c2b-ops",
			key: "Z4nimHuhoi8yZXM2aMPlKTBc",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/c2b-ops",
			key: "8SC2l9uFxDU4A2O4m7jZTpwcm9kdWN0IW9u",
		},
	};
	return metaData[currentEnv];
};

export const getConsumerServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/cms",
			key: "YzJiX2Zyb250ZW5kOkszcHJyT0cwNm1uZE41V0FMS0NTQUxuQVd3eHBXQ0hJ",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/cms",
			key: "cGFya19wbHVzOmEwNzAxNWI0OTgzYTYyOTg4MGYyZDQwN2ZlZDY0YzgyYmIxNDhhMGQ=",
		},
		PRODUCTION: {
			url: "https://vehicle.cars24.team",
			key: "YjJiX2NoYW5uZWxfcGFydG5lcjpmZjhjOGU4MWE5YmU3MGRjOTUxNWU1MGQyYjNhYmJlZTA1MWM0Mjdi",
		},
	};
	return metaData[currentEnv];
};
export const getOpsChallanServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/challan-fulfilment",
			key: "Y2hhbGxhbi1zZXJ2aWNlLXFh",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/challan-fulfilment",
			key: "Y2hhbGxhbi1zZXJ2aWNlLXN0YWdl",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/challan-fulfilment",
			key: "YzJiLWNoQGxsQG4kc2VydmljZTpwcm9kdWN0IW9u",
		},
	};
	return metaData[currentEnv];
};

export const getSupersetMetaData = () => {
  const configs = {
      QA: { key: '167', url: 'https://superset-prod.cars24.team' },
      STAGING: { key: '167', url: 'https://superset-prod.cars24.team' },
      PRODUCTION: { key: '167', url: 'https://superset-prod.cars24.team' }
  };
  return configs[currentEnv];
};

export const getDealerProcurementServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/partners-lead",
			key: "",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/partners-lead",
			key: "",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/partners-lead",
			key: "",
		},
	};
	return metaData[currentEnv];
};

export const getInventoryServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/inventory",
			key: "",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/inventory",
			key: "",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/inventory",
			key: "",
		},
	};
	return metaData[currentEnv];
};
export const getPrAutomationServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/oms",
			key: "",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/oms",
			key: "",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/oms",
			key: "",
		},
	};
	return metaData[currentEnv];
};
export const getDmsServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/dms",
			key: "",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/dms",
			key: "",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/dms",
			key: "",
		},
	};
	return metaData[currentEnv];
};
export const getLmsServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/lms",
			key: "",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/lms",
			key: "",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/lms",
			key: "",
		},
	};
	return metaData[currentEnv];
};
export const getDealerServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/dealer",
			key: "",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/dealer",
			key: "",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/dealer",
			key: "",
		},
	};
	return metaData[currentEnv];
};
export const getRetailServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/cms",
			key: "YzI0X2FwaTpCMHctOWVnc3lNUk1nbTFGQko5Q2J2YTVJYXdOQmVSZg==",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/cms",
			key: "YzI0X2FwaTpCMHctOWVnc3lNUk1nbTFGQko5Q2J2YTVJYXdOQmVSZg==",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/cms",
			key: "YzJiLXBhbmVsLWludGVybmFsOjgwN2QzOGVhNjkzMDJkYTM0ZGZkYzUwMWJlM2M4Y2MwZWJmNGYyYzU=",
		},
	};
	return metaData[currentEnv];
};

export const getVASServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/vas",
			key: "YzI0X2FwaTpCMHctOWVnc3lNUk1nbTFGQko5Q2J2YTVJYXdOQmVSZg==",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/vas",
			key: "YzI0X2FwaTpCMHctOWVnc3lNUk1nbTFGQko5Q2J2YTVJYXdOQmVSZg==",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/vas",
			key: "YzJiLXBhbmVsLWludGVybmFsOjgwN2QzOGVhNjkzMDJkYTM0ZGZkYzUwMWJlM2M4Y2MwZWJmNGYyYzU=",
		},
	};
	return metaData[currentEnv];
};

export const getVehicleServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/vehicle",
			key: "YzI0X2FwaTpCMHctOWVnc3lNUk1nbTFGQko5Q2J2YTVJYXdOQmVSZg==",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/vehicle",
			key: "YzI0X2FwaTpCMHctOWVnc3lNUk1nbTFGQko5Q2J2YTVJYXdOQmVSZg==",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/vehicle",
			key: "YzJiLXBhbmVsLWludGVybmFsOjgwN2QzOGVhNjkzMDJkYTM0ZGZkYzUwMWJlM2M4Y2MwZWJmNGYyYzU=",
		},
	};
	return metaData[currentEnv];
};

export const getLocationServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/location-service",
			key: "YzI0X2FwaTpCMHctOWVnc3lNUk1nbTFGQko5Q2J2YTVJYXdOQmVSZg==",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/location-service",
			key: "YzI0X2FwaTpCMHctOWVnc3lNUk1nbTFGQko5Q2J2YTVJYXdOQmVSZg==",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/location-service",
			key: "YzJiLXBhbmVsLWludGVybmFsOjgwN2QzOGVhNjkzMDJkYTM0ZGZkYzUwMWJlM2M4Y2MwZWJmNGYyYzU=",
		},
	};
	return metaData[currentEnv];
};
export const getVahanServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/vahan-service",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/vahan-service",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/vahan-service",
		},
	};
	return metaData[currentEnv];
};

export const getVerificationMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/challan-fulfilment",
			key: "Y2hhbGxhbi1zZXJ2aWNlLXFh",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/challan-fulfilment",
			key: "Y2hhbGxhbi1zZXJ2aWNlLXN0YWdl",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/challan-fulfilment",
			key: "YzJiLWNoQGxsQG4kc2VydmljZTpwcm9kdWN0IW9u",
		},
	};
	return metaData[currentEnv];
};

export const getOpsAdministratorServiceMetaData = () => {
    const metaData = {
      QA: {
        url: "https://c2b-internal-portal-gateway.qac24svc.dev/challan-fulfilment",
        key: "Y2hhbGxhbi1zZXJ2aWNlLXFh",
      },
      STAGING: {
        url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/challan-fulfilment",
        key: "Y2hhbGxhbi1zZXJ2aWNlLXN0YWdl",
      },
      PRODUCTION: {
        url: "https://gateway.24c.in/challan-fulfilment",
        key: "YzJiLWNoQGxsQG4kc2VydmljZTpwcm9kdWN0IW9u",
      },
    };
    return metaData[currentEnv];
  };

export const getSellerFinanceMetaData = () => {
  const metaData = {
    QA: {
      url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/finance-lead",
      key: "YzI0X2FwaTpCMHctOWVnc3lNUk1nbTFGQko5Q2J2YTVJYXdOQmVSZg==",
    },
    STAGING: {
      url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/finance-lead",
      key: "YzI0X2FwaTpCMHctOWVnc3lNUk1nbTFGQko5Q2J2YTVJYXdOQmVSZg==",
    },
    PRODUCTION: {
      url: "https://gateway.24c.in/finance-lead",
      key: "YzJiLXBhbmVsLWludGVybmFsOjgwN2QzOGVhNjkzMDJkYTM0ZGZkYzUwMWJlM2M4Y2MwZWJmNGYyYzU=",
    },
  };
  return metaData[currentEnv];
}

export const getVehicleInspectionServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/c2b-vehicle-inspection/c2b",
			key: "Y2hhbGxhbi1zZXJ2aWNlLXFh",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/c2b-vehicle-inspection/c2b",
			key: "Y2hhbGxhbi1zZXJ2aWNlLXN0YWdl",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/c2b-vehicle-inspection/c2b",
			key: "YzJiLWNoQGxsQG4kc2VydmljZTpwcm9kdWN0IW9u",
		},
	};
	return metaData[currentEnv];
};

export const getInspectionServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/c2b-vehicle-inspection/social",
			key: "Y2hhbGxhbi1zZXJ2aWNlLXFh",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/c2b-vehicle-inspection/social",
			key: "Y2hhbGxhbi1zZXJ2aWNlLXN0YWdl",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/c2b-vehicle-inspection/social",
			key: "YzJiLWNoQGxsQG4kc2VydmljZTpwcm9kdWN0IW9u",
		},
	};
	return metaData[currentEnv];
}

export const thumborUrl = () => {
	const urls = {
		QA: 'https://asset-inspection.ninja24.in/',
		STAGING: 'https://img-staging.24c.in/',
		PRODUCTION: 'https://fastly-production.24c.in/',
	};
	return urls[currentEnv];
};

export const sellerFinanceCRM = () => {
	const urls = {
		QA: 'https://c2b-website.qac24svc.dev/',
		STAGING: 'https://stage-c2b-website.qac24svc.dev/',
		PRODUCTION: 'https://cars24.com/',
	}
	return urls[currentEnv];
}

export const getCJIncentiveServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/inspection-incentive",
			key: "Y2hhbGxhbi1zZXJ2aWNlLXFh",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/inspection-incentive",
			key: "Y2hhbGxhbi1zZXJ2aWNlLXN0YWdl",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/inspection-incentive",
			key: "YzJiLWNoQGxsQG4kc2VydmljZTpwcm9kdWN0IW9u",
		},
	};
	return metaData[currentEnv];
};

export const getInspectionReferralMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/c2b-vehicle-inspection",
			key: "Y2hhbGxhbi1zZXJ2aWNlLXFh",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/c2b-vehicle-inspection",
			key: "Y2hhbGxhbi1zZXJ2aWNlLXN0YWdl",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/c2b-vehicle-inspection",
			key: "YzJiLWNoQGxsQG4kc2VydmljZTpwcm9kdWN0IW9u",
		},
	};
	return metaData[currentEnv];
}

export const getInspectionFeedServiceMetaData = () => {
	const metaData = {
		QA: {
			url: "https://c2b-internal-portal-gateway.qac24svc.dev/c2b-vehicle-inspection",
			key: "Y2hhbGxhbi1zZXJ2aWNlLXFh",
		},
		STAGING: {
			url: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/c2b-vehicle-inspection",
			key: "Y2hhbGxhbi1zZXJ2aWNlLXN0YWdl",
		},
		PRODUCTION: {
			url: "https://gateway.24c.in/c2b-vehicle-inspection",
			key: "YzJiLWNoQGxsQG4kc2VydmljZTpwcm9kdWN0IW9u",
		},
	};
	return metaData[currentEnv];
}

export const videoUrl = () => {
	const urls = {
		QA: 'https://asset-inspection.ninja24.in/',
		STAGING: 'https://s3.ap-south-1.amazonaws.com/videos-staging.24c.in/',
		PRODUCTION: 'https://fastly-videos.24c.in/',
	};
	return urls[currentEnv];
}

export const getBaseUrl = () => {
	const metaData = {
        QA: "https://c2b-internal-portal-gateway.qac24svc.dev/",
		STAGING: "https://stage-c2b-internal-portal-gateway.qac24svc.dev/",
		PRODUCTION: "https://gateway.24c.in/",
	};
	return metaData[currentEnv];
};

export const getNewCarServiceBaseUrl = () => {
    const metaData = {
        QA: "https://new-car-api.qac24svc.dev/",
        STAGING: "https://new-car-api.qac24svc.dev/",
        PRODUCTION: "https://new-car-api-prod.cars24.team/",
    };
    return metaData[currentEnv];
};

export const getGenAiBaseUrl = () => {
	const metaData = {
		QA: "https://genai-coordinator.qac24svc.dev/",
		STAGING: "https://genai-coordinator.qac24svc.dev/",
		PRODUCTION: "https://genai-coordinator.c24.tech/",
	};
	return metaData[currentEnv];
};

export const getAdminPortalUrl = () => {
	const metaData = {
		QA: "https://admin-staging.c24svc.app/",
		STAGING: "https://admin-staging.c24svc.app/",
		PRODUCTION: "https://admin.24c.in/",
	};
	return metaData[currentEnv];
};