import React, { useEffect, useState } from "react";
// import styles from "../../assets/style/ff-layout.module.css";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import DetailCardLayout from "../../components/layout-detail-card";
import { NUMBERS } from "../../../../utils/constants/values.constants";
import { finder_detail_list_keys } from "./viewModal";
// import FormCardLayout from "../../components/layout-form-card";
import { ChallanService } from "../../../../services";
import ChallanItemGrid from "./component";
// import { getRequestChallanItems } from "../../../../store/actions/ops-challan";
import { Loader } from "../../../../common/Loader";
import LayoutStatusBanner from "../../components/layout-status-banner";
import CellTooltip from "../../../../common/cell-tooltip";
import { challan_item_keys } from "../../components/challan-detail-revamp/viewModel";
import { formatDateV2 } from "../../../../utils/utils";
import { updateFinderChallanRequest } from "../../../../store/actions/ops-challan";
import { useDispatch } from "react-redux";
const FinderDetailView = (props = {}) => {
  const { rowData = {}, onBackClick } = props;
  const { idChallanRequest } = rowData;
  const [challanRequestInfo, setChallanRequestInfo] = useState([]);
  const [challanRequestDetail, setChallanRequestDetail] = useState({});
  const [challanRequestDetailItems, setChallanRequestDetailItems] = useState(
    {}
  );
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setRefresh(true);
  }, [idChallanRequest]);

  useEffect(() => {
    RefreshData();
  }, [refresh]);

  useEffect(() => {
    const updatedChallanRequestInfo = challanRequestInfo?.map((item) => ({
      ...item,
      isCell: true,
      cell: () => (
        <CellTooltip
          value={
            item?.field === challan_item_keys.CREATED_AT
              ? formatDateV2(item?.value)
              : item?.value
          }
          position="right"
        />
      ),
    }));
    setChallanRequestInfo(updatedChallanRequestInfo);
  }, [challanRequestDetail]);

  const RefreshData = () => {
    if (refresh) {
      setIsLoading(true);
      ChallanService.fetchFinderChallanDetailInfo(idChallanRequest)
        .then((res) => {
          const updatedList = finder_detail_list_keys?.map((item) => ({
            ...item,
            value: res?.detail?.[item?.field],
          }));
          setChallanRequestInfo(updatedList);
          setChallanRequestDetail(res?.detail);
          dispatch(updateFinderChallanRequest(res?.detail));
        })
        .catch((err) => {
          console.log({ err });
        })
        .finally(() => {
          ChallanService.fetchChallanItems({ idChallanRequest })
            .then((resp) => {
              const {
                detail = [],
                numberOfElements = 0,
                totalElements = 0,
              } = resp;
              setChallanRequestDetailItems({
                channelItems: detail,
                numberOfElements,
                totalElements,
              });
            })
            .catch((err) => {
              console.log({ err });
            })
            .finally(() => {
              setIsLoading(false);
              setRefresh(false);
            });
        });
    }
  };

  return (
    <div className="ops-challan-container">
      <div className="ops-grid-layout-container">
        {!!isLoading ? (
          <Loader />
        ) : (
          <GridLayout
            gap={{
              rows: "15px",
              cols: 10,
            }}
          >
            <GridLayoutItem row={1}>
              <LayoutStatusBanner
                label={`Appt. ID. #${challanRequestDetail?.appointmentOrderId}`}
                status={challanRequestDetail?.status}
                onBackClick={onBackClick}
              />
            </GridLayoutItem>

            <GridLayoutItem row={2}></GridLayoutItem>
            <GridLayoutItem row={3}>
              <DetailCardLayout
                headerTitle={"Challan Info"}
                itemPerRow={NUMBERS.FOUR}
                columnList={challanRequestInfo}
              />
            </GridLayoutItem>
            <GridLayoutItem row={4}>
              <ChallanItemGrid
                idChallanRequest={idChallanRequest}
                challanRequestDetail={challanRequestDetail}
                challanRequestDetailItems={challanRequestDetailItems}
                setRefresh={setRefresh}
              />
            </GridLayoutItem>
          </GridLayout>
        )}
      </div>
    </div>
  );
};

export default FinderDetailView;
