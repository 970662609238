import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useState } from "react";
import tabClasses from "../leads/styles/LeadsGrid.module.css";
import SingleUpload from "./containers/SingleUpload";
import BulkUpload from "./containers/BulkUpload";
import useMetadata from "../common/hooks/useMetadata";

const UploadLeads = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const { metadata } = useMetadata();

    const leadSources = Object.entries(metadata?.leadSource || {}).map(
        ([value, label]) => ({ label, value })
    );

    return (
        <div className="ops-grid-layout-container">
            <TabStrip
                className={tabClasses.sfLeadsTabs}
                selected={selectedTab}
                onSelect={(e) => setSelectedTab(e.selected)}
            >
                <TabStripTab title="Single Upload">
                    <SingleUpload leadSources={leadSources} />
                </TabStripTab>
                <TabStripTab title="Bulk Upload">
                    <BulkUpload />
                </TabStripTab>
            </TabStrip>
        </div>
    );
};

export default UploadLeads;
