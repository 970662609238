import { useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { unlockIcon, lockIcon } from "@progress/kendo-svg-icons";
import { Loader } from "@progress/kendo-react-indicators";
import { useSelector, useDispatch } from "react-redux";

import "./tab-unlock-ra-details.scss";
import { getRADetails } from "../../../store/actions/allCars";
import { trackAnalytics } from "../../services/analytics";
import { SegmentEvents } from "../../constants/events.constants";
import { getLoginUserEmail } from "../../../../../utils/utils";
import { EVENT_CAR_SOURCE } from "../../constants/table.constants";
import { callDealer, setCorrelationId, setOngoingCallStatus } from "../../../store/actions/myDealers";
import CallIcon from "../../assets/images/callIcon";
import { getCallEvent } from "../../utils/utils";

const TabUnlockRADetails = ({ carDetails, view, dealerId }) => {
	const [showRaDetails, setShowRaDetails] = useState(true);
	const { filters, userInfo } = useSelector((state) => state.allCars);
	const { isCallOngoing } = useSelector((state) => state.myDealers);
	const dispatch = useDispatch();

	let fields = [
		{
			label: "Name",
			value: ""
		}, {
			label: "Email",
			value: ""
		}, {
			label: "Contact No.",
			value: ""
		}
	];
	const [data, setData] = useState([...fields]);
	const email = getLoginUserEmail();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (showRaDetails) {
			fetchRaDetails();
		}
	}, [showRaDetails]);

	const handleCallDealer = (callingNumber) => {
		const userId = userInfo?.id;
		dispatch(callDealer({ dealerId, userId, callingNumber })).then((resp) => {
			getCallEvent(resp, EVENT_CAR_SOURCE.allCars)
			dispatch(setCorrelationId(resp?.clientCorrelationId));
			dispatch(setOngoingCallStatus(true));

		});
	};

	const fetchRaDetails = () => {
		if (carDetails?.appointmentDetails?.appointmentId) {
			getRADetails(carDetails?.appointmentDetails?.appointmentId, { userId: userInfo?.id }).then(res => {
				let details = res?.details;
				let newFields = [{
					label: "Name",
					value: details?.raName
				}, {
					label: "Email",
					value: details?.raEmail
				}, {
					label: "Contact No.",
					value: details?.raContactNumber ? <div className="ra-contact-grid">
						<span>{details.raContactNumber}</span>
						<Button
							className='contact-icon-button'
							onClick={() => handleCallDealer(details.raContactNumber)}
							disabled={isCallOngoing}
						>
							<CallIcon fill="#EF6E0B" />
						</Button>
					</div> : null
				}];
				setData([...newFields]);
				setIsLoading(false);
				trackAnalytics(SegmentEvents.Viz_clicked_unlock_ra_details, {
					email_id: email,
					dealer_id: dealerId || null,
					source: view ? EVENT_CAR_SOURCE[view] : EVENT_CAR_SOURCE.cars,
					auctionId: carDetails?.appointmentDetails?.carId,
					appointmentId: carDetails?.appointmentDetails?.appointmentId,
					auctionType: filters?.dealerCarCategory?.label,
					raEmployeeEmailId: details?.raEmail
				});
			}).catch(err => {
				setIsLoading(false);
			});
		}
	};

	return (
		<>
			<div className="tab-unlock-ra-details-container">
				<div className="tab-title-btn-block">
					<div className="tab-title">View RA Details</div>
					<Button
						className='app-warning-btn'
						size={'medium'}
						fillMode={'outline'}
						rounded={'medium'}
						svgIcon={showRaDetails ? unlockIcon : lockIcon}
						onClick={() => setShowRaDetails(!showRaDetails)}
					>
						{showRaDetails ? "Lock RA Details" : "Unlock RA Details"}
					</Button>
				</div>
			</div>
			{showRaDetails && (
				isLoading ? (
					<div className="loader-container">
						<Loader type="infinite-spinner" size="medium" />
					</div>
				) : (
					data && data.length > 0 && (
						data.map((item) => (
							<div className="plane-grid" key={item.label}>
								<div className="label">{item.label || '-'}</div>
								<div className="value">{item.value || '-'}</div>
							</div>
						))
					)
				)
			)}
		</>
	);
};

export default TabUnlockRADetails;
