import { TextBox } from '@progress/kendo-react-inputs'
import React from 'react'

import './call-logs.scss';


const SearchIcon = () => {
  return (<>
    <span className="k-icon k-font-icon k-i-search search-icon"></span>
  </>)
}

const SearchBar = ({ setSearch }) => {

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      setSearch(e.target.value.trim());
    }
  };

  return (
    <div className='text-box-container'>
      <TextBox onKeyDown={handleKeyDown} placeholder="Search By Dealer Number or Dealer Code" suffix={SearchIcon} />
    </div>
  )
}

export default SearchBar