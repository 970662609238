import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import React, { useEffect, useState } from 'react'
import styles from "../../operations/assets/style/ff-layout.module.css";
import { Button } from '@progress/kendo-react-buttons';
import CustomGrid from '../../../common/custom-grid/component';
import { VAS_PRICE_UPDATE_SCREEN, getDispalyedColumns, vasPriceUpdateColumns, vas_price_update_column_keys } from './viewModal';
import { RetailService, VasService } from '../../../services';
import { showToastMessage } from '../../../utils/utils';
import PriceDetail from './component/vas-price-detail';


const PriceUpdate = () => {

    const [data, setData] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState(vasPriceUpdateColumns);
    const [dataRow, setDataRow] = useState();
    const [screen, setScreen] = useState(VAS_PRICE_UPDATE_SCREEN.PRICE_LISTING);

    useEffect(() => {
        VasService.fetchPriceUpdateData().then((res) => {
            setData(res);
        }).catch((err) => showToastMessage(err?.message, false));

        setVisibleColumns((columns) => ({
            ...columns,
            [vas_price_update_column_keys.PRODUCT_UINIQUE_IDENTIFIER]: {
                ...columns[vas_price_update_column_keys.PRODUCT_UINIQUE_IDENTIFIER],
                cell: ApptIdCell,
            },
        }));
    }, [])

    const ApptIdCell = (props) => {
        const { dataItem } = props || {};
        return (
            <td
                title={dataItem[vas_price_update_column_keys.PRODUCT_UINIQUE_IDENTIFIER]}
                className="k-table-td "
            >
                {
                    <span
                        onClick={() => {
                            setDataRow(dataItem);
                            setScreen(VAS_PRICE_UPDATE_SCREEN.PRICE_DETAIL);
                        }
                        }
                        style={{
                            color: "#003E72",
                            textDecoration: "underline",
                            cursor: "pointer",
                            wordBreak: "break-word",
                            fontSize: "13px",
                        }}
                    >
                        {dataItem[vas_price_update_column_keys.PRODUCT_UINIQUE_IDENTIFIER]}
                    </span>
                }
            </td>
        );
    };

    return (
        <div className="ops-challan-container">
            <div className="ops-grid-layout-container">
                {
                    screen === VAS_PRICE_UPDATE_SCREEN.PRICE_DETAIL ?
                        <PriceDetail dataRow={dataRow} setScreen={setScreen} setData={setData} data={data} /> :
                        <GridLayout
                            gap={{
                                rows: "0px",
                                cols: 10,
                            }}>
                            <GridLayoutItem
                                row={1}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}>
                                <div className={styles.listing_header}>Price Update</div>
                                <Button themeColor={"primary"} onClick={() => {
                                    setDataRow({});
                                    setScreen(VAS_PRICE_UPDATE_SCREEN.PRICE_DETAIL);
                                }} className="m-1">
                                    Add
                                </Button>
                            </GridLayoutItem>
                            <GridLayoutItem row={2}>
                                <CustomGrid
                                    displayedColumns={getDispalyedColumns(vasPriceUpdateColumns)}
                                    columnMap={visibleColumns}
                                    data={data}
                                // count={count}
                                // selectedField={selectedField}
                                // idField={idField}
                                // onSelectionChange={onRowSelectionChange}
                                // onHeaderSelectionChange={onHeaderSelectionChange}
                                // selectable={selectable}
                                // isPagination={isPagination}
                                // pageChange={pageChange}
                                // paginationParams={paginationParams}
                                />
                            </GridLayoutItem>

                        </GridLayout>
                }
            </div>
        </div>
    )
}

export default PriceUpdate;
