export const ASSIGNED_LEAD_SUBTABS = {
    DIALER_LEADS: "DIALER_LEADS",
    CONTACT_INTERESTED: "CONTACT_INTERESTED",
    ALL_DOCS_COLLECTED: "ALL_DOCS_COLLECTED",
    CONTACT_NOT_INTERESTED: "CONTACT_NOT_INTERESTED",
};

export const SF_EVENT_CATEGORIES = {
    PRE_INTENT_API_CALL: "PRE_INTENT_API_CALL",
    POST_INTENT_API_CALL: "POST_INTENT_API_CALL",
    FORM_1_SUBMITTED: "FORM_1_SUBMITTED",
    FORM_2_SUBMITTED: "FORM_2_SUBMITTED",
    FORM_3_SUBMITTED: "FORM_3_SUBMITTED",
    DOCUMENT_UPLOAD_REQUESTED: "DOCUMENT_UPLOAD_REQUESTED",
    DOCUMENT_UPLOADED: "DOCUMENT_UPLOADED",
    CALL_INITIATION_SUCCESS: "CALL_INITIATION_SUCCESS",
    CALL_INITIATION_FAILED: "CALL_INITIATION_FAILED",
};

export const CALL_STATUS = {
    RINGING: "RINGING",
    CONNECTED: "CONNECTED",
    NOT_CONNECTED: "NOT_CONNECTED",
    AGENT_NOT_ACTIVE: "AGENT_NOT_ACTIVE",
};

export const CALL_ERROR_MESSAGES = {
    [CALL_STATUS.NOT_CONNECTED]:
        "Unable to reach customer. Please try again later.",
    [CALL_STATUS.AGENT_NOT_ACTIVE]: "Log in to Cube Dialer to make calls.",
};
