import FormulaItemChip from "../formula-item-chip";
const FormulaChip = ({ id, name, expression, onFormulaClick, placeholder }) => {
	const words = !!expression ? expression.split(/\s+/) : [];
	const filteredItems = words.filter((item) => !!item);
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "flex-start",
				flexWrap: "wrap",
				border: "1px solid #C3C3C3",
				borderRadius: "4px",
				padding: "5px",
				minHeight: "80px",
				height: "auto",
			}}
			onClick={onFormulaClick}>
			{filteredItems?.length > 0 ? (
				filteredItems?.map((item, index) => {
					return <FormulaItemChip label={item} />;
				})
			) : (
				<span
					name={id}
					id={id}
					style={{
						width: "100%",
						height: "65px",
						color: "#888",
						opacity: "0.5",
					}}
					// onClick={onFormulaClick}
				>
					{placeholder}{" "}
				</span>
			)}
		</div>
	);
};
export default FormulaChip;
