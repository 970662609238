import React from 'react';
import styles from './label.module.css';

function LabelComponent({ title, length }) {
    return (
        <div className={styles.label}>
            {title} {length && <span>({length} Characters)</span>}
        </div>
    )
}

export default LabelComponent