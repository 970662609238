import React, { useState, useEffect, useCallback } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import styles from "../assets/style/inspection.module.css";
import { FormSearchInput } from "../../../components";
import { Button } from "@progress/kendo-react-buttons";
import IncentiveDetail from "./incentive-detail";
import {
	cj_incentive_columns,
	cj_incentive_keys,
	getDisplayedCoulmns,
	CJ_INCENTIVE_INITIAL_STATE,
	DD_DATA_SOURCES,
} from "./view-modal";
import CustomGrid from "../../../common/custom-grid/component";
import { ColumnMenuCheckboxFilter } from "../../../components/custom-grid-filters";
import {
	INITIAL_PAGINATION_PARAMS,
	SORT_DIRECTION_KEYS,
} from "../../../utils/constants/values.constants";
import { CjIncentivesService } from "../../../services";
import {
	isObjectEmpty,
	convertObjectToParams,
	isFilterFieldPresent,
	getArrayItemValueByLabel,
} from "../../../utils/utils";
const initialSort = [
	{
		field: cj_incentive_keys.VALIDITY,
		dir: SORT_DIRECTION_KEYS.DESC,
	},
];
const initialFilter = {
	filters: [],
	logic: "and",
};
const ALLOWED_SORT_PARAMS = [cj_incentive_keys.VALIDITY];
const CjIncentives = (props) => {
	let loadKey = "";
	const [visibleColumns, setVisibleColumns] = useState(cj_incentive_columns);
	const [cjIncentives, setCjIncentives] = useState(CJ_INCENTIVE_INITIAL_STATE);
	const [incentive, setIncentive] = useState();
	const [paginationParams, setPaginationParams] = useState(
		INITIAL_PAGINATION_PARAMS
	);
	const [sources, setSources] = useState(DD_DATA_SOURCES);
	const [sortParms, setSortParms] = useState(initialSort);
	const [filterParams, setFilterParams] = useState({});
	const [gridFilters, setGridFilters] = useState(initialFilter);
	const { isLoading, data, searchQuery, totalCount, error, filters } =
		cjIncentives;

	useEffect(() => {
		setVisibleColumns((columns) => ({
			...columns,
			[cj_incentive_keys.NAME]: {
				...columns[cj_incentive_keys.NAME],
				cell: IncentiveNameCell,
			},
		}));
	}, []);

	useEffect(() => {
		setVisibleColumns((columns) => ({
			...columns,
			[cj_incentive_keys.STATUS]: {
				...columns[cj_incentive_keys.STATUS],
				headerClassName: isFilterFieldPresent(
					gridFilters?.filters,
					cj_incentive_keys.STATUS
				)
					? "selected-filter"
					: "de-selected-filter",
				columnMenu: (props) => {
					return (
						<ColumnMenuCheckboxFilter
							{...props}
							filterData={sources}
							onFilterButtonClick={handleFilterButtonClick}
							columnMapKey={cj_incentive_keys.STATUS}
							filter={gridFilters}
						/>
					);
				},
			},
		}));
	}, [gridFilters]);
	useEffect(() => {
		if (!isObjectEmpty(filterParams)) {
			fetchIncentives("filterParams");
		}
	}, [filterParams]);

	useEffect(() => {
		if (!isObjectEmpty(paginationParams)) {
			fetchIncentives("paginationParams");
		}
	}, [paginationParams]);

	const handleFilterButtonClick = React.useCallback(
		(key, e, data) => {
			if (!!e) {
				setGridFilters(e);
				let _filterParamsData = { ...filterParams };

				e?.filters.map((item) => {
					const updatedFilter = item?.filters.reduce((acc, cur) => {
						const existingItem = acc?.find(
							(transformedItem) => transformedItem[cur.field]
						);
						const itemValue = getArrayItemValueByLabel(
							data[cur.field],
							cur.value
						);
						if (existingItem) {
							existingItem[cur.field] += `,${itemValue}`;
						} else {
							acc.push({ [cur.field]: itemValue });
						}
						return acc;
					}, []);

					const filterParamsData = updatedFilter.reduce(
						(result, obj) => ({ ...result, ...obj }),
						{}
					);
					_filterParamsData = { ..._filterParamsData, ...filterParamsData };
				});
				setFilterParams(_filterParamsData);
			}
		},
		[filterParams]
	);
	const IncentiveNameCell = (props = {}) => {
		const { dataItem } = props;
		return (
			<td title={dataItem[cj_incentive_keys.NAME]} className="k-table-td ">
				{
					<span
						onClick={() => setIncentive(dataItem)}
						style={{
							color: "#003E72",
							textDecoration: "underline",
							cursor: "pointer",
							wordBreak: "break-word",
							fontSize: "13px",
						}}>
						{dataItem[cj_incentive_keys.NAME]}
					</span>
				}
			</td>
		);
	};
	const onIncentiveOpen = (rowData = { id: "new" }) => {
		setIncentive(rowData);
	};

	const onIncentiveClose = (rowData = { id: "new" }) => {
		fetchIncentives()
		setIncentive();
	};

	const pageChange = ({
		page = INITIAL_PAGINATION_PARAMS.pageNumber,
		size = INITIAL_PAGINATION_PARAMS.pageSize,
	}) => {
		setPaginationParams({ pageNumber: page, pageSize: size });
	};

	const sortChange = (e) => {
		let sortData = !!e?.sort.length ? e?.sort : initialSort;
		let sortKey = sortData[0]?.field;
		let sortDir = sortData[0]?.dir;
		if (!e?.sort?.length) {
			sortDir =
				sortParms[0].dir === SORT_DIRECTION_KEYS.DESC
					? SORT_DIRECTION_KEYS.ASC
					: SORT_DIRECTION_KEYS.DESC;
		}
		if (ALLOWED_SORT_PARAMS.includes(sortKey)) {
			const params = [
				{
					field: sortKey,
					dir: sortDir,
				},
			];
			setSortParms([{ field: sortData[0].field, dir: sortDir }]);
			fetchIncentives("sort", params);
		}
	};

	const fetchIncentives = (key, sort = []) => {
		if (!loadKey && !isLoading) {
			loadKey = key;
			const params = { ...paginationParams, ...filterParams };
			let qsParams = convertObjectToParams(params);
			if (!!sort.length) {
				qsParams = `${qsParams}&sortBy=${sort[0].field}&sortDir=${sort[0].dir}`;
			}
			setCjIncentives((prev) => ({ ...prev, isLoading: true }));
			CjIncentivesService.fetchAllIncentives(qsParams)
				.then((resp) => {
					const {
						content = [],
						numberOfElements = 0,
						totalElements = 0,
					} = resp?.detail;
					setCjIncentives((prev) => ({
						...prev,
						data: content,
						numberOfElements,
						totalCount: totalElements,
					}));
				})
				.catch((err) => {
					console.log({ err });
				})
				.finally(() => {
					setCjIncentives((prev) => ({ ...prev, isLoading: false }));
				});
		}
	};
	return (
		<div className="ops-challan-container">
			<div className="ops-grid-layout-container">
				{!!incentive ? (
					<IncentiveDetail
						rowItem={incentive}
						onBackHandler={onIncentiveClose}
					/>
				) : (
					<GridLayout
						gap={{
							rows: "0px",
							cols: 10,
						}}>
						<GridLayoutItem
							row={1}
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}>
							<div className={styles.listingHeader}>CJ Incentives</div>
							<div className={styles.search}>
								<FormSearchInput
									value={""}
									placeholder={`Search`}
									handleChange={() => {}}
									iconName={"search"}
									handleInputSuffixAction={() => {}}
									handleClearSearch={() => {}}
								/>
								<Button
									className="mx-2"
									themeColor={"primary"}
									onClick={onIncentiveOpen}>
									Create
								</Button>
							</div>
						</GridLayoutItem>
						<GridLayoutItem row={3}>
							<CustomGrid
								displayedColumns={getDisplayedCoulmns(visibleColumns)}
								data={data}
								count={totalCount}
								columnMap={visibleColumns}
								isPagination={true}
								paginationParams={paginationParams}
								pageChange={pageChange}
								sortable={true}
								sortParms={sortParms}
							/>
						</GridLayoutItem>
					</GridLayout>
				)}
			</div>
		</div>
	);
};
export default CjIncentives;
