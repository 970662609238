import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import CreateReplicaForm from "../replica-form";
import { RetailService } from "../../../../services";
import { showToastMessage } from "../../../../utils/utils";
const LeadMonActions = (props) => {
  const [openReplicaDialog, setOpenReplicaDialog] = useState(false);
  const [data, setData] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const history = useHistory();
  const onCreateLeadMon = () => {
    history.push("/retail/leadmon/add");
  };
  const onCreateReplica = () => {
    if (!data.sourceCityId) {
      showToastMessage("Please souce city", false);
      return;
    } else if (!data.sourceLocationId) {
      showToastMessage("Please souce store", false);
      return;
    } else if (!data.destinationCityId) {
      showToastMessage("Please target city", false);
      return;
    } else if (!data.destinationLocationId) {
      showToastMessage("Please target store", false);
      return;
    }
    setIsSubmit(true);
    RetailService.replicaLeadMonConfig(data)
      .then((resp) => {
        showToastMessage(resp?.message || "Success");
        setOpenReplicaDialog(false);
      })
      .catch((err) => {
        showToastMessage(err?.message || "Failed", false);
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };
  return (
    <>
      <div className="ops-challan-actions">
        <div className="k-form-buttons">
          {!!props.isDetail && (
            <Button
              themeColor={"primary"}
              className="m-2"
              onClick={props.goBack}
            >
              Back
            </Button>
          )}
        </div>
        <div className="k-form-buttons">
          <Button
            className="m-2"
            themeColor={"primary"}
            onClick={() => setOpenReplicaDialog(true)}
          >
            Create Replica
          </Button>
          <Button
            className="m-2"
            themeColor={"primary"}
            onClick={onCreateLeadMon}
            disabled={isSubmit}
          >
            Add Config
          </Button>
        </div>
        {openReplicaDialog && (
          <Dialog
            title={"Create Replica config"}
            onClose={() => {
              setOpenReplicaDialog(false);
            }}
            width={450}
            height={300}
          >
            <CreateReplicaForm setData={setData} />
            <DialogActionsBar>
              <Button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={onCreateReplica}
                themeColor={"primary"}
              >
                Confirm
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    </>
  );
};
export default LeadMonActions;
