export const getItemCreatedBy = (item) => {
  if (!item) {
    return "";
  }

  if (item.createdBy && item.createdBy !== "null") {
    return item.createdBy;
  }

  return item.source ? item.source : "";
};
