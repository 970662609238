export const OPS_CHALLAN = {
	FETCH_ORDERS_START: "FETCH_ORDERS_START",
	FETCH_ORDERS_END: "FETCH_ORDERS_END",
	FETCH_ORDERS_SUCCESS: "FETCH_CHALLAN_ORDERS_SUCCESS",
	FETCH_ORDERS_FAILURE: "FETCH_CHALLAN_ORDERS_FAILURE",
	SET_PAGINATION_PARAMS: "SET_PAGINATION_PARAMS",
	SET_CHALLAN_SOURCE: "SET_CHALLAN_SOURCE",
	SET_ASSIGNMENT_TYPE: "SET_ASSIGNMENT_TYPE",
	UPDATE_REQUEST_ORDERS: "UPDATE_ROW_CHANNEL_REQUESTS",
	FETCH_CHALLAN_ITEMS_SUCCESS: "FETCH_CHALLAN_ITEMS_SUCCESS",
	FETCH_CHALLAN_ITEMS_FAILURE: "FETCH_CHALLAN_ITEMS_FAILURE",
	UPDATE_ROW_CHALLAN_ITEM: "UPDATE_ROW_CHALLAN_ITEM",
	FETCH_DISPOSE_CHALLAN_ITEMS_SUCCESS: "FETCH_DISPOSE_CHALLAN_ITEMS_SUCCESS",
	FETCH_DISPOSE_CHALLAN_ITEMS_FAILURE: "FETCH_DISPOSE_CHALLAN_ITEMS_FAILURE",
	SET_CHALLAN_ITEM_SOURCE: "SET_CHALLAN_ITEM_SOURCE",
	SET_CHALLAN_ITEM_ASSIGNMENT_TYPE: "SET_CHALLAN_ITEM_ASSIGNMENT_TYPE",
	SET_CHALLAN_REQUEST_SEARCH_QUERY: "SET_CHALLAN_REQUEST_SEARCH_QUERY",
	SET_DISPOSE_FILTERS: "SET_DISPOSE_FILTERS",
	SET_DISPOSE_SEARCH_QUERY: "SET_DISPOSE_SEARCH_QUERY",
	SET_FINDER_FILTERS: "SET_FINDER_FILTERS",
	SET_CHALLAN_ITEM_DELAYED_TYPE: "SET_CHALLAN_ITEM_DELAYED_TYPE",
	RESET_DISPOSE_CHALLAN_ITEMS: "RESET_DISPOSE_CHALLAN_ITEMS",
	REFRESH_DISPOSE_CHALLAN_ITEM: "REFRESH_DISPOSE_CHALLAN_ITEM",
	REFRESH_FINDER_CHALLAN_REQUEST: "REFRESH_FINDER_CHALLAN_REQUEST",
};
