import { useState } from "react";
import { useHistory } from "react-router-dom";
import callIcon from "../../../../assets/img/callIcon.svg";
import {
    getLoginUserEmail,
    showToastMessage,
    yieldToMain,
} from "../../../../utils/utils";
import { Dialog } from "@progress/kendo-react-dialogs";
import { SFService } from "../../../../services";
import sfEventParser from "../../../../tracking/parser/seller-finance";
import phoneIcon from "../../../../assets/img/phoneIcon.svg";
import classes from "../styles/callButton.module.css";
import {
    CALL_ERROR_MESSAGES,
    CALL_STATUS,
    SF_EVENT_CATEGORIES,
} from "../../common/constants";
import { sendEvent } from "../../../../tracking";
import usePollingEffect from "../../common/hooks/usePollingEffect";

const CallButton = ({ leadDetails, subTabKey }) => {
    const [showCallModal, setShowCallModal] = useState(false);
    const [modalText, setModalText] = useState(
        "Please wait, We are transferring the call"
    );

    const history = useHistory();

    const fetchCallStatus = async () => {
        const response = await SFService.getCallStatus(leadDetails?.leadId);

        const { detail = {}, message } = response;

        const { callStatus = "" } = detail || {};

        if (callStatus !== CALL_STATUS.RINGING) {
            handleCloseModal();
            if (callStatus === CALL_STATUS.CONNECTED) {
                showToastMessage("Call connected successfully");
                // history.push(`/seller-finance/leads/${leadDetails?.leadId}`, {
                //     subTabKey,
                // });
            } else {
                showToastMessage(
                    CALL_ERROR_MESSAGES[callStatus] ||
                        message ||
                        "Something went wrong",
                    false
                );
            }
        }

        return {
            stopPolling: callStatus !== CALL_STATUS.RINGING,
        };
    };

    const [isPolling, startPolling] = usePollingEffect(fetchCallStatus, [], {
        interval: 3000,
        duration: 30000,
        onDurationExpire: () => {
            showToastMessage(
                CALL_ERROR_MESSAGES[CALL_STATUS.NOT_CONNECTED],
                false
            );
            handleCloseModal();
        },
        onError: (error) => {
            console.log(error);
            showToastMessage(
                error?.message || "Failed to get call status",
                true
            );
        },
    });

    const handleInitiateCallLead = async () => {
        let callEventPayload = null;
        try {
            setShowCallModal(true);

            const body = {
                leadId: leadDetails?.leadId,
                customerName: leadDetails?.name,
                agentId: getLoginUserEmail(),
            };
            const response = await SFService.callLead(body);
            const { detail = "" } = response || {};

            if (detail === "success") {
                callEventPayload = sfEventParser({
                    action: SF_EVENT_CATEGORIES.CALL_INITIATION_SUCCESS,
                    token: leadDetails?.leadId,
                });
                setModalText(
                    `Please wait, We are calling ${
                        leadDetails?.name || "the customer"
                    }`
                );
                startPolling();
            } else {
                showToastMessage("Unable to transfer call", false);
                handleCloseModal();
                callEventPayload = sfEventParser({
                    action: SF_EVENT_CATEGORIES.CALL_INITIATION_FAILED,
                    token: leadDetails?.leadId,
                });
            }
        } catch (error) {
            console.log(error);
            showToastMessage("Unable to transfer call", false);
            handleCloseModal();
            callEventPayload = sfEventParser({
                action: SF_EVENT_CATEGORIES.CALL_INITIATION_FAILED,
                token: leadDetails?.leadId,
            });
        } finally {
            if (callEventPayload) {
                await yieldToMain();
                sendEvent(callEventPayload);
            }
        }
    };

    const handleCloseModal = () => {
        setShowCallModal(false);
        setModalText("Please wait, We are transferring the call");
    };

    return (
        <>
            <img
                role="button"
                src={callIcon}
                alt="Call"
                className="k-ml-md"
                onClick={handleInitiateCallLead}
            />

            {showCallModal && (
                <Dialog
                    onClose={handleCloseModal}
                    className={classes.callModal}
                >
                    <div className={classes.pulse}>
                        <img src={phoneIcon} alt="phone" />
                    </div>
                    <p className={classes.callText}>{modalText || ""}</p>
                </Dialog>
            )}
        </>
    );
};

export default CallButton;
