import { getBaseUrl } from "../utils/constants/url.constants";
import { getFFUserType, getLoginUserEmail, getValuesString } from "../utils/utils";
import apiInterceptor from "./apiInterceptor";

const lmsRetailService = (api) => {
  const BASE_ENDPOINT = 'purchase/api/v1/cc-ops';

  const getLeads = (payload) => {
    const { page, size, filter, sortParams } = payload || { page: 0, size: 10, filter: {}, sortParams: {} }
    let filterPayload = '';
    filter?.forEach(group => {
      if (group?.filters) {
        let field = ''
        let value = ''
        group.filters.forEach(filter => {
          field = filter.field
          if(!value){
            value = filter.value
          }
          else{
            value = value + ',' + filter.value
          }
        });
        filterPayload += `&${field == 'raEmail' ? 'userIds' : field}=${value}`;
      }
    });
    let sortPayload = ''
    sortParams?.map((item) => {
      if (item?.field == "intentDate") {
        sortPayload = sortPayload + `&sortField=daysSinceIntent&sortOrder=${item?.dir === 'asc' ? 'ASC' : 'DESC'}`
      }
    })

    const endpoint = `${BASE_ENDPOINT}/leads?pageNumber=${page + 1}&pageSize=${size}${filterPayload}${sortPayload}`;

    return api.get(endpoint, {
      headers: {
        "user-type": `${getFFUserType()}`,
        "user-email": getLoginUserEmail(),
      },
    })
  };

  return {
    getLeads
  };
};

export const lmsRetailApi = lmsRetailService(apiInterceptor(getBaseUrl()));

