import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import styles from "../../../negotiation/assets/styles/pr.styles.module.css";

export const BaGrid = ({ updatedBusinessApproval, visibleColumns }) => {
  return (
    <Grid data={updatedBusinessApproval || []} className={`${styles.gridContent} ${styles.businessApproval_grid}`}>
      <GridNoRecords>
        <div className="align-center k-justify-content-center">
          <span
            className="k-icon k-i-check-circle k-mr-2 "
            style={{ color: "green" }}
          ></span>
          <span>No Business approval required!</span>
        </div>
      </GridNoRecords>
      {visibleColumns &&
        Object.keys(visibleColumns)?.map((columnName) => {
          const column = visibleColumns[columnName];
          return (
            <GridColumn
              key={columnName}
              field={column["field"]}
              title={column["label"]}
              cell={column["cell"]}
            />
          );
        })}
    </Grid>
  );
};
