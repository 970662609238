import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PrAutomationService } from "../../../../../services";
import { PR_AUTOMATION } from "../../../../../store/types";
import { showToastMessage } from "../../../../../utils/utils";

const useAuditChecks = (props) => {
  const { setIsAuditLoader, setIsAuditChecks } = props || {};
  const dispatch = useDispatch();
  const lms = useSelector((state) => state.lms);
  const { appointmentId } = lms || {};
  const [retryCount, setRetryCount] = useState(0);

  const fetchAuditData = async () => {
    PrAutomationService.getAuditCheckDetails(appointmentId)
      .then((res) => {
        if (res && res.status === 200) {
          if (res?.detail?.auditCheckStatus === "COMPLETED") {
            dispatch({
              type: PR_AUTOMATION.FETCH_AUDIT_CHECKS_SUCCESS,
              payload: {
                data: res?.detail,
              },
            });
            setIsAuditLoader(false);
            setIsAuditChecks(true);
          } else {
            initiateRetryLogic();
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: PR_AUTOMATION.FETCH_AUDIT_CHECKS_FAILURE,
          payload: error,
        });
        setIsAuditLoader(false);
        setIsAuditChecks(true);
        showToastMessage(
          error?.message || error?.error || "Error running Audit Checks",
          false
        );
      });
  };
  const initiateRetryLogic = async () => {
    const intervalId = setInterval(() => {
      if (retryCount < 5) {
        setRetryCount(retryCount + 1);
        fetchAuditData(); // Retry the API call
      } else {
        clearInterval(intervalId); // Stop retrying after 4 attempts
        dispatch({
          type: PR_AUTOMATION.FETCH_AUDIT_CHECKS_FAILURE,
          payload: "error",
        });
        setIsAuditLoader(false);
        setIsAuditChecks(true);
      }
    }, 4000); // Retry every 4 seconds

    // Clean up interval on unmount or when desired response is received
    return () => clearInterval(intervalId);
  };
  const handleInitiateAudit = async () => {
    dispatch({ type: PR_AUTOMATION.FETCH_PR_SUBMIT_LOADING_START });
    PrAutomationService.initiateAudit(appointmentId)
      .then((res) => {
        if (res && res.status === 200) {
          setIsAuditLoader(true);
          fetchAuditData();
        }
      })
      .catch((error) => {
        showToastMessage(error?.message || error?.error || "Error", false);
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_PR_SUBMIT_LOADING_END });
      });
  };
  return [handleInitiateAudit];
};
export default useAuditChecks;
