import * as React from "react";
import { FormClearInput, FormComboBox } from "..";
import { NUMBERS } from "../../utils/constants/values.constants";
import { GridColumnMenuCheckboxFilter } from "@progress/kendo-react-grid";
import { StackLayout } from "@progress/kendo-react-layout";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import classes from "./style.module.css";

export const DropdownFilter = (props) => {
    let hasValue = (value) => Boolean(value && value !== props.defaultItem);
    const onChange = (event) => {
        const { value } = event;
        hasValue = hasValue(value?.value);
        props.onChange({
            value: hasValue ? value?.value : "",
            operator: hasValue ? "eq" : "",
            syntheticEvent: event.syntheticEvent,
        });
        props.onFilterButtonClick({ key: props.field, value: value?.value });
    };
    return (
        <div className="k-filtercell">
            <FormComboBox
                id={props.field}
                key={props.field}
                onChange={onChange}
                data={props.data}
                textField="label"
                dataItemKey="value"
                value={
                    props.data?.find((item) => item.value === props.value) ||
                    null
                }
                wrapperStyle={{ marginBottom: "0px" }}
                label={props?.label}
            />
        </div>
    );
};

export const TextboxFilter = (props) => {
    const {
        value,
        onChange,
        placeholder = "",
        field,
        onClearClick,
        handleFilterButtonClick,
    } = props;
    let hasValue = (value) => Boolean(value && value !== "");

    const handleChange = (event) => {
        const { value } = event;
        hasValue = hasValue(value);
        onChange({
            value: hasValue ? value : "",
            operator: hasValue ? "eq" : "",
            syntheticEvent: event.syntheticEvent,
        });
    };
    const handleClear = (event) => {
        onChange({
            value: "",
            operator: "",
            syntheticEvent: event.syntheticEvent,
        });
        onClearClick();
    };
    const element = document.getElementById(field);

    React.useEffect(() => {
        element?.addEventListener("keydown", handleFilter, false);
        return () => {
            element?.removeEventListener("keydown", handleFilter, false);
        };
    });
    const handleFilter = (e) => {
        if (e?.keyCode === NUMBERS.THIRTEEN) {
            handleFilterButtonClick();
        }
    };
    return (
        <FormClearInput
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            id={field}
            handleClear={handleClear}
        />
    );
};

export const ColumnMenuCheckboxFilter = (props) => {
    const { filterData, columnMapKey, filter, userAssignments } = props || {};
    const updatedFilterData =
        filterData[columnMapKey]?.map((item) => ({
            [columnMapKey]: item?.label,
        })) || [];
    return (
        <div>
            <GridColumnMenuCheckboxFilter
                {...props}
                data={updatedFilterData}
                expanded={true}
                onFilterChange={(e) =>
                    props.onFilterButtonClick(
                        columnMapKey,
                        e,
                        filterData,
                        userAssignments
                    )
                }
                filter={filter}
            />
        </div>
    );
};

export const ColumnMenuDateRangeFilter = (props) => {
    const { onFilterChange, columnMapKey, filter } = props;

    const [dateRange, setDateRange] = React.useState({
        start: filter?.start,
        end: filter?.end,
    });

    const handleChangeDateRange = (e) => {
        const { setShow } = e?.target || {};
        const {
            value: { start, end },
        } = e;
        setDateRange({ start, end });
        if (start && end) {
            setShow?.(false);
        }
    };

    const handleSubmitFilter = () => {
        const { start, end } = dateRange;
        if (!start || !end) {
            return;
        }

        onFilterChange?.(columnMapKey, { start, end });
        props?.onCloseMenu?.();
    };

    const handleClearFilter = () => {
        onFilterChange?.(columnMapKey, {
            start: null,
            end: null,
        });
        props?.onCloseMenu?.();
    };

    return (
        <div className={classes.filterContainer}>
            <DateRangePicker
                value={{
                    start: dateRange.start ? new Date(dateRange.start) : null,
                    end: dateRange.end ? new Date(dateRange.end) : null,
                }}
                onChange={handleChangeDateRange}
                format="dd-MM-yyyy"
            />
            <StackLayout className="mt-2" gap={6}>
                <Button
                    className={classes.submitBtn}
                    onClick={handleSubmitFilter}
                >
                    Filter
                </Button>
                <Button
                    className={classes.cancelBtn}
                    onClick={handleClearFilter}
                >
                    Clear Filter
                </Button>
            </StackLayout>
        </div>
    );
};
