function SuccessIcon(props) {
  return (
    <svg
      width={14}
      height={15}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 7.5a7 7 0 1114 0 7 7 0 01-14 0zm1 0c0 3.313 2.688 6 6 6 3.313 0 6-2.688 6-6 0-3.313-2.688-6-6-6-3.313 0-6 2.688-6 6z"
        fill={props.fill ? props.fill : "#37B400"}
      />
      <path
        d="M6 8.688L3.406 6.094 2 7.5l4 4L11.5 6l-1.406-1.406L6 8.687z"
        fill={props.fill ? props.fill : "#37B400"}
      />
    </svg>
  )
}

export default SuccessIcon
