import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Error, Hint } from '@progress/kendo-react-labels';
import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import AppChips from '../../components/app-chips';
import styles from './styles.module.css';
import { createSegment, refreshSegmentationData, updateSegment } from '../../../store/actions/segmentation';
import { getLoginUserEmail } from '../../../../../utils/utils';
import { AUCTION_TYPE } from '../../../common/constants/segmentation.constants';

const SegmentationForm = ({ setIsModalOpen, initialValues = {}, isEdit = false }) => {

  const [entityCodes, setEntityCodes] = useState(
    (initialValues?.entityIds || []).map(code => ({ entityCode: code, isRemove: false }))
  );

  const [error, setError] = useState('');
  const [actionType, setActionType] = useState(null);
  const parentDivRef = useRef(null);
  const dispatch = useDispatch();
  const arrayRef = useRef(0);
  const email = getLoginUserEmail();

  const handleEntityInputChange = (value, fieldRenderProps) => {
    fieldRenderProps.onChange(value);
  };

  const dropdownData = [{ name: "STATIC" }];

  const handleKeyDown = (event, fieldRenderProps) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (isEdit && actionType === "remove") {
        setError('Cannot add entities while removal is allowed.');
        return;
      }

      let value = event.target.value;
      const newCodes = value
        ?.split(',')
        .map((code) => code.trim())
        .filter((code) => code && !entityCodes.some(entity => entity.entityCode === code))
        .map(code => ({ entityCode: code, isRemove: false }));

      arrayRef.current = arrayRef.current + newCodes.length;

      const updatedCodes = [...entityCodes, ...newCodes];

      if (isEdit) setActionType(AUCTION_TYPE.ADD);
      setEntityCodes(updatedCodes);
      setError('');
      fieldRenderProps.onChange(updatedCodes);
    }

  };
  const handleRemoveEntity = (code, isRemoved = true, fieldRenderProps) => {

    const updatedCodes = entityCodes.map((item) => item.entityCode === code ? { ...item, isRemove: isRemoved } : item);

    if (isEdit && actionType === AUCTION_TYPE.ADD) {
      setError('Cannot remove entities while addition is allowed.');
      return;
    }


    if (isEdit) setActionType(AUCTION_TYPE.REMOVE);

    setEntityCodes(updatedCodes);
    setError('');

    fieldRenderProps.onChange(updatedCodes);
  };

  const handleClearAllEntity = (e, formRenderProps) => {
    if (isEdit) {
      const updatedCodes = entityCodes.map((item) => ({ ...item, isRemove: true }));
      setEntityCodes(updatedCodes);
      setActionType(AUCTION_TYPE.CLEAR);
      formRenderProps.onChange(updatedCodes);
    } else {
      setEntityCodes([]);
      formRenderProps.onChange([]);
    }
  };

  const handleSubmit = (dataItem) => {
    const isRemoved = isEdit && (actionType === AUCTION_TYPE.CLEAR || actionType === AUCTION_TYPE.REMOVE);

    const entityIds = isRemoved
      ? entityCodes.filter(item => item.isRemove).map(item => item.entityCode)
      : entityCodes.filter(item => !item.isRemove).map(item => item.entityCode)

    const payload = {
      ...dataItem,
      entityIds,
      createdBy: email,
      ...(isEdit && { segmentId: initialValues.segmentId })
    };

    const action = isEdit ? updateSegment : createSegment;

    dispatch(action(payload, isRemoved)).then(() => {
      dispatch(refreshSegmentationData(true));
    });

    if (isEdit) {
      setActionType(null);
    }
    setIsModalOpen(false);
  };

  const requiredValidator = (value) => (value ? "" : "This field is required");

  const CustomInput = (fieldRenderProps) => {
    const { validationMessage, touched, valid, ...others } = fieldRenderProps;
    return (
      <>
        <Input valid={valid} {...others} onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }} />
        {touched && validationMessage && <Error>{validationMessage}</Error>}
      </>
    );
  };

  const CustomChips = (fieldRenderProps) => {
    return (
      <div className={styles["form-wrapper"]}>
        <label className={`${styles['form-label']}`} htmlFor='entity'>Entity</label>
        <Input
          type='text'
          id="entity"
          value={fieldRenderProps.value}
          onChange={(e) => handleEntityInputChange(e.target.value, fieldRenderProps)}
          onKeyDown={(e) => handleKeyDown(e, fieldRenderProps)}
        />
        <div className={styles["entity-hint"]}>
          <div>
            <Hint id="entity">(Entity must be comma separated.)</Hint>
            {error && <Error>{error}</Error>}
          </div>
          {entityCodes.length > 0 ? (
            <Button type='button' fillMode="link" className={styles['clear-entity-btn']} onClick={(e) => handleClearAllEntity(e, fieldRenderProps)}>Clear all data</Button>
          ) : null}
        </div>
        <div className={styles.chipContainer}>
          <AppChips isEdit={isEdit} newCodes={arrayRef.current} remove={(code, isRemoved) => handleRemoveEntity(code, isRemoved, fieldRenderProps)} data={entityCodes} ref={arrayRef} />
        </div>
      </div>
    );
  };

  const CustomDropDown = (fieldRenderProps) => {
    const { validationMessage, touched, value, ...others } = fieldRenderProps;
    const selectedValue = dropdownData.findIndex(item => item.name === value);
    return (
      <div ref={parentDivRef} className={styles["form-wrapper"]} >
        <label className={`${styles['form-label']} ${styles['form-label-required']}`} htmlFor='type'>Type</label>
        <DropDownList
          popupSettings={{
            appendTo: parentDivRef.current
          }}
          {...others}
          value={dropdownData[selectedValue]}
          data={dropdownData}
          textField="name"
        />
        {touched && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  };

  return (
    <Form
      initialValues={{
        name: initialValues.name || "",
        description: initialValues.description || "",
        type: initialValues.type || dropdownData[0].name,
      }}
      onSubmit={handleSubmit}
      render={(formRenderProps) => (
        <FormElement className={styles.form}>
          <div className={styles["form-wrapper"]}>
            <label className={`${styles['form-label']} ${styles['form-label-required']}`} htmlFor='name'>Name</label>
            <Field id="name" name="name" component={CustomInput} validator={requiredValidator} />
            {formRenderProps.touched.name && <Error>{formRenderProps.errors.name}</Error>}
          </div>
          <div className={styles["form-wrapper"]}>
            <label className={`${styles['form-label']} ${styles['form-label-required']}`} htmlFor='description'>Description</label>
            <Field id="description" name="description" component={CustomInput} validator={requiredValidator} />
            {(formRenderProps.touched.description && formRenderProps.errors.description) && <Error>{formRenderProps.errors.description}</Error>}
          </div>
          <Field name="type" component={CustomDropDown} validator={requiredValidator} />
          <Field name="entityIds" component={CustomChips} value={entityCodes} />
          <div className={styles['form-button']}>
            <Button fillMode="link" onClick={() => setIsModalOpen(false)}>Cancel</Button>
            <Button type='submit' fillMode="solid" className={styles.submitButton} disabled={!formRenderProps.modified}>{isEdit ? "Update" : "Submit"}</Button>
          </div>
        </FormElement>
      )}
    />
  );
};
export default SegmentationForm;