export const SERVICE_HISTORY = {
	FETCH_LOADING_START: "FETCH_LOADING_START",
	FETCH_LOADING_END: "FETCH_LOADING_END",
	FETCH_SERVICE_LIST_SUCCESS: "FETCH_SERVICE_LIST_SUCCESS",
	FETCH_SERVICE_LIST_FAILURE: "FETCH_SERVICE_LIST_FAILURE",
	SET_ASSIGNMENT_TYPE: "SET_ASSIGNMENT_TYPE",
	SET_SEARCH_QUERY: "SET_SEARCH_QUERY",
	SET_SERVICEHISTORY_FILTERS: "SET_SERVICEHISTORY_FILTERS",
	UPDATE_SERVICE_LIST_ITEM: "UPDATE_SERVICE_LIST_ITEM",
	RESET_SERVICE_LIST_ITEMS: "RESET_SERVICE_LIST_ITEMS",
};
