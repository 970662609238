import { LMS } from "../types";
import { LmsService } from "../../services";
import { showToastMessage } from "../../utils/utils";

export const validateAppointmentId =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: LMS.FETCH_LOADING_START });
    LmsService.validateAppointmentId(params)
      .then((resp) => {
        if (resp && resp.detail && !resp.detail.appointmentId) {
          showToastMessage("Appointment Id not available", false);
        }
        dispatch({
          type: LMS.FETCH_VALIDATE_APPID_SUCCESS,
          payload: {
            data: resp,
          },
        });
      })
      .catch((err) => {
        showToastMessage("Error", false);
        dispatch({
          type: LMS.FETCH_VALIDATE_APPID_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        dispatch({ type: LMS.FETCH_LOADING_END });
      });
  };
