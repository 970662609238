function SortingIcon(props) {
  return (
    <svg
      width={14}
      height={18}
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.666 9.833V3.854L1.521 6 .333 4.833 4.5.667l4.166 4.166L7.48 6 5.333 3.854v5.98H3.666zm5.834 7.5l-4.167-4.166L6.521 12l2.145 2.146v-5.98h1.667v5.98L12.479 12l1.187 1.167L9.5 17.333z"
        fill="#EF6E0B"
      />
    </svg>
  )
}

export default SortingIcon
