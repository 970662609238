import React from "react";
import { Button } from "@progress/kendo-react-buttons";

export default function ActionButton({ label, handleClick , ...props }) {
    return (
        <Button className="m-2" themeColor={"primary"} disabled={false} onClick={handleClick} {...props}>
            {label}
        </Button>
    );
}
