import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  getStatus,
  // formatDateV2,
  // getPageName,
  // getUserRoles,
  // getFFUserType,
  formatDateTime,
} from "../../../utils/utils";
import { Button } from "@progress/kendo-react-buttons";

export const service_history_column_keys = {
  ID: "idServiceHistoryRequest",
  ORDER_ID: "orderId",
  APPOINTMENT_ORDER_NUMBER: "appointmentOrderNumber",
  ORDER_NUMBER: "orderNumber",
  ORDER_ITEM_ID: "orderItemId",
  REG_NUMBER: "regNumber",
  STATUS: "status",
  AGENT: "agent",
  CREATION_DATE: "createdAt",
  AGEING: "ageing",
  MAKE: "make",
  MODEL: "model",
  VARIANT: "variant",
  YEAR: "year",
  DATE: "date",
  CAR_DETAILS: "carDetails",
  SOURCE: "source",
  CLOSURE_DATE: "closureDate",
  ENGINE_NUMBER: "engineNumber",
  CHASSIS_NUMBER: "chassisNumber",
};

export const CentreLinkCell = (props) => {
  const { dataItem } = props || {};
  const linkTo = `/operations/services/${dataItem[service_history_column_keys.ID]
    }`;
  return (
    <td className="k-table-td ">
      {
        <Link to={linkTo} style={{ color: "#0d6efd" }}>
          {dataItem?.appointmentOrderNumber}
        </Link>
      }
    </td>
  );
};

export const StatusCell = (event) => {
  const status = getStatus(event.dataItem?.status);
  return (
    <td>
      <span className={status?.css}>{status?.label}</span>
    </td>
  );
};

export const AgeingCell = (props) => {
  const { dataItem } = props || {};
  const getAgeingCellStyle = () => {
    if (dataItem?.ageing <= 23) {
      return "greenAgeingCell";
    } else if (dataItem?.ageing > 23 && dataItem?.ageing <= 40) {
      return "orangeAgeingCell";
    } else {
      return "redAgeingCell";
    }
  };
  return (
    <td className="k-table-td ">
      {
        <span className={`ageingCell ${getAgeingCellStyle()}`}>
          <span className="k-icon k-i-clock" />
          {dataItem?.ageing}hrs
        </span>
      }
    </td>
  );
};

export const AgentCell = (props) => {
  const { dataItem } = props || {};
  return (
    <td className="k-table-td ">
      {dataItem?.status == "CREATED" ? (
        <Button className="m-2" themeColor={"primary"} onClick={() => { }}>
          Assign to Me
        </Button>
      ) : (
        <span>{dataItem?.agent}</span>
      )}
    </td>
  );
};

export const CreatedDateCell = (event) => {
  return (
    <td className="td-custom-text">
      {formatDateTime(event?.dataItem?.createdAt)}
    </td>
  );
};

export const ClosureDateCell = (event) => {
  return (
    <td className="td-custom-text">
      {formatDateTime(event?.dataItem?.closureDate)}
    </td>
  );
};

export const serviceHistoryColumns = {
  [service_history_column_keys.APPOINTMENT_ORDER_NUMBER]: {
    field: service_history_column_keys.APPOINTMENT_ORDER_NUMBER,
    label: "APPT ID/ORDER ID",
    cell: CentreLinkCell,
    width: "250px",
  },
  [service_history_column_keys.ORDER_ID]: {
    field: service_history_column_keys.ORDER_ID,
    label: "Order Id",
    width: "150px",
  },
  [service_history_column_keys.REG_NUMBER]: {
    field: service_history_column_keys.REG_NUMBER,
    label: "REG Number",
    width: "200px",
  },
  [service_history_column_keys.STATUS]: {
    field: service_history_column_keys.STATUS,
    label: "Status",
    cell: StatusCell,
    filterable: true,
    width: "150px",
  },
  [service_history_column_keys.CAR_DETAILS]: {
    field: service_history_column_keys.CAR_DETAILS,
    filterable: true,
    label: "Car Details",
    // cell: CarDetailCell,
    width: "250px",
  },
  [service_history_column_keys.AGENT]: {
    field: service_history_column_keys.AGENT,
    label: "Agent",
    // cell: AgentCell,
    width: "200px",
  },
  [service_history_column_keys.AGEING]: {
    field: service_history_column_keys.AGEING,
    label: "Ageing",
    cell: AgeingCell,
    width: "150px",
  },
  [service_history_column_keys.SOURCE]: {
    field: service_history_column_keys.SOURCE,
    label: "Source",
    filterable: true,
    width: "150px",
  },
  [service_history_column_keys.CREATION_DATE]: {
    field: service_history_column_keys.CREATION_DATE,
    cell: CreatedDateCell,
    label: "Creation Date",
    width: "200px",
  },
};

export const getDisplayedCoulmns = (cols) => {
  return Object.keys(cols);
};

export const getSelectedAssignToMeRowIds = (rows, data) => {
  const Ids = Object.keys(rows).filter((key) => {
    const state = rows[key];
    const row = data.find(
      (item) => item.idServiceHistoryRequest === parseInt(key)
    );
    if (state && row.status.toUpperCase() === "UNASSIGNED") {
      return key;
    }
  });
  return Ids;
};

export const SERVICE_HISTORY_STATUSES = {
  ASSIGNED: "ASSIGNED",
  UNASSIGNED: "UNASSIGNED",
  FULFILLED: "FULFILLED",
  UNABLE_TO_FULFILL: "UNABLE_TO_FULFILL",
};
