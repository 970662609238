import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router-dom";


const ServiceDetailActions = (props) => {

  const history = useHistory();

  const onBack = () => {
    history.push("/operations/services");
  }; 
 
  return (
    <>
      <div className="ops-challan-actions">
        <div className="k-form-buttons">
          <Button themeColor={"primary"} fillMode="outline" onClick={onBack}>
            Back
          </Button>
          <span className="service-detail-progress-bar">Service History <span className="k-icon k-i-arrow-chevron-right k-icon-24"></span>{props?.appOrderId}</span>
        </div>
      </div>
    </>
  );
};
export default ServiceDetailActions;
