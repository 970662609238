import { USER_ROLE_KEYS } from "../../../utils/constants/menu.constants"

export const TAB_STRIPS = [
    { key: 'CJ_ANNOUNCEMENTS', label: 'Announcements', role: [USER_ROLE_KEYS.C2B_INSP_ANNOUNCEMENT_ADMIN] },
    { key: 'CJ_REFERRAL', label: 'CJ Referral', role: [USER_ROLE_KEYS.C2B_INSP_CJ_REFERRAL_ADMIN] },
    { key: 'CLUSTER_MANAGER', label: 'Cluster Manager', role: [USER_ROLE_KEYS.C2B_OPS_QC_CJ_INCENTIVE_ADMIN] },
]

export const INITIAL_ANNOUNCEMENT_DATA = {
    feedId: null,
    title: '',
    message: '',
    uploadedFileUrl: null,
    cluster: [],
    triggerDateTime: null,
    expiryDateTime: null,
    recurring: null,
}

export const INITIAL_REFERRAL_DATA = {
    mainHeader: null,
    stepHeader1: null,
    stepSubHeader1: null,
    stepHeader2: null,
    stepSubHeader2: null,
    stepHeader3: null,
    stepSubHeader3: null,
    termsConditions: null
}