import axios from "axios";
import {
	API_HEADER_CONSTANTS,
	ERROR_CODES,
} from "../../utils/constants/values.constants";
import { getSellerFinanceMetaData } from "../../utils/constants/url.constants";
import { getSessionToken } from "@descope/react-sdk";
import { onBackLogin, getLoginUserEmail } from "../../utils/utils";

const DEBUG = process.env.NODE_ENV === "development";
const apiMetaData = getSellerFinanceMetaData();
const api = axios.create({
	baseURL: apiMetaData.url,
	headers: {
		"Content-Type": "application/json",
	},
});

api.interceptors.request.use(
	(config) => {
		config.headers["user-email"] = getLoginUserEmail();
		config.headers[API_HEADER_CONSTANTS.X_AUTH_KEY] = `${getSessionToken()}`;
		if (DEBUG) {
			console.info("✉️ ", config);
		}
		return config;
	},
	(error) => {
		if (DEBUG) {
			console.error("✉️ ", error);
		}
		return Promise.reject(error);
	}
);

api.interceptors.response.use(
	(response) => {
		if (DEBUG) {
			console.info("📩 ", response);
		}
		return response;
	},
	(error) => {
		if (error.response) {
			if (DEBUG) {
				console.error("Error: ", error.response.data);
			}
			if (
				error.response &&
				error.response.status === ERROR_CODES.UNAUTHORISED &&
				!window.location.pathname.includes("login")
			) {
				localStorage.clear();
				onBackLogin();
			}
			return Promise.reject(error.response.data);
		}
		if (DEBUG) {
			console.error("📩 ", error);
		}
		return Promise.reject(error.message);
	}
);

export default api;
