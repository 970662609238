import { useCallback, useContext, useEffect, useState } from "react";
import { fetchLeads } from "../utils/helper";
import { AgentContext } from "../context/AgentContext";
import { LeadsFilterContext } from "../context/LeadsFilterContext";

export const useFetchLeads = (
    paginationParams,
    filterParams,
    allowAgentFilter = true
) => {
    const [leadData, setLeadData] = useState(null);
    const [totalLeadCount, setTotalLeadCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const selectedAgent = useContext(AgentContext);
    const leadsFilters = useContext(LeadsFilterContext);

    const handleFetchLeads = useCallback(
        () =>
            fetchLeads(
                paginationParams,
                filterParams,
                allowAgentFilter ? selectedAgent : null,
                leadsFilters,
                setLeadData,
                setTotalLeadCount,
                setIsLoading
            ),
        [
            paginationParams,
            filterParams,
            selectedAgent,
            allowAgentFilter,
            leadsFilters,
        ]
    );

    useEffect(() => {
        handleFetchLeads();
    }, [handleFetchLeads]);

    return {
        leadData,
        totalLeadCount,
        refreshLeads: handleFetchLeads,
        isLoading,
    };
};
