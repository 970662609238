import { useEffect, useState } from "react";
import { FormComboBox, FormInput } from "../../../../../../../components";
import { PickUpFormData } from "../../../../negotiation/pr-automation/viewModel";
import { FORM_ELEMENT_TYPE } from "../../../../../../../utils/constants/values.constants";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCityList,
  fetchOrderDetails,
} from "../../../../../../../store/actions/pr-automation";
import styles from "../../../../negotiation/assets/styles/pr.styles.module.css";
import isPickUpDetails from "../../../shared/is-pickup-details";
import { InventoryService } from "../../../../../../../services";
import { PR_AUTOMATION } from "../../../../../../../store/types";
import { showToastMessage } from "../../../../../../../utils/utils";
import { Button } from "@progress/kendo-react-buttons";

export const AddressForm = (props) => {
  const dispatch = useDispatch();
  const { selectedDate, selectedTime, togglePickUp } = props || {};
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [formData, setFormData] = useState(PickUpFormData);
  const lms = useSelector((state) => state.lms);
  const { appointmentId } = lms || {};
  const prDetails = useSelector((state) => state.prAutomation);
  const {
    bookSlotData: { slotData = {} },
    address = {},
    orderData: { pickupDetails = {} },
  } = prDetails || {};
  const { fromState } = pickupDetails || {};
  const { stateList = [], cityList = [] } = address || {};
  useEffect(() => {
    if (fromState && stateList?.length) {
      const stateId = stateList?.find(
        (item) => item.stateName === fromState
      )?.stateId;
      dispatch(fetchCityList(stateId));
    }
  }, [fromState, stateList]);
  useEffect(() => {
    if (isPickUpDetails(pickupDetails)) {
      let updatedData = { ...formData };
      Object.keys(formData)?.forEach((item) => {
        updatedData = {
          ...updatedData,
          [item]: { ...updatedData[item], value: pickupDetails[item] },
        };
      });
      setFormData(updatedData);
    }
  }, [pickupDetails]);
  useEffect(() => {
    const list = stateList?.map((item) => ({
      value: item.stateId,
      label: item.stateName,
    }));
    setStateOptions(list);
  }, [stateList]);
  useEffect(() => {
    const list = cityList?.map((item) => ({
      value: item.cityId,
      label: item.cityName,
    }));
    setCityOptions(list);
  }, [cityList]);
  const handleChange = (e) => {
    const { name } = e?.target?.props || {};
    const { value } = e || {};
    if (name === "fromPincode" && (value > 999999 || value < 0)) {
      return;
    }
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value:
          e?.target?.props?.elementtype === "SELECT"
            ? value?.label
            : name === "fromPincode"
            ? value.replace(/\D/g, "").slice(0, 6)
            : value,
        validationMessage:
          name === "fromPincode" && (value < 100000 || value > 999999)
            ? "Please enter 6 digit pincode"
            : "",
      },
    });
    if (name === "fromState") {
      dispatch(fetchCityList(value?.value));
    }
  };
  const getSelectedValue = (key) => {
    if (key === "fromState") {
      return (
        stateOptions?.find((item) => item.label === formData[key]?.value) ||
        null
      );
    } else {
      return (
        cityOptions?.find((item) => item.label === formData[key]?.value) || null
      );
    }
  };
  const handleSubmit = () => {
    const filteredSlot =
      slotData &&
      slotData?.[selectedDate]?.find((item) => item.slotKey === selectedTime);

    const payload = {
      appointmentId,
      address: formData["fromAddress"].value,
      pincode: parseInt(formData["fromPincode"].value),
      city: formData["fromCity"].value,
      state: formData["fromState"].value,
      reschedule: isPickUpDetails(pickupDetails),
      slot: {
        slotKey: selectedTime,
        date: selectedDate,
        from: filteredSlot?.from,
        to: filteredSlot?.to,
      },
      source: "APP_ANDROID",
    };
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    InventoryService.schedulePickup(payload)
      .then((res) => {
        if (res && res.status === 200) {
          showToastMessage("Pickup details saved successfully", true);
          dispatch(
            fetchOrderDetails({
              appointmentId,
              documentCodes: ["AADHAR_CARD", "PAN_CARD_COPY"],
            })
          );
          togglePickUp();
        }
      })
      .catch((error) => {
        showToastMessage(error?.message || error?.error, false);
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };
  const handleKeyDown = (e) => {
    const { name } = e?.target || {};
    if (name === "fromPincode" && e.key === "e") {
      e.preventDefault();
    }
  };
  return (
    <div>
      <div className={styles.pickupFormWrapper}>
        <div className={styles.pickupForm}>
          {Object.keys(formData).map((key) => {
            const elementMetaData = formData[key];
            switch (elementMetaData.elementtype) {
              case FORM_ELEMENT_TYPE.SELECT:
                return (
                  <FormComboBox
                    id={key}
                    key={key}
                    onChange={handleChange}
                    data={key === "fromState" ? stateOptions : cityOptions}
                    textField="label"
                    dataItemKey="value"
                    {...elementMetaData}
                    value={getSelectedValue(key)}
                    placeholder={
                      key === "fromState" ? "Select State" : "Select City"
                    }
                  />
                );
              default:
                return (
                  <FormInput
                    id={key}
                    key={key}
                    onChange={handleChange}
                    {...elementMetaData}
                    value={formData[key]?.value}
                    placeholder={
                      key === "fromPincode" ? "Enter Pincode" : "Enter Address"
                    }
                    onKeyDown={handleKeyDown}
                    touched={true}
                    validationMessage={formData[key]?.validationMessage}
                  />
                );
            }
          })}
        </div>
      </div>
      <div className={`k-mt-15 ${styles.alignRight}`}>
        <Button onClick={togglePickUp} className={`${styles.cancelCta} k-mr-5`}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          className={styles.submitCta}
          disabled={
            !selectedDate ||
            !selectedTime ||
            Object.keys(formData).find(
              (item) =>
                !formData[item].value || formData[item].validationMessage
            )
          }
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
