import React from "react";
// import styles from "../../../assets/style/ff-layout.module.css";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { KendoCard } from "../../../../../components";
// import { Button } from "@progress/kendo-react-buttons";
import ChallanDetailRevamp from "../../../components/challan-detail-revamp";
// import { useDispatch, useSelector } from "react-redux";
// import { getRequestChallanItems } from "../../../../../store/actions/ops-challan";

const ChallanItemGrid = (props = {}) => {
  const style = {
    background: "#FFFFFF",
    minHeight: "180px",
    border: "15px solid #EBF5FF",
    boxShadow: "unset",
    borderRadius: "8px",
  };
  const headerStyle = {
    color: "#003E72",
    fontWeight: "600",
    fontSize: "18px",
  };
  return (
    <div>
      <KendoCard
        cardStyle={style}
        headerStyle={headerStyle}
        headerTitle={`Challan Info (Appt. ID ${
          props?.challanRequestDetail?.appointmentOrderId || ""
        })`}
        details={
          <GridLayout>
            <GridLayoutItem row={1}>
              <ChallanDetailRevamp {...props} />
            </GridLayoutItem>
          </GridLayout>
        }
      />
    </div>
  );
};

export default ChallanItemGrid;
