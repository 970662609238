export const DEALER_PROCUREMENT = {
  FETCH_LOADING_START: "FETCH_LOADING_START",
  FETCH_LOADING_END: "FETCH_LOADING_END",
  FETCH_DEALER_LIST_SUCCESS: "FETCH_DEALER_LIST_SUCCESS",
  FETCH_DEALER_LIST_FAILURE: "FETCH_DEALER_LIST_FAILURE",
  CREATE_DEALER_SUCCESS: "CREATE_DEALER_SUCCESS",
  CREATE_DEALER_FAILURE: "CREATE_DEALER_FAILURE",
  GET_DEALER_CENTER_SUCCESS: "GET_DEALER_CENTER_SUCCESS",
  GET_DEALER_CENTER_FAILURE: "GET_DEALER_CENTER_FAILURE",
  GET_CITY_DETAILS_SUCCESS: "GET_CITY_DETAILS_SUCCESS",
  GET_CITY_LIST_FAILURE: "GET_CITY_LIST_FAILURE",
};
