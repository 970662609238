import { service_history_column_keys } from "../../service-history/viewModels";
export const detail_list_keys = [
	{
		field: service_history_column_keys.MAKE,
		title: "Make",
	},
	{
		field: service_history_column_keys.MODEL,
		title: "Model",
	},
	{
		field: service_history_column_keys.VARIANT,
		title: "Variant",
	},
	{
		field: service_history_column_keys.YEAR,
		title: "Year",
	},
	{
		field: service_history_column_keys.REG_NUMBER,
		title: "Reg No.",
	},
	{
		field: service_history_column_keys.SOURCE,
		title: "Source",
	},
	{
		field: service_history_column_keys.CREATION_DATE,
		title: "Creation Date",
	},
	{
		field: service_history_column_keys.CLOSURE_DATE,
		title: "Closure Date",
	},
	{
		field: service_history_column_keys.ENGINE_NUMBER,
		title: "Engine No.",
	},
	{
		field: service_history_column_keys.CHASSIS_NUMBER,
		title: "Chassis No.",
	},
];