import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router-dom";

const FulfillmentActions = (props) => {
  const history = useHistory();

  const openDialogCreateOrder = () => {
    history.push("/dealer/fulfillments/createDealer");
  };
  return (
    <div className="ops-challan-actions">
      <div className="k-form-buttons"></div>
      <div className="k-form-buttons">
        <Button
          className="m-2"
          themeColor={"primary"}
          onClick={openDialogCreateOrder}
        >
          Create Dealer
        </Button>
      </div>
    </div>
  );
};
export default FulfillmentActions;
