import { BaGrid } from "../../shared/ba-grid";
import useBaColumns from "../../../hooks/useBaColumns";
import { KendoCard } from "../../../../../../components";

const BusinessApprovalsCard = () => {
  const [updatedBusinessApproval, visibleColumns, baTitle] = useBaColumns();

  const renderDetails = () => {
    return (
      <BaGrid
        updatedBusinessApproval={updatedBusinessApproval}
        visibleColumns={visibleColumns}
      />
    );
  };
  const renderHeaderTitle = () => {
    return (
      <div className="align-center">
        <span className="k-font-bold k-font-size-xl k-mr-2">
          Business Approvals
        </span>
        {baTitle}
      </div>
    );
  };
  return (
    <KendoCard
      cardStyle={{
        background: "#F0F2F5",
      }}
      headerTitle={renderHeaderTitle()}
      details={renderDetails()}
    />
  );
};

export default BusinessApprovalsCard;
