import { TYPE_BUY_BACK } from "../types"

const INITIAL_STATE = {
  isLoading: false,
  buyBackData: [],
  isRefreshRequired: false,
}

const BuyBack = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPE_BUY_BACK.SET_BUY_BACK_DATA:
      return {
        ...state,
        buyBackData: action.payload,
      }
    case TYPE_BUY_BACK.FETCH_LOADING_START:
      return {
        ...state,
        isLoading: true,
      }
    case TYPE_BUY_BACK.FETCH_LOADING_END:
      return {
        ...state,
        isLoading: false,
      }
    case TYPE_BUY_BACK.SET_REFRESH:
      return {
        ...state,
        isRefreshRequired: action.payload,
      }

    default:
      return state;
  }
}

export default BuyBack;