import { TYPE_LOADER } from "../types";

const INITIAL_STATE = {
  isLoading: false,
};

const AppLoader = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPE_LOADER.SHOW_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case TYPE_LOADER.HIDE_LOADER:
      return {
        ...state,
        isLoading: false,
      };
    case TYPE_LOADER.TOGGLE_LOADER:
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    default:
      return state;
  }
};
export default AppLoader;
