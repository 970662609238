import React, { useEffect } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useState } from "react";
import { KendoCard } from "../../../../../components";
import { Button } from "@progress/kendo-react-buttons";
import RaiseBa from "./raise-ba";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBusinessApprovalTypes,
  fetchBusinessApprovals,
  fetchC24QuoteDetails,
  fetchDocsStatus,
  fetchKycDocs,
  fetchOrderDetails,
  fetchSlots,
  fetchStateList,
} from "../../../../../store/actions/pr-automation";
import { Loader } from "../../../../../common/Loader";
import BusinessApprovals from "./business-approvals";
import KycDocs from "./kyc-docs";
import BankDetails from "./bank-details";
import { UploadDocs } from "./upload-docs";
import styles from "../../negotiation/assets/styles/pr.styles.module.css";
import BookSlot from "./book-slot";
import MarkReasons from "./mark-reasons";
import PickUpDetails from "./book-slot/pick-up-details";
import SubmitPrCard from "./submit-pr-card";
import AuditChecks from "./audit-checks";
import NetPayableCard from "./net-payable-card";
import { numberFormat } from "../../../../../utils/utils";
import { validateAppointmentId } from "../../../../../store/actions/lms";
import AuditCheckLoader from "./audit-checks/audit-check-loader";
import { isPrInitialized } from "./viewModel";

const PrSubmission = (props) => {
  const appointmentIdToken = props?.match?.params?.appointmentId;
  const make = props?.match?.params?.make;
  const model = props?.match?.params?.model;
  const dispatch = useDispatch();
  const [isRaiseBa, setIsRaiseBa] = useState(false);
  const [isMarkReason, setIsMarkReason] = useState(false);
  const [isPickUp, setIsPickUp] = useState(false);
  const [isAuditChecks, setIsAuditChecks] = useState(false);
  const [isAuditLoader, setIsAuditLoader] = useState(false);
  const [docCode, setDocCode] = useState("");
  const [chargesAmount, setChargesAmount] = useState(0);
  const [filteredRules, setFilteredRules] = useState([]);
  // const [authData, setAuthData] = useState({});
  // const { appointmentId, make, model } = props || {};
  const prDetails = useSelector((state) => state.prAutomation);
  const {
    c24Quote,
    isLoading: prLoading,
    bookSlotData: { isLoading: slotLoading },
    orderData: { purchase: { purchaseStatus = "" } = {} } = {},
  } = prDetails || {};
  const lms = useSelector((state) => state.lms);
  const { appointmentId } = lms || {};
  useEffect(() => {
    dispatch(validateAppointmentId(appointmentIdToken));
  }, [appointmentIdToken]);
  useEffect(() => {
    if (appointmentId) {
      dispatch(fetchBusinessApprovals(appointmentId));
    }
  }, [appointmentId]);
  useEffect(() => {
    if (appointmentId) {
      dispatch(
        fetchC24QuoteDetails({
          appointmentId,
        })
      );
    }
  }, [appointmentId]);

  useEffect(() => {
    if (appointmentId) {
      dispatch(
        fetchOrderDetails({
          appointmentId,
          documentCodes: ["AADHAR_CARD", "PAN_CARD_COPY"],
        })
      );
    }
  }, [appointmentId]);
  useEffect(() => {
    if (appointmentId) {
      dispatch(fetchKycDocs(appointmentId));
    }
  }, [appointmentId]);
  useEffect(() => {
    if (appointmentId) {
      dispatch(fetchDocsStatus(appointmentId));
    }
  }, [appointmentId]);
  const toggleRaiseBa = () => {
    if (!isRaiseBa) {
      dispatch(
        fetchBusinessApprovalTypes({
          cityHeadApprovalApplicable: true,
          active: true,
        })
      );
    }
    setIsRaiseBa(!isRaiseBa);
  };
  const togglePickUp = () => {
    if (!isPickUp) {
      dispatch(fetchSlots(appointmentId));
      dispatch(fetchStateList());
    }
    setIsPickUp(!isPickUp);
  };
  const toggleMarkReasons = () => {
    setIsMarkReason(!isMarkReason);
  };

  return prLoading || slotLoading ? (
    <Loader />
  ) : (
    <div className="k-mr-2">
      <GridLayout
        gap={{
          rows: 10,
          cols: 10,
        }}
        cols={[
          {
            width: "22%",
          },
          {
            width: "68%",
          },
          {
            width: "9%",
          },
        ]}
      >
        <GridLayoutItem rowSpan={10} col={1} style={{ height: "max-content" }}>
          <KendoCard
            headerTitle={
              <span
                className={`k-font-medium k-font-size-lg ${
                  !appointmentId ? styles.colorRed : ""
                }`}
              >{`# ${appointmentId ? appointmentId : "NA"}`}</span>
            }
            headerSubtitle={make || model ? make + " " + model : "NA"}
            titleStyle={{ fontSize: "16px" }}
            details={
              <NetPayableCard
                chargesAmount={chargesAmount}
                setChargesAmount={setChargesAmount}
              />
            }
          />
        </GridLayoutItem>
        <GridLayoutItem row={1} col={2}>
          <BusinessApprovals />
        </GridLayoutItem>
        <GridLayoutItem row={1} col={3} rowSpan={1}>
          <Button
            className={styles.raiseBa}
            onClick={toggleRaiseBa}
            disabled={!isPrInitialized(purchaseStatus)}
          >
            Raise BA
          </Button>
        </GridLayoutItem>
        <GridLayoutItem row={2} colSpan={2} col={2}>
          <KycDocs
            docCode={docCode}
            setDocCode={setDocCode}
            filteredRules={filteredRules}
            setFilteredRules={setFilteredRules}
            // setAuthData={setAuthData}
          />
        </GridLayoutItem>
        <GridLayoutItem row={3} colSpan={2} col={2}>
          <BankDetails toggleMarkReasons={toggleMarkReasons} />
        </GridLayoutItem>
        <GridLayoutItem row={4} colSpan={2} col={2}>
          <BookSlot togglePickUp={togglePickUp} />
        </GridLayoutItem>
        <GridLayoutItem row={5} colSpan={2} col={2}>
          <SubmitPrCard
            chargesAmount={chargesAmount}
            filteredRules={filteredRules}
            setIsAuditLoader={setIsAuditLoader}
            setIsAuditChecks={setIsAuditChecks}
          />
        </GridLayoutItem>
        {isRaiseBa && <RaiseBa toggleRaiseBa={toggleRaiseBa} {...props} />}
        {isMarkReason && (
          <MarkReasons toggleMarkReasons={toggleMarkReasons} {...props} />
        )}
        {isAuditChecks && (
          <AuditChecks
            setIsAuditLoader={setIsAuditLoader}
            setIsAuditChecks={setIsAuditChecks}
            netPayableAmount={
              c24Quote - chargesAmount
                ? numberFormat(c24Quote - chargesAmount)
                : numberFormat(0)
            }
            {...props}
          />
        )}
        {isAuditLoader && <AuditCheckLoader {...props} />}
        {isPickUp && <PickUpDetails togglePickUp={togglePickUp} {...props} />}
        {docCode && (
          <UploadDocs
            setDocCode={setDocCode}
            docCode={docCode}
            // authData={authData}
            {...props}
          />
        )}
      </GridLayout>
    </div>
  );
};
export default PrSubmission;
