import { TAB_CAR_DETAILS_COLUMNS } from "../../constants/fields.constants";
import "./tab-car-details.scss";
import { useEffect, useState}  from "react";
const TabCarDetails = ({carDetails}) => {

	const [tabCarDetails,setTabDetails] = useState([...TAB_CAR_DETAILS_COLUMNS])
	useEffect(()=>{
		let data = carDetails?.carDetailsSummary;
		if(data){
			setTabDetails([
				{
					label:"Odometer",
					value: data?.odometerReading
				},{
					label:"Fuel Type",
					value: data?.fuelType
				},{
					label:"Color",
					value: data?.color
				},{
					label:"Ownership",
					value: data?.ownership
				},{
					label:"Insurance Expiry",
					value: data?.insuranceExpiry
				},{
					label:"Insurance Type",
					value: data?.insuranceType
				},{
					label:"Registration city",
					value: data?.registrationCity
				},{
					label:"Registration Number",
					value: data?.registrationNumber
				},
			])
		}
	},[carDetails])
	return (
		<>
		{tabCarDetails.map((item)=>(
			<div className="plane-grid" key={item.label}>
             <div className="label">{item.label}</div>
             <div className="value">{item.value}</div>
			</div>
		))}
		</>
	);
};
export default TabCarDetails;
