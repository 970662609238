import { Button } from "@progress/kendo-react-buttons"
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs"

import './pa-confirmation.scss'
import { TL_VIEW_CALENDAR } from "../../common/constants/filters.constants"
import { formatDateTime } from "../../../../utils/utils"
import { useDispatch, useSelector } from "react-redux"
import { reassignDealer } from "../../store/actions/myDealers"
import { formatDateToDMY, showToastMessage } from "../../common/utils/utils"
import { AppLoader } from "../../common/components/app-loader"

const PaConfirmationModal = ({ setIsAssignModalOpen, selectedDealers, date = null, filter = TL_VIEW_CALENDAR[0], selectedPA, setSelectedDealers, updateResetSelection, resetSelection, tabSelected, refreshPageData, assignmentIds, setAssignmentIds }) => {

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.appLoader);

  const getDate = (filterId) => {
    const today = new Date();
    const twoDaysLater = new Date(today);
    twoDaysLater.setDate(today.getDate() + 2);

    switch (filterId) {
      case TL_VIEW_CALENDAR[1].id:
        return { start: today, end: today };
      case TL_VIEW_CALENDAR[2].id:
        return { start: today, end: twoDaysLater };
      default:
        return null;
    }
  };

  const handleReassignDealer = () => {
    const dateRange = getDate(filter.id);

    const validFrom = date?.dateRange?.start && filter.id === TL_VIEW_CALENDAR[3].id
      ? formatDateTime(date.dateRange.start).slice(0, 10)
      : dateRange?.start ? formatDateTime(dateRange.start).slice(0, 10) : null;

    const validTill = date?.dateRange?.end && filter.id === TL_VIEW_CALENDAR[3].id
      ? formatDateTime(date.dateRange.end).slice(0, 10)
      : dateRange?.end ? formatDateTime(dateRange.end).slice(0, 10) : null;

    const params = tabSelected === 1 ? {
      dealerIds: assignmentIds
    } : {
      userId: selectedPA?.id,
      dealerIds: selectedDealers,
      validFrom,
      validTill,
    }

    dispatch(reassignDealer(
      params, tabSelected
    )).then((resp) => {

      showToastMessage(resp.message, true);
      setIsAssignModalOpen(false);
      setSelectedDealers([]);
      setAssignmentIds([]);
      updateResetSelection(!resetSelection);

      tabSelected === 1 && refreshPageData();
    }).catch((err) => {
      showToastMessage(err?.error || err?.message, false);
    });
  };

  const message = tabSelected === 1
    ? <span className="confirmation-text">Are you sure you want to cancel reassignment of {selectedDealers?.length} {selectedDealers?.length === 1 ? 'dealer' : 'dealers'}?</span>
    : (
      <span className="confirmation-text">
        Are you sure you want to reassign {selectedDealers?.length} {selectedDealers?.length === 1 ? 'dealer' : 'dealers'} to
        <span className="highlight-email">&nbsp;{selectedPA?.email}</span>
        {date?.dateRange?.start && date?.dateRange?.end && filter.id === TL_VIEW_CALENDAR[3].id
          ? ` from ${formatDateToDMY(date.dateRange.start).slice(0, 10)} to ${formatDateToDMY(date.dateRange.end).slice(0, 10)}`
          : ` for ${filter.label}`
        }
        &nbsp;?
      </span>
    );


  if (isLoading)
    return <AppLoader />

  return (
    <Dialog className="paConfirmationModal">
      {message}

      <DialogActionsBar>
        <Button type="button" className="cancel-btn" onClick={() => setIsAssignModalOpen(false)} >
          Cancel
        </Button>
        <Button type="button" className="confirm-btn" onClick={handleReassignDealer} >
          Yes, {tabSelected === 1 ? 'Cancel' : 'Reassign'}
        </Button>
      </DialogActionsBar>
    </Dialog >

  )
}

export default PaConfirmationModal