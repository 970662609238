
import { useEffect, useState } from "react";
import { Chip, ChipList } from "@progress/kendo-react-buttons";
import styles from "./styles.module.css";
import { DANGER_ICON_COLOR } from "../../../../viz-panel/common/constants/color.constants";
import { arrowRotateCwIcon } from "@progress/kendo-svg-icons";

const AppChips = ({ data = [], isPlainArray = true, remove, newCodes, isEdit = false }) => {
	const [chips, setChips] = useState([]);

	useEffect(() => {
		if (isPlainArray) {
			let newData = data.map((ele, index) => {
				return {
					text: ele.entityCode,
					value: "tab" + index,
					isRemove: ele.isRemove
				}
			});
			setChips([...newData]);

		} else {
			setChips([...data]);
		}
	}, [data.length])

	const removedChips = data.filter(chip => chip.isRemove)
	const addedChips = data.slice(data.length - newCodes, data.length)

	return (
		<>
			<div className={`${styles["app-chips"]}`}>
				<ChipList
					data={[...chips]}
					chip={(props) => (
						<Chip
							style={{ border: removedChips.some(chip => chip.entityCode === props.text) ? `1px solid ${DANGER_ICON_COLOR}` : isEdit && addedChips.some(chip => chip.entityCode === props.text) ? `1px solid green` : '' }}
							{...props}
							removeSvgIcon={removedChips.some(chip => chip.entityCode === props.text) ? arrowRotateCwIcon : ''}
							rounded="full"
							removable={!!remove}
							onRemove={(event) => {
								if (!event.target.props.dataItem.isRemove) {
									remove(event.target.element.innerText, true);
								}
								else {
									remove(event.target.element.innerText, false);
								}

							}}

						/>
					)}
				/>

			</div>
		</>
	);
};
export default AppChips;
