import { useEffect, useState } from "react";
import { fetchAnnouncements } from "../helper";

export const useFetchAnnouncements = (paginationParams, sortParms, filterParams) => {

    const [announcementData, setAnnouncementData] = useState(null);
    const [totalAnnouncementCount, setTotalAnnouncementCount] = useState(0);

    useEffect(() => {
        fetchAnnouncements(paginationParams, sortParms, filterParams, setAnnouncementData, setTotalAnnouncementCount);
    }, [paginationParams, sortParms, filterParams]);

    return { announcementData, totalAnnouncementCount };
};