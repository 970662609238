import { FILE_VIEW_ACTIONS } from "../utils/constants/values.constants";
import { getLoginUserEmail, getFFUserType } from "../utils/utils";
export default (api) => {
	const createOrder = (payload) => {
		const params = { ...payload, createdBy: getLoginUserEmail() };
		return new Promise((resolve, reject) => {
			api
				.post(`/api/v1/challan-request`, params, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const createChallanItem = ({ id, payload }) => {
		if (!!id) {
			const params = {
				...payload,
			};
			return new Promise((resolve, reject) => {
				api
					.put(`/api/v1/challan-item/${id}`, params, {
						headers: { "user-type": `${getFFUserType()}` },
					})
					.then((response) => {
						resolve(response.data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		} else {
			const params = {
				...payload,
				createdBy: getLoginUserEmail(),
			};
			return new Promise((resolve, reject) => {
				api
					.post(`/api/v1/challan-item`, params, {
						headers: { "user-type": `${getFFUserType()}` },
					})
					.then((response) => {
						resolve(response.data);
					})
					.catch((error) => {
						reject(error);
					});
			});
		}
	};
	const fetchOrders = (params = "") => {
		const url = "/api/v1/challan-request";
		return new Promise((resolve, reject) => {
			api
				.get(`${url}${params}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const fetchChallanItems = (params = {}) => {
		return new Promise((resolve, reject) => {
			api
				.get(`/api/v1/challan-item/${params.idChallanRequest}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const assignToMeRequests = (params) => {
		const payload = {
			challanRequestIds: params,
			assignTo: getLoginUserEmail(),
		};
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-request/assign`, payload, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const markAsResolvedRequests = (params) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-request/resolve`, params, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const submitAllChallans = (params) => {
		return new Promise((resolve, reject) => {
			api
				.patch(
					`api/v1/challan-request/submit-challans/${params}`,
					{},
					{
						headers: { "user-type": `${getFFUserType()}` },
					}
				)
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const submitChallanItems = (params) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-item/submit`, params, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const submitChallanItem = (params) => {
		return new Promise((resolve, reject) => {
			api
				.patch(
					`api/v1/challan-item/submit/${params}`,
					{},
					{
						headers: { "user-type": `${getFFUserType()}` },
					}
				)
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const deleteChallanItem = (params) => {
		return new Promise((resolve, reject) => {
			api
				.delete(
					`api/v1/challan-item/${params}`,
					{},
					{
						headers: { "user-type": `${getFFUserType()}` },
					}
				)
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const fetchDisposeChallanItems = (params = "") => {
		const url = "/api/v1/challan-item";
		return new Promise((resolve, reject) => {
			api
				.get(`${url}${params}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const fetchUploadProofFileLink = (id) => {
		return new Promise((resolve, reject) => {
			api
				.get(`api/v1/challan-request/download/${id}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const fetchUploadProofMultipleFileLink = (id, payload, type) => {
		return new Promise((resolve, reject) => {
			if (type === FILE_VIEW_ACTIONS.DELETE) {
				api
					.delete(`api/v1/challan-request/proof/${id}?file-path=${payload}`, {
						headers: { "user-type": `${getFFUserType()}` },
					})
					.then((response) => {
						resolve(response.data);
					})
					.catch((error) => {
						reject(error);
					});
			} else {
				api
					.get(`api/v1/challan-request/download/${id}?file-path=${payload}`, {
						headers: { "user-type": `${getFFUserType()}` },
					})
					.then((response) => {
						resolve(response.data);
					})
					.catch((error) => {
						reject(error);
					});
			}
		});
	};
	const fetchChallanItemUploadProofFileLink = (id) => {
		return new Promise((resolve, reject) => {
			api
				.get(`api/v1/challan-item/download/${id}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const fetchAllRegion = () => {
		return new Promise((resolve, reject) => {
			api
				.get(`api/v1/challan-region`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const fetchAllCourts = () => {
		return new Promise((resolve, reject) => {
			api
				.get(`api/v1/challan-court/all`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const fetchAllSources = () => {
		return new Promise((resolve, reject) => {
			api
				.get(`api/v1/challan-data/sources/all`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const submitSingleChallan = (id) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-item/submit/${id}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const assignDisposerToChallanItem = (params) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-item/assign`, params, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const fetchChallanRegions = () => {
		return new Promise((resolve, reject) => {
			api
				.get(`api/v1/challan-region`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const fetchCourtsByRegion = (id) => {
		const url = !!id
			? `api/v1/challan-region/${id}`
			: `api/v1/challan-court/all`;
		return new Promise((resolve, reject) => {
			api
				.get(url, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const fetchAgentsByCourt = (id) => {
		const url = id ? `api/v1/challan-court/${id}` : `api/v1/challan-agent/all`;
		return new Promise((resolve, reject) => {
			api
				.get(url, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const assignAgentToChallanItem = (id, params) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-item/assignAgent/${id}`, params, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const updateChallanItemAction = (id, params, action) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-item/${action}/${id}`, params, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const paymentChallanItem = (id, params) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-item/payment/${id}`, params, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const validateRegNumber = (id, regnumber) => {
		return new Promise((resolve, reject) => {
			api
				.get(
					`api/v1/challan-item/validate-reg-number/${id}?regNumber=${regnumber}`,
					{
						headers: { "user-type": `${getFFUserType()}` },
					}
				)
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const fetchAllOffenses = () => {
		return new Promise((resolve, reject) => {
			api
				.get(`api/v1/challan-data/offences/all`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const addDelayReasonChallanRequest = (params, payload) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-request/delay/${params}`, payload, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const addDelayReasonChallanItem = (params, payload) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-item/delay/${params}`, payload, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const fetchDisposeUsers = () => {
		return new Promise((resolve, reject) => {
			api
				.get(`/api/v1/challan-data/users/dispose`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const fetchFinderUsers = () => {
		return new Promise((resolve, reject) => {
			api
				.get(`/api/v1/challan-data/users/finder`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const UpdateAssignTo = (id, assignToEmail, action) => {
		const resourceName =
			action === "dispose" ? "challan-item/admin" : "challan-request";
		const keyName =
			action === "dispose" ? "challanItemIds" : "challanRequestIds";
		const params = {
			[keyName]: [id],
			assignTo: assignToEmail,
		};
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/${resourceName}/assign`, params, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const convertChallanVirtualCourtProceeding = (idChallanItem) => {
		return new Promise((resolve, reject) => {
			api
				.patch(
					`api/v1/challan-item/vc/convert/${idChallanItem}`,
					{},
					{
						headers: { "user-type": `${getFFUserType()}` },
					}
				)
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const raisePayment = (params, payload) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-item/payment/raise/${params}`, payload, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const amendPayment = (params, payload) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-item/payment/amend/${params}`, payload, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const fetchAllDelayReason = () => {
		return new Promise((resolve, reject) => {
			api
				.get(`/api/v1/challan-data/delay-reason/all`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const fetchFinderChallanDetailInfo = (params = "") => {
		const url = `api/v1/challan-request/details/${params}`;
		return new Promise((resolve, reject) => {
			api
				.get(`${url}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const fetchDisposeChallanDetailInfo = (params = "") => {
		const url = `/api/v1/challan-item/details/${params}`;
		return new Promise((resolve, reject) => {
			api
				.get(`${url}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const onExportData = (type, params) => {
		const url = `/api/v1/${type}/export-csv`;
		return new Promise((resolve, reject) => {
			api
				.get(
					`${url}?startDate=${params?.startDate}&endDate=${params.endDate}`,
					{
						headers: { "user-type": `${getFFUserType()}` },
					}
				)
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const updateChallanRequest = (params) => {
		const payload = {
			description: params.description,
		};
		return new Promise((resolve, reject) => {
			api
				.patch(
					`api/v1/challan-request/update/${params.idChallanRequest}`,
					payload,
					{
						headers: { "user-type": `${getFFUserType()}` },
					}
				)
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const fetchReasons = (type = "") => {
		const url = `api/v1/challan-data/${type}/all`;
		return new Promise((resolve, reject) => {
			api
				.get(`${url}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const reOpenChallanItem = (idChallanItem, reason) => {
		const payload = {
			reason,
		};
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-item/reopen/${idChallanItem}`, payload, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const finderUpload = (params, payload) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-request/upload/${params}`, payload, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const syncUsers = (payload) => {
		return new Promise((resolve, reject) => {
			api
				.post(`/api/v1/challan-data/users/add`, payload, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const updateAssignedDisposer = (params, payload) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-item/agentType/${params}`, payload, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const bulkAgentAssigned = (payload) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-item/assign-agent`, payload, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	return {
		bulkAgentAssigned,
		onExportData,
		updateChallanRequest,
		fetchReasons,
		reOpenChallanItem,
		submitChallanItems,
		convertChallanVirtualCourtProceeding,
		UpdateAssignTo,
		fetchDisposeUsers,
		fetchFinderUsers,
		fetchAllOffenses,
		validateRegNumber,
		paymentChallanItem,
		updateChallanItemAction,
		assignAgentToChallanItem,
		fetchAgentsByCourt,
		fetchCourtsByRegion,
		fetchChallanRegions,
		fetchAllRegion,
		fetchAllCourts,
		submitAllChallans,
		submitChallanItem,
		markAsResolvedRequests,
		assignToMeRequests,
		createOrder,
		fetchOrders,
		fetchChallanItems,
		createChallanItem,
		fetchDisposeChallanItems,
		fetchUploadProofFileLink,
		fetchChallanItemUploadProofFileLink,
		fetchAllSources,
		submitSingleChallan,
		assignDisposerToChallanItem,
		addDelayReasonChallanRequest,
		addDelayReasonChallanItem,
		raisePayment,
		amendPayment,
		fetchAllDelayReason,
		fetchFinderChallanDetailInfo,
		fetchDisposeChallanDetailInfo,
		deleteChallanItem,
		fetchUploadProofMultipleFileLink,
		finderUpload,
		syncUsers,
		updateAssignedDisposer,
	};
};
