export const OPS_VERIFIACTION = {
	FETCH_LOADING_START: "FETCH_LOADING_START",
	FETCH_LOADING_END: "FETCH_LOADING_END",
	FETCH_VERIFICATION_LIST_SUCCESS: "FETCH_VERIFICATION_LIST_SUCCESS",
	FETCH_VERIFICATION_LIST_FAILURE: "FETCH_VERIFICATION_LIST_FAILURE",
	SET_ASSIGNMENT_TYPE: "SET_ASSIGNMENT_TYPE",
	SET_SEARCH_QUERY: "SET_SEARCH_QUERY",
	SET_VERIFICATION_FILTERS: "SET_VERIFICATIONHISTORY_FILTERS",
	UPDATE_VERIFICATION_ROW_ITEM: "UPDATE_VERIFICATION_ROW_ITEM",
};
