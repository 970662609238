import { useState } from "react";
import { useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";

const HoursLeft = ({ hours,auctionEnded=()=>{} }) => {
	useEffect(() => {
		if (hours) {
			var d = new Date();
			var seconds = Math.round(d.getTime() / 1000);
			let t = hours - seconds;
			setTime(t);
			secondsToHms(t)
		}

	}, [hours])
	const [time, setTime] = useState(null)
	const [displayTime, setDisplayTime] = useState(null)
	useEffect(() => {
		let interval = null
		if (hours) {
			interval = setInterval(() => {
				setTime(val => {

					let newVal = val || time;
					secondsToHms(newVal - 1);
					auctionEnded(false);
					if(newVal<=0){
						clearInterval(interval);
						auctionEnded(true);
					}
					return newVal - 1
				});
			}, 1000);
		} else{
			auctionEnded(true);
		}
		return () => {
			if (interval) { clearInterval(interval); }
		}
	}, [hours])

	const secondsToHms = (d) => {
		d = Number(d);
		var h = Math.floor(d / 3600);
		var m = Math.floor(d % 3600 / 60);
		var s = Math.floor(d % 3600 % 60);

		var hDisplay = h > 0 ? h + (h === 1 ? "h:" : "h:") : "";
		var mDisplay = m > 0 ? m + (m === 1 ? "m:" : "m:") : "";
		var sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
		setDisplayTime(hDisplay + mDisplay + sDisplay);
	}
	return (
		<>
		{
			displayTime ?
			<span className="hours-left">{displayTime ? displayTime + ' left' : ''} </span>
			:
				<Button
				        className='app-error-btn auction-ended-btn'
						fillMode={'solid'}
						size={'small'}
						onClick={() => {}}
						>Auction Ended
						</Button>
		}
			
		</>
	);
};
export default HoursLeft;
