import { useEffect, useState } from "react";
import { VasService } from "../../../../../../services";

export const useFetchPriceCohort = (paginationParams, filterParams, sortParms, triggerList) => {

    const [data, setData] = useState(null);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        fetchCohorts(paginationParams, filterParams, sortParms, setData, setTotalCount);
    }, [paginationParams, filterParams, sortParms, triggerList]);

    return { data, totalCount };
};

const fetchCohorts = (paginationParams, filterParams, sortParms, setData, setTotalCount) => {
    const payload = {
        searchCriteria: {
            ...filterParams
        },
        pageDetails: {
            ...paginationParams,
            sorting: [
                {
                    direction: sortParms[0].dir.toUpperCase(),
                    field: sortParms[0].field
                }
            ]
        }
    }
    VasService.fetchPriceListing(payload)
        .then((resp) => {
            setData(resp?.content)
            setTotalCount(resp?.totalElements);
        })
        .catch((err) => {
            setData([])
            setTotalCount(0)
            console.log('Error Fetching Price List', err)
        })
}