import React from 'react'
import { thumborUrl, videoUrl } from '../../../../../../../utils/constants/url.constants'
import { isMp4Url } from '../../../../utils/helper';

function FileLoader(event) {

    if(!event.dataItem.uploadedFileUrl) {
        return <td></td>;
    }

    let imagePath = event.dataItem.uploadedFileUrl;
    let imageUrl = `${isMp4Url(imagePath) ? videoUrl() : thumborUrl()}${imagePath}`;

    return (
        <td>
            {
                event?.dataItem?.uploadedFileUrl &&
                <a style={{ color: '#0D6EFD', textDecoration: 'underline' }} href={imageUrl} target="_blank">
                    File URL
                </a>
            }
        </td>
    )
}

export default FileLoader