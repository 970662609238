import { MY_DEALERS_FILTER, MY_DEALERS_SORT_BY_FILTER, MY_DEALERS_TL_VIEW_DEFAULT_FILTER } from "../../common/constants/filters.constants";
import { TYPE_MY_DEALERS } from "../types";

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  page: 0,
  totalElements: 0,
  pageSize: 0,
  totalPages: 0,
  error: {},
  sortBy: "",
  filters: {
    sortBy: MY_DEALERS_SORT_BY_FILTER[0],
    dealerType: MY_DEALERS_FILTER[0],
    filterBy: MY_DEALERS_TL_VIEW_DEFAULT_FILTER[0]
  },
  isCallOngoing: false,
  currentCallEvent: "",
  dealerId: null,
  refreshRequired: false,
  markAsContactedConfirm: null,
  sideBarDealerFilterPayload: null,
  sideBarDealerFilterApplied: null,
  isDealerCarFiltersApplied: false,
  dealerFilterQueryParam: '',
  clientCorrelationId: null,
  allPAs: [],
  notificationPopup: false,
  isInitialMount: true,
};

const MyDealers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPE_MY_DEALERS.FETCH_LOADING_START:
      return {
        ...state,
        isLoading: true,
      };
    case TYPE_MY_DEALERS.SET_DEALER_ID:
      return {
        ...state,
        dealerId: action.payload
      };
    case TYPE_MY_DEALERS.UPDATE_DEALERS_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload }
      };
    case TYPE_MY_DEALERS.SHOW_CONTACTED_CONFIRM:
      return {
        ...state,
        markAsContactedConfirm: action.payload,
      };
    case TYPE_MY_DEALERS.FETCH_LOADING_END:
      return {
        ...state,
        isLoading: false,
      };
    case TYPE_MY_DEALERS.FETCH_MY_DEALERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        error: {},
      };
    case TYPE_MY_DEALERS.FETCH_MY_DEALERS_FAILURE:
      return {
        ...state,
        data: [],
        totalCount: 0,
        numberOfElements: 0,
        error: action.payload,
      };
    case TYPE_MY_DEALERS.REFRESH_DEALERS:
      return {
        ...state,
        refreshRequired: action.payload,
      };
    case TYPE_MY_DEALERS.SET_SIDEBAR__DEALER_FILTERS_PAYLOAD:
      return {
        ...state,
        sideBarDealerFilterPayload: action.payload
      };

    case TYPE_MY_DEALERS.SET_SIDEBAR_DEALER_FILTERS_APPLIED:
      return {
        ...state,
        sideBarDealerFilterApplied: action.payload
      };

    case TYPE_MY_DEALERS.SET_ONGOING_CALL_STATUS:
      return {
        ...state,
        isCallOngoing: action.payload
      };

    case TYPE_MY_DEALERS.SET_CURRENT_CALL_EVENT:
      return {
        ...state,
        currentCallEvent: action.payload
      };

    case TYPE_MY_DEALERS.SET_DEALER_FILTER_STATUS:
      return {
        ...state,
        isDealerCarFiltersApplied: action.payload
      };

    case TYPE_MY_DEALERS.SET_DEALER_FILTER_QUERY_PARAM:
      return {
        ...state,
        dealerFilterQueryParam: action.payload
      };

    case TYPE_MY_DEALERS.SET_CORRELATION_ID:
      return {
        ...state,
        clientCorrelationId: action.payload
      }

    case TYPE_MY_DEALERS.SET_REASSIGN_PA:
      return {
        ...state,
        allPAs: action.payload

      }

    case TYPE_MY_DEALERS.SET_NOTIFICATION_MODAL:
      return {
        ...state,
        notificationPopup: action.payload
      }

    case TYPE_MY_DEALERS.SET_INITIAL_MOUNT:
      return {
        ...state,
        isInitialMount: action.payload
      }

    default:
      return state;
  }
};
export default MyDealers;
