import { useState, useEffect } from "react";
import { CJ_REFERRAL_KEYS, REFERRAL_COLUMN_MAPPER } from "../viewModal";
import StatusManager, { predefinedStatusValues } from "../components/status-manager";
import { isFilterFieldPresent } from "../../../../../../utils/utils";
import { ColumnMenuRadioFilter } from "../components/column-menu-radio-filter";

export const useVisibleColumns = (
    referralData,
    setReferralDetails,
    gridFilters,
    setGridFilters,
    filterParams,
    setFilterParams,
    setPaginationParams,
    storeList,
    setTriggerStatusUpdate
) => {
    const [visibleColumns, setVisibleColumns] = useState(REFERRAL_COLUMN_MAPPER);
    const [filterData, setFilterData] = useState({ [CJ_REFERRAL_KEYS.STATUS]: predefinedStatusValues, [CJ_REFERRAL_KEYS.STORE]: [] });

    useEffect(() => {
        if(storeList?.length > 0 && filterData[CJ_REFERRAL_KEYS.STORE]?.length == 0) {
            setFilterData((filters) => ({
                ...filters,
                [CJ_REFERRAL_KEYS.STORE]: storeList
            }))
        }
    }, [storeList])

    useEffect(() => {
        setVisibleColumns((columns) => ({
            ...columns,
            [CJ_REFERRAL_KEYS.STORE]: {
                ...columns[CJ_REFERRAL_KEYS.STORE],
                columnMenu: (props) => (
                    <ColumnMenuRadioFilter
                        {...props}
                        filterData={filterData}
                        columnMapKey={CJ_REFERRAL_KEYS.STORE}
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        title={'Store List'}
                    />
                ),
            },
            [CJ_REFERRAL_KEYS.STATUS]: {
                ...columns[CJ_REFERRAL_KEYS.STATUS],
                columnMenu: (props) => (
                    <ColumnMenuRadioFilter
                        {...props}
                        filterData={filterData}
                        columnMapKey={CJ_REFERRAL_KEYS.STATUS}
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        title={'Status List'}
                    />
                ),
                cell: (props) => (
                    <StatusManager
                        {...props}
                        key={props?.dataItem?.id}
                        setReferralDetails={setReferralDetails}
                        columnMapKey={CJ_REFERRAL_KEYS.STATUS}
                        setTriggerStatusUpdate={setTriggerStatusUpdate}
                    />
                ),
            }
        }));
    }, [referralData, filterData, gridFilters, setReferralDetails, filterParams, setFilterParams]);

    return visibleColumns;
};
