import { Button } from '@progress/kendo-react-buttons';
import { MultiViewCalendar } from '@progress/kendo-react-dateinputs';
import { useState } from 'react';

import { formatToReadableDate } from '../../common/utils/utils';

const DateRangeCalendar = ({ onApply, onClose, startDate, endDate }) => {

  const pathname = window.location.pathname;

  const [state, setState] = useState({
    value: {
      start: new Date(startDate) || null,
      end: new Date(endDate) || null,
    },
    mode: "range",
  });

  const handleChange = (event) => {
    let currentValue = event.value;
    setState({
      ...state,
      value: currentValue,
    });
  };

  return (
    <div className="calendar-container">

      {state?.value?.start ?
        <div className='calendar-header'>
          <span className='selected-date-text'>{formatToReadableDate(state.value.start)} - {formatToReadableDate(state?.value?.end)} </span>
        </div>
        : null}

      <MultiViewCalendar
        mode={state.mode}
        value={state.value}
        onChange={handleChange}
        views={1}
        topView='year'
        className="calendar-body"
        min={pathname.includes('my-dealers') ? new Date() : undefined}
        max={!pathname.includes('my-dealers') ? new Date() : undefined}
      />
      <div className="calendar-footer">
        <Button fillMode="outline" className="cancel-button" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!state.value.end} className="apply-button" onClick={() => onApply(state.value.start, state.value.end)}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default DateRangeCalendar;
