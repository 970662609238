import React, { useState } from "react";
import styles from "./DialPad.module.css";

function DialPad({ sendDTMF }) {

  const [pin,setPin] = useState('')

  const handleButtonClick = (digit) => {
    if(pin?.length<4){
      setPin(pin+digit.toString())
      sendDTMF(digit.toString()); // Call the sendDTMF function with the digit
    }
  };

  return (
    <div className={styles['dial-pad']}>
      <div className={styles.pin}>
        {pin}
      </div>
      <div className={styles['buttons']}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"].map((digit) => (
          <button key={digit} onClick={() => handleButtonClick(digit)}>
            {digit}
          </button>
        ))}
      </div>
    </div>
  );
}

export default DialPad;
