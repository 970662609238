import { getFFUserType } from "../utils/utils";

export default (api) => {
	const fetchVerifications = (params = "") => {
		const url = `api/v1/challan-verification/list`;
		return new Promise((resolve, reject) => {
			api
				.get(`${url}${params}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const assignMeOpsVerification = (params) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-verification/assign`, params, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const fetchChallanItems = (params = {}) => {
		return new Promise((resolve, reject) => {
			api
				.get(`/api/v1/challan-verification/details/${params}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const submitOpsVerificationDetails = (params, payload) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/challan-verification/submit/${params}`, payload, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const fetchUploadProofMultipleFileLink = (id, payload) => {
		return new Promise((resolve, reject) => {
			api
				.get(`api/v1/challan-request/download/${id}?file-path=${payload}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const getDisposeProof = (params) => {
		return new Promise((resolve, reject) => {
			api
				.get(`api/v1/challan-item/download/${params}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const getExceptionProof = (params) => {
		return new Promise((resolve, reject) => {
			api
				.get(`api/v1/challan-item/download/exception-proof/${params}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const getGovernmentFeeProof = (params) => {
		return new Promise((resolve, reject) => {
			api
				.get(`api/v1/challan-item/download/government-fee/${params}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const fetchAllVerificationRejectReason = () => {
		return new Promise((resolve, reject) => {
			api
				.get(`/api/v1/challan-data/verification-reject-reason/all`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	return {
		fetchVerifications,
		assignMeOpsVerification,
		fetchChallanItems,
		submitOpsVerificationDetails,
		fetchUploadProofMultipleFileLink,
		getDisposeProof,
		getExceptionProof,
		getGovernmentFeeProof,
		fetchAllVerificationRejectReason
	};
};
