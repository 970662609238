import React, { useEffect, useState } from 'react';
import CustomGrid from '../../../../../common/custom-grid/component';
import { PRICE_COHORT_COLUMN_MAP } from './viewModal';
import { PRICE_COHORT_COLUMN_KEYS } from '../custom-pricing-list/viewModal';
import { useVisibleColumns } from './hooks/useVisibleColumns';
import CustomPricing from '../custom-pricing';
import { useFetchPriceCohort } from './hooks/useFetchPriceCohort';

const PRICE_COHORT_DEFAULT_PAGE_SIZE = 10;
const PRICE_COHORT_INITIAL_FILTER = {
    filters: [],
    logic: "and",
};

const SORT_DIRECTION_KEYS = {
    DESC: "desc",
    ASC: "asc",
};

const INITIAL_SORT = [
    {
        field: PRICE_COHORT_COLUMN_KEYS.UPDATED_AT,
        dir: SORT_DIRECTION_KEYS.DESC,
    }
];

const ALLOWED_SORT_PARAMS = [
    PRICE_COHORT_COLUMN_KEYS.UPDATED_AT,
];

function CustomPricingList({ cohortScreen, setCohortScreen, cohortFormData, setCohortFormData, isUpdateCohort, setIsUpdateCohort }) {

    const getDisplayedColumns = (columnMap) => {
        return Object.keys(columnMap);
    }

    const [paginationParams, setPaginationParams] = useState({ pageNumber: 0, pageSize: PRICE_COHORT_DEFAULT_PAGE_SIZE });
    const [filterParams, setFilterParams] = useState({});
    const [gridFilters, setGridFilters] = useState(PRICE_COHORT_INITIAL_FILTER);
    const [sortParms, setSortParms] = useState(INITIAL_SORT);
    const [triggerList, setTriggerList] = useState(false);

    const { data, totalCount } = useFetchPriceCohort(paginationParams, filterParams, sortParms, triggerList);
    const visibleColumns = useVisibleColumns(gridFilters, filterParams, setFilterParams, setCohortScreen, setCohortFormData, setIsUpdateCohort);
    const pageChange = ({ page = 0, size = PRICE_COHORT_DEFAULT_PAGE_SIZE }) => {
        setPaginationParams({ pageNumber: page, pageSize: size });
    };

    useEffect(() => {
        if(paginationParams.pageNumber != 0) {
            setPaginationParams({ pageNumber: 0, pageSize: PRICE_COHORT_DEFAULT_PAGE_SIZE })
        }
    }, [filterParams, sortParms])

    const sortChange = (e) => {
        let sortData = !!e?.sort.length ? e?.sort : INITIAL_SORT;
        let sortKey = sortData[0]?.field;
        let sortDir = sortData[0].dir;
        if (!e?.sort?.length) {
            sortDir =
                sortParms[0].dir === SORT_DIRECTION_KEYS.DESC
                    ? SORT_DIRECTION_KEYS.ASC
                    : SORT_DIRECTION_KEYS.DESC;
        }
        if (ALLOWED_SORT_PARAMS.includes(sortKey)) {
            setSortParms([{ field: sortData[0].field, dir: sortDir }]);
        }
    };

    return (
        <div>

            <CustomGrid
                displayedColumns={getDisplayedColumns(PRICE_COHORT_COLUMN_MAP)}
                data={data}
                count={totalCount}
                columnMap={visibleColumns}
                isPagination={true}
                paginationParams={paginationParams}
                pageChange={pageChange}
                sortable={true}
                sortParms={sortParms}
                onSortChange={sortChange}
            />

            {cohortScreen && <CustomPricing setCohortScreen={setCohortScreen} cohortFormData={cohortFormData} isUpdateCohort={isUpdateCohort} setTriggerList={setTriggerList} />}

        </div>
    )
}

export default CustomPricingList