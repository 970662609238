import React from 'react';
import styles from '../styles.module.css';
import { thumborUrl } from '../../../../utils/constants/url.constants';

export const ProfilePicCell = (event) => {

    const imageUrl = event.dataItem?.profilePic;

    return (
        <td>
            {imageUrl &&
                <a href={`${thumborUrl()}${imageUrl}`} target='_blank'>
                    <span className={styles["image-text"]}>Profile Image</span>
                </a>
            }
        </td>
    );
};