import React, { useState, useEffect } from "react";
import {
  formatDateV2,
  getStatus,
  showToastMessage,
} from "../../../../../utils/utils";
import { Button } from "@progress/kendo-react-buttons";
import {
  REASON_DD,
  VERIFICATION_STATUS,
  // getDisplayedCoulmns,
  proofData,
  // sampleData,
  // verificationDetailsColumns,
  verification_detail_keys,
} from "./viewModels";
import { FormComboBox, KendoCard } from "../../../../../components";
// import CustomGrid from '../../../../../common/custom-grid/component';
import { GridDetailSectionWithCell } from "../../../../../common/grid-detail";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import VerificationDetailActions from "./actions";
import { Loader } from "../../../../../common/Loader";
import { VerficationService } from "../../../../../services";
// import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const VerificationDetails = (props) => {
  const {
    match: {
      params: { idChallanVerification },
    },
  } = props;
  const history = useHistory();
  const spreadsheetLink =
    "https://docs.google.com/spreadsheets/d/1eRPmHDPIs6ar9HCaQpZtPKgz8Cr0TsBfP6nxBzOyyi4/edit#gid=1941969572";
  const [isLoading, setIsLoading] = useState(false);
  const [challanItemData, setChallanItemData] = useState([]);
  const [verficationStatus, setVerficationStatus] = useState();
  const [rejectReason, setRejectReason] = useState();

  const {
    appointmentOrderId,
    createdAt,
    idChallanItem,
    fkChallanRequest,
    exceptionProof,
  } = challanItemData["challan-item"] || {};
  const { status } = challanItemData["verification-item"] || {};
  const statusData = getStatus(status);

  useEffect(() => {
    setIsLoading(true);
    VerficationService.fetchChallanItems(idChallanVerification)
      .then((resp) => {
        let updatedRes = resp?.detail || {};
        updatedRes["challan-item"].createdAt = formatDateV2(
          updatedRes["challan-item"].createdAt
        );
        setChallanItemData(updatedRes);
      })
      .catch(() => {
        setChallanItemData({});
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [idChallanVerification]);

  const handleOnSubmit = () => {
    if (!verficationStatus) {
      showToastMessage("Please select Verification Status", false);
    } else if (verficationStatus === "REJECTED" && !rejectReason) {
      showToastMessage("Please select Reason", false);
    } else {
      let formData = new FormData();
      formData.append("status", verficationStatus);
      verficationStatus === "REJECTED" &&
        formData.append("reason", rejectReason);
      VerficationService.submitOpsVerificationDetails(
        idChallanVerification,
        formData
      )
        .then(() => {
          showToastMessage("Submitted");
          history.push(`/operations/verification`);
        })
        .catch((err) => showToastMessage(err?.message, false));
    }
  };

  const handleStatusChange = (e) => {
    setRejectReason("");
    setVerficationStatus(e?.value?.value);
  };

  const handleReasonChange = (e) => {
    setRejectReason(e?.value?.value);
  };

  const handleDownloadProof = (file) => {
    if (file === "find") {
      VerficationService.getFinderProof(fkChallanRequest)
        .then((res) => {
          window.open(res?.detail, "_blank");
        })
        .catch((err) => showToastMessage(err?.message, false));
    } else if (file === "dipose") {
      VerficationService.getDisposeProof(idChallanItem)
        .then((res) => {
          window.open(res?.detail, "_blank");
        })
        .catch((err) => showToastMessage(err?.message, false));
    } else {
      VerficationService.getExceptionProof(idChallanItem)
        .then((res) => {
          window.open(res?.detail, "_blank");
        })
        .catch((err) => showToastMessage(err?.message, false));
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <VerificationDetailActions noticeNumber={1} />
          <div className="service-history-wrapper">
            <div className="service-history-top-banner">
              <div className="order-id-info">
                <div className="order-id">
                  <span className="order-id-label">
                    Appointment OrderId : {appointmentOrderId}
                  </span>
                  <span className="order-id-date">
                    Creation Date: {formatDateV2(createdAt)}
                  </span>
                </div>
                <span className={`${statusData?.css}`}>
                  {statusData?.label}
                </span>
              </div>
              <Button
                className="m-2 service-detail-cta"
                themeColor={"primary"}
                onClick={handleOnSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
          <KendoCard
            headerTitle={"Challan Details"}
            details={
              <GridDetailSectionWithCell
                // title="Dealer Centre List"
                // displayedColumns={getDisplayedCoulmns()}
                dataList={[challanItemData["challan-item"]] || []}
                columnList={verification_detail_keys}
              />
            }
          />
          <KendoCard
            details={
              <GridLayout
                gap={{
                  rows: 2,
                  cols: 10,
                }}
              >
                <GridLayoutItem col={1}>
                  <KendoCard
                    headerTitle={"Attachments"}
                    cardStyle={{
                      background: "#F0F2F5",
                      minHeight: "250px",
                    }}
                    details={
                      <>
                        <div className="card-label download-proof">
                          Find Proof:{" "}
                          <Button onClick={() => handleDownloadProof("find")}>
                            {proofData.FIND_PROOF}
                          </Button>
                        </div>
                        <div className="card-label download-proof">
                          Dispose Proof:{" "}
                          <Button onClick={() => handleDownloadProof("dipose")}>
                            {proofData.DISPOSE_PROOF}
                          </Button>
                        </div>
                        <div className="card-label download-proof">
                          Exception Proof:{" "}
                          <Button
                            onClick={() => handleDownloadProof("exception")}
                            disabled={!exceptionProof}
                          >
                            {proofData.EXCEPTION_PROOF}
                          </Button>
                        </div>
                      </>
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem col={2}>
                  <KendoCard
                    headerTitle={"Action"}
                    cardStyle={{
                      minHeight: "250px",
                    }}
                    details={
                      <>
                        <div className="card-label">
                          Is Exception?
                          <div>{!!exceptionProof ? "Yes" : "No"}</div>
                        </div>
                        <GridLayout
                          gap={{
                            rows: 2,
                            cols: 10,
                          }}
                        >
                          <GridLayoutItem col={1}>
                            <FormComboBox
                              key={"Verification Status"}
                              onChange={handleStatusChange}
                              data={VERIFICATION_STATUS}
                              textField="label"
                              dataItemKey="value"
                              label={"Verification Status"}
                              id={"verificationStatus"}
                              // disabled={status !== "ASSIGNED"}
                              value={{
                                label: verficationStatus,
                                value: verficationStatus,
                              }}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem col={2}>
                            {verficationStatus === "REJECTED" && (
                              <FormComboBox
                                key={"Reason"}
                                label={"Reason"}
                                onChange={handleReasonChange}
                                data={REASON_DD}
                                textField="label"
                                dataItemKey="value"
                                id={"reason"}
                                value={{
                                  label: rejectReason,
                                  value: rejectReason,
                                }}
                              />
                            )}
                          </GridLayoutItem>
                        </GridLayout>
                        <div className="card-label">
                          Agent Costing Sheet Reference{" "}
                          <div>
                            <a
                              href={spreadsheetLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Agent costing sheet.xls
                            </a>
                          </div>
                        </div>
                      </>
                    }
                  />
                </GridLayoutItem>
              </GridLayout>
            }
          />
        </>
      )}
    </>
  );
};

export default VerificationDetails;
