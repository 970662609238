const FormulaItemChip = (props) => {
	return (
		<span
			id={props?.id}
			name={props?.name}
			style={{
				background: "#EBF5FF",
				padding: "3px 5px 3px 5px",
				borderRadius: "5px",
				margin: "4px",
				border: "1px solid #C3C3C3",
				cursor: "pointer",
			}}
			onClick={props?.onClickHandler}>
			{props?.label}
		</span>
	);
};

export default FormulaItemChip;
