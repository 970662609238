import { cj_incentive_keys } from "../../../view-modal";
import FormulaChip from "../formula-chip";
import { FormTextArea } from "../../../../../../components";
import {
	isValidEquation,
	isValidParams,
} from "../../../../utils/expression-validator";
import { Error } from "@progress/kendo-react-labels";
import { useEffect, useRef, useState } from "react";
import {
	ARTHEMETIC_OPERATORS,
	SYSTEM_METRICS,
} from "../forumula-builder/viewModal";
import styles from "../../../../assets/style/inspection.module.css";
import { CjIncentivesService } from "../../../../../../services";
const FormulaTextArea = (props) => {
	const {
		isSelected = false,
		value = "",
		name,
		index,
		placeholder,
		onFormulaChipClick,
		onInputLostFocus,
		onEditFormula,
		metrices,
		...others
	} = props;

	const inputRef = useRef(null);
	const data = [...Object.values(ARTHEMETIC_OPERATORS), ...metrices];
	const [suggestions, setSuggestions] = useState([]);
	const [isValidExpression, setIsValidExpression] = useState(true);
	const [isSuggestionSelected, setIsSuggestionSelected] = useState(false);
	const type = name === cj_incentive_keys.FORMULA_EXP ? "Condition" : "Value";

	useEffect(() => {
		const fetchUserSuggestions = () => {
			const searchTerm = getLastWordAfterSpace(value, true);
			if (searchTerm.trim() === "") {
				setSuggestions([]);
				return;
			}
			const filteredData = data.filter((item) =>
				item.toLowerCase().includes(searchTerm.toLowerCase())
			);
			setSuggestions(filteredData.map((item) => ({ name: item })));
		};
		if (isSuggestionSelected) {
			setSuggestions([]);
		} else {
			fetchUserSuggestions();
		}
	}, [isSuggestionSelected, value, name]);

	const getLastWordAfterSpace = (inputString, getLastValue = true) => {
		// Split the input string by spaces
		const words = inputString.split(/\s+/);

		// Return the last word or the rest of the string based on the parameter
		if (getLastValue) {
			return words[words.length - 1];
		} else {
			words.pop(); // Remove the last word
			return words.join(" "); // Join the remaining words back into a string
		}
	};

	const onFormulaLostFocus = (e) => {
		const { value } = e?.target;
		checkValidExpression(value);
	};

	const onSuggestionClick = (e, index) => {
		setIsSuggestionSelected(true);
		const { name, value } = e?.target;
		checkValidExpression(value);
		onEditFormula(e, index);
		setSuggestions([]);
	};

	const checkValidExpression = (value) => {
		let isExpressionValid = true;
		if (!!value) {
			if (isValidParams(value, data)) {
				if (isValidEquation(value)) {
					CjIncentivesService.validateExpression(value, type).then((resp) => {
						isExpressionValid = resp?.detail;
						console.log({ name, value, state: "BE Invalid equation" });
					});
				} else {
					isExpressionValid = false;
					console.log({ name, value, state: "Invalid equation" });
				}
			} else {
				isExpressionValid = false;
				console.log({ name, value, state: "Invalid params" });
			}
		}
		setIsValidExpression(isExpressionValid);
	};
	return (
		<div
			style={{
				marginBottom: "10px",
			}}>
			{isSelected ? (
				<>
					<FormTextArea
						ref={inputRef}
						key={`${name}-${index}-text`}
						label={""}
						id={name}
						name={name}
						value={value}
						onBlur={onFormulaLostFocus}
						onChange={(e) => onEditFormula(e, index)}
						placeholder={placeholder}
						{...others}
					/>
					{!!suggestions.length && (
						<ul className={styles.suggestionsList}>
							{suggestions.map((user, indexKey) => (
								<li
									key={indexKey}
									onClick={(e) => {
										onSuggestionClick(
											{
												target: {
													value:
														getLastWordAfterSpace(value, false) +
														" " +
														user.name,
													name: cj_incentive_keys.FORMULA_EXP,
												},
											},
											index
										);
									}}>
									<span>{user.name}</span>
								</li>
							))}
						</ul>
					)}
				</>
			) : (
				<FormulaChip
					key={`${name}-${index}-chip`}
					id={name}
					name={name}
					expression={value}
					onFormulaClick={() => onFormulaChipClick(name, index)}
					placeholder={placeholder}
				/>
			)}
			{!!value && !isValidExpression && (
				<Error id={`${name}-${index}-error`}>Invalid formula</Error>
			)}
		</div>
	);
};
export default FormulaTextArea;
