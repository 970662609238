import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { CONSENT_FORM_DATA } from "./view-models"
import useLazyQuery from "../../../../../../../hooks/useLazyQuery"
import { commonApis } from "../../../../../../../services/commonApi"
import FormCardLayout from "../../../../../../operations/components/layout-form-card"
import { FORM_ELEMENT_TYPE } from "../../../../../../../utils/constants/values.constants"
import { setDefaultValueToForm, showToastMessage } from "../../../../../../../utils/utils"

const ConsentForm = forwardRef((props, ref) => {

    const { data } = props
    const [formData, setFormData] = useState(CONSENT_FORM_DATA)
    const { apiCbFunction: sendConsentGenerationApi, data: sendConsentGenerationApiData, isLoading: sendConsentGenerationApiLoading } = useLazyQuery(commonApis.sendConsentGeneration)

    useImperativeHandle(ref, () => ({
        formData
    }));

    const extraFormData = {
        ['CONSENT_GENERATION']: {
            name: 'CONSENT_GENERATION',
            label: "Send Message for Consent Generation",
            elementtype: FORM_ELEMENT_TYPE.BUTTON,
            buttonTitle: sendConsentGenerationApiLoading ? 'Loading' : 'Send',
            hide: !!data?.consent ?? true,
            onClick: () => {
                if (!sendConsentGenerationApiLoading)
                    sendConsentGenerationApi({
                        body: {
                            "appointmentId": data?.appointmentId,
                            "requestType": 'CREDIT_REPORT_CONSENT_REQUEST'
                        }
                    })
            }
        },
    }

    const onInputChange = (e) => {
        const { value } = e;
        const { id } = e.target.props || {};
        const key = id || e.target.name;
        let updatedPopupData = {
            ...formData,
            [key]: {
                ...formData[key],
                ...{ ["value"]: value },
            },
        };
        setFormData(updatedPopupData);
    };

    useEffect(() => {
        if (data) {
            setFormData(prevFormData => setDefaultValueToForm(prevFormData, data));
        }
    }, [data]);

    useEffect(() => {
        if (sendConsentGenerationApiData) {
            if (sendConsentGenerationApiData?.status == 200)
                showToastMessage(sendConsentGenerationApiData?.message, true);
            else
                showToastMessage('Some error occured', false);
        }
    }, [sendConsentGenerationApiData])


    return (
        <FormCardLayout
            formData={{ ...formData, ...extraFormData }}
            isShowCTA={false}
            handleChange={onInputChange}
            cardStyle={{ border: "0px solid #EBF5FF", minHeight: '0px', marginTop: '0px' }}
        />
    )
})

export default ConsentForm