import React from 'react'
import styles from './richTextViewer.module.css';

function RichTextViewer(event) {

    let { message } = event?.dataItem;

    return (
        <td>
            <div className={styles["truncated-content-wrapper"]}>
                <div className={styles["truncated-content"]} dangerouslySetInnerHTML={{ __html: message }} />
            </div>
        </td>
    )
}

export default RichTextViewer