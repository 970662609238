import React, { useEffect, useState } from "react";
import { FF_PAGE_TYPES } from "../../../utils/constants/values.constants";
import { getUserRoles, getPageName } from "../../../utils/utils";
import { USER_ROLE_KEYS } from "../../../utils/constants/menu.constants";
import FinderContainerView from "../finder-container";
import DisposeContainerView from "../dispose-container";
import "../assets/style/challan-layout.css";
const ChallanHome = (props) => {
	const [pageKey, setPageKey] = useState("");

	const getPageTitle = () => {
		const userGroups = getUserRoles() || [];
		const pageName = getPageName();
		if (
			(userGroups.includes(USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN) &&
				pageName === FF_PAGE_TYPES.FINDERS) ||
			(userGroups.includes(USER_ROLE_KEYS.FINDER) &&
				pageName === FF_PAGE_TYPES.COMMON)
		) {
			return FF_PAGE_TYPES.FINDERS;
		} else if (
			(userGroups.includes(USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN) &&
				pageName === FF_PAGE_TYPES.DISPOSE) ||
			(userGroups.includes(USER_ROLE_KEYS.DISPOSE) &&
				pageName === FF_PAGE_TYPES.COMMON)
		) {
			return FF_PAGE_TYPES.DISPOSE;
		} else if (
			userGroups.includes(USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_VIEW) &&
			pageName === FF_PAGE_TYPES.COMMON
		) {
			return FF_PAGE_TYPES.DISPOSE_VIEW;
		}
	};

	useEffect(() => {
		const title = getPageTitle();
		setPageKey(title);
	}, []);

	const renderView = () => {
		switch (pageKey) {
			case FF_PAGE_TYPES.FINDERS:
				return <FinderContainerView type={FF_PAGE_TYPES.FINDERS} />;
			case FF_PAGE_TYPES.DISPOSE:
				return <DisposeContainerView type={FF_PAGE_TYPES.DISPOSE} />;
			case FF_PAGE_TYPES.DISPOSE_VIEW:
				return <DisposeContainerView type={FF_PAGE_TYPES.DISPOSE_VIEW} />;
			default:
				return <></>;
		}
	};
	return renderView();
};

export default ChallanHome;
