import React, { useState } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { InspectionReferralService } from '../../../../../../../services';
import { showToastMessage } from '../../../../../../../utils/utils';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { FormInput } from '../../../../../../../components';

export const predefinedStatusValues = [
    { label: 'Referral Sent', value: 'REFERRAL_SENT' },
    { label: 'Referral Declined', value: 'REFERRAL_DECLINED' },
    { label: 'Referral Earned', value: 'REWARD_EARNED' },
    { label: 'CJ Joined', value: 'CJ_JOINED' },
    { label: 'Under Evaluation', value: 'UNDER_EVALUATION' },
    { label: 'On Hold', value: 'ON_HOLD' },
];

const statusStyles = {
    REFERRAL_SENT: { backgroundColor: '#FFF1D6', border: '1px solid #FFF1D6', color: '#F6A70A' },
    REFERRAL_DECLINED: { backgroundColor: '#FFE2E2', border: '1px solid #FFA5A5', color: '#EB5757' },
    REWARD_EARNED: { backgroundColor: '#E5FEF3', border: '1px solid #ACE6CC', color: '#00BA67' },
    CJ_JOINED: { border: '1px solid #0D6EFD', color: '#0D6EFD' },
    UNDER_EVALUATION: { backgroundColor: '#FFFFFF', border: '1px solid #ACE6CC', color: '#00BA67' },
    ON_HOLD: { backgroundColor: '#FCE7D6', border: '1px solid #EF6E0B', color: '#EF6E0B' },
};

const StatusManager = ({ dataItem, field, setTriggerStatusUpdate }) => {
    const [status, setStatus] = useState(dataItem[field]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newStatus, setNewStatus] = useState(null);
    const [isJoinedStatusPopUp, setIsJoinedStatusPopUp] = useState(false);
    const [email, setEmail] = useState(null);
    const [code, setCode] = useState(null);

    const handleStatusChange = (e) => {
        const selectedStatus = e.target.value.value;
        setNewStatus(selectedStatus);
        if (selectedStatus == 'CJ_JOINED') {
            setIsJoinedStatusPopUp(true);
        } else {
            setIsDialogOpen(true);
        }
    };

    const confirmStatusChange = () => {
        setIsDialogOpen(false);
        InspectionReferralService.updateReferralStatus({ referralPhone: dataItem?.referralPhone, status: newStatus })
            .then((resp) => {
                setStatus(newStatus);
                showToastMessage('Status Updated Successfully');
            })
            .catch((err) => {
                showToastMessage('Error Updating the Status', false);
                console.log("Error Updating the Status: ", err);
            });
    };

    const getStatusStyles = (status) => {
        return statusStyles[status] || {};
    };

    const DISABLED_STATUS = ['REFERRAL_DECLINED', 'CJ_JOINED', 'REWARD_EARNED']

    const handleJoinedOnSubmit = () => {
        if(!code) {
            showToastMessage('Please enter Employee Code', false);
            return;
        }
        
        if(!email) {
            showToastMessage('Please enter Employee Email ID', false);
            return;
        }

        InspectionReferralService.updateReferralStatus({ referralPhone: dataItem?.referralPhone, status: newStatus, joinerEmployeeCode: code, joinerEmailId: email })
        .then((resp) => {
            setStatus(newStatus);
            setTriggerStatusUpdate((prev) => !prev);
            showToastMessage('Status Updated Successfully');
        })
        .catch((err) => {
            showToastMessage('Error Updating the Status', false);
            console.log("Error Updating the Status: ", err);
        });

        setIsJoinedStatusPopUp(false);
    }

    const handleJoinedClear = () => {
        setEmail(null);
        setCode(null);
        setIsJoinedStatusPopUp(false);
    }

    return (
        <td className="k-table-td" key={dataItem?.id}>
            <DropDownList
                data={predefinedStatusValues}
                textField="label"
                dataItemKey="value"
                value={predefinedStatusValues.find(item => item.value === status)}
                onChange={handleStatusChange}
                style={getStatusStyles(status)}
                disabled={DISABLED_STATUS.includes(status)}
            />
            {isDialogOpen && (
                <Dialog title={`Status Update`} width={350} onClose={() => setIsDialogOpen(false)}>
                    <p style={{ textAlign: "center", width: "310px" }}>
                        Are you sure you want to change <br /> the status to "{newStatus}"?
                    </p>
                    <DialogActionsBar>
                        <Button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={() => setIsDialogOpen(false)}
                        >
                            Reset
                        </Button>
                        <Button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={confirmStatusChange}
                            themeColor={"primary"}
                        >
                            Confirm
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            )}
            {
                isJoinedStatusPopUp &&
                <Dialog title={"Status Update for CJ Joining"} onClose={() => setIsJoinedStatusPopUp(false)}>
                    <GridLayout>
                        <GridLayoutItem row={1}>
                            <FormInput
                                id={"joinerEmployeeCode"}
                                key={"joinerEmployeeCode"}
                                onChange={(e) => setCode(e.target.value)}
                                value={code}
                                label={"Employee Code"}
                                placeholder={"Enter Employee Code"}
                            />
                        </GridLayoutItem>
                        <GridLayoutItem row={2}>
                            <FormInput
                                id={"joinerEmailId"}
                                key={"joinerEmailId"}
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                label={"Employee Email ID"}
                                placeholder={"Enter Employee Email ID"}
                            />
                        </GridLayoutItem>
                    </GridLayout>

                    <DialogActionsBar>
                        <Button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleJoinedClear}
                        >
                            Reset
                        </Button>
                        <Button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleJoinedOnSubmit}
                            themeColor={"primary"}
                        >
                            Confirm
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            }
        </td>
    );
};

export default StatusManager;
