import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from '@progress/kendo-react-inputs';

import CrossIcon from '../../assets/images/crossIcon';
import { ICON_COLOR } from '../../constants/color.constants';
import './notification.scss';
import { getImportantNotificationCount, getNotificationData, markNotificationAsImportant, markNotificationAsSeen, setNotificationModal } from '../../../store/actions/myDealers';
import SnackBar from '../snackbar';
import { INITIAL_TABLE_SETTINGS } from '../../constants/table.constants';
import { Loader } from '@progress/kendo-react-indicators';
import { showToastMessage } from '../../utils/utils';
import { setNotificationCount } from '../../../store/actions/allCars';

const Notification = () => {
  const { userInfo, unseenNotificationCount } = useSelector((state) => state.allCars);
  const { isLoading } = useSelector((state) => state.appLoader);
  const dispatch = useDispatch();
  const [notificationData, setNotificationData] = useState([]);
  const [isImportant, setIsImportant] = useState(false);
  const [importantNotificationCount, setImportantNotificationCount] = useState();
  const modalRef = useRef(null);
  const [page, setPage] = useState(0);

  const observerRef = useRef(null);
  const inViewCountRef = useRef([])


  const handleVisibilityChange = (notificationId) => {
    if(!inViewCountRef.current.includes(notificationId)){
      
       inViewCountRef.current.push(notificationId);
    }
  };

  useEffect(() => {
    dispatch(getImportantNotificationCount({ id: userInfo?.id })).then((resp) => {
      setImportantNotificationCount(resp.importantCount);
    });

    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        dispatch(setNotificationModal(false));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (inViewCountRef.current.length > 0) {
        dispatch(setNotificationCount(unseenNotificationCount - inViewCountRef.current.length));
        dispatch(markNotificationAsSeen({ id: userInfo?.id, notificationIds: inViewCountRef.current }));
      }

    };

  }, [modalRef])


  useEffect(() => {
    if (userInfo) {
      dispatch(getNotificationData({ id: userInfo?.id, isImportant, page: page, size: INITIAL_TABLE_SETTINGS.take })).then((resp) => {
        setNotificationData((prev) => {
          if (prev?.data?.length > 0) {
            return {
              data: [...prev.data, ...resp.data],
              pagination: resp.pagination
            }
          }
          return {
            data: [...resp.data],
            pagination: resp.pagination
          }
        })
      });
    }
  }, [userInfo, isImportant, page]);




  const handleToggleSwitch = () => {
    setPage(0);
    setIsImportant(!isImportant);
    setNotificationData([]);
  };


  const handleImportant = (notificationId) => {
    let isImportant;
    const receiverId = userInfo?.id;

    const updatedUnseenNotifications = notificationData?.data?.map((item) => {
      if (item.id === notificationId) {
        isImportant = !item.isImportant;
        return { ...item, isImportant };
      }
      return item;
    });

    dispatch(markNotificationAsImportant({
      notificationId,
      isImportant,
      receiverId
    })).then(() => {
      setImportantNotificationCount((prev) => {
        return isImportant ? prev + 1 : prev - 1
      })
    }).catch((err) => {
      showToastMessage(err.message, false);
    });

    setNotificationData((prev) => {
      return {
        ...prev,
        data: updatedUnseenNotifications
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && notificationData?.pagination?.totalPages > page + 1) {
          setPage(page + 1);
        }
      },
      {
        threshold: 0.1
      }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [notificationData]);


  return (
    <div className="notification-container" ref={modalRef} >
      <div className="notification-header">
        <span className="notification-title">All Notification</span>
        <div className="important-notification">
          <Switch className="notification-switch" onChange={handleToggleSwitch} isImportant={isImportant} />
          <span className="imp-title">Only show important </span>
          <span className='imp-count'>{importantNotificationCount}</span>
          <div
            className="cross-icon"
            onClick={() => {
              dispatch(setNotificationModal(false));
            }}
          >
            <CrossIcon fill={ICON_COLOR} />
          </div>
        </div>
      </div>

      <div className="notification-body">
        {notificationData?.data?.length > 0 && (
          <>
            {notificationData?.data?.map((notification) => (
              <>
                <SnackBar
                  key={notification.id}
                  handleImportant={handleImportant}
                  notificationData={notification}
                  onVisibilityChange={handleVisibilityChange}
                />
                <div className="notification-divider" />
              </>
            ))}

          </>

        )}

        <div className='notification-loader' ref={observerRef}>{isLoading ? <Loader type='infinite-spinner' /> : null}</div>


      </div>
    </div>
  );
};

export default Notification;
