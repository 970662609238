import React, { useEffect, useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { FormComboBox, FormInput, FormMultiSelect, FormNumericTextBox } from '../../../../../components';
import { PRICE_COHORT_FORM_DATA, PRICE_COHORT_KEYS } from './viewModal';
import { FORM_ELEMENT_TYPE } from '../../../../../utils/constants/values.constants';
import { showToastMessage } from '../../../../../utils/utils';
import { VasService, VehicleService } from '../../../../../services';
import { PRICE_COHORT_COLUMN_KEYS, PRICE_RANGES } from '../custom-pricing-list/viewModal';

function CustomPricing({ setCohortScreen, cohortFormData, isUpdateCohort, setTriggerList }) {

    const [formData, setFormData] = useState(PRICE_COHORT_FORM_DATA);

    const findMatchingRange = (lower, upper) => {
        return PRICE_RANGES.find(range =>
            range.value.lower === lower && range.value.upper === upper
        );
    };

    useEffect(() => {
        if(isUpdateCohort) {
            setFormData({
                ...formData,
                [PRICE_COHORT_KEYS.RANGE]: {
                    ...formData[PRICE_COHORT_KEYS.RANGE],
                    elementType: FORM_ELEMENT_TYPE.SELECT
                }
            })
        }
    },[isUpdateCohort])
    
    useEffect(() => {

        if(isUpdateCohort) {
            const matchingRange = findMatchingRange(cohortFormData.carPriceLowerRange, cohortFormData.carPriceUpperRange);

            setFormData(prevData => ({
                ...prevData,
                [PRICE_COHORT_KEYS.TITLE]: { ...prevData[PRICE_COHORT_KEYS.TITLE], value: cohortFormData.configName },
                [PRICE_COHORT_KEYS.STATE]: { ...prevData[PRICE_COHORT_KEYS.STATE], value: { label: cohortFormData.stateName, value: cohortFormData.stateId } },
                [PRICE_COHORT_KEYS.CITY]: { ...prevData[PRICE_COHORT_KEYS.CITY], value: { label: cohortFormData.cityName, value: cohortFormData.cityId } },
                [PRICE_COHORT_KEYS.RANGE]: { ...prevData[PRICE_COHORT_KEYS.RANGE], value: { label: matchingRange?.label, value: matchingRange?.value } },
                [PRICE_COHORT_KEYS.CONTROL_PRICE]: { ...prevData[PRICE_COHORT_KEYS.CONTROL_PRICE], value: cohortFormData.controlPrice },
                [PRICE_COHORT_KEYS.VARIANT_PRICE]: { ...prevData[PRICE_COHORT_KEYS.VARIANT_PRICE], value: cohortFormData.variantAPrice },
                [PRICE_COHORT_KEYS.VARIANT_TWO_PRICE]: { ...prevData[PRICE_COHORT_KEYS.VARIANT_TWO_PRICE], value: cohortFormData.variantBPrice },
                [PRICE_COHORT_KEYS.STATUS]: { ...prevData[PRICE_COHORT_KEYS.STATUS], value: { label: cohortFormData.status, value: cohortFormData.status } }
            }));
        }

        VehicleService.fetchStates()
            .then((resp) => {
                const stateData = resp.map((item) => ({ label: item?.state_name, value: item?.state_id }));
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setFormData((data) => ({
                    ...data,
                    [PRICE_COHORT_KEYS.STATE]: {
                        ...data[PRICE_COHORT_KEYS.STATE],
                        data: stateData
                    }
                }))
            })
            .catch((err) => {
                console.log('err', err)
            })
    }, [])

    useEffect(() => {
        if (formData[PRICE_COHORT_COLUMN_KEYS.STATE].value) {
            VehicleService.fetchCitiesById(formData[PRICE_COHORT_COLUMN_KEYS.STATE].value.value)
                .then((resp) => {
                    let cityData = resp.map((item) => ({ label: item?.city_name, value: item?.city_id }))
                    cityData.sort((a, b) => a?.label.localeCompare(b?.label));
                    setFormData((data) => ({
                        ...data,
                        [PRICE_COHORT_KEYS.CITY]: {
                            ...data[PRICE_COHORT_KEYS.CITY],
                            data: cityData
                        }
                    }))
                })
                .catch((err) => {
                    console.log('err', err)
                })
        }
    }, [formData[PRICE_COHORT_COLUMN_KEYS.STATE]])

    const handleChange = (e) => {
        const { value } = e;
        const { id } = e.target.props || {};
        const key = id || e.target.name;
        let parsedValue = formData[key]?.elementType === FORM_ELEMENT_TYPE.SELECT ? { label: value?.label, value: value?.value } : value;
        let updatedPopupData = {
            ...formData,
            [key]: {
                ...formData[key],
                value: parsedValue?.value ? parsedValue : FORM_ELEMENT_TYPE.MULTI_SELECT ? value : null
            },
            ...(key == PRICE_COHORT_KEYS.STATE && {
                [PRICE_COHORT_KEYS.CITY]: {
                    ...formData[PRICE_COHORT_KEYS.CITY],
                    data: null,
                    value: null
                }
            })
        };
        setFormData(updatedPopupData);
    };

    const handleConfirm = () => {
        const title = formData[PRICE_COHORT_KEYS.TITLE];
        const state = formData[PRICE_COHORT_KEYS.STATE];
        const city = formData[PRICE_COHORT_KEYS.CITY];
        const range = formData[PRICE_COHORT_KEYS.RANGE];
        const controlPrice = formData[PRICE_COHORT_KEYS.CONTROL_PRICE];
        const variantPrice = formData[PRICE_COHORT_KEYS.VARIANT_PRICE];
        const variantPrice2 = formData[PRICE_COHORT_KEYS.VARIANT_TWO_PRICE];
        const status = formData[PRICE_COHORT_KEYS.STATUS];

        if (!title.value) {
            showToastMessage('Please enter a unique Title', false);
            return;
        }

        if (!state.value) {
            showToastMessage('Please select a State', false);
            return;
        }

        if (!range.value || range.value.length < 1) {
            showToastMessage('Please select Car Price Range', false);
            return;
        }

        if (!controlPrice.value) {
            showToastMessage('Please enter Control Price', false);
            return;
        }

        if (!variantPrice.value) {
            showToastMessage('Please enter Variant Price', false);
            return;
        }

        if (!variantPrice2.value) {
            showToastMessage('Please enter Variant Price 2', false);
            return;
        }

        if (!status.value) {
            showToastMessage('Please select Status', false);
            return;
        }

        let cityObj = {};

        if(city.value) {
            cityObj = {
                cityId: city.value.value,
                cityName: city.value.label
            }
        }

        let priceRanges = isUpdateCohort ? range.value?.value : range.value.map((item) => ({ carPriceLowerRange: item.value.lower, carPriceUpperRange: item.value.upper }));

        let payload = {
            stateId: state.value.value,
            stateName: state.value.label,
            configName: title?.value,
            controlPrice: controlPrice?.value,
            variantAPrice: variantPrice?.value,
            status: status.value.value,
            ...(isUpdateCohort ? {
                carPriceLowerRange: priceRanges.lower,
                carPriceUpperRange: priceRanges.upper
            }: {
                priceRanges
            }),
            ...cityObj,
            ...(variantPrice2.value && { variantBPrice: variantPrice2?.value }),
        }

        const updateCall = isUpdateCohort ? 
            VasService.updatePriceCohortData('update', { id: cohortFormData.id, ...payload }) :
            VasService.updatePriceCohortData('create', payload);
            
        updateCall
        .then((resp) => {
            setFormData(PRICE_COHORT_FORM_DATA);
            setTriggerList((prev) => !prev);
            setCohortScreen(false);
            showToastMessage('Pricing Added Successfully');
        })
        .catch((err) => {
            showToastMessage(err?.message || 'Error Adding Pricing', false);
        })
    }

    const handleCancel = () => {
        setCohortScreen(false);
    }
    
    return (
        <Dialog
            title={'Add Custom CHR Pricing'}
            onClose={() => setCohortScreen(false)}
            width={700}
            contentStyle={{ width: "auto" }}
        >

            <GridLayout
                cols={[{ width: 300 }, { width: 300 }]}
                gap={{
                    rows: "20px",
                    cols: 40,
                }}>
                {Object.keys(formData)?.map((item, index) => {
                    return (
                        <GridLayoutItem col={(index % 2) + 1}>
                            {formData[item]?.elementType === FORM_ELEMENT_TYPE.SELECT ?
                                <FormComboBox
                                    {...formData[item]}
                                    id={item}
                                    key={item}
                                    onChange={handleChange}
                                    data={formData[item]?.data ?? []}
                                    textField="label"
                                    dataItemKey="value"
                                    value={formData[item]?.value}
                                    isDisplayLabel={true}
                                    label={formData[item]?.label}
                                    disabled={!formData[item]?.data || (item == PRICE_COHORT_KEYS.CITY && !formData[PRICE_COHORT_KEYS.STATE]?.value)}
                                /> :
                                formData[item]?.elementType === FORM_ELEMENT_TYPE.NUMERIC_TEXTBOX ?

                                    <FormNumericTextBox
                                        {...formData[item]}
                                        label={formData[item]?.label}
                                        id={item}
                                        key={item}
                                        onChange={handleChange}
                                        value={formData[item]?.value}
                                        min={0}
                                    />
                                    :
                                    formData[item]?.elementType === FORM_ELEMENT_TYPE.MULTI_SELECT ?
                                        <FormMultiSelect
                                            {...formData[item]}
                                            id={item}
                                            key={item}
                                            onChange={handleChange}
                                            data={formData[item].data}
                                            textField="label"
                                            dataItemKey="value"
                                            label={formData[item].label}
                                            isDisplayLabel={true}
                                            value={formData[item].value}
                                        />

                                        :
                                        <FormInput
                                            {...formData[item]}
                                            label={formData[item]?.label}
                                            id={item}
                                            key={item}
                                            onChange={handleChange}
                                            value={formData[item]?.value || ""}
                                        />
                            }

                        </GridLayoutItem>
                    )
                })}
            </GridLayout>
            <DialogActionsBar>
                <Button onClick={handleConfirm} themeColor={"primary"}>
                    Confirm
                </Button>
                <Button onClick={handleCancel} themeColor={"primary"}>
                    Go back
                </Button>
            </DialogActionsBar>
        </Dialog>
    )
}

export default CustomPricing
