import { useEffect, useState, useRef, useCallback } from 'react';
import './app-table.scss';
import { Grid, GridColumn, GridColumn as Column, getSelectedState, GridNoRecords } from '@progress/kendo-react-grid';
import AllCars from "../../../all-cars"
import { ALL_CARS_TABLE_VIEW, INITIAL_TABLE_SETTINGS } from "../.../../../constants/table.constants";
import { getter } from "@progress/kendo-react-common";
import { setDealerFilterQueryParam, setDealerFilterStatus, setSidebarDealerFilterApplied, setSidebarDealerFilterPayloads } from '../../../store/actions/myDealers';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { updateCarsFilters } from '../../../store/actions/allCars';
import { MY_DEALERS_CARS_SORT_FILTER, MY_DEALERS_CARS_VIEW_FILTER } from '../../constants/filters.constants';

export const DetailComponent = (props) => {

  const { dataItem } = props;

  return (
    dataItem?.expanded ? <div className='grid-detailed-view'><AllCars
      type={dataItem.type}
      dealerId={dataItem.dealerId}
      view={ALL_CARS_TABLE_VIEW.dealersOCBView} shareCar={props.shareCar} />  </div> : ''
  );
};


const AppTable = ({
  headerData = [],
  data,
  skip = INITIAL_TABLE_SETTINGS.skip,
  take = INITIAL_TABLE_SETTINGS.take,
  totalCount = 0,
  pageable = true,
  isHoverable = false,
  buttonCount = 4,
  handlePageChange,
  expandable = false,
  wrapperClassName,
  scrollTo = null,
  multiSelectEnabled = false,
  onRowSelection = () => { },
  resetSelection = false,
  markAllSelected = false,
  noRecords = false,
  tabSelected = 0,
}) => {


  const pageableSettings = {
    buttonCount,
  };
  const ADJUST_PADDING = 0;
  const SELECTED_FIELD = "selected";
  const DATA_ITEM_KEY = "id";
  const TL_VIEW_DATA_ITEM_KEY = "dealerId";
  const TL_VIEW_REASSIGNMENT_DATA_ITEM_KEY = "assignmentId";

  const idGetter = getter(DATA_ITEM_KEY);
  const tlIdGetter = getter(TL_VIEW_DATA_ITEM_KEY);
  const tlReassignIdGetter = getter(TL_VIEW_REASSIGNMENT_DATA_ITEM_KEY);

  const [gridData, setGridData] = useState(data);
  const [visibleColumnsLength, setVisibleColumnsLength] = useState(headerData.length);
  const newDealerSelectedState = {}
  const newReassignDealerSelectedState = {}

  const [selectedState, setSelectedState] = useState({});
  const [selectedDealerState, setSelectedDealerState] = useState({});

  const [selectReassignDealerState, setSelectReassignDealerState] = useState({});


  const dispatch = useDispatch();
  const history = useHistory();
  let params = new URLSearchParams(window.location.search);

  const expandChange = (event) => {

    const dealerCode = event?.dataItem?.dealerCode;


    if (params?.get('dealerCode') != dealerCode) {

      const page = params.get('page') || 1;
      params = new URLSearchParams();

      params.set('dealerCode', dealerCode);
      params.set('page', page);

      dispatch(setDealerFilterQueryParam(''));
      dispatch(setDealerFilterStatus(false));
      dispatch(setSidebarDealerFilterPayloads(null));
      dispatch(setSidebarDealerFilterApplied(null));
      dispatch(updateCarsFilters(
        {
          dealerCarSort: MY_DEALERS_CARS_SORT_FILTER[0],
          dealerCarCategory: MY_DEALERS_CARS_VIEW_FILTER[0],
          searchDealerCarQuery: ''
        }
      ))

      history.push({ pathname: window.location.pathname, search: params.toString() });

    }

    let newData = gridData.map((item) => {
      if (item?.dealerCode === event.dataItem?.dealerCode) {
        item.expanded = !event.dataItem.expanded;
      } else {
        item.expanded = false
      }
      return item;
    });

    setGridData([...newData]);
  };

  const updateElement = (ele, dealerCode) => {
    const code = ele?.dealerCode || ele?.dealerInfo?.dealerCode;
    if (code === dealerCode) {
      return {
        ...ele,
        expanded: true,
        selected: false,
      };
    }
    return {
      ...ele,
      expanded: false,
      selected: false,
    };
  };

  useEffect(() => {
    const dealerCode = params.get('dealerCode');
    const newData = (data || []).map((ele) => updateElement(ele, dealerCode));

    setGridData([...newData]);
    const length = newData.filter(d => d.show !== false).length;
    setVisibleColumnsLength(length);
  }, [data]);



  useEffect(() => {
    setSelectedState({});
    setSelectedDealerState({});
    setSelectReassignDealerState({});
  }, [resetSelection]);

  const minGridWidth = useRef(0);
  const grid = useRef(null);
  const [applyMinWidth, setApplyMinWidth] = useState(false);
  const [gridCurrent, setGridCurrent] = useState(0);

  useEffect(() => {
    grid.current = document.querySelector('.' + wrapperClassName);
    window.addEventListener('resize', handleResize);
    headerData.map(item => item?.minWidth !== undefined ? minGridWidth.current += item.minWidth : minGridWidth.current);
    setGridCurrent(grid.current.offsetWidth);
    setApplyMinWidth(grid.current.offsetWidth < minGridWidth.current);
  }, []);

  const handleResize = () => {
    if (grid.current.offsetWidth < minGridWidth.current) {
      setGridCurrent(grid.current.offsetWidth);
      setApplyMinWidth(grid.current.offsetWidth < minGridWidth.current);
    }
  };

  const setWidth = (minWidth, maxWidth) => {
    if (maxWidth) return maxWidth;
    minWidth === undefined ? minWidth = 0 : minWidth;
    let width = applyMinWidth ? minWidth : minWidth + (gridCurrent - minGridWidth.current) / visibleColumnsLength.length;
    width = width < minWidth ? minWidth : width - ADJUST_PADDING;

    return width < minWidth ? minWidth : (maxWidth && width > maxWidth ? maxWidth : width) || undefined;
  };


  const onSelectionChange = (event) => {

    if (event?.dataItem?.id && event?.dataItem.hasOwnProperty('carSharedWithDealer') && !event.dataItem.carSharedWithDealer) {

      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });

      setSelectedState(newSelectedState);
      onRowSelection(newSelectedState);

    }


    else if (event?.dataItem?.dealerCode) {

      const newSelectedState = getSelectedState({
        event,
        selectedState: tabSelected === 0 ? selectedDealerState : selectReassignDealerState,
        dataItemKey: tabSelected === 0 ? TL_VIEW_DATA_ITEM_KEY : TL_VIEW_REASSIGNMENT_DATA_ITEM_KEY,
      });

      if (tabSelected === 0) {
        setSelectedDealerState(newSelectedState);
        onRowSelection(newSelectedState);
      } else {
        setSelectReassignDealerState(newSelectedState);

        const assignmentId = event.dataItem.assignmentId;

        onRowSelection({
          assignmentId,
          ...newSelectedState
        })
      }

    }

  };


  const onHeaderSelectionChange = useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};

    event.dataItems.forEach((item) => {
      if (item.dealerCode) {
        if (tabSelected === 0) {
          newDealerSelectedState[tlIdGetter(item)] = checked;
        } else {
          newReassignDealerSelectedState[tlReassignIdGetter(item)] = checked;
        }
      }

      else {
        if (!item.carSharedWithDealer)
          newSelectedState[idGetter(item)] = checked;
      }
    });


    if (Object.keys(newSelectedState).length > 0) {
      setSelectedState(newSelectedState);
      onRowSelection(newSelectedState);

    }
    else {
      if (tabSelected === 0) {
        setSelectedDealerState(newDealerSelectedState);
        onRowSelection(newDealerSelectedState);
        return;
      }
      setSelectReassignDealerState(newReassignDealerSelectedState);
      const assignmentIds = event.dataItems.map((item) => item.assignmentId);

      onRowSelection({
        assignmentId: [...assignmentIds],
        ...newReassignDealerSelectedState
      })

    }

  }, [tabSelected]);


  useEffect(() => {
    if (markAllSelected) {
      let allIds = {};
      gridData.map((item) =>
        allIds = { ...allIds, [item[DATA_ITEM_KEY]]: true }
      );
      setSelectedState(allIds);
    }
  }, [markAllSelected]);

  return (
    <Grid
      data={multiSelectEnabled ? gridData.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedState[idGetter(item)] || (tabSelected === 0 ? selectedDealerState[tlIdGetter(item)] : selectReassignDealerState[tlReassignIdGetter(item)]),
      })) : gridData}
      total={totalCount}
      skip={skip * take}
      take={take}
      onPageChange={(e) => {
        handlePageChange(e);
        if (scrollTo) {
          window.scrollTo(0, 0);
        }
      }}
      pageable={pageable ? pageableSettings : pageable}
      style={{ ...(isHoverable && { cursor: 'pointer' }) }}
      className={`app-table ${!isHoverable ? 'cell-remove-hover' : ''} ${wrapperClassName}`}
      expandField={expandable ? "expanded" : undefined}
      onExpandChange={expandChange}
      detail={expandable ? DetailComponent : ''}
      selectable={{ enabled: multiSelectEnabled, drag: false, cell: false, mode: "multiple" }}
      selectedField={SELECTED_FIELD}
      dataItemKey={`${DATA_ITEM_KEY} ${TL_VIEW_DATA_ITEM_KEY} ${TL_VIEW_REASSIGNMENT_DATA_ITEM_KEY}`}
      onSelectionChange={onSelectionChange}
      onHeaderSelectionChange={onHeaderSelectionChange}
    >
      {noRecords ? <GridNoRecords>Type appointment id to view the results...</GridNoRecords> : null}


      {multiSelectEnabled && <Column
        field={SELECTED_FIELD}
        width="40px"
        className='table-checkbox'
        headerSelectionValue={
          gridData.findIndex(
            (item) =>
              !item.carSharedWithDealer && !selectedState[idGetter(item)] && (tabSelected === 0 ? !selectedDealerState[tlIdGetter(item)] : !selectReassignDealerState[tlReassignIdGetter(item)])
          ) === -1
        }
      />}

      {
        headerData.map((item, index) => (
          (item?.show == undefined || item?.show) && <GridColumn
            key={index}
            field={item?.field}
            title={item?.title}
            cell={item?.cells}
            minResizableWidth={item?.minWidth}
            resizable={true}
            width={setWidth(item?.minWidth, item?.maxWidth)}
          />
        )
        )}
    </Grid>
  );
};

export default AppTable;
