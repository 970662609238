import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Button } from '@progress/kendo-react-buttons';
import TextInputComponent from './components/text-input';
import RichTextEditorComponent from './components/rich-text-editor';
import FileUploadComponent from './components/file-upload-wrapper';
import MultiSelectComponent from './components/multi-select';
import DateTimePickerComponent from './components/date-time-picker';
import ComboBoxComponent from './components/combo-box';
import styles from './style.module.css';
import { CARD_STYLE, DATE_TIME_PICKER_DATA, HEADER_STYLE, recurringOptions } from './constants';
import { KendoCard } from '../../../../../components';
import BackButton from '../back-button';
import { CjIncentivesService, InspectionSocialService } from '../../../../../services';
import { showToastMessage } from '../../../../../utils/utils';
import { makeClusterData } from '../announcement-view/helper';

const INITIAL_PAGINATION_PARAMS = {
	pageNumber: 0,
	pageSize: 10,
};

const AnnouncementForm = ({ announcementData, handleBack }) => {

    const [clusterData, setClusterData] = useState([]);
	const [clusterParam, setClusterParam] = useState("");
    const [initialClusterData, setInitialClusterData] = useState([]);
    useEffect(() => {
        let tempClusterData = [];
        let params = clusterParam !== "" && clusterParam !== null ? {clusterName: clusterParam} : INITIAL_PAGINATION_PARAMS;
        CjIncentivesService.fetchClustersByName(params)
            .then((response) => {
                tempClusterData = makeClusterData(response);
                if(params == INITIAL_PAGINATION_PARAMS) {
                    setInitialClusterData(tempClusterData);
                }
                setClusterData(tempClusterData)
            })
            .catch((err) => {
                console.log('err', err)
            })
    }, [clusterParam])

    const handleReset = (formikProps) => {
        formikProps.resetForm();
    };
    
    const fetchStoreIdsForCluster = (cluster) => {
        return CjIncentivesService.fetchStoreIdByCluster(cluster.value.clusterId)
            .then((response) => {
                return response?.detail?.clusterStoreList?.map(store => store.storeId) || [];
            })
            .catch((err) => {
                console.log('Error Fetching Stores for this Cluster', err);
                showToastMessage('Error Fetching Stores for this Cluster', false);
                return [];
            });
    };
    
    return (    
        
        <>
            <BackButton handleBack={handleBack} text={"Back"} />
            <KendoCard
                headerTitle={"Announcement"}
                cardStyle={CARD_STYLE}
                headerStyle={HEADER_STYLE}
                details={
                    <Formik
                        initialValues={announcementData}
                        onSubmit={(values) => {
                            const formData = new FormData();
                            const file = values?.uploadedFileUrl;
                            {typeof values.uploadedFileUrl == 'object' && formData.append('file', file)};

                            Promise.all((values.clusters || []).map((item) => {
                                return fetchStoreIdsForCluster(item)
                                    .then((storeIds) => {
                                        return {
                                            text: item?.text,
                                            clusterId: item?.value?.clusterId,
                                            value: {
                                                clusterId: item?.value?.clusterId,
                                                storeIds: storeIds
                                            }
                                        };
                                    });
                            })).then((populatedClusters) => {
                                const announcementRequest = {
                                    title: values?.title,
                                    message: values?.message,
                                    triggerDateTime: values?.triggerDateTime,
                                    expiryDateTime: values?.expiryDateTime,
                                    recurringType: values?.recurringType?.value,
                                    clusters: populatedClusters?.map(item => item?.value),
                                    ...(values?.feedId && { feedId: values?.feedId }),
                                    isRead: true
                                };

                                formData.append('announcementRequest', JSON.stringify(announcementRequest));

                                return InspectionSocialService.saveAnnouncement(formData, values?.feedId ? 'update' : 'save');
                            }).then((resp) => {
                                showToastMessage("Announcement saved successfully");
                            }).catch((err) => {
                                showToastMessage(`Error Saving Announcement: ${err?.message}`, false);
                                console.error('Error updating announcement:', err);
                            }).finally(() => {
                                handleBack()
                            })
                        }}
                    >
                        {({ setFieldValue, values, ...formikProps }) => (
                            <Form style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                                <div>
                                    <TextInputComponent name="title" label="Announcement Title" placeholder="Enter Announcement Title" length={50} />
                                    <RichTextEditorComponent name="message" label="Announcement Message" placeholder="Enter Announcement Message" length={1200} />
                                    <FileUploadComponent name="uploadedFileUrl" label="Announcement File Upload" />
                                </div>
                                <div>
                                    <MultiSelectComponent name="clusters" label="Cluster" data={clusterData} setFieldValue={setFieldValue} initialClusterData={initialClusterData} setClusterData={setClusterData} setClusterParam={setClusterParam}/>
                                    {DATE_TIME_PICKER_DATA.map((item, index) => (
                                        <DateTimePickerComponent
                                            key={index}
                                            name={item.name}
                                            label={item.title}
                                            placeholder={item.placeholder}
                                            triggerDate={values?.triggerDateTime}
                                        />
                                    ))}
                                    <ComboBoxComponent name="recurringType" label="Recurring Event" data={recurringOptions} />
                                </div>
                                <div className={styles.actionButton}>
                                    <Button className={styles.reset} type="button" onClick={() => handleReset(formikProps)}>Reset</Button>
                                    <Button className={styles.submit} type="submit">Submit</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                }
            />
        </>
    );
};

export default AnnouncementForm;
