import React from 'react';
import styles from './BackButton.module.css';

function BackButton({ handleBack, text }) {
    return (
        <div onClick={handleBack} className={styles.wrapper}>
            <span className={`k-icon k-i-arrow-chevron-left ${styles.backArrowIcon}`} />
            <span className={styles.label}>
                {text}
            </span>
        </div>
    )
}
export default BackButton;