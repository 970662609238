import React, { useState, useEffect } from "react";
import { FieldWrapper } from "@progress/kendo-react-form";
import { ListView } from "@progress/kendo-react-listview";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardActions,
  CardImage,
  CardSubtitle,
  Avatar,
  StackLayout,
} from "@progress/kendo-react-layout";
import { Icon } from "@progress/kendo-react-common";
import {
	Input,
	MaskedTextBox,
	NumericTextBox,
	Checkbox,
	ColorPicker,
	Switch,
	RadioGroup,
	Slider,
	SliderLabel,
	RangeSlider,
	TextArea,
	Rating,
	TextBox,
	InputSuffix,
	InputClearValue,
} from "@progress/kendo-react-inputs";
import {
	DatePicker,
	TimePicker,
	DateTimePicker,
	DateRangePicker,
	DateInput,
} from "@progress/kendo-react-dateinputs";
import {
	Label,
	Error,
	Hint,
	FloatingLabel,
} from "@progress/kendo-react-labels";
import {
	DropDownList,
	AutoComplete,
	MultiSelect,
	ComboBox,
	MultiColumnComboBox,
} from "@progress/kendo-react-dropdowns";
import { Upload } from "@progress/kendo-react-upload";
import rightTick from "../assets/rightTickIcon.svg";
import { filterBy } from "@progress/kendo-data-query";
import { Loader } from "@progress/kendo-react-indicators";
import styles from './style.module.css'
export const SingleFileUpload = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		// type,
		optional,
		onAdd,
		allowedExtensions,
		...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	return (
		<FieldWrapper>
			<Label
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				optional={optional}>
				{label}
			</Label>
			<div className={"k-form-field-wrap"}>
				<Upload
					batch={false}
					multiple={false}
					restrictions={{
						allowedExtensions: [".pdf", ".png", ".jpg", ".jpeg"],
					}}
					defaultFiles={[]}
					id={id}
					disabled={disabled}
					ariaDescribedBy={`${hintId} ${errorId}`}
					withCredentials={false}
					onAdd={onAdd}
					{...others}
				/>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};
export const FormInput = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  return (
    <FieldWrapper>
      <Label
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
      >
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <Input
          valid={valid}
          type={type}
          id={id}
          disabled={disabled}
          ariaDescribedBy={`${hintId} ${errorId}`}
          {...others}
          spinners={false}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};
export const FormInputTick = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		type,
		optional,
		istick = false,
		...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	return (
		<FieldWrapper>
			<Label
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				optional={optional}>
				{label}
			</Label>
			<div className={"k-form-field-wrap"}>
				<div className="wrapper">
					<Input
						valid={valid}
						type={type}
						id={id}
						disabled={disabled}
						ariaDescribedBy={`${hintId} ${errorId}`}
						{...others}
					/>
					{istick && (
						<img src={rightTick} alt="right" style={{ marginLeft: "10px" }} />
					)}
				</div>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};
export const FormRadioGroup = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		id,
		label,
		valid,
		disabled,
		hint,
		// visited,
		// modified,
		isDisplayLabel = false,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper>
			{!!isDisplayLabel && (
				<Label
					id={labelId}
					editorRef={editorRef}
					editorId={id}
					editorValid={valid}
					editorDisabled={disabled}>
					{label}
				</Label>
			)}
			<RadioGroup
				ariaDescribedBy={`${hintId} ${errorId}`}
				ariaLabelledBy={labelId}
				valid={valid}
				disabled={disabled}
				ref={editorRef}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const FormNumericTextBox = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	return (
		<FieldWrapper>
			<Label editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
			</Label>
			<NumericTextBox
				ariaDescribedBy={`${hintId} ${errorId}`}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const FormCheckbox = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		id,
		valid,
		disabled,
		hint,
		optional,
		label,
		visited,
		modified,
		...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	return (
		<FieldWrapper>
			<Checkbox
				ariaDescribedBy={`${hintId} ${errorId}`}
				label={label}
				labelOptional={optional}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const FormSwitch = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		optional,
		id,
		valid,
		disabled,
		hint,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				optional={optional}>
				{label}
			</Label>
			<Switch
				ref={editorRef}
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const FormMaskedTextBox = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		hint,
		optional,
		...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	return (
		<FieldWrapper>
			<Label editorId={id} editorValid={valid} optional={optional}>
				{label}
			</Label>
			<div className={"k-form-field-wrap"}>
				<MaskedTextBox
					ariaDescribedBy={`${hintId} ${errorId}`}
					valid={valid}
					id={id}
					{...others}
				/>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};
export const FormTextArea = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		hint,
		disabled,
		optional,
		...others
	} = fieldRenderProps;

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";

	return (
		<FieldWrapper>
			<Label editorId={id} editorValid={valid} optional={optional}>
				{label}
			</Label>
			<TextArea
				className="auto-height-textarea"
				valid={valid}
				id={id}
				disabled={disabled}
				ariaDescribedBy={`${hintId} ${errorId}`}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormColorPicker = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}>
				{label}
			</Label>
			<ColorPicker
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const FormSlider = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		data,
		min,
		max,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}>
				{label}
			</Label>
			<Slider
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				min={min}
				max={max}
				{...others}>
				{data.map((value) => (
					<SliderLabel title={value} key={value} position={value}>
						{value.toString()}
					</SliderLabel>
				))}
			</Slider>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const FormRangeSlider = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		hint,
		disabled,
		data,
		min,
		max,
		...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	return (
		<FieldWrapper>
			<Label editorId={id} editorValid={valid}>
				{label}
			</Label>
			<RangeSlider
				valid={valid}
				id={id}
				disabled={disabled}
				ariaDescribedBy={`${hintId} ${errorId}`}
				min={min}
				max={max}
				{...others}>
				{data.map((value) => {
					return (
						<SliderLabel key={value} position={value}>
							{value.toString()}
						</SliderLabel>
					);
				})}
			</RangeSlider>
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const FormRating = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		hint,
		disabled,
		optional,
		...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	return (
		<FieldWrapper>
			<Label editorId={id} editorValid={valid} optional={optional}>
				{label}
			</Label>
			<Rating
				valid={valid}
				id={id}
				disabled={disabled}
				ariaDescribedBy={`${hintId} ${errorId}`}
				{...others}
			/>
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormDropDownList = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}>
				{label}
			</Label>
			<DropDownList
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const FormAutoComplete = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}>
				{label}
			</Label>
			<AutoComplete
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const FormComboBox = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		isDisplayLabel = false,
		data,
		placeholder = "",
		filterable = true,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	const [sources, setSources] = useState(data);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		setSources(data);
		setLoading(false);
	}, [data]);

	const filterData = (filter) => {
		const slices = data.slice();
		const filteredData = filterBy(slices, filter);
		return filteredData;
	};
	const filterChange = (e) => {
		setLoading(true);
		setTimeout(() => {
			setSources(filterData(e.filter));
			setLoading(false);
		}, 200);
	};
	return (
		<FieldWrapper style={wrapperStyle}>
			{!!isDisplayLabel && (
				<Label
					id={labelId}
					editorRef={editorRef}
					editorId={id}
					editorValid={valid}
					editorDisabled={disabled}>
					{label}
				</Label>
			)}
			<div className={"k-form-field-wrap"}>
				<ComboBox
					key={id}
					ariaLabelledBy={labelId}
					ariaDescribedBy={`${hintId} ${errorId}`}
					ref={editorRef}
					valid={valid}
					id={id}
					filterable={true}
					onFilterChange={filterChange}
					loading={loading}
					disabled={disabled}
					placeholder={placeholder ? placeholder : label || "Please select ..."}
					data={sources}
					{...others}
				/>
			</div>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const FormClearInput = (fieldRenderProps) => {
	const {
		value,
		onChange,
		placeholder = "",
		id,
		handleClear,
	} = fieldRenderProps;
	const EMPTY_VALUE = "";
	return (
		<div className="action-search">
			<FieldWrapper>
				<TextBox
					value={value}
					onChange={onChange}
					placeholder={placeholder}
					id={id}
					suffix={() => (
						<>
							{value !== EMPTY_VALUE && (
								<InputClearValue onClick={handleClear}>
									<Icon name="x" />
								</InputClearValue>
							)}
						</>
					)}
				/>
			</FieldWrapper>
		</div>
	);
};
export const FormMultiColumnComboBox = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	const columns = [
		{
			field: "id",
			header: <span>header</span>,
			width: "100px",
		},
		{
			field: "name",
			header: "Name",
			width: "300px",
		},
		{
			field: "position",
			header: "Position",
			width: "300px",
		},
	];
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}>
				{label}
			</Label>
			<MultiColumnComboBox
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				columns={columns}
				textField={"name"}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const FormMultiSelect = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}>
				{label}
			</Label>
			<MultiSelect
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const FormDatePicker = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		hintDirection,
		isDisplayLabel = false,
		...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper style={wrapperStyle}>
			{!!isDisplayLabel && (
				<Label
					id={labelId}
					editorId={id}
					editorValid={valid}
					editorDisabled={disabled}>
					{label}
				</Label>
			)}
			<div className={"k-form-field-wrap"}>
				<DatePicker
					ariaLabelledBy={labelId}
					ariaDescribedBy={`${hintId} ${errorId}`}
					valid={valid}
					id={id}
					disabled={disabled}
					format={"dd/MM/yyyy"}
					placeholder={label || "Choose a date..."}
					{...others}
				/>
				{showHint && (
					<Hint id={hintId} direction={hintDirection}>
						{hint}
					</Hint>
				)}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};
export const FormDateTimePicker = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}>
				{label}
			</Label>
			<DateTimePicker
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const FormTimePicker = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}>
				{label}
			</Label>
			<TimePicker
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const FormDateInput = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}>
				{label}
			</Label>
			<DateInput
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const FormDateRangePicker = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";
	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}>
				{label}
			</Label>
			<DateRangePicker
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const FormFloatingNumericTextBox = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		optional,
		value,
		...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	return (
		<FieldWrapper>
			<FloatingLabel
				optional={optional}
				editorValue={value}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				label={label}>
				<NumericTextBox
					ariaDescribedBy={`${hintId} ${errorId}`}
					value={value}
					valid={valid}
					id={id}
					disabled={disabled}
					format="n2"
					{...others}
				/>
			</FloatingLabel>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormSearchInput = (fieldRenderProps) => {
	const {
		value,
		handleChange,
		handleInputSuffixAction,
		placeholder,
		handleClearSearch,
		id,
		style,
	} = fieldRenderProps;

	const [isSearching, setIsSearching] = React.useState(false);
	const [searchKey, setSearchKey] = React.useState("");

	React.useEffect(() => {
		if (searchKey !== value) {
			setSearchKey(value);
			setIsSearching(false);
		}
	}, [value]);

	const handleSufix = (value) => {
		setIsSearching(!isSearching);
		value === "close" ? handleClearSearch() : handleInputSuffixAction();
	};

	return (
		<div className="action-search">
			<FieldWrapper>
				<TextBox
					value={searchKey}
					onChange={handleChange}
					placeholder={placeholder}
					id={id}
					suffix={() => (
						<InputSuffix
							onClick={() => handleSufix(isSearching ? "close" : "search")}>
							{isSearching ? (
								<span className="k-icon k-i-close"></span>
							) : (
								<span className="k-icon k-i-search"></span>
							)}
						</InputSuffix>
					)}
					style={{ ...style }}
				/>
			</FieldWrapper>
		</div>
	);
};

export const UploadFile = (fieldRenderProps) => {
  const { key, label, showValidationMessage, validationMessage, ...others } =
    fieldRenderProps;
  return (
    <FieldWrapper>
      <Label>{label}</Label>
      <input
        key={key}
        className="form-control fileUploadField"
        type="file"
        {...others}
      />
      {showValidationMessage && (
        <Error id={`error-${key}`}>{validationMessage}</Error>
      )}
    </FieldWrapper>
  );
};

export const UploadFileV2 = (fieldRenderProps) => {
  const { key, label, showValidationMessage, validationMessage, isFileAvailable,id,handleFileView, ...others } = fieldRenderProps;

  return (
    <FieldWrapper>
      <div className={styles.head}>
        <p className={styles['quote_container_data--label']}>{label}</p>
        <div className={styles.head_content}>
          {
            isFileAvailable 
            ? 
              <p className={styles.head_content_file} onClick={handleFileView} >View File</p> 
            : 
              <p className={styles.head_content_no_file}>Not Available</p>
          }
          <label 
            htmlFor={id} 
            style={{ cursor: 'pointer' }} 
            className={`${styles.head_content_inputLabel} ${fieldRenderProps?.disabled && 'input-field-disabled'}`}
          >
            {
              isFileAvailable
              ?
                <>
                  <span class="k-icon k-font-icon k-i-edit"></span>
                  Edit
                </>
              :
                <>
                  <span class="k-icon k-font-icon k-i-upload"></span>
                  Upload
                </>
            }
          </label>
          <input
            id={id}
            name={id}
            key={key}
            className="form-control fileUploadField"
            type="file"
            style={{ display: 'none' }}
            {...others}
          />
        </div>
        {showValidationMessage && (
          <Error id={`error-${key}`}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};


export const KendoListView = (props) => {
	const {
		listViewStyle,
		prefixStyle,
		detailsStyle,
		valueStyle,
		MyListRender,
		listViewClassname,
	} = props;
	const ListRender = (props) => {
		let item = props.dataItem;
		return (
			<div
				className={`k-pb-2 k-pt-2`}
				style={{
					...listViewStyle,
				}}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						...item?.itemStyle,
					}}>
					<div style={{ ...detailsStyle }}>
						{item?.prefix && (
							<span
								className="k-pr-3"
								style={{
									...prefixStyle,
								}}>
								{item?.prefix}
							</span>
						)}
						<span>{item?.details}</span>
					</div>
					<div
						style={{
							...valueStyle,
						}}>
						{item?.value}
					</div>
				</div>
			</div>
		);
	};
	return (
		<ListView
			data={props.data}
			item={MyListRender ? MyListRender : ListRender}
			style={{
				width: "100%",
			}}
			header={props.header}
			footer={props.footer}
			className={listViewClassname}
		/>
	);
};

export const KendoListViewV2 = (props) => {
	const {
		listViewStyle,
		prefixStyle,
		detailsStyle,
		valueStyle,
		MyListRender,
		listViewClassname,
	} = props;
	const ListRender = (props) => {
		let item = props.dataItem;
		return (
			<div 
        className={`k-pb-2 k-pt-2`}
				style={{
					...listViewStyle,
				}}
      >
				<div style={{display: "flex",alignItems: "center",justifyContent: "space-between",...item?.itemStyle,}}>
					<div style={{ ...detailsStyle }}>
						{
              item?.prefix && (
							<span className="k-pr-3" style={{...prefixStyle,}}>
								{item?.prefix}
							</span>
						)}
						<span>{item?.details}</span>
					</div>
					<div style={{...valueStyle,}}>
            {item?.value}
          </div>
				</div>
			</div>
		);
	};
	return (
		<ListView
			data={props.data}
			item={MyListRender ? MyListRender : ListRender}
			style={{
				width: "100%",
			}}
			header={props.header}
			footer={props.footer}
			className={listViewClassname}
		/>
	);
};

export const KendoCard = (props) => {
	const {
		isImage,
		isTitleAvatar,
		headerTitle = "",
		headerSubtitle = "",
		details,
		actions,
		headerImage,
		thumbnailSrc,
		cardStyle,
		headerStyle,
		titleStyle,
		cardBodyStyle,
	} = props;
	return (
		<div style={{ height: "100%" }}>
			<Card
				style={{
					boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
					marginTop: "15px",
					display: "flex",
					height: "100%",
					...cardStyle,
				}}>
				{isImage && headerImage && (
					<CardImage
						src={headerImage}
						style={{
							margin: "15px",
						}}
					/>
				)}
				{headerTitle && (
					<CardHeader
						className=""
						style={{
							background: "transparent",
							...headerStyle,
						}}>
						{isTitleAvatar && (
							<Avatar type="image" size="medium" shape="circle">
								<img
									style={{
										width: 45,
										height: 45,
									}}
									alt="KendoReact Card Thumbnail"
									src={thumbnailSrc}
								/>
							</Avatar>
						)}

						<div>
							<CardTitle
								style={{
									// marginBottom: "4px",
									fontWeight: "700",
									fontSize: "20px",
									...titleStyle,
								}}>
								{headerTitle}
							</CardTitle>
							{headerSubtitle && <CardSubtitle>{headerSubtitle}</CardSubtitle>}
						</div>
					</CardHeader>
				)}
				{details && <CardBody style={{ ...cardBodyStyle }}>{details}</CardBody>}
				{actions && (
					<CardActions
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}>
						{actions}
					</CardActions>
				)}
			</Card>
		</div>
	);
};

export const FormUpload = (fieldRenderProps) => {
	const {
		value,
		id,
		optional,
		label,
		hint,
		validationMessage,
		touched,
		...others
	} = fieldRenderProps;

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";

	const onChangeHandler = (event) => {
		fieldRenderProps.onChange({ value: event.newState });
	};
	const onRemoveHandler = (event) => {
		fieldRenderProps.onChange({ value: event.newState });
	};
	return (
		<FieldWrapper>
			{label && (
				<Label
					id={labelId}
					editorId={id}
					optional={optional}
					className="k-form-label">
					{label}
				</Label>
			)}
			<div className={"k-form-field-wrap"}>
				<Upload
					id={id}
					autoUpload={false}
					showActionButtons={false}
					multiple={false}
					files={value}
					onAdd={onChangeHandler}
					onRemove={onRemoveHandler}
					ariaDescribedBy={`${hintId} ${errorId}`}
					ariaLabelledBy={labelId}
					{...others}
				/>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};
