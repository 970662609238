export const LEAD_SOURCES = [
    {
        label: "RA Lead",
        value: "RA_LEAD",
    },
    {
        label: "CJ Lead",
        value: "CJ_LEAD",
    },
    {
        label: "CC/DSA Lead",
        value: "CC_DSA_LEAD",
    },
    {
        label: "Looking for Funds Lead",
        value: "LOOKING_FOR_FUNDS_LEAD",
    },
    {
        label: "SF banner_FGVGE Lead",
        value: "SF_BANNER_FGVGE_LEAD",
    },
    {
        label: "CRM Lead",
        value: "CRM_LEAD",
    },
];

export const BULK_FILE_MAX_SIZE = 15 * 1024 * 1024; // 15MB in bytes
