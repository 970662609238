import { refreshDealers, updateDealerContact } from '../../../../../store/actions/myDealers';
import { SegmentEvents } from '../../../../constants/events.constants';
import { trackAnalytics } from '../../../../services/analytics';
import { showToastMessage } from '../../../../utils/utils';

export const handleEditClick = (index, dealerContactNumbers, setEditIndex, setEditValue, setIsEdit) => {
  setEditIndex(index);
  setEditValue(dealerContactNumbers[index].number);
  setIsEdit(true);
};

export const handleSaveClick = (editValue, editIndex, dealerContactNumbers, dealerId, dispatch, resetEditState, dealerName, email) => {
  const trackData = {
    dealer_id: dealerId,
    email_id: email,
    added_number: editValue,
    removed_number: dealerContactNumbers[editIndex].number,
    dealer_name: dealerName
  }

  if (editValue.length === 10) {
    if (dealerContactNumbers[editIndex].number !== editValue) {
      dispatch(updateDealerContact({ dealerId, existingContactNumber: dealerContactNumbers[editIndex].number, newContactNumber: editValue }))
        .then(() => {
          showToastMessage('Contact updated successfully', true, 1000);
          dispatch(refreshDealers(false));
          trackAnalytics(SegmentEvents.Viz_contact_modification, trackData);

        })
        .catch(() => {
          showToastMessage('Failed to update contact', false, 1000);
        });

      resetEditState();
    } else {
      showToastMessage('Number is the same as existing number', false, 1000);
    }
  } else {
    alert('Number must be 10 digits');
  }
};
