// import { Button } from "@progress/kendo-react-buttons";
// import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  // CardActions,
} from "@progress/kendo-react-layout";
// import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FormComboBox } from "../../../../components";
import { useSelector } from "react-redux";
import { LocationService } from "../../../../services";
const ELEMENT_KEYS = {
  source_city_id: "sourceCityId",
  source_store_id: "sourceLocationId",
  target_city_id: "destinationCityId",
  target_store_id: "destinationLocationId",
};
const CreateReplicaForm = (props) => {
  const masterData = useSelector((state) => state.retailConsumers);
  const { cities = [] } = masterData;
  const [cityList, setCityList] = useState([]);
  const [sourceStores, setSourceStores] = useState([]);
  const [targetStores, setTargetStores] = useState([]);

  useEffect(() => {
    const cts = cities.map((item) => {
      return {
        label: item.city_name,
        value: item.city_id,
      };
    });
    setCityList(cts);
  }, [cities]);
  const onInputChange = (e) => {
    const { value } = e;
    const { id } = e.target.props || {};
    const key = id || e.target.name;
    props.setData((items) => ({ ...items, [key]: value?.value }));
    if (key === ELEMENT_KEYS.source_city_id && !!value?.value) {
      LocationService.featchCityLocations(value?.value).then((resp) => {
        const stores = (resp?.detail || []).map((item) => {
          return {
            label: item.name,
            value: item.locationId,
          };
        });
        setSourceStores(stores);
      });
    } else if (key === ELEMENT_KEYS.target_city_id && !!value?.value) {
      LocationService.featchCityLocations(value?.value).then((resp) => {
        const stores = (resp?.detail || []).map((item) => {
          return {
            label: item.name,
            value: item.locationId,
          };
        });
        setTargetStores(stores);
      });
    }
  };
  return (
    <>
      <div className="k-card-deck">
        <Card
          style={{
            width: 200,
          }}
        >
          <CardBody>
            <CardTitle>Source</CardTitle>
            <FormComboBox
              key={ELEMENT_KEYS.source_city_id}
              name={ELEMENT_KEYS.source_city_id}
              data={cityList}
              textField="label"
              dataItemKey="value"
              label="City"
              onChange={onInputChange}
            />
            <FormComboBox
              key={ELEMENT_KEYS.source_store_id}
              name={ELEMENT_KEYS.source_store_id}
              data={sourceStores}
              textField="label"
              dataItemKey="value"
              label="Store"
              onChange={onInputChange}
            />
          </CardBody>
        </Card>
        <Card
          style={{
            width: 200,
          }}
        >
          <CardBody>
            <CardTitle>Target</CardTitle>
            <FormComboBox
              key={ELEMENT_KEYS.target_city_id}
              name={ELEMENT_KEYS.target_city_id}
              data={cityList}
              textField="label"
              dataItemKey="value"
              label="City"
              onChange={onInputChange}
            />
            <FormComboBox
              key={ELEMENT_KEYS.target_store_id}
              name={ELEMENT_KEYS.target_store_id}
              data={targetStores}
              textField="label"
              dataItemKey="value"
              label="Store"
              onChange={onInputChange}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};
export default CreateReplicaForm;
