import { FORM_ELEMENT_TYPE } from "../../../../utils/constants/values.constants";
import { challan_request_keys } from "../../challan-list/viewModels";
export const formData = {
  [challan_request_keys.APPOINTMENT_ORDER_ID]: {
    name: challan_request_keys.APPOINTMENT_ORDER_ID,
    label: "App Id/Order Id",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    type: "number",
    maxLength: 11,
  },
  [challan_request_keys.CHALLAN_SOURCE]: {
    name: challan_request_keys.CHALLAN_SOURCE,
    label: "Source Name",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    isDisplayLabel: true,
  },
  [challan_request_keys.REG_NUMBER]: {
    name: challan_request_keys.REG_NUMBER,
    label: "Registration Number",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    maxLength: 12,
  },
  [challan_request_keys.RETAIL_REGION]: {
    name: challan_request_keys.RETAIL_REGION,
    label: "Retail region",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
  [challan_request_keys.DESCRIPTION]: {
    name: challan_request_keys.DESCRIPTION,
    label: "Description",
    elementtype: FORM_ELEMENT_TYPE.TEXTAREA,
    maxLength: 200,
  },
};
