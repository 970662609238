import { useSelector } from "react-redux";
import { KendoCard, KendoListView } from "../../../../../../../components";
import { getBadge } from "../../../pr-automation/viewModel";
import DocumentsForm from "./DocumentsForm";
import styles from '../style.module.css'

const KycCard = ({ appointmentId }) => {
    return (
      <div className={styles.head}>
        <div className={styles.head_title}>
          KYC Documents
        </div>
        <div className={styles['quote_container']}>
          <DocumentsForm
            appointmentId={appointmentId}
            isFormDisabled={false}
            loanStatus={true}
          />
        </div>
      </div>
    );
};
export default KycCard;
