import styles from "../../../negotiation/assets/styles/pr.styles.module.css";
const titleBar = (title, subTitle) => {
  return (
    <div className="align-center">
      <span className={`${styles.titleBar} k-mr-4 k-text-capitalize `}>
        {title}
      </span>
      {subTitle}
    </div>
  );
};

export default titleBar;
