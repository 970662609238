const TablePageInfo = ({ currentPage = 0, total = 0, take }) => {

	return (
		<>
			{total ? <span className="table-page-info">
				({currentPage + 1} out of {Math.ceil(total / take)} {Math.ceil(total / take) > 1 ? 'pages' : 'page'})
			</span> : <span></span>}
		</>
	);
};
export default TablePageInfo;
