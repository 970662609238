import React, { useEffect } from 'react'
import useLazyQuery from '../../../../../../../hooks/useLazyQuery'
import { genAiApis } from '../../../../../../../services/genAiApi'
import { Loader } from '@progress/kendo-react-indicators';
import styles from './style.module.css'

function Highlights(props) {

  const {appointmentId} = props;
  const { apiCbFunction:getHighlights,data:highlightsData, isLoading:highlightsDataLoading,errorMessage:highlightsErrorMessage} = useLazyQuery(genAiApis.getHighlights)
  const { apiCbFunction:getDataByRequestId,data:dataByRequestId, isLoading:dataByRequestIdLoading} = useLazyQuery(genAiApis.getDataByRequestId)

  useEffect(()=>{
    getHighlights({
      body:{
        "journey": "RETAIL_ASSOCIATE",
        "action": "RA_TRANSCRIPT_HIGHLIGHT",
        "appointment":appointmentId,
      }
    })
  },[])

  useEffect(() => {
    let callCount = 0;
    let intervals = [15000, 20000, 25000];
    const fetchData = () => {
      if (callCount < 3) {
        setTimeout(() => {
            getDataByRequestId({
                requestId: dataByRequestId ? dataByRequestId.data?.requestId : highlightsData?.data?.requestId,
            });
            callCount += 1;
            fetchData();
        }, intervals[callCount]);
      }
    };
    if ((highlightsData?.msg === 'SUCCESS' || dataByRequestId?.msg === 'SUCCESS') && dataByRequestId?.data?.status !== 'PROCESSED') {
      fetchData();
    }
    return () => {
      callCount = 3;
    };
  }, [highlightsData, dataByRequestId]);

  const contentToRender = () =>{
    if((highlightsDataLoading || dataByRequestIdLoading) || ((highlightsData?.msg == 'SUCCESS' || dataByRequestId?.msg == 'SUCCESS') && dataByRequestId?.data?.status !=='PROCESSED')){
      return(<Loader/>)
    }
    else if (highlightsErrorMessage?.msg == 'Not Found.'){
      return (
        <p className={styles['head_collections--error']}>No records found</p>
      ) 
    }
    else if (dataByRequestId && dataByRequestId?.data?.status ==='PROCESSED')
    {
      const data = dataByRequestId?.data?.response?JSON.parse(dataByRequestId?.data?.response):undefined
      return(
        <div className={styles.head_data}>
          {parseMarkdown(data?.data?.text)}
        </div>
      )
    }
  }

  return (
    <div className={styles.head}>
      {contentToRender()}
    </div>
  )
}

export default Highlights

const parseMarkdown = (markdown) => {
  if(!markdown)
    return '';
  const lines = markdown.split('\n');
  const elements = lines.map((line, index) => {
      // Headers
      if (/^###\s/.test(line)) return <h3 key={index}>{line.replace(/^###\s/, '')}</h3>;
      if (/^##\s/.test(line)) return <h2 key={index}>{line.replace(/^##\s/, '')}</h2>;
      if (/^#\s/.test(line)) return <h1 key={index}>{line.replace(/^#\s/, '')}</h1>;

      // Bold text
      if (/\*\*(.*)\*\*/.test(line)) {
          const parts = line.split(/\*\*(.*?)\*\*/g);
          return (
              <p className={styles.markdown_p} key={index}>
                  {parts.map((part, i) =>
                      i % 2 === 1 ? <b key={i}>{part}</b> : part
                  )}
              </p>
          );
      }

      // Italic text
      if (/\*(.*)\*/.test(line)) {
          const parts = line.split(/\*(.*?)\*/g);
          return (
              <p className={styles.markdown_p} key={index}>
                  {parts.map((part, i) =>
                      i % 2 === 1 ? <i key={i}>{part}</i> : part
                  )}
              </p>
          );
      }

      // Links
      if (/\[(.*?)\]\((.*?)\)/.test(line)) {
          const parts = line.split(/(\[.*?\]\(.*?\))/g);
          return (
              <p className={styles.markdown_p} key={index}>
                  {parts.map((part, i) => {
                      const match = part.match(/\[(.*?)\]\((.*?)\)/);
                      if (match) {
                          return (
                              <a key={i} href={match[2]}>
                                  {match[1]}
                              </a>
                          );
                      }
                      return part;
                  })}
              </p>
          );
      }

      return <p className={styles.markdown_p} key={index}>{line}</p>;
  });

  return elements;
};
