import { Switch, Route } from "react-router-dom";
import { RoutesConfig } from "./constants";
import UiLayout from "../components/ui-layout";
import { Suspense } from "react";
import { Loader } from "../common/Loader";

const RenderRoutes = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                {
                    RoutesConfig.filter((item) => !item.isSecure).map((route) => {
                        return <Route key={route.path} {...route} />;
                    })
                }
                <UiLayout>
                    {
                        RoutesConfig.filter((item) => !!item.isSecure).map((route) => {
                            return <Route key={route.path} {...route} />;
                        })
                    }
                </UiLayout>
            </Switch>
        </Suspense>
    );
};

export default RenderRoutes;
