import { getFFUserType } from "../utils/utils";
export default (api) => {
	const getServiceHistoryDetails = (id) => {
		return new Promise((resolve, reject) => {
			api
				.get(`api/v1/service-history/${id}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const getServiceHistory = (params) => {
		const url = `api/v1/service-history/list`;
		return new Promise((resolve, reject) => {
			api
				.get(`${url}${params}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const assignMeServiceHistory = (params) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/service-history/assign`, params, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const adminAssignServiceHistory = (params) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/service-history/admin/assign`, params, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const serviceHistoryFulfillment = (id, params = {}) => {
		return new Promise((resolve, reject) => {
			api
				.patch(`api/v1/service-history/fulfillment/${id}`, params, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const getServiceHistoryPdfUrl = (orderId, orderItemId) => {
		return new Promise((resolve, reject) => {
			api
				.get(`api/v1/service-history/pdf/${orderId}/${orderItemId}`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const fetchServiceUsers = () => {
		return new Promise((resolve, reject) => {
			api
				.get(`/api/v1/service-history/users`, {
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const syncServiceHistoryUserList = () => {
		return new Promise((resolve, reject) => {
			api
				.post(`/api/v1/service-history/users/add`,{
					headers: { "user-type": `${getFFUserType()}` },
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	return {
		getServiceHistoryDetails,
		getServiceHistory,
		assignMeServiceHistory,
		serviceHistoryFulfillment,
		getServiceHistoryPdfUrl,
		fetchServiceUsers,
		adminAssignServiceHistory,
		syncServiceHistoryUserList
	};
};
