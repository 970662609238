import { getFFUserType } from "../utils/utils";

export default (api) => {

    const addReferralContent = (payload) => {
        return new Promise((resolve, reject) => {
            const request = api.post;
            request(`/api/v1/referral-content`, payload, {
                headers: { 
                    "user-type": `${getFFUserType()}` 
                },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    return {
        addReferralContent,
    };
};
