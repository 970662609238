import {
	GridLayout,
	GridLayoutItem,
	ExpansionPanel,
	ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { KendoCard } from "../../../../../../components";
import CustomGrid from "../../../../../../common/custom-grid/component";
import titleBar from "../../shared/title-bar";
import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "../../assets/styles/pr.styles.module.css";
import { inspectionReportColumns, sample_data } from "./view-models";
import { prAutomationApis } from "../../../../../../services/pr-automation-service";
import useLazyQuery from "../../../../../../hooks/useLazyQuery";
import { showToastMessage } from "../../../../../../utils/utils";

const InspectionReportCard = (props) => {
	const { appointmentId } = props
	const selectedElementRef = useRef(null);
	const { apiCbFunction: reportApiCaller, isLoading: isReportApiLoading } = useLazyQuery(prAutomationApis.getViewReportUrl);

	const inspectionData = useSelector(
		(state) => state.prAutomation.inspectionReportSummary
	);

	const renderReportDetails = () => {
		const [selectedTabKeyIndex, setSelectedTabKeyIndex] = useState();
		const renderCategoryGridItems = (categories) => {
			return (
				<GridLayout>
					<GridLayoutItem row={1}>
            <div className={styles.inspection_grid}>
              <CustomGrid
                data={categories}
                displayedColumns={Object.keys(inspectionReportColumns)}
                columnMap={inspectionReportColumns}
              />
            </div>
					</GridLayoutItem>
				</GridLayout>
			);
		};
		const renderSubCategoryGridItems = (subCategories) => {
			return (
				<>
					{subCategories?.map((subcat,index) => {
						const { parts, title } = subcat;
						const count = parts.filter((item) => !item.condition).length;
						return (
							<GridLayout style={{ marginBottom: '10px' }} key={index}>
								<GridLayoutItem
									row={1}
									className={styles.inspectionItemHeader}>
									<span className="k-font-bold k-mr-2">{title}</span>
									{count >= 0 && (
										<span
											style={{
												color: count == 0 ? "green" : "red",
												fontWeight: "bold",
												marginRight: "8px",
												fontSize: "14px",
												fontWeight: "500",
											}}>
											{count == 0 ? 'No' : count} Issue(s) Found
										</span>
									)}
								</GridLayoutItem>
								<GridLayoutItem row={2} className={styles.inspection_grid}>
									<CustomGrid
										data={parts}
										displayedColumns={Object.keys(inspectionReportColumns)}
										columnMap={inspectionReportColumns}
									/>
								</GridLayoutItem>
							</GridLayout>
						);
					})}
				</>
			);
		};

		const getExpandContent = (data = {}) => {
			const { parts = [], subCategories = [] } = data;
			return !!parts.length
				? renderCategoryGridItems(parts)
				: !!subCategories.length
					? renderSubCategoryGridItems(subCategories)
					: "";
		};

		const onExpand = (e, index) => {
			if (!e?.expanded) {
				setSelectedTabKeyIndex(index);
			} else {
				setSelectedTabKeyIndex();
			}
		};

		const getExpandPanelTitle = (item = {}) => {
			const { parts = [], subCategories = [], title } = item;
			let count = parts.filter((item) => !item.condition).length;
			subCategories.map((subCat) => {
				const { parts = [] } = subCat;
				count = count + parts.filter((item) => !item.condition).length;
			});

			return (
				<div className="justify-between">
					<div className={`k-font-medium k-mr-3 ${styles.expansion_panel_title}`}>
						{title}
					</div>
					{
						count >= 0 &&
						<div 
              className={styles.expansion_panel_title_check}
              style={{ 
                color: count == 0 ? 'green' : "#B42318",
                backgroundColor: count == 0 ? 'transparent' : "#FEF3F2" ,
              }}
            >
							{
                count === 0 ? 
                  <span class="k-icon k-font-icon k-i-check-circle"></span>
                : 
                  count
              }
						</div>
					}
				</div>
			);
		};

		useEffect(() => {
			setTimeout(() => {
				if (!!selectedElementRef.current) {
					selectedElementRef.current.scrollIntoView({
						behavior: "smooth",
					});
				}
			}, 300);
		}, [selectedTabKeyIndex]);

		return (
			<>
				{inspectionData
					?.filter(
						(row) => !!row?.parts?.length || !!row?.subCategories?.length
					)
					?.map((item, index) => (
						<div
							ref={selectedTabKeyIndex === index ? selectedElementRef : null}>
							<ExpansionPanel
								title={getExpandPanelTitle(item)}
								className={styles.expandedControl}
								expanded={selectedTabKeyIndex === index}
								tabIndex={index}
								onAction={(e) => onExpand(e, index)}>
								<Reveal>
									{selectedTabKeyIndex === index && (
										<ExpansionPanelContent>
											<GridLayout>{getExpandContent(item)}</GridLayout>
										</ExpansionPanelContent>
									)}
								</Reveal>
							</ExpansionPanel>
						</div>
					))}
			</>
		);
	};

	const viewReportHandler = async () => {
		const res = await reportApiCaller({ appointmentId: appointmentId })
		if (res?.status == 200) {
			window.open(res?.detail?.data?.link, '_blank')
		}
		else {
			showToastMessage('Inspection not done yet...', false)
		}
	}

	const inspectionReportHeader = () => {
		return (
			<div className={styles.inspection_report_header}>
				<span className={`k-font-bold k-font-size-xl k-mr-2`}>Inspection Report</span>
				<span className={styles.inspection_report_view_report} onClick={viewReportHandler}>
					{isReportApiLoading ? 'Loading' : 'View Report'}
				</span>
			</div>
		)
	}

	return (
		<KendoCard
			cardStyle={{ background: "#F0F2F5", }}
			headerTitle={inspectionReportHeader()}
			details={renderReportDetails()}
		/>
	);
};
export default InspectionReportCard;
