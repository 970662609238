import { VEHICLE_INSPECTION } from "../types";
import { VisService } from "../../services";

export const fetchCjVerificationList = (params) => (dispatch) => {
	dispatch({ type: VEHICLE_INSPECTION.FETCH_CJ_VERIFICATION_LIST });
	VisService.fetchCjInspectionList(params)
		.then((resp) => {
			dispatch({
				type: VEHICLE_INSPECTION.FETCH_CJ_VERIFICATION_LIST_SUCCESS,
				payload: {
					data: resp,
					numberOfElements: resp.length,
					totalElements: resp.length,
				},
			});
		})
		.catch((err) => {
			dispatch({
				type: VEHICLE_INSPECTION.FETCH_CJ_VERIFICATION_LIST_FAILURE,
				payload: err,
			});
		})
};