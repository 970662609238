import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Input } from '@progress/kendo-react-inputs';
import LabelComponent from '../label';
import styles from '../../style.module.css';

const TextInputComponent = ({ name, label, length, placeholder }) => (
    <div className={styles.mb30}>
        <LabelComponent title={label} length={length} />
        <Field name={name}>
            {({ field }) => (
                <Input placeholder={placeholder} {...field} />
            )}
        </Field>
        <ErrorMessage name={name} component="div" className={styles.error} />
    </div>
);

export default TextInputComponent;
