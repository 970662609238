import React from 'react'

export const VerificationStatusCell = (event) => {

	const getVerificationStatus = (name) => {
		switch (name) {
			case "Verified":
				return { css: "status-assigned", label: "VERIFIED" };
			case "Not Verified":
				return { css: "status-unassigned", label: "NOT VERIFIED" };
			default:
				break;
		}
	};
	const status = getVerificationStatus(event.dataItem?.isVerified ? 'Verified' : 'Not Verified');

	return (
		<td title={status}>
			<span className={status?.css}>{status?.label}</span>
		</td>
	);
};