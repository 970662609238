import "./tab-reco-details.scss";
import { useState, useEffect, useRef, useCallback } from "react";

import AppTable from "../app-table"
import { vizPanelService } from "../../../services";
import { getCallEvent, getUserId, getReqParams } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, TextBox } from "@progress/kendo-react-inputs";
import ShareCarButton from "../share-car-modal/share-button";
import { callDealer, fetchMyDealers, setCorrelationId, setOngoingCallStatus } from "../../../store/actions/myDealers";
import ContactCell from "../contact-cell";
import { AppLoader } from "../app-loader";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import AllDealersIcon from "../../assets/images/allDealersIcon";
import RecoDealerIcon from "../../assets/images/recoDealerIcon";
import { CARD_TYPE, EVENT_CAR_SOURCE, INITIAL_TABLE_SETTINGS } from "../../constants/table.constants";

const TabRecoDetails = ({ appointmentId }) => {

	const [selected, setSelected] = useState(0);
	const [page, setPage] = useState(INITIAL_TABLE_SETTINGS);

	const [data, setData] = useState([]);
	const { filters, userInfo } = useSelector((state) => state.allCars);
	const [selectedContact, setSelectedContact] = useState(null);
	const { isCallOngoing, isLoading, filters: dealerFilters } = useSelector((state) => state.myDealers)
	const tableRef = useRef(null);
	const [search, setSearch] = useState('');
	const dealers = useSelector((state) => state.myDealers);
	const [selectedCars, setSelectedCars] = useState([]);
	const [resetSelection, updateResetSelection] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		if (appointmentId) {
			if (selected === 1) {
				fetchDealersData();
			}
			else {
				getRecoDealers();
			}
		}
	}, [page, selected, appointmentId]);

	const fetchDealersData = useCallback(() => {


		dispatch(fetchMyDealers({
			page: page.skip,
			size: page.take,
			filterBy: dealerFilters.dealerType?.id,
			userId: userInfo?.id,
			appointmentId,
			auctionCategory: filters?.ocbLive.id,
			searchQuery: search,
			userType: userInfo?.type,
		}));
	}, [page, filters, search]);

	const getRecoDealers = useCallback(() => {
		const reqParams = getReqParams({ userId: getUserId(userInfo) });
		vizPanelService.fetchRecoDealers(appointmentId, reqParams).then((res) => {
			setData(res?.recoDealers || []);
		});
	}, []);


	const SafeCells = safeCellProps => {
		return (
			<td className={`${safeCellProps?.className} contact-cell`}>
				{safeCellProps.children}
			</td>
		);
	};
	const tableColumns = [
		{
			field: 'label',
			title: 'label',
			cells: ({ dataItem }) => {
				return <SafeCells data={dataItem} className="highlight-label">
					<div className="grid-label">{dataItem?.dealerDetails?.dealerName || dataItem?.dealerName}</div>
					{dataItem?.carSharedWithDealer && <div className="dealer-car-shared-cell">Car shared with dealer</div>}
				</SafeCells>
			},
		},
		{
			title: 'contactNumber',
			field: 'contactNumber',
			cells: ({ dataItem }) => {
				return <SafeCells data={dataItem}>
					<div className="ra-contact-cell" >
						<ContactCell tableRef={tableRef} dealerId={dataItem?.dealerDetails?.dealerId || dataItem?.dealerId} isCallOngoing={isCallOngoing} contacts={dataItem?.dealerDetails?.contactNumbers || dataItem?.contactNumbers} setSelectedContact={setSelectedContact} selectedContact={selectedContact} handleCallDealer={handleCallDealer} />
					</div>
				</SafeCells>
			},
		}

	];

	const markAllSelected = () => {
		if (selectedCars.length === data.length) {
			updateResetSelection(!resetSelection)
			setSelectedCars([]);
		} else {
			let marked = [];
			data.map(ele => marked.push(ele?.dealerDetails?.dealerId));
			updateResetSelection(!resetSelection)
			setSelectedCars(marked);

		}
	}


	const handlePageChange = pageNumber => {
		setPage({
			skip: pageNumber.page.skip / pageNumber.page.take,
			take: pageNumber.page.take
		});
	}

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			setPage({
				skip: INITIAL_TABLE_SETTINGS.skip,
				take: INITIAL_TABLE_SETTINGS.take
			});
		}
	};

	const handleSearch = () => {
		fetchDealersData();
	};

	const handleCallDealer = (dealerId, contacts) => {
		const callingNumber = contacts.find(contact => contact.isDefault).number || contacts[0].number;
		dispatch(callDealer({ dealerId, userId: userInfo?.id, callingNumber })).then((resp) => {
			getCallEvent(resp, EVENT_CAR_SOURCE.cars)
			dispatch(setCorrelationId(resp?.clientCorrelationId));
			dispatch(setOngoingCallStatus(true));
		}
		);

	}

	const handleSelect = (e) => {

		setSelected(e.selected);
	};


	const SearchIcon = () => (
		<span onClick={handleSearch} className="k-icon k-font-icon k-i-search search-icon"></span>
	)

	return (
		<div className="tab-reco-details">
			<div className="current-bids-grid app-table-view-sm app-table-hide-header table-as-grid app-table multiselect-enabled-table reco-table-wrapper">

				<TabStrip selected={selected} onSelect={handleSelect}>
					<TabStripTab title={
						<div className="reco-dealer-title">
							<RecoDealerIcon />
							<span className="tab-title-text">Reco Dealers</span>
						</div>}>

						<div className="title-bar">
							{data?.length > 0 && <Checkbox onClick={() => {
								markAllSelected()
							}}
								value={selectedCars.length === data.length ? true : false}
							/>}
							<span className="title-text">Dealer’s information</span>
						</div>
						{isLoading ? <AppLoader /> :
							<div ref={tableRef}>
								<AppTable
									data={data.map(ele => { return { ...ele, id: ele?.dealerDetails?.dealerId } }) || []}
									headerData={[
										...tableColumns
									]}
									onRowSelection={(e => {
										let marked = [];
										for (const [key, value] of Object.entries(e)) {
											if (value) marked.push(key);
										}
										setSelectedCars([...marked]);
									})}
									isHoverable={false}
									totalCount={data.length}
									pageable={false}
									multiSelectEnabled={true}
									resetSelection={resetSelection}
									markAllSelected={selectedCars.length === data.length}
								/>
							</div>
						}
					</TabStripTab>

					<TabStripTab title={
						<div className="reco-dealer-title">
							<AllDealersIcon />
							<span className="tab-title-text">All Dealers</span>
						</div>}>
						<TextBox
							size="medium"
							fillMode="solid"
							rounded="medium"
							className="app-search-input"
							placeholder="Search dealer by name...."
							prefix={SearchIcon}
							value={search}
							onKeyDown={handleKeyDown}
							onChange={(e) => setSearch(e.target.value)}
						/>
						<div className="title-bar">
							<span className="title-text">Dealers</span>
						</div>
						{isLoading ? <AppLoader /> :
							<div ref={tableRef}>
								<AppTable
									data={dealers?.data.map(ele => { return { ...ele, id: ele?.dealerId } }) || []}

									skip={page.skip}
									take={page.take}
									multiSelectEnabled
									headerData={tableColumns}
									isHoverable={true}
									totalCount={dealers?.totalElements}
									handlePageChange={handlePageChange}
									type={CARD_TYPE.dealers}
									onRowSelection={(e => {
										let marked = [];
										for (const [key, value] of Object.entries(e)) {
											if (value) marked.push(key);
										}
										setSelectedCars([...marked]);
									})}
								/>
							</div>
						}

					</TabStripTab>

				</TabStrip>

			</div>
			<ShareCarButton selectedCars={selectedCars?.length > 0 ? [appointmentId] : []} dealerId={selectedCars} isSlider={true}
				auctionType={filters?.dealerCarCategory?.id}
				refresh={() => {
					updateResetSelection(!resetSelection);
					selected === 1 ? fetchDealersData() : getRecoDealers();
					setSelectedCars([]);
				}} />
		</div>
	);
};
export default TabRecoDetails;
