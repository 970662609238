import { connect } from "react-redux";
import DescopeSignInWidget from "./DescopeSignInWidget";
const AccountContainer = () => {
  return (
    <div>
      <DescopeSignInWidget />
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AccountContainer);
