import React from "react";
import { KendoCard } from "../../../../components";
// import { GridDetailSectionWithCell } from "../../../../common/grid-detail";
// import { verification_detail_keys } from "../../verification/components/verification-details/viewModels";
import { Button } from "@progress/kendo-react-buttons";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import DynamicDataList from "../../../../common/dynamic-data-list";

const DetailCardLayout = (props) => {
  const { ctaActions = {} } = props || {};
  const style = {
    background: "#FFFFFF",
    minHeight: "180px",
    border: "15px solid #EBF5FF",
    boxShadow: "unset",
    borderRadius: "8px",
  };
  const headerStyle = {
    color: "#003E72",
    fontWeight: "600",
    fontSize: "18px",
  };

  return (
    <div>
      <KendoCard
        headerTitle={props.headerTitle}
        cardStyle={style}
        headerStyle={headerStyle}
        details={
          <GridLayout>
            <GridLayoutItem row={1}>
              <DynamicDataList
                columnList={props.columnList}
                itemPerRow={props.itemPerRow}
                customIndexStyle={props.customIndexStyle}
              />
              {!!props?.customComponent && props?.customComponent}
            </GridLayoutItem>
            <GridLayoutItem
              row={2}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {!!Object.keys(ctaActions)?.length
                ? Object.keys(ctaActions).map(
                  (item, index) =>
                    !ctaActions[item]?.hide && (
                      <Button
                        key={index}
                        onClick={ctaActions[item]?.handleClick}
                        themeColor={"primary"}
                        className="m-1"
                      >
                        {ctaActions[item]?.title}
                      </Button>
                    )
                )
                : null}
            </GridLayoutItem>
          </GridLayout>
        }
      />
    </div>
  );
};

export default DetailCardLayout;
