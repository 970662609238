import { forwardRef, useEffect, useState } from "react";
import { Loader } from "../../../../../../common/Loader";
import useLazyQuery from "../../../../../../hooks/useLazyQuery";
import { commonApis } from "../../../../../../services/commonApi";
import FormCardLayout from "../../../../../operations/components/layout-form-card";
import { getLoginUserEmail, showToastMessage } from "../../../../../../utils/utils";
import { FORM_ELEMENT_TYPE } from "../../../../../../utils/constants/values.constants";
import { useSelector } from "react-redux";
import styles from './style.module.css'

const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const CARD_STYLE = { border: "0px solid #EBF5FF", minHeight: '0px', marginTop: '0px' };


const DocumentsForm = forwardRef((props) => {
  const { appointmentId } = props;
  const prDetails = useSelector((state) => state.prAutomation);
  const { dreDocuments,orderData } = prDetails || {};
  const [categorizedDocuments, setCategorizedDocuments] = useState({});
  const { apiCbFunction: uploadDocs, isLoading: uploadDocsLoading } = useLazyQuery(commonApis.uploadDocs);
  const { apiCbFunction: getTokenForUpload } = useLazyQuery(commonApis.getTokenForUpload);
  const isFormDisabled = orderData?.orderStatus == 'SOLD'

  useEffect(() => {
      if (dreDocuments && orderData?.orderStatus) {
          categorizeDocuments();
      }
  }, [dreDocuments,orderData]);

  const categorizeDocuments = () => {
      const categorized = dreDocuments.reduce((acc, doc) => {
          const category = doc.category || "UNCATEGORIZED";
          if (!acc[category]) {
              acc[category] = {};
          }
          if (doc?.page?.length > 0) {
              doc.page.forEach((page) => {
                  const docUrl = doc?.uploads?.find((upload) => upload.page === page.page)?.attachmentName;
                  acc[category][doc.code + page.page] = createDocumentObject(doc, docUrl, page.page);
              });
          } else {
              const docUrl = doc?.uploads?.[0]?.attachmentName;
              acc[category][doc.code] = createDocumentObject(doc, docUrl);
          }

          return acc;
      }, {});
      setCategorizedDocuments(categorized);
  };

  const createDocumentObject = (item, docUrl, page = null) => {
      return {
          key: `${item.name}${page ? " " + page : ""}`,
          id: `${item.name}${page ? " " + page : ""}`,
          name: item.code,
          label: `${item.name}${page ? " " + page : ""}`,
          elementtype: FORM_ELEMENT_TYPE.UPLOAD_FILE_V2,
          accept: ".pdf, .png, .jpg",
          value: null,
          showStatus: docUrl ? 'Available' : 'Not Available',
          isFileAvailable: !!docUrl,
          statusStyle: {
              background: docUrl ? 'green' : '#d92d20',
              color: 'white',
              padding: '0px 5px',
              borderRadius: '10px',
              fontSize: '10px',
              fontWeight: '550'
          },
          disabled: !!docUrl && isFormDisabled ? true : false,
          handleChange: fileChangeHandler,
          handleFileView: docUrl ? () => window.open(docUrl) : () => { },
          showFileView: !!docUrl,
          ['data-category']: item.category,
          ['data-documentpage']: page ? page : null
      };
  };

  const fileChangeHandler = async (e) => {
      if(e.target.files[0].size > 2097152) {
          showToastMessage("File is too big!",false);
          e.target.value = "";
          return;
      }
      const response = await fileUploader(e);
      if (response?.status === 200) {
          const docUrl = response?.data?.baseUri;
          const category = e.target.dataset['category'];
          const page = e.target.dataset['documentpage'] ?? '';
          const key = e.target.name + page;

          setCategorizedDocuments((prevDocuments) => {
              const updatedCategory = {
                  ...prevDocuments[category],
                  [key]: {
                      ...prevDocuments[category][key],
                      showFileView: true,
                      handleFileView: () => window.open(docUrl),
                      showStatus: 'Available',
                      isFileAvailable: true,
                      disabled: isFormDisabled,
                      statusStyle: {
                          background: 'green',
                          color: 'white',
                          padding: '0px 5px',
                          borderRadius: '10px',
                          fontSize: '10px',
                          fontWeight: '550'
                      }
                  }
              };
              return {
                  ...prevDocuments,
                  [category]: updatedCategory
              };
          });
      } else {
          showToastMessage('Some Error Occurred, Please try again', false);
      }
  };

  const fileUploader = async (e) => {
      const payload = {
          userEmail: getLoginUserEmail(),
          client: "c2b-internal-portal",
      };
      const userTokens = await getTokenForUpload(payload);
      const headers = {
          Authorization: `BEARER ${userTokens?.data?.accessToken}`,
          jti: userTokens?.data?.jti,
          utid: userTokens?.data?.utid,
      };

      const page = e.target.dataset['documentpage'] ?? null;
      const formData = new FormData();
      formData.append("appointmentId", appointmentId);
      formData.append("imageSource", "receive");
      formData.append("isRetailPipeline", true);
      formData.append("docCode", e.target.name);
      if (page !== null) {
          formData.append("page", page);
      }

      formData.append("file", e.target.files[0],`${e.target.name}-${Date.now()}`);
      formData.append("client", "c2b-internal-portal");
      formData.append("uploadSource", "ADMIN_RA");

      const response = await uploadDocs({
          body: formData,
          headers: headers
      });
      e.target.value = ''
      return response;
  };

  return (
      <div className={`${styles['quote_container']} ${styles.last}`}>
          {uploadDocsLoading && (
              <div
                  style={{
                      position: 'fixed',
                      height: '100vh',
                      width: '100vw',
                      background: 'rgba(128, 128, 128, 0.5)',
                      top: '0',
                      left: '0',
                      zIndex: '9999',
                  }}
              >
                  <Loader />
              </div>
          )}
          <div className={styles.documents_container_parent}>
              {!isEmptyObject(categorizedDocuments) &&
                  Object.keys(categorizedDocuments).map((category) => (
                    ['LOAN_DOCUMENTS', 'KYC_DOCUMENTS', 'UNCATEGORIZED'].includes(category)
                      ?
                        <></>
                      :
                        <div className={styles.documents_container}>
                            <p className={styles.documents_container_header}>{category.split('_').join(' ')}</p>
                            <FormCardLayout
                                key={category}
                                formData={categorizedDocuments[category]}
                                isShowCTA={false}
                                cardStyle={CARD_STYLE}
                            />
                        </div>
                  ))
              }
          </div>
      </div>
  );
});


export default DocumentsForm;
