import { KendoCard } from "../../../components";
import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { VahanService } from "../../../services";
import { showToastMessage } from "../../../utils/utils";
import { useRef } from "react";
const ConsumerAdminSettings = () => {
  const [file, setFile] = useState(null);
  const [isFileSubmit, setIsFileSubmit] = useState(false);
  const inputFileRef = useRef(null);
  const handleOnSubmit = () => {
    if (!file) {
      showToastMessage("Choose file to upload", false);
      return;
    }
    setIsFileSubmit(true);
    let formData = new FormData();
    formData.append("regNumberCsvFile", file);
    VahanService.uploadRCDetailsCSV(formData)
      .then(() => {
        showToastMessage("Uploaded successfully");
        setFile();
        inputFileRef.current.value = "";
      })
      .catch((err) => {
        showToastMessage(
          err?.message || err?.error || "Failed to upload",
          false
        );
      })
      .finally(() => {
        setIsFileSubmit(false);
      });
  };
  return (
    <>
      <div className="ops-channel-page-bar">
        <span className="order-id-label">Settings</span>
      </div>
      <div className="middleContent">
        <KendoCard
          headerTitle={"Upload Documents"}
          cardStyle={{
            background: "#F0F2F5",
            height: "170px",
          }}
          details={
            <input
              key="csv-upload"
              className="form-control fileUploadField"
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
              accept=".csv"
              ref={inputFileRef}
            />
          }
        />
        <Button
          className="m-2 service-detail-cta"
          themeColor={"primary"}
          onClick={handleOnSubmit}
          disabled={isFileSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
};
export default ConsumerAdminSettings;
