import LoanForm from './LoanForm';
import React, { useEffect, useRef, useState } from 'react'
import ConsentForm from './ConsentForm';
import DocumentsForm from './DocumentsForm';
import TeamRemarkForm from './team-remark-form';
import { Button } from '@progress/kendo-react-buttons';
import { getFormDataValue, getLoginUserEmail, showToastMessage } from '../../../../../../../utils/utils';
import NumberCorrectionForm from './NumberCorrectionForm';
import useQuery from '../../../../../../../hooks/useQuery';
import { KendoCard } from '../../../../../../../components';
import { hypoQcApis } from '../../../../../../../services/hypo-qc-service';
import { Loader } from '../../../../../../../common/Loader';
import useLazyQuery from '../../../../../../../hooks/useLazyQuery';
import { commonApis } from '../../../../../../../services/commonApi';
import { isValidAccountNumber } from '../../../../../../../utils/utils';
import { fetchOrderDetails } from '../../../../../../../store/actions/pr-automation';
import { useDispatch } from 'react-redux';

const CARD_STYLE = { background: "#FFFFFF", minHeight: "180px", border: "2px solid #EBF5FF", boxShadow: "unset", borderRadius: "8px", };
const HEADER_STYLE = { color: "#003E72", fontWeight: "600", fontSize: "18px", };

function RaLoanCard({
    appointmentId,
    handleAddOpenLoan
}) {
    const dataRef = useRef() //This ref will be used during form submission to compare the previous values 
    const loanFormRef = useRef();
    const consentFormRef = useRef();
    const numberCorrectionFormRef = useRef();
    const dispatch = useDispatch();
    const [loanStatus, setLoanStatus] = useState(undefined)
    const { data: detailsData, isLoading } = useQuery(hypoQcApis.getDetails, { appointmentId: appointmentId })
    const { apiCbFunction: saveLoanDetails, data: saveLoanDetailsData, } = useLazyQuery(commonApis.saveLoanDetails)

    const isFormDisabled = false;

    const formSubmitHandler = () => {
        const loanFormData = getFormDataValue(loanFormRef?.current?.formData ?? {})
        if (!loanFormData?.loanPendingAmount || !isNaN(parseFloat(loanPendingAmount?.loanPendingAmount)) || loanFormData?.loanPendingAmount < 0) {
            showToastMessage('Loan Amount is not a valid', false);
            return;
        }
        if (!loanFormData?.loanAccountNumber || !isValidAccountNumber(loanFormData?.loanAccountNumber)) {
            showToastMessage('Loan Account Number is not a valid', false);
            return;
        }
        let isFormUpdated = false;
        for (const item in loanFormData) {
            if (
                (item === 'hypoBank' && loanFormData['hypoBank'] !== dataRef.current.detail?.['hypothecationBank']) ||
                (item !== 'hypoBank' && loanFormData[item] !== dataRef.current.detail?.[item])
            ) {
                isFormUpdated = true;
                break;
            }
        }
        if (!isFormUpdated) {
            showToastMessage('Please update some details to save form', false);
            return;
        }
        const formData = {
            ...loanFormData,
            hypoDetailsSource: "RA",
            requestedBy: getLoginUserEmail(),
            validNocAvailable: true,
        }
        saveLoanDetails({
            appointmentId: appointmentId,
            body: formData
        })
        handleAddOpenLoan({
            loanAmount: formData.loanPendingAmount,
            bank: {
                bankName: formData.hypoBank
            }
        })
        dataRef.current.detail = loanFormData
        dataRef.current.detail['hypothecationBank'] = loanFormData['hypoBank']
        delete dataRef.current.detail['hypoBank']
    }

    useEffect(() => {
        if (saveLoanDetailsData) {
            if (saveLoanDetailsData?.status == 200)
                showToastMessage('Data Saved Successfully', true)
            else
                showToastMessage('Some Error Occured, Please try again', false)
        }
    }, [saveLoanDetailsData])

    useEffect(() => {
        if (detailsData) {
            setLoanStatus(detailsData?.detail?.loanStatus == 'PENDING')
            dataRef.current = { ...detailsData }
        }
    }, [detailsData])

    return (
        isLoading && !loanStatus ?
            <Loader />
            :
            <>
                <LoanForm data={detailsData?.detail} ref={loanFormRef} isFormDisabled={isFormDisabled} setLoanStatus={setLoanStatus} loanStatus={loanStatus}/>
                <DocumentsForm data={detailsData?.detail} isFormDisabled={isFormDisabled} loanStatus={loanStatus} key={loanStatus} />
                <KendoCard
                    headerTitle={'Credit Report Status'}
                    cardStyle={CARD_STYLE}
                    headerStyle={HEADER_STYLE}
                    details={
                        <>
                            <ConsentForm data={detailsData?.detail} ref={consentFormRef} />
                            {
                                (detailsData?.detail?.consent && detailsData?.detail?.creditReportMaskedPhone) &&
                                <NumberCorrectionForm data={detailsData?.detail} ref={numberCorrectionFormRef} />
                            }
                        </>
                    }
                />
                <TeamRemarkForm data={detailsData?.detail} />
                {
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: '10px' }}>
                        <Button
                            onClick={formSubmitHandler}
                            themeColor={"primary"}
                            className="m-1"
                            disabled={isFormDisabled}
                        >
                            Confirm
                        </Button>
                    </div>
                }
            </>
    )
}

export default RaLoanCard