import React, { useEffect, useState } from "react";
import { FormComboBox } from "../../../../components";
// import { Button } from "@progress/kendo-react-buttons";
import { ServiceHistory } from "../../../../services";
// import { showToastMessage } from "../../../../utils/utils";

const AssignAgentPopup = ({ agentPopupData, setAgentPopupData }) => {
  const { selectedAgent } = agentPopupData;
  const [serviceUserList, setServiceUserList] = useState([]);
  useEffect(() => {
    !serviceUserList.length &&
      ServiceHistory.fetchServiceUsers()
        .then((resp) => {
          const serviceUsers = resp.detail?.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
          setServiceUserList(serviceUsers);
        })
        .catch((err) => console.log(err));
  }, []);

  const handleChange = (e) => {
    const { value } = e || {};
    setAgentPopupData((data) => ({ ...data, selectedAgent: value }));
  };
  return (
    <div>
      <FormComboBox
        id={"userList"}
        onChange={handleChange}
        data={serviceUserList}
        textField="label"
        dataItemKey="value"
        label={"Agent List"}
        value={selectedAgent}
        isDisplayLabel={true}
      />
    </div>
  );
};

export default AssignAgentPopup;
