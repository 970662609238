import { getFFUserType } from "../utils/utils";

export default (api) => {

    const fetchReferralList = (params) => {
        return new Promise((resolve, reject) => {
            api
                .get(`api/v1/referral${params}`, {
                    headers: { "user-type": `${getFFUserType()}` },
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const updateReferralStatus = (payload) => {
        return new Promise((resolve, reject) => {
            const request = api.put;
            request(`api/v1/referral/status`, payload, {
                headers: { 
                    "user-type": `${getFFUserType()}` 
                },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    return {
        fetchReferralList,
        updateReferralStatus,
    };
};
