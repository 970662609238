import { convertObjectToParams } from "../utils/utils";

export default (api) => {
  const fetchKycDocs = (params = "") => {
    const url = "purchase/kyc-doc";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}/${params}?view=CUSTOMER`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchDocsStatus = (params = "") => {
    const url = "purchase/docket/verify";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}/${params}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchPrList = (params = "") => {
    const url = "purchase/purchase-request/list";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}${convertObjectToParams(params)}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchServiceHistory = (params = "") => {
    const url = "purchase/order/service/history/detail";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}/${params}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchVahanMismatch = (params = "") => {
    const url = "purchase/order/vahan/mismatch";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}/${params}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchOrderDetails = (params = "") => {
    const url = "purchase/nego-view";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}${convertObjectToParams(params)}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchHypoBanks = (params = "") => {
    const url = "purchase/hypo/banks";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}${convertObjectToParams(params)}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchBusinessApprovals = (params = "") => {
    const url = "purchase/business-approval";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}/${params}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchServiceHistoryEligibility = (params = "") => {
    const url = "purchase/order/service/history/eligible";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}/${params}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchBusinessApprovalTypes = (params = "") => {
    const url = "purchase/business-approval/type";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}${convertObjectToParams(params)}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchStates = () => {
    const url = "purchase/state";
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const fetchCities = (params = "") => {
    const url = "purchase/city-list";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}/${params}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const downloadDocument = (params, docCode) => {
    const url = "purchase/purchase-request/lite/document";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}/${params}?docCode=${docCode}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const getPrSubmitAllowed = (params = "") => {
    const url = "purchase/purchase/ra-journey";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}/${params}/submit-allowed`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const getAuditCheckDetails = (params = "") => {
    const url = "purchase/purchase-request";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}/${params}/audit`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const updateAppointment = (params, payload) => {
    const url = `purchase/orders`;
    return api.put(`${url}`, payload);
  };
  const reRaiseVahan = (payload) => {
    const url = `purchase/order/vahan-check/raise`;
    return api.post(`${url}`, payload);
  };
  const raiseRcCheck = (payload) => {
    const url = `purchase/order/rc-check/raise`;
    return api.post(`${url}`, payload);
  };
  const initiatePr = (payload) => {
    const url = `purchase/purchase/ra-journey/initiate`;
    return api.post(`${url}`, payload);
  };
  const businessApproval = (payload) => {
    const url = `purchase/business-approval`;
    return api.post(`${url}`, payload);
  };
  const sendVerificationLink = (payload) => {
    const url = `purchase/bank-details/penny/send-link`;
    return api.post(`${url}`, payload);
  };
  const markReasons = (payload) => {
    const url = `purchase/bank-details`;
    return api.patch(`${url}`, payload);
  };
  const initiateAudit = (params) => {
    const url = `purchase/purchase-request/${params}/audit`;
    return api.post(`${url}`, {});
  };
  const submitPr = (params, payload) => {
    const url = `purchase/purchase/ra-journey/${params}`;
    return api.patch(`${url}`, payload);
  };
  return {
    updateAppointment,
    fetchOrderDetails,
    reRaiseVahan,
    raiseRcCheck,
    fetchHypoBanks,
    fetchVahanMismatch,
    initiatePr,
    businessApproval,
    fetchBusinessApprovals,
    fetchBusinessApprovalTypes,
    fetchServiceHistoryEligibility,
    fetchServiceHistory,
    fetchPrList,
    fetchKycDocs,
    fetchDocsStatus,
    sendVerificationLink,
    markReasons,
    fetchStates,
    fetchCities,
    downloadDocument,
    getPrSubmitAllowed,
    initiateAudit,
    getAuditCheckDetails,
    submitPr,
  };
};
