import { ExpansionPanel, ExpansionPanelContent, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { KendoCard } from "../../../../../../components";
import CustomerToReceiveCard from "./customer-to-receive-card";
import DeductibleChargesCard from "./deductible-charges-card";
import { numberFormat, convertToNumber, getLoginUserEmail, showToastMessage, formatDateTime } from "../../../../../../utils/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../assets/styles/pr.styles.module.css";
import { prAutomationApis } from "../../../../../../services/pr-automation-service";
import useQuery from "../../../../../../hooks/useQuery";
import { Reveal } from "@progress/kendo-react-animation";
import { commonApis } from "../../../../../../services/commonApi";
import useLazyQuery from "../../../../../../hooks/useLazyQuery";
import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";
import PriceCalculator from "./price-calculator";
import RaiseOCBPopup from "./raise-ocb-popup";
import { useRef } from "react";
import { fetchC24QuoteDetails, fetchOrderDetails } from "../../../../../../store/actions/pr-automation";

const OCB_STATUS_MAPPING2 = {
  NOT_RAISED:'Not Raised',
  RUNNING:'Running',
  ACCEPTED:'Accepted',
  DNP_APPLIED:'DNP Applied',
  EXPIRED:'Expired',
  NOT_ACCEPTED:'Not Accepted',
  EXPIRED_WITH_DNP:"Expired With DNP"
}

const FinalQuoteCard = (props) => {
    const { loanAmount, appointmentId} = props || {};
    const prDetails = useSelector((state) => state.prAutomation);
    const {
        c24Quote = 0,
        negotiationAmount = 0,
        paymentRules,
        directOcbPrice = {},
        quoteData = {},
        lmsData,
    } = prDetails || {};
    const [deductibleChargeDetails, setDeductibleChargeDetails] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [holdDetails, setHoldDetails] = useState([]);
    const [chargesAmount, setChargesAmount] = useState(0);
    const { data: userQuoteInfo } = useQuery(prAutomationApis.getUserQuoteInfo, { email: getLoginUserEmail() });
    const { apiCbFunction: getPriceForCustomer, data: customerPriceData ,isLoading:isCustomerPriceDataLoading} = useLazyQuery(commonApis.getPriceForCustomer);
    const dispatch = useDispatch()
    const [updatedQuotePrice, setUpdatedQuotePrice] = useState(null)
    const [isCEPEditClicked, setIsCEPEditClicked] = useState(false)
    const [isQuoteEditClicked, setIsQuoteEditClicked] = useState(false)
    const [isRaiseOCBModalOpen, setIsRaiseOCBModalOpen] = useState(false)
    const [isPriceCalculatorOpen, setIsPriceCalculatorOpen] = useState(false)
    const { apiCbFunction: acceptDNP, isLoading: isAcceptDNPLoading } = useLazyQuery(commonApis.acceptDNP);
    const { apiCbFunction: updateQuote, isLoading: isUpdateQuoteLoading } = useLazyQuery(commonApis.updateQuote);
    const { apiCbFunction: updateCEP, isLoading: isUpdateCEPLoading } = useLazyQuery(commonApis.updateCEP);
    const updatedQuotePriceRef = useRef()
    const updatedCEPPriceRef = useRef()

    const setReload = () =>{
      setTimeout(()=>getPriceForCustomer({appointmentId:appointmentId}),1000)
    }

	const acceptDnpHandler = async () => {
		if (!isAcceptDNPLoading) {
			const res = await acceptDNP({
				body: {
					appointmentId: appointmentId,
					auctionId: quoteData?.auctionId,
					c24Quote: Number(customerPriceData?.data?.auctionStatus?.data?.dealerNegoPrice)
				}
			})
			if (res.status == 200) {
				showToastMessage('DNP Accepted Successfully', true)
        dispatch(fetchC24QuoteDetails({ appointmentId:appointmentId}));
        dispatch(fetchOrderDetails({ appointmentId, documentCodes: ["AADHAR_CARD", "PAN_CARD_COPY"], }));
        setReload()
			}
			else {
				showToastMessage('Some error occured', false)
			}
		}
	}

	const quoteSubmitHandler = async () => {
		if (!isUpdateQuoteLoading) {
      if(isNaN(updatedQuotePriceRef?.current?.value)){
        showToastMessage('Input is not number',false)
        return;
      }
			const response = await updateQuote({
				body: {
					appointmentId: appointmentId,
					manualQuote: updatedQuotePriceRef?.current?.value,
					source: "C2B_INTERNAL_PORTAL"
				}
			})
			if (response.c24Quote) {
        setIsQuoteEditClicked(false)
        dispatch(fetchC24QuoteDetails({ appointmentId:appointmentId}));
        dispatch(fetchOrderDetails({ appointmentId, documentCodes: ["AADHAR_CARD", "PAN_CARD_COPY"], }));
        showToastMessage('Quote updated successfully', true)
			}
			else {
				setUpdatedQuotePrice(null)
				setIsQuoteEditClicked(false)
				showToastMessage('Some error occured', false)
			}
		}
	}

	const cepSubmitHandler = async () => {
		if (!isUpdateCEPLoading) {
      if(isNaN(updatedCEPPriceRef?.current?.value)){
        showToastMessage('Input is not number',false)
        return;
      }
			const response = await updateCEP({
				body: {
					appointmentId: appointmentId,
					expectedCustomerPrice: updatedCEPPriceRef?.current?.value,
					source: "C2B_INTERNAL_PORTAL"
				}
			})
			if (response.status == 200) {
				setIsCEPEditClicked(false)
        setReload()
        showToastMessage('CEP updated successfully', true)
			}
			else {
				setIsCEPEditClicked(false)
				showToastMessage('Some error occured', false)
			}
		}
	}

    useEffect(() => {
        let chargesTotal = 0;
        if (deductibleChargeDetails && deductibleChargeDetails.length) {
            deductibleChargeDetails.forEach((detail) => {
                chargesTotal =
                    chargesTotal + Number.parseInt(convertToNumber(detail.value), 10);
            });
        }
        setChargesAmount(chargesTotal);
    }, [loanAmount, deductibleChargeDetails]);

    const getDetails = (data) => {
        let details = [];
        data.forEach((item) => {
            if (item?.isApplicable && !!item?.amount) {
                details.push({
                    details: item.label,
                    remarks: item.remarks,
                    value: numberFormat(item.amount),
                });
            }
        });
        return details;
    };

    useEffect(()=>{
      getPriceForCustomer({appointmentId:appointmentId})
    },[])



    useEffect(() => {
        if (paymentRules) {
            let charges = [];
            if (paymentRules.charges && paymentRules.charges.length) {
                charges = getDetails(paymentRules.charges);
            }
            if (loanAmount) {
                const loanDetails = {
                    details: "Loan amount",
                    value: numberFormat(parseInt(loanAmount, 10)),
                };
                charges.push(loanDetails);
            }
            setDeductibleChargeDetails(charges);

            paymentRules.holds &&
                paymentRules.holds.length &&
                setHoldDetails(getDetails(paymentRules.holds));
        }
    }, [paymentRules, loanAmount]);

    const c24QuoteCalculated = c24Quote
    const pllAvailableQuote = quoteData?.inputAmount - quoteData?.pllMargin
    const actualAvailableQuote = quoteData?.inputAmount - (lmsData?.isPll?quoteData?.pllMargin:quoteData?.retailMargin)
    
    const PriceDetails = (props) => {
        return (
          <>
            <GridLayout
                gap={{
                    rows: "15px",
                    cols: "0px",
                }}>
                  <GridLayoutItem row={1} col={1}>
                    <div className={styles['quote_container']}>
                      <div className={styles['quote_container_data']}>
                        <span className={`${styles['quote_container_data--label']}`}>
                          Current Quote
                        </span>
                        {
                          isQuoteEditClicked ?
                            isUpdateQuoteLoading ?
                              <Loader />
                              :
                              <span>
                                <input
                                  type='text'
                                  ref={updatedQuotePriceRef}
                                  className={styles.editClickedInput}
                                  defaultValue={updatedQuotePriceRef?.current?.value?updatedQuotePriceRef?.current?.value:c24QuoteCalculated}
                                />
                                <Button
                                  themeColor="success"
                                  className={styles['secondaryButton--submit']}
                                  icon="success"
                                  onClick={quoteSubmitHandler}
                                  size={"small"}
                                  fillMode={"flat"}
                                />
                                <Button
                                  className={styles['secondaryButton--cancel']}
                                  themeColor="secondary"
                                  icon="clear"
                                  onClick={() => {
                                    updatedQuotePriceRef.current.value = null;
                                    setIsQuoteEditClicked(false)
                                  }}
                                  size={"small"}
                                  fillMode={"flat"}
                                />
                              </span>
                            :
                            <span className={`${styles['quote_container_data--value']}`}>
                              {numberFormat(c24QuoteCalculated)}
                              <Button
                                className={styles['secondaryButton--edit']}
                                icon="edit"
                                onClick={() => { setIsQuoteEditClicked(true) }}
                                size={"small"}
                                fillMode={"flat"}
                              />
                            </span>
                        }
                      </div>
                      <div className={styles['quote_container_data']}>
                          <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
                              Actual Available Quote
                          </span>
                          <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
                              {actualAvailableQuote>=0 ? numberFormat(actualAvailableQuote):numberFormat(0)}
                          </span>
                      </div>
                    </div>
                    <div className={styles['quote_container']}>
                      <div className={styles['quote_container_data']}>
                        <span className={`${styles['quote_container_data--label']}`}>
                          CEP
                        </span>
                        <span className={`${styles['quote_container_data--value']}`}>
                          {
                            isCEPEditClicked ?
                              isUpdateCEPLoading ?
                                <Loader />
                                :
                                <span>
                                  <input
                                    type='text'
                                    ref={updatedCEPPriceRef}
                                    className={styles.editClickedInput}
                                    defaultValue={customerPriceData?.data?.cep?.data?.customerExpectedPrice ?? 0}
                                  />
                                  <Button
                                    themeColor="success"
                                    className={styles['secondaryButton--submit']}
                                    icon="success"
                                    onClick={cepSubmitHandler}
                                    size={"small"}
                                    fillMode={"flat"}
                                  />
                                  <Button
                                    className={styles['secondaryButton--cancel']}
                                    themeColor="secondary"
                                    icon="clear"
                                    onClick={() => {
                                      setIsCEPEditClicked(false)
                                    }}
                                    size={"small"}
                                    fillMode={"flat"}
                                  />
                                </span>
                              :
                              <span className={`${styles['quote_container_data--value']}`}>
                                {numberFormat(Number((customerPriceData?.data?.cep?.data?.customerExpectedPrice ?? 0)))}
                                <span
                                  className={`k-icon k-font-icon k-i-edit ${styles['edit_icon']}`}
                                  onClick={() => {
                                    setIsCEPEditClicked(true)
                                  }}
                                ></span>
                              </span>
                          }
                        </span>
                      </div>
                      <div className={styles['quote_container_data']}>
                        <span className={`${styles['quote_container_data--label']}`}>
                          Customer TP
                        </span>
                        <span className={`${styles['quote_container_data--value']}`}>
                          {numberFormat(Number(customerPriceData?.data?.customerTp?.data?.targetPrice ?? 0))}
                        </span>
                      </div>
                    </div>
                    <div className={`${styles.low_margin_div} ${styles['quote_container']}`}>
                        <div className={styles['quote_container_data']}>
                            <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
                                PLL Available Quote 
                            </span>
                            <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
                                {pllAvailableQuote>=0 ? numberFormat(pllAvailableQuote):numberFormat(0)}
                            </span>
                        </div>
                        <div className={styles['quote_container_data']}>
                            <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
                                Quota Available
                            </span>
                            <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
                                {userQuoteInfo?.detail?.quota ?? 'NA'} | {userQuoteInfo?.detail?.quotaExpiryDate?.split('T')?.[0] ?? 'NA'}
                            </span>
                        </div>
                    </div>
                    <div className={styles['quote_container']}>
                      <div className={styles['quote_container_data']}>
                        <span className={`${styles['quote_container_data--label']}`}>
                          Last Raised OCB
                        </span>
                        <span className={`${styles['quote_container_data--value']}`}>
                          {numberFormat(Number(customerPriceData?.data?.auctionStatus?.data?.previousRaisedOCBPrice)??'NA')}
                        </span>
                      </div>
                      <div className={styles['quote_container_data']}>
                        <span className={`${styles['quote_container_data--label']}`}>
                          OCB Status
                        </span>
                        <span className={`${styles['quote_container_data--value']}`}>
                          {customerPriceData?.data?.auctionStatus?.data?.statusText}
                        </span>
                      </div>
                    </div>
                    <div className={styles['quote_container']}>
                      <div className={styles['quote_container_data']}>
                        <span className={`${styles['quote_container_data--label']}`}>
                          DNP Received
                        </span>
                        <span className={`${styles['quote_container_data--value']}`}>
                          {numberFormat(Number(customerPriceData?.data?.auctionStatus?.data?.dealerNegoPrice) || 'Not Available')}
                          {
                            Number(customerPriceData?.data?.auctionStatus?.data?.dealerNegoPrice ?? 0) !== 0 && customerPriceData?.data?.auctionStatus?.data?.ocbStatus == 5 &&
                            <span className={styles.acceptDNPButton} onClick={acceptDnpHandler}>
                              {
                                isAcceptDNPLoading ? "Loading" : "Accept DNP"
                              }
                            </span>
                          }
                        </span>
                      </div>
                      {
                        customerPriceData?.data?.auctionStatus?.data?.statusLabel == 'RUNNING' &&
                        <div className={styles['quote_container_data']}>
                          <span className={`${styles['quote_container_data--label']}`}>
                            OCB Expired Time
                          </span>
                          <span className={`${styles['quote_container_data--value']}`}>
                            {customerPriceData?.data?.auctionStatus?.data?.auctionEndTime?.replace('T', ' ')}
                          </span>
                        </div>
                      }
                    </div>
                    <div className={`${styles['last']} ${styles['quote_container']}`}>
                      <div className={styles['quote_container_data']}>
                        <span
                          className={styles['quote_container_data--button']}
                          onClick={() => { setIsPriceCalculatorOpen(!isPriceCalculatorOpen) }}
                        >
                          Calculate Price
                        </span>
                        {
                          isPriceCalculatorOpen &&
                          <PriceCalculator
                            appointmentId={appointmentId}
                            isPriceCalculatorOpen={isPriceCalculatorOpen}
                            setIsPriceCalculatorOpen={setIsPriceCalculatorOpen}
                          />
                        }
                      </div>
                      {
                        lmsData?.isPll && ["NO BID", "EXTERNAL BID", "EXPIRED","INTERNAL BID","BOUGHT"].includes(quoteData?.tag?.toUpperCase()) &&
                        <div className={styles['quote_container_data']}>
                          <span
                            className={styles['quote_container_data--button']}
                            onClick={() => { setIsRaiseOCBModalOpen(!isRaiseOCBModalOpen) }}
                          >
                            Raise OCB
                          </span>
                          {
                            isRaiseOCBModalOpen &&
                            <RaiseOCBPopup
                              appointmentId={appointmentId}
                              isRaiseOCBModalOpen={isRaiseOCBModalOpen}
                              setIsRaiseOCBModalOpen={setIsRaiseOCBModalOpen}
                              auctionId={quoteData?.auctionId}
                              setRelaod={setReload}
                            />
                          }
                        </div>
                      }
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem row={1} col={2}>
                    {renderDetails()}
                  </GridLayoutItem>
            </GridLayout>
            <div>
            <ExpansionPanel
              title={<p className={styles['expansion_header']}>OCB Logs</p>}
              className={styles.expandedControl}
              expanded={isExpanded}
              tabIndex={0}
              onAction={()=>{setIsExpanded(!isExpanded)}}
            >
              <Reveal>
                  {
                    isExpanded && 
                    <ExpansionPanelContent>
                      {
                        <GridLayout className={styles['get_more_price_container']}>
                          <div className={styles.ocbLogsTable}>
                            <div className={styles.ocbLogsHeader}>
                              <div className={styles.ocbLogsHeader_h}>Running Price</div>
                              <div className={styles.ocbLogsHeader_h}>Running Date</div>
                              <div className={styles.ocbLogsHeader_h}>Status</div>
                            </div>
                            {
                              customerPriceData?.data?.auctionLogs?.data?.map((item)=>{
                                return(
                                  <div className={styles.ocbLogsdata}>
                                    <div className={styles.ocbLogsdata_d}>{numberFormat(item.runningPrice)}</div>
                                    <div className={styles.ocbLogsdata_d}>{formatDateTime(item.runningDate)}</div>
                                    <div className={styles.ocbLogsdata_d}>{OCB_STATUS_MAPPING2[item.status]??'NA'}</div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </GridLayout>
                      }
                    </ExpansionPanelContent>
                  }
              </Reveal>
            </ExpansionPanel>
            </div>
          </>
        );
    };
    
    const renderDetails = () => {
        return (
            <GridLayout
                cols={[
                    {width: "100%",},
                    {width: "100%",},
                ]}
                style={{marginLeft:'20px'}}
                className={styles.price_breakdown_div}
            >
                <GridLayoutItem row={1} col={1}>
                    <CustomerToReceiveCard
                        holdDetails={holdDetails}
                        chargesAmount={chargesAmount}
                        c24Quote={c24Quote}
                    />
                </GridLayoutItem>
                <GridLayoutItem row={2} col={1}>
                    <DeductibleChargesCard
                        deductibleChargeDetails={deductibleChargeDetails}
                        chargesAmount={chargesAmount}
                    />
                </GridLayoutItem>
            </GridLayout>
        );
    };

    const renderHeaderTitle = () => {
        return (
            <div className={styles.finalQuoteHeader}>
                <div>
                    <span className="k-font-bold k-font-size-xl k-mr-2">
                        Pricing
                    </span>
                </div>
            </div>
        );
    };

    return (
        <>
            <KendoCard
              cardStyle={{background: "#F0F2F5"}}
              headerTitle={renderHeaderTitle()}
              details={<PriceDetails/>}
            />
        </>
    );
};

export default FinalQuoteCard;