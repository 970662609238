import { lead_mon_keys } from "../lead-mon/viewModels";
import { FORM_ELEMENT_TYPE } from "../../../utils/constants/values.constants";

export const FormData = {
  [lead_mon_keys.CITY_NAME]: {
    name: lead_mon_keys.CITY_NAME,
    label: "City",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    isDisplayLabel: true,
  },
  [lead_mon_keys.LOCATION_NAME]: {
    name: lead_mon_keys.LOCATION_NAME,
    label: "Store",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    isDisplayLabel: true,
  },
  [lead_mon_keys.MAKER_NAME]: {
    name: lead_mon_keys.MAKER_NAME,
    label: "Make",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    isDisplayLabel: true,
  },
  [lead_mon_keys.MODEL_NAME]: {
    name: lead_mon_keys.MODEL_NAME,
    label: "Model",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    isDisplayLabel: true,
  },
  [lead_mon_keys.FROM_YEAR]: {
    name: lead_mon_keys.FROM_YEAR,
    label: "From Year",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    isDisplayLabel: true,
  },
  [lead_mon_keys.TO_YEAR]: {
    name: lead_mon_keys.TO_YEAR,
    label: "To Year",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    isDisplayLabel: true,
  },
  [lead_mon_keys.IS_LEAD_MON]: {
    name: lead_mon_keys.IS_LEAD_MON,
    label: "Lead Mon",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    isDisplayLabel: true,
    source: [{label: 'Yes', value: 1}, {label: 'No', value: 0}]
  },
  [lead_mon_keys.IS_LUXURY_CAR]: {
    name: lead_mon_keys.IS_LUXURY_CAR,
    label: "Luxury Car",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    isDisplayLabel: true,
    source: [{label: 'Yes', value: 1}, {label: 'No', value: 0}]
  },
  [lead_mon_keys.PARTNER_ID]: {
    name: lead_mon_keys.PARTNER_ID,
    label: "Partner ID",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    isDisplayLabel: true,
    placeholder: 'Enter Partner ID',
    type: 'number'
  },
  [lead_mon_keys.TP_THRESHOLD]: {
    name: lead_mon_keys.TP_THRESHOLD,
    label: "TP Threshold",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
    isDisplayLabel: true,
    placeholder: 'Enter TP Threshold',
    type: 'number',
    step: '0.0001'
  },
};
