import React from "react";

export const AppLoader = () => {
  return (
    <div className="app-loader-container" onClick={(e)=>{e.preventDefault();e.stopPropagation();}}>
      <div className="loader">
        <div className="k-loading-image" />
      </div>
    </div>
  );
};
