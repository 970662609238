import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { commonApis } from "../../../../../../../services/commonApi"
import FormCardLayout from "../../../../../../operations/components/layout-form-card"
import { setDefaultValueToForm } from "../../../../../../../utils/utils"
import { LOAN_STATUS_FORM_DATA } from "./view-models"
import useQuery from "../../../../../../../hooks/useQuery"
import { useDispatch } from "react-redux"
import { fetchOrderDetails } from "../../../../../../../store/actions/pr-automation"

const LoanForm = forwardRef((props, ref) => {

    const { data, isFormDisabled, setLoanStatus } = props
    const dispatch = useDispatch()
    const [formData, setFormData] = useState(LOAN_STATUS_FORM_DATA)
    const { data: hypotecationBanksData } = useQuery(commonApis.getHypotecationBanks);


    useImperativeHandle(ref, () => ({
        formData
    }));

    const onInputChange = (e) => {
        const { value } = e;
        const { id } = e.target.props || {};
        const key = id || e.target.name;
        let updatedPopupData = {
            ...formData,
            [key]: {
                ...formData[key],
                ...{ ["value"]: value },
            },
        };
        if (key == 'loanStatus'){
            setLoanStatus(value?.value == 'PENDING')
            // dispatch(fetchOrderDetails({ appointmentId:data?.appointmentId, documentCodes: ["AADHAR_CARD", "PAN_CARD_COPY"], loanStatusOpen:value?.value == 'PENDING'}));
        }
        setFormData(updatedPopupData);
    };

    useEffect(() => {
        if (data) {
            setFormData(prevFormData => setDefaultValueToForm(prevFormData, data));
        }
    }, [data]);

    useEffect(() => {
        if (!isFormDisabled) {
            let updatedFormData = {}
            Object.keys(formData).forEach(key => {
                updatedFormData[key] = {
                    ...formData[key],
                    disabled: isFormDisabled
                }
            });
            setFormData(updatedFormData)
        }
    }, [isFormDisabled])

    useEffect(() => {
        let value = null
        if (hypotecationBanksData) {
            setFormData(prevFormData => {
                return {
                    ...prevFormData,
                    'hypothecationBank': {
                        ...prevFormData['hypothecationBank'],
                        source: hypotecationBanksData?.detail?.map((item) => {
                            value = data?.hypothecationBank == item.bankName ? {
                                label: item.bankName,
                                value: item.bankName
                            } : value
                            return {
                                label: item.bankName,
                                value: item.bankName
                            };
                        }),
                        value: value
                    }
                };
            })
        }
    }, [hypotecationBanksData])

    return (
        <FormCardLayout
            headerTitle={"Loan Status"}
            formData={{ ...formData }}
            handleChange={onInputChange}
            isShowCTA={false}
            cardStyle={{ border: "2px solid #EBF5FF", minHeight: '0px' }}
        />
    )
})

export default LoanForm;