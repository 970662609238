import {
	ExpansionPanel,
	ExpansionPanelContent,
	GridLayout,
	GridLayoutItem,
} from "@progress/kendo-react-layout";
import { useState, useEffect } from "react";
import {
	FormSearchInput,
	FormTextArea,
	KendoCard,
	UploadFile,
	FormAutoComplete,
} from "../../../../../../components";
import { Reveal } from "@progress/kendo-react-animation";
import {
	ARTHEMETIC_OPERATORS,
	CONDITION_SECTION_ACCORDIAN,
	SYSTEM_METRICS,
} from "./viewModal";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { cj_incentive_keys } from "../../../view-modal";
import { showToastMessage } from "../../../../../../utils/utils";
import FormulaChip from "../formula-chip";
import FormulaItemChip from "../formula-item-chip";
import FormulaTextArea from "../formula-text-area";
import { isValidEquation } from "../../../../utils/expression-validator";
import { NUMBERS } from "../../../../../../utils/constants/values.constants";
import { CjIncentivesService } from "../../../../../../services";
const FormulaBuilder = ({ data, updateData, systemMetrices }) => {
	const [expanded, setExpanded] = useState();
	const [fileCount, setFileCount] = useState(0);
	const [matrixSearchString, setMatrixSearchString] = useState("");
	const [lastSelectedIndex, setLastSelectedIndex] = useState(0);
	const handleSysytemMetrics = (value) => {
		const selectedConditionKeyIndex = getSelectedCondition();
		if (selectedConditionKeyIndex < 0) {
			showToastMessage("Please select control placeholder", false);
		} else {
			let _conditions = data[cj_incentive_keys.CONDITIONS];
			let condition = _conditions[selectedConditionKeyIndex];
			let selectedKey = "";
			if (condition[cj_incentive_keys.FORMULA_EXP].isSelected) {
				selectedKey = cj_incentive_keys.FORMULA_EXP;
			} else if (condition[cj_incentive_keys.FORMULA_RESULT].isSelected) {
				selectedKey = cj_incentive_keys.FORMULA_RESULT;
			}
			condition = {
				...condition,
				[selectedKey]: {
					...condition[selectedKey],
					value: `${condition[selectedKey].value || ""} ${value}`,
				},
			};
			_conditions[selectedConditionKeyIndex] = condition;
			updateData((prev) => ({
				...prev,
				[cj_incentive_keys.CONDITIONS]: _conditions,
			}));
		}
	};

	const renderSystemMetrics = () => {
		return (
			<>
				<FormSearchInput
					value={matrixSearchString}
					placeholder={`Search`}
					handleChange={(e) => setMatrixSearchString(e.target.value)}
					iconName={"search"}
					handleInputSuffixAction={() => {}}
					handleClearSearch={() => setMatrixSearchString("")}
				/>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-start",
						flexWrap: "wrap",
					}}>
					{systemMetrices
						.filter((item) =>
							item.toLowerCase().startsWith(matrixSearchString.toLowerCase())
						)
						?.map((item) => (
							<FormulaItemChip
								label={item}
								onClickHandler={() => handleSysytemMetrics(item)}
							/>
						))}
				</div>
			</>
		);
	};

	const renderArithmeticOperators = () => {
		return (
			<>
				{Object.values(ARTHEMETIC_OPERATORS)?.map((item) => (
					<Button
						themeColor={"primary"}
						className="m-1"
						fillMode={"link"}
						style={{ background: "#EBF5FF" }}
						onClick={() => handleSysytemMetrics(item)}>
						{item}
					</Button>
				))}
			</>
		);
	};

	const renderTargets = () => {
		const handleAddFile = () => {
			setFileCount(fileCount + 1);
		};
		return (
			<>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-start",
						flexWrap: "wrap",
						marginBottom: "10px",
					}}>
					{data?.[cj_incentive_keys.TARGET_MATRIX]?.map((item) => (
						<FormulaItemChip
							label={item}
							onClickHandler={() => handleSysytemMetrics(item)}
						/>
					))}
				</div>
				<Button
					themeColor={"primary"}
					onClick={handleAddFile}
					fillMode={"outline"}
					style={{ marginBottom: "10px" }}>
					Add File
				</Button>
				{Array.from({ length: fileCount })?.map((_, index) => (
					<UploadFile
						key={index}
						id={`target-${index}`}
						name={`target-${index}`}
						accept="text/csv"
						multiple="false"
						onChange={(e) => onTargetFileAdded(e, index)}
						label={`Target ${index + 1}`}
					/>
				))}
			</>
		);
	};

	const getSelectedCondition = () => {
		const keyIndex = data[cj_incentive_keys.CONDITIONS].findIndex(
			(item) =>
				item[cj_incentive_keys.FORMULA_EXP].isSelected ||
				item[cj_incentive_keys.FORMULA_RESULT].isSelected
		);
		return keyIndex;
	};

	const resetCondtionsFocus = (conditions = []) => {
		let _conditions = conditions?.map((item) => {
			return {
				[cj_incentive_keys.FORMULA_EXP]: {
					...item[cj_incentive_keys.FORMULA_EXP],
					isSelected: false,
					isvalid: isValidEquation(
						item[cj_incentive_keys.FORMULA_EXP]?.value
					).toString(),
				},
				[cj_incentive_keys.FORMULA_RESULT]: {
					...item[cj_incentive_keys.FORMULA_RESULT],
					isSelected: false,
					isvalid: isValidEquation(
						item[cj_incentive_keys.FORMULA_RESULT]?.value
					).toString(),
				},
			};
		});
		return _conditions;
	};
	const onEditFormula = (event, index) => {
		const {
			target: { value = "", name },
		} = event;
		let _conditions = data[cj_incentive_keys.CONDITIONS];
		let condition = _conditions[index];
		condition = {
			...condition,
			[name]: {
				...condition[name],
				value,
			},
		};
		_conditions[index] = condition;
		updateData((prev) => ({
			...prev,
			[cj_incentive_keys.CONDITIONS]: _conditions,
		}));
	};

	const onFormulaChipClick = (key = "", index = 0) => {
		let _data = { ...data };
		let _conditions = resetCondtionsFocus(_data[cj_incentive_keys.CONDITIONS]);
		// update current condition
		_conditions[index][key].isSelected = true;
		updateData((prev) => ({
			...prev,
			[cj_incentive_keys.CONDITIONS]: _conditions,
		}));
	};

	const onTargetFileAdded = (e, index) => {
		const {
			target: { name, files },
		} = e;
		const formData = new FormData();
		formData.append("target_name", files[0]);
		CjIncentivesService.addIncentiveTargetFile(
			data?.[cj_incentive_keys.ID],
			formData
		)
			.then((resp) => {
				if (!!resp?.detail) {
					updateData((prev) => ({
						...prev,
						[cj_incentive_keys.TARGET_MATRIX]: [
							...data?.[cj_incentive_keys.TARGET_MATRIX],
							resp?.detail,
						],
					}));
					showToastMessage("File uploaded successfully", true);
				}
			})
			.catch((err) => {
				showToastMessage("Failed to upload file", false);
				console.log(err);
			});
	};

	const onAddCondition = () => {
		let _conditions = resetCondtionsFocus(data[cj_incentive_keys.CONDITIONS]);
		_conditions = [..._conditions, _conditions[0]];
		updateData((prev) => ({
			...prev,
			[cj_incentive_keys.CONDITIONS]: _conditions,
		}));
	};

	const onInputLostFocus = (e) => {
		// const _conditions = resetCondtionsFocus(data[cj_incentive_keys.CONDITIONS]);
		// updateData((prev) => ({
		// 	...prev,
		// 	[cj_incentive_keys.CONDITIONS]: _conditions,
		// }));
	};

	const onDeleteCondition = () => {
		const selectedIndex = getSelectedCondition();
		if (
			data[cj_incentive_keys.CONDITIONS].length <= 1 ||
			selectedIndex === NUMBERS.MINUS_ONE
		) {
			showToastMessage(
				selectedIndex === NUMBERS.MINUS_ONE
					? "Select Condition"
					: "Please add more then one conditions",
				false
			);
		} else {
			const updatedConditions = resetCondtionsFocus(
				data[cj_incentive_keys.CONDITIONS]?.filter(
					(_, index) => index !== selectedIndex
				)
			);
			updateData((prev) => ({
				...prev,
				[cj_incentive_keys.CONDITIONS]: updatedConditions,
			}));
		}
	};

	return (
		<GridLayout
			cols={[
				{
					width: "33%",
				},
				{
					width: "33%",
				},
				{
					width: "34%",
				},
			]}
			gap={{
				rows: "5px",
				cols: "5px",
			}}>
			<GridLayoutItem row={1} colSpan={3}>
				<Label key="labelcondition">
					{
						"Create conditions using system metrics, operators, and targets. You can also input numeric digits and use the backspace key from keyboard."
					}
				</Label>
			</GridLayoutItem>
			<GridLayoutItem row={2} col={1}>
				<KendoCard
					cardStyle={{
						marginTop: "0px",
					}}
					headerTitle={"Condition"}
					details={data?.[cj_incentive_keys.CONDITIONS]?.map((item, index) => {
						const exp = item?.[cj_incentive_keys.FORMULA_EXP];
						return (
							<FormulaTextArea
								{...exp}
								key={`${cj_incentive_keys.FORMULA_RESULT}-${index}-exp`}
								name={cj_incentive_keys.FORMULA_EXP}
								index={index}
								onFormulaChipClick={onFormulaChipClick}
								onEditFormula={onEditFormula}
								onInputLostFocus={onInputLostFocus}
								placeholder="Enter Condition"
								metrices={[
									...systemMetrices,
									...(data?.[cj_incentive_keys.TARGET_MATRIX] || []),
								]}
							/>
						);
					})}
				/>
			</GridLayoutItem>
			<GridLayoutItem row={2} col={2}>
				<KendoCard
					cardStyle={{
						marginTop: "0px",
					}}
					headerTitle={"Incentive Value"}
					details={data?.[cj_incentive_keys.CONDITIONS]?.map((item, index) => {
						const exp = item?.[cj_incentive_keys.FORMULA_RESULT];
						return (
							<FormulaTextArea
								{...exp}
								key={`${cj_incentive_keys.FORMULA_RESULT}-${index}-value`}
								name={cj_incentive_keys.FORMULA_RESULT}
								index={index}
								onFormulaChipClick={onFormulaChipClick}
								onEditFormula={onEditFormula}
								onInputLostFocus={onInputLostFocus}
								placeholder="Enter Incentive Value"
								metrices={[
									...systemMetrices,
									...(data?.[cj_incentive_keys.TARGET_MATRIX] || []),
								]}
							/>
						);
					})}
				/>
			</GridLayoutItem>
			<GridLayoutItem row={2} col={3} rowSpan={2}>
				{[
					CONDITION_SECTION_ACCORDIAN.SYSTEM_METRICS,
					CONDITION_SECTION_ACCORDIAN.ARITHMETIC_OPERATORS,
					CONDITION_SECTION_ACCORDIAN.TARGETS,
				]?.map((item, index) => (
					<ExpansionPanel
						title={item}
						expanded={expanded === index}
						tabIndex={index}
						onAction={(event) => {
							setExpanded(event.expanded ? "" : index);
						}}>
						<Reveal>
							{expanded === index && (
								<ExpansionPanelContent>
									{item === CONDITION_SECTION_ACCORDIAN.SYSTEM_METRICS
										? renderSystemMetrics()
										: item === CONDITION_SECTION_ACCORDIAN.ARITHMETIC_OPERATORS
										? renderArithmeticOperators()
										: renderTargets()}
								</ExpansionPanelContent>
							)}
						</Reveal>
					</ExpansionPanel>
				))}
			</GridLayoutItem>
			<GridLayoutItem
				row={3}
				colSpan={2}
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					marginTop: "10px",
					gap: "10px",
				}}>
				<Button themeColor={"primary"} onClick={onAddCondition}>
					+ Add Condition
				</Button>
				<Button themeColor={"primary"} onClick={onDeleteCondition}>
					Delete Condition
				</Button>
			</GridLayoutItem>
		</GridLayout>
	);
};

export default FormulaBuilder;
