export const SOURCE_DELAY_REASONS = [
    {
        label:"Server Down",
        value:"Server Down"
    },
    {
        label:"Payment Debited but not updated",
        value:"Payment Debited but not updated"
    },
    {
        label:"Payement Gateway issue",
        value:"Payement Gateway issue"
    },
    {
        label:"Documents Issue",
        value:"Documents Issue"
    },
    {
        label:"Court Not working",
        value:"Court Not working"
    },
    {
        label:"Agent Unable to Fulfil",
        value:"Agent Unable to Fulfil"
    },
    {
        label:"Mismatches in Offence Type",
        value:"Mismatches in Offence Type"
    },
    {
        label:"File misplaced in Court",
        value:"File misplaced in Court"
    },
    {
        label:"Bank OTP Issue",
        value:"Bank OTP Issue"
    },
    {
        label:"V-Court Proceeding TAT 30<>90 Days",
        value:"V-Court Proceeding TAT 30<>90 Days"
    },
    {
        label:"Debit Card Not Accepting",
        value:"Debit Card Not Accepting"
    },
    {
        label:"Net Banking Not Accepting",
        value:"Net Banking Not Accepting"
    },
    {
        label:"Stake Holder Approval Pending",
        value:"Stake Holder Approval Pending"
    },
    {
        label:"Court / Advocate Strike",
        value:"Court / Advocate Strike"
    },
    {
        label:"Payment stuck in verification mode",
        value:"Payment stuck in verification mode"
    },
    {
        label:"Website Error / Not Operating",
        value:"Website Error / Not Operating"
    },
    {
        label:"Agent declined to solve",
        value:"Agent declined to solve"
    },
    {
        label:"Court stay to dispose the challan",
        value:"Court stay to dispose the challan"
    },
    {
        label:"Drink & Drive",
        value:"Drink & Drive"
    },
    {
        label:"Buyer Peshi Required",
        value:"Buyer Peshi Required"
    },
    {
        label:"Registered Owner Peshi Required",
        value:"Registered Owner Peshi Required"
    },
    {
        label:"Driver Peshi Required",
        value:"Driver Peshi Required"
    },
    {
        label:"Buyer & Driver Peshi Required",
        value:"Buyer & Driver Peshi Required"
    },
    {
        label:"Registerd Owner & Driver Peshi Required",
        value:"Registerd Owner & Driver Peshi Required"
    },
    {
        label:"Invalid Engine & Chassis Number",
        value:"Invalid Engine & Chassis Number"
    },
    {
        label:"MV-207 Act - Vehicle  Need's To Be Seized",
        value:"MV-207 Act - Vehicle  Need's To Be Seized"
    },
]