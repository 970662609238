import { MY_DEALERS_CARS_VIEW_FILTER, MY_DEALERS_CARS_SORT_FILTER, getAppliedFilters, getSelectedCenterValues, getSelectedStateValues, queryParamsKeys } from "../common/constants/filters.constants";
import { useDispatch, useSelector } from "react-redux";
import "./all-cars.scss";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { updateCarsFilters } from '../store/actions/allCars';
import { TextBox } from "@progress/kendo-react-inputs";
import { useCallback, useEffect, useState } from "react";
import _debounce from 'lodash/debounce';
import { SEARCH_INPUT_DELAY } from "../common/constants/timeout.constants";
import { ALL_CARS_TABLE_VIEW } from "../common/constants/table.constants";
import SideBarFilters from "./side-bar-filters";
import { setDealerFilterQueryParam, setDealerFilterStatus, setSidebarDealerFilterApplied, setSidebarDealerFilterPayloads } from "../store/actions/myDealers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { buildQueryParam } from "../common/utils/side-bar-filter";

const DealersViewFilters = ({ applied, clearMoreFilters, queryParameters }) => {
	const { filters, sidebarFilters } = useSelector((state) => state.allCars);

	const { sideBarDealerFilterApplied, isDealerCarFiltersApplied } = useSelector((state) => state.myDealers)
	const [showMoreFilters, setShowMoreFilters] = useState(false);
	const [checkedItems, setCheckedItems] = useState([]);
	const [search, setSearch] = useState(queryParameters['searchQuery'] || '');


	const dispatch = useDispatch();
	const history = useHistory();

	const debounceFn = useCallback(_debounce((e) => dispatch(updateCarsFilters({ searchDealerCarQuery: e })), SEARCH_INPUT_DELAY), []);

	const isDisabled = filters?.dealerCarCategory?.id === ALL_CARS_TABLE_VIEW.dealersNegoView || filters?.dealerCarCategory?.id === ALL_CARS_TABLE_VIEW.dealersProcuredView;

	const placeholderText = filters?.dealerCarCategory?.id === ALL_CARS_TABLE_VIEW.dealersNegoView || filters?.dealerCarCategory?.id === ALL_CARS_TABLE_VIEW.dealersProcuredView
		? "Search cars by appt id...."
		: "Search cars by name....";


	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const sortBy = params.get('sortBy');
		const category = params.get('category');
		const searchQuery = params.get('searchQuery');

		if (sortBy) {
			const sortByFilter = MY_DEALERS_CARS_SORT_FILTER.find(filter => filter.id === sortBy);
			dispatch(updateCarsFilters({ dealerCarSort: sortByFilter }));
		}
		if (category) {

			const categoryFilter = MY_DEALERS_CARS_VIEW_FILTER.find(filter => filter.id === category);

			dispatch(updateCarsFilters({ dealerCarCategory: categoryFilter }));
		}

		if (searchQuery) {
			dispatch(updateCarsFilters({ searchDealerCarQuery: searchQuery }));

		}

	}, [])

	const updateFilters = (value) => {

		const params = new URLSearchParams(window.location.search);

		if (value?.dealerCarCategory?.id) {
			params.set('category', value?.dealerCarCategory?.id);
		}
		else {
			params.set('sortBy', value?.dealerCarSort?.id);
		}

		history.push({ pathname: window.location.pathname, search: params.toString() });
		dispatch(setDealerFilterQueryParam(params.toString()));
		dispatch(updateCarsFilters(value));
	}

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			const params = new URLSearchParams(window.location.search);
			debounceFn(e.target.value);

			if (e.target.value.length > 0) {
				params.set("searchQuery", e.target.value);
			} else {
				params.delete("searchQuery");
			}

			dispatch(setDealerFilterQueryParam(params.toString()));
			history.push({ pathname: window.location.pathname, search: params.toString() });
		}
	};

	const handleSearch = () => {
		debounceFn(searchDealerCarQuery);
	};

	const handleFilter = (e) => {
		setSearch(e.target.value)
	}

	const applyDealerCarFilters = (filterOptions) => {
		let carFilters = getAppliedFilters(filterOptions);
		let registrationStates = { states: getSelectedStateValues(filterOptions[filterOptions.length - 3]) };
		let stores = getSelectedCenterValues(filterOptions[0]);
		const dealerCode = queryParameters['dealerCode'];
		const page = queryParameters['page'];
		const params = buildQueryParam({ filterOptions, search, dealerCode, filters, page });

		let reqpayload = {
			carFilters,
			registrationStates,
			stores
		};

		if (carFilters.length > 0 || registrationStates.states.length > 0 || stores.length > 0) {
			dispatch(setDealerFilterStatus(true));
		}
		else {
			dispatch(setDealerFilterStatus(false));

		}

		dispatch(setSidebarDealerFilterPayloads(reqpayload));
		dispatch(setSidebarDealerFilterApplied([...filterOptions]));
		setShowMoreFilters(false);
		dispatch(setDealerFilterQueryParam(params.toString()));
		history.push({ pathname: window.location.pathname, search: params.toString() });

	}



	const resetCarFilters = () => {
		const params = new URLSearchParams();
		params.set('dealerCode', queryParameters['dealerCode']);
		params.set('page', queryParameters['page']);

		dispatch(setSidebarDealerFilterPayloads(null));
		dispatch(setSidebarDealerFilterApplied(null));
		dispatch(setDealerFilterStatus(false));
		setCheckedItems([]);
		setSearch('');
		dispatch(setDealerFilterQueryParam(params.toString()));

		history.push({ pathname: window.location.pathname, search: params.toString() });
	}

	const SearchIcon = () => {
		return (<>
			<span onClick={handleSearch} className="k-icon k-font-icon k-i-search search-icon"></span>
		</>)
	}

	const ClearIcon = () => {
		const params = new URLSearchParams(window.location.search);

		return (<>
			<span onClick={() => {
				if (search !== "") {
					dispatch(updateCarsFilters({ searchDealerCarQuery: '' }))
					setSearch('');
					params.delete("searchQuery");
					history.push({ pathname: window.location.pathname, search: params.toString() });
				}
			}} className="k-icon k-font-icon k-i-close clear-icon"></span>

		</>)
	}

	return (
		<>
			{showMoreFilters && <SideBarFilters queryParameters={queryParameters} checkedItems={checkedItems} setCheckedItems={setCheckedItems} applyCarFilters={applyDealerCarFilters} filters={filters} sidebarFiltersApplied={sideBarDealerFilterApplied} sidebarFilters={sidebarFilters} show={showMoreFilters} close={() => { setShowMoreFilters(false) }} clearMoreFilters={clearMoreFilters} applied={applied} resetCarFilters={resetCarFilters} />}

			<div className="all-cars-filters">
				<div className="app-dropdown-container">
					<DropDownList
						className="app-dropdown"
						data={MY_DEALERS_CARS_VIEW_FILTER}
						textField="label"
						value={filters?.dealerCarCategory}
						onChange={(e) => { updateFilters({ dealerCarCategory: e.target.value }) }}
					/>
				</div>
				<span
					className="dropdown-text"
				>
					Sort By:
				</span>

				<div className="app-dropdown-container">
					<DropDownList
						className="app-dropdown"
						data={MY_DEALERS_CARS_SORT_FILTER}
						textField="label"
						value={filters?.dealerCarSort}
						onChange={(e) => { updateFilters({ dealerCarSort: e.target.value }) }}
					/>
				</div>


				<TextBox
					size="medium"
					fillMode="solid"
					rounded="medium"
					className="app-search-input"
					placeholder={placeholderText}
					prefix={SearchIcon}
					suffix={ClearIcon}
					value={search}
					onChange={handleFilter}
					onKeyDown={handleKeyDown}
				/>

				<span
					className={`filter-btn-primary ${isDealerCarFiltersApplied ? 'filters-applied-icon' : ''}  ${isDisabled ? 'disabled' : ''}`}
					onClick={!isDisabled ? () => setShowMoreFilters(true) : undefined}
				>
					<span className='k-icon k-font-icon k-i-filter'></span>
				</span>

			</div>
		</>
	);
};
export default DealersViewFilters;
