import { FORM_ELEMENT_TYPE } from "../../../../utils/constants/values.constants";
import {
    ageValidator,
    getDateYearsAgo,
    mobileNumberValidator,
    nameValidator,
    panValidator,
    pincodeValidator,
} from "../../../../utils/utils";
import { ASSIGNED_LEAD_SUBTABS } from "../../common/constants";
import { ASSIGNED_LEAD_KEY } from "../../leads/viewModel";

export const ASSIGNED_DETAILS = [
    {
        field: ASSIGNED_LEAD_KEY.NAME,
        title: "Customer Name",
    },
    {
        field: ASSIGNED_LEAD_KEY.APPLICATION_ID,
        title: "Application Id",
    },
    {
        field: ASSIGNED_LEAD_KEY.MAKE,
        title: "Make",
    },
    {
        field: ASSIGNED_LEAD_KEY.MODEL,
        title: "Model",
    },
    {
        field: ASSIGNED_LEAD_KEY.YEAR,
        title: "Year",
    },
    {
        field: ASSIGNED_LEAD_KEY.CITY,
        title: "City",
    },
    {
        field: ASSIGNED_LEAD_KEY.VG_PRICE_RANGE,
        title: "VG Price Range",
    },
    {
        field: ASSIGNED_LEAD_KEY.STATUS,
        title: "Status",
    },
    {
        field: ASSIGNED_LEAD_KEY.CREATED_AT,
        title: "Created At",
    },
    {
        field: ASSIGNED_LEAD_KEY.UPDATED_AT,
        title: "Updated At",
    },
    {
        field: ASSIGNED_LEAD_KEY.UPDATED_BY,
        title: "Updated By",
    },
];

export const PAN_DETAIL_KEYS = {
    NAME: "name",
    PHONE_NUMBER: "phoneNumber",
    DOB: "dob",
    PAN_NUMBER: "panNumber",
    GENDER: "gender",
    PINCODE: "pincode",
    CIBIL_CONSENT: "cibilConsent",
};

export const PAN_DETAIL_FORM_DATA = {
    [PAN_DETAIL_KEYS.NAME]: {
        name: PAN_DETAIL_KEYS.NAME,
        label: "Name",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        placeholder: "Name",
        autocomplete: "false",
    },
    [PAN_DETAIL_KEYS.PHONE_NUMBER]: {
        name: PAN_DETAIL_KEYS.PHONE_NUMBER,
        label: "Phone Number",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        placeholder: "Phone Number",
        value: null,
        type: "number",
    },
    [PAN_DETAIL_KEYS.PAN_NUMBER]: {
        name: PAN_DETAIL_KEYS.PAN_NUMBER,
        label: "PAN Number",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        placeholder: "PAN Number",
        value: null,
    },
    [PAN_DETAIL_KEYS.PINCODE]: {
        name: PAN_DETAIL_KEYS.PINCODE,
        label: "Pincode",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        placeholder: "Pincode",
        value: null,
        type: "number",
        min: 0,
        max: 999999,
        type: "number",
    },
    [PAN_DETAIL_KEYS.DOB]: {
        name: PAN_DETAIL_KEYS.DOB,
        label: "Date of Birth",
        elementtype: FORM_ELEMENT_TYPE.DATE,
        isDisplayLabel: true,
        value: null,
        placeholder: "Date of Birth",
        max: getDateYearsAgo(18),
    },
    [PAN_DETAIL_KEYS.GENDER]: {
        name: PAN_DETAIL_KEYS.GENDER,
        label: "Gender",
        elementtype: FORM_ELEMENT_TYPE.SELECT,
        isDisplayLabel: true,
        value: null,
        source: [
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
            { label: "Other", value: "Other" },
        ],
    },
    [PAN_DETAIL_KEYS.CIBIL_CONSENT]: {
        name: PAN_DETAIL_KEYS.CIBIL_CONSENT,
        label: "CIBIL Consent",
        elementtype: FORM_ELEMENT_TYPE.SELECT,
        isDisplayLabel: true,
        value: null,
        source: [
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" },
        ],
        disabled: true,
    },
};

export const INCOME_DETAIL_KEYS = {
    HOUSE_TYPE: "houseType",
    EMPLOYMENT_TYPE: "employmentType",
    MONTHLY_INCOME: "monthlyIncome",
    REQUIRED_LOAN_AMOUNT: "requiredLoanAmount",
    REASON_FOR_LOAN: "reasonForLoan",
};

export const HOUSE_TYPE_MAPPING = {
    OWNED: "Owned",
    RENTED: "Rented",
};

export const EMPLOYMENT_TYPE_MAPPING = {
    SALARIED: "Salaried",
    SELF_EMPLOYED: "Own Business",
};

export const INCOME_DETAIL_FORM_DATA = {
    [INCOME_DETAIL_KEYS.HOUSE_TYPE]: {
        name: INCOME_DETAIL_KEYS.HOUSE_TYPE,
        elementtype: FORM_ELEMENT_TYPE.SELECT,
        label: "House Type",
        placeholder: "House Type",
        isDisplayLabel: true,
        value: null,
        source: Object.entries(HOUSE_TYPE_MAPPING).map(([value, label]) => ({
            label,
            value,
        })),
    },
    [INCOME_DETAIL_KEYS.EMPLOYMENT_TYPE]: {
        name: INCOME_DETAIL_KEYS.EMPLOYMENT_TYPE,
        elementtype: FORM_ELEMENT_TYPE.SELECT,
        label: "Employment Type",
        placeholder: "Employment Type",
        isDisplayLabel: true,
        value: null,
        source: Object.entries(EMPLOYMENT_TYPE_MAPPING).map(
            ([value, label]) => ({
                label,
                value,
            })
        ),
    },
    [INCOME_DETAIL_KEYS.MONTHLY_INCOME]: {
        name: INCOME_DETAIL_KEYS.MONTHLY_INCOME,
        label: "Monthly Income",
        elementtype: FORM_ELEMENT_TYPE.NUMBER,
        placeholder: "Monthly Income",
        value: null,
        type: "number",
    },
    [INCOME_DETAIL_KEYS.REQUIRED_LOAN_AMOUNT]: {
        name: INCOME_DETAIL_KEYS.REQUIRED_LOAN_AMOUNT,
        label: "Required Load Amount",
        elementtype: FORM_ELEMENT_TYPE.NUMBER,
        placeholder: "Required Load Amount",
        value: null,
        type: "number",
        min: 0,
    },
    [INCOME_DETAIL_KEYS.REASON_FOR_LOAN]: {
        name: INCOME_DETAIL_KEYS.REASON_FOR_LOAN,
        label: "Reason for Loan",
        elementtype: FORM_ELEMENT_TYPE.SELECT,
        isDisplayLabel: true,
        value: null,
        placeholder: "Reason for Loan",
        source: [
            { label: "Home Renovation", value: "Home Renovation" },
            { label: "Medical Bills", value: "Medical Bills" },
            { label: "Business Expenses", value: "Business Expenses" },
            { label: "Investment", value: "Investment" },
            { label: "Education Expenses", value: "Education Expenses" },
            { label: "Wedding Expenses", value: "Wedding Expenses" },
            { label: "Travel", value: "Travel" },
            {
                label: "Clearing current loan liabilities",
                value: "Clearing current loan liabilities",
            },
            { label: "Others", value: "Others" },
        ],
    },
};

export const VALIDATION_METHOD = {
    name: (value) => nameValidator(value).errorType,
    phoneNumber: (value) =>
        mobileNumberValidator(value) ? "" : "Enter Correct Phone Number",
    panNumber: (value) =>
        panValidator(value) ? "" : "Please enter right PAN no.",
    pincode: (value) =>
        pincodeValidator(value) ? "" : "Please recheck the PIN code",
    dob: (value) => ageValidator(value),
};

export const API_FORM_STEPS = {
    FORM_1_PENDING: "FORM_1_PENDING",
    FORM_1_COMPLETED: "FORM_1_COMPLETED",
    FORM_2_PENDING: "FORM_2_PENDING",
    FORM_2_COMPLETED: "FORM_2_COMPLETED",
    FORM_3_PENDING: "FORM_3_PENDING",
    FORM_3_COMPLETED: "FORM_3_COMPLETED",
};

export const ERROR_CODES = {
    FILE_SIZE_EXCEEDED: "C2BSELFIN410",
};

export const DETAILS_PANEL_CONFIG = {
    [API_FORM_STEPS.FORM_1_PENDING]: {
        PAN_DETAILS: true,
        INCOME_DETAILS: false,
        DOCUMENTS: false,
        SHOW_VERIFY_ALL_DOCS: false,
    },
    [API_FORM_STEPS.FORM_1_COMPLETED]: {
        PAN_DETAILS: true,
        INCOME_DETAILS: true,
        DOCUMENTS: false,
        SHOW_VERIFY_ALL_DOCS: false,
    },
    [API_FORM_STEPS.FORM_2_COMPLETED]: {
        PAN_DETAILS: true,
        INCOME_DETAILS: true,
        DOCUMENTS: true,
        SHOW_VERIFY_ALL_DOCS: false,
    },
    [API_FORM_STEPS.FORM_3_COMPLETED]: {
        PAN_DETAILS: true,
        INCOME_DETAILS: true,
        DOCUMENTS: true,
        SHOW_VERIFY_ALL_DOCS: true,
    },
};

export const STATUS_MAPPING = {
    PENDING: "PENDING",
    UNDER_VERIFICATION: "UNDER_VERIFICATION",
    REJECTED: "REJECTED",
    VERIFIED: "VERIFIED",
};

export const DOCUMENT_STATUS = {
    [STATUS_MAPPING.PENDING]: "Pending",
    [STATUS_MAPPING.UNDER_VERIFICATION]: "Under Verification",
    [STATUS_MAPPING.REJECTED]: "Verification Failed",
    [STATUS_MAPPING.VERIFIED]: "Verified",
};

export const UPLOADED_BY = "Agent";

export const TOAST_MESSAGES = {
    DEFAULT: {
        ERROR: {
            message: "Something went wrong",
            id: "error",
        },
    },
    DOCUMENT_UPLOAD: {
        SUCCESS: {
            message: "Document Uploaded Successfully",
            id: "doc-upload-success",
        },
        ERROR: {
            id: "doc-upload-error",
        },
    },
    FETCH_DETAILS: {
        ERROR: {
            id: "fetch-details-error",
        },
    },
    UPDATE_DETAILS: {
        ERROR: {
            message: "",
            id: "update-details-error",
        },
        SUCCESS: {
            message: "Details Updated Successfully",
            id: "update-details-success",
        },
    },
    VERIFY_DOCUMENT: {
        SUCCESS: {
            message: (type) => `Document ${type} successfully`,
            id: "doc-verify-success",
        },
        ERROR: {
            message: "Document verification failed",
            id: "doc-verify-failed",
        },
    },
    SYNC_AGENTS: {
        SUCCESS: {
            message: "Synced Agents list successfully",
            id: "agent-sync-success",
        },
        ERROR: {
            message: "Failed to fetch agent list",
            id: "agent-fetch-error",
        },
    },
};

export const VERIFICATION_STATUS = {
    approve: "VERIFIED",
    reject: "REJECTED",
};

export const style = {
    background: "#FFFFFF",
    minHeight: "180px",
    border: "15px solid #EBF5FF",
    boxShadow: "unset",
    borderRadius: "8px",
};

export const headerStyle = {
    color: "#003E72",
    fontWeight: "600",
    fontSize: "18px",
};

export const UPDATE_CALL_STATUS_BINDINGS = {
    [ASSIGNED_LEAD_SUBTABS.DIALER_LEADS]: {
        ALLOWED_STATUSES: [
            "NOT_CONNECTED",
            "FOLLOW_UP",
            "DROP_LEAD",
            "INTERESTED_FRESH",
        ],
        BINDINGS: {},
    },
    [ASSIGNED_LEAD_SUBTABS.CONTACT_INTERESTED]: {
        ALLOWED_STATUSES: [
            "NOT_CONNECTED",
            "FOLLOW_UP",
            "DROP_LEAD",
            "SUCCESS",
        ],
        BINDINGS: {},
    },
    [ASSIGNED_LEAD_SUBTABS.ALL_DOCS_COLLECTED]: {
        ALLOWED_STATUSES: [
            "NOT_CONNECTED",
            "FOLLOW_UP",
            "DROP_LEAD",
            "SUCCESS",
        ],
        BINDINGS: {},
    },
};