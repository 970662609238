import { Dialog } from "@progress/kendo-react-dialogs";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { FormCheckbox, FormComboBox } from "../../../../../../components";
import { carOwnershipTypes } from "../../pr-automation/viewModel";
import { Button } from "@progress/kendo-react-buttons";
import {
  getLoginUserEmail,
  showToastMessage,
} from "../../../../../../utils/utils";
import { PrAutomationService } from "../../../../../../services";
import { PR_AUTOMATION } from "../../../../../../store/types";
import { useDispatch } from "react-redux";
import CustomTitle from "../../shared/custom-title";
const SelectOwnershipType = (props) => {
  const { toggleInitiatePr, appointmentId, fetchOrderDetails } = props || {};
  const dispatch = useDispatch();

  const handleSubmit = (props) => {
    const { type } = props || {};
    const payload = {
      appointmentId,
      ownershipType: type?.value,
      username: getLoginUserEmail(),
      source: "c2b-internal-panel",
    };
    dispatch({ type: PR_AUTOMATION.FETCH_LOADING_START });
    PrAutomationService.initiatePr(payload)
      .then((res) => {
        if (res && res.status === 200) {
          showToastMessage("PR Initiated Successful", true);
          dispatch(
            fetchOrderDetails({
              appointmentId,
              documentCodes: ["AADHAR_CARD", "PAN_CARD_COPY"],
            })
          );
          toggleInitiatePr();
        }
      })
      .catch((error) => {
        showToastMessage(error?.message || error?.error, false);
      })
      .finally(() => {
        dispatch({ type: PR_AUTOMATION.FETCH_LOADING_END });
      });
  };

  return (
    <Dialog
      title={CustomTitle("What is the car ownership type?")}
      onClose={toggleInitiatePr}
      width={"25%"}
      contentStyle={{ width: "auto" }}
    >
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <FormElement horizontal={true}>
            <div className="ownership-type-input">
              <Field
                id={"type"}
                name={"type"}
                label={"Car ownership type:"}
                placeholder={"Select..."}
                component={FormComboBox}
                textField="label"
                dataItemKey="value"
                data={carOwnershipTypes || []}
                isDisplayLabel={true}
              />
            </div>
            <Field
              id={"confirm"}
              name={"confirm"}
              label={"Confirm if the customer has agreed to the offered price"}
              component={FormCheckbox}
              className="ownership-type-checkbox"
            />
            <div className="k-form-buttons">
              <Button
                themeColor="primary"
                size="large"
                type="submit"
                disabled={
                  !formRenderProps.valueGetter("type") ||
                  !formRenderProps.valueGetter("confirm")
                }
              >
                Continue
              </Button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default SelectOwnershipType;
