import React, { useEffect, useRef, useState, createContext } from 'react';
import { useSession } from '@descope/react-sdk';
import LmsActivityPopup from '../components/lms-activity-popup';
import { commonApis } from '../services/commonApi';
import useLazyQuery from '../hooks/useLazyQuery';
import { isLmsPage } from '../utils/utils';
const ACTIVE_TIME_GRACE=1000*60*10;

const LmsStateManagementContext = createContext();

export const LmsStateManagementContextProvider = props => {

  const timeoutRef = useRef(); 
  const { isAuthenticated } = useSession();
  const [ isRaActive, setIsRaActive] = useState(false);
  const [ showPopup, setShowPopup] = useState(false);
  const { apiCbFunction: getRaActivity } = useLazyQuery(commonApis.getRaActivity);
  const { apiCbFunction: addRaActivity } = useLazyQuery(commonApis.addRaActivity);

  const addRaActivityHandler = async ({activityStatus,inactivityReason,activity}) =>{
    const response = await getRaActivity()
    if(response?.activityStatus?.toUpperCase() !== activityStatus?.toUpperCase()){
      addRaActivity({
        body:{
          source: "C2B_INTERNAL_PORTAL",
          ...(activityStatus && { activityStatus: activityStatus }),
          ...(inactivityReason && { inactivityReason: inactivityReason }),
          ...(activity && { activity: activity }),
          ...(response?.id && { previousLogId: response?.id }),
          ...(response?.inactivityReason && { previousInactivityReason: response?.inactivityReason }),
        }
      })
    }
    if(activityStatus == 'ACTIVE'){
      setIsRaActive(true)
    }
    else{
      setIsRaActive(false)
    }
  }

  const tabSwitchHandler = async () => {
    if(isAuthenticated){
      if(window?.document?.visibilityState== 'hidden'){
        timeoutRef.current = setTimeout(()=>{
          addRaActivityHandler({activityStatus:'INACTIVE',inactivityReason:'AUTO'})
          setShowPopup(true)
          setIsRaAcday(false)
        },ACTIVE_TIME_GRACE)
      }
      else{
        clearTimeout(timeoutRef.current)
        const res = await getRaActivity()
        if(res && res?.activityStatus == 'ACTIVE'){
          setShowPopup(false)
          setIsRaActive(true)
        }
      }
    }
  }
  useEffect(() => {
    window?.document.addEventListener('visibilitychange', tabSwitchHandler, false);
    
    const getActivityStatus = async () => {
      const res = await getRaActivity()
      if(res && res?.activityStatus == 'INACTIVE'){
        setShowPopup(true)
        setIsRaActive(false)
      }
      else{
        setIsRaActive(true)
        addRaActivityHandler({activityStatus:'ACTIVE'})
      }
    } 
    if(isAuthenticated){
      getActivityStatus()
    }

    return () => {
      window?.document.removeEventListener('visibilitychange', tabSwitchHandler, false);
    };
  }, [isAuthenticated]);

  return (
    <LmsStateManagementContext.Provider
      value={{
        isRaActive,
        setIsRaActive,
        showPopup,
        setShowPopup,
        addRaActivityHandler
      }}
    >
        <>
          {
            showPopup && isAuthenticated && !isRaActive && isLmsPage() && 
            <LmsActivityPopup/>
          }
          {props.children}
        </>
    </LmsStateManagementContext.Provider>
  );
};

export default LmsStateManagementContext;
