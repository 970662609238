import { useEffect, useState } from "react";
import CustomGrid from "../../../../../common/custom-grid/component";
import styles from "./styles.module.css";
import { useDispatch } from "react-redux";
import { fetchInspectionDetails } from "../../../store/actions/buyback";
import { Button } from "@progress/kendo-react-buttons";
import SuccessIcon from "../../../../../assets/img/successIcon";
import DangerIcon from "../../../../../assets/img/dangerIcon";
import { Loader } from "@progress/kendo-react-indicators";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { APPROVAL_ACTION, BUY_BACK_STATE, INSPECTION_REPORT_STATUS } from "../../../common/constants/buyback.constants";
import { showToastMessage } from "../../../../../utils/utils";

const CustomCell = ({ dataItem }) => {
  const { title, old: oldValue, new: newValue } = dataItem;

  return (
    <td>

      <div className={styles.customCellContainer}>
        <div className={styles.cellContent}>
          <span>{oldValue.status === INSPECTION_REPORT_STATUS.GOOD ? <SuccessIcon /> : INSPECTION_REPORT_STATUS.SEVERE ? <DangerIcon /> : <SuccessIcon fill="#F4B242" />}</span>
          <div className={styles.cellTitleContainer}>
            <span className={styles.cellTitle}>{title}</span>
            <span className={styles.cellDescription}>{oldValue.description}</span>
          </div>
        </div>

        <div className={styles.cellContent}>
          <span>{newValue.status === "GOOD" ? <SuccessIcon /> : <DangerIcon />}</span>
          <div className={styles.cellTitleContainer}>
            <span className={styles.cellTitle}>{title}</span>
            <span className={styles.cellDescription}>{newValue.description}</span>
          </div>
        </div>
      </div>
    </td>
  );
};

const BuyBackDetails = ({ dataItem, toggleDialog }) => {
  const dispatch = useDispatch();
  const [buyBackData, setBuyBackData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState(0);

  useEffect(() => {

    dispatch(fetchInspectionDetails(dataItem?.appointmentId)).then((resp) => {
      setBuyBackData(resp);
    }
    ).catch((err) => {
      showToastMessage(err, false)
    });

    setIsLoading(false);

  }, [])


  const handleSelect = (e) => setSelected(e.selected);

  const renderTabContent = (item) => (
    item.children.map((child) => (
      <CustomGrid
        key={child.title}
        data={child.data || []}
        columnMap={{
          [child.title]: {
            field: child.title,
            label: <div className={styles['report-header-container']}>
              <span className={styles['cellTitle']}>{child.title}</span>

              <div className={styles['header-text']}>
                <span className={styles['cellTitle']}>Old</span>
                <span className={styles['cellTitle']}>New</span>

              </div>


            </div>,

            cell: CustomCell,
          }
        }}
        displayedColumns={[child.title]}
      />
    ))
  );

  const renderTabs = () => (
    <TabStrip className={styles.tabs} selected={selected} onSelect={handleSelect}>
      {buyBackData.map((item) => (
        <TabStripTab key={item.title} title={item.title}>
          {renderTabContent(item)}
        </TabStripTab>
      ))}
    </TabStrip>
  );

  const renderButtons = () => (
    <div className={styles.modalButton}>
      <Button className={styles['accept-btn']} onClick={() => toggleDialog(APPROVAL_ACTION.APPROVE)}>Approve</Button>
      <Button className={styles['reject-btn']} onClick={() => toggleDialog(APPROVAL_ACTION.REJECT)}>Reject</Button>
    </div>
  );

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  if (buyBackData.length === 0) {
    return <span className={styles['no-report-found']}>No reports found</span>;
  }

  return (
    <>
      {renderTabs()}
      {dataItem?.buybackState !== BUY_BACK_STATE[0].id && dataItem?.buybackState !== BUY_BACK_STATE[1].id && renderButtons()}
    </>
  );
};

export default BuyBackDetails;