import { challan_item_keys } from "../../../../components/challan-detail-revamp/viewModel";
import {
  FORM_ELEMENT_TYPE,
  CHALLAN_MODES,
} from "../../../../../../utils/constants/values.constants";

export const DD_DATA_SOURCES = {
  [challan_item_keys.CHALLAN_TYPE]: [
    { label: "All Challans", value: "" },
    ...CHALLAN_MODES,
  ],
  [challan_item_keys.CHALLAN_OFFENSE_ID]: [],
  [challan_item_keys.STATUS]: [
    { value: "ASSIGNED", label: "Assigned" },
    { value: "SUBMITTED", label: "UnAssigned" },
    { value: "AGENT_ASSIGNED", label: "Agent Assigned" },
    { value: "PAID", label: "Paid" },
    { value: "UNABLE_TO_FULFILL", label: "Unable to Fulfill" },
  ],
  [challan_item_keys.REGION]: [],
  [challan_item_keys.CHALLAN_COURT]: [],
  [challan_item_keys.AGENT_NAME]: [],
};

export const dispose_filters_formData = {
  [challan_item_keys.CHALLAN_TYPE]: {
    name: challan_item_keys.CHALLAN_TYPE,
    label: "Challan Mode",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
  },
  // [challan_item_keys.CHALLAN_OFFENSE_ID]: {
  //   name: challan_item_keys.CHALLAN_OFFENSE_ID,
  //   label: "Offense Type",
  //   elementtype: FORM_ELEMENT_TYPE.SELECT,
  // },
  [challan_item_keys.STATUS]: {
    name: challan_item_keys.STATUS,
    label: "Payment Status",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
  },
  // [challan_item_keys.OFFENCE_DATE]: {
  //   name: challan_item_keys.OFFENCE_DATE,
  //   label: "Challan Date",
  //   elementtype: FORM_ELEMENT_TYPE.DATE,
  // },
  [challan_item_keys.REGION]: {
    name: challan_item_keys.REGION,
    label: "Region",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
  },
  [challan_item_keys.CHALLAN_COURT]: {
    name: challan_item_keys.CHALLAN_COURT,
    label: "Court",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
  },
  [challan_item_keys.AGENT_NAME]: {
    name: challan_item_keys.AGENT_NAME,
    label: "Agent",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
  },
};
