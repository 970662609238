import dayjs from "dayjs";
import AudioPlayer from "../components/AudioPlayer";
import detailsClasses from "../styles/Details.module.css";

export const CALL_HISTORY_KEY = {
    AGENT: "agentId",
    REMARKS: "remarks",
    CALL_STATUS: "status",
    FOLLOW_UP_TIME: "followUpTimestamp",
    CALLED_AT: "createdAt",
    RECORDING: "recording",
    SUB_DISPOSITION_STATUS: "subDispositionStatus",
    DISPOSITION_ITEM: "dispositionItem",
};

export const CALL_HISTORY_COLS = {
    [CALL_HISTORY_KEY.CALL_STATUS]: {
        field: CALL_HISTORY_KEY.CALL_STATUS,
        label: "Status",
    },
    [CALL_HISTORY_KEY.CALLED_AT]: {
        field: CALL_HISTORY_KEY.CALLED_AT,
        label: "Called At",
    },
    [CALL_HISTORY_KEY.FOLLOW_UP_TIME]: {
        field: CALL_HISTORY_KEY.FOLLOW_UP_TIME,
        label: "Follow Up Time",
    },
    [CALL_HISTORY_KEY.REMARKS]: {
        field: CALL_HISTORY_KEY.REMARKS,
        label: "Remarks",
    },
    [CALL_HISTORY_KEY.DISPOSITION_ITEM]: {
        field: CALL_HISTORY_KEY.DISPOSITION_ITEM,
        label: "Disposition Status",
    },
    [CALL_HISTORY_KEY.SUB_DISPOSITION_STATUS]: {
        field: CALL_HISTORY_KEY.SUB_DISPOSITION_STATUS,
        label: "Sub Disposition Status",
    },
    [CALL_HISTORY_KEY.RECORDING]: {
        field: CALL_HISTORY_KEY.RECORDING,
        label: "Recording",
    },
};

export const ADMIN_CALL_HISTORY_COLS = structuredClone(CALL_HISTORY_COLS);

export const AGENT_CALL_HISTORY_COLS = structuredClone(CALL_HISTORY_COLS);
delete AGENT_CALL_HISTORY_COLS[CALL_HISTORY_KEY.RECORDING];

export const CALL_HISTORY_VISIBLE_COLS = {
    ...CALL_HISTORY_COLS,
    [CALL_HISTORY_KEY.CALLED_AT]: {
        ...CALL_HISTORY_COLS[CALL_HISTORY_KEY.CALLED_AT],
        cell: (props) => {
            const { createdAt = "" } = props?.dataItem;
            return (
                <td>
                    {createdAt && dayjs(createdAt).isValid()
                        ? dayjs(createdAt).format("DD-MM-YYYY hh:mm A")
                        : "-"}
                </td>
            );
        },
    },
    [CALL_HISTORY_KEY.FOLLOW_UP_TIME]: {
        ...CALL_HISTORY_COLS[CALL_HISTORY_KEY.FOLLOW_UP_TIME],
        cell: (props) => {
            const { followUpTimestamp = "" } = props?.dataItem;
            return (
                <td>
                    {followUpTimestamp && dayjs(followUpTimestamp).isValid()
                        ? dayjs(followUpTimestamp).format("DD-MM-YYYY hh:mm A")
                        : "-"}
                </td>
            );
        },
    },
    [CALL_HISTORY_KEY.RECORDING]: {
        ...CALL_HISTORY_COLS[CALL_HISTORY_KEY.RECORDING],
        cell: (props) => {
            const { createdAt = "", callAudioUrl = "" } = props?.dataItem;
            return (
                <td className={detailsClasses.audioPlayerContainer}>
                    {createdAt && callAudioUrl && callAudioUrl.length > 0 ? (
                        <AudioPlayer src={callAudioUrl} id={createdAt} />
                    ) : (
                        "-"
                    )}
                </td>
            );
        },
    },
};
