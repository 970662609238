import { Form, FormElement } from "@progress/kendo-react-form";
import { useDispatch } from "react-redux";
import { FormNumericTextBox } from "../../../../../components";
import { DealerProcureService } from "../../../../../services";
import { DEALER_PROCUREMENT } from "../../../../../store/types";
import {
  dealerPayoutOpenIssueStatuses,
  dealerPayoutPaymentStatuses,
  FORM_ELEMENT_TYPE,
} from "../../../../../utils/constants/values.constants";
import { showToastMessage } from "../../../../../utils/utils";
import PaymentAction from "../payment-action";

const PaySection = (props) => {
  const {
    detailsData,
    fetchPayouts,
    updatedFlagData,
    leadId,
    handleFormChange,
  } = props || {};
  const dispatch = useDispatch();

  const handleRetry = () => {
    const payload = {};
    const action = "sync-payout";
    const toastMessage = "Payment Re-tried";

    dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_START });
    DealerProcureService.updatePayoutsFlag(leadId, action, payload)
      .then(() => {
        showToastMessage(toastMessage, true);
        fetchPayouts(leadId);
      })
      .catch((error) => {
        showToastMessage(error?.message, false);
      })
      .finally(() => {
        dispatch({ type: DEALER_PROCUREMENT.FETCH_LOADING_END });
      });
  };

  const CustomDialogTitleBar = (props) => {
    return <div className="k-font-medium k-font-size-xl">{props.title}</div>;
  };
  return (
    <Form
      render={() => (
        <FormElement
          style={{
            marginTop: "20px",
          }}
        >
          <fieldset className={"k-form-fieldset"}>
            <div className="align-center k-mb-2">
              <span
                style={{
                  color: "#0d6efd",
                  fontWeight: "600",
                  fontSize: "16px",
                  marginRight: "10px",
                }}
              >
                PAY SECTION
              </span>
              <span className="status-inprogress">
                {updatedFlagData?.status?.value?.replace(/_/g, " ")}
              </span>
            </div>
            <div className="box-shadow k-pb-5">
              {dealerPayoutOpenIssueStatuses.includes(
                updatedFlagData?.status?.value
              ) && (
                <div className="dealer-payout-grievance-form">
                  {Object.keys(updatedFlagData)?.map((key) => {
                    const elementMetaData = updatedFlagData[key];
                    if (
                      elementMetaData.elementtype ===
                      FORM_ELEMENT_TYPE.NUMERIC_TEXTBOX
                    ) {
                      return (
                        <>
                          <FormNumericTextBox
                            id={key}
                            key={key}
                            label={updatedFlagData?.[key]?.label}
                            {...elementMetaData}
                            value={updatedFlagData?.[key]?.value}
                            min={0}
                            type="number"
                            onChange={handleFormChange}
                            spinners={false}
                            format=""
                            valid={updatedFlagData?.[key]?.value}
                            touched={true}
                            validationMessage={
                              !updatedFlagData?.[key]?.value
                                ? "Please enter custom amount"
                                : ""
                            }
                          />
                        </>
                      );
                    }
                  })}
                </div>
              )}
              {dealerPayoutPaymentStatuses.includes(
                updatedFlagData?.status?.value
              ) && (
                <PaymentAction
                  titleComponent={CustomDialogTitleBar}
                  updatedFlagData={updatedFlagData}
                  handleRetry={handleRetry}
                  detailsData={detailsData}
                  fetchPayouts={fetchPayouts}
                />
              )}
            </div>
          </fieldset>
        </FormElement>
      )}
    />
  );
};
export default PaySection;
