import { LMS } from "../types";

const INITIAL_STATE = {
  isLoading: false,
  error: {},
  appointmentId: "",
};

const Lms = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LMS.FETCH_LOADING_START:
      return {
        ...state,
        isLoading: true,
      };
    case LMS.FETCH_LOADING_END:
      return {
        ...state,
        isLoading: false,
      };
    case LMS.FETCH_VALIDATE_APPID_SUCCESS:
      return {
        ...state,
        appointmentId:
          action.payload.data &&
          action.payload.data.detail &&
          action.payload.data.detail.appointmentId,
        error: {},
      };
    case LMS.FETCH_VALIDATE_APPID_FAILURE:
      return {
        ...state,
        appointmentId: "",
        error: action.payload,
      };
    default:
      return state;
  }
};
export default Lms;
