import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import FormCardLayout from "../../../../../../operations/components/layout-form-card"
import { setDefaultValueToForm } from "../../../../../../../utils/utils"
import { REMARKS_FORM_DATA } from "./view-models"

const HYPO_QC_STATUS_DATA = {
    PENDING: "Pending",
    OK_TO_PROCURE: "Ok To Procure",
    VENDOR_INTERVENTION_REQUIRE: "Vendor intervention require",
    SENT_TO_VENDOR: "Sent To vendor",
    APPROVED_BY_VENDOR: "Approved by vendor",
    REJECTED_BY_VENDOR: "Rejected by vendor",
}

const TeamRemarkForm = forwardRef((props, ref) => {

    const { data } = props
    const [formData, setFormData] = useState(REMARKS_FORM_DATA)

    useImperativeHandle(ref, () => ({
        formData
    }));

    useEffect(() => {
        if (data) {
            const updatedData = { ...data }
            updatedData['hypoQcStatus'] = HYPO_QC_STATUS_DATA?.[data?.['hypoQcStatus']]
            setFormData(prevFormData => setDefaultValueToForm(prevFormData, updatedData));
        }
    }, [data]);

    return (
        <FormCardLayout
            headerTitle={"Team Remarks"}
            formData={{ ...formData }}
            handleChange={() => { }}
            isShowCTA={false}
            cardStyle={{ border: "2px solid #EBF5FF", minHeight: '0px' }}
        />
    )
})

export default TeamRemarkForm;