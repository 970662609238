import React, { useState, useEffect } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import CustomGrid from "../../../../common/custom-grid/component";
import { useDispatch } from "react-redux";
import {
  updateAssignmentTypeParams,
  updateChallanItemAssignmentTypeParams,
  // updateChallanItemDelayedParams,
} from "../../../../store/actions/ops-challan";
import {
  FF_PAGE_TYPES,
  ASSIGNMENT_TYPES,
  ADMIN_ASSIGNMENT_TYPES,
  NUMBERS,
} from "../../../../utils/constants/values.constants";
import { getFFUserType } from "../../../../utils/utils";
import { USER_ROLE_KEYS } from "../../../../utils/constants/menu.constants";
import { Loader } from "../../../../common/Loader";
import { updateServiceHistoryAssignmentTypeParams } from "../../../../store/actions/service-history";
import { updateVerificationAssignmentTypeParams } from "../../../../store/actions/ops-verification";
const TabGridLayout = (props = {}) => {
  const {
    isLoading,
    type = "",
    columns,
    data,
    count,
    selectable,
    selectedField,
    idField,
    onRowSelectionChange,
    onHeaderSelectionChange,
    isPagination,
    pageChange,
    paginationParams,
    sortable,
    sortParms,
    sortChange,
    isDataExport,
    onExportDataEnd,
    customRow,
    userAssignments,
  } = props;
  const userType = getFFUserType();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(NUMBERS.ZERO);
  const [tabItems, setTabItems] = useState([]);
  useEffect(() => {
    let tabs = [];
    switch (type) {
      case FF_PAGE_TYPES.DISPOSE_VIEW:
        tabs = [{ label: "All Requests", value: NUMBERS.ZERO }];
        break;
      case FF_PAGE_TYPES.FINDERS:
        tabs =
          userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN
            ? ADMIN_ASSIGNMENT_TYPES
            : ASSIGNMENT_TYPES;
        break;
      case FF_PAGE_TYPES.DISPOSE:
        tabs =
          userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN
            ? ADMIN_ASSIGNMENT_TYPES
            : ASSIGNMENT_TYPES;
        tabs = [...tabs, { label: "Delayed", value: NUMBERS.FOUR }];
        break;
      case FF_PAGE_TYPES.SERVICE_HISTORY:
        tabs =
          userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN
            ? ADMIN_ASSIGNMENT_TYPES
            : ASSIGNMENT_TYPES;
        break;
      case FF_PAGE_TYPES.VERIFICATION:
        tabs = ASSIGNMENT_TYPES;

      default:
        break;
    }
    setTabItems(tabs);
  }, [type]);

  const handleSelect = (e) => {
    const currentTabInfo = tabItems[e.selected];
    setSelectedTab(e.selected);
    switch (type) {
      case FF_PAGE_TYPES.FINDERS:
        dispatch(updateAssignmentTypeParams(currentTabInfo.value));
        break;
      case FF_PAGE_TYPES.DISPOSE:
      case FF_PAGE_TYPES.DISPOSE_VIEW:
        dispatch(updateChallanItemAssignmentTypeParams(currentTabInfo.value));
        break;
      case FF_PAGE_TYPES.SERVICE_HISTORY:
        dispatch(
          updateServiceHistoryAssignmentTypeParams(currentTabInfo.value)
        );
        break;
      case FF_PAGE_TYPES.VERIFICATION:
        dispatch(updateVerificationAssignmentTypeParams(currentTabInfo.value));
        break;
      default:
        break;
    }
  };
  const getDisplayedCoulmns = () => {
    const displayColumns = Object.values(columns).filter((item) => !item.hide);
    return displayColumns.map((item) => item.field);
  };
  useEffect(() => {
    if (!!tabItems.length) {
      const index = tabItems.findIndex(
        (item) => item.value === userAssignments
      );
      setSelectedTab(index);
    }
  }, [tabItems, userAssignments]);

  return (
    <div>
      <TabStrip selected={selectedTab} onSelect={handleSelect}>
        {tabItems.map((item) => (
          <TabStripTab title={item.label}>
            {!!isLoading ? (
              <Loader />
            ) : (
              <CustomGrid
                displayedColumns={getDisplayedCoulmns()}
                columnMap={columns}
                data={data}
                count={count}
                selectedField={selectedField}
                idField={idField}
                onSelectionChange={onRowSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
                selectable={selectable}
                isPagination={isPagination}
                pageChange={pageChange}
                paginationParams={paginationParams}
                sortable={sortable}
                sortParms={sortParms}
                onSortChange={sortChange}
                isDataExport={isDataExport}
                onExportDataEnd={onExportDataEnd}
                fixedHeight={{ height: "650px" }}
                rowRender={customRow}
              />
            )}
          </TabStripTab>
        ))}
      </TabStrip>
    </div>
  );
};
export default TabGridLayout;
