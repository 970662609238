import { Button } from '@progress/kendo-react-buttons';
import { Popover } from '@progress/kendo-react-tooltip';
import React, { useRef, useState } from 'react'
import FileView from '../../../../common/file-view';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';


const FinderProofView = (props) => {
    const {handleFileView, fkChallanRequest, challanFinderProofs} = props || {};
    
	const [show, setShow] = useState(false);
    const anchor = useRef(null);

    const handleClick = () => {
		setShow(!show);
	};

  return (
    <div>
        <Button
				themeColor={"primary"}
                fillMode={"outline"}
				ref={anchor}
                disabled={!challanFinderProofs?.length}
				onClick={handleClick}>
				    View Files
			</Button>
        <Popover
			show={show}
			anchor={anchor.current && anchor.current.element}
			position={"right"}
			className={"layout-popover"}>
                <GridLayout
					gap={{ rows: 5 }}
                    style={{width:"350px"}}
                >
                    {challanFinderProofs?.map((proof,index) => (
                        <GridLayoutItem row={index + 1} style={{margin:"16px 0px"}}>
                            <span>Proof {index + 1}</span>
                            <FileView handleFileView={() => handleFileView(fkChallanRequest, proof)}/>
                        </GridLayoutItem>
                    ))}
                    </GridLayout>
        </Popover>
    </div>
  )
}

export default FinderProofView;
