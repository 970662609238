import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TextBox } from '@progress/kendo-react-inputs';
import CustomGrid from '../../../../common/custom-grid/component';
import { Loader } from '../../../../common/Loader';
import { EntityCell, getDisplayColumns, table_column_keys, table_column_labels, UpdateSegmentationCell } from '../components/table-cells'
import styles from './styles.module.css';
import { getUserRoles } from '../../../../utils/utils';
import { USER_ROLE_KEYS } from '../../../../utils/constants/menu.constants';

const SearchIcon = () => {
  return (<><span className="k-icon k-font-icon k-i-search search-icon"></span></>)
}
const TableData = ({ pageParam, pageChange }) => {

  const [searchTerm, setSearchTerm] = useState('');
  const { segmentationData, isLoading } = useSelector((state) => state.segmentationData);

  const userRoles = getUserRoles();


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = searchTerm
    ? segmentationData.content?.filter((item) => {
      return item?.name?.toLowerCase().includes(searchTerm?.toLowerCase().trim())
    }) || []
    : segmentationData.content || [];

  useEffect(() => {

    if (userRoles?.includes(USER_ROLE_KEYS.SEGMENTATION_WRITER)) {
      setColumns({
        ...segmentationColumns,
        [table_column_keys.UPDATE_SEGMENT]: {
          field: table_column_keys.UPDATE_SEGMENT,
          label: "Update",
          width: "100px",
          cell: UpdateSegmentationCell,
        }
      })
    }

  }, [])


  const segmentationColumns = {

    [table_column_keys.SEGMENT_ID]: {
      field: table_column_keys.SEGMENT_ID,
      label: table_column_labels.SEGMENT_ID,
      width: "100px",
    },
    [table_column_keys.NAME]: {
      field: table_column_keys.NAME,
      label: table_column_labels.NAME,
      width: "150px",
    },
    [table_column_keys.DESCRIPTION]: {
      field: table_column_keys.DESCRIPTION,
      label: table_column_labels.DESCRIPTION,
      width: "250px",
    },
    [table_column_keys.TYPE]: {
      field: table_column_keys.TYPE,
      label: table_column_labels.TYPE,
      width: "100px",
    },
    [table_column_keys.CREATED_BY]: {
      field: table_column_keys.CREATED_BY,
      label: table_column_labels.CREATED_BY,
      width: "150px",
    },
    [table_column_keys.ENTITY_IDS]: {
      field: table_column_keys.ENTITY_IDS,
      label: table_column_labels.ENTITY_IDS,
      width: "100px",
      cell: EntityCell,
    },



  };

  const [columns, setColumns] = useState(segmentationColumns);

  return (
    <>
      <div className={styles['search-container']}>
        <TextBox
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder='Search by segment name'
          className={styles['search-input']}
          prefix={SearchIcon}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <CustomGrid
          displayedColumns={getDisplayColumns(columns)}
          data={filteredData}
          columnMap={columns}
          pageSize={pageParam.pageSize}
          paginationParams={{
            pageNumber: pageParam?.pageNumber,
            pageSize: pageParam?.pageSize,
          }}
          pageChange={pageChange}
          isPagination
          pageSizes={[10, 25, 50, 100, 200]}
          count={segmentationData?.totalElements}
        />
      )}
    </>
  );
};
export default TableData;