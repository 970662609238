import { FormComboBox } from "../../../../../../components";
import { challan_request_keys } from "../../../viewModels";
import { Button } from "@progress/kendo-react-buttons";
import { useState, useEffect } from "react";
import { formData, DD_DATA_SOURCES } from "./viewModel";
import {
  FORM_ELEMENT_TYPE,
  CHALLAN_SOURCE_KEYS,
} from "../../../../../../utils/constants/values.constants";
import { updateRequestFiltersParams } from "../../../../../../store/actions/ops-challan";
import { useDispatch } from "react-redux";
import { ChallanService } from "../../../../../../services";
const FinderFilters = () => {
  const [filters, setFilters] = useState(formData);
  const [sources, setSources] = useState(DD_DATA_SOURCES);
  const [isFilteringComplete, setIsFilteringComplete] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    ChallanService.fetchAllSources().then((resp) => {
      const srcs = resp?.detail?.map((item) => {
        return {
          value: item.code,
          label: item.name,
        };
      });
      const filterSources = srcs.filter(
        (item) => item.label.toUpperCase() !== CHALLAN_SOURCE_KEYS.VAS
      );
      setSources((items) => ({
        ...items,
        [challan_request_keys.CHALLAN_SOURCE]: [
          { label: "ALL REQUESTS", value: "" },
          ...filterSources,
        ],
      }));
    });
  }, []);

  const onInputChange = (e) => {
    const { value } = e;
    const { id } = e.target.props;
    const key = id || e.target.name;
    setFilters((items) => ({
      ...items,
      [key]: { ...filters[key], value: value },
    }));
  };

  const onFilterApply = () => {
    let filterParams = {};
    Object.values(filters).map((item) => {
      const itemValue = item?.value;
      if (!!itemValue && item.elementtype === FORM_ELEMENT_TYPE.SELECT) {
        filterParams = {
          ...filterParams,
          [item.name]: item?.value?.value || "",
        };
      }
    });
    dispatch(updateRequestFiltersParams(filterParams));
  };
  return (
    <div className="ops-dispose-filters">
      {Object.keys(filters).map((key) => {
        const elementMetaData = filters[key];
        switch (elementMetaData.elementtype) {
          case FORM_ELEMENT_TYPE.SELECT:
            return (
              <div className="ops-field-filter">
                <FormComboBox
                  id={key}
                  key={key}
                  onChange={onInputChange}
                  data={sources[key]}
                  textField="label"
                  dataItemKey="value"
                  {...elementMetaData}
                />
              </div>
            );
          default:
            break;
        }
      })}
      <div className="ops-channel-filter-action">
        <Button
          themeColor={"primary"}
          onClick={onFilterApply}
          disabled={isFilteringComplete}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
export default FinderFilters;
