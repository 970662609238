import React, { useState } from "react";
// import { KendoCard } from '../../../components';
import { AgentManager } from "./component/agent-manger";
import { ReasonManger } from "./component/reason-manager";
import {
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import styles from "../assets/style/ff-layout.module.css";

const AdministratorView = () => {
  const [selected, setSelected] = useState(0);
  // const style = {
  //   background: "#F0F2F5",
  //   minHeight: "180px",
  // };

  const handleSelect = (e) => {
    setSelected(e.selected);
  };
  return (
    <>
      <div className="ops-challan-container">
        <div className="ops-grid-layout-container">
          <GridLayout
            gap={{
              rows: 15,
              cols: 10,
            }}
          >
            <GridLayoutItem row={1}>
              <span className={styles.listing_header}>Administrator View</span>
            </GridLayoutItem>
            <GridLayoutItem row={2}>
              <TabStrip selected={selected} onSelect={handleSelect}>
                <TabStripTab title="Agent Manager">
                  <AgentManager />
                </TabStripTab>
                <TabStripTab title="Reason Manager">
                  <ReasonManger />
                </TabStripTab>
              </TabStrip>
            </GridLayoutItem>
          </GridLayout>
        </div>
      </div>
    </>
  );
};

export default AdministratorView;
