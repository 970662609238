import { ProgressBar } from '@progress/kendo-react-progressbars';

import './call-quality.scss';
import { getColor, roundCallScore } from '../../common/utils/utils';

const CallQualityCell = ({ data, setSelectedRow }) => {

  const roundedCallScore = roundCallScore(data?.callScore);
  const progressBarColor = getColor(roundedCallScore).progressBarColor

  return (
    <div className='call-quality-container'>
      <div className='call-quality-header'>
        <span className='quality-number' >{roundedCallScore} / 10</span>
        <span
          className={`k-icon k-i-reorder ${!data.recordingUrl ? 'disabled-quality-icon' : ''}`}
          onClick={data.recordingUrl ? () => setSelectedRow(data.clientCorrelationId) : () => { }}
        />
      </div>
      <ProgressBar
        progressStyle={{
          backgroundColor: progressBarColor,
        }}
        min={0}
        max={10}
        className="progress-bar-container"
        value={roundedCallScore}
      />

    </div>

  )

}


export default CallQualityCell;