import {
  FORM_ELEMENT_TYPE,
  CHALLAN_SOURCE_KEYS,
} from "../../../../utils/constants/values.constants";
import { challan_request_keys } from "../../challan-list/viewModels";
import { FormInput, FormComboBox, FormTextArea } from "../../../../components";
import { ChallanService } from "../../../../services";
import React, { useState } from "react";
const ChallanCreateOrder = (props) => {
  const [sources, setSources] = useState([]);
  const onInputChange = (e) => {
    const { name, value } = e;
    const { id } = e.target.props || {};
    const key = id || e.target.name;
    props.setData({
      ...props.data,
      [key]: { ...props.data[key], value: value },
    });
  };
  React.useEffect(() => {
    ChallanService.fetchAllSources().then((resp) => {
      const srcs = resp?.detail?.map((item) => {
        return {
          value: item.code,
          label: item.name,
        };
      });
      const filterSources = srcs.filter(
        (item) => item.label.toUpperCase() !== CHALLAN_SOURCE_KEYS.VAS
      );
      setSources(filterSources);
    });
  }, []);
  return (
    <div>
      {Object.keys(props.data).map((key) => {
        const elementMetaData = props.data[key];
        switch (elementMetaData.elementtype) {
          case FORM_ELEMENT_TYPE.SELECT:
            return (
              <FormComboBox
                id={key}
                key={key}
                onChange={onInputChange}
                data={
                  key === challan_request_keys.CHALLAN_SOURCE ? sources : []
                }
                textField="label"
                dataItemKey="value"
                value={props.data[key]?.value}
                {...elementMetaData}
              />
            );
          case FORM_ELEMENT_TYPE.TEXTAREA:
            return (
              <FormTextArea
                id={key}
                key={key}
                onChange={onInputChange}
                value={props.data[key]?.value}
                {...elementMetaData}
              />
            );
          default:
            return (
              <FormInput
                id={key}
                key={key}
                onChange={onInputChange}
                value={props.data[key]?.value}
                {...elementMetaData}
              />
            );
        }
      })}
    </div>
  );
};
export default ChallanCreateOrder;
