import coin from "../assets/images/coin.svg";
import bike from "../assets/images/bikr.svg";
import miss from "../assets/images/incentive-miss.svg";
import penalty from "../assets/images/penalty.svg";

export const cj_incentive_keys = {
	NAME: "incentiveName",
	CREATION_DATE: "createdAt",
	CREATED_BY: "createdBy",
	UPDATED_DATE: "updatedAt",
	UPDATED_BY: "updatedBy",
	TYPE: "incentiveType",
	CJ_TYPE: "cjType",
	ID: "idIncentiveDetail",
	CLUSTER: "idClusterList",
	DESCRIPTION: "incentiveDescription",
	IS_RANGE: "isRange",
	VALID_RANGE: "valid_range",
	VALIDITY_START_DATE: "startDate",
	VALIDITY_END_DATE: "endDate",
	STATUS: "status",
	ALERT: "publishOnApp",
	FORMULA_EXP: "formulaExp",
	FORMULA_RESULT: "formulaResult",
	CONDITIONS: "conditionExpression",
	TARGET_MATRIX: "targets",
	ID_CLUSTER: "idCluster",
	NUDGE_ICON: "nudgeIcon",
	NUDGE_DESCRIPTION: "nudgeDescription",
	ID_INCENTIVE_CONDITION: "idIncentiveCondition",
	CLUSTER_NAME: "clusters"
};

export const cj_incentive_columns = {
	[cj_incentive_keys.NAME]: {
		field: cj_incentive_keys.NAME,
		label: "Incentive Name",
	},
	[cj_incentive_keys.UPDATED_BY]: {
		field: cj_incentive_keys.UPDATED_BY,
		label: "Updated By",
	},
	[cj_incentive_keys.TYPE]: {
		field: cj_incentive_keys.TYPE,
		label: "Incentive Type",
	},
	// [cj_incentive_keys.CJ_TYPE]: {
	// 	field: cj_incentive_keys.CJ_TYPE,
	// 	label: "CJ Type",
	// },
	[cj_incentive_keys.CLUSTER_NAME]: {
		field: cj_incentive_keys.CLUSTER_NAME,
		label: "Cluster",
	},
	[cj_incentive_keys.STATUS]: {
		field: cj_incentive_keys.STATUS,
		label: "Active Status",
	},
};

export const getDisplayedCoulmns = (cols) => {
	return Object.keys(cols);
};

export const INCENTIVE_TYPE_KEYS = {
	DAILY: "daily",
	MONTHLY: "monthly",
};

export const INCENTIVE_TYPE_SOURCES = [
	{ label: "Daily", value: INCENTIVE_TYPE_KEYS.DAILY },
	{ label: "Monthly", value: INCENTIVE_TYPE_KEYS.MONTHLY },
];

// export const INCENTIVE_STATUS_SOURCES = [
// 	{ label: "Active", value: "active" },
// 	{ label: "Inactive", value: "inactive" },
// ];

export const DD_DATA_SOURCES = {
	[cj_incentive_keys.STATUS]: [
		{ label: "Active", value: "active" },
		{ label: "Inactive", value: "inactive" },
	],
};
export const CJ_INCENTIVE_INITIAL_STATE = {
	isLoading: false,
	data: [],
	filters: {},
	searchQuery: "",
	numberOfElements: 0,
	totalCount: 0,
	error: null,
};

export const NUDGE_ICON_TYPES = {
	COIN: "Coin",
	BIKE: "Bike",
	INCENTIVE_MISS: "IncentiveMiss",
	PENALTY: "Penalty",
};

export const NUDGE_ICON_SOURCES = [
	{ label: "Coin", value: NUDGE_ICON_TYPES.COIN },
	{ label: "Bike", value: NUDGE_ICON_TYPES.BIKE },
	{ label: "Inspection Miss", value: NUDGE_ICON_TYPES.INCENTIVE_MISS },
	{ label: "Penalty", value: NUDGE_ICON_TYPES.PENALTY },
];

export const getImagepath = (key) => {
	switch (key) {
		case NUDGE_ICON_TYPES.COIN:
			return coin;
		case NUDGE_ICON_TYPES.BIKE:
			return bike;
		case NUDGE_ICON_TYPES.INCENTIVE_MISS:
			return miss;
		case NUDGE_ICON_TYPES.PENALTY:
			return penalty;
		default:
			break;
	}
};
