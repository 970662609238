function CarViewedIcon(props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.825 10.312a.374.374 0 01-.325.188h-9a.375.375 0 01-.324-.563A5.579 5.579 0 014.274 7.4a3.375 3.375 0 113.452 0 5.579 5.579 0 013.098 2.538.376.376 0 010 .375z"
        fill="#E3F4DC"
      />
    </svg>
  )
}

export default CarViewedIcon
