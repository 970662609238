import {
    GridLayout,
    TabStrip,
    TabStripTab,
} from "@progress/kendo-react-layout";
import React, { useState, useEffect, useRef, useMemo } from "react";
import DetailCardLayout from "../../operations/components/layout-detail-card";
import {
    API_FORM_STEPS,
    ASSIGNED_DETAILS,
    DETAILS_PANEL_CONFIG,
    INCOME_DETAIL_FORM_DATA,
    PAN_DETAIL_FORM_DATA,
    STATUS_MAPPING,
    TOAST_MESSAGES,
} from "./constants";
import FormCardLayout from "../../operations/components/layout-form-card";
import { SFService } from "../../../services";
import {
    onInputChange,
    updateIncomeDetailFormData,
    updatePanCardFormData,
} from "./utils/helper";
import dayjs from "dayjs";
import { getUserRoles, showToastMessage, yieldToMain } from "../../../utils/utils";
import { useLocation } from "react-router-dom";
import { ASSIGNED_LEAD_KEY } from "../leads/viewModel";
import { Loader } from "../../../common/Loader";
import DocumentsForm from "./components/DocumentsForm";
import UpdateCallStatus from "./components/UpdateCallStatus";
import classes from "./styles/Details.module.css";
import { Button } from "@progress/kendo-react-buttons";
import { chevronLeftIcon } from "@progress/kendo-svg-icons";
import { useHistory } from "react-router-dom";
import sfEventParser from "../../../tracking/parser/seller-finance";
import { sendEvent } from "../../../tracking";
import { USER_ROLE_KEYS } from "../../../utils/constants/menu.constants";

const getLeadDetails = (item, carInfo, detailsResponse) => {
    const {
        status = "",
        createdAt = "",
        updatedAt = "",
        updatedBy = "",
    } = detailsResponse || {};

    switch (item.field) {
        case ASSIGNED_LEAD_KEY.STATUS:
            return status;
        case ASSIGNED_LEAD_KEY.CREATED_AT:
            return dayjs(createdAt).isValid()
                ? dayjs(createdAt).format("DD-MM-YYYY hh:mm A")
                : "-";
        case ASSIGNED_LEAD_KEY.UPDATED_AT:
            return dayjs(updatedAt).isValid()
                ? dayjs(updatedAt).format("DD-MM-YYYY hh:mm A")
                : "-";
        case ASSIGNED_LEAD_KEY.UPDATED_BY:
            return updatedBy;
        case ASSIGNED_LEAD_KEY.NAME:
            return detailsResponse?.sellerInfo?.name || "-";
        default:
            return carInfo[item.field] ? carInfo[item.field] : null;
    }
};

function DetailsView() {
    const [data, setData] = useState(ASSIGNED_DETAILS);
    const [panCardFormData, setPanCardFormData] =
        useState(PAN_DETAIL_FORM_DATA);
    const [incomeDetailFormData, setIncomeDetailFormData] = useState(
        INCOME_DETAIL_FORM_DATA
    );
    const [documentFormData, setDocumentFormData] = useState(null);
    const [areDetailsLoading, setAreDetailsLoading] = useState(false);
    const [submitDisabledState, setSubmitDisabledState] = useState({
        PAN_DETAILS: false,
        DOCUMENTS: false,
    });
    const [selectedTab, setSelectedTab] = useState(0);
    const tokenRef = useRef(null);
    const stepsRef = useRef(null);
    const callHistoryRef = useRef([]);
    const history = useHistory();
    const { pathname, state } = useLocation();
    const splitPathname = pathname.split("/");
    const id = splitPathname[splitPathname.length - 1];
    const { subTabKey = "" } = state || {};

    const roles = useMemo(
        () =>
            Object.values(getUserRoles()).reduce((acc, curr) => {
                acc[curr] = true;
                return acc;
            }, {}),
        []
    );

    const fetchLeadData = () => {
        setAreDetailsLoading(true);
        SFService.fetchLeadById(id)
            .then((resp) => {
                const sellerInfo = resp?.detail?.sellerInfo || {};
                const documentInfo = resp?.detail?.documents || {};
                const carInfo = resp?.detail?.carInfo || {};

                tokenRef.current = resp?.detail?.token;
                stepsRef.current = resp?.detail?.steps;
                callHistoryRef.current = resp?.detail?.callHistory || [];

                const updatedData = ASSIGNED_DETAILS.map((item) => {
                    return {
                        ...item,
                        value: getLeadDetails(item, carInfo, resp?.detail),
                    };
                });
                updatedData[1].value = id;
                setData(updatedData);

                updatePanCardFormData(sellerInfo, setPanCardFormData);
                updateIncomeDetailFormData(sellerInfo, setIncomeDetailFormData);
                const { ADDITIONAL_DOCS, ...restDocs } = documentInfo;
                setDocumentFormData({
                    ...restDocs,
                    ...ADDITIONAL_DOCS?.subDocuments,
                });
            })
            .catch((err) => {
                const { errorMessage } = err;
                showToastMessage(
                    errorMessage || TOAST_MESSAGES.DEFAULT.ERROR.message,
                    false,
                    _,
                    TOAST_MESSAGES.FETCH_DETAILS.ERROR.id
                );
            })
            .finally(() => setAreDetailsLoading(false));
    };

    const handleUpdate = async (formStep) => {
        try {
            const requestBody = {};
            Object.entries(panCardFormData).reduce(
                (acc, [key, { value, name }]) => {
                    if (value?.value) {
                        acc[key] = value?.value;
                    } else {
                        if (name === "dob") {
                            acc[key] = dayjs(value).format("YYYY-MM-DD");
                        } else acc[key] = value;
                    }
                    return acc;
                },
                requestBody
            );

            Object.entries(incomeDetailFormData).reduce(
                (acc, [key, { value, name }]) => {
                    if (value?.value) {
                        acc[key] = value?.value;
                    } else {
                        acc[key] = value;
                    }
                    return acc;
                },
                requestBody
            );

            requestBody["documents"] = { ...documentFormData };
            requestBody["step"] = formStep;

            await SFService.updateDetails(id, requestBody);
            fetchLeadData();
            showToastMessage(
                TOAST_MESSAGES.UPDATE_DETAILS.SUCCESS.message,
                true,
                _,
                TOAST_MESSAGES.UPDATE_DETAILS.SUCCESS.id
            );
            const submitEventPayload = sfEventParser({
                action: formStep,
                label: tokenRef.current,
            });

            await yieldToMain();
            sendEvent(submitEventPayload);
        } catch (err) {
            showToastMessage(
                err?.error || err?.errorMessage,
                false,
                _,
                TOAST_MESSAGES.UPDATE_DETAILS.ERROR.id
            );
        }
    };

    const handleDocumentUpload = (docCode, uploadedFileData) => {
        setDocumentFormData((prev) => ({
            ...prev,
            [docCode]: {
                ...prev[docCode],
                attachments: [{ ...uploadedFileData }],
                documentState: STATUS_MAPPING.UNDER_VERIFICATION,
            },
        }));
    };

    const handleDocumentVerify = (docCode, status) => {
        setDocumentFormData((prev) => ({
            ...prev,
            [docCode]: {
                ...prev[docCode],
                documentState:
                    STATUS_MAPPING[status] || STATUS_MAPPING.UNDER_VERIFICATION,
            },
        }));
    };

    const tabsList = [
        {
            title: "Basic Details",
            component: (
                <DetailCardLayout
                    headerTitle={"Basic Details"}
                    itemPerRow={4}
                    columnList={data}
                />
            ),
            showTab: true,
        },
        {
            title: "Call Status",
            component: (
                <UpdateCallStatus
                    leadId={id}
                    callHistory={callHistoryRef?.current}
                    subTabKey={subTabKey}
                    onSubmit={fetchLeadData}
                    isAdmin={roles[USER_ROLE_KEYS.C2B_FINANCE_SELLER_ADMIN]}
                />
            ),
            showTab: true,
        },
        {
            title: "PAN Details",
            component: (
                <FormCardLayout
                    headerTitle={"PAN Details"}
                    formData={panCardFormData}
                    handleChange={(e) =>
                        onInputChange(e, panCardFormData, setPanCardFormData)
                    }
                    isShowCTA
                    showResetCTA={false}
                    confirmCTAText="Submit"
                    handleConfirm={(e) => {
                        handleUpdate(API_FORM_STEPS.FORM_1_COMPLETED);
                    }}
                    isConfirmDisabled={submitDisabledState.PAN_DETAILS}
                />
            ),
            showTab: DETAILS_PANEL_CONFIG[stepsRef.current]?.PAN_DETAILS,
        },
        {
            title: "Income Details",
            component: (
                <FormCardLayout
                    headerTitle={"Income Details"}
                    formData={incomeDetailFormData}
                    handleChange={(e) =>
                        onInputChange(
                            e,
                            incomeDetailFormData,
                            setIncomeDetailFormData
                        )
                    }
                    isShowCTA
                    showResetCTA={false}
                    confirmCTAText="Submit"
                    handleConfirm={() =>
                        handleUpdate(API_FORM_STEPS.FORM_2_COMPLETED)
                    }
                />
            ),
            showTab: DETAILS_PANEL_CONFIG[stepsRef.current]?.INCOME_DETAILS,
        },
        {
            title: "Documents",
            component: (
                <DocumentsForm
                    data={documentFormData}
                    token={tokenRef.current}
                    onUpload={handleDocumentUpload}
                    onDocumentsFormSubmit={() =>
                        handleUpdate(API_FORM_STEPS.FORM_3_COMPLETED)
                    }
                    isSubmitDisabled={submitDisabledState.DOCUMENTS}
                    onVerify={handleDocumentVerify}
                />
            ),
            showTab:
                documentFormData &&
                DETAILS_PANEL_CONFIG[stepsRef.current]?.DOCUMENTS,
        },
    ];

    useEffect(() => {
        fetchLeadData();
    }, []);

    useEffect(() => {
        const isPanSubmitDisabled = Object.values(panCardFormData).some(
            (obj) => obj?.validationMessage?.length > 0
        );

        const isDocumentsSubmitDisabled = documentFormData
            ? Object.values(documentFormData).some(
                  (document) =>
                      document.isMandatory &&
                      !(document.attachments && document.attachments.length > 0)
              )
            : false;

        setSubmitDisabledState((prev) => ({
            ...prev,
            PAN_DETAILS: isPanSubmitDisabled,
            DOCUMENTS: isDocumentsSubmitDisabled,
        }));
    }, [panCardFormData, documentFormData]);

    if (areDetailsLoading) {
        return <Loader />;
    }

    return (
        <GridLayout
            style={{
                placeItems: "center",
            }}
        >
            <Button
                className={classes.backBtn}
                svgIcon={chevronLeftIcon}
                themeColor="primary"
                onClick={() =>
                    history.push("/seller-finance/leads", { subTabKey })
                }
            >
                Back
            </Button>
            <TabStrip
                selected={selectedTab}
                onSelect={(e) => setSelectedTab(e.selected)}
                className={classes.detailsTab}
            >
                {tabsList.map((tabItem) => (
                    <TabStripTab
                        disabled={!tabItem?.showTab || false}
                        key={tabItem?.title}
                        title={tabItem?.title}
                    >
                        {tabItem?.component}
                    </TabStripTab>
                ))}
            </TabStrip>
        </GridLayout>
    );
}

export default DetailsView;
