import { forwardRef, useEffect, useState } from "react"
import useQuery from "../../../../../../../hooks/useQuery"
import { Loader } from "../../../../../../../common/Loader"
import useLazyQuery from "../../../../../../../hooks/useLazyQuery"
import { commonApis } from "../../../../../../../services/commonApi"
import FormCardLayout from "../../../../../../operations/components/layout-form-card"
import { getLoginUserEmail, showToastMessage } from "../../../../../../../utils/utils"
import { FORM_ELEMENT_TYPE } from "../../../../../../../utils/constants/values.constants"
import { useSelector } from "react-redux"

const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

const CARD_STYLE = { border: "2px solid #EBF5FF", minHeight: '0px', marginTop: '10px' }

const DocumentsForm = forwardRef((props) => {

    const { data, loanStatus } = props
    const [loanDocuments, setLoanDocuments] = useState({})
    const [ssVendorDocuments, setSSVendorDocuments] = useState({})
    const prDetails = useSelector((state) => state.prAutomation);
    const { dreDocuments,orderData } = prDetails || {};
    const isFormDisabled = orderData?.orderStatus == 'SOLD'
    const { data: documentData, } = useQuery(commonApis.getHypotecationDocuments, { appointmentId: data?.appointmentId, loanStatus: loanStatus })
    const { data: ssVendorDocumentsData, } = useQuery(commonApis.getSSVendorDocuments, { appointmentId: data?.appointmentId })
    const { apiCbFunction: uploadDocs, isLoading: uploadDocsLoading } = useLazyQuery(commonApis.uploadDocs)
    const { apiCbFunction: getTokenForUpload, } = useLazyQuery(commonApis.getTokenForUpload)

    const documentFormDataSetter = () => {
        const loanDocumentsFiltered = {};
        documentData?.detail.forEach((item) => {
            if (item.category == "LOAN_DOCUMENTS") {
                if (item?.page?.length > 0)
                    item?.page?.map((page) => {
                        const docUrl = item?.uploads?.filter((doc) => doc.page == page.page)[0]?.attachmentName
                        loanDocumentsFiltered[item.code + page.page] = {
                            name: item.code,
                            label: item.name + " " + page.page,
                            elementtype: FORM_ELEMENT_TYPE.UPLOAD_FILE,
                            accept: ".pdf, .png, .jpg",
                            value: null,
                            handleChange: fileChangeHandler,
                            handleFileView: docUrl ?
                                () => {
                                    window.open(docUrl)
                                }
                                :
                                () => { },
                            showFileView: !!docUrl,
                            disabled: isFormDisabled,
                            ['data-category']: 'LOAN_DOCUMENTS',
                            ['data-documentpage']: page.page
                        }
                    })
                else
                    loanDocumentsFiltered[item.code] = {
                        name: item.code,
                        label: item.name,
                        elementtype: FORM_ELEMENT_TYPE.UPLOAD_FILE,
                        accept: ".pdf, .png, .jpg",
                        value: null,
                        handleChange: fileChangeHandler,
                        handleFileView: item?.uploads?.length ? () => { window.open(item?.uploads?.[0]?.attachmentName) } : () => { },
                        showFileView: !!item?.uploads?.length,
                        disabled: isFormDisabled,
                        ['data-category']: 'LOAN_DOCUMENTS'
                    }
            }
        });
        setLoanDocuments(loanDocumentsFiltered)
    }

    const fileChangeHandler = async (e) => {
        if(e.target.files[0].size > 2097152) {
          showToastMessage("File is too big!",false);
          e.target.value = "";
          return;
        }
        const response = await fileUploader(e)
        if (response?.status == 200) {
            const docUrl = response?.data?.baseUri
            const key = e.target.name + (e.target.dataset.documentpage ?? '')
            if (e.target.dataset['category'] == "LOAN_DOCUMENTS") {
                setLoanDocuments((prevFormData) => {
                    return {
                        ...prevFormData,
                        [key]: {
                            ...prevFormData[key],
                            showFileView: true,
                            handleFileView: () => {
                                window.open(docUrl)
                            }, random: true,
                        }
                    }
                })
            }
            else if (e.target.dataset['category'] == "SS_VENDOR_DOCUEMNTS") {
                setSSVendorDocuments((prevFormData) => {
                    return {
                        ...prevFormData,
                        [key]: {
                            ...prevFormData[key],
                            showFileView: true,
                            handleFileView: () => {
                                window.open(docUrl)
                            }, random: true,
                        }
                    }
                })
            }
        }
        else {
            showToastMessage('Some Error Occured, Please try again', false)
        }
    }

    const fileUploader = async (e) => {
        const payload = {
            userEmail: getLoginUserEmail(),
            client: "c2b-internal-portal",
        };
        const userTokens = await getTokenForUpload(payload)
        const headers = {
            Authorization: `BEARER ${userTokens?.data?.accessToken}`,
            jti: userTokens?.data?.jti,
            utid: userTokens?.data?.utid,
        };
        const page = e.target.dataset.documentpage??null
        const formData = new FormData()
        formData.append("appointmentId", data?.appointmentId);
        formData.append("imageSource", "receive");
        formData.append("isRetailPipeline", true);
        formData.append("docCode", e.target.name);
        if (page !== null) {
          formData.append("page", page);
        }
        formData.append("file", e.target.files[0],`${e.target.name}-${Date.now()}`);
        formData.append("client", "c2b-internal-portal");
        formData.append("uploadSource", "ADMIN_RA");

        const response = await uploadDocs({
            body: formData,
            headers: headers
        })
        e.target.value = ''
        return response
    }

    useEffect(() => {
        if (documentData) {
            documentFormDataSetter()
        }
    }, [documentData])

    useEffect(() => {
        if (ssVendorDocumentsData) {
            const ssVendorDocumentsFiltered = {};
            Object.keys(ssVendorDocumentsData?.data)?.forEach((data) => {
                ssVendorDocumentsData.data[data].forEach((item) => {
                    ssVendorDocumentsFiltered[item.name] = {
                        name: item.name,
                        label: item.label,
                        elementtype: FORM_ELEMENT_TYPE.UPLOAD_FILE,
                        accept: ".pdf, .png, .jpg",
                        value: null,
                        handleChange: fileChangeHandler,
                        handleFileView: item?.attachmentName ? () => { window.open(item?.attachmentName) } : () => { },
                        showFileView: !!item?.attachmentName,
                        ['data-category']: 'SS_VENDOR_DOCUEMNTS'
                    }
                })
            });
            setSSVendorDocuments(ssVendorDocumentsFiltered)
        }
    }, [ssVendorDocumentsData])

    return (
        <>
            {
                uploadDocsLoading &&
                <div
                    style={{
                        position: 'fixed',
                        height: '100vh',
                        width: '100vw',
                        background: 'rgba(128, 128, 128, 0.5)',
                        top: '0',
                        left: '0',
                        zIndex: '9999',
                    }}
                >
                    <Loader />
                </div >
            }
            {
                !isEmptyObject(loanDocuments) &&
                <FormCardLayout
                    headerTitle='Loan Documents'
                    formData={loanDocuments}
                    isShowCTA={false}
                    cardStyle={CARD_STYLE}
                />
            }
            {
                !isEmptyObject(ssVendorDocuments) &&
                <FormCardLayout
                    headerTitle='SS Vendor Documents'
                    formData={ssVendorDocuments}
                    isShowCTA={false}
                    cardStyle={CARD_STYLE}
                />
            }
        </>
    )
})

export default DocumentsForm