import { Provider } from "react-redux";
import store from "./store";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import "./App.css";
import RenderRoutes from "./router/routes";
import { AuthProvider } from "@descope/react-sdk";
import { ExotelDailerContextProvider } from "./context/exotel-dailer-context";
import TagManager from "react-gtm-module";
import { LmsStateManagementContextProvider } from "./context/lms-stage-management-context";

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_RA_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

const App = () => {
    return (
        <AuthProvider projectId={process.env.REACT_APP_PROJECT_ID}>
            <Provider store={store}>
              <LmsStateManagementContextProvider>
                <ExotelDailerContextProvider>
                <RenderRoutes />
                </ExotelDailerContextProvider>
              </LmsStateManagementContextProvider>
            </Provider>
        </AuthProvider>
    );
};

export default App;
