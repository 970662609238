import { useEffect, useState } from "react";
import { KendoCard, KendoListView } from "../../../../../../../components";
import {
	convertToNumber,
	numberFormat,
} from "../../../../../../../utils/utils";
import { defaultStyle, ChargeItem } from "../view-modals";
const CustomerToReceiveCard = (props) => {
	const { holdDetails, chargesAmount, c24Quote } = props || {};
	const [customerToReceiveDetails, setCustomerToReceiveDetails] = useState([]);
	useEffect(() => {
		let payableLater = 0;
		if (holdDetails && holdDetails.length) {
			holdDetails.forEach((detail) => {
				payableLater =
					payableLater + Number.parseInt(convertToNumber(detail.value));
			});
		}
		setCustomerToReceiveDetails([
			{
				prefix: null,
				details: "Payable on car delivery",
				value:
					c24Quote - chargesAmount - payableLater
						? numberFormat(c24Quote - chargesAmount - payableLater)
						: numberFormat(0),
			},
			{
				prefix: null,
				details: "Payable later",
				value: numberFormat(payableLater),
			},
		]);
	}, [holdDetails, chargesAmount, c24Quote]);

	const renderHeaderTitle = () => {
		return (
			<div className="justify-between">
				<div className="k-font-medium" style={{ fontSize: "12px" }}>
					Customer to receive
				</div>
				<div
					className="k-font-bold k-font-size-lg"
					style={{ color: "#027A48",fontSize: "12px"}}>
					{c24Quote - chargesAmount
						? numberFormat(c24Quote - chargesAmount)
						: numberFormat(0)}
				</div>
			</div>
		);
	};
	return (
		<KendoCard
			headerTitle={renderHeaderTitle()}
			cardStyle={{ margin: "0px 0px 10px 0px"}}
			details={
				<>
					<KendoListView data={customerToReceiveDetails} />
					<KendoListView data={holdDetails} MyListRender={ChargeItem} />
				</>
			}
		/>
	);
};

export default CustomerToReceiveCard;
