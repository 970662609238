import { memo, useEffect, useRef, useState } from 'react'
import { Button } from '@progress/kendo-react-buttons'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { TextArea } from '@progress/kendo-react-inputs'
import { useDispatch, useSelector } from 'react-redux'
import { Hint } from "@progress/kendo-react-labels";

import './upload-view.scss'
import { getPresignedURL, uploadFile, uploadFileToS3 } from '../../store/actions/controlCenter'
import { showToastMessage } from '../../common/utils/utils'
import { AppLoader } from '../../common/components/app-loader'
import { TYPE_LOADER } from '../../store/types'

const UploadView = ({ dropDownData, setRefreshPageData, refreshPageData }) => {

  const dispatch = useDispatch();
  const [selectedPurpose, setSelectedPurpose] = useState({})
  const { userInfo } = useSelector((state) => state.allCars);
  const [comment, setComment] = useState('')
  const [file, setFile] = useState(null);
  const inputRef = useRef(null);
  const { isLoading } = useSelector((state) => state.appLoader)


  const payload = {
    comment,
    userId: userInfo?.id,
    type: selectedPurpose?.value,
    originalFilename: file?.name
  }

  useEffect(() => {
    setSelectedPurpose(dropDownData?.length > 0 ? dropDownData[0] : {})
  }, [dropDownData])


  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (
      (selectedFile.type === 'text/csv' ||
        selectedFile.name.endsWith('.csv'))
    ) {
      setFile(selectedFile);
    } else {
      inputRef.current.value = '';
    }
  };


  const UploadFile = () => {

    return (
      <div>
        <input ref={inputRef} type='file' style={{ display: 'none' }} accept=".csv"
          onChange={handleFileChange} />
        <div className='upload-file'>
          <span className={`text-container ${!file?.name ? "placeholder-text" : ""}`}>{file?.name ? file.name : "Choose File"}</span>
          <Button onClick={() => inputRef?.current?.click()}>{file?.name ? "Choose Another" : "Choose File"}</Button>
        </div>
      </div>
    )
  }

  const handleClick = () => {
    dispatch(getPresignedURL({ uploadType: selectedPurpose.value })).then((resp) => {
      window.open(resp?.presignedUrl, '_blank');
    })
  }


  const readFileAsArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };


  const handleSubmit = () => {

    dispatch({ type: TYPE_LOADER.SHOW_LOADER });

    dispatch(uploadFile({ uploadType: selectedPurpose.value })).then(async (resp) => {
      const { presignedUrl, filename } = resp;

      try {
        const binaryFile = await readFileAsArrayBuffer(file);


        const uploadResponse = await fetch(presignedUrl, {
          method: 'PUT',
          body: binaryFile,
        });

        if (!uploadResponse.ok) {
          throw new Error('Failed to upload file to S3');
        }

        dispatch(uploadFileToS3({ ...payload, filename })).then(() => {

          dispatch({ type: TYPE_LOADER.HIDE_LOADER });
          setRefreshPageData(!refreshPageData);
          showToastMessage("File uploaded successfully", true, 1500);
          setSelectedPurpose(dropDownData[0]);
          setFile(null);
          setComment('');

        }).catch((error) => {
          dispatch({ type: TYPE_LOADER.HIDE_LOADER });
          showToastMessage(error?.message, false, 1500);
        });

      } catch (error) {
        showToastMessage(error?.message, false, 1500);
        dispatch({ type: TYPE_LOADER.HIDE_LOADER });
      }
    }).catch((err) => {
      showToastMessage(err?.message, false, 1500);
      dispatch({ type: TYPE_LOADER.HIDE_LOADER });
    });
  };

  return (
    <div className="left-container">
      <div className='center-container'>
        <span className="title-text">New Mapping</span>

        <div className='center-header'>
          <div className='header-content'>
            <span className='title-text'>Enter Purpose</span>
            <DropDownList
              className='center-dropdown'
              data={dropDownData}
              value={selectedPurpose}
              textField='label'
              onChange={(e) => setSelectedPurpose(e.target.value)}
              popupSettings={{
                className: 'center-dropdown-popup'
              }}
            />
          </div>
          <div>
            <Button disabled={!selectedPurpose.value} onClick={handleClick}>Sample CSV Download</Button>
          </div>
        </div>

        <div className='form-container'>

          <div className='form-body'>

            <div className='form-content'>
              <span className='file-label'>
                Upload File
              </span>
              <UploadFile />
            </div>

            <div className='form-content'>
              <span className='comment-label'>
                Comments
              </span>
              <div>
                <TextArea onChange={(e) => setComment(e.target.value)} value={comment} rounded={'small'} maxLength={100} placeholder='Add Comments' rows={4} />
                <Hint direction={"end"}>
                  {comment.length} / {100}
                </Hint>
              </div>
            </div>

          </div>

          <div className="k-form-buttons">
            <Button disabled={!file || !selectedPurpose.value} onClick={handleSubmit}>Upload file</Button>
          </div>
        </div>
      </div>
      {isLoading && <AppLoader />}
    </div>
  )
}

export default memo(UploadView);
