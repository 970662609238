function DefaultIcon(props) {
  return (
    <svg
      width={18}
      height={17}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.354 4.333l2.334-3.02c.166-.223.364-.386.594-.49a1.718 1.718 0 011.437 0c.23.104.427.267.594.49l2.333 3.02 3.542 1.188c.36.11.646.316.854.614.208.299.313.629.313.99 0 .167-.025.333-.073.5a1.551 1.551 0 01-.24.48l-2.292 3.25.084 3.416c.014.486-.146.896-.48 1.229-.333.333-.722.5-1.166.5-.028 0-.18-.02-.459-.063L9 15.396l-3.729 1.041a.792.792 0 01-.229.052c-.083.008-.16.011-.23.011-.444 0-.833-.167-1.166-.5a1.602 1.602 0 01-.479-1.23l.083-3.437L.98 8.104a1.551 1.551 0 01-.24-.479 1.778 1.778 0 01-.073-.5c0-.347.1-.67.302-.969.201-.298.483-.51.844-.635l3.541-1.188z"
        fill={props.fill ? props.fill : "#DFDFDF"}
      />
    </svg>
  )
}

export default DefaultIcon
