function FlagIcon(props) {
  return (
    <svg
      fill={props.fill || "#E27936"}
      xmlns="http://www.w3.org/2000/svg"
      width="15px"
      height="15px"
      viewBox="0 0 46.182 46.182"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M37.734 2.655H8.438C3.786 2.655 0 6.447 0 11.099v17.14c0 4.652 3.786 8.439 8.438 8.439h18.376l3.935 5.634a2.848 2.848 0 004.662.004l4.126-5.845c3.792-.82 6.645-4.195 6.645-8.232v-17.14c0-4.652-3.795-8.444-8.448-8.444zM23.091 25.826c1.359 0 2.462 1.126 2.462 2.515s-1.103 2.513-2.462 2.513c-1.359 0-2.462-1.124-2.462-2.513s1.102-2.515 2.462-2.515zm-2.455-4.224V11.234c0-1.389 1.066-2.515 2.455-2.515 1.39 0 2.455 1.126 2.455 2.515v10.368c0 1.389-1.065 2.515-2.455 2.515-1.389 0-2.455-1.127-2.455-2.515z" />
    </svg>
  )
}

export default FlagIcon
