import React, { useState, useEffect } from "react";
import { getDisplayedColumns } from './helper';
import CustomGrid from "../../../../../common/custom-grid/component";
import { useVisibleColumns } from "./hooks/useVisibleColumns";
import { useFetchReferrals } from "./hooks/useFetchReferrals";
import { CJ_REFERRAL_KEYS } from "./viewModal";
import { SORT_DIRECTION_KEYS } from "../../../../../utils/constants/values.constants";

const REFERRAL_DEFAULT_PAGE_SIZE = 10;
const REFERRAL_INITIAL_FILTER = {
    filters: [],
    logic: "and",
};

const INITIAL_SORT = [
    {
        field: CJ_REFERRAL_KEYS.DATE,
        dir: SORT_DIRECTION_KEYS.DESC,
    }
];

const ALLOWED_SORT_PARAMS = [
    CJ_REFERRAL_KEYS.DATE,
];


const ReferralView = ({ setReferralDetails }) => {

    const [paginationParams, setPaginationParams] = useState({ pageNumber: 0, pageSize: REFERRAL_DEFAULT_PAGE_SIZE });
    const [filterParams, setFilterParams] = useState({});
    const [gridFilters, setGridFilters] = useState(REFERRAL_INITIAL_FILTER);
    const [sortParms, setSortParms] = useState(INITIAL_SORT);
    const [storeList, setStoreList] = useState([]);
    const [triggerStatusUpdate, setTriggerStatusUpdate] = useState(false);

    const { referralData, totalReferralCount } = useFetchReferrals(paginationParams, filterParams, sortParms, setStoreList, triggerStatusUpdate);
    const visibleColumns = useVisibleColumns(referralData, setReferralDetails, gridFilters, setGridFilters, filterParams, setFilterParams, setPaginationParams, storeList, setTriggerStatusUpdate)

    const pageChange = ({ page = 0, size = REFERRAL_DEFAULT_PAGE_SIZE }) => {
        setPaginationParams({ pageNumber: page, pageSize: size });
    };

    useEffect(() => {
        setPaginationParams({ pageNumber: 0, pageSize: REFERRAL_DEFAULT_PAGE_SIZE })
    }, [filterParams, sortParms])

    const sortChange = (e) => {
        let sortData = !!e?.sort.length ? e?.sort : INITIAL_SORT;
        let sortKey = sortData[0]?.field;
        let sortDir = sortData[0].dir;
        if (!e?.sort?.length) {
            sortDir =
                sortParms[0].dir === SORT_DIRECTION_KEYS.DESC
                    ? SORT_DIRECTION_KEYS.ASC
                    : SORT_DIRECTION_KEYS.DESC;
        }
        if (ALLOWED_SORT_PARAMS.includes(sortKey)) {
            setSortParms([{ field: sortData[0].field, dir: sortDir }]);
        }
    };


    return (
        <CustomGrid
            displayedColumns={getDisplayedColumns()}
            columnMap={visibleColumns}
            data={referralData}
            rowHeight={1}
            isPagination={true}
            pageChange={pageChange}
            count={totalReferralCount}
            paginationParams={paginationParams}
            sortable={true}
            sortParms={sortParms}
            onSortChange={sortChange}
        />
    )
}
export default ReferralView;

