import { Button } from "@progress/kendo-react-buttons";
import React, { useRef, useState } from "react";
import { Popover } from "@progress/kendo-react-tooltip";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import { formatDate, showToastMessage } from "../../utils/utils";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const DateRangeCta = ({ ctaTitle, onClick, isDisabled, popUpCtaTitle }) => {
	const anchor = useRef(null);
	const today = new Date();
	const twoMonthsAgo = new Date();
	twoMonthsAgo.setMonth(today.getMonth() - 2);
	const defaultValue = {
		start: twoMonthsAgo,
		end:  new Date(),
	  };

	const [show, setShow] = useState(false);
	const [rangeDate, setRangeDate] = useState({
		startDate: formatDate(defaultValue?.start),
		endDate: formatDate(defaultValue?.end),
	});
	const handleClick = () => {
		setShow(!show);
	};
	const handleChange = (e) => {
		const { start, end } = e?.value || {};
		setRangeDate({ startDate: formatDate(start), endDate: formatDate(end) });
	};

	const handleOnClick = () => {
		onClick(rangeDate.startDate, rangeDate.endDate);
		handleClick();
	};
	return (
		<>
			<Button
				className="m-2"
				icon="k-icon k-i-download"
				themeColor={"primary"}
				fillMode="outline"
				disabled={isDisabled}
				ref={anchor}
				onClick={handleClick}>
				{ctaTitle}
			</Button>

			<Popover
				show={show}
				anchor={anchor.current && anchor.current.element}
				position={"bottom"}
				className={"layout-popover"}>
				<GridLayout
					gap={{ rows: 10 }}
					style={{
						display: "flex",
						alignItems: "end",
						flexDirection: "column",
					}}>
					<GridLayoutItem row={1}>
						<div style={{ zIndex: 101 }}>
							<DateRangePicker
								onChange={handleChange}
								min={twoMonthsAgo}
								max={today}
								defaultValue={defaultValue}
							/>
						</div>
					</GridLayoutItem>
					<GridLayoutItem row={2}>
						<Button
							className="m-2"
							icon="k-icon k-i-download"
							themeColor={"primary"}
							onClick={() =>
								!!rangeDate.startDate && !!rangeDate.endDate
									? handleOnClick()
									: showToastMessage("Please select Date Range", false)
							}>
							{popUpCtaTitle}
						</Button>
					</GridLayoutItem>
				</GridLayout>
			</Popover>
		</>
	);
};

export default DateRangeCta;
