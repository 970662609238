import { USER_ROLE_KEYS } from "../../../utils/constants/menu.constants";

export const SFTabStrips = {
    [USER_ROLE_KEYS.C2B_FINANCE_SELLER_AGENT]: [
        { key: "ASSIGNED", label: "Assigned Leads" },
        { key: "UNASSIGNED", label: "Unassigned Leads" },
    ],
    [USER_ROLE_KEYS.C2B_FINANCE_SELLER_ADMIN]: [
        { key: "ADMIN_ASSIGNED", label: "Assigned Leads" },
        { key: "ADMIN_UNASSIGNED", label: "Unassigned Leads" },
    ],
};

export const SFSubTabStrips = {
    [USER_ROLE_KEYS.C2B_FINANCE_SELLER_AGENT]: {
        ASSIGNED: [
            { key: "DIALER_LEADS", label: "Dialer Leads" },
            { key: "CONTACT_INTERESTED", label: "Contact - Interested Leads" },
            { key: "ALL_DOCS_COLLECTED", label: "All Docs Collected & Verified Leads" },
        ],
    },
    [USER_ROLE_KEYS.C2B_FINANCE_SELLER_ADMIN]: {
        ASSIGNED: [
            { key: "DIALER_LEADS", label: "Dialer Leads" },
            { key: "CONTACT_INTERESTED", label: "Contact - Interested Leads" },
            { key: "ALL_DOCS_COLLECTED", label: "All Docs Collected & Verified Leads" },
            { key: "CONTACT_NOT_INTERESTED", label: "Not Interested Leads" },
        ],
    },
};

export const UNASSIGNED_LEAD_KEY = {
    APPLICATION_ID: "leadId",
    CUSTOMER_CITY: "city",
    ASSIGNMENT: "Assignment",
    LEAD_STATUS: "leadSource",
};

export const ASSIGNED_LEAD_KEY = {
    APPLICATION_ID: "leadId",
    NAME: "customerName",
    PHONE_NUMBER: "phoneNumber",
    LOAN_AMOUNT: "requestedLoanAmount",
    CITY: "city",
    STATUS: "status",
    CREATED_AT: "createdAt",
    UPDATED_AT: "updatedAt",
    UPDATED_BY: "updatedBy",
    COPY_LINK: "copyLink",
    MAKE: "make",
    MODEL: "model",
    YEAR: "year",
    VG_PRICE_RANGE: "vgPriceRange",
    INTERNAL_STATUS: "dispositionStatus",
    TIME_LEFT: "timeLeft",
    LAST_CALL_STATUS: "lastCallStatus",
    RESTORE: "",
    LEAD_SOURCE: "leadSource",
    SHARE_LINK: "",
};

export const All_LEAD_KEY = {
    APPLICATION_ID: "leadId",
    NAME: "customerName",
    PHONE_NUMBER: "phoneNumber",
    LOAN_AMOUNT: "requestedLoanAmount",
    CITY: "city",
    STATUS: "status",
    CREATED_AT: "createdAt",
    UPDATED_AT: "updatedAt",
    UPDATED_BY: "updatedBy",
    ASSIGNMENT: "Assignment",
};

export const UNASSIGNGED_LEADS_COLS = {
    [UNASSIGNED_LEAD_KEY.APPLICATION_ID]: {
        field: UNASSIGNED_LEAD_KEY.APPLICATION_ID,
        label: "Application ID",
    },
    [UNASSIGNED_LEAD_KEY.CUSTOMER_CITY]: {
        field: UNASSIGNED_LEAD_KEY.CUSTOMER_CITY,
        label: "City",
    },
    // [UNASSIGNED_LEAD_KEY.LEAD_STATUS]: {
    //     field: UNASSIGNED_LEAD_KEY.LEAD_STATUS,
    //     label: "Lead Source",
    // },
    [UNASSIGNED_LEAD_KEY.ASSIGNMENT]: {
        field: UNASSIGNED_LEAD_KEY.ASSIGNMENT,
        label: "Assignment",
    },
};

export const ASSIGNED_LEAD_COLS = (subTabKey) => {
    switch (subTabKey) {
        case "DIALER_LEADS":
            return {
                [ASSIGNED_LEAD_KEY.APPLICATION_ID]: {
                    field: ASSIGNED_LEAD_KEY.APPLICATION_ID,
                    label: "Application Id",
                },
                [ASSIGNED_LEAD_KEY.PHONE_NUMBER]: {
                    field: ASSIGNED_LEAD_KEY.PHONE_NUMBER,
                    label: "Phone Number",
                },
                [ASSIGNED_LEAD_KEY.LOAN_AMOUNT]: {
                    field: ASSIGNED_LEAD_KEY.LOAN_AMOUNT,
                    label: "Loan Amount",
                },
                [ASSIGNED_LEAD_KEY.LEAD_SOURCE]: {
                    field: ASSIGNED_LEAD_KEY.LEAD_SOURCE,
                    label: "Lead Source",
                },
                [ASSIGNED_LEAD_KEY.STATUS]: {
                    field: ASSIGNED_LEAD_KEY.STATUS,
                    label: "Status",
                },
                [ASSIGNED_LEAD_KEY.INTERNAL_STATUS]: {
                    field: ASSIGNED_LEAD_KEY.INTERNAL_STATUS,
                    label: "Internal Status",
                },
                [ASSIGNED_LEAD_KEY.CREATED_AT]: {
                    field: ASSIGNED_LEAD_KEY.CREATED_AT,
                    label: "Created At",
                },
                [ASSIGNED_LEAD_KEY.UPDATED_AT]: {
                    field: ASSIGNED_LEAD_KEY.UPDATED_AT,
                    label: "Updated At",
                },
                [ASSIGNED_LEAD_KEY.TIME_LEFT]: {
                    field: ASSIGNED_LEAD_KEY.TIME_LEFT,
                    label: "Time left for Call",
                },
                [ASSIGNED_LEAD_KEY.COPY_LINK]: {
                    field: ASSIGNED_LEAD_KEY.SHARE_LINK,
                    label: "Share Link",
                },
            };

        case "ALL_DOCS_COLLECTED": {
            return {
                [ASSIGNED_LEAD_KEY.APPLICATION_ID]: {
                    field: ASSIGNED_LEAD_KEY.APPLICATION_ID,
                    label: "Application Id",
                },
                [ASSIGNED_LEAD_KEY.PHONE_NUMBER]: {
                    field: ASSIGNED_LEAD_KEY.PHONE_NUMBER,
                    label: "Phone Number",
                },
                [ASSIGNED_LEAD_KEY.LOAN_AMOUNT]: {
                    field: ASSIGNED_LEAD_KEY.LOAN_AMOUNT,
                    label: "Loan Amount",
                },
                [ASSIGNED_LEAD_KEY.LEAD_SOURCE]: {
                    field: ASSIGNED_LEAD_KEY.LEAD_SOURCE,
                    label: "Lead Source",
                },
                [ASSIGNED_LEAD_KEY.LAST_CALL_STATUS]: {
                    field: ASSIGNED_LEAD_KEY.LAST_CALL_STATUS,
                    label: "Last Call Status",
                },
                [ASSIGNED_LEAD_KEY.CREATED_AT]: {
                    field: ASSIGNED_LEAD_KEY.CREATED_AT,
                    label: "Created At",
                },
                [ASSIGNED_LEAD_KEY.UPDATED_AT]: {
                    field: ASSIGNED_LEAD_KEY.UPDATED_AT,
                    label: "Updated At",
                },
                [ASSIGNED_LEAD_KEY.COPY_LINK]: {
                    field: ASSIGNED_LEAD_KEY.SHARE_LINK,
                    label: "Share Link",
                },
            };
        }

        case "CONTACT_INTERESTED": {
            return {
                [ASSIGNED_LEAD_KEY.APPLICATION_ID]: {
                    field: ASSIGNED_LEAD_KEY.APPLICATION_ID,
                    label: "Application Id",
                },
                [ASSIGNED_LEAD_KEY.PHONE_NUMBER]: {
                    field: ASSIGNED_LEAD_KEY.PHONE_NUMBER,
                    label: "Phone Number",
                },
                [ASSIGNED_LEAD_KEY.LOAN_AMOUNT]: {
                    field: ASSIGNED_LEAD_KEY.LOAN_AMOUNT,
                    label: "Loan Amount",
                },
                [ASSIGNED_LEAD_KEY.LEAD_SOURCE]: {
                    field: ASSIGNED_LEAD_KEY.LEAD_SOURCE,
                    label: "Lead Source",
                },
                [ASSIGNED_LEAD_KEY.STATUS]: {
                    field: ASSIGNED_LEAD_KEY.STATUS,
                    label: "Status",
                },
                [ASSIGNED_LEAD_KEY.LAST_CALL_STATUS]: {
                    field: ASSIGNED_LEAD_KEY.LAST_CALL_STATUS,
                    label: "Last Call Status",
                },
                [ASSIGNED_LEAD_KEY.CREATED_AT]: {
                    field: ASSIGNED_LEAD_KEY.CREATED_AT,
                    label: "Created At",
                },
                [ASSIGNED_LEAD_KEY.UPDATED_AT]: {
                    field: ASSIGNED_LEAD_KEY.UPDATED_AT,
                    label: "Updated At",
                },
                [ASSIGNED_LEAD_KEY.TIME_LEFT]: {
                    field: ASSIGNED_LEAD_KEY.TIME_LEFT,
                    label: "Time left for Call",
                },
                [ASSIGNED_LEAD_KEY.COPY_LINK]: {
                    field: ASSIGNED_LEAD_KEY.SHARE_LINK,
                    label: "Share Link",
                },
            };
        }
    }
};

export const ADMIN_ASSIGNED_LEAD_COLS = (subTabKey) => {
    switch (subTabKey) {
        case "DIALER_LEADS":
            return {
                [ASSIGNED_LEAD_KEY.APPLICATION_ID]: {
                    field: ASSIGNED_LEAD_KEY.APPLICATION_ID,
                    label: "Application Id",
                },
                [ASSIGNED_LEAD_KEY.PHONE_NUMBER]: {
                    field: ASSIGNED_LEAD_KEY.PHONE_NUMBER,
                    label: "Phone Number",
                },
                [ASSIGNED_LEAD_KEY.LOAN_AMOUNT]: {
                    field: ASSIGNED_LEAD_KEY.LOAN_AMOUNT,
                    label: "Loan Amount",
                },
                [ASSIGNED_LEAD_KEY.LEAD_SOURCE]: {
                    field: ASSIGNED_LEAD_KEY.LEAD_SOURCE,
                    label: "Lead Source",
                },
                [ASSIGNED_LEAD_KEY.STATUS]: {
                    field: ASSIGNED_LEAD_KEY.STATUS,
                    label: "Status",
                },
                [ASSIGNED_LEAD_KEY.INTERNAL_STATUS]: {
                    field: ASSIGNED_LEAD_KEY.INTERNAL_STATUS,
                    label: "Internal Status",
                },
                [ASSIGNED_LEAD_KEY.CREATED_AT]: {
                    field: ASSIGNED_LEAD_KEY.CREATED_AT,
                    label: "Created At",
                },
                [ASSIGNED_LEAD_KEY.UPDATED_AT]: {
                    field: ASSIGNED_LEAD_KEY.UPDATED_AT,
                    label: "Updated At",
                },
                [ASSIGNED_LEAD_KEY.TIME_LEFT]: {
                    field: ASSIGNED_LEAD_KEY.TIME_LEFT,
                    label: "Time left for Call",
                },
                [All_LEAD_KEY.ASSIGNMENT]: {
                    field: All_LEAD_KEY.ASSIGNMENT,
                    label: "Assignment",
                },
            };

        case "ALL_DOCS_COLLECTED": {
            return {
                [ASSIGNED_LEAD_KEY.APPLICATION_ID]: {
                    field: ASSIGNED_LEAD_KEY.APPLICATION_ID,
                    label: "Application Id",
                },
                [ASSIGNED_LEAD_KEY.PHONE_NUMBER]: {
                    field: ASSIGNED_LEAD_KEY.PHONE_NUMBER,
                    label: "Phone Number",
                },
                [ASSIGNED_LEAD_KEY.LOAN_AMOUNT]: {
                    field: ASSIGNED_LEAD_KEY.LOAN_AMOUNT,
                    label: "Loan Amount",
                },
                [ASSIGNED_LEAD_KEY.LEAD_SOURCE]: {
                    field: ASSIGNED_LEAD_KEY.LEAD_SOURCE,
                    label: "Lead Source",
                },
                [ASSIGNED_LEAD_KEY.LAST_CALL_STATUS]: {
                    field: ASSIGNED_LEAD_KEY.LAST_CALL_STATUS,
                    label: "Last Call Status",
                },
                [ASSIGNED_LEAD_KEY.CREATED_AT]: {
                    field: ASSIGNED_LEAD_KEY.CREATED_AT,
                    label: "Created At",
                },
                [ASSIGNED_LEAD_KEY.UPDATED_AT]: {
                    field: ASSIGNED_LEAD_KEY.UPDATED_AT,
                    label: "Updated At",
                },
                [All_LEAD_KEY.ASSIGNMENT]: {
                    field: All_LEAD_KEY.ASSIGNMENT,
                    label: "Assignment",
                },
            };
        }

        case "CONTACT_INTERESTED": {
            return {
                [ASSIGNED_LEAD_KEY.APPLICATION_ID]: {
                    field: ASSIGNED_LEAD_KEY.APPLICATION_ID,
                    label: "Application Id",
                },
                [ASSIGNED_LEAD_KEY.PHONE_NUMBER]: {
                    field: ASSIGNED_LEAD_KEY.PHONE_NUMBER,
                    label: "Phone Number",
                },
                [ASSIGNED_LEAD_KEY.LOAN_AMOUNT]: {
                    field: ASSIGNED_LEAD_KEY.LOAN_AMOUNT,
                    label: "Loan Amount",
                },
                [ASSIGNED_LEAD_KEY.LEAD_SOURCE]: {
                    field: ASSIGNED_LEAD_KEY.LEAD_SOURCE,
                    label: "Lead Source",
                },
                [ASSIGNED_LEAD_KEY.STATUS]: {
                    field: ASSIGNED_LEAD_KEY.STATUS,
                    label: "Status",
                },
                [ASSIGNED_LEAD_KEY.LAST_CALL_STATUS]: {
                    field: ASSIGNED_LEAD_KEY.LAST_CALL_STATUS,
                    label: "Last Call Status",
                },
                [ASSIGNED_LEAD_KEY.CREATED_AT]: {
                    field: ASSIGNED_LEAD_KEY.CREATED_AT,
                    label: "Created At",
                },
                [ASSIGNED_LEAD_KEY.UPDATED_AT]: {
                    field: ASSIGNED_LEAD_KEY.UPDATED_AT,
                    label: "Updated At",
                },
                [ASSIGNED_LEAD_KEY.TIME_LEFT]: {
                    field: ASSIGNED_LEAD_KEY.TIME_LEFT,
                    label: "Time left for Call",
                },
                [All_LEAD_KEY.ASSIGNMENT]: {
                    field: All_LEAD_KEY.ASSIGNMENT,
                    label: "Assignment",
                },
            };
        }

        case "CONTACT_NOT_INTERESTED": {
            return {
                [ASSIGNED_LEAD_KEY.APPLICATION_ID]: {
                    field: ASSIGNED_LEAD_KEY.APPLICATION_ID,
                    label: "Application Id",
                },
                [ASSIGNED_LEAD_KEY.PHONE_NUMBER]: {
                    field: ASSIGNED_LEAD_KEY.PHONE_NUMBER,
                    label: "Phone Number",
                },
                [ASSIGNED_LEAD_KEY.LOAN_AMOUNT]: {
                    field: ASSIGNED_LEAD_KEY.LOAN_AMOUNT,
                    label: "Loan Amount",
                },
                [ASSIGNED_LEAD_KEY.LEAD_SOURCE]: {
                    field: ASSIGNED_LEAD_KEY.LEAD_SOURCE,
                    label: "Lead Source",
                },
                [ASSIGNED_LEAD_KEY.STATUS]: {
                    field: ASSIGNED_LEAD_KEY.STATUS,
                    label: "Status",
                },
                // [ASSIGNED_LEAD_KEY.LAST_CALL_STATUS]: {
                //     field: ASSIGNED_LEAD_KEY.LAST_CALL_STATUS,
                //     label: "Last Call Status",
                // },
                [ASSIGNED_LEAD_KEY.CREATED_AT]: {
                    field: ASSIGNED_LEAD_KEY.CREATED_AT,
                    label: "Created At",
                },
                [ASSIGNED_LEAD_KEY.UPDATED_AT]: {
                    field: ASSIGNED_LEAD_KEY.UPDATED_AT,
                    label: "Updated At",
                },
                [ASSIGNED_LEAD_KEY.RESTORE]: {
                    field: ASSIGNED_LEAD_KEY.RESTORE,
                    label: "Restore",
                },
            };
        }
    }
};

export const ASSIGNED_LEAD_FILTERS = {
    [ASSIGNED_LEAD_KEY.STATUS]: [
        { label: "Interested", value: "INTERESTED" },
        { label: "Form 1 P1 filled", value: "FORM_1_P1_FILLED" },
        { label: "Form 1 P2 filled", value: "FORM_1_P2_FILLED" },
        { label: "Partial Docs Collected", value: "PARTIAL_DOCS_COLLECTED" },
        { label: "All Docs Collected", value: "ALL_DOCS_COLLECTED" },
        {
            label: "Docs collected and verified",
            value: "DOCS_COLLECTED_AND_VERIFIED",
        },
        {
            label: "Eligible to be sent to partner",
            value: "ELIGIBLE_TO_BE_SENT_TO_PARTNER",
        },
        { label: "Partner Not eligible", value: "PARTNER_NOT_ELIGIBLE" },
        {
            label: "Sent to Partner Pending at partner end",
            value: "SENT_TO_PARTNER_PENDING_AT_PARTNER_END",
        },
        {
            label: "Sent to Partner Pending at our end",
            value: "SENT_TO_PARTNER_PENDING_AT_OUR_END",
        },
        { label: "Approved", value: "APPROVED" },
        { label: "Rejected", value: "REJECTED" },
        { label: "Disbursed", value: "DISBURSED" },
    ],
};

export const ASSIGNED_LEAD_DEFAULT_PAGE_SIZE = 100;

export const ASSIGNED_LEAD_INITIAL_FILTER = {
    filters: [],
    logic: "and",
};

export const LEADS_TYPE = {
    ASSIGNED: "assigned",
    UNASSIGNED: "unassigned",
    ADMIN_UNASSIGNED: "admin_unassigned",
    ADMIN_ASSIGNED: "admin_assigned",
};

export const JOURNEY_INTERNAL_STATUS = {
    FRESH_LEAD: "FRESH_LEAD",
    NOT_CONNECTED: "NOT_CONNECTED",
    FOLLOW_UP_NEEDED: "FOLLOW_UP_NEEDED",
    CONTACT_INTERESTED: "CONTACT_INTERESTED",
    CONTACT_NOT_INTERESTED: "CONTACT_NOT_INTERESTED",
    INTERESTED_DROP_LEAD: "INTERESTED_DROP_LEAD",
};

export const LEAD_STATUS = {
    INTERESTED: "INTERESTED",
    FORM_1_P1_FILLED: "FORM_1_P1_FILLED",
    FORM_1_P2_FILLED: "FORM_1_P2_FILLED",
    PARTIAL_DOCS_COLLECTED: "PARTIAL_DOCS_COLLECTED",
    ALL_DOCS_COLLECTED: "ALL_DOCS_COLLECTED",
    DOCS_COLLECTED_AND_VERIFIED: "DOCS_COLLECTED_AND_VERIFIED",
    ELIGIBLE_TO_BE_SENT_TO_PARTNER: "ELIGIBLE_TO_BE_SENT_TO_PARTNER",
    PARTNER_NOT_ELIGIBLE: "PARTNER_NOT_ELIGIBLE",
    SENT_TO_PARTNER_PENDING_AT_PARTNER_END:
        "SENT_TO_PARTNER_PENDING_AT_PARTNER_END",
    SENT_TO_PARTNER_PENDING_AT_OUR_END: "SENT_TO_PARTNER_PENDING_AT_OUR_END",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
    DISBURSED: "DISBURSED",
};

export const ASSIGNED_SUB_TABS_GRID_CONFIG = {
    DIALER_LEADS: {
        FILTER_PARAMS: {
            dispositionStatus: [
                JOURNEY_INTERNAL_STATUS.FRESH_LEAD,
                JOURNEY_INTERNAL_STATUS.FOLLOW_UP_NEEDED,
                JOURNEY_INTERNAL_STATUS.NOT_CONNECTED,
            ].join(","),
            status: [
                LEAD_STATUS.INTERESTED,
                LEAD_STATUS.FORM_1_P1_FILLED,
                LEAD_STATUS.FORM_1_P2_FILLED,
                LEAD_STATUS.PARTIAL_DOCS_COLLECTED,
                LEAD_STATUS.ALL_DOCS_COLLECTED,
            ].join(","),
        },
    },
    CONTACT_INTERESTED: {
        FILTER_PARAMS: {
            dispositionStatus: JOURNEY_INTERNAL_STATUS.CONTACT_INTERESTED,
            status: [
                LEAD_STATUS.INTERESTED,
                LEAD_STATUS.FORM_1_P1_FILLED,
                LEAD_STATUS.FORM_1_P2_FILLED,
                LEAD_STATUS.PARTIAL_DOCS_COLLECTED,
                LEAD_STATUS.ALL_DOCS_COLLECTED,
            ].join(","),
        },
    },
    ALL_DOCS_COLLECTED: {
        FILTER_PARAMS: {
            status: LEAD_STATUS.DOCS_COLLECTED_AND_VERIFIED,
            lastCallStatus: [
                "FRESH",
                "NOT_CONNECTED",
                "FOLLOW_UP",
                "SUCCESS",
                "INTERESTED_FRESH",
            ].join(","),
        },
    },
    CONTACT_NOT_INTERESTED: {
        FILTER_PARAMS: {
            dispositionStatus: [
                JOURNEY_INTERNAL_STATUS.CONTACT_NOT_INTERESTED,
                JOURNEY_INTERNAL_STATUS.INTERESTED_DROP_LEAD,
            ].join(","),
        },
    },
};
