import React from 'react';
import CheckIcon from '../../../assets/images/checkIcon';
import CrossIcon from '../../../assets/images/crossIcon';

const ConfirmationMessage = ({ setIsDelete, handleDelete, editValue, defaultIndex = false }) => {

  return (
    <div className='confirmation-action-container'>
      <span className='title-color confirmation-message'>
        {defaultIndex
          ? <>Are you sure you want to set <b>{editValue}</b> default?</>
          : <>Are you sure you want to delete <b>{editValue}</b> number?</>
        }
      </span>
      <CrossIcon fillMode='link' className='cancel-button' onClick={() => setIsDelete(false)}>Cancel</CrossIcon>
      <CheckIcon fillMode='link' className='delete-button' onClick={handleDelete}>Delete</CheckIcon>
    </div>
  );
}

export default ConfirmationMessage;
