import { ASSIGNED_LEAD_SUBTABS } from "../../common/constants";

export const TOAST_MESSAGES = {
    SYNC_AGENTS: {
        SUCCESS: {
            message: "Synced Agents list successfully",
            id: "agent-sync-success",
        },
        ERROR: {
            message: "Failed to fetch agent list",
            id: "agent-fetch-error",
        },
    },
};

export const LAST_CALL_STATUS_FILTER_BINDINGS = {
    [ASSIGNED_LEAD_SUBTABS.CONTACT_INTERESTED]: {
        ALLOWED_FILTERS: [
            "INTERESTED_FRESH",
            "NOT_CONNECTED",
            "FOLLOW_UP",
            "SUCCESS",
        ],
        BINDINGS: {},
    },
    [ASSIGNED_LEAD_SUBTABS.ALL_DOCS_COLLECTED]: {
        ALLOWED_FILTERS: [
            "INTERESTED_FRESH",
            "NOT_CONNECTED",
            "FOLLOW_UP",
            "SUCCESS",
        ],
        BINDINGS: {},
    },
};

export const LEAD_FILTERS = {
    LEAD_ID: "leadId",
    PHONE_NUMBER: "phoneNumber",
    NAME: "name",
};

export const LEADS_FILTER_OPTIONS = [
    {
        label: "Phone Number",
        value: LEAD_FILTERS.PHONE_NUMBER,
    },
    {
        label: "Name",
        value: LEAD_FILTERS.NAME,
    },
    {
        label: "Application ID",
        value: LEAD_FILTERS.LEAD_ID,
    },
];
