import { getSessionToken } from "@descope/react-sdk";
import { getSupersetMetaData } from "../utils/constants/url.constants";
import apiInterceptor from "./apiInterceptor";

const API_HEADER_CONSTANTS = {
  USER_EMAIL: 'user-email',
  X_AUTH_KEY: 'x-auth-key',
  API_AUTH_KEY: 'api-auth-key',
  AUTHORIZATION: 'Authorization',
};


const supersetService = (api) => {
  const BASE_ENDPOINT = 'api/v1/security';

  const getCsrfToken = async function () {
    const url = `${BASE_ENDPOINT}/csrf_token/`;
    const resp = await api.get(
      url,
      {headers: {
          [API_HEADER_CONSTANTS.AUTHORIZATION]: `Bearer ${getSessionToken()}`
        }
      }
    );
    return resp.data;
  }

  const getGuestToken = async function (payload) {
    const response = await getCsrfToken();
    const url = `${BASE_ENDPOINT}/guest_token/`;
    return api.post(
      url,
      payload.body,
      {
        headers: {
          "X-CSRFToken": response.result,
          [API_HEADER_CONSTANTS.AUTHORIZATION]: `Bearer ${getSessionToken()}`
        },
        withCredentials: true,
      });
  }

  return {
    getGuestToken,
  };
};

export const supersetApis = supersetService(apiInterceptor(getSupersetMetaData().url));
