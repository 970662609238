export default (api) => {
  const validateAppointmentId = (params = "") => {
    const url = "purchase/api/v1/appointment/validate-appointment/";
    return new Promise((resolve, reject) => {
      api
        .get(`${url}${params}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  return {
    validateAppointmentId,
  };
};
