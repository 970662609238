import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@progress/kendo-react-buttons";

import ShareCarModal from ".";
import "./share-car-modal.scss"
import { shareDealerCars } from "../../../store/actions/allCars";
import ShareCarSuccessModal from "./share-car-success-modal";
import { getLoginUserEmail } from "../../../../../utils/utils";
import { SegmentEvents } from "../../constants/events.constants";
import { ALL_CARS_TABLE_VIEW, EVENT_CAR_SOURCE } from "../../constants/table.constants";
import { trackAnalytics } from "../../services/analytics";

const ShareCarButton = ({ selectedCars, dealerId = null, auctionType = '', refresh = () => { }, isSlider = false }) => {
  const [showShareCarModal, setShowShareCarModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [dealersCount, setDealersCount] = useState(1);
  const dispatch = useDispatch();
  const pathName = window.location.pathname;

  const logAnalytic = (reqObj, eventName = SegmentEvents.Viz_share_car) => {

    const email = getLoginUserEmail();
    let trackData = {
      ...reqObj,
      email_id: email,
      source: dealerId && pathName.includes(ALL_CARS_TABLE_VIEW.all.toLowerCase()) ? EVENT_CAR_SOURCE.cars : EVENT_CAR_SOURCE.dealers,
    };

    trackAnalytics(eventName, trackData);
  }

  useEffect(() => {
    if (selectedCars?.length > 0) {
      const reqObj = {
        dealerId: dealerId.map(ele => Number(ele)),
        appointmentIds: selectedCars[selectedCars.length - 1],
        auctionType: auctionType.toUpperCase()
      }
      logAnalytic(reqObj, SegmentEvents.selected_car_check_box);
    }
  }, [selectedCars])



  return (
    <>
      {selectedCars?.length > 0 && <div className={`share-car-btn-block ${isSlider ? 'share-car-slider-block' : ''}`}>
        <Button
          className='app-primary-btn'
          themeColor={"primary"}
          fillMode={'solid'}
          onClick={() => {
            setShowShareCarModal(true);
            const reqObj = {
              dealerIds: dealerId.map(ele => Number(ele)),
              appointmentIds: [...selectedCars].map(ele => Number(ele)),
              auctionType: auctionType.toUpperCase(),
            }
            logAnalytic(reqObj, SegmentEvents.clicked_share_button);
          }}
        >
          Share with Dealer
        </Button>
        {showShareCarModal && <ShareCarModal count={selectedCars?.length} close={(isYes) => {
          if (isYes) {
            const reqObj = {
              dealerIds: dealerId.map(ele => Number(ele)),
              appointmentIds: [...selectedCars].map(ele => Number(ele)),
              auctionType: auctionType.toUpperCase(),
              number_of_cars_selected: selectedCars.length
            }
            setDealersCount(isSlider ? reqObj?.dealerIds?.length : reqObj.appointmentIds.length);
            shareDealerCars(reqObj).then(res => {
              logAnalytic(reqObj, SegmentEvents.clicked_share_confirm);

              setShowSuccess(true);
              refresh();
              setTimeout(() =>
                setShowSuccess(false), 3000)
              dispatch(setShowShareCarModal(false));
            })
          } else {
            setShowShareCarModal(false);
            dispatch(setShowShareCarModal(false));
          }


        }} />}


      </div>}
      {
        showSuccess && <ShareCarSuccessModal count={dealersCount} isSlider={isSlider} />
      }
    </>
  );
};
export default ShareCarButton;
