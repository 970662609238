import { FIELDS_KEY, INITIAL_TABLE_SETTINGS, TABLE_LABELS } from "../common/constants/table.constants";
import AppTable from "../common/components/app-table";
import SearchBar from "./search-bar";
import './call-logs.scss'
import CallTableCell from "./call-table-cell";
import { useEffect, useRef, useState } from "react";
import { fetchCallLogsData } from "../store/actions/myDealers";
import { useDispatch, useSelector } from "react-redux";
import { AppLoader } from "../common/components/app-loader";
import { formatDateTime, getLoginUserEmail } from "../../../utils/utils";
import { trackAnalytics } from "../common/services/analytics";
import { SegmentEvents } from "../common/constants/events.constants";
import { getPredefinedDateRange, showToastMessage } from "../common/utils/utils";
import DateRangeCalendar from "./date-range-calendar";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { CALENDAR_CONSTANTS } from "../common/constants/filters.constants";
import CallQualityCell from "./call-quality-cell";
import { CallQualityModal } from "./call-quality-cell/call-quality-modal";
import { Dialog } from "@progress/kendo-react-dialogs";
import ScoreCardModal from "./score-card-modal";

const CallLogs = () => {

  const [selectedRow, setSelectedRow] = useState(null);

  const CustomCell = cellProps => {
    return <CallTableCell
      {...cellProps}
    />

  };

  const [callLogsData, setCallLogsData] = useState([]);
  const { userInfo } = useSelector((state) => state.allCars);
  const [value, setValue] = useState(CALENDAR_CONSTANTS[0]);
  const [showCustomDateRange, setShowCustomDateRange] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [date, setDate] = useState(() => {
    const { start, end } = getPredefinedDateRange(CALENDAR_CONSTANTS[0].id);
    return {
      dateRange: {
        start: `${formatDateTime(start).slice(0, 10)}T00:00:00`,
        end: `${formatDateTime(end).slice(0, 10)}T23:59:59`
      }
    };
  });


  const [page, setPage] = useState(INITIAL_TABLE_SETTINGS);
  const { isLoading } = useSelector((state) => state.appLoader);
  const { currentSort, filters } = useSelector((state) => state.callLogs);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('')
  const isInitialLoad = useRef(true);
  const email = getLoginUserEmail();
  const tableRef = useRef(null)


  const trackData = {
    email,
    searched_text: search,
  }

  useEffect(() => {
    if (!isInitialLoad.current) {

      if (search) {
        trackAnalytics(SegmentEvents.Viz_call_logs_searched_text, trackData);
      }
      setPage({
        skip: INITIAL_TABLE_SETTINGS.skip,
        take: INITIAL_TABLE_SETTINGS.take
      });

    }
  }, [search, filters, value, date]);

  useEffect(() => {
    if (!isInitialLoad.current) {
      fetchData({ page: page.skip, size: page.take });
    }

  }, [page])

  useEffect(() => {
    fetchData({ page: page.skip, size: page.take });
    isInitialLoad.current = false;

  }, []);

  const handlePageChange = pageNumber => {
    setPage({
      skip: pageNumber.page.skip / pageNumber.page.take,
      take: pageNumber.page.take
    });
  }

  const SafeCells = safeCellProps => {
    const event = {
      dataItem: safeCellProps.data
    };
    return (
      <td onClick={() => navigateTo(event)}>
        {safeCellProps.children}
      </td>
    );
  };

  const fetchData = ({ page, size }) => {

    const payload = {
      page,
      size,
      userId: userInfo?.id,
      searchQuery: search,
      ...(currentSort === 'conversationTime'
        ? { sort: `conversationDuration,${filters?.sortBy?.conversationTime}` }
        : currentSort === 'callTime'
          ? { sort: `startTime,${filters?.sortBy?.callTime}` }
          : {}),
      fromDateTime: date.dateRange.start,
      toDateTime: date.dateRange.end,

    };


    dispatch(fetchCallLogsData(payload)).then((resp) => {
      setCallLogsData(resp);
    }).catch((err) => {
      showToastMessage(err, false)
    });
  };

  const columns = [
    {
      field: FIELDS_KEY.Date,
      title: TABLE_LABELS.Date,
      cells: CustomCell,
      show: true,
      maxWidth: 100,
    },
    {
      field: FIELDS_KEY.Time,
      title: TABLE_LABELS.Time,
      cells: CustomCell,
      show: true,
      maxWidth: 80,
    },
    {
      title: TABLE_LABELS.ACTION,
      field: FIELDS_KEY.ACTION,
      cells: CustomCell,
      maxWidth: 80,
    },
    {
      title: TABLE_LABELS.DealersCode,
      field: FIELDS_KEY.DealersCode,
      cells: CustomCell,
      maxWidth: 80,
    },
    {
      title: TABLE_LABELS.Number,
      field: FIELDS_KEY.Number,
      cells: CustomCell,
      maxWidth: 100,
    },
    {
      title: TABLE_LABELS.DealerName,
      field: FIELDS_KEY.DealerName,
      cells: CustomCell,
      maxWidth: 80,
    },
    {
      field: FIELDS_KEY.CallType,
      title: TABLE_LABELS.CallType,
      cells: CustomCell,
      show: true,
      maxWidth: 80,
    },
    {
      field: FIELDS_KEY.OverallCallStatus,
      title: TABLE_LABELS.OverallCallStatus,
      cells: CustomCell,
      show: true,
      maxWidth: 100,
    },
    {
      field: FIELDS_KEY.HangupCause,
      title: TABLE_LABELS.HangupCause,
      cells: CustomCell,
      show: true,
      maxWidth: 80,
    },
    {
      field: FIELDS_KEY.CallerStatus,
      title: TABLE_LABELS.CallerStatus,
      cells: CustomCell,
      show: true,
      maxWidth: 100,
    },
    {
      field: FIELDS_KEY.OverallConversationTime,
      title: TABLE_LABELS.OverallConversationTime,
      cells: CustomCell,
      show: true,
      maxWidth: 100,
    },
    {
      field: FIELDS_KEY.CallerName,
      title: TABLE_LABELS.CallerName,
      cells: CustomCell,
      show: true,
      maxWidth: 80,
    },
    {
      field: FIELDS_KEY.Recording,
      title: TABLE_LABELS.Recording,
      cells: CustomCell,
      show: true,
      maxWidth: 80,
    },
    {
      field: FIELDS_KEY.CallQuality,
      title: TABLE_LABELS.CallQuality,
      cells: ({ dataItem }) => {
        return (
          <td className="k-table-td relative quality-container">
            <CallQualityCell data={dataItem} setSelectedRow={setSelectedRow} />
            {selectedRow === dataItem.clientCorrelationId && dataItem?.recordingUrl ? <CallQualityModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} dataItem={dataItem} closeModal={() => setSelectedRow(null)} ref={tableRef} /> : null}

            {isModalOpen && selectedRow === dataItem.clientCorrelationId && <Dialog className="score-modal-container" title={"Score Card"} onClose={toggleDialog}>
              <ScoreCardModal setSelectedRow={setSelectedRow} dataItem={dataItem} />
            </Dialog>
            }
          </td>
        )
      },

      show: true,
      maxWidth: 80,

    }

  ];

  const handleChange = (event) => {
    const selectedValue = event.target.value;



    if (selectedValue.id === CALENDAR_CONSTANTS[4].id) {
      setShowCustomDateRange(true);
      isInitialLoad.current = true;

    } else {
      setShowCustomDateRange(false);

      const dateRange = getPredefinedDateRange(selectedValue.id);
      setDate((prevFilters) => ({
        ...prevFilters,
        dateRange: {
          start: formatDateTime(dateRange.start).slice(0, 10) + "T00:00:00",
          end: formatDateTime(dateRange.end).slice(0, 10) + "T23:59:59"
        },
      }));

    }
    setValue(selectedValue);
  };

  const handleRefreshData = (startDate, endDate) => {
    const fromDateTime = startDate
      ? formatDateTime(startDate).slice(0, 10) + "T00:00:00"
      : null;

    const toDateTime = endDate
      ? formatDateTime(endDate).slice(0, 10) + "T23:59:59"
      : null;

    setDate({
      dateRange: {
        start: fromDateTime,
        end: toDateTime
      }
    })

    isInitialLoad.current = false

    setShowCustomDateRange(false);

  };

  const toggleDialog = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
  }


  return (
    <div className="middleContent">
      <div className="call-log-header">
        <div className="search-container">
          <span className="call-log-text">Call Logs</span>
          <SearchBar setSearch={setSearch} />
        </div>

        <div className="date-dropdown-container">
          <DropDownList
            data={CALENDAR_CONSTANTS}
            value={CALENDAR_CONSTANTS.find(item => item.id === value.id)}
            className="date-dropdown"
            textField="label"
            onChange={handleChange}
            onOpen={CALENDAR_CONSTANTS[4].id === value.id ? () => setShowCustomDateRange(true) : () => setShowCustomDateRange(false)}
            onToggle={() => setShowDropdown(prevState => !prevState)}
          />

          {showCustomDateRange && (
            <div className="date-container">
              <DateRangeCalendar onClose={() => {
                setShowCustomDateRange(false);
              }}
                onApply={
                  (startDate, endDate) => handleRefreshData(startDate, endDate)
                }
                startDate={date.dateRange.start}
                endDate={date.dateRange.end}
              />
            </div>
          )}
        </div>
      </div>

      <div className="call-log-table" ref={tableRef}>
        {isLoading ? <AppLoader />
          :
          <AppTable

            data={callLogsData.data}
            headerData={[
              ...columns
            ]}
            skip={page.skip}
            take={page.take}
            totalCount={callLogsData?.pagination?.totalElements}
            handlePageChange={handlePageChange}
            isHoverable
          />
        }
      </div>

    </div>

  )
}

export default CallLogs