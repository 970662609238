import { useEffect } from 'react';

function useBodyOverflow(isOpen) {
  useEffect(() => {
    if (isOpen && typeof window !== 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);
}

export default useBodyOverflow;
