import { FORM_ELEMENT_TYPE } from "../../../../utils/constants/values.constants";
import {
  FormInput,
  FormComboBox,
  FormDatePicker,
  FormTextArea,
} from "../../../../components";
import { useEffect, useState } from "react";
import { ChallanService } from "../../../../services";
import { challan_item_keys } from "../challan-detail-revamp/viewModel";
import { DD_DATA_SOURCES } from "./viewModel";
const ChallanTask = (props) => {
  const [sources, setSources] = useState(DD_DATA_SOURCES);
  const onInputChange = (e) => {
    const { value } = e;
    const { id } = e.target.props || {};
    const key = id || e.target.name;
    const updatedValue = key === challan_item_keys.NOTICE_NUMBER ? value?.replace(/\s/g, '') : value;
    props.setData({
      ...props.data,
      [key]: { ...props.data[key], value: updatedValue },
    });
  };
  const updateCache = () => {
    let updatedDataCache = { ...props.data };
    Object.keys(updatedDataCache).map((key) => {
      const elementMetaData = props.data[key];
      if (FORM_ELEMENT_TYPE.INPUT === elementMetaData.elementtype) {
        updatedDataCache = {
          ...updatedDataCache,
          [key]: { ...updatedDataCache[key], value: props.item[key] },
        };
      } else if (FORM_ELEMENT_TYPE.DATE === elementMetaData.elementtype) {
        const dateString = props.item[key];
        if (dateString) {
          const dateValue = new Date(dateString);
          updatedDataCache = {
            ...updatedDataCache,
            [key]: { ...updatedDataCache[key], value: dateValue },
          };
        }
      }
    });
    const status = sources[challan_item_keys.CHALLAN_TYPE].find(
      (item) => item.value === props.item[challan_item_keys.CHALLAN_TYPE]
    );
    updatedDataCache = {
      ...updatedDataCache,
      [challan_item_keys.CHALLAN_TYPE]: {
        ...updatedDataCache[challan_item_keys.CHALLAN_TYPE],
        value: status,
      },
    };
    props.setData((items) => ({
      ...items,
      ...updatedDataCache,
    }));
  };
  useEffect(() => {
    updateCache();
    ChallanService.fetchAllCourts().then((resp) => {
      const srcs = resp?.detail?.map((item) => {
        return {
          value: item.id,
          label: item.courtName,
        };
      });
      setSources((courts) => ({
        ...courts,
        [challan_item_keys.CHALLAN_COURT]: srcs,
      }));
      const currentCourt = srcs.find(
        (item) => item.label === props?.item?.court
      );
      props.setData((items) => ({
        ...items,
        [challan_item_keys.CHALLAN_COURT]: {
          ...props.data[challan_item_keys.CHALLAN_COURT],
          value: currentCourt,
        },
      }));
    });
    ChallanService.fetchAllOffenses().then((resp) => {
      const srcs = resp?.detail?.map((item) => {
        return {
          value: item.idChallanOffence,
          label: item.offenceName,
        };
      });
      setSources((offenses) => ({
        ...offenses,
        [challan_item_keys.CHALLAN_NAME]: srcs,
      }));
      const currentOffense = srcs.find(
        (item) => item.value === props.item.idChallanOffence
      );
      props.setData((items) => ({
        ...items,
        [challan_item_keys.CHALLAN_NAME]: {
          ...props.data[challan_item_keys.CHALLAN_NAME],
          value: currentOffense,
        },
      }));
    });
  }, []);
  return (
    <div>
      {Object.keys(props.data).map((key) => {
        const elementMetaData = props.data[key];
        switch (elementMetaData.elementtype) {
          case FORM_ELEMENT_TYPE.SELECT:
            return (
              <FormComboBox
                id={key}
                key={key}
                onChange={onInputChange}
                data={sources[key]}
                textField="label"
                dataItemKey="value"
                value={props.data[key]?.value}
                {...elementMetaData}
              />
            );
          case FORM_ELEMENT_TYPE.DATE:
            return (
              <FormDatePicker
                id={key}
                key={key}
                onChange={onInputChange}
                value={props.data[key]?.value}
                {...elementMetaData}
              />
            );
          case FORM_ELEMENT_TYPE.TEXTAREA:
            return (
              <FormTextArea
                id={key}
                key={key}
                onChange={onInputChange}
                value={props.data[key]?.value}
                {...elementMetaData}
              />
            );
          default:
            return (
              <FormInput
                id={key}
                key={key}
                onChange={onInputChange}
                value={props.data[key]?.value || props.item[key]}
                {...elementMetaData}
              />
            );
        }
      })}
    </div>
  );
};
export default ChallanTask;
