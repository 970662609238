import { getBaseUrl } from "../utils/constants/url.constants";
import { getFFUserType, getLoginUserEmail } from "../utils/utils";
import apiInterceptor from "./apiInterceptor";

const prAutomationService = (api) => {
    const BASE_ENDPOINT = 'oms/purchase';

    const getUserQuoteInfo = (payload) => {
        const endpoint = `${BASE_ENDPOINT}/user/profile?email=${payload?.email}`;

        return api.get(endpoint, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        })
    };

    const getViewReportUrl = (payload) => {
        const endpoint = `inspection/v2/api/getInspectionReport?appointmentId=${payload?.appointmentId}`;

        return api.get(endpoint, {
            headers: {
                "user-type": `${getFFUserType()}`,
                "user-email": getLoginUserEmail(),
            },
        })
    }

    return {
        getUserQuoteInfo,
        getViewReportUrl
    };
};

export const prAutomationApis = prAutomationService(apiInterceptor(getBaseUrl()));

