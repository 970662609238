import React, { useState } from "react";
import { ANNOUNCEMENT_COLUMN_MAPPER, CJ_ANNOUNCEMENT_KEYS } from "./viewModel";
import { getDisplayedColumns } from './helper';
import CustomGrid from "../../../../../common/custom-grid/component";
import { useVisibleColumns } from "./hooks/useVisibleColumns";
import { useFetchAnnouncements } from "./hooks/useFetchAnnouncements";
import { SORT_DIRECTION_KEYS } from "../../../../../utils/constants/values.constants";

const ASSIGNED_LEAD_DEFAULT_PAGE_SIZE = 10;

const INITIAL_SORT = [
    {
        field: CJ_ANNOUNCEMENT_KEYS.TRIGGER_DATE_AND_TIME,
        dir: SORT_DIRECTION_KEYS.ASC,
    }
];

const ALLOWED_SORT_PARAMS = [
    CJ_ANNOUNCEMENT_KEYS.TRIGGER_DATE_AND_TIME,
    CJ_ANNOUNCEMENT_KEYS.EXPIRY_DATE_AND_TIME,
];

const ASSIGNED_LEAD_INITIAL_FILTER = {
    filters: [],
    logic: "and",
};
const AnnouncementView = ({ setAnnouncementDetails, setItemType }) => {

    const [paginationParams, setPaginationParams] = useState({ pageNumber: 0, pageSize: ASSIGNED_LEAD_DEFAULT_PAGE_SIZE });
    const [sortParms, setSortParms] = useState(INITIAL_SORT);
    const [filterParams, setFilterParams] = useState({});
    const [gridFilters, setGridFilters] = useState(ASSIGNED_LEAD_INITIAL_FILTER);
    const { announcementData, totalAnnouncementCount } = useFetchAnnouncements(paginationParams, sortParms, filterParams);

    const pageChange = ({ page = 0, size = ASSIGNED_LEAD_DEFAULT_PAGE_SIZE }) => {
        setPaginationParams({ pageNumber: page, pageSize: size });
    };

    const sortChange = (e) => {
        let sortData = !!e?.sort.length ? e?.sort : INITIAL_SORT;
        let sortKey = sortData[0]?.field;
        let sortDir = sortData[0].dir;
        if (!e?.sort?.length) {
            sortDir =
                sortParms[0].dir === SORT_DIRECTION_KEYS.DESC
                    ? SORT_DIRECTION_KEYS.ASC
                    : SORT_DIRECTION_KEYS.DESC;
        }
        if (ALLOWED_SORT_PARAMS.includes(sortKey)) {
            setSortParms([{ field: sortData[0].field, dir: sortDir }]);
        }
    };

    const visibleColumns = useVisibleColumns(setAnnouncementDetails, gridFilters, setGridFilters, filterParams, setFilterParams, setPaginationParams, setItemType)

    return (
        <CustomGrid
            displayedColumns={getDisplayedColumns()}
            columnMap={visibleColumns}
            data={announcementData}
            rowHeight={1}
            isPagination={true}
            pageChange={pageChange}
            count={totalAnnouncementCount}
            paginationParams={paginationParams}
            sortable={true}
            sortParms={sortParms}
            onSortChange={sortChange}
        />
    )
}
export default AnnouncementView;

