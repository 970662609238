import { FORM_ELEMENT_TYPE } from "../../../../../utils/constants/values.constants";

export const REFERRAL_DETAIL_KEYS = {
    MAIN_HEADER: 'mainHeader',
    STEP_1_HEADER: 'stepHeader1',
    STEP_1_SUB_HEADER: 'stepSubHeader1',
    STEP_2_HEADER: 'stepHeader2',
    STEP_2_SUB_HEADER: 'stepSubHeader2',
    STEP_3_HEADER: 'stepHeader3',
    STEP_3_SUB_HEADER: 'stepSubHeader3',
    TERMS_AND_CONDITIONS: 'termsConditions'
}

export const REFERRAL_DETAIL_FORM_DATA = {
    [REFERRAL_DETAIL_KEYS.MAIN_HEADER]: {
        name: REFERRAL_DETAIL_KEYS.MAIN_HEADER,
        label: "Main Header",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        placeholder: 'Enter Header',
        autocomplete: "false",
    },
    [REFERRAL_DETAIL_KEYS.STEP_2_SUB_HEADER]: {
        name: REFERRAL_DETAIL_KEYS.STEP_2_SUB_HEADER,
        label: "Step 02 Sub-header",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        placeholder: 'Enter Sub-header',
        autocomplete: "false",
    },
    [REFERRAL_DETAIL_KEYS.STEP_1_HEADER]: {
        name: REFERRAL_DETAIL_KEYS.STEP_1_HEADER,
        label: "Step 01 Header",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        placeholder: 'Enter Header',
        autocomplete: "false",
    },
    [REFERRAL_DETAIL_KEYS.STEP_3_HEADER]: {
        name: REFERRAL_DETAIL_KEYS.STEP_3_HEADER,
        label: "Step 03 Header",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        placeholder: 'Enter Header',
        autocomplete: "false",
    },
    [REFERRAL_DETAIL_KEYS.STEP_1_SUB_HEADER]: {
        name: REFERRAL_DETAIL_KEYS.STEP_1_SUB_HEADER,
        label: "Step 01 Sub-header",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        placeholder: 'Enter Sub-header',
        autocomplete: "false",
    },
    [REFERRAL_DETAIL_KEYS.STEP_3_SUB_HEADER]: {
        name: REFERRAL_DETAIL_KEYS.STEP_3_SUB_HEADER,
        label: "Step 03 Sub-header",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        placeholder: 'Enter Sub-header',
        autocomplete: "false",
    },
    [REFERRAL_DETAIL_KEYS.STEP_2_HEADER]: {
        name: REFERRAL_DETAIL_KEYS.STEP_2_HEADER,
        label: "Step 02 Header",
        elementtype: FORM_ELEMENT_TYPE.INPUT,
        placeholder: 'Enter Header',
        autocomplete: "false",
    },
    [REFERRAL_DETAIL_KEYS.TERMS_AND_CONDITIONS]: {
        name: REFERRAL_DETAIL_KEYS.TERMS_AND_CONDITIONS,
        label: "Terms and Conditions",
        elementtype: FORM_ELEMENT_TYPE.RICH_TEXT_EDITOR,
        placeholder: 'Enter Terms and Conditions',
        value: ""
    },
    
};