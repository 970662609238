import React, { useEffect, useState } from "react";
// import styles from "../../assets/style/ff-layout.module.css";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import LayoutStatusBanner from "../../components/layout-status-banner";
import DetailCardLayout from "../../components/layout-detail-card";
// import FormCardLayout from "../../components/layout-form-card";
import { NUMBERS } from "../../../../utils/constants/values.constants";
import { ServiceHistory } from "../../../../services";
import { detail_list_keys } from "./viewModel";
import {
  KendoCard,
  FormComboBox,
  UploadFile,
  FormInput,
} from "../../../../components";
import { FULFILLMENT_STATUS } from "../../service-history/components/service-history-details/viewModels";
import {
  SERVICE_HISTORY_STATUSES,
  service_history_column_keys,
} from "../../service-history/viewModels";
import {
  formatDateTime,
  getFFUserType,
  showToastMessage,
} from "../../../../utils/utils";
import { USER_ROLE_KEYS } from "../../../../utils/constants/menu.constants";
import FileView from "../../../../common/file-view";
import { Loader } from "../../../../common/Loader";
import { updateServiceHistoryItemRow } from "../../../../store/actions/service-history";
import { useDispatch } from "react-redux";
const style = {
  background: "#FFFFFF",
  minHeight: "180px",
  border: "15px solid #EBF5FF",
  boxShadow: "unset",
  borderRadius: "8px",
};
const headerStyle = {
  color: "#003E72",
  fontWeight: "600",
  fontSize: "18px",
};
const INITIAL_DATA = {
  inputStatus: null,
  inputFile: "",
  inputReason: "",
};
const ServiceHistoryDetailContainer = (props) => {
  const { rowData = {}, onBackClick } = props;
  const {
    idServiceHistoryRequest,
    appointmentOrderNumber,
    orderId: appOrderId,
    orderItemId,
    source,
  } = rowData;
  const [detailListKeys, setDetailListKeys] = useState(detail_list_keys);
  //   const [formData, setFormData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shDetailData, setShDetailData] = useState();
  const [payload, setPayload] = useState(INITIAL_DATA);
  const dispatch = useDispatch();
  const userType = getFFUserType();
  const isViewOnly = [
    USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_ADMIN,
    USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_VIEW,
  ].includes(userType);
  //page events
  useEffect(() => {
    if (!refresh && !shDetailData) {
      setRefresh(true);
    }
  }, [idServiceHistoryRequest, shDetailData]);

  useEffect(() => {
    !!refresh && RefreshData();
  }, [refresh]);

  useEffect(() => {
    if (!!shDetailData && !!detailListKeys?.length) {
      const data = detailListKeys.map((item, index) => {
        return {
          ...item,
          value: [
            service_history_column_keys.CREATION_DATE,
            service_history_column_keys.CLOSURE_DATE,
          ].includes(item?.field)
            ? formatDateTime(shDetailData[item.field])
            : shDetailData[item.field] || "-",
        };
      });
      setDetailListKeys(data);
    }
  }, [shDetailData]);

  // page methods
  const RefreshData = () => {
    setIsLoading(true);
    ServiceHistory.getServiceHistoryDetails(idServiceHistoryRequest)
      .then((resp) => {
        setShDetailData(resp?.detail);
        const item = FULFILLMENT_STATUS.find(
          (item) => item.value === resp?.detail?.status
        );
        setPayload((items) => ({
          ...items,
          inputStatus: item,
          inputReason: resp?.detail?.remarks,
          inputFile: resp?.detail?.pdfUrl,
        }));
        dispatch(updateServiceHistoryItemRow(resp?.detail));
      })
      .catch((err) => {
        setShDetailData();
      })
      .finally(() => {
        setIsLoading(false);
        setRefresh(false);
      });
  };
  // data events
  const onFulfillmentStatusChange = (e) => {
    if (
      [
        SERVICE_HISTORY_STATUSES.UNABLE_TO_FULFILL,
        SERVICE_HISTORY_STATUSES.FULFILLED,
      ].includes(e?.value?.value)
    ) {
      setPayload((items) => ({ ...payload, inputStatus: e.value }));
    } else {
      setPayload(INITIAL_DATA);
    }
  };
  const onFileUpload = (e) => {
    setPayload((items) => ({ ...payload, inputFile: e.target.files[0] }));
  };
  const handleFileView = () => {
    if (!!idServiceHistoryRequest && !!shDetailData?.pdfUrl) {
      ServiceHistory.getServiceHistoryPdfUrl(appOrderId, orderItemId)
        .then((resp) => {
          resp?.detail && window.open(resp?.detail, "_blank", "noopener");
        })
        .catch((err) => {
          console.log(err);
          showToastMessage(err?.message || err?.error, false);
        });
    }
  };
  const onReasonChange = (e) => {
    setPayload((items) => ({ ...payload, inputReason: e?.value }));
  };
  const handleOnSubmit = (e) => {
    if (!payload?.inputStatus) {
      showToastMessage("Please select Fulfillment Status", false);
    } else if (
      payload?.inputStatus?.value === SERVICE_HISTORY_STATUSES.FULFILLED &&
      !payload?.inputFile
    ) {
      showToastMessage("Please select valid service report", false);
    } else if (
      payload?.inputStatus?.value ===
        SERVICE_HISTORY_STATUSES.UNABLE_TO_FULFILL &&
      !payload?.inputReason
    ) {
      showToastMessage("Please select unfulfilled reason", false);
    } else {
      let formData = new FormData();
      formData.append("status", payload?.inputStatus?.value);
      payload?.inputStatus?.value === SERVICE_HISTORY_STATUSES.FULFILLED &&
        formData.append("file", payload?.inputFile);
      payload?.inputStatus?.value ===
        SERVICE_HISTORY_STATUSES.UNABLE_TO_FULFILL &&
        formData.append("remarks", payload?.inputReason);
      ServiceHistory.serviceHistoryFulfillment(
        idServiceHistoryRequest,
        formData
      ).then((res) => {
        showToastMessage(res?.message);
        setRefresh(true);
      });
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <GridLayout
          gap={{
            rows: "15px",
            cols: 10,
          }}
        >
          <GridLayoutItem row={1}>
            <LayoutStatusBanner
              status={shDetailData?.status}
              label={`Appt. ID #${appointmentOrderNumber}`}
              onBackClick={onBackClick}
              isSubmitRequired={
                shDetailData?.status === SERVICE_HISTORY_STATUSES.ASSIGNED &&
                !isViewOnly
              }
              isAdmin={userType === USER_ROLE_KEYS.C2B_OPS_FF_SVCHIS_ADMIN}
              onSubmitHandler={handleOnSubmit}
              source={source}
            />
          </GridLayoutItem>
          <GridLayoutItem row={2}></GridLayoutItem>
          <GridLayoutItem row={3}>
            <DetailCardLayout
              headerTitle="Service History Details"
              itemPerRow={NUMBERS.FOUR}
              columnList={detailListKeys}
            />
          </GridLayoutItem>
          <GridLayoutItem row={4}>
            <KendoCard
              headerTitle="Service History Status"
              cardStyle={style}
              headerStyle={headerStyle}
              details={
                <GridLayout
                  gap={{
                    rows: 5,
                    cols: 100,
                  }}
                  style={{
                    padding: "0px 24px",
                  }}
                >
                  <GridLayoutItem col={1}>
                    <FormComboBox
                      isDisplayLabel={true}
                      key={"fulfillmentStatus"}
                      onChange={onFulfillmentStatusChange}
                      data={FULFILLMENT_STATUS}
                      textField="label"
                      dataItemKey="value"
                      label="Fulfillment Status"
                      disabled={
                        shDetailData?.status !==
                          SERVICE_HISTORY_STATUSES.ASSIGNED || isViewOnly
                      }
                      value={payload?.inputStatus}
                    />
                  </GridLayoutItem>
                  {payload?.inputStatus?.value ===
                    SERVICE_HISTORY_STATUSES.FULFILLED && (
                    <GridLayoutItem col={2}>
                      <UploadFile
                        onChange={onFileUpload}
                        accept="application/pdf"
                        label={"Upload Service History Report"}
                        disabled={
                          shDetailData?.status !==
                            SERVICE_HISTORY_STATUSES.ASSIGNED || isViewOnly
                        }
                      />
                      {!!shDetailData?.pdfUrl && (
                        <FileView handleFileView={handleFileView} />
                      )}
                    </GridLayoutItem>
                  )}
                  {payload?.inputStatus?.value ===
                    SERVICE_HISTORY_STATUSES.UNABLE_TO_FULFILL && (
                    <GridLayoutItem col={2}>
                      <FormInput
                        key={"unfulfilledReason"}
                        onChange={onReasonChange}
                        label="Reason"
                        disabled={
                          shDetailData?.status !==
                            SERVICE_HISTORY_STATUSES.ASSIGNED || isViewOnly
                        }
                        value={payload?.inputReason}
                      />
                    </GridLayoutItem>
                  )}
                  {![
                    SERVICE_HISTORY_STATUSES.UNABLE_TO_FULFILL,
                    SERVICE_HISTORY_STATUSES.FULFILLED,
                  ].includes(payload?.inputStatus?.value) && (
                    <GridLayoutItem col={2}>
                      <p>{""}</p>
                    </GridLayoutItem>
                  )}
                </GridLayout>
              }
            />
          </GridLayoutItem>
        </GridLayout>
      )}
    </>
  );
};
export default ServiceHistoryDetailContainer;
