import { FORM_ELEMENT_TYPE } from "../../../../utils/constants/values.constants";
import { dealer_column_keys } from "../../fulfillment-list/viewModels";

export const DEALER_TYPE_DATA = [
  {
    label: "NCD",
    value: "NCD",
  },
  {
    label: "UCD",
    value: "UCD",
  },
  {
    label: "DSA",
    value: "DSA",
  },
];

export const COMPANY_CODE_DATA = [
  {
    label: "CSPL",
    value: "CSPL",
  },
];

export const STATUS_DATA = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Inactive",
    value: "Inactive",
  },
];

export const FormData = {
  [dealer_column_keys.CODE]: {
    name: dealer_column_keys.CODE,
    label: "Dealer Code",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
  [dealer_column_keys.NAME]: {
    name: dealer_column_keys.NAME,
    label: "Dealer Name",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
  [dealer_column_keys.TYPE]: {
    name: dealer_column_keys.TYPE,
    label: "Dealer Type",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    source: DEALER_TYPE_DATA,
    isDisplayLabel: true,
  },
  [dealer_column_keys.COMPANY_CODE]: {
    name: dealer_column_keys.COMPANY_CODE,
    label: "Company Code",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    source: COMPANY_CODE_DATA,
    isDisplayLabel: true,
  },
  [dealer_column_keys.PERM_ADDRESS]: {
    name: dealer_column_keys.PERM_ADDRESS,
    label: "Permanent Address",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
  [dealer_column_keys.PRESENT_ADDRESS]: {
    name: dealer_column_keys.PRESENT_ADDRESS,
    label: "Present Address",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
  [dealer_column_keys.MOBILE]: {
    name: dealer_column_keys.MOBILE,
    label: "Mobile No",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
  [dealer_column_keys.ALTERNATE_NO]: {
    name: dealer_column_keys.ALTERNATE_NO,
    label: "Alternate No",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
  [dealer_column_keys.EMAIL_ADDRESS]: {
    name: dealer_column_keys.EMAIL_ADDRESS,
    label: "Email Address",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
};
