import { DEALER_PAYOUT } from "../types";

const INITIAL_STATE = {
  isLoading: false,
  currentIndex: 0,
  data: [],
  totalCount: 0,
  numberOfElements: 0,
  error: {},
  leadType: "",
};

const DealerPayout = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DEALER_PAYOUT.FETCH_LOADING_START:
      return {
        ...state,
        isLoading: true,
      };
    case DEALER_PAYOUT.FETCH_LOADING_END:
      return {
        ...state,
        isLoading: false,
      };
    case DEALER_PAYOUT.FETCH_PAYOUT_LEADS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        totalCount: action.payload.totalElements,
        numberOfElements: action.payload.numberOfElements,
        error: {},
      };
    case DEALER_PAYOUT.FETCH_PAYOUT_LEADS_FAILURE:
      return {
        ...state,
        data: [],
        totalCount: 0,
        numberOfElements: 0,
        error: action.payload,
      };
    case DEALER_PAYOUT.FETCH_PAYOUT_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        error: {},
      };
    case DEALER_PAYOUT.FETCH_PAYOUT_DETAILS_FAILURE:
      return {
        ...state,
        data: {},
        error: action.payload,
      };
    case DEALER_PAYOUT.SET_LEAD_TYPE_FILTER:
      return {
        ...state,
        leadType: action.payload,
      };
    default:
      return state;
  }
};
export default DealerPayout;
