import "../common/assets/styles/common.scss";
import "./car-details.scss";
import AppTable from "../common/components/app-table"
import { FIELDS_KEY, TABLE_LABELS } from "../common/constants/table.constants"
import { currencyFormat, percentageFormat } from "../common/utils/utils";
import { useEffect, useState } from "react";
const PAIsBids = ({ carDetails }) => {
	const [data, setData] = useState([{
		previousHB: '',
		reqPriceTP: '',
		dealersName: '',
		requestedPrice: '',
	}]);

	useEffect(() => {
		if (carDetails) {
			setData([
				{
					previousHB: currencyFormat(carDetails?.paiDetails?.previousHighestBid),
					reqPriceTP: percentageFormat(carDetails?.paiDetails?.requestedPriceTpRatio),
					dealersName: carDetails?.paiDetails?.dealerDetails?.dealerName,
					requestedPrice: currencyFormat(carDetails?.paiDetails?.requestedPrice),
				}
			])
		}
	}, [carDetails])


	const SafeCells = safeCellProps => {
		const event = {
			dataItem: safeCellProps.data
		};
		return (
			// eslint-disable-next-line no-use-before-define
			<td onClick={() => navigateTo(event)}>
				{safeCellProps.children}
			</td>
		);
	};
	const tableColumns = [
		{
			field: FIELDS_KEY.PreviousHB,
			title: TABLE_LABELS.PreviousHB,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem.previousHB}</div>
				</SafeCells>
			),
		},
		{
			field: FIELDS_KEY.ReqPriceTP,
			title: TABLE_LABELS.ReqPriceTP,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem.reqPriceTP}</div>
				</SafeCells>
			),
		},
		{
			title: TABLE_LABELS.DealersName,
			field: FIELDS_KEY.DealersName,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem.dealersName}</div>
				</SafeCells>
			),
		}, {
			field: FIELDS_KEY.RequestedPrice,
			title: TABLE_LABELS.RequestedPrice,
			cells: ({ dataItem }) => (
				<SafeCells data={dataItem}>
					<div>{dataItem.requestedPrice}</div>
				</SafeCells>
			),
		}];

	return (
		<>
			<div className="current-bids-grid app-table-view-sm app-table">
				<AppTable
					data={data}
					headerData={[
						...tableColumns
					]}
					isHoverable={false}
					totalCount={data.length}
					handlePageChange={() => { }}
					pageable={false}
				/>
			</div>
		</>
	);
};
export default PAIsBids;
