import { getLoginUserId } from "../../utils/utils";

export default (payload, extras = {}) => ({
    event: payload?.action,
    eventCategory: "Seller financing",
    eventAction: payload?.action,
    eventLabel: payload?.token,
    agent_id: getLoginUserId(),
    ...extras,
});
