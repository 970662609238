import TableDateCell from "../../buy-back/components/table-date-cell";

export const APPROVAL_ACTION = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT'
}

export const table_column_keys = {
  APPOINTMENT_ID: 'appointmentId',
  AUCTION_ID: 'auctionId',
  DEALER_ID: 'dealerId',
  BUYBACK_STATE: 'buybackState',
  EVENT_DATE: 'eventDate',
  VALID_TILL_DATE: 'validTillDate',
  BUYBACK_PRICE: 'buybackPrice',
  ACTION: 'action'
}

export const BUY_BACK_STATE = [
  { id: "ACCEPTED", label: "Approved" },
  { id: "REJECTED", label: "Rejected" },
  { id: "REINSPECTION_DONE", label: "Reinspection Done" },
  { id: "EXPIRED", label: "Expired" }
]

export const INSPECTION_REPORT_STATUS = {
  GOOD: 'GOOD',
  SEVERE: 'SEVERE',
  AVERAGE: 'AVERAGE',
}


export const ValidDateCell = ({ dataItem }) => {
  return (
    <TableDateCell dataItem={dataItem} />
  );
}

export const ValidTillDateCell = ({ dataItem }) => {
  return (
    <TableDateCell dataItem={dataItem} />
  );
}

export const getDisplayColumns = () => {
  return Object.values(table_column_keys)
}

export const maxLength = 150;

