import {
    GridLayout,
    GridLayoutItem,
    StackLayout,
} from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { KendoCard } from "../../../../components";
import { UPDATE_CALL_STATUS_BINDINGS, headerStyle, style } from "../constants";
import useMetadata from "../../common/hooks/useMetadata";
import { FieldWrapper } from "@progress/kendo-react-form";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { useState } from "react";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import dayjs from "dayjs";
import { TextArea } from "@progress/kendo-react-inputs";
import classes from "../styles/UpdateCallStatus.module.css";
import { Button } from "@progress/kendo-react-buttons";
import { SFService } from "../../../../services";
import { showToastMessage } from "../../../../utils/utils";
import CustomGrid from "../../../../common/custom-grid/component";
import {
    ADMIN_CALL_HISTORY_COLS,
    AGENT_CALL_HISTORY_COLS,
    CALL_HISTORY_VISIBLE_COLS,
} from "../models";

const getCallStatus = (metadata, subTabKey) => {
    let allowedStatuses =
        UPDATE_CALL_STATUS_BINDINGS[subTabKey]?.ALLOWED_STATUSES ||
        Object.keys(metadata?.callStatus || {});

    const statusList = Object.entries(metadata?.callStatus || {})
        .filter(([key]) => allowedStatuses.includes(key))
        .map(([value, label]) => {
            label =
                UPDATE_CALL_STATUS_BINDINGS[subTabKey]?.BINDINGS[value] ||
                label;
            return { label, value };
        });

    return statusList;
};

const UpdateCallStatus = ({
    leadId,
    callHistory,
    subTabKey,
    isAdmin,
    onSubmit,
}) => {
    const { metadata, loading } = useMetadata();
    const [formData, setFormData] = useState({
        status: null,
        followUpTime: null,
        remarks: "",
    });

    const handleUpdateCallStatus = async () => {
        try {
            const response = await SFService.updateCallStatus(
                { ...formData, source: "Manual" },
                leadId
            );

            const { success } = response;
            if (success) {
                showToastMessage("Status updated successfully", true);
                onSubmit();
            }
        } catch (error) {
            console.log(error);
            showToastMessage(
                error?.errorMessage || "Failed to update status",
                false
            );
        }
    };

    const showFollowUpTime =
        !loading &&
        metadata &&
        Object.keys(metadata).length > 0 &&
        (["DIALER_LEADS", "CONTACT_INTERESTED"].includes(subTabKey)
            ? ["FOLLOW_UP", "SUCCESS", "INTERESTED_FRESH"].includes(
                  formData.status
              )
            : ["FOLLOW_UP"].includes(formData.status));

    const isSubmitDisabled =
        loading ||
        !metadata ||
        !formData.status ||
        (showFollowUpTime && !formData.followUpTime);

    return (
        <KendoCard
            headerTitle="Call Status"
            cardStyle={style}
            headerStyle={headerStyle}
            details={
                <StackLayout orientation="vertical">
                    <GridLayout
                        gap={{
                            rows: 5,
                            cols: 64,
                        }}
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                        }}
                    >
                        <GridLayoutItem row={1} col={1}>
                            <FieldWrapper>
                                <Label>
                                    Call Status{" "}
                                    <span className="text-danger">*</span>
                                </Label>
                                <ComboBox
                                    loading={loading}
                                    placeholder="Select status"
                                    data={
                                        metadata?.callStatus && !loading
                                            ? getCallStatus(metadata, subTabKey)
                                            : []
                                    }
                                    textField="label"
                                    dataItemKey="value"
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            status: e?.value?.value,
                                        }))
                                    }
                                />
                            </FieldWrapper>
                        </GridLayoutItem>
                        <GridLayoutItem
                            row={2}
                            col={1}
                            className={
                                showFollowUpTime
                                    ? classes.visible
                                    : classes.hidden
                            }
                        >
                            <FieldWrapper>
                                <Label>
                                    Follow Up Time{" "}
                                    <span className="text-danger">*</span>
                                </Label>
                                <DateTimePicker
                                    min={new Date()}
                                    max={dayjs().add(15, "day").toDate()}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            followUpTime: dayjs(e.value).format(
                                                "YYYY-MM-DD HH:mm:ss"
                                            ),
                                        }))
                                    }
                                />
                            </FieldWrapper>
                        </GridLayoutItem>
                        <GridLayoutItem row={showFollowUpTime ? 3 : 2} col={1}>
                            <FieldWrapper>
                                <Label>Remarks</Label>
                                <TextArea
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            remarks: e.value,
                                        }))
                                    }
                                />
                            </FieldWrapper>
                        </GridLayoutItem>
                        <GridLayoutItem
                            row={showFollowUpTime ? 4 : 3}
                            col={1}
                            style={{
                                marginLeft: "auto",
                            }}
                        >
                            <Button
                                themeColor="primary"
                                className={`m-1 mb-0 ${classes.submitBtn}`}
                                disabled={isSubmitDisabled}
                                onClick={handleUpdateCallStatus}
                            >
                                Update
                            </Button>
                        </GridLayoutItem>
                    </GridLayout>
                    <>
                        <p className="text-lg k-font-weight-semibold mb-0">
                            Call History
                        </p>
                        <div
                            className="ops-grid-layout-container"
                            style={{
                                padding: 0,
                            }}
                        >
                            <CustomGrid
                                displayedColumns={Object.values(
                                    isAdmin
                                        ? ADMIN_CALL_HISTORY_COLS
                                        : AGENT_CALL_HISTORY_COLS
                                ).map((ele) => ele.field)}
                                columnMap={CALL_HISTORY_VISIBLE_COLS}
                                data={callHistory}
                                count={6}
                                gridClass={classes.callHistoryGrid}
                                gridStyle={{
                                    height: "100%",
                                }}
                                fixedHeight={{ height: "100%" }}
                            />
                        </div>
                    </>
                </StackLayout>
            }
        />
    );
};

export default UpdateCallStatus;
