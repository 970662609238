import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useCallback, useEffect, useState } from "react";
import styles from "../assets/style/ff-layout.module.css";
import SearchBarLayout from "../components/layout-search-bar";
import {
	FF_PAGE_TYPES,
	NUMBERS,
	INITIAL_PAGINATION_PARAMS,
	SORT_DIRECTION_KEYS,
} from "../../../utils/constants/values.constants";
import ActionBarLayout from "../components/layout-action-bar";
import TabGridLayout from "../components/layout-tab-grid";
import {
	DD_DATA_SOURCES,
	SCREEN_TYPE,
	getDisplayedCoulmns,
	getSelectedAssignToMeRowIds,
	verificationColumns,
	verification_column_keys,
} from "./viewModal";
import { useDispatch, useSelector } from "react-redux";
import {
	convertObjectToParams,
	formatDateV2,
	getArrayItemValueByLabel,
	getStatus,
	isFilterFieldPresent,
	isObjectEmpty,
	showToastMessage,
} from "../../../utils/utils";
import { ChallanService, VerficationService } from "../../../services";
import {
	fetchVerificationsList,
	updateVerificationFiltersParams,
} from "../../../store/actions/ops-verification";
import { getter } from "@progress/kendo-data-query";
import { getSelectedState } from "@progress/kendo-react-data-tools";
import { ColumnMenuCheckboxFilter } from "../../../components/custom-grid-filters";
import VerificationDetailContainer from "./verification-detail-container";

const ALLOWED_SORT_PARAMS = [
	verification_column_keys.CREATION_DATE,
	verification_column_keys.CLOSURE_DATE,
];

const initialFilter = {
	filters: [],
	logic: "and",
};

const initialSort = [
	{
		field: verification_column_keys.CREATION_DATE,
		dir: SORT_DIRECTION_KEYS.DESC,
	},
];

const VerificationContainer = () => {
	const idGetter = getter(verification_column_keys.ID);
	const dispatch = useDispatch();
	const opsVerficationData = useSelector((state) => state.opsVerification);
	const { isLoading, data, filters, searchQuery, userAssignments } =
		opsVerficationData || {};
	let loadKey = "";
	const [selectedState, setSelectedState] = useState({});
	const [visibleColumns, setVisibleColumns] = useState(verificationColumns);
	const [isDataExport, setIsDataExport] = useState(false);
	const [filterParams, setFilterParams] = useState({ isAssigned: 0 });
	const [paginationParams, setPaginationParams] = useState(
		INITIAL_PAGINATION_PARAMS
	);

	const [sortParms, setSortParms] = useState(initialSort);
	const [gridFilters, setGridFilters] = useState(initialFilter);
	const [sources, setSources] = useState(DD_DATA_SOURCES);
	const [selectedAppointmentId, setSelectedAppointmentId] = useState();
	const [screen, setScreen] = useState(SCREEN_TYPE.VERIFICATION_LISTING);

	useEffect(() => {
		ChallanService.fetchAllSources().then((resp) => {
			const sourceData = resp?.detail?.map((item) => {
				return {
					value: item.code,
					label: item.name,
				};
			});
			setSources((items) => ({
				...items,
				[verification_column_keys.SOURCE]: sourceData,
			}));
		});

		setVisibleColumns((columns) => ({
			...columns,
			[verification_column_keys.APPOINTMENT_ORDER_ID]: {
				...columns[verification_column_keys.APPOINTMENT_ORDER_ID],
				cell: noticeNumberLinkCell,
			},
			[verification_column_keys.VERIFIED_BY]: {
				...columns[verification_column_keys.VERIFIED_BY],
				cell: AssignedCell,
			},
			[verification_column_keys.CREATION_DATE]: {
				...columns[verification_column_keys.CREATION_DATE],
				cell: CreationDateCell,
			},
			[verification_column_keys.STATUS]: {
				...columns[verification_column_keys.STATUS],
				cell: StatusCell,
			},
		}));
	}, []);

	useEffect(() => {
		setVisibleColumns((columns) => ({
			...columns,
			[verification_column_keys.STATUS]: {
				...columns[verification_column_keys.STATUS],
				headerClassName: isFilterFieldPresent(
					gridFilters?.filters,
					verification_column_keys.STATUS
				)
					? "selected-filter"
					: "de-selected-filter",
				columnMenu: (props) => {
					return (
						<ColumnMenuCheckboxFilter
							{...props}
							filterData={sources}
							userAssignments={userAssignments}
							onFilterButtonClick={handleFilterButtonClick}
							columnMapKey={verification_column_keys.STATUS}
							filter={gridFilters}
						/>
					);
				},
			},
			[verification_column_keys.SOURCE]: {
				...columns[verification_column_keys.SOURCE],
				headerClassName: isFilterFieldPresent(
					gridFilters?.filters,
					verification_column_keys.SOURCE
				)
					? "selected-filter"
					: "de-selected-filter",
				columnMenu: (props) => {
					return (
						<ColumnMenuCheckboxFilter
							{...props}
							filterData={sources}
							userAssignments={userAssignments}
							onFilterButtonClick={handleFilterButtonClick}
							columnMapKey={verification_column_keys.SOURCE}
							filter={gridFilters}
						/>
					);
				},
			},
		}));
	}, [userAssignments, sources, gridFilters]);

	useEffect(() => {
		if (!isObjectEmpty(paginationParams)) {
			fetchOpsVerificationsItems("paginationParams");
		}
	}, [paginationParams]);

	useEffect(() => {
		setGridFilters(initialFilter);
		!!searchQuery
			? setFilterParams({ searchString: searchQuery?.value })
			: setFilterParams({ isAssigned: NUMBERS.ZERO });
	}, [searchQuery]);

	useEffect(() => {
		if (!isObjectEmpty(filterParams)) {
			fetchOpsVerificationsItems("filterParams");
		}
	}, [filterParams]);

	useEffect(() => {
		let params = { ...filterParams };
		params = {
			...params,
			isAssigned: userAssignments,
		};
		setFilterParams(params);
	}, [userAssignments]);

	const resetOpsVerificationItems = () => {
		loadKey = "";
		fetchOpsVerificationsItems("reload");
	};

	const pageChange = ({
		page = INITIAL_PAGINATION_PARAMS.pageNumber,
		size = INITIAL_PAGINATION_PARAMS.pageSize,
	}) => {
		setPaginationParams({ pageNumber: page, pageSize: size });
	};

	const sortChange = (e) => {
		let sortData = !!e?.sort.length ? e?.sort : initialSort;
		let sortKey = sortData[0]?.field;
		let sortDir = sortData[0].dir;
		if (!e?.sort?.length) {
			sortDir =
				sortParms[0].dir === SORT_DIRECTION_KEYS.DESC
					? SORT_DIRECTION_KEYS.ASC
					: SORT_DIRECTION_KEYS.DESC;
		}

		if (ALLOWED_SORT_PARAMS.includes(sortKey)) {
			const params = [
				{
					field: sortKey,
					dir: sortDir,
				},
			];
			setSortParms(params);
			fetchOpsVerificationsItems("sort", params);
		}
	};

	const onExportDataStart = () => {
		setIsDataExport(true);
	};

	const onExportDataEnd = () => {
		setIsDataExport(false);
	};

	const onRowSelectionChange = useCallback(
		(event) => {
			const newSelectedState = getSelectedState({
				event,
				selectedState: selectedState,
				dataItemKey: verification_column_keys.ID,
			});
			setSelectedState(newSelectedState);
		},
		[selectedState]
	);

	const onHeaderSelectionChange = useCallback((event) => {
		const checkboxElement = event.syntheticEvent.target;
		const checked = checkboxElement.checked;
		const newSelectedState = {};
		event.dataItems.forEach((item) => {
			newSelectedState[idGetter(item)] = checked;
			item["selected"] = checked;
		});
		setSelectedState(newSelectedState);
	}, []);

	const onAssignItems = () => {
		const items = getSelectedAssignToMeRowIds(selectedState, data);
		if (!!items.length) {
			onAssignedToMe(items);
		} else {
			showToastMessage(
				"Please select atleast one unassigned row to continue",
				false
			);
		}
	};

	const onAssignedToMe = (data) => {
		VerficationService.assignMeOpsVerification(data)
			.then((resp) => {
				showToastMessage("Assigned successfully");
				setSelectedState({});
			})
			.catch((err) => {
				showToastMessage(err?.message, false);
			})
			.finally(() => {
				resetOpsVerificationItems();
			});
	};

	const fetchOpsVerificationsItems = (key, sort = []) => {
		if (!loadKey && !isLoading) {
			loadKey = key;
			const params = { ...paginationParams, ...filterParams };
			let qsParams = convertObjectToParams(params);
			if (!!sort?.length) {
				qsParams = `${qsParams}&sortBy=${sort[0].field}&sortDir=${sort[0].dir}`;
			}
			dispatch(fetchVerificationsList(qsParams, !!searchQuery));
		}
	};

	const handleFilterButtonClick = React.useCallback(
		(key, e, data, assign) => {
			let _filterParamsData = { ...filterParams };
			delete _filterParamsData[key];
			if (assign !== NUMBERS.FOUR) {
				_filterParamsData = { ..._filterParamsData, isAssigned: assign };
			}
			if (!!e) {
				setGridFilters(e);
				setPaginationParams(INITIAL_PAGINATION_PARAMS);
				e?.filters.map((item) => {
					const updatedFilter = item?.filters.reduce((acc, cur) => {
						const existingItem = acc?.find(
							(transformedItem) => transformedItem[cur.field]
						);
						const itemValue = getArrayItemValueByLabel(
							data[cur.field],
							cur.value
						);
						if (existingItem) {
							existingItem[cur.field] += `,${itemValue}`;
						} else {
							acc.push({ [cur.field]: itemValue });
						}
						return acc;
					}, []);

					const filterParamsData = updatedFilter.reduce(
						(result, obj) => ({ ...result, ...obj }),
						{}
					);
					_filterParamsData = { ..._filterParamsData, ...filterParamsData };
				});
			}
			setFilterParams(_filterParamsData);
			dispatch(updateVerificationFiltersParams({ ..._filterParamsData }));
		},
		[userAssignments, filterParams]
	);

	const CreationDateCell = (event) => {
		return (
			<td className="k-table-td">{formatDateV2(event?.dataItem?.createdAt)}</td>
		);
	};

	const StatusCell = (event) => {
		const status = getStatus(event.dataItem?.status);
		return (
			<td className="k-table-td">
				<span className={status?.css}>{status?.label}</span>
			</td>
		);
	};

	const AssignedCell = (event) => {
		const { updatedBy = "", status } = event.dataItem;
		if (!!updatedBy && status !== "AMENDED") {
			return (
				<td className="k-table-td">
					<span
						style={{
							wordWrap: "break-word",
							textAlign: "center",
							fontSize: "13px",
						}}>
						{updatedBy}
					</span>
				</td>
			);
		} else {
			return (
				<td className="k-table-td">
					{/* <div className="assign-me-wrapper">
						<span
							className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md m-2"
							onClick={() =>
								onAssignedToMe([event.dataItem?.idChallanVerification])
							}>
							Assign to Me
						</span>
					</div> */}
				</td>
			);
		}
	};

	const noticeNumberLinkCell = (props) => {
		const { dataItem } = props || {};
		return (
			<td className="k-table-td ">
				{
					<span
						style={{ color: "#0d6efd", fontSize: "13px", cursor: "pointer" }}
						onClick={() => {
							setSelectedAppointmentId(dataItem?.idChallanVerification);
							setScreen(SCREEN_TYPE.VERIFICATION_DETAIL);
						}}>
						{dataItem?.appointmentOrderId}
					</span>
				}
			</td>
		);
	};

	return (
		<div className="ops-challan-container">
			<div className="ops-grid-layout-container">
				{screen === SCREEN_TYPE.VERIFICATION_DETAIL ? (
					<VerificationDetailContainer
						idChallanVerification={selectedAppointmentId}
						setScreen={setScreen}
					/>
				) : (
					<GridLayout
						gap={{
							rows: "0px",
							cols: 10,
						}}>
						<GridLayoutItem
							row={1}
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}>
							<div className={styles.listing_header}>Verification Panel</div>
							<div style={{ display: "flex", justifyContent: "flex-end" }}>
								<SearchBarLayout type={FF_PAGE_TYPES.VERIFICATION} />
							</div>
						</GridLayoutItem>
						<GridLayoutItem
							row={2}
							style={{
								display: "flex",
								justifyContent: "flex-end",
								alignItems: "center",
							}}>
							<ActionBarLayout
								type={FF_PAGE_TYPES.VERIFICATION}
								// setIsRefreshRequired={setIsRefreshRequired}
								AssignToMe={onAssignItems}
								// MarkAsResolved={MarkAsResolved}
								isDataExport={isDataExport}
								onExportDataStart={onExportDataStart}
							/>
						</GridLayoutItem>
						<GridLayoutItem row={3}>
							<TabGridLayout
								isLoading={isLoading}
								type={FF_PAGE_TYPES.VERIFICATION}
								displayedColumns={getDisplayedCoulmns(visibleColumns)}
								data={data?.map((item) => ({
									...item,
									selected: selectedState[idGetter(item)],
								}))}
								columns={visibleColumns}
								selectable={true}
								selectedField="selected"
								idField={verification_column_keys.ID}
								onRowSelectionChange={onRowSelectionChange}
								onHeaderSelectionChange={onHeaderSelectionChange}
								isDataExport={isDataExport}
								onExportDataEnd={onExportDataEnd}
								isPagination={true}
								pageChange={pageChange}
								paginationParams={paginationParams}
								userAssignments={userAssignments}
								sortable={true}
								sortParms={sortParms}
								sortChange={sortChange}
							/>
						</GridLayoutItem>
					</GridLayout>
				)}
			</div>
		</div>
	);
};

export default VerificationContainer;
