import _debounce from 'lodash/debounce'
import { useDispatch, useSelector } from 'react-redux'

import './table-header.scss'
import SortingIcon from '../../assets/images/sortingIcon'
import { setCurrentSort, setFilter } from '../../../store/actions/callLogs'


export const TotalStockedInHeader = () => {
  return (
    <div className='table-container'>
      <span className='table-text'>Total Stocked In</span>
      <div className='table-content'>
        <span className='table-text'>L3M Avg</span>
        <span className='table-text'>This Month</span>
      </div>
    </div>
  )
}


export const OverallConversationTimeHeader = () => {
  const dispatch = useDispatch();

  const { filters } = useSelector((state) => state.callLogs);

  const handleSort = () => {
    const order = filters?.sortBy?.conversationTime == 'asc' ? 'desc' : 'asc'

    dispatch(setCurrentSort('conversationTime'))
    dispatch(setFilter({
      sortBy: {
        conversationTime: order

      }
    }))
  }
  return (
    <div className='conversation-container'>
      <span className='table-text'>Conversation Time</span>
      <span className='sorting-icon'><SortingIcon onClick={handleSort} /></span>
    </div>
  )
}

export const TimeHeader = () => {
  const dispatch = useDispatch();

  const { filters } = useSelector((state) => state.callLogs);

  const handleSort = () => {
    const order = filters?.sortBy?.callTime == 'asc' ? 'desc' : 'asc'

    dispatch(setCurrentSort('callTime'))

    dispatch(setFilter({
      sortBy: {
        callTime: order
      }

    }))

  }
  return (
    <div className='conversation-container'>
      <span className='table-text'>Time</span>
      <span className='sorting-icon'><SortingIcon onClick={handleSort} /></span>

    </div>
  )
}

export const ValidationHeader = () => {

  return (
    <div className='table-container'>
      <span className='table-text'>Valid</span>
      <div className='table-content'>
        <span className='table-text'>From</span>
        <span className='table-text'>Till</span>
      </div>
    </div>
  )
}
