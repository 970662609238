import { ALL_PAIS_SORT_BY_FILTER } from "../../common/constants/filters.constants";
import { TYPE_ANALYTICS, TYPE_PAIS } from "../types";
// import { AnalyticsBrowser } from '@segment/analytics-next'
const INITIAL_STATE = {
  analyticsInitialized:false
};

const AppAnalytics = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPE_ANALYTICS.INITIALIZE_ANALYTICS:
      return {
        ...state,
        analyticsInitialized: true,
        // analytics: AnalyticsBrowser.load({ writeKey: 'r1iQSQhJfpQRMEsqBeImqzcK6FG8RHTA' })
      };
      case TYPE_ANALYTICS.TRACK_ANALYTICS:
        // state.analytics.track('Hello world');
    default:
      return state;
  }
};
export default AppAnalytics;
