import React, { useEffect, useState } from 'react'
import { FormComboBox, KendoCard } from '../../../../../components'
import { Button } from '@progress/kendo-react-buttons'
import { Dialog } from '@progress/kendo-react-dialogs';
import { AdministratorService } from '../../../../../services';
import ReasonManagerPopup from './component/reason-manager-popup';

export const ReasonManger = () => {

    const cardStyle = {
		background: "#FFFFFF",
		minHeight: "180px",
		border: "15px solid #EBF5FF",
		boxShadow: "unset",
		borderRadius:"8px",
	};
	const headerStyle = {
		color: "#003E72",
		fontWeight: "600",
		fontSize: "18px",
	};

    const headerContainerStyle = {
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between"
    }

    const cardDetailStyle= {
        padding: "10px 26px"
    }

    const [isPopupOpen,setIsPopupOpen] = useState(false);
    const [ctaAction,setCtaAction] = useState("");
    const [delayList, setDelayList] = useState([]);
    const [offenceList, setOffenceList] = useState([]);
    const [rejectList, setRejectList] = useState([]);
    const [verificationRejectList, setVerificationRejectList] = useState([]);
    const [unFulfillList, setUnFulfillList] = useState([]);
    const [selectedDelay,setSelectedDelay] = useState({});
    const [selectedOffence,setSelectedOffence] = useState({});
    const [selectedRejection,setSelectedRejection] = useState({});
    const [selectedVerificationRejection,setSelectedVerificationRejection] = useState({});
    const [selectedUnFullfill,setSelectedUnFullfill] = useState({});

    useEffect(() => {
        handleReload();
    }, [])

    const handleReload = () => {
        setSelectedDelay({});
        setSelectedOffence({});
        setSelectedRejection({});
        setSelectedUnFullfill({});
        setSelectedVerificationRejection({});
        AdministratorService.fetchAllRejectReason().then((res) => {
            const rejectList = res?.detail?.map(item => ({
                value: item?.idChallanReason,
                label: item?.reason,
                status: !!item?.status
            }));
            setRejectList(rejectList);
        }).catch((err) => console.log(err));

        AdministratorService.fetchAllVerificationRejectReason().then((res) => {
            const rejectList = res?.detail?.map(item => ({
                value: item?.idChallanReason,
                label: item?.reason,
                status: !!item?.status
            }));
            setVerificationRejectList(rejectList);
        }).catch((err) => console.log(err));

        AdministratorService.fetchAllDelayReason().then((res) => {
            const delayList = res?.detail?.map(item => ({
                value: item?.idChallanReason,
                label: item?.reason,
                status: !!item?.status
            }));
            setDelayList(delayList);
        }).catch((err) => console.log(err));

        AdministratorService.fetchAllOffenceReason().then((res) => {
            const offenceList = res?.detail?.map(item => ({
                value: item?.idChallanOffence,
                label: item?.offenceName,
                status: !!item?.status
            }));
            setOffenceList(offenceList);
        }).catch((err) => console.log(err));

        AdministratorService.fetchAllUnFulfillReason().then((res) => {
            const unFulfillList = res?.detail?.map(item => ({
                value: item?.idChallanReason,
                label: item?.reason,
                status: !!item?.status
            }));
            setUnFulfillList(unFulfillList);
        }).catch((err) => console.log(err));
    }
    const openReasonMangerPopup = () => {
        setIsPopupOpen(!isPopupOpen);
      }

    const handleClick = (ctaName) => {
        openReasonMangerPopup();
        setCtaAction(ctaName);
    }

    const handleChange = (e) => {
		const { value } = e;
		const { id } = e.target.props;
        if(id === "Delay"){
            setSelectedDelay(value);
            setSelectedOffence("");
        }
        else if(id === "Offence"){
            setSelectedOffence(value);
        }
        else if(id === "Rejection"){
            setSelectedRejection(value);
        }
        else if(id === "UnFulfill"){
            setSelectedUnFullfill(value);
        }
        else if(id === "verificationRejection"){
            setSelectedVerificationRejection(value);
        }
	};

    const getCTA = (key) => {
        if(key === "Delay"){
            return(
                <div>
                    <Button fillMode="outline" themeColor={"primary"} className="m-2" onClick={()=>handleClick("Add Delay Reason")} icon='k-icon k-font-icon k-i-plus'>
                        Add Reason
                    </Button>
                    <span className="k-icon k-font-icon k-i-delete m-2" onClick={()=>handleClick("Delete Delay Reason")} style={{color:"#003E72",cursor:"pointer"}} />
                    <span className="k-icon k-font-icon k-i-edit m-2" onClick={()=>handleClick("Edit Delay Reason")} style={{color:"#003E72",cursor:"pointer"}} />
                </div>
            )
        }
        else if(key === "Offence"){
            return (
                <div>
                    <Button fillMode="outline" themeColor={"primary"} className="m-2" onClick={()=>handleClick("Add Offence")} icon='k-icon k-font-icon k-i-plus'>
                        Add Offence
                    </Button>
                    {/* <span className="k-icon k-font-icon k-i-delete m-2" onClick={()=>handleClick("Delete Offence Reason")} style={{color:"#003E72",cursor:"pointer"}} />
                    <span className="k-icon k-font-icon k-i-edit m-2" onClick={()=>handleClick("Edit Offence Reason")} style={{color:"#003E72",cursor:"pointer"}} /> */}
                </div>
            )
        }
        else if(key === "Rejection"){
            return(
                <div>
                    <Button fillMode="outline" themeColor={"primary"} className="m-2" onClick={()=>handleClick("Add Reject Reason")} icon='k-icon k-font-icon k-i-plus'>
                        Add Reason
                    </Button>
                    <span className="k-icon k-font-icon k-i-delete m-2" onClick={()=>handleClick("Delete Reject Reason")} style={{color:"#003E72",cursor:"pointer"}} />
                    <span className="k-icon k-font-icon k-i-edit m-2" onClick={()=>handleClick("Edit Reject Reason")} style={{color:"#003E72",cursor:"pointer"}} />
                </div>
            )
        }

        else if(key === "verificationRejection"){
            return(
                <div>
                    <Button fillMode="outline" themeColor={"primary"} className="m-2" onClick={()=>handleClick("Add Verification Reject Reason")} icon='k-icon k-font-icon k-i-plus'>
                        Add Reason
                    </Button>
                    <span className="k-icon k-font-icon k-i-delete m-2" onClick={()=>handleClick("Delete Verification Reject Reason")} style={{color:"#003E72",cursor:"pointer"}} />
                    <span className="k-icon k-font-icon k-i-edit m-2" onClick={()=>handleClick("Edit Verification Reject Reason")} style={{color:"#003E72",cursor:"pointer"}} />
                </div>
            )
        }

        else if(key === "UnFulfill"){
            return(
                <div>
                    <Button fillMode="outline" themeColor={"primary"} className="m-2" onClick={()=>handleClick("Add Unable to Fullfill Reason")} icon='k-icon k-font-icon k-i-plus'>
                        Add Reason
                    </Button>
                    <span className="k-icon k-font-icon k-i-delete m-2" onClick={()=>handleClick("Delete Unable to Fullfill Reason")} style={{color:"#003E72",cursor:"pointer"}} />
                    <span className="k-icon k-font-icon k-i-edit m-2" onClick={()=>handleClick("Edit Unable to Fullfill Reason")} style={{color:"#003E72",cursor:"pointer"}} />
                </div>
            )
        }
    }

  return (
    <>
     <KendoCard
				headerTitle={
                    <div style={headerContainerStyle}>
                        <span style={headerStyle}>Challan Rejection Reason</span>
                        {getCTA("Rejection")}
                    </div>
                }
				cardStyle={cardStyle}
				details={
                        <div style={cardDetailStyle}>
                            <div className='administrator-cta-container'>
                                <FormComboBox
                                    id={"Rejection"}
                                    onChange={handleChange}
                                    data={rejectList}
                                    textField="label"
                                    dataItemKey="value"
                                    label={"Select Reason"}
                                    value={selectedRejection}
                                    isDisplayLabel={true}
                                />
                            </div>
                        </div>
                        } />
    <KendoCard
				headerTitle={
                    <div style={headerContainerStyle}>
                        <span style={headerStyle}>Verification Rejection Reason</span>
                        {getCTA("verificationRejection")}
                    </div>
                }
				cardStyle={cardStyle}
				details={
                        <div style={cardDetailStyle}>
                            <div className='administrator-cta-container'>
                                <FormComboBox
                                    id={"verificationRejection"}
                                    onChange={handleChange}
                                    data={verificationRejectList}
                                    textField="label"
                                    dataItemKey="value"
                                    label={"Select Reason"}
                                    value={selectedVerificationRejection}
                                    isDisplayLabel={true}
                                />
                            </div>
                        </div>
                        } />
    <KendoCard
				headerTitle={
                    <div style={headerContainerStyle}>
                        <span style={headerStyle}>Delay Reasons</span>
                        {getCTA("Delay")}
                    </div>
                }
				cardStyle={cardStyle}
				details={
                        <div style={cardDetailStyle}>
                            <div className='administrator-cta-container'>
                                <FormComboBox
                                    id={"Delay"}
                                    onChange={handleChange}
                                    data={delayList}
                                    textField="label"
                                    dataItemKey="value"
                                    label={"Delay Reasons"}
                                    value={selectedDelay}
                                    isDisplayLabel={true}
                                />
                            </div>
                        </div>
                        } />

        <KendoCard
				headerTitle={
                    <div style={headerContainerStyle}>
                        <span style={headerStyle}>Unable to Fulfill Reason</span>
                        {getCTA("UnFulfill")}
                    </div>
                }
				cardStyle={cardStyle}
				details={
                        <div style={cardDetailStyle}>
                            <div className='administrator-cta-container'>
                            <FormComboBox
                                id={"UnFulfill"}
                                onChange={handleChange}
                                data={unFulfillList}
                                textField="label"
                                dataItemKey="value"
                                label={"Unable to Fullfill Reason"}
                                value={selectedUnFullfill}
                                isDisplayLabel={true}
                            />
                            </div>
                        </div>
                        } />
        
         <KendoCard
				headerTitle={
                    <div style={headerContainerStyle}>
                        <span style={headerStyle}>Offence Type</span>
                        {getCTA("Offence")}
                    </div>
                }
				cardStyle={cardStyle}
				details={
                        <div style={cardDetailStyle}>
                            <div className='administrator-cta-container'>
                            <FormComboBox
                                id={"Offence"}
                                onChange={handleChange}
                                data={offenceList}
                                textField="label"
                                dataItemKey="value"
                                label={"Offence Type"}
                                value={selectedOffence}
                                isDisplayLabel={true}
                            />
                            </div>
                        </div>
                        } />

        {isPopupOpen && <Dialog title={ctaAction} onClose={openReasonMangerPopup}>
            <ReasonManagerPopup ctaAction={ctaAction} selectedRejection={selectedRejection} selectedVerificationRejection={selectedVerificationRejection} selectedDelay={selectedDelay} selectedOffence={selectedOffence} selectedUnFullfill={selectedUnFullfill} openReasonMangerPopup={openReasonMangerPopup} handleReload={handleReload} delayList={delayList} offenceList={offenceList} rejectList={rejectList} unFulfillList={unFulfillList} verificationRejectList={verificationRejectList}/>
        </Dialog>}
    </>
  )
}
