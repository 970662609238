import React, { useState, useEffect } from 'react'
import CustomGrid from '../../../../../common/custom-grid/component';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { DD_DATA_SOURCES, STORE_TYPE_LIST, clusterManagementColumns, cluster_management_keys, initialFormData } from './viewModal';
import { FormInput, FormMultiSelect } from '../../../../../components';
import { Button } from '@progress/kendo-react-buttons';
import { getArrayItemValueByLabel, isFilterFieldPresent, isObjectEmpty, showToastMessage } from '../../../../../utils/utils';
import { CjIncentivesService } from '../../../../../services';
import { ColumnMenuCheckboxFilter } from '../../../../../components/custom-grid-filters';

const INITIAL_PAGINATION_PARAMS = {
    pageNumber: 0,
    pageSize: 10,
};

const initialFilter = {
    filters: [],
    logic: "and",
};

function ClusterView({ isOpen, setIsOpen }) {

    const [visibleColumns, setVisibleColumns] = useState(
        clusterManagementColumns
    );
    const [clusterData, setClusterData] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [sources, setSources] = useState(DD_DATA_SOURCES);
    const [formData, setFormData] = useState(initialFormData);
    const [gridFilters, setGridFilters] = useState(initialFilter);
    const [filterParams, setFilterParams] = useState({});
    const [paginationParams, setPaginationParams] = useState(
        INITIAL_PAGINATION_PARAMS
    );

    let loadKey = "";

    useEffect(() => {
        CjIncentivesService.fetchAllCities()
            .then((res) => {
                const mappedCities = res?.detail?.map((city) => ({
                    label: city.city_name,
                    value: city.city_id,
                }));
                setSources((items) => ({
                    ...items,
                    [cluster_management_keys.CITY_NAMES]: mappedCities,
                }));
                setFormData((prevState) => ({ ...prevState, cities: mappedCities }));
            })
            .catch((err) => showToastMessage(err?.message, false));
    }, []);

    useEffect(() => {
        setVisibleColumns((columns) => ({
            ...columns,
            [cluster_management_keys.CITY_NAMES]: {
                ...columns[cluster_management_keys.CITY_NAMES],
                headerClassName: isFilterFieldPresent(
                    gridFilters?.filters,
                    cluster_management_keys.CITY_NAMES
                )
                    ? "selected-filter"
                    : "de-selected-filter",
                columnMenu: (props) => {
                    return (
                        <ColumnMenuCheckboxFilter
                            {...props}
                            filterData={sources}
                            onFilterButtonClick={handleFilterButtonClick}
                            columnMapKey={cluster_management_keys.CITY_NAMES}
                            filter={gridFilters}
                        />
                    );
                },
            },
            [cluster_management_keys.STORE_TYPES]: {
                ...columns[cluster_management_keys.STORE_TYPES],
                headerClassName: isFilterFieldPresent(
                    gridFilters?.filters,
                    cluster_management_keys.STORE_TYPES
                )
                    ? "selected-filter"
                    : "de-selected-filter",
                columnMenu: (props) => {
                    return (
                        <ColumnMenuCheckboxFilter
                            {...props}
                            filterData={sources}
                            onFilterButtonClick={handleFilterButtonClick}
                            columnMapKey={cluster_management_keys.STORE_TYPES}
                            filter={gridFilters}
                        />
                    );
                },
            },
        }));
    }, [sources, gridFilters]);

    useEffect(() => {
		if (!isObjectEmpty(filterParams)) {
			fetchCluster("filterParams");
		}
	}, [filterParams]);

	useEffect(() => {
		if (!isObjectEmpty(paginationParams)) {
			fetchCluster("paginationParams");
		}
	}, [paginationParams]);

    useEffect(() => {
		if (!!formData.selectedCities.length && !!formData.selectedRa.length) {
			const params = {
				storeType: formData.selectedRa.map((type) => type.value).join(","),
				cityId: formData.selectedCities.map((city) => city.value).join(","),
			};
			CjIncentivesService.fetchAllStores(params)
				.then((res) => {
					const mappedStores = res?.detail
						?.map((city) => ({
							label: city.name,
							value: city.storeId,
							displayOrder: city.displayOrder,
						}))
						.sort((a, b) => a.displayOrder - b.displayOrder);
					setFormData((prevState) => ({ ...prevState, stores: mappedStores }));
				})
				.catch((err) => showToastMessage(err?.message, false));
		}
	}, [formData.selectedCities, formData.selectedRa]);

    const handleFilterButtonClick = React.useCallback(
        (key, e, data) => {
            let _filterParamsData = { ...filterParams };
            delete _filterParamsData[key];
            if (!!e) {
                setGridFilters(e);
                e?.filters.map((item) => {
                    const updatedFilter = item?.filters.reduce((acc, cur) => {
                        const existingItem = acc?.find(
                            (transformedItem) => transformedItem[cur.field]
                        );
                        const itemValue = getArrayItemValueByLabel(
                            data[cur.field],
                            cur.value
                        );
                        if (existingItem) {
                            existingItem[cur.field] += `,${itemValue}`;
                        } else {
                            acc.push({ [cur.field]: itemValue });
                        }
                        return acc;
                    }, []);

                    const filterParamsData = updatedFilter.reduce(
                        (result, obj) => ({ ...result, ...obj }),
                        {}
                    );
                    _filterParamsData = { ..._filterParamsData, ...filterParamsData };
                });
            }
            setFilterParams(_filterParamsData);
        },
        [filterParams]
    );


    const getDisplayedCoulmns = (columns) => {
        return Object.keys(columns);
    };

    const pageChange = ({
        page = INITIAL_PAGINATION_PARAMS.pageNumber,
        size = INITIAL_PAGINATION_PARAMS.pageSize,
    }) => {
        setPaginationParams({ pageNumber: page, pageSize: size });
    };

    const handleResetData = () => {
        setFormData(initialFormData);
    };

    const fetchCluster = (key) => {
        if (!loadKey && !isDataLoading) {
            loadKey = key;
            setIsDataLoading(true);
            const params = { ...paginationParams, ...filterParams };
            CjIncentivesService.fetchAllClusters(params)
                ?.then((res) => {
                    setClusterData(res?.detail?.content);
                })
                .catch((err) => showToastMessage(err?.message, false))
                .finally(() => {
                    setIsDataLoading(false);
                });
        }
    };

    const handleOnSubmit = () => {
        if (!formData.clusterName) {
            showToastMessage("Please enter Cluster name", false);
        } else if (!formData.selectedStore?.length) {
            showToastMessage("Please select Store", false);
        } else {
            const payload = {
                clusterName: formData.clusterName,
                storeIds: formData.selectedStore.map((store) => store.value),
            };
            CjIncentivesService.addCluster(payload)
                .then((res) => {
                    showToastMessage(res?.message);
                    handleResetData();
                    fetchCluster("reload");
                    setIsOpen(false);
                })
                .catch((err) => showToastMessage(err?.message, false));
        }
    };

    const handleChange = (event, key) => {
        setFormData((prevState) => ({ ...prevState, [key]: event?.value }));
    };


    return (
        <>
            <CustomGrid
                displayedColumns={getDisplayedCoulmns(visibleColumns)}
                columnMap={visibleColumns}
                isPagination={true}
                data={clusterData}
                pageChange={pageChange}
                paginationParams={paginationParams}
            />
            {isOpen && (
                <Dialog title={"Add Cluster"} onClose={() => setIsOpen(false)}>
                    <GridLayout>
                        <GridLayoutItem row={1}>
                            <FormInput
                                id={"clusterName"}
                                key={"clusterName"}
                                onChange={(event) => handleChange(event, "clusterName")}
                                value={formData?.clusterName}
                                label={"Cluster Name"}
                            />
                        </GridLayoutItem>
                        <GridLayoutItem row={2}>
                            <FormMultiSelect
                                label={"City"}
                                data={formData?.cities}
                                onChange={(event) => handleChange(event, "selectedCities")}
                                value={formData?.selectedCities}
                                placeholder="Please select ..."
                                textField="label"
                                dataItemKey="value"
                            />
                        </GridLayoutItem>
                        <GridLayoutItem row={3}>
                            <FormMultiSelect
                                label={"Store Type"}
                                data={STORE_TYPE_LIST}
                                onChange={(event) => handleChange(event, "selectedRa")}
                                value={formData?.selectedRa}
                                placeholder="Please select ..."
                                textField="label"
                                dataItemKey="value"
                            />
                        </GridLayoutItem>
                        <GridLayoutItem row={4}>
                            <FormMultiSelect
                                label={"Stores"}
                                data={formData?.stores}
                                onChange={(event) => handleChange(event, "selectedStore")}
                                value={formData?.selectedStore}
                                placeholder="Please select ..."
                                textField="label"
                                dataItemKey="value"
                            />
                        </GridLayoutItem>
                    </GridLayout>

                    <DialogActionsBar>
                        <Button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleResetData}>
                            Reset
                        </Button>
                        <Button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleOnSubmit}
                            themeColor={"primary"}>
                            Confirm
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            )}
        </>
    )
}

export default ClusterView;