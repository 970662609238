import { useEffect, useState } from "react";
// import { FormUpload } from "../../../../../../../components";
// import Close from "../../../../negotiation/assets/images/close.svg";
import styles from "../../../../negotiation/assets/styles/pr.styles.module.css";
import { showToastMessage } from "../../../../../../../utils/utils";
import { Button } from "@progress/kendo-react-buttons";
import defaultImage from "../../../../negotiation/assets/images/card.svg";
import panImage from "../../../../negotiation/assets/images/panImage.svg";
import aadhaarFrontImage from "../../../../negotiation/assets/images/aadhaarFrontImage.svg";
import aadhaarBackImage from "../../../../negotiation/assets/images/aadhaarBackImage.svg";

export const UploadCard = (props) => {
  const allowedFileExtensions = ["pdf", "png", "jpg", "jpeg", "gif"];
  const { imageList, setImageList, page, selectedDoc, setUploadedPageCount } =
    props || {};
  // const [uploadFiles, setUploadFiles] = useState([]);
  const [viewImages, setViewImages] = useState([]);
  const [images, setImages] = useState([]);
  useEffect(() => {
    if (selectedDoc && selectedDoc.uploads && selectedDoc.uploads.length) {
      const filteredImage = selectedDoc.uploads.find(
        (file) => file.page === page
      );
      if (filteredImage) {
        setViewImages([filteredImage.attachmentName]);
        setUploadedPageCount((count) => count + 1);
      }
    }
  }, [selectedDoc]);
  const getInitialImage = () => {
    switch (selectedDoc.code) {
      case "PAN_CARD_COPY":
      case "PAN_CARD_COMPANY_COPY":
        return panImage;
      case "AADHAR_CARD":
        if (page === "(F)") {
          return aadhaarFrontImage;
        } else {
          return aadhaarBackImage;
        }
      default:
        return defaultImage;
    }
  };
  const removeImages = () => {
    setImages([]);
    setViewImages([]);
    // setUploadFiles([]);
    const modifiedList = { ...imageList };
    delete modifiedList[page];
    setImageList(modifiedList);
    setUploadedPageCount((count) => count - 1);
  };

  const uploadImage = (value) => {
    // const { value } = props || {};
    // setUploadFiles(value);
    const maxSizeInBytes = 10 * 1024 * 1024; //10 MB
    if (value) {
      let imageCheck = false;
      let sizeCheck = false;

      let imageFiles = Array.from(value).map((file) => {
        if (file.size > maxSizeInBytes) {
          imageCheck = true;
        } else if (
          !allowedFileExtensions.includes(file?.name.split(".").pop())
        ) {
          sizeCheck = true;
        } else {
          return file;
        }
      });
      if (sizeCheck) {
        showToastMessage("File type not allowed", false);
        return;
      }
      if (imageCheck) {
        showToastMessage("File size should be less than 10MB", false);
        return;
      }
      if (value && value[0]) {
        let imageFileUrls = Array.from(value).map((file) => {
          return URL.createObjectURL(file);
        });
        setViewImages(imageFileUrls);
        setUploadedPageCount((count) => count + 1);
      }
      setImages(imageFiles);
      setImageList({ ...imageList, [page]: imageFiles });
    }
  };
  const isPdfType = (i) => {
    if (images?.length) {
      if (images?.[i]?.name.split(".").pop() === "pdf") {
        return true;
      } else {
        return false;
      }
    } else if (selectedDoc?.uploads?.length) {
      const filteredImage = selectedDoc.uploads.find(
        (file) => file.page === page
      );
      if (filteredImage && filteredImage.fileType === "pdf") {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };
  const uploadedImageRender = (img, i) => {
    return (
      <>
        <div className={styles.uploadedImage}>
          {isPdfType(i) ? (
            <object data={img} type="application/pdf"></object>
          ) : (
            <img src={img} alt="uploadedImage" />
          )}
        </div>
        <div className={`${styles.filenameText} justify-between`}>
          <span>{`${viewImages[0].slice(0, 26)}...`}</span>
          <Button
            className={styles.iconButton}
            fillMode="flat"
            icon="x-outline"
            onClick={removeImages}
          />
        </div>
      </>
    );
  };
  const defaultImageRender = () => {
    return (
      <div
        className={styles.imageUpload}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <label htmlFor="fileinput">
          <img src={getInitialImage()} alt="upload" />
        </label>
        <input
          id="fileinput"
          type="file"
          multiple={false}
          onChange={(e) => uploadImage(e.target.files)}
        />
        <p>Drag the file or click in the box to upload</p>
      </div>
    );
  };
  // const CustomListItemUI = () => {
  //   return (
  //     <div>
  //       {viewImages &&
  //         viewImages.length &&
  //         viewImages.map((img, i) => {
  //           return (
  //             <>
  //               <div className={styles.uploadedImage}>
  //                 {/* <img
  //                 className={styles.close}
  //                 src={Close}
  //                 alt="close"
  //                 onClick={removeImages}
  //               /> */}
  //                 {images[i]?.name.split(".").pop() === "pdf" ? (
  //                   <object
  //                     data={img}
  //                     type="application/pdf"
  //                     width="100%"
  //                     height="100%"
  //                   ></object>
  //                 ) : (
  //                   <img src={img} alt="uploadedImage" />
  //                 )}
  //               </div>
  //               <div className={`${styles.filenameText} justify-between`}>
  //                 <span>{`${viewImages[0].slice(0, 26)}...`}</span>
  //                 <Button
  //                   className={styles.iconButton}
  //                   fillMode="flat"
  //                   icon="x-outline"
  //                   onClick={removeImages}
  //                 />
  //               </div>
  //             </>
  //           );
  //         })}
  //     </div>
  //   );
  // };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;

    if (files.length > 0) {
      uploadImage(files);
    }
  };
  return (
    <div className={styles.imageUploadWrapper}>
      {viewImages && viewImages.length
        ? viewImages.map((img, i) => {
            return uploadedImageRender(img, i);
          })
        : defaultImageRender()}
    </div>
  );
};
{
  /* <FormUpload
        id={"file"}
        name={"file"}
        value={uploadFiles}
        onChange={uploadImage}
        // restrictions={{
        //   allowedExtensions: [".pdf", ".png", ".jpg", ".jpeg", ".gif"],
        // }}
        listItemUI={CustomListItemUI}
        className={styles.uploadImageWrapper}
        files={viewImages}
      /> */
}
