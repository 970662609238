import { useState } from "react";
import NoLoanCard from "./no-loan-card";
import RaLoanCard from "./ra-loan-card";
import OpenLoanForm from "./open-loan-form";
import OpenLoanCard from "./open-loan-card";
import CloseLoanForm from "./close-loan-form";
import ClosedLoanCard from "./close-loan-card";
import LinkedLoanCard from "./linked-loan-card";
import { KendoCard } from "../../../../../../components";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { intialLoanDropdownValue } from "../../../../../../utils/constants/values.constants";

const LoanDetailsCard = (props) => {

    const { loanAmount, setLoanAmount, appointmentId, isHypothecation } = props || {};

    const [bankName, setBankName] = useState("");
    const [loanDetails, setLoanDetails] = useState("");
    const [isOpenLoanForm, setIsOpenLoanForm] = useState(false);
    const [isCloseLoanForm, setIsCloseLoanForm] = useState(false);
    const [selectedValue, setSelectedValue] = useState(intialLoanDropdownValue);

    const handleChange = (e) => {
        setSelectedValue(e.target.value);
        switch (e.target.value?.value) {
            case "open": {
                setIsOpenLoanForm(true);
                break;
            }
            case "close": {
                setIsCloseLoanForm(true);
                break;
            }
            default:
                break;
        }
    };

    const handleAddOpenLoan = (props) => {
        const { loanAmount = 0, bank } = props || {};
        setLoanAmount(loanAmount);
        setBankName(bank?.bankName);
        setSelectedValue(intialLoanDropdownValue);
        setIsOpenLoanForm(false);
        setLoanDetails("open");
    };

    const handleAddCloseLoan = (props) => {
        const { bank } = props || {};
        setBankName(bank?.bankName);
        setSelectedValue(intialLoanDropdownValue);
        setIsCloseLoanForm(false);
        setLoanDetails("close");
    };

    const loanDetailsRender = () => {
        return (
            <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                {(() => {
                    if (isHypothecation) {
                        return (
                            <>
                                {
                                    props.appointmentId &&
                                    <RaLoanCard
                                        appointmentId={appointmentId}
                                        handleAddOpenLoan={handleAddOpenLoan}
                                    />
                                }
                            </>
                        );
                    }
                    // else if (!loanDetails) {
                    //     return (
                    //         <>
                    //             <NoLoanCard
                    //                 selectedValue={selectedValue}
                    //                 handleChange={handleChange}
                    //             />
                    //         </>
                    //     );
                    // }
                    // else if (loanDetails === "open") {
                    //     return (
                    //         <OpenLoanCard
                    //             loanAmount={loanAmount}
                    //             bankName={bankName}
                    //             setIsOpenLoanForm={setIsOpenLoanForm}
                    //         />
                    //     );
                    // }
                    // else {
                    //     return (
                    //         <ClosedLoanCard bankName={bankName} />
                    //     );
                    // }
                })()}
            </div>
        );
    };

    return (
        <>
            <KendoCard
                cardStyle={{ background: "#F0F2F5", }}
                headerTitle="Loan Details"
                details={loanDetailsRender()}
            />
        </>
    );
};

export default LoanDetailsCard;
