function MissedCallIcon(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={16}
        height={16}
      >
        <path fill="#D9D9D9" d="M0 0H16V16H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M2.267 13.6L.733 12.1a.64.64 0 01-.2-.467.64.64 0 01.2-.466 9.537 9.537 0 013.384-2.375A10.092 10.092 0 018 8c1.311 0 2.603.264 3.875.792 1.272.527 2.403 1.32 3.392 2.375a.64.64 0 01.2.466.64.64 0 01-.2.467l-1.534 1.5a.65.65 0 01-.867.067L10.934 12.2a.66.66 0 01-.2-.233.662.662 0 01-.067-.3v-1.9a10.083 10.083 0 00-1.3-.317A7.914 7.914 0 008 9.333c-.467 0-.922.04-1.367.117-.444.078-.878.183-1.3.317v1.9a.662.662 0 01-.066.3.66.66 0 01-.2.233l-1.934 1.467a.65.65 0 01-.867-.067zm5.7-6.033l-3.3-3.3V6H3.333V2h4v1.333H5.6l2.35 2.35 3.766-3.766.95.95-4.7 4.7z"
          fill="#E31B54"
        />
      </g>
    </svg>
  )
}

export default MissedCallIcon
