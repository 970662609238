import { useSelector } from "react-redux";
import { KendoCard, KendoListView } from "../../../../../../../components";
import { underscoreToTitleCase } from "../../../../../../../utils/utils";
import { insuranceColorMapping } from "../../../../../../../utils/constants/values.constants";
import { getBadge } from "../../../pr-automation/viewModel";
import styles from '../style.module.css'

const InsuranceDetailsCard = () => {
  const prDetails = useSelector((state) => state.prAutomation);
  const { orderData } = prDetails || {};
  const { insuranceType = "", insuranceUpTo = "" } = orderData || {};
  const insuranceDetails = [
    {
      details: "Insurance type",
      value: insuranceType
        ? underscoreToTitleCase(insuranceType)
        : "NA",
    },
    {
      details: "Insurance validity",
      value: insuranceUpTo ? insuranceUpTo?.split(" ")[0] : "NA",
    },
  ];
  return (
    <div className={styles.head}>
      <div className={styles.head_title}>
        Insurance Details
      </div>
      <div className={`${styles['last']} ${styles['quote_container']}`}>
        <div className={styles['quote_container_data']}>
          <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
            {insuranceDetails?.[0]?.details}
          </span>
          <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
            {insuranceDetails?.[0]?.value}
          </span>
        </div>
        <div className={styles['quote_container_data']}>
          <span className={`k-font-bold k-font-size-lg k-mr-2 ${styles['quote_container_data--label']}`}>
            {insuranceDetails?.[1]?.details}
          </span>
          <span className={`k-font-bold ${styles['quote_container_data--value']}`}>
            {insuranceDetails?.[1]?.value}
          </span>
        </div>
      </div>
    </div>
  );
};

export default InsuranceDetailsCard;
