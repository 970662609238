export const DEFAULT_VALUE = 'N/A';

export const IRItemStatus = {
    Good: 'Good',
    Average: 'Average',
    Severe: 'Severe',
    Default: 'Default',
    NA: 'NA',
    Empty: 'Empty',
}

export const TAB_CAR_DETAILS_COLUMNS = [
    {
        label: "Odometer",
        value: ''
    }, {
        label: "Fuel Type",
        value: ''
    }, {
        label: "Color",
        value: ''
    }, {
        label: "Ownership",
        value: ''
    }, {
        label: "Insurance Expiry",
        value: ''
    }, {
        label: "Insurance Type",
        value: ''
    }, {
        label: "Registration city",
        value: ''
    }, {
        label: "Registration Number",
        value: ''
    },
];

export const CAR_TAG_NAMES = {
    CAR_SHARED: "CAR SHARED",
    SHARED_CAR_VIEWED: "SHARED CAR VIEWED",
    VIEWED_BY_DEALER: "VIEWED BY DEALER",
    BID_BY_DEALER: "BID BY DEALER"
};

export const STATUS_CONSTANTS = {
    SYSTEM_ERROR: 'System Error',
    PROCESSING: 'Processing',
    COMPLETED: 'Completed',
    FAILED: 'Failed',
    PARTIALLY_FAILED: 'Partially Failed',
}

export const APPOINTMENT_STATES = {
    IN_PROCURED: 'IN_PROCURED',
    IN_NEGO: 'IN_NEGO',
    LIVE_AUCTION_RUNNING: 'LIVE_AUCTION_RUNNING',
    OCB_RUNNING: 'OCB_RUNNING',
    AUCTION_OVER: 'AUCTION_OVER',
    CAR_SOLD: 'CAR_SOLD',
    EXTENDED_AUCTION_RUNNING: 'EXTENDED_AUCTION_RUNNING',
}

