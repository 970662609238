export default (api) => {
  const featchCityLocations = (cityId) => {
    return new Promise((resolve, reject) => {
      api
        .get(
          `api/v1/location/by-city-id-and-types?cityId=${cityId}&types=store,locality,B2B,PnS,parking_yard`
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  return {
    featchCityLocations,
  };
};
