import React from 'react';
import styles from '../listing/styles.module.css';
import { showToastMessage } from '../../../../utils/utils';

const findFilterValuesForCheckbox = (filterObject, fieldName) => {
  if (!filterObject || !filterObject?.filters) {
    return [];
  }
  for (const filterGroup of filterObject?.filters) {
    if (!filterGroup?.filters) {
      continue;
    }
    const res = filterGroup?.filters
      .filter(filter => filter?.field === fieldName)
      .map(filter => filter?.value);

    if(res?.length>0){
      return res
    }
  }
  return [];
};

const findFilterValue = (filterObject, fieldName) => {
  if (!filterObject || !filterObject?.filters) {
    return null; 
  }
  for (const filterGroup of filterObject?.filters) {
    if (!filterGroup?.filters) {
      continue;
    }
    for (const filter of filterGroup?.filters) {
      if (filter?.field === fieldName) {
        return filter?.value;
      }
    }
  }
  return null; 
};

export const CheckboxFilterColumnMenuUI = (props) => {
  const { data, name, filterChangeHandler, filters = {} } = props;
  const initialValue = findFilterValuesForCheckbox(filters, name);
  const [value, setValue] = React.useState(initialValue);
  let newFiltersArray = [...(filters?.filters ?? [])];

  const onChange = (event) => {
    const newValue = event.target.value;
    const isChecked = event.target.checked;
    setValue(prevValues =>
      isChecked ? [...prevValues, newValue] : prevValues?.filter(val => val !== newValue)
    );
  };

  const filterButtonClickHandler = () => {
    const updatedFiltersArray = newFiltersArray?.filter(
      filter => !filter?.filters.some(f => f?.field === name)
    );

    if (value.length > 0) {
      const newFilter = {
        logic: "or",
        filters: value.map(val => ({
          field: name,
          operator: "eq",
          value: val
        }))
      };
      updatedFiltersArray.push(newFilter);
    }

    filterChangeHandler(undefined, { filters: updatedFiltersArray, logic: "and" }, undefined, undefined);
  };

  const clearButtonClickHandler = () => {
    setValue([]);
    const updatedFiltersArray = newFiltersArray.filter(
      filter => !filter?.filters?.some(f => f?.field === name)
    );
    filterChangeHandler(undefined, { filters: updatedFiltersArray, logic: "and" }, undefined, undefined);
  };

  return (
    <div className={styles.checkboxFilter}>
      {data?.map((item) => (
        <div key={item.value}>
          <input
            type="checkbox"
            id={item.value}
            name={name}
            value={item.value}
            checked={value.includes(item?.value?.toString())}
            onChange={onChange}
            style={{ marginRight: '10px' }}
          />
          <label htmlFor={item.value}>{item.label}</label>
          <br />
        </div>
      ))}
      <div className={styles.filteractions}>
        <button className={styles.filteractions_filter} onClick={filterButtonClickHandler}>Filter</button>
        <button className={styles.filteractions_clear} onClick={clearButtonClickHandler}>Clear</button>
      </div>
    </div>
  );
};

export const DropdownFilterColumnMenuUI = (props) => {
  const { data, name, filterChangeHandler, filters = {} } = props;
  const [value, setValue] = React.useState(findFilterValue(filters, name));
  let newFiltersArray = [...filters?.filters ?? []];

  const onChange = (event) => {
    setValue(event.target.value);
  };

  const filterButtonClickHandler = () => {
    const filterIndex = newFiltersArray.findIndex(
      filter => filter?.filters.some(f => f?.field === name)
    );
  
    if (filterIndex !== -1) {
      newFiltersArray[filterIndex] = {
        ...newFiltersArray[filterIndex],
        filters: newFiltersArray[filterIndex]?.filters?.map(f =>
          f?.field === name
            ? { ...f, value }
            : f
        )
      };
    } else {
      const newFilter = {
        logic: "or",
        filters: [
          {
            field: name,
            operator: "eq",
            value
          }
        ]
      };
      newFiltersArray.push(newFilter);
    }
    filterChangeHandler(undefined, { filters: newFiltersArray, logic: "and" }, undefined, undefined);
  };

  const clearButtonClickHandler = () => {
    setValue(null);
    const updatedFiltersArray = newFiltersArray?.filter(
      filter => !filter?.filters.some(f => f?.field === name)
    );
    filterChangeHandler(undefined, { filters: updatedFiltersArray, logic: "and" }, undefined, undefined);
  };

  return (
    <div className={styles.dropdownFilter}>
      {data?.map((item) => (
        <div key={item.value}>
          <input
            type="radio"
            id={item.value}
            name={name}
            value={item.value}
            checked={item.value == value}
            onChange={onChange}
            style={{ marginRight: '5px' }}
          />
          <label htmlFor={item.value}>{item.label}</label>
          <br />
        </div>
      ))}
      <div className={styles.filteractions}>
        <button className={styles.filteractions_filter} onClick={filterButtonClickHandler}>Filter</button>
        <button className={styles.filteractions_clear} onClick={clearButtonClickHandler}>Clear</button>
      </div>
    </div>
  );
};

export const InputBoxFilterColumnMenuUI = (props) => {
  const { filters, name, filterChangeHandler } = props;
  const [value, setValue] = React.useState(findFilterValue(filters, name));
  let newFiltersArray = [...filters?.filters ?? []];

  const onChange = (event) => {
    setValue(event.target.value);
  };

  const filterButtonClickHandler = () => {
    const filterIndex = newFiltersArray.findIndex(
      filter => filter?.filters.some(f => f?.field === name)
    );

    if (filterIndex !== -1) {
      newFiltersArray[filterIndex] = {
        ...newFiltersArray[filterIndex],
        filters: newFiltersArray[filterIndex].filters.map(f =>
          f?.field === name
            ? { ...f, value }
            : f
        )
      };
    } else {
      const newFilter = {
        logic: "or",
        filters: [
          {
            field: name,
            operator: "eq",
            value
          }
        ]
      };
      newFiltersArray.push(newFilter);
    }
    if(value)
    {
      filterChangeHandler(undefined, { filters: newFiltersArray, logic: "and" }, undefined, undefined);
    }
    else{
      showToastMessage('Value cannot be empty',false)
    }
  };

  const clearButtonClickHandler = () => {
    setValue('');
    const updatedFiltersArray = newFiltersArray.filter(
      filter => !filter?.filters.some(f => f?.field === name)
    );
    filterChangeHandler(undefined, { filters: updatedFiltersArray, logic: "and" }, undefined, undefined);
  };

  return (
    <div className={styles.inputBoxFilter}>
      <input
        className={styles.inputFilter}
        type='text'
        placeholder='Enter value'
        value={value || ''}
        onChange={onChange}
      />
      <div className={styles.filteractions}>
        <button className={styles.filteractions_filter} onClick={filterButtonClickHandler}>Filter</button>
        <button className={styles.filteractions_clear} onClick={clearButtonClickHandler}>Clear</button>
      </div>
    </div>
  );
};

