import { NUMBERS } from "../../utils/constants/values.constants";
import { OPS_CHALLAN } from "../types";
const INITIAL_STATE = {
	isLoading: false,
	currentIndex: 0,
	data: [],
	totalCount: 0,
	numberOfElements: 0,
	error: {},
	channelSource: "",
	paginationParams: {},
	userAssignments: 0,
	filters: {},
	searchQuery: "",
	delayed: false,
};

const OpsChallanItem = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case OPS_CHALLAN.FETCH_ORDERS_START:
			return {
				...state,
				isLoading: true,
			};
		case OPS_CHALLAN.FETCH_ORDERS_END:
			return {
				...state,
				isLoading: false,
			};
		case OPS_CHALLAN.FETCH_DISPOSE_CHALLAN_ITEMS_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				totalCount: action.payload.totalElements,
				numberOfElements: action.payload.numberOfElements,
				error: {},
			};
		case OPS_CHALLAN.RESET_DISPOSE_CHALLAN_ITEMS:
			return {
				...state,
				data: [],
				totalCount: 0,
				numberOfElements: 0,
				searchQuery: "",
			};
		case OPS_CHALLAN.FETCH_DISPOSE_CHALLAN_ITEMS_FAILURE:
			return {
				...state,
				data: [],
				totalCount: 0,
				numberOfElements: 0,
				error: action.payload,
			};
		case OPS_CHALLAN.SET_CHALLAN_ITEM_SOURCE:
			return {
				...state,
				channelSource: action.payload,
			};
		case OPS_CHALLAN.SET_CHALLAN_ITEM_ASSIGNMENT_TYPE:
			return {
				...state,
				userAssignments: action.payload,
				delayed: false,
			};
		case OPS_CHALLAN.SET_DISPOSE_FILTERS:
			return {
				...state,
				filters: action.payload,
			};
		case OPS_CHALLAN.SET_DISPOSE_SEARCH_QUERY:
			return {
				...state,
				searchQuery: action.payload,
			};
		case OPS_CHALLAN.SET_CHALLAN_ITEM_DELAYED_TYPE:
			return {
				...state,
				delayed: action.payload,
				userAssignments: NUMBERS.FOUR,
			};
		case OPS_CHALLAN.REFRESH_DISPOSE_CHALLAN_ITEM:
			const currentItemIndex = state.data.findIndex(
				(item) => item.idChallanItem === action?.payload?.idChallanItem
			);
			const currentItem = state.data[currentItemIndex];
			let updateCurrentItem = { ...currentItem };
			Object.keys(currentItem).map((key) => {
				updateCurrentItem[key] = action?.payload[key];
			});
			state.data[currentItemIndex] = updateCurrentItem;
			return {
				...state,
			};
		default:
			return state;
	}
};

export default OpsChallanItem;
