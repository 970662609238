import React, { useEffect, useState } from "react";
import CustomGrid from "../../../../common/custom-grid/component";
import { ASSIGNED_LEAD_DEFAULT_PAGE_SIZE, LEADS_TYPE } from "../viewModel";
import { useVisibleColumns } from "../hooks/useVisibleColumns";
import { getDisplayedColumns, assignLeadToAgent } from "../utils/helper";
import { useFetchLeads } from "../hooks/useFetchLeads";
import { getLoginUserEmail, showToastMessage } from "../../../../utils/utils";
import classes from "../styles/LeadsGrid.module.css"
import GridLoader from "../components/GridLoader";

const UnassignedLead = ({ isAdmin, filterData }) => {
    const [paginationParams, setPaginationParams] = useState({
        pageNumber: 0,
        pageSize: ASSIGNED_LEAD_DEFAULT_PAGE_SIZE,
    });
    const [filterParams, setFilterParams] = useState({
        isAssigned: false,
    });
    const [gridFilters, setGridFilters] = useState();
    const [leadAssignment, setLeadAssignment] = useState({});

    const { leadData, totalLeadCount, refreshLeads, isLoading } = useFetchLeads(
        paginationParams,
        filterParams,
        false // Allow Agent Filtering
    );

    const visibleColumns = useVisibleColumns({
        gridFilters,
        setGridFilters,
        filterParams,
        setFilterParams,
        setPaginationParams,
        type: isAdmin ? LEADS_TYPE.ADMIN_UNASSIGNED : LEADS_TYPE.UNASSIGNED,
        refreshLeads,
        setLeadAssignment,
        filterData,
        subType: "UNASSIGNED_LEADS",
    });

    useEffect(() => {
        if (Object.keys(leadAssignment).length) {
            assignLeadToAgent(leadAssignment?.leadId, {
                assignedTo: getLoginUserEmail(),
            })
                .then((resp) => {
                    if (resp && resp.success) {
                        showToastMessage("Lead Assigned Successfully", true);
                        refreshLeads?.();
                        return;
                    }
                    throw new Error("Lead Assigned Failed");
                })
                .catch((err) => {
                    showToastMessage(
                        err?.response?.data?.message || "Something went wrong",
                        false
                    );
                })
                .finally(() => {
                    setLeadAssignment({});
                });
        }
    }, [leadAssignment]);

    const pageChange = ({
        page = 0,
        size = ASSIGNED_LEAD_DEFAULT_PAGE_SIZE,
    }) => {
        setPaginationParams({ pageNumber: page, pageSize: size });
    };

    return (
        <div className="unassigned-leads">
            <GridLoader isLoading={isLoading}>
                <CustomGrid
                    displayedColumns={getDisplayedColumns(
                        isAdmin
                            ? LEADS_TYPE.ADMIN_UNASSIGNED
                            : LEADS_TYPE.UNASSIGNED
                    )}
                    columnMap={visibleColumns}
                    data={leadData}
                    isPagination={true}
                    pageChange={pageChange}
                    count={totalLeadCount}
                    paginationParams={paginationParams}
                    fixedHeight={{ maxHeight: "70vh" }}
                    gridClassName={classes.sfGrid}
                />
            </GridLoader>
        </div>
    );
};

export default UnassignedLead;
