import React, { useEffect, useState, useRef } from 'react';
import styles from './style.module.css';
import useLazyQuery from '../../../../../../../hooks/useLazyQuery';
import { genAiApis } from '../../../../../../../services/genAiApi';
import { Loader } from '@progress/kendo-react-indicators';

function ChatHistory(props) {
  const { appointmentId } = props;
  const [pageNumber, setPageNumber] = useState(0);
  const [chats, setChats] = useState([]);
  const { apiCbFunction: getChatHistory, data: chatHistoryData, isLoading: chatHistoryDataLoading } = useLazyQuery(genAiApis.getChatHistory);
  const containerRef = useRef(null);

  useEffect(() => {
    getChatHistory({
      pageSize: 3,
      pageNumber: pageNumber,
      appointmentId: appointmentId,
    });
  }, [pageNumber]);

  const loadMore = () => {
    const scrollTop = containerRef.current.scrollTop;
    const scrollHeight = containerRef.current.scrollHeight;
    setPageNumber(pageNumber + 1);
    setTimeout(() => {
      containerRef.current.scrollTop = scrollTop + (containerRef.current.scrollHeight - scrollHeight);
    }, 100);
  };

  useEffect(() => {
    if (chatHistoryData && chatHistoryData?.content?.length !== 0) {
      setChats([...chatHistoryData?.content, ...chats]);
    }
  }, [chatHistoryData]);

  const renderContent = () => {
    if (chatHistoryDataLoading && chats?.length === 0) {
      return <Loader />;
    } else if (chatHistoryData?.msg === 'SUCCESS') {
      if (chats.length === 0 && chatHistoryData?.content?.length === 0) {
        return <p className={styles['head_collections--error']}>No records found</p>;
      }
      return (
        <div className={styles.head_data}>
          {chatHistoryDataLoading ? (
            <div style={{ textAlign: 'center' }}>
              <Loader />
            </div>
          ) : (
            !chatHistoryData?.last && (
              <button onClick={loadMore} className={styles.loadMore}>
                Show Older Chats
              </button>
            )
          )}
          {chats?.map((item, index) => {
            const className = `head_data_${item?.type}`;
            return (
              <div key={index} className={styles[className]}>
                <div className={styles.head_data_content}>
                  {parseMarkdown(item?.message)}
                  <p className={styles.timestamp}>{item?.messageDate}</p>
                </div>
              </div>
            );
          })}
        </div>
      )
    }
  }

  return (
    <div className={styles.head} ref={containerRef}>
      {renderContent()}
    </div>
  )
}

export default ChatHistory;

const parseMarkdown = (markdown) => {
  if(!markdown)
    return '';
  const lines = markdown.split('\n');
  const elements = lines.map((line, index) => {
      // Headers
      if (/^###\s/.test(line)) return <h3 key={index}>{line.replace(/^###\s/, '')}</h3>;
      if (/^##\s/.test(line)) return <h2 key={index}>{line.replace(/^##\s/, '')}</h2>;
      if (/^#\s/.test(line)) return <h1 key={index}>{line.replace(/^#\s/, '')}</h1>;

      // Bold text
      if (/\*\*(.*)\*\*/.test(line)) {
          const parts = line.split(/\*\*(.*?)\*\*/g);
          return (
              <p className={styles.markdown_p} key={index}>
                  {parts.map((part, i) =>
                      i % 2 === 1 ? <b key={i}>{part}</b> : part
                  )}
              </p>
          );
      }

      // Italic text
      if (/\*(.*)\*/.test(line)) {
          const parts = line.split(/\*(.*?)\*/g);
          return (
              <p className={styles.markdown_p} key={index}>
                  {parts.map((part, i) =>
                      i % 2 === 1 ? <i key={i}>{part}</i> : part
                  )}
              </p>
          );
      }

      // Links
      if (/\[(.*?)\]\((.*?)\)/.test(line)) {
          const parts = line.split(/(\[.*?\]\(.*?\))/g);
          return (
              <p className={styles.markdown_p} key={index}>
                  {parts.map((part, i) => {
                      const match = part.match(/\[(.*?)\]\((.*?)\)/);
                      if (match) {
                          return (
                              <a key={i} href={match[2]}>
                                  {match[1]}
                              </a>
                          );
                      }
                      return part;
                  })}
              </p>
          );
      }

      return <p className={styles.markdown_p} key={index}>{line}</p>;
  });

  return elements;
};
