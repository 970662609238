import React, { useEffect, useRef } from "react";
import './style.css';
import { getSupersetMetaData } from "../../../../utils/constants/url.constants";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { getLoginUserEmail } from "../../../../utils/utils";
import { supersetApis } from "../../../../services/superset-api";

const key = 'dashboard-superset';

const ZmView = (props) => {
    const raEmail  = getLoginUserEmail();
    const supersetRef = useRef(null);
    const supersetMetaData = getSupersetMetaData();

    useEffect(() => {
        const embedSupersetDashboard = async () => {
            const element = document.getElementById(key);
            if (!element) return;
            supersetRef.current = await embedDashboard({
                id: supersetMetaData.key,
                supersetDomain: supersetMetaData.url,
                mountPoint: element,
                fetchGuestToken: async () => {
                    const response = await supersetApis.getGuestToken({
                        body:{
                          user: {
                              username: raEmail
                          },
                          resources: [
                              {
                                  type: 'dashboard',
                                  id: supersetMetaData.key,
                              },
                          ],
                          rls: [],
                        }
                    });
                    return response.data.token;
                },
                dashboardUiConfig: {
                    hideChartControls: true,
                    hideTitle: true,
                    filters: {
                        expanded: false,
                    },
                },
            });
            const size = await supersetRef.current.getScrollSize();
            if (size.width !== 0) {
                const iframe = document.querySelector("iframe");
                iframe.setAttribute(
                    "sandbox",
                    "allow-same-origin allow-scripts allow-presentation allow-popups"
                );
            }
        };
        embedSupersetDashboard();
        return () => {
            if (supersetRef && supersetRef.current) {
                supersetRef.current.unmount();
            }
        };
    }, []);

    return (<div id={key} />);

};

export default ZmView;

