import { Fragment } from "react"

import { FIELDS_KEY } from "../../common/constants/table.constants"
import './table-cell.scss'
import { convertEpochToFormattedTime, showToastMessage } from "../../common/utils/utils"
import DownloadIcon from "../../common/assets/images/download"
import { STATUS_CONSTANTS } from "../../common/constants/fields.constants"
import { useDispatch } from "react-redux"
import { downloadFile } from "../../store/actions/controlCenter"
import { DEFAULT_TAG_COLOR, PRIMARY_COLOR } from "../../common/constants/color.constants"


const ControlCenterTableCell = ({ dataItem, field }) => {

  const FileUpload = () => {
    return (
      <span>
        {convertEpochToFormattedTime(dataItem?.createdAt)}
      </span>
    )
  }

  const FileName = () => {
    return (
      <span>
        {dataItem.originalFilename || "N/A"}
      </span>
    )

  }

  const FileStatus = () => {

    const statusConfig = {
      [STATUS_CONSTANTS.PROCESSING]: {
        className: "processing",
        text: STATUS_CONSTANTS.PROCESSING
      },
      [STATUS_CONSTANTS.COMPLETED]: {
        className: "completed",
        text: STATUS_CONSTANTS.COMPLETED,
      },
      [STATUS_CONSTANTS.FAILED]: {
        className: "failed",
        text: STATUS_CONSTANTS.FAILED
      },
      [STATUS_CONSTANTS.PARTIALLY_FAILED]: {
        className: "partially-failed",
        text: STATUS_CONSTANTS.PARTIALLY_FAILED
      },
    };

    const customChip = (status) => {

      const { className, text } = statusConfig[status] || {
        className: "system-error",
        text: status
      };

      return (
        <div className={`custom-chip ${className}`}>
          <span className="bulletElement" />
          <span className="status-text">{text}</span>
        </div>
      );
    }

    return (
      <div className="status-container">
        {dataItem.status ? customChip(dataItem.status) : "N/A"}
      </div>
    )

  }

  const Comment = () => {

    const dispatch = useDispatch();

    const isButtonDisabled = () => {
      return dataItem?.status === STATUS_CONSTANTS.SYSTEM_ERROR || dataItem?.status === STATUS_CONSTANTS.PROCESSING;
    };

    const handleDownloadFile = () => {
      dispatch(downloadFile({ uploadType: dataItem.type, filename: dataItem?.filename })).then((res) => {

        const { presignedUrl } = res;

        window.open(presignedUrl, '_blank');
        showToastMessage('File downloaded successfully', true, 1000);

      }).catch((err) => {
        showToastMessage(err.message, false, 1000)
      })

    }


    return (
      <div className="comment-cell" >
        <span>{dataItem.comment || "N/A"}</span>
        <div className={isButtonDisabled() ? 'download-icon-disable' : 'download-icon'} onClick={!isButtonDisabled() ? handleDownloadFile : null}>
          <DownloadIcon fill={!isButtonDisabled() ? PRIMARY_COLOR : DEFAULT_TAG_COLOR} />
        </div>
      </div>
    )

  }


  return (
    <Fragment>
      <td className="k-table-td relative">
        {
          {
            [FIELDS_KEY.UploadedOn]: <FileUpload />,
            [FIELDS_KEY.FileName]: <FileName />,
            [FIELDS_KEY.FileStatus]: <FileStatus />,
            [FIELDS_KEY.Comment]: <Comment />,
          }[field]
        }
      </td>
    </Fragment>
  )
}

export default ControlCenterTableCell