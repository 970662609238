import { useEffect, useState } from "react";
import { SFService } from "../../../../services";
import { showToastMessage } from "../../../../utils/utils";

const useMetadata = () => {
    const [metadata, setMetadata] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchMetadata = async () => {
        try {
            const response = await SFService.fetchMetadata();
            const { success, detail } = response;
            if (success) {
                setMetadata(detail);
            }
        } catch (error) {
            showToastMessage(
                "Failed to fetch metadata",
                false,
                _,
                "fetch-metadata-failed"
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMetadata();
    }, []);

    return { metadata, loading };
};

export default useMetadata;
