import { PR_AUTOMATION } from "../types";

const INITIAL_STATE = {
	isLoading: false,
	docLoading: false,
	prSubmitLoading: false,
	backgroundCheck: {},
	documents: {},
	orderData: {},
	paymentRules: {},
	c24Quote: 0,
	error: {},
	hypoBanks: [],
	vahanMismatch: [],
	virtualNumber: "",
	businessApprovals: [],
	baTypes: [],
	isServiceHistoryEligible: false,
	serviceHistory: [],
	prList: [],
	docs: [],
	documentStatus: [],
	uploadAuthData: {},
	bookSlotData: { isLoading: false, slotData: {} },
	address: {
		stateList: [],
		cityList: [],
	},
	auditCheckDetails: {
		auditCheckStatus: "",
		deliveryPaymentType: "",
		redirectUrl: "",
		auditFlagged: null,
	},
	inspectionReportSummary: [],
	directOcbPrice: {},
	quoteData: {}
};

const PrAutomation = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PR_AUTOMATION.FETCH_PR_SUBMIT_LOADING_START:
			return {
				...state,
				prSubmitLoading: true,
			};
		case PR_AUTOMATION.FETCH_PR_SUBMIT_LOADING_END:
			return {
				...state,
				prSubmitLoading: false,
			};
		case PR_AUTOMATION.FETCH_DOC_LOADING_START:
			return {
				...state,
				docLoading: true,
			};
		case PR_AUTOMATION.FETCH_DOC_LOADING_END:
			return {
				...state,
				docLoading: false,
			};
		case PR_AUTOMATION.FETCH_LOADING_START:
			return {
				...state,
				isLoading: true,
			};
		case PR_AUTOMATION.FETCH_LOADING_END:
			return {
				...state,
				isLoading: false,
			};
		case PR_AUTOMATION.FETCH_SLOTS_LOADING_START:
			return {
				...state,
				bookSlotData: { ...state.bookSlotData, isLoading: true },
			};
		case PR_AUTOMATION.FETCH_SLOTS_LOADING_END:
			return {
				...state,
				bookSlotData: { ...state.bookSlotData, isLoading: false },
			};
		case PR_AUTOMATION.FETCH_PR_DETAILS_SUCCESS:
			return {
				...state,
				backgroundCheck:
					action.payload.data && action.payload.data.backgroundCheck?.data,
				documents: action.payload.data && action.payload.data.documents?.data,
				orderData: action.payload.data && action.payload.data.orderData?.data,
				paymentRules:
					action.payload.data && action.payload.data.paymentRules?.data,
				virtualNumber:
					action.payload.data &&
					action.payload.data.lmsData?.data &&
					action.payload.data.lmsData?.data?.virtualNumber,
				inspectionReportSummary:
					action?.payload?.data?.inspectionReportSummary?.data?.categories,
        lmsData:action.payload.data &&action.payload.data.lmsData?.data,
        dreDocuments:action.payload.data &&action.payload.data?.dreDocuments?.data,
				directOcbPrice: action?.payload?.data?.directOcbPrice?.data || {},
        crmLeadDetails:action.payload.data &&action.payload.data.crmLeadDetails,
				error: {},
			};
		case PR_AUTOMATION.FETCH_AUDIT_CHECKS_SUCCESS:
			return {
				...state,
				auditCheckDetails: {
					auditCheckStatus:
						action.payload.data && action.payload.data.auditCheckStatus,
					deliveryPaymentType:
						action.payload.data && action.payload.data.deliveryPaymentType,
					redirectUrl: action.payload.data && action.payload.data.redirectUrl,
					auditFlagged: action.payload.data && action.payload.data.auditFlagged,
				},
				error: {},
			};
		case PR_AUTOMATION.FETCH_AUDIT_CHECKS_FAILURE:
			return {
				...state,
				auditCheckDetails: {
					auditCheckStatus: "",
					deliveryPaymentType: "",
					redirectUrl: "",
					auditFlagged: false,
				},
				error: action.payload,
			};
		case PR_AUTOMATION.FETCH_PR_C24QUOTE_SUCCESS:
			return {
				...state,
				c24Quote: action.payload?.data?.[0]?.c24Quote,
				negotiationAmount: action.payload?.data?.[0]?.negotiationAmount,
				quoteData: action.payload?.data?.[0],
				error: {},
			};
		case PR_AUTOMATION.FETCH_PR_C24QUOTE_FAILURE:
			return {
				...state,
				c24Quote: 0,
				error: action.payload,
			};
		case PR_AUTOMATION.FETCH_SLOTS_SUCCESS:
			return {
				...state,
				bookSlotData: {
					...state.bookSlotData,
					slotData: action.payload.data,
				},
				error: {},
			};
		case PR_AUTOMATION.FETCH_SLOTS_FAILURE:
			return {
				...state,
				bookSlotData: {},
				error: action.payload,
			};
		case PR_AUTOMATION.FETCH_STATES_SUCCESS:
			return {
				...state,
				address: {
					...state.address,
					stateList: action.payload.data,
				},
				error: {},
			};
		case PR_AUTOMATION.FETCH_STATES_FAILURE:
			return {
				...state,
				address: {
					...state.address,
					stateList: [],
				},
				error: action.payload,
			};

		case PR_AUTOMATION.FETCH_CITIES_SUCCESS:
			return {
				...state,
				address: {
					...state.address,
					cityList: action.payload.data,
				},
				error: {},
			};
		case PR_AUTOMATION.FETCH_CITIES_FAILURE:
			return {
				...state,
				address: {
					...state.address,
					cityList: [],
				},
				error: action.payload,
			};
		case PR_AUTOMATION.FETCH_UPLOAD_AUTH_SUCCESS:
			return {
				...state,
				uploadAuthData: action.payload.data,
				error: {},
			};
		case PR_AUTOMATION.FETCH_UPLOAD_AUTH_FAILURE:
			return {
				...state,
				uploadAuthData: {},
				error: action.payload,
			};
		case PR_AUTOMATION.FETCH_HYPO_BANKS_SUCCESS:
			return {
				...state,
				hypoBanks: action.payload.data,
				error: {},
			};
		case PR_AUTOMATION.FETCH_HYPO_BANKS_FAILURE:
			return {
				...state,
				hypoBanks: [],
				error: action.payload,
			};
		case PR_AUTOMATION.FETCH_KYC_DOCS_SUCCESS:
			return {
				...state,
				docs: action.payload.data,
				error: {},
			};
		case PR_AUTOMATION.FETCH_KYC_DOCS_FAILURE:
			return {
				...state,
				docs: [],
				error: action.payload,
			};
		case PR_AUTOMATION.FETCH_DOCS_STATUS_SUCCESS:
			return {
				...state,
				documentStatus:
					action.payload.data &&
					action.payload.data.docketData &&
					action.payload.data.docketData[0] &&
					action.payload.data.docketData[0].documents,
				error: {},
			};
		case PR_AUTOMATION.FETCH_DOCS_STATUS_FAILURE:
			return {
				...state,
				documentStatus: [],
				error: action.payload,
			};
		case PR_AUTOMATION.FETCH_BUSINESS_APPROVAL_SUCCESS:
			return {
				...state,
				businessApprovals: action.payload.data,
				error: {},
			};
		case PR_AUTOMATION.FETCH_BUSINESS_APPROVAL_FAILURE:
			return {
				...state,
				businessApprovals: [],
				error: action.payload,
			};
		case PR_AUTOMATION.FETCH_BUSINESS_APPROVAL_TYPES_SUCCESS:
			return {
				...state,
				baTypes: action.payload.data,
				error: {},
			};
		case PR_AUTOMATION.FETCH_BUSINESS_APPROVAL_TYPES_FAILURE:
			return {
				...state,
				baTypes: [],
				error: action.payload,
			};
		case PR_AUTOMATION.FETCH_SERVICE_ELIGIBLE_SUCCESS:
			return {
				...state,
				isServiceHistoryEligible:
					action.payload.data && action.payload.data.isServiceHistoryEligible,
				error: {},
			};
		case PR_AUTOMATION.FETCH_SERVICE_ELIGIBLE_FAILURE:
			return {
				...state,
				isServiceHistoryEligible: false,
				error: action.payload,
			};
		case PR_AUTOMATION.FETCH_VAHAN_MISMATCH_SUCCESS:
			return {
				...state,
				vahanMismatch: action.payload.data,
				error: {},
			};
		case PR_AUTOMATION.FETCH_VAHAN_MISMATCH_FAILURE:
			return {
				...state,
				vahanMismatch: [],
				error: action.payload,
			};
		case PR_AUTOMATION.FETCH_SERVICE_HISTORY_SUCCESS:
			return {
				...state,
				serviceHistory: action.payload.data,
				error: {},
			};
		case PR_AUTOMATION.FETCH_SERVICE_HISTORY_FAILURE:
			return {
				...state,
				serviceHistory: [],
				error: action.payload,
			};
		case PR_AUTOMATION.FETCH_PR_LIST_SUCCESS:
			return {
				...state,
				prList: action.payload.data,
				error: {},
			};
		case PR_AUTOMATION.FETCH_PR_LIST_FAILURE:
			return {
				...state,
				prList: [],
				error: action.payload,
			};
		case PR_AUTOMATION.FETCH_PR_DETAILS_FAILURE:
			return {
				...state,
				backgroundCheck: {},
				documents: {},
				orderData: {},
				paymentRules: {},
				inspectionReportSummary: {},
				directOcbPrice: {},
				error: action.payload,
			};
		default:
			return state;
	}
};
export default PrAutomation;
