import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import styles from "../assets/style/ff-layout.module.css";
import TabGridLayout from "../components/layout-tab-grid";
import SearchBarLayout from "../components/layout-search-bar";
import ActionBarLayout from "../components/layout-action-bar";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
	challan_item_keys,
	SELECTED_FIELD_KEY,
	getSelectedAssignToMeRowIds,
	getSelectedBulkRowIds,
} from "../components/challan-detail-revamp/viewModel";
import { DD_DATA_SOURCES, dispose_columns } from "./viewModal";
import {
	resetChallanDataItems,
	fetchDisposeChallanItems,
	// updateChallanItemFiltersParams,
	setChallanDisposeSearchQuery,
	updateChallanItemAssignmentTypeParams,
} from "../../../store/actions/ops-challan";
import {
	convertObjectToParams,
	isObjectEmpty,
	showToastMessage,
	// getFromLocalStorage,
	shortenString,
	getFFUserType,
	getUserFirstNameFromEmail,
	getArrayItemValueByLabel,
	isFilterFieldPresent,
	getUserRoles,
} from "../../../utils/utils";
import {
	FF_PAGE_TYPES,
	// LOCAL_STORAGE_KEYS,
	CHALLAN_ITEM_STATUSES,
	// CHALLAN_SOURCE_KEYS,
	NUMBERS,
	// CHALLAN_MODE_TYPES,
	WORD_LIMIT_TRUNCATE,
	CHALLAN_MODE_TYPES,
	CHALLAN_SOURCE_KEYS,
} from "../../../utils/constants/values.constants";
import {
	FORM_TYPES,
	// CHALLAN_PAYMENT_FORM_DATA,
	ASSIGN_AGENT_FORM_DATA,
	// REJECT_CHALLAN_FORM_DATA,
	// PAYMENT_STATUSES,
	ADMIN_ASSIGN_AGENT_POPUP,
	ADMIN_RE_ASSIGN_AGENT_POPUP,
	ADMIN_BULK_ASSIGNMENT_AGENT_POPUP,
	// calculateTotalPayment,
} from "../components/dispose-challan-item-popup/viewModel";
import { dispose_filters_formData } from "../challan-list/filters/components/dispose-filters/viewModel";
import { getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import {
	challan_request_keys,
	getPageTitle,
	page_params,
} from "../challan-list/viewModels";
import { ChallanService } from "../../../services";
import { FormDropDownList } from "../../../components";
import { ColumnMenuCheckboxFilter } from "../../../components/custom-grid-filters";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "@progress/kendo-react-buttons";
import { USER_ROLE_KEYS } from "../../../utils/constants/menu.constants";
import ChallanItemPopup from "../components/dispose-challan-item-popup";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import srcEditImg from "../../../assets/img/edit-ico.svg";
import DisposeDetailView from "./dispose-detail-container";
import { DISPOSER_ASSIGNED_VALUES } from "./dispose-detail-container/viewModal";
const idGetter = getter(challan_item_keys.CHALLAN_ID);
const ALLOWED_SORT_PARAMS = [
	challan_item_keys.AGEING,
	challan_item_keys.ATTEMP_COUNT,
];
const initialFilter = {
	filters: [],
	logic: "and",
};
const DisposeContainerView = (props = {}) => {
	const dispatch = useDispatch();
	const challanItems = useSelector((state) => state.opsChallanItem);
	const {
		isLoading,
		data,
		totalCount,
		// numberOfElements,
		channelSource,
		userAssignments,
		filters,
		searchQuery,
		delayed,
	} = challanItems;
	const [visibleColumns, setVisibleColumns] = useState(dispose_columns);
	const [sources, setSources] = useState(DD_DATA_SOURCES);
	const [paginationParams, setPaginationParams] = useState(page_params);
	const [filterParams, setFilterParams] = useState({ isAssigned: 0 });
	const [sortParms, setSortParms] = useState([]);
	const [isDataExport, setIsDataExport] = useState(false);
	const [currentPopup, setCurrentPopup] = useState();
	const [openDialog, setOpenDialog] = useState(false);
	const [gridFilters, setGridFilters] = useState(initialFilter);
	const { type: userPageType = "" } = props;
	let loadKey = "";
	const [selectedState, setSelectedState] = useState({});
	const [delayReasons, setDelayReasons] = useState([]);
	const [challanItemData, setChallanItemData] = useState();
	const isQA = ["STAGING", "QA"].includes(process.env.REACT_APP_ENV);
	const history = useHistory();
	const userType = getFFUserType();
	const pageKey = getPageTitle();
	const roles = getUserRoles();

	const onExportDataStart = () => {
		setIsDataExport(true);
	};

	const onExportDataEnd = () => {
		setIsDataExport(false);
	};

	useEffect(() => {
		if (sources?.sourceCode?.length && !filterParams?.sourceCode) {
			setFilterParams((prev) => ({
				...prev,
				sourceCode: sources?.sourceCode?.map((val) => val.value).join(',') || '',
			  }));
		}
	},[sources, filterParams]);

	const resetChallanItems = (sort) => {
		const params = { ...paginationParams, ...filterParams };
		if(roles.includes(USER_ROLE_KEYS.DISPOSE) && !filterParams?.sourceCode) {
			return;
		}
		let qsParams = convertObjectToParams(params);
		if (!!sort?.length) {
			qsParams = `${qsParams}&sortBy=${sort[0].field}&sortDir=${sort[0].dir}`;
		}
		dispatch(fetchDisposeChallanItems(qsParams));
	};

	const fetchChallanItems = (key, sort = []) => {
		if (!loadKey && userPageType !== FF_PAGE_TYPES.DISPOSE_VIEW) {
			loadKey = key;
			resetChallanItems(sort);
		} else if (
			!loadKey &&
			!isLoading &&
			userPageType === FF_PAGE_TYPES.DISPOSE_VIEW &&
			!!searchQuery
		) {
			loadKey = key;
			resetChallanItems(sort);
		}
	};

	const pageChange = ({
		page = page_params.pageNumber,
		size = page_params.pageSize,
	}) => {
		setPaginationParams({ pageNumber: page, pageSize: size });
	};

	const sortChange = (e) => {
		const sortKey = !!e?.sort?.length && e.sort[0].field;
		if (ALLOWED_SORT_PARAMS.includes(sortKey)) {
			setSortParms(e.sort);
			fetchChallanItems("sort", e.sort);
		}
	};

	const getDefaultFilterParams = (parameters, value) => {
		let params = { ...parameters };
		delete params.isDelayed;
		delete params.isAssigned;
		if (value === NUMBERS.FOUR) {
			params = {
				...params,
				isDelayed: NUMBERS.ONE,
			};
		} else {
			params = {
				...params,
				isAssigned: userAssignments,
			};
		}
		// for view only user
		if (!!searchQuery && userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_VIEW) {
			params = {
				...params,
				[searchQuery?.selectedOption]: searchQuery?.value,
			};
		}
		if (userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_VIEW) {
			params = {
				...params,
				[searchQuery?.selectedOption || challan_item_keys.REG_NUMBER]:
					searchQuery?.value || "",
			};
		}
		if (roles.includes(USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_DISPOSE_VAS)) {
			params = {
				...params,
				sourceCode: CHALLAN_SOURCE_KEYS.VAS,
			}
		} else {
			params = {
				...params,
				sourceCode: sources?.sourceCode?.map((val) => val.value).join(',') || '',
			}
		}
		return params;
	};
	const onDataSortByAgeing = (e) => {
		const sortKey = challan_item_keys.AGEING;
		if (ALLOWED_SORT_PARAMS.includes(sortKey)) {
			const sortParams = [{ field: sortKey, dir: e }];
			setSortParms(sortParams);
			fetchChallanItems("sort", sortParams);
		}
	};

	const onAssignedToMe = () => {
		const selectedRowIds = getSelectedAssignToMeRowIds(selectedState, data);
		if (!!selectedRowIds.length) {
			ChallanService.assignDisposerToChallanItem(selectedRowIds)
				.then((resp) => {
					showToastMessage("Challan items assigned successfully");
					resetChallanItems();
					setSelectedState({});
				})
				.catch((err) => {
					showToastMessage(err?.message || "Failed", false);
				});
		} else {
			showToastMessage("Please select atleast one row to continue", false);
		}
	};
	const NoticeNumberLCell = (props) => {
		const { dataItem } = props || {};
		return (
			<td
				title={dataItem[challan_item_keys.NOTICE_NUMBER]}
				className="k-table-td ">
				{
					<span
						onClick={() => setChallanItemData(dataItem)}
						style={{
							color: "#003E72",
							textDecoration: "underline",
							cursor: "pointer",
							wordBreak: "break-word",
							fontSize: "13px",
						}}>
						{dataItem[challan_item_keys.NOTICE_NUMBER]}
					</span>
				}
			</td>
		);
	};

	useEffect(() => {
		setVisibleColumns((columns) => ({
			...columns,
			[challan_item_keys.NOTICE_NUMBER]: {
				...columns[challan_item_keys.NOTICE_NUMBER],
				cell: NoticeNumberLCell,
			},
		}));
		if (
			userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN &&
			userAssignments === NUMBERS.FOUR
		) {
			ChallanService.fetchAllDelayReason()
				.then((res) => {
					const delayReasonList =
						(!!res?.detail?.length &&
							res?.detail?.map((item) => ({
								label: item?.reason,
								value: item?.reason,
							}))) ||
						[];
					setDelayReasons(delayReasonList);
				})
				.catch((err) => {
					setDelayReasons([]);
					console.log({ err });
				});
		}

		ChallanService.fetchChallanRegions().then((resp) => {
			const rgns = resp.detail?.map((item) => {
				return {
					label: item.regionName,
					value: item.id,
				};
			});
			setSources((items) => ({
				...items,
				[challan_item_keys.REGION]: rgns,
			}));
		});
		ChallanService.fetchAllOffenses().then((resp) => {
			const srcs = resp?.detail?.map((item) => {
				return {
					value: item.idChallanOffence,
					label: item.offenceName,
				};
			});
			setSources((offenses) => ({
				...offenses,
				[challan_item_keys.CHALLAN_OFFENSE_ID]: srcs,
			}));
		});
		ChallanService.fetchAgentsByCourt().then((resp) => {
			const agents = resp.detail?.map((item) => {
				return {
					label: item.agentName,
					value: item.id,
				};
			});
			setSources((items) => ({
				...items,
				[challan_item_keys.AGENT_NAME]: agents,
			}));
		});

		ChallanService.fetchAllSources().then((resp) => {
			const srcs = resp?.detail?.map((item) => {
				return {
					value: item.code,
					label: item.name,
				};
			});
			let filteredSrcs = [];
			if(userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN) {
				if(!roles.includes(USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_DISPOSE_VAS)) {
					filteredSrcs = srcs.filter((item) => {
						return item.label.toUpperCase() !== CHALLAN_SOURCE_KEYS.VAS
					})
				} else {
					filteredSrcs = srcs.filter((item) => {
						return item.label.toUpperCase() === CHALLAN_SOURCE_KEYS.VAS
					})
				}
			}
			// const filterSources = srcs.filter(
			// 	(item) => item.label.toUpperCase() !== CHALLAN_SOURCE_KEYS.VAS
			// );
			setSources((items) => ({
				...items,
				[challan_request_keys.CHALLAN_SOURCE]: filteredSrcs?.length ? filteredSrcs : srcs,
			}));
		});

		ChallanService.fetchCourtsByRegion().then((resp) => {
			const courts = resp.detail?.map((item) => {
				return {
					label: item.courtName,
					value: item.id,
				};
			});
			setSources((items) => ({
				...items,
				[challan_item_keys.CHALLAN_COURT]: courts,
			}));
		});

		ChallanService.fetchDisposeUsers().then((resp) => {
			const disposers = resp.detail?.map((item) => {
				return {
					label: item,
					value: item,
				};
			});
			setSources((items) => ({
				...items,
				[challan_item_keys.ASSIGN_TO]: disposers,
			}));
		});

		return () => {
			dispatch(setChallanDisposeSearchQuery());
			dispatch(resetChallanDataItems());
		};
	}, []);
	useEffect(() => {
		if (!!delayReasons.length && userAssignments === NUMBERS.FOUR) {
			setVisibleColumns((columns) => ({
				...columns,
				[challan_item_keys.DELAY_REASON]: {
					...columns[challan_item_keys.DELAY_REASON],
					cell: DelayReasonCell,
				},
			}));
		}
	}, [delayReasons]);

	useEffect(() => {
		setVisibleColumns((columns) => ({
			...columns,
			[challan_item_keys.CHALLAN_TYPE]: {
				...columns[challan_item_keys.CHALLAN_TYPE],
				headerClassName: isFilterFieldPresent(
					gridFilters?.filters,
					challan_item_keys.CHALLAN_TYPE
				)
					? "selected-filter"
					: "de-selected-filter",
				columnMenu: (props) => {
					return (
						<ColumnMenuCheckboxFilter
							{...props}
							userAssignments={userAssignments}
							filterData={sources}
							onFilterButtonClick={handleFilterButtonClick}
							columnMapKey={challan_item_keys.CHALLAN_TYPE}
							filter={gridFilters}
						/>
					);
				},
			},
			[challan_item_keys.STATUS]: {
				...columns[challan_item_keys.STATUS],
				headerClassName: isFilterFieldPresent(
					gridFilters?.filters,
					challan_item_keys.STATUS
				)
					? "selected-filter"
					: "de-selected-filter",
				columnMenu: (props) => {
					return (
						<ColumnMenuCheckboxFilter
							{...props}
							userAssignments={userAssignments}
							filterData={sources}
							onFilterButtonClick={handleFilterButtonClick}
							columnMapKey={challan_item_keys.STATUS}
							filter={gridFilters}
						/>
					);
				},
			},
			[challan_item_keys.SOURCE_CODE]: {
				...columns[challan_item_keys.SOURCE_CODE],
				headerClassName: isFilterFieldPresent(
					gridFilters?.filters,
					challan_item_keys.SOURCE_CODE
				)
					? "selected-filter"
					: "de-selected-filter",
				columnMenu: (props) => {
					return (
						<ColumnMenuCheckboxFilter
							{...props}
							userAssignments={userAssignments}
							filterData={sources}
							onFilterButtonClick={handleFilterButtonClick}
							columnMapKey={challan_item_keys.SOURCE_CODE}
							filter={gridFilters}
						/>
					);
				},
			},
			[challan_item_keys.REGION]: {
				...columns[challan_item_keys.REGION],
				headerClassName: isFilterFieldPresent(
					gridFilters?.filters,
					challan_item_keys.REGION
				)
					? "selected-filter"
					: "de-selected-filter",
				columnMenu: (props) => {
					return (
						<ColumnMenuCheckboxFilter
							{...props}
							userAssignments={userAssignments}
							filterData={sources}
							onFilterButtonClick={handleFilterButtonClick}
							columnMapKey={challan_item_keys.REGION}
							filter={gridFilters}
						/>
					);
				},
			},
			[challan_item_keys.CHALLAN_COURT]: {
				...columns[challan_item_keys.CHALLAN_COURT],
				headerClassName: isFilterFieldPresent(
					gridFilters?.filters,
					challan_item_keys.CHALLAN_COURT
				)
					? "selected-filter"
					: "de-selected-filter",
				columnMenu: (props) => {
					return (
						<ColumnMenuCheckboxFilter
							{...props}
							filterData={sources}
							userAssignments={userAssignments}
							onFilterButtonClick={handleFilterButtonClick}
							columnMapKey={challan_item_keys.CHALLAN_COURT}
							filter={gridFilters}
						/>
					);
				},
			},
			[challan_item_keys.AGENT_NAME]: {
				...columns[challan_item_keys.AGENT_NAME],
				cell: AssignedAgentCell,
				headerClassName: isFilterFieldPresent(
					gridFilters?.filters,
					challan_item_keys.AGENT_NAME
				)
					? "selected-filter"
					: "de-selected-filter",
				columnMenu: (props) => {
					return (
						<ColumnMenuCheckboxFilter
							{...props}
							filterData={sources}
							userAssignments={userAssignments}
							onFilterButtonClick={handleFilterButtonClick}
							columnMapKey={challan_item_keys.AGENT_NAME}
							filter={gridFilters}
						/>
					);
				},
			},
			[challan_item_keys.STATUS]: {
				...columns[challan_item_keys.STATUS],
				headerClassName: isFilterFieldPresent(
					gridFilters?.filters,
					challan_item_keys.STATUS
				)
					? "selected-filter"
					: "de-selected-filter",
				columnMenu: (props) => {
					return (
						<ColumnMenuCheckboxFilter
							{...props}
							filterData={sources}
							userAssignments={userAssignments}
							onFilterButtonClick={handleFilterButtonClick}
							columnMapKey={challan_item_keys.STATUS}
							filter={gridFilters}
						/>
					);
				},
			},
			[challan_item_keys.PAYMENT_URL]: {
				...columns[challan_item_keys.PAYMENT_URL],
				headerClassName: isFilterFieldPresent(
					gridFilters?.filters,
					challan_item_keys.PAYMENT_URL
				)
					? "selected-filter"
					: "de-selected-filter",
				columnMenu: (props) => {
					return (
						<ColumnMenuCheckboxFilter
							{...props}
							userAssignments={userAssignments}
							filterData={sources}
							onFilterButtonClick={handleFilterButtonClick}
							columnMapKey={challan_item_keys.PAYMENT_URL}
							filter={gridFilters}
						/>
					);
				},
			},
			...(userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN && {
				[challan_item_keys.ASSIGN_TO]: {
					...columns[challan_item_keys.ASSIGN_TO],
					headerClassName: isFilterFieldPresent(
						gridFilters?.filters,
						challan_item_keys.ASSIGN_TO
					)
						? "selected-filter"
						: "de-selected-filter",
					cell: AssignedDisposerCell,
					hide: false,
					columnMenu: (props) => {
						return (
							<ColumnMenuCheckboxFilter
								{...props}
								filterData={sources}
								userAssignments={userAssignments}
								onFilterButtonClick={handleFilterButtonClick}
								columnMapKey={challan_item_keys.ASSIGN_TO}
								filter={gridFilters}
							/>
						);
					},
				},
			}),
		}));
	}, [userAssignments, sources, gridFilters, searchQuery]);

	useEffect(() => {
		if (userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_VIEW && !searchQuery) {
			dispatch(resetChallanDataItems());
		} else {
			dispatch(updateChallanItemAssignmentTypeParams(NUMBERS.ZERO));
			setGridFilters(initialFilter);
			if (!!searchQuery) {
				setFilterParams({ [searchQuery?.selectedOption]: searchQuery?.value });
			} else {
				setFilterParams({ isAssigned: 0 });
			}
		}
	}, [searchQuery]);

	useEffect(() => {
		let params = { ...filterParams };
		Object.keys(dispose_filters_formData).map((key) => {
			delete params[key];
		});
		params = {
			...params,
			...filters,
		};
		setFilterParams(params);
	}, [filters]);

	useEffect(() => {
		if (userAssignments !== null) {
			if (
				userType !== USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN &&
				userAssignments === NUMBERS.FOUR
			) {
				ChallanService.fetchAllDelayReason()
					.then((res) => {
						const delayReasonList =
							(!!res?.detail?.length &&
								res?.detail?.map((item) => ({
									label: item?.reason,
									value: item?.reason,
								}))) ||
							[];
						setDelayReasons(delayReasonList);
					})
					.catch((err) => {
						setDelayReasons([]);
						console.log({ err });
					});
			}
			setVisibleColumns((columns) => ({
				...columns,
				[challan_item_keys.DELAY_REASON]: {
					...columns[challan_item_keys.DELAY_REASON],
					hide: userAssignments !== NUMBERS.FOUR,
				},
			}));
			setPaginationParams(page_params);
			let params = getDefaultFilterParams(filterParams, userAssignments);
			setFilterParams(params);
		}
	}, [userAssignments]);

	useEffect(() => {
		if (!!delayed) {
			let params = { ...filterParams };
			delete params["isAssigned"];
			params = {
				...params,
				isDelayed: 1,
			};
			setFilterParams(params);
		}
	}, [delayed]);

	useEffect(() => {
		let params = { ...filterParams };
		delete params[challan_item_keys.CHALLAN_TYPE];
		if (!!channelSource) {
			params = {
				...params,
				[challan_item_keys.CHALLAN_TYPE]: channelSource,
			};
		}
		setFilterParams(params);
	}, [channelSource]);

	useEffect(() => {
		if (!isObjectEmpty(filterParams)) {
			fetchChallanItems("filterParams");
		}
	}, [filterParams]);

	useEffect(() => {
		if (!isObjectEmpty(paginationParams)) {
			fetchChallanItems("paginationParams");
		}
	}, [paginationParams]);

	const onRowSelectionChange = React.useCallback(
		(event) => {
			const newSelectedState = getSelectedState({
				event,
				selectedState: selectedState,
				dataItemKey: challan_item_keys.CHALLAN_ID,
			});
			setSelectedState(newSelectedState);
		},
		[selectedState]
	);

	const onHeaderSelectionChange = React.useCallback((event) => {
		const checkboxElement = event.syntheticEvent.target;
		const checked = checkboxElement.checked;
		const newSelectedState = {};
		event.dataItems.forEach((item) => {
			newSelectedState[idGetter(item)] = checked;
			item[SELECTED_FIELD_KEY] = checked;
		});
		setSelectedState(newSelectedState);
	}, []);

	const onChangeDelayReason = (e, data) => {
		const reason = e?.value?.value;
		const { idChallanItem } = data;
		let formData = new FormData();
		formData.append("delayReason", reason);
		ChallanService.addDelayReasonChallanItem(idChallanItem, formData)
			.then((res) => {
				showToastMessage(res?.message);
			})
			.catch((err) => {
				showToastMessage(err?.message, false);
			})
			.finally(() => {
				resetChallanItems();
			});
	};

	const DelayReasonCell = (props) => {
		const { dataItem } = props || {};
		const curDelayReason = delayReasons.find(
			(item) => item.value === dataItem[challan_item_keys.DELAY_REASON]
		);
		return (
			<td className="k-table-td " title={curDelayReason?.label || ""}>
				{userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN ? (
					shortenString(curDelayReason?.label, WORD_LIMIT_TRUNCATE.LIMIT)
				) : (
					<FormDropDownList
						data={delayReasons}
						onChange={(e) => onChangeDelayReason(e, dataItem)}
						id={`delay-reason-${dataItem[challan_item_keys.NOTICE_NUMBER]}`}
						key={`delay-reason-${dataItem[challan_item_keys.NOTICE_NUMBER]}`}
						textField="label"
						dataItemKey="value"
						value={curDelayReason}
					/>
				)}
			</td>
		);
	};

	const handleFilterButtonClick = React.useCallback(
		(key, e, data, assign) => {
			let _filterParamsData = getDefaultFilterParams(filterParams, assign);
			delete _filterParamsData[key];
			if (!!e) {
				setGridFilters(e);
				setPaginationParams({
					pageNumber: page_params.pageNumber,
					pageSize: page_params.pageSize,
				});
				// filter issue here - start
				e?.filters.map((item) => {
					const updatedFilter = item?.filters.reduce((acc, cur) => {
						const existingItem = acc?.find(
							(transformedItem) => transformedItem[cur.field]
						);
						const itemValue = [
							challan_item_keys.REGION,
							challan_item_keys.CHALLAN_COURT,
							challan_item_keys.AGENT_NAME,
						].includes(cur.field)
							? cur.value
							: getArrayItemValueByLabel(data[cur.field], cur.value);
						if (existingItem) {
							existingItem[cur.field] += `,${itemValue}`;
						} else {
							acc.push({ [cur.field]: itemValue });
						}
						return acc;
					}, []);

					const filterParamsData = updatedFilter.reduce(
						(result, obj) => ({ ...result, ...obj }),
						{}
					);
					_filterParamsData = { ..._filterParamsData, ...filterParamsData };
				});
			}
			setFilterParams(_filterParamsData);
		},
		[userAssignments, filterParams, searchQuery]
	);

	const handleVersionChange = () => {
		if (!!isQA) {
			history.push(
				userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN
					? pageKey === FF_PAGE_TYPES.DISPOSE
						? "/operations/v1/admin/disposers"
						: "/operations/v1/admin/finders"
					: "/operations/v1/challans"
			);
		}
	};

	const AssignedAgentCell = (event) => {
		const { agent = "", status } = event.dataItem;
		// const updatedAgent = getUserFirstNameFromEmail(agent);
		const isChallanCycleCompleted = [
			CHALLAN_ITEM_STATUSES.PAID,
			CHALLAN_ITEM_STATUSES.UNABLE_TO_FULFILL,
			CHALLAN_ITEM_STATUSES.RAISE_ACCEPTED,
		].includes(status);
		if (!!agent) {
			return (
				<td className="k-table-td" title={agent}>
					<p
						style={{
							wordWrap: "break-word",
							textAlign: "left",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							textTransform: "capitalize",
						}}>
						{getUserFirstNameFromEmail(agent)}
						{userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN &&
							!isChallanCycleCompleted && (
								<div className="assign-me-wrapper">
									<span
										style={{
											cursor: "pointer",
											color: "#0059A3",
											fontWeight: "700",
										}}
										onClick={() =>
											onChallanItemOpenDialog(
												FORM_TYPES.ADMIN_RE_ASSIGN_AGENT_DISPOSE,
												event.dataItem
											)
										}>
										<img alt="edit" src={srcEditImg} />
									</span>
								</div>
							)}
					</p>
				</td>
			);
		} else {
			return (
				<td className="k-table-td">
					<div className="assign-me-wrapper">
						{userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN && (
							<Button
								themeColor={"primary"}
								onClick={() =>
									onChallanItemOpenDialog(
										FORM_TYPES.ADMIN_RE_ASSIGN_AGENT_DISPOSE,
										event.dataItem
									)
								}>
								{" "}
								Assign
							</Button>
						)}
					</div>
				</td>
			);
		}
	};

	const AssignedDisposerCell = (event) => {
		const { assignedTo = "", status } = event.dataItem;
		const updatedAssignedTo = getUserFirstNameFromEmail(assignedTo);
		const isChallanCycleCompleted = [
			CHALLAN_ITEM_STATUSES.PAID,
			CHALLAN_ITEM_STATUSES.UNABLE_TO_FULFILL,
			CHALLAN_ITEM_STATUSES.RAISE_ACCEPTED,
		].includes(status);
		if (!!assignedTo) {
			return (
				<td className="k-table-td" title={assignedTo}>
					<p
						style={{
							wordWrap: "break-word",
							textAlign: "center",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							textTransform: "capitalize",
						}}>
						{updatedAssignedTo}
						{userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN &&
							!isChallanCycleCompleted && (
								<div className="assign-me-wrapper">
									<span
										style={{
											cursor: "pointer",
											color: "#0059A3",
											fontWeight: "700",
										}}
										onClick={() =>
											onChallanItemOpenDialog(
												FORM_TYPES.ADMIN_ASSIGN_AGENT_DISPOSE,
												event.dataItem
											)
										}>
										<img alt="edit" src={srcEditImg} />
									</span>
								</div>
							)}
					</p>
				</td>
			);
		} else {
			return (
				<td className="k-table-td">
					<div className="assign-me-wrapper">
						<Button
							themeColor={"primary"}
							fillMode={"solid"}
							onClick={() =>
								onChallanItemOpenDialog(
									FORM_TYPES.ADMIN_ASSIGN_AGENT_DISPOSE,
									event.dataItem
								)
							}>
							Assign
						</Button>
					</div>
				</td>
			);
		}
	};

	const onChallanItemOpenDialog = (action, event = {}, isEdit = false) => {
		let isPopup = true;
		switch (action) {
			case FORM_TYPES.ASSIGN_AGENT:
				const popUtitle = isEdit ? "Update Agent" : "Assign Agent";
				let _agentData = {};
				Object.keys(ASSIGN_AGENT_FORM_DATA).map((key) => {
					_agentData = {
						..._agentData,
						[key]: { ...ASSIGN_AGENT_FORM_DATA[key], value: null },
					};
				});
				if (isEdit) {
					_agentData[challan_item_keys.AGENT_FESS].value =
						event[challan_item_keys.AGENT_FESS];
					_agentData[challan_item_keys.EXPECTED_TAT].value =
						event[challan_item_keys.EXPECTED_TAT];
				}
				setCurrentPopup({
					key: action,
					title: `${popUtitle} - (Notice Number: ${
						event[challan_item_keys.NOTICE_NUMBER]
					})`,
					dataItem: event,
					formData: { ..._agentData },
				});
				break;
			case FORM_TYPES.ADMIN_ASSIGN_AGENT_DISPOSE:
				let _assignToData = { ...ADMIN_ASSIGN_AGENT_POPUP };
				_assignToData[challan_item_keys.APPOINTMENT_ORDER_ID].value =
					event[challan_item_keys.APPOINTMENT_ORDER_ID];
				_assignToData[challan_item_keys.REG_NUMBER].value =
					event[challan_item_keys.REG_NUMBER];
				_assignToData[challan_item_keys.SOURCE_CODE].value =
					event[challan_item_keys.SOURCE_CODE];
				setCurrentPopup({
					key: action,
					title: `Assign Disposer`,
					dataItem: event,
					formData: _assignToData,
				});
				break;
			case FORM_TYPES.ADMIN_RE_ASSIGN_AGENT_DISPOSE:
				let _reAssignAgentData = {};
				Object.keys(ADMIN_RE_ASSIGN_AGENT_POPUP).map((key) => {
					let value = null;
					let disabled = false;
					let hide = false;
					if (key === challan_item_keys.AGENT_TYPE) {
						value = event[challan_item_keys.AGENT_TYPE];
					}
					if (
						event[challan_item_keys.CHALLAN_TYPE] ===
							CHALLAN_MODE_TYPES.OFFLINE &&
						key === challan_item_keys.AGENT_TYPE
					) {
						disabled = true;
						value = DISPOSER_ASSIGNED_VALUES.EXTERNAL_AGENT;
					} else if (
						[
							CHALLAN_MODE_TYPES.ONLINE,
							CHALLAN_MODE_TYPES.VIRTUAL_COURT,
						].includes(event[challan_item_keys.CHALLAN_TYPE]) &&
						(event[challan_item_keys.AGENT_TYPE] ===
							DISPOSER_ASSIGNED_VALUES.SELF ||
							!event[challan_item_keys.AGENT_TYPE]) &&
						key !== challan_item_keys.AGENT_TYPE
					) {
						hide = true;
					}
					_reAssignAgentData = {
						..._reAssignAgentData,
						[key]: {
							...ADMIN_RE_ASSIGN_AGENT_POPUP[key],
							value,
							disabled,
							hide,
						},
					};
				});

				setCurrentPopup({
					key: action,
					title: `Assign Agent`,
					dataItem: event,
					formData: _reAssignAgentData,
				});
				break;
			case FORM_TYPES.ADMIN_BULK_ASSIGN_AGENT_DISPOSE:
				const selectedRowIds = getSelectedBulkRowIds(selectedState, data);
				if (!!selectedRowIds?.length) {
					let _bulkAssignAgentData = {
						idChallanItemList: selectedRowIds,
						...ADMIN_BULK_ASSIGNMENT_AGENT_POPUP,
					};
					setCurrentPopup({
						key: action,
						title: `Bulk Assignment `,
						dataItem: event,
						formData: _bulkAssignAgentData,
					});
				} else {
					isPopup = false;
					setCurrentPopup({});
					showToastMessage("Please select atleast one row to continue", false);
				}
				break;
			default:
				isPopup = false;
				setCurrentPopup({});
				break;
		}
		setOpenDialog(isPopup);
	};

	const updateCurrentPopupData = ({
		key,
		value,
		isFile = false,
		isHideRequired = false,
	}) => {
		let updatedPopupData = {
			...currentPopup,
			formData: {
				...currentPopup.formData,
				[key]: {
					...currentPopup.formData[key],
					...{ [isFile ? "file" : "value"]: value },
				},
			},
		};

		if (!!isHideRequired) {
			const hiddenKeys = [
				challan_item_keys.AGENT_NAME,
				challan_item_keys.CHALLAN_COURT,
				challan_item_keys.REGION,
			];
			hiddenKeys.forEach((hiddenKey) => {
				updatedPopupData.formData[hiddenKey].hide =
					value !== DISPOSER_ASSIGNED_VALUES.EXTERNAL_AGENT;
			});
		}
		setCurrentPopup(updatedPopupData);
	};

	const onPopupConfirm = () => {
		if (
			[FORM_TYPES.ADMIN_RE_ASSIGN_AGENT_DISPOSE].includes(currentPopup.key) &&
			!currentPopup?.formData?.[challan_item_keys.AGENT_TYPE]?.value
		) {
			showToastMessage("Please select Assign Agent type", false);
			return;
		} else if (
			currentPopup.key === FORM_TYPES.ASSIGN_AGENT ||
			(currentPopup.key === FORM_TYPES.ADMIN_RE_ASSIGN_AGENT_DISPOSE &&
				currentPopup?.formData?.[challan_item_keys.AGENT_TYPE]?.value ===
					DISPOSER_ASSIGNED_VALUES.EXTERNAL_AGENT)
		) {
			const payload = {
				[challan_item_keys.CHALLAN_COURT]:
					currentPopup.formData[challan_item_keys.CHALLAN_COURT]?.value?.label,
				[challan_item_keys.REGION]:
					currentPopup.formData[challan_item_keys.REGION]?.value?.label,
				idChallanAgent:
					currentPopup.formData[challan_item_keys.AGENT_NAME]?.value?.value,
				[challan_item_keys.AGENT_NAME]:
					currentPopup.formData[challan_item_keys.AGENT_NAME]?.value?.label,
			};
			if (!currentPopup.formData[challan_item_keys.REGION]?.value?.value) {
				showToastMessage("Please select region", false);
				return;
			} else if (
				!currentPopup.formData[challan_item_keys.CHALLAN_COURT]?.value?.value
			) {
				showToastMessage("Please select court", false);
				return;
			} else if (
				!currentPopup.formData[challan_item_keys.AGENT_NAME]?.value?.value
			) {
				showToastMessage("Please select agent", false);
				return;
			}
			ChallanService.assignAgentToChallanItem(
				currentPopup?.dataItem?.idChallanItem,
				payload
			)
				.then((resp) => {
					showToastMessage(resp?.message);
					setOpenDialog(false);
					resetChallanItems();
				})
				.catch((err) => {
					showToastMessage(err?.message || "Failed", false);
				});
		} else if (currentPopup.key === FORM_TYPES.ADMIN_ASSIGN_AGENT_DISPOSE) {
			const regNumber = currentPopup.formData[challan_item_keys.REG_NUMBER];
			const assignTo =
				currentPopup.formData[challan_item_keys.ASSIGN_TO]?.value?.value;
			if (!regNumber?.value) {
				showToastMessage(
					"Please select valid vehicle registration number",
					false
				);
				return;
			} else if (!assignTo) {
				showToastMessage("Please select disposer", false);
				return;
			}
			ChallanService.UpdateAssignTo(
				currentPopup?.dataItem?.idChallanItem,
				assignTo,
				"dispose"
			)
				.then((resp) => {
					showToastMessage(resp?.message);
					setOpenDialog(false);
					resetChallanItems();
				})
				.catch((err) => {
					showToastMessage(err?.message || "Failed", false);
				});
		} else if (
			currentPopup.key === FORM_TYPES.ADMIN_RE_ASSIGN_AGENT_DISPOSE &&
			currentPopup?.formData?.[challan_item_keys.AGENT_TYPE]?.value ===
				DISPOSER_ASSIGNED_VALUES.SELF
		) {
			ChallanService.updateAssignedDisposer(
				currentPopup?.dataItem?.idChallanItem,
				{
					agentType:
						currentPopup?.formData?.[challan_item_keys.AGENT_TYPE]?.value,
				}
			)
				.then((resp) => {
					showToastMessage(resp?.message);
					setOpenDialog(false);
					resetChallanItems();
				})
				.catch((err) => {
					showToastMessage(err?.message || "Failed", false);
				});
		} else if (
			currentPopup.key === FORM_TYPES.ADMIN_BULK_ASSIGN_AGENT_DISPOSE
		) {
			if (!currentPopup.formData[challan_item_keys.REGION]?.value?.value) {
				showToastMessage("Please select region", false);
				return;
			} else if (
				!currentPopup.formData[challan_item_keys.CHALLAN_COURT]?.value?.value
			) {
				showToastMessage("Please select court", false);
				return;
			} else if (
				!currentPopup.formData[challan_item_keys.AGENT_NAME]?.value?.value
			) {
				showToastMessage("Please select agent", false);
				return;
			}
			const payload = {
				idChallanItemList: currentPopup?.formData?.idChallanItemList,
				[challan_item_keys.CHALLAN_COURT]:
					currentPopup.formData[challan_item_keys.CHALLAN_COURT]?.value?.label,
				[challan_item_keys.REGION]:
					currentPopup.formData[challan_item_keys.REGION]?.value?.label,
				[challan_item_keys.AGENT_NAME]:
					currentPopup.formData[challan_item_keys.AGENT_NAME]?.value?.label,
			};
			ChallanService.bulkAgentAssigned(payload)
				.then((resp) => {
					showToastMessage(resp?.message);
					setOpenDialog(false);
					resetChallanItems();
					setSelectedState({});
				})
				.catch((err) => {
					showToastMessage(err?.message || "Failed", false);
				});
		}
	};

	const onResetPopup = () => {
		switch (currentPopup.key) {
			case FORM_TYPES.ASSIGN_AGENT:
				setCurrentPopup((prev) => ({
					...prev,
					formData: { ...ASSIGN_AGENT_FORM_DATA },
				}));
				break;
			case FORM_TYPES.ADMIN_ASSIGN_AGENT_DISPOSE:
				setCurrentPopup((prev) => ({
					...prev,
					formData: { ...ADMIN_ASSIGN_AGENT_POPUP },
				}));
				break;
			case FORM_TYPES.ADMIN_RE_ASSIGN_AGENT_DISPOSE:
				setCurrentPopup((prev) => ({
					...prev,
					formData: { ...ADMIN_RE_ASSIGN_AGENT_POPUP },
				}));
				break;
			case FORM_TYPES.ADMIN_BULK_ASSIGN_AGENT_DISPOSE:
				setCurrentPopup((prev) => ({
					...prev,
					formData: { ...ADMIN_BULK_ASSIGNMENT_AGENT_POPUP },
				}));
				break;
			default:
				setCurrentPopup({});
				break;
		}
	};
	const onBackClick = () => {
		setChallanItemData();
	};
	return (
		<>
			{!!challanItemData ? (
				<DisposeDetailView
					rowData={challanItemData}
					onBackClick={onBackClick}
				/>
			) : (
				<div className="ops-challan-container">
					<div className="ops-grid-layout-container">
						<div>
							<GridLayout
								gap={{
									rows: "0px",
									cols: 10,
								}}>
								<GridLayoutItem
									row={1}
									style={{
										display: "flex",
										justifyContent: "space-between",
									}}>
									<div className={styles.listing_header}>Dispose Panel</div>
									<SearchBarLayout type={userPageType} />
								</GridLayoutItem>
								{userPageType !== FF_PAGE_TYPES.DISPOSE_VIEW && (
									<GridLayoutItem
										row={2}
										style={{
											display: "flex",
											justifyContent: "flex-end",
											alignItems: "center",
										}}>
										{userType === USER_ROLE_KEYS.C2B_OPS_FF_CHALLAN_ADMIN && (
											<Button
												themeColor={"primary"}
												fillMode={"outline"}
												onClick={() =>
													onChallanItemOpenDialog(
														FORM_TYPES.ADMIN_BULK_ASSIGN_AGENT_DISPOSE
													)
												}>
												Bulk Transfer
											</Button>
										)}
										<ActionBarLayout
											type={userPageType}
											AssignToMe={onAssignedToMe}
											isDataExport={isDataExport}
											onExportDataStart={onExportDataStart}
											onDataSortByAgeing={onDataSortByAgeing}
										/>
										{/* {!!isQA && (
											<Button
												themeColor={"primary"}
												fillMode={"outline"}
												onClick={handleVersionChange}>
												V1
											</Button>
										)} */}
									</GridLayoutItem>
								)}
								<GridLayoutItem row={3}>
									<div className="">
										<TabGridLayout
											isLoading={isLoading}
											type={userPageType}
											data={data?.map((item) => ({
												...item,
												[SELECTED_FIELD_KEY]: selectedState[idGetter(item)],
											}))}
											columns={visibleColumns}
											count={totalCount}
											selectable={true}
											selectedField={SELECTED_FIELD_KEY}
											idField={challan_item_keys.CHALLAN_ID}
											onRowSelectionChange={onRowSelectionChange}
											onHeaderSelectionChange={onHeaderSelectionChange}
											isPagination={true}
											pageChange={pageChange}
											paginationParams={paginationParams}
											sortable={true}
											sortParms={sortParms}
											sortChange={sortChange}
											isDataExport={isDataExport}
											onExportDataEnd={onExportDataEnd}
											userAssignments={userAssignments}
										/>
									</div>
								</GridLayoutItem>
							</GridLayout>
						</div>
						{openDialog && (
							<Dialog
								title={currentPopup?.title}
								onClose={() => {
									setOpenDialog(false);
								}}>
								<ChallanItemPopup
									{...currentPopup}
									action={currentPopup.key}
									updateCurrentPopupData={updateCurrentPopupData}
									// showTickRegNumber={showTickRegNumber}
									setCurrentPopup={setCurrentPopup}
								/>
								<DialogActionsBar>
									<Button
										className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
										onClick={onResetPopup}>
										Reset
									</Button>
									<Button
										className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
										onClick={onPopupConfirm}
										themeColor={"primary"}>
										Confirm
									</Button>
								</DialogActionsBar>
							</Dialog>
						)}
					</div>
				</div>
			)}
		</>
	);
};
export default DisposeContainerView;
