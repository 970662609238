// import { Button } from "@progress/kendo-react-buttons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FORM_ELEMENT_TYPE } from "../../../../utils/constants/values.constants";
import { dealer_column_keys } from "../../fulfillment-list/viewModels";
import {
  COMPANY_CODE_DATA,
  DEALER_TYPE_DATA,
  STATUS_DATA,
} from "../create-dealer/viewModels";

export const centre_column_keys = {
  DEALER_ID: "dealerId",
  CENTRE_ID: "dealerCentreId",
  CENTRE_NAME: "centreName",
  UCM_NAME: "ucmName",
  UCM_PHONE: "ucmPhone",
  UCM_EMAIL: "ucmEmail",
  RA_NAME: "raName",
  RA_PHONE: "raPhone",
  RA_EMAIL: "raEmail",
  CENTRE_ADDRESS: "centreAddress",
  CENTRE_PHONE: "centrePhone",
  CENTRE_EMAIL: "centreEmail",
  CITY_ID: "cityId",
  CITY_NAME: "cityName",
  CITY_AREA: "area",
  LATITUDE: "latitude",
  LONGITUDE: "longitude",
  PINCODE: "pincode",
  STATUS: "status",
  MAKE_ID: 'makeId',
  NCM: 'ncm',
  IS_NEW_CARS_ENABLED: 'isNewCarEnabled'
};

export const EditActionCell = (props) => {
  const { dataItem } = props || {};
  const linkTo = `/dealer/fulfillments/updateDealerCenter/${dataItem?.dealerId}/${dataItem?.dealerCentreId}`;
  return (
    <td style={{ color: "#0d6efd" }} className="k-table-td ">
      {<Link to={linkTo}>{dataItem?.centreName}</Link>}
    </td>
  );
};

export const centreColumns = {
  [centre_column_keys.DEALER_ID]: {
    field: centre_column_keys.DEALER_ID,
    label: "Dealer Id",
    width: "100px",
  },
  [centre_column_keys.CENTRE_ID]: {
    field: centre_column_keys.CENTRE_ID,
    label: "Centre Id",
    width: "100px",
  },
  [centre_column_keys.CENTRE_NAME]: {
    field: centre_column_keys.CENTRE_NAME,
    label: "Centre Name",
    cell: EditActionCell,
    width: "200px",
  },
  [centre_column_keys.UCM_NAME]: {
    field: centre_column_keys.UCM_NAME,
    label: "UCM Name",
    width: "150px",
  },
  [centre_column_keys.UCM_PHONE]: {
    field: centre_column_keys.UCM_PHONE,
    label: "UCM Phone",
    width: "150px",
  },
  [centre_column_keys.UCM_EMAIL]: {
    field: centre_column_keys.UCM_EMAIL,
    label: "UCM Email",
    width: "300px",
  },
  [centre_column_keys.RA_NAME]: {
    field: centre_column_keys.RA_NAME,
    label: "RA Name",
    width: "150px",
  },
  [centre_column_keys.RA_PHONE]: {
    field: centre_column_keys.RA_PHONE,
    label: "RA Phone",
    width: "150px",
  },
  [centre_column_keys.RA_EMAIL]: {
    field: centre_column_keys.RA_EMAIL,
    label: "RA Email",
    width: "300px",
  },
  [centre_column_keys.CENTRE_ADDRESS]: {
    field: centre_column_keys.CENTRE_ADDRESS,
    label: "Centre Address",
    width: "400px",
  },
  [centre_column_keys.CENTRE_PHONE]: {
    field: centre_column_keys.CENTRE_PHONE,
    label: "Centre Phone",
    width: "150px",
  },
  [centre_column_keys.CENTRE_EMAIL]: {
    field: centre_column_keys.CENTRE_EMAIL,
    label: "Centre Email",
    width: "150px",
  },
  [centre_column_keys.CITY_ID]: {
    field: centre_column_keys.CITY_ID,
    label: "City Id",
    width: "150px",
  },
  [centre_column_keys.CITY_NAME]: {
    field: centre_column_keys.CITY_NAME,
    label: "City Name",
    width: "150px",
  },
  [centre_column_keys.LATITUDE]: {
    field: centre_column_keys.LATITUDE,
    label: "Latitude",
    width: "150px",
  },
  [centre_column_keys.LONGITUDE]: {
    field: centre_column_keys.LONGITUDE,
    label: "Longitude",
    width: "150px",
  },
  [centre_column_keys.PINCODE]: {
    field: centre_column_keys.PINCODE,
    label: "Pincode",
    width: "150px",
  },
  [centre_column_keys.MAKE_ID]: {
    field: centre_column_keys.MAKE_ID,
    label: "Make ID",
    width: "150px",
  },
  [centre_column_keys.IS_NEW_CARS_ENABLED]: {
    field: centre_column_keys.IS_NEW_CARS_ENABLED,
    label: "New Cars Status",
    width: "150px",
    cell: (props) => {
      return (
        <td className="k-table-td">
          {props?.dataItem?.isNewCarEnabled ? 'Enabled' : 'Disabled'}
        </td>
      )
    }
  },
  [centre_column_keys.STATUS]: {
    field: centre_column_keys.STATUS,
    label: "Status",
    width: "150px",
  },
};

export const getDisplayedCoulmns = () => {
  return Object.keys(centreColumns);
};

export const FormData = {
  [dealer_column_keys.CODE]: {
    name: dealer_column_keys.CODE,
    label: "Dealer Code",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
  [dealer_column_keys.NAME]: {
    name: dealer_column_keys.NAME,
    label: "Dealer Name",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
  [dealer_column_keys.TYPE]: {
    name: dealer_column_keys.TYPE,
    label: "Dealer Type",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    source: DEALER_TYPE_DATA,
    isDisplayLabel: true,
  },
  [dealer_column_keys.COMPANY_CODE]: {
    name: dealer_column_keys.COMPANY_CODE,
    label: "Company Code",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    source: COMPANY_CODE_DATA,
    isDisplayLabel: true,
  },
  [dealer_column_keys.PERM_ADDRESS]: {
    name: dealer_column_keys.PERM_ADDRESS,
    label: "Permanent Address",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
  [dealer_column_keys.PRESENT_ADDRESS]: {
    name: dealer_column_keys.PRESENT_ADDRESS,
    label: "Present Address",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
  [dealer_column_keys.MOBILE]: {
    name: dealer_column_keys.MOBILE,
    label: "Mobile No",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
  [dealer_column_keys.ALTERNATE_NO]: {
    name: dealer_column_keys.ALTERNATE_NO,
    label: "Alternate No",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
  [dealer_column_keys.EMAIL_ADDRESS]: {
    name: dealer_column_keys.EMAIL_ADDRESS,
    label: "Email Address",
    elementtype: FORM_ELEMENT_TYPE.INPUT,
  },
  [dealer_column_keys.STATUS]: {
    name: dealer_column_keys.STATUS,
    label: "Status",
    elementtype: FORM_ELEMENT_TYPE.SELECT,
    source: STATUS_DATA,
    isDisplayLabel: true,
  },
};
