import styles from './styles.module.css';
import CreateSegmentation from './create-segmentation';
import TableData from './table-data';
import { useDispatch, useSelector } from 'react-redux';
import { getSegmentsData, refreshSegmentationData } from '../store/actions/segmentation';
import { useEffect, useRef, useState } from 'react';
import { getUserRoles } from '../../../utils/utils';
import { USER_ROLE_KEYS } from '../../../utils/constants/menu.constants';

const INIT_PAGE_PARAMS = {
  pageNumber: 0,
  pageSize: 10,
};

const Segmentation = () => {

  const dispatch = useDispatch();
  const [pageParam, setPageParam] = useState(INIT_PAGE_PARAMS);
  const isRefreshing = useRef(false);
  const { isRefreshRequired } = useSelector((state) => state.segmentationData);

  useEffect(() => {
    if (isRefreshRequired) {
      isRefreshing.current = true;
      dispatch(getSegmentsData(INIT_PAGE_PARAMS));
      setPageParam(INIT_PAGE_PARAMS);
      dispatch(refreshSegmentationData(false));
    }
  }, [isRefreshRequired]);

  useEffect(() => {
    if (!isRefreshing.current) {
      dispatch(getSegmentsData(pageParam));
    } else {
      isRefreshing.current = false;
    }
  }, [pageParam]);

  const pageChange = ({ page = 0, size = 10 }) => {
    setPageParam((prev) => ({ ...prev, pageNumber: page, pageSize: size }));
  };

  const isWriter = getUserRoles()?.includes(
    USER_ROLE_KEYS.SEGMENTATION_WRITER
  );

  return (
    <div className={styles['segmentation-container']}>
      <div className={styles['panel-header']}>
        <span className={styles['panel-name']}>Segmentation Panel </span>
        {isWriter ? <CreateSegmentation /> : null}
      </div>
      <div className={styles['table-container']}>
        <TableData pageParam={pageParam} pageChange={pageChange} />
      </div>
    </div>
  );
};
export default Segmentation;